import { call, fork, put, takeLatest } from '@redux-saga/core/effects'
import { push } from 'connected-react-router'
import { checkErorr, getToken } from '../../../sagas/helper'
import {
  accountGetItemDomains,
  accountGetList,
  changeReferrerStatus,
  TAccountDomains,
  TAccountList,
} from '../../../utils/api'
import { TFetchReferralStatus, TonAccountGetItemDomains } from './actions'
import { TActionsAccounts } from './redusers'

export function* watcherSaga() {
  yield takeLatest('ON_ACCOUNT_GET_LIST', workerAccountGetList)
  yield takeLatest('ON_ACCOUNT_GET_ITEM_DOMAINS', workerAccountGetItemDomains)
  yield takeLatest('accounts/fetchReferralStatus', workerSetReferralStatus)
}

function* workerAccountGetList() {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const accountList: TAccountList = yield call(accountGetList, token)
    const action: TActionsAccounts = {
      type: 'accountList',
      payload: accountList,
    }

    yield put(action)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerAccountGetItemDomains({ payloads }: TonAccountGetItemDomains) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const accountItemDomains: TAccountDomains = yield call(accountGetItemDomains, token, { id: payloads })

    const action: TActionsAccounts = {
      type: 'accountItemDomains',
      payload: accountItemDomains.items,
    }

    yield put(action)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerSetReferralStatus({ payload }: TFetchReferralStatus) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    const res: boolean = yield call(changeReferrerStatus, token, payload)
    yield fork(workerAccountGetList)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export default watcherSaga
