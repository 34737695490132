import HolobgImg from '../assets/command/gamebling.png'
import Dream1Img from '../assets/command/dream1.png'
import Dream2Img from '../assets/command/dream2.png'

export const DreamingList = [
  {
    id: 0,
    seener: '23.8k',
    title: 'Lets earn some...',
    owner: '#PowelPie',
    follow: '24.2k',
    comment: '26.3k',
    img: Dream1Img,
  },
  {
    id: 1,
    seener: '13.2k',
    title: 'Gamebling future...',
    owner: '#Ninja',
    follow: '24.2k',
    comment: '26.3k',
    img: Dream2Img,
  },
  {
    id: 2,
    seener: '26.4k',
    title: 'Lets earn some...',
    owner: '#Cluster',
    follow: '24.2k',
    comment: '26.3k',
    img: HolobgImg,
  },
  {
    id: 3,
    seener: '53.8k',
    title: 'Lets earn some...',
    owner: '#IvanSlab',
    follow: '24.2k',
    comment: '26.3k',
    img: Dream1Img,
  },
  {
    id: 4,
    seener: '3.3k',
    title: 'Lets earn some...',
    owner: '#PowelPie',
    follow: '24.2k',
    comment: '26.3k',
    img: Dream2Img,
  },
  {
    id: 5,
    seener: '2.83k',
    title: 'Lets earn some...',
    owner: '#PowelPie',
    follow: '24.2k',
    comment: '26.3k',
    img: HolobgImg,
  },
]
