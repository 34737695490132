import styled, { css } from 'styled-components'

export const Root = styled.div`
  position: relative;
  width: 100%;
`

export const AutoSearchCompleteDiv = styled.div`
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  border: 1px solid white;
  height: 40px;
  font-size: 14px;
  padding: 0 10px;
  color: white;
  width: 100%;
  outline: none;
`

export const baseButtonMixin = css`
  background: none;
  border: none;
  padding: 0;
`

export const ValueWrapper = styled.input`
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  border: 1px solid white;
  height: 40px;
  font-size: 14px;
  padding: 0 10px;
  color: white;
  width: 100%;
  outline: none;
`

export const AutoSearchCompleteIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 32px;
  transition: all 150ms linear;
  transform: ${(props: any) => (props.isOpen ? 'rotate(0.5turn)' : 'none')};
  transform-origin: center;
  display: flex;

  svg {
    margin: auto;
  }

  ${ValueWrapper}:focus + & {
    color: ${(props: any) => props.color || '0063cc'};
    fill: ${(props: any) => props.fill || '0063cc'};
  }
`

export const AutoSearchCompleteContainer = styled.ul`
  background: rgb(0, 0, 0, 0.9);
  padding: 8px 0;
  list-style-type: none;
  width: 100%;
  position: absolute;
  // left: 10px;
  border: 1px solid #b6c1ce;
  border-radius: 2px;
  // margin-top: 2px;
  max-height: 280px;
  overflow-y: auto;
  z-index: 2;
`
export const AutoSearchDomainContainer = styled.ul`
  background: rgb(61, 61, 61, 0.9);
  padding: 8px 10px;
  list-style-type: none;
  width: 20%;
  position: absolute;
  border: 1px solid #b6c1ce;
  border-radius: 2px;
  margin-top: 45px;
  right: 0;
  max-height: 280px;
  overflow-y: auto;
  z-index: 2;
`
export const AutoSearchCompleteHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  // border-bottom: 1px solid #000;
  padding: 10px;
  font-family: 'Montserrat', sans-serif !important;
  &:hover {
    outline: none;
    color: #fbb212;
    background: transparent;
    cursor: pointer;
  }
`

export const AutoSearchCompleteHeaderText = styled.span`
  font-size: 14px;
`

export const AutoSearchCompleteItem = styled.li`
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
`

export const AutoSearchCompleteItemButton = styled.button`
  ${baseButtonMixin} width: 100%;
  line-height: 18px;
  text-align: left;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  &:hover {
    outline: none;
    color: #fbb212;
    background: transparent;
    cursor: pointer;
  }
`

export const DomainName = styled.div`
  max-width: 68%;
  font-family: 'Montserrat', sans-serif !important;
`

export const TargetUrl = styled.div`
  color: #fbb212;
`
export const Input = styled(ValueWrapper)`
  // transition: border-color 150ms linear;

  &:focus {
    box-shadow: 0 0 8px 1px #598ff3;
    background-color: rgba(0, 0, 0, 0);
    // border: 1px solid #5d90dc;

    + ${AutoSearchCompleteIcon} {
      color: ${(props: any) => props.color || '0063cc'};
      fill: ${(props: any) => props.fill || '0063cc'};
    }
  }
`
