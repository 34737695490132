import React, { FC } from 'react'
import { FaWallet } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { useSelector, shallowEqual } from 'react-redux'
import { Init } from 'redusers/actions'
import { Init as UserInit } from 'redusers/user'
import { AppState } from 'redusers/config'
import { useGetThemeMode } from 'state/theme/hook'
import { useCart, useGetTotalCartAmount } from '../Cart/hooks'
import { TR_Cart } from '../Cart/reduser'
import './styles.scss'

type TCartItem = {
  id: string
  price: number
  isDemo: boolean
  delItem: () => void
  discount?: number
  type?: string
  keyword?: string
}

const CartItem: FC<TCartItem> = ({ id, price, isDemo, delItem, discount, type, keyword }) => {
  const difference = discount === undefined ? 0 : (price / 100) * discount
  const domain = id.substring(0, 1) === '#' ? id : '#' + id
  return (
    <>
      <div className="hashtagCart-body_item">
        <span title={`${id} ${isDemo ? '(demo)' : ''}`} className={`cartName ${isDemo ? 'demo' : ''}`}>
          {domain}
        </span>
        <span className={'prices w600'}>${isDemo ? 0 : price.toFixed(2)}</span>
        <MdClose className={'close'} onClick={delItem} />
      </div>
      {discount !== undefined && discount > 0 && (
        <div className="hashtagCart-body_item discount" title="Discount for paying MHC">
          <span>Discount</span>
          <span>{discount}%</span>
          <span>-{difference.toFixed(2)}$</span>
        </div>
      )}
    </>
  )
}

const CartHashtag: FC = () => {
  const listItems = useSelector<AppState, TR_Cart['cart_listItems']>(({ cart_reducer }) =>
    cart_reducer.get('cart_listItems')
  )
  const { memoDelItem, btn } = useCart()
  const { total } = useGetTotalCartAmount()

  return (
    <form
      className="hashtagCart"
      style={{
        color: 'black',
        border: '2px solid #ebe8e8',
      }}
    >
      <div className="hashtagCart-header w600">
        <FaWallet />
        #HashtagDomain Cart
      </div>
      <div className="hashtagCart-body">
        <div className="hashtagCart-item">
          {listItems?.toArray().map(([_, val]) => {
            return (
              <CartItem
                key={val.id}
                delItem={memoDelItem(val.id)}
                discount={val.discount}
                id={val.id}
                isDemo={val.isDemo}
                price={val.price}
              />
            )
          })}
        </div>

        {listItems && listItems.size > 0 ? (
          <div className="subTotal">
            <div className="hashtagCart-body_item">
              <span>SUBTOTAL</span>
              <span className="w600">${total.toFixed(2)}</span>
            </div>
            <div className="subTotalExplain">*Dead man switch #domain fee $1.00 per year.</div>
          </div>
        ) : (
          <div className="hashtagCart-body_empty">
            <span className="zero">0</span>
            <span>None is the loneliest number.</span>
          </div>
        )}
      </div>
      {btn}
    </form>
  )
}
export default CartHashtag
