import React, { useEffect, useState } from 'react'
import { BsCircleFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { useGetThemeMode } from 'state/theme/hook'
import { Init as UserInit } from 'redusers/user'
import VPNIcon from '../../../assets/icons/vpn.png'
import './styles.scss'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import axios from 'axios'

const FreeVPN: React.FC<any> = ({}) => {
  const userInfo = useSelector<AppState, UserInit['userInfo']>(({ user }) => user.get('userInfo'))
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()

  const [siteDomain, setSiteDomain] = useState<string>('space.hashtag.org')
  const [ipAddress, setIpAddress] = useState<string>('0.0.0.0')
  const [ipPort, setIpPort] = useState<string>('0000')
  const [isOnline, setIsOnline] = useState<boolean>(true)

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    const ip = res.data.IPv4
    const oldIP = localStorage.getItem('ipAddress')
    if (oldIP && oldIP != '') {
      if (oldIP === ip) {
        setIsOnline(true)
      } else {
        setIsOnline(false)
      }
    }

    const protocol = window.location.protocol
    const domain = window.location.hostname
    const port = window.location.port
    setSiteDomain(domain)
    setIpAddress(ip)
    setIpPort(port)
    localStorage.setItem('ipAddress', res.data.IPv4)
  }
  useEffect(() => {
    getData()
    // setIpPort('dddddddd')
  }, [])
  return (
    <div className="VPNContainer">
      <div className="vpn-dropdown">
        <div className="vpnbtn">
          <img src={VPNIcon} width={36} />
        </div>
        <div
          className="vpn-content"
          style={{
            background: themeMode === 'main' ? '#000000d5' : '#fff',
            color: themeMode === 'main' ? '#fff' : '#000',
          }}
        >
          <div className="ipSite mTop">
            <span style={{ marginRight: '10px' }}>{siteDomain}</span>
            <AiOutlineInfoCircle size={18} />
          </div>
          <div className="mTop">
            <span>IP: {ipAddress}</span>
          </div>
          <div className="mTop">
            <span>Port: {ipPort}</span>
          </div>
          <div
            className="ipStatus mTop"
            style={{
              borderColor: isOnline ? '#19f119' : 'red',
              color: isOnline ? '#19f119' : 'red',
            }}
          >
            <BsCircleFill />
            {isOnline ? <span>ONLINE</span> : <span>OFFLINE</span>}
          </div>
          <div className="mTop">
            <span>DO NOT SHARE!!</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FreeVPN
