import React, { ReactNode } from 'react'
import { IGetAllDomainListR, IGetAllSEODomainListR } from 'utils/api'
import { TSearchEngineContext } from '../types'

const InitialState: TSearchEngineContext = {
  domainList: [],
  setDomainList: () => {},
  seoDomainList: [],
  setSEODomainList: () => {},
}

const SearchEngineContext = React.createContext<TSearchEngineContext>(InitialState)
const useSearchEngineContext = () => React.useContext(SearchEngineContext)

const SearchEngineContextProvider = ({ children }: { children: ReactNode }) => {
  const [domainList, setDomainList] = React.useState<IGetAllDomainListR[]>(InitialState['domainList'])
  const [seoDomainList, setSEODomainList] = React.useState<IGetAllSEODomainListR[]>(InitialState['seoDomainList'])

  return (
    <SearchEngineContext.Provider value={{ domainList, setDomainList, seoDomainList, setSEODomainList }}>
      {children}
    </SearchEngineContext.Provider>
  )
}

export { SearchEngineContext, useSearchEngineContext, SearchEngineContextProvider }
