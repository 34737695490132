import React, { FC } from 'react'
import { FaShoppingCart } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { useSelector, shallowEqual } from 'react-redux'
import { Init } from 'redusers/actions'
import { Init as UserInit } from 'redusers/user'
import { AppState } from 'redusers/config'
import { useGetThemeMode } from 'state/theme/hook'
import { useCart, useGetTotalCartAmount } from './hooks'
import { TR_Cart } from './reduser'
import './styles.scss'

type TCartItem = {
  id: string
  price: number
  isDemo: boolean
  delItem: () => void
  discount?: number
  type?: string
  keyword?: string
}

const CartItem: FC<TCartItem> = ({ id, price, isDemo, delItem, discount, type, keyword }) => {
  const difference = discount === undefined ? 0 : (price / 100) * discount
  const domain = id.substring(0, 1) === '#' ? id : '#' + id
  return (
    <>
      <div className="mycart-body_item">
        <span title={`${id} ${isDemo ? '(demo)' : ''}`} className={`cartName ${isDemo ? 'demo' : ''}`}>
          {domain}
        </span>
        <span className={'prices w600'}>${isDemo ? 0 : price.toFixed(2)}</span>
        <MdClose className={'close'} onClick={delItem} />
      </div>
      {discount !== undefined && discount > 0 && (
        <div className="mycart-body_item discount" title="Discount for paying MHC">
          <span>Discount</span>
          <span>{discount}%</span>
          <span>-{difference.toFixed(2)}$</span>
        </div>
      )}
    </>
  )
}

const MyCart: FC = () => {
  const listItems = useSelector<AppState, TR_Cart['cart_listItems']>(({ cart_reducer }) =>
    cart_reducer.get('cart_listItems')
  )
  const { memoDelItem, btn } = useCart()
  const { total } = useGetTotalCartAmount()
  const themeMode = useGetThemeMode()
  return (
    <form
      className="mycart"
      style={{
        background: themeMode == 'main' ? '#000000' : '#ffffff',
        color: themeMode == 'main' ? 'white' : 'black',
      }}
    >
      <div
        className="mycart-header w600"
        style={{
          background:
            themeMode == 'main' ? '#872f9e' : 'linear-gradient(#bdbcbc, #f3f3f3, #fff, #fff, #fff, #f3f3f3, #bdbcbc)',
          color: themeMode == 'main' ? 'white' : 'black',
        }}
      >
        <FaShoppingCart />
        Your #Cart
      </div>
      <div className="mycart-body">
        {listItems?.toArray().map(([_, val]) => {
          return (
            <CartItem
              key={val.id}
              delItem={memoDelItem(val.id)}
              discount={val.discount}
              id={val.id}
              isDemo={val.isDemo}
              price={val.price}
            />
          )
        })}

        {listItems && listItems.size > 0 ? (
          <div className="subTotal">
            <div className="mycart-body_item">
              <span>SUBTOTAL</span>
              <span className="w600">${total.toFixed(2)}</span>
            </div>
            <div className="subTotalExplain">Your #domain purchase is for life.</div>
          </div>
        ) : (
          <div className="mycart-body_empty">
            <span className="zero">0</span>
            <span>None is the loneliest number.!</span>
          </div>
        )}
      </div>
      {btn}
    </form>
  )
}
export default MyCart
