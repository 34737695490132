import { onLogOut } from 'actions/user'
import cn from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import {
  FaChevronRight,
  FaCode,
  FaCoins,
  FaHashtag,
  FaLevelDownAlt,
  FaMoneyCheckAlt,
  FaRegEdit,
  FaRegEnvelope,
  FaRegListAlt,
  FaTable,
  FaUsers,
} from 'react-icons/fa'
import { MdSupervisorAccount } from 'react-icons/md'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { AppState } from '../../redusers/config'
import { Init } from '../../redusers/user'
import { getLogin } from '../../utils/utils'
import AnimationText from '../AnimationText'
import MenuIcons from '../Icons/MenuIcons'
import './styles.scss'
import { FiExternalLink, FiMenu, FiArrowRight } from 'react-icons/fi'
import { useGetThemeMode } from 'state/theme/hook'
import { AudioItem, AudioMenu, tooltipMenu, tooltipSearch } from './tooltip'
import {
  CAPSLOCK_LOGIN_URL,
  Documentation,
  Fiat_integration,
  GAMEBLING_URL,
  Hashtag_Decentralized,
  HDNS_integration,
  MOONPAY_URL,
  Project_Requirements,
  Public_links,
  SOCIAL_URL,
} from 'constants/urls'
import { MODAL_TYPES, useGlobalModalContext } from 'components/Modals/GlobalModal'
import { BiBookmark, BiHide, BiShow } from 'react-icons/bi'
import { useIsShowTooltip } from 'state/toolTip/hook'
import { setIsShowTooltip } from 'state/toolTip/reducer'
import { AiOutlineAndroid, AiOutlineDashboard } from 'react-icons/ai'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { getListTransactionForAdm } from 'actions/transaction'
import { BsEye, BsFileEarmarkText } from 'react-icons/bs'
import styled from 'styled-components'
import Modal from 'components/Modals/Modal'
import GuideImg from '../../assets/bg/guide.jpeg'
import { useProfile } from 'views/UserProfile/hooks'
import Avatar from 'react-avatar'
import { Init as HashtagInit } from '../../redusers/actions'
import { setIsClickAvatar } from 'state/avatar/reducer'
import { useIsClickAvatar } from 'state/avatar/hook'
import { getHashtagList } from 'actions/hashtag'
import FreeVPN from 'components/Header/VPN'
import PlanetIcon from 'components/PlanetIcon'
import { setIsCollapseMenu } from 'state/collapseMenu/reducer'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  padding: 0;
  width: 100%;
`

interface IMenu {
  dropdownLeft?: boolean
  getListTransactionForAdm: Function
}

const Menu: React.FC<IMenu> = ({ dropdownLeft, getListTransactionForAdm }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { state, onChangeFile, onChangeText, onSubmit } = useProfile()
  const themeMode = useGetThemeMode()
  const isShowTooltip = useIsShowTooltip()
  const isCollapseMenu = useIsCollapseMenu()
  const isLogIn = useSelector<AppState, Init['isLogin']>(({ user }) => user.get('isLogin'))
  const isAdmin = useSelector<AppState, Init['isAdmin']>(({ user }) => user.get('isAdmin'), shallowEqual)
  const userInfo = useSelector<AppState, Init['userInfo']>(({ user }) => user.get('userInfo'), shallowEqual)
  const hashtagList = useSelector<AppState, HashtagInit['hashtagList']>(({ actions }) => actions.get('hashtagList'))
  const countNotification = useSelector<AppState, number>(({ transaction }) => transaction.get('TRWithdrawCountForAdm'))
  const listTransactionCountForAdm = useSelector<AppState, number>(({ transaction }) =>
    transaction.get('listTransactionCountForAdm')
  )
  // const countNotification = 3
  const filterText = useSelector<AppState, string>(({ transaction }) => transaction.get('listTRFilterForAdmin'))
  const { showModal } = useGlobalModalContext()

  const [flag, setFlag] = useState<boolean>(false)
  const [hideMenu, setHideMenu] = useState<boolean>(false)
  const [hideMenuItems, setHideMenuItems] = useState<boolean>(false)
  const [showGuide, setShowGuide] = useState<boolean>(false)
  const [leftpx, setLeftpx] = useState<string>('')
  const [defaultDomainName, setDefaultDomainName] = useState<string>('')
  const [hashtagCount, setHashtagCount] = useState<number>(0)

  const url = new URL(window.location.href)

  if (!isLogIn) {
    history.push('/login/')
  }

  const onLogOutCallback = useCallback(() => {
    AudioMenu('logout').play()
    dispatch(onLogOut())
  }, [dispatch])

  const onDeleteCallback = useCallback(() => {
    // dispatch(onLogOut())
  }, [dispatch])

  const onChangeSideMenuCallback = () => {
    const ws = window.screen.availWidth
    if (ws >= 768) {
      setHideMenuItems(false)
      setHideMenu(!hideMenu)
      dispatch(setIsCollapseMenu(!isCollapseMenu))
    } else {
      setHideMenuItems(!hideMenuItems)
    }
  }

  // TODO
  const onVideoCallback = () => {
    AudioMenu('dream').play()
    // const id = userInfo.nick
    const id = defaultDomainName
    const url = ''
    const flag = 0
    showModal(MODAL_TYPES.VIDEO_CONFIRM_MODAL, { id, url, flag })
  }
  const onOpenVideo = () => {
    AudioMenu('dream').play()
    // const id = userInfo.nick
    const id = defaultDomainName
    const url = ''
    const flag = 1
    showModal(MODAL_TYPES.VIDEO_CONFIRM_MODAL, { id, url, flag })
  }

  const playAudio = (page: string) => {
    AudioMenu(page).play()
    const ws = window.screen.availWidth
    if (ws < 768) {
      setHideMenuItems(true)
    }
  }

  const openPage = (page: string) => {
    history.push(`/admin/${page}/`)
  }

  const handleUserAvatar = () => {
    dispatch(setIsClickAvatar(true))
  }
  const handleCommand = () => {
    dispatch(setIsClickAvatar(false))
  }

  const onShowTooltip = () => {
    localStorage.setItem('tooltipKiller', '' + !isShowTooltip)
    dispatch(setIsShowTooltip(!isShowTooltip))
  }

  const openModal = () => {
    setShowGuide(true)
  }

  const closeModal = () => {
    setShowGuide(false)
  }

  useEffect(() => {
    setFlag(true)
    setHideMenu(false)
    const ws = window.screen.availWidth
    if (ws >= 768) {
      setHideMenuItems(false)
    } else {
      setHideMenuItems(true)
    }
    return () => {
      setFlag(false)
    }
  }, [])

  useEffect(() => {
    if (isAdmin !== 'YES') return
    if (!isLogIn) return

    const idInterval = setInterval(() => {
      getListTransactionForAdm({
        search: filterText,
        limit: listTransactionCountForAdm,
        isClear: true,
      })

      if (countNotification > 0) AudioItem('wait_confirm').play()
    }, 15000)

    return () => {
      clearInterval(idInterval)
    }
  }, [countNotification, isAdmin, filterText, isLogIn, getListTransactionForAdm])

  useEffect(() => {
    let left = ''
    if (dropdownLeft) {
      if (hideMenu) left = '200px'
      else left = '10px'
    }
    setLeftpx(left)
  }, [dropdownLeft, hideMenu])

  useEffect(() => {
    if (hashtagList.length === 0) dispatch(getHashtagList({ search: '', limit: 0, isClear: true }))
    setHashtagCount(hashtagList.length)

    const list = hashtagList.filter((v) => v.is_default_domain === 1)
    if (list.length > 0) setDefaultDomainName(list[0].id)
  }, [dispatch, hashtagList])

  return (
    <nav
      className={themeMode == 'main' ? cn('menu') : cn('menu-l')}
      style={{
        width: hideMenu ? '70px' : '',
        minWidth: hideMenu ? '' : '',
        left: leftpx,
      }}
    >
      <div
        className="menu_header"
        style={{
          background: themeMode === 'main' ? 'black' : 'white',
          color: themeMode === 'main' ? 'white' : 'black',
        }}
      >
        {hideMenu ? (
          <FiMenu size={25} className="headerIcon" onClick={onChangeSideMenuCallback} />
        ) : (
          <>
            <div className="icon-div">
              {/* {isAdmin === 'YES' && ( */}
              <>
                <span
                  className="icon-span"
                  data-tip={tooltipSearch.adminTransaction}
                  data-for="main-search-tip"
                  onClick={() => openPage('listTransaction')}
                >
                  <IoMdNotificationsOutline fontSize={20} color={themeMode == 'light' ? 'black' : 'white'} />
                  {countNotification > 0 && <div className={'withdrawReqCount'}>{countNotification}</div>}
                </span>
                <span
                  className="icon-span"
                  data-tip={tooltipSearch.adminDashBoard}
                  data-for="main-search-tip"
                  onClick={() => openPage('hastagList')}
                >
                  <AiOutlineDashboard fontSize={20} color={themeMode == 'light' ? 'black' : 'white'} />
                </span>
                <span
                  className="icon-span"
                  data-tip={tooltipSearch.menuEyeIcon}
                  data-for="main-search-tip"
                  onClick={onShowTooltip}
                >
                  {isShowTooltip ? (
                    <BiShow size={20} color={themeMode == 'light' ? 'black' : 'white'} />
                  ) : (
                    <BiHide size={20} color={themeMode == 'light' ? 'black' : 'white'} />
                  )}
                </span>
                <span
                  className="icon-span"
                  data-tip={tooltipSearch.androidIcon}
                  data-for="main-search-tip"
                  onClick={openModal}
                >
                  <AiOutlineAndroid fontSize={20} color={themeMode == 'light' ? 'black' : 'white'} />
                </span>
                <FreeVPN />
              </>
              {/* )} */}
            </div>
            <FiMenu size={25} className="headerIcon" onClick={onChangeSideMenuCallback} />
          </>
        )}
      </div>

      <div className="menuItems" style={{ display: hideMenuItems ? 'none' : '' }}>
        <div className={themeMode == 'main' ? cn('menubg') : cn('menubg-l')}>
          <div
            className="sub-menu-title"
            style={{
              background: themeMode === 'main' ? '#000' : '#fff',
              color: themeMode === 'main' ? '#fff' : '#000',
            }}
          >
            Web &sup3;
          </div>
          <NavLink
            to="/domainsresult/"
            onClick={() => playAudio('command')}
            data-tip={tooltipMenu.command}
            data-for="menu-tip"
          >
            <span className="planet_icon" onClick={handleUserAvatar}>
              <PlanetIcon seed={defaultDomainName} size={50} themeMode={themeMode} />
            </span>
            {!hideMenu && (
              <div className="menu_text" style={{ marginLeft: '19px' }} onClick={handleCommand}>
                {url.pathname === '/domainsresult/' ? (
                  <AnimationText text="COMMAND" themeMode={themeMode} />
                ) : (
                  <span className="menu_text_primary">COMMAND</span>
                )}
                <span className="menu_text_secondary">
                  {defaultDomainName === '' ? '#HASHTAGSPACE' : defaultDomainName.toUpperCase()}
                </span>
              </div>
            )}
          </NavLink>
          <NavLink
            to="/domainsdashboard/"
            onClick={() => playAudio('control')}
            data-tip={tooltipMenu.control}
            data-for="menu-tip"
          >
            <MenuIcons
              url="hashtag3-white"
              showAnimation={url.pathname === '/domainsdashboard/'}
              themeMode={themeMode}
            />
            {!hideMenu && (
              <>
                <div className="menu_text">
                  {url.pathname === '/domainsdashboard/' ? (
                    <AnimationText text="CONTROL" themeMode={themeMode} />
                  ) : (
                    <span className="menu_text_primary">CONTROL</span>
                  )}
                  <span className="menu_text_secondary">WEB3 DOMAINS</span>
                </div>
                <div className="control_num">
                  <span>{hashtagCount}</span>
                  <FaChevronRight className="icon-span" size={12} />
                </div>
              </>
            )}
          </NavLink>
          <NavLink
            exact
            to="/search"
            onClick={() => playAudio('option')}
            data-tip={tooltipMenu.option}
            data-for="menu-tip"
          >
            <MenuIcons url="search-white" showAnimation={url.pathname === '/search'} themeMode={themeMode} />
            {!hideMenu && (
              <div className="menu_text">
                {url.pathname === '/search' ? (
                  <AnimationText text="OPTION" themeMode={themeMode} />
                ) : (
                  <span className="menu_text_primary">OPTION</span>
                )}
                <span className="menu_text_secondary">WEB3 SEARCH</span>
              </div>
            )}
          </NavLink>
          <NavLink
            exact
            to="/Affiliates"
            onClick={() => playAudio('return')}
            data-tip={tooltipMenu.return}
            data-for="menu-tip"
          >
            <MenuIcons url="affiliate" showAnimation={url.pathname === '/Affiliates'} themeMode={themeMode} />
            {!hideMenu && (
              <div className="menu_text">
                {url.pathname === '/Affiliates' ? (
                  <AnimationText text="RETURN" themeMode={themeMode} />
                ) : (
                  <span className="menu_text_primary">RETURN</span>
                )}
                <span className="menu_text_secondary">AFFILIATE</span>
              </div>
            )}
          </NavLink>
          <NavLink to="/pay/" onClick={() => playAudio('shift')} data-tip={tooltipMenu.shift} data-for="menu-tip">
            <MenuIcons url="thebridge-white" showAnimation={url.pathname === '/pay/'} themeMode={themeMode} />
            {!hideMenu && (
              <div className="menu_text">
                {url.pathname === '/pay/' ? (
                  <AnimationText text="SHIFT" themeMode={themeMode} />
                ) : (
                  <span className="menu_text_primary">SHIFT</span>
                )}
                <span className="menu_text_secondary">#bling</span>
              </div>
            )}
          </NavLink>
        </div>

        <div className={themeMode == 'main' ? cn('menubg') : cn('menubg-l')}>
          <div
            className="sub-menu-title m-top"
            style={{
              background: themeMode === 'main' ? '#000' : '#fff',
              color: themeMode === 'main' ? '#fff' : '#000',
            }}
          >
            Esports &sup3;
          </div>
          <a
            href={GAMEBLING_URL + 'dashboard'}
            onClick={() => playAudio('enter')}
            data-tip={tooltipMenu.enter}
            data-for="menu-tip"
          >
            <MenuIcons url="gamblingicon" showAnimation={url.pathname === '/enter'} themeMode={themeMode} />
            {!hideMenu && (
              <div className="menu_text">
                {url.pathname === '/enter' ? (
                  <AnimationText text="ENTER" themeMode={themeMode} />
                ) : (
                  <span className="menu_text_primary">ENTER</span>
                )}
                <span className="menu_text_secondary">GAMEBLING</span>
              </div>
            )}
          </a>
        </div>

        <div className={themeMode == 'main' ? cn('menubg') : cn('menubg-l')}>
          <div
            className="sub-menu-title m-top"
            style={{
              background: themeMode === 'main' ? '#000' : '#fff',
              color: themeMode === 'main' ? '#fff' : '#000',
            }}
          >
            Application &sup3;
          </div>
          <a data-tip={tooltipMenu.dream} data-for="menu-tip">
            <span className="dreamIcon" onClick={onVideoCallback}>
              <MenuIcons url="dreaming-white" themeMode={themeMode} />
            </span>
            {!hideMenu && (
              <>
                <div className="menu_text" onClick={onVideoCallback}>
                  <span className="menu_text_primary dreaming">~</span>
                  <span className="menu_text_secondary dreaming_secondary">DREAMING</span>
                </div>
                <div className="dream-extraIcon">
                  <FiExternalLink size={20} className="externalIcon" onClick={onOpenVideo} />
                </div>
              </>
            )}
          </a>
          <a
            href="/portfolio"
            target="_self"
            onClick={() => playAudio('tab')}
            data-tip={tooltipMenu.tab}
            data-for="menu-tip"
          >
            <MenuIcons url="portfolio-white" showAnimation={url.pathname === '/portfolio'} themeMode={themeMode} />
            {!hideMenu && (
              <>
                <div className="menu_text">
                  {url.pathname === '/portfolio' ? (
                    <AnimationText text="TAB" themeMode={themeMode} />
                  ) : (
                    <span className="menu_text_primary">TAB</span>
                  )}
                  <span className="menu_text_secondary">PORTFOLIO</span>
                </div>
                <a href="/portfolio" target="_blank">
                  <FiExternalLink size={20} className="externalIcon" />
                </a>
              </>
            )}
          </a>
          <a
            href={SOCIAL_URL}
            target="_self"
            onClick={() => playAudio('spacebar')}
            rel="referrer"
            data-tip={tooltipMenu.spacebar}
            data-for="menu-tip"
          >
            <MenuIcons url="social-white" themeMode={themeMode} />
            {!hideMenu && (
              <>
                <div className="menu_text">
                  <span className="menu_text_primary">SPACEBAR</span>
                  <span className="menu_text_secondary">SOCIAL</span>
                </div>
                <a href={SOCIAL_URL} target="_blank" rel="noreferrer">
                  <FiExternalLink size={20} className="externalIcon" />
                </a>
              </>
            )}
          </a>
          <a
            href={CAPSLOCK_LOGIN_URL}
            target="_self"
            rel="referrer"
            onClick={() => playAudio('capslock')}
            data-tip={tooltipMenu.capslock}
            data-for="menu-tip"
          >
            <MenuIcons url="dmail-white" themeMode={themeMode} />
            {!hideMenu && (
              <>
                <div className="menu_text">
                  <span className="menu_text_primary">CAPS LOCK</span>
                  <span className="menu_text_secondary">D-MAIL</span>
                </div>
                <a href={CAPSLOCK_LOGIN_URL} target="_blank" rel="noreferrer">
                  <FiExternalLink size={20} className="externalIcon" />
                </a>
              </>
            )}
          </a>
          <NavLink
            to="/marketspace"
            onClick={() => playAudio('backslash')}
            data-tip={tooltipMenu.backslash}
            data-for="menu-tip"
          >
            <MenuIcons url="marketspace" showAnimation={url.pathname === '/marketspace'} themeMode={themeMode} />
            {!hideMenu && (
              <div className="menu_text">
                {url.pathname === '/marketspace' ? (
                  <AnimationText text="BACKSLASH" themeMode={themeMode} />
                ) : (
                  <span className="menu_text_primary">BACKSLASH</span>
                )}
                <span className="menu_text_secondary">MARKETSPACE</span>
              </div>
            )}
          </NavLink>
        </div>

        <div className={themeMode == 'main' ? cn('menubg') : cn('menubg-l')}>
          <div
            className="sub-menu-title m-top"
            style={{
              background: themeMode === 'main' ? '#000' : '#fff',
              color: themeMode === 'main' ? '#fff' : '#000',
            }}
          >
            Documentation &sup3;
          </div>
          <div className="dropdown">
            <div className="dropdown__title" data-tip={tooltipMenu.function} data-for="menu-tip">
              <MenuIcons url="hashtag3-white" themeMode={themeMode} />
              {!hideMenu && (
                <>
                  <div className="menu_text">
                    <span className="menu_text_primary">FUNCTION</span>
                    <span className="menu_text_secondary">#SPACE</span>
                  </div>
                  <div className="dropdown__chevron">
                    <FaChevronRight size={12} />
                  </div>
                </>
              )}
            </div>

            <div className="dropdown__list">
              <a href={Public_links} onClick={() => playAudio('function')} target="_black">
                <BsFileEarmarkText /> Public links
              </a>
              <a href={HDNS_integration} onClick={() => playAudio('function')} target="_black">
                <BsFileEarmarkText /> HDNS integration Docu...
              </a>
              <a href={Documentation} onClick={() => playAudio('function')} target="_black">
                <BsFileEarmarkText /> Documentation
              </a>
              <a href={Project_Requirements} onClick={() => playAudio('function')} target="_black">
                <BsFileEarmarkText /> Project Requirements
              </a>
              <a href={Hashtag_Decentralized} onClick={() => playAudio('function')} target="_black">
                <BsFileEarmarkText /> Hashtag Decentralized...
              </a>
              <a href={Fiat_integration} onClick={() => playAudio('function')} target="_black">
                <BsFileEarmarkText /> Fiat integration Requ...
              </a>
            </div>
          </div>
        </div>

        <div className={themeMode == 'main' ? cn('menubg') : cn('menubg-l')}>
          <div
            className="sub-menu-title m-top"
            style={{
              background: themeMode === 'main' ? '#000' : '#fff',
              color: themeMode === 'main' ? '#fff' : '#000',
            }}
          >
            Eject &sup3;
          </div>
          <NavLink to="/delete" onClick={() => playAudio('tab')} data-tip={tooltipMenu.delete} data-for="menu-tip">
            <MenuIcons url="delete" showAnimation={url.pathname === '/delete'} themeMode={themeMode} />
            {!hideMenu && (
              <div className="menu_text">
                {url.pathname === '/delete' ? (
                  <AnimationText text="DELETE" themeMode={themeMode} />
                ) : (
                  <span className="menu_text_primary">DELETE</span>
                )}
                <span className="menu_text_secondary">ERASE</span>
              </div>
            )}
          </NavLink>
          <a href="#" onClick={onLogOutCallback}>
            <MenuIcons url="logout" themeMode={themeMode} />
            {!hideMenu && (
              <div className="menu_text">
                <span className="menu_text_primary">ESC</span>
                <span className="menu_text_secondary">LOGOUT</span>
              </div>
            )}
          </a>
        </div>

        <div className={themeMode == 'main' ? cn('menubg') : cn('menubg-l')}>
          <div
            className="sub-menu-title m-top"
            style={{
              background: themeMode === 'main' ? '#000' : '#fff',
              color: themeMode === 'main' ? '#fff' : '#000',
            }}
          >
            Administrator &sup3;
          </div>
          {isAdmin === 'YES' && (
            <div className="dropdown">
              <div className="dropdown__title">
                <FaLevelDownAlt />
                {!hideMenu && (
                  <>
                    {url.pathname === '/admin/listTransaction/' ||
                    url.pathname === '/admin/hastagList/' ||
                    url.pathname === '/admin/accounts/' ||
                    url.pathname === '/admin/categorylist/' ||
                    url.pathname === '/admin/setMetaTags/' ||
                    url.pathname === '/admin/changeInfo/' ||
                    url.pathname === '/admin/settingEmail/' ||
                    url.pathname === '/admin/settings/' ||
                    url.pathname === '/admin/notification-settings/' ? (
                      <AnimationText text="ADMIN" themeMode={themeMode} />
                    ) : (
                      <span className="menu_text_primary">ADMIN</span>
                    )}
                    <div className="dropdown__chevron">
                      <FaChevronRight size={12} />
                    </div>
                  </>
                )}
              </div>

              <div className="dropdown__admin">
                <NavLink to="/admin/listTransaction/" onClick={() => playAudio('tab')}>
                  <FaMoneyCheckAlt />
                  TRANSACTIONS
                </NavLink>
                <NavLink to="/admin/hastagList/" onClick={() => playAudio('tab')}>
                  <FaHashtag />
                  DASHBOARD
                </NavLink>
                <NavLink to="/admin/accounts/" onClick={() => playAudio('tab')}>
                  <MdSupervisorAccount />
                  ACCOUNTS
                </NavLink>
                <NavLink to="/admin/categorylist/" onClick={() => playAudio('tab')}>
                  <FaTable />
                  CATEGORIES
                </NavLink>
                <NavLink to="/admin/setMetaTags/" onClick={() => playAudio('tab')}>
                  <FaCode />
                  META TAGS
                </NavLink>
                <NavLink to="/admin/changeInfo/" onClick={() => playAudio('tab')}>
                  <FaRegListAlt />
                  SITE PAGES
                </NavLink>
                <NavLink to="/admin/settingEmail/" onClick={() => playAudio('tab')}>
                  <FaRegEdit />
                  EMAIL SETTINGS
                </NavLink>
                <NavLink to="/admin/settings/" onClick={() => playAudio('tab')}>
                  <FaTable />
                  ADMIN SETTINGS
                </NavLink>
                <NavLink to="/admin/notification-settings/" onClick={() => playAudio('tab')}>
                  <FaRegEdit />
                  NOTIFICATION SETTINGS
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactTooltip
        id="menu-tip"
        effect="solid"
        type={themeMode === 'main' ? 'info' : themeMode === 'light' ? 'dark' : 'light'}
        className="tooltip-menu"
        disable={isShowTooltip ? false : true}
      />
      <ReactTooltip
        id="main-search-tip"
        effect="solid"
        type={themeMode === 'light' ? 'dark' : 'info'}
        className="tooltip-search"
        disable={isShowTooltip ? false : true}
      />
      <Modal
        isOpen={showGuide}
        onDismiss={closeModal}
        minHeight={false}
        maxHeight={90}
        overflow={true}
        width={90}
        zoom={true}
      >
        <Wrapper>
          <img src={GuideImg} className="portfolioImg" alt="" />
        </Wrapper>
      </Modal>
    </nav>
  )
}

// export default Menu
function mapStateToProps({ transaction, user }: AppState) {
  return {
    balance: transaction.get('balance'),
    userInfo: user.get('userInfo'),
  }
}

export default connect(mapStateToProps, {
  getListTransactionForAdm,
})(Menu)
