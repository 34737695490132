import React from 'react'
import classes from 'classnames'
import cn from './cn.module.css'

type OnChange = (e: React.FormEvent<HTMLInputElement>) => void
type OnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => void

interface FormInputP {
  error?: boolean
  social?: string
  label?: string
  value?: string
  onChange?: OnChange
  type?: string
  step?: number | undefined
  readOnly?: boolean
  onKeyPress?: OnKeyPress
  placeholder?: string
  className?: string
  maxLength?: number
  isFocusStyle?: boolean
  border?: string
  paddingRight?: string
  paddingLeft?: string
  labelHeight?: string
  themeMode?: string
}
const FormInput: React.FC<FormInputP> = ({
  type = 'text',
  error = false,
  social = '',
  label = '',
  value = '',
  placeholder = '',
  onChange = (e: React.FormEvent<HTMLInputElement>) => {},
  step,
  readOnly,
  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {},
  className = '',
  maxLength = 250,
  isFocusStyle = false,
  border = '',
  paddingRight = '',
  paddingLeft = '',
  labelHeight = '',
  themeMode,
}) => {
  return (
    <div
      className={
        cn.input +
        ' ' +
        classes(error ? cn.error : '') +
        ' ' +
        classes({ social: social }) +
        ' ' +
        classes(readOnly ? cn.readOnly : '') +
        ' ' +
        classes(isFocusStyle ? cn.isFocusStyle : '') +
        ' ' +
        className
      }
    >
      <label
        style={{
          color: themeMode == 'main' ? 'white' : 'black',
          height: labelHeight,
        }}
      >
        {label}
      </label>
      <input
        readOnly={readOnly}
        value={value || ''}
        type={type}
        onChange={onChange}
        onKeyPress={onKeyPress}
        step={step}
        placeholder={placeholder}
        maxLength={maxLength}
        autoComplete="new-password"
        style={{
          background: themeMode == 'main' ? '#00000090' : '#ffffff90',
          color: themeMode == 'main' ? 'white' : 'black',
          border: border,
          paddingRight: paddingRight,
          paddingLeft: paddingLeft,
        }}
      />
    </div>
  )
}
export default FormInput
