import { GradientButton } from 'components/Buttons/GradientButton'
import React from 'react'
import {
  BigValueText,
  FlexRow,
  FlexRowContent,
  HorizontalDivider,
  SubContainer,
  SubContainerBody,
  SubTitleText,
} from '../../styled'
import { FaRegStopCircle, FaRegCheckCircle, FaExclamationCircle } from 'react-icons/fa'
import { IBridgeStatusProps } from '../../types'
import { useGetThemeMode } from 'state/theme/hook'

const StatusIcon = {
  Pending: <FaRegStopCircle color={'#ee092f'} />,
  Process: <FaExclamationCircle color={'#0093fc'} />,
  Success: <FaRegCheckCircle color={'#2ec814'} />,
}

export const MHCBuying: React.FC = () => {
  const mhcBuyingList: IBridgeStatusProps[] = [
    { id: 1, date: 'Jan 29, 2021 | 19:32', amount: 12345, status: 'Pending' },
    {
      id: 2,
      date: 'Jan 29, 2021 | 19:32',
      amount: 12345.06,
      status: 'Process',
    },
    { id: 3, date: 'Jan 29, 2021 | 19:32', amount: 12345, status: 'Success' },
  ]

  const themeMode = useGetThemeMode()

  return (
    <SubContainer width={'40%'} height={'200px'} themeMode={themeMode}>
      <FlexRowContent>
        <SubTitleText fontSize={'12px'}>MHC BUYING</SubTitleText>
        <GradientButton width={'100px'} height={'32px'} margin={'-8px 0 0'}>
          Buy MHC
        </GradientButton>
      </FlexRowContent>
      <HorizontalDivider />
      <SubContainerBody>
        {mhcBuyingList.map((item) => {
          return (
            <FlexRowContent key={item.id} margin={'8px 0'}>
              <BigValueText color={'#919599'} fontSize={'14px'}>
                {item.date}
              </BigValueText>
              <BigValueText color={'#ffffff'} fontSize={'14px'}>{`${item.amount} MHC`}</BigValueText>
              <BigValueText color={'#919599'} fontSize={'14px'}>
                {item.status} &nbsp; {StatusIcon[item.status]}
              </BigValueText>
            </FlexRowContent>
          )
        })}
      </SubContainerBody>
    </SubContainer>
  )
}
