import React, { useState } from 'react'
import styled from 'styled-components'
import { SwitchButtonOptions } from '../../types'

const SwitchButtonContainer = styled.div`
  margin: 0 12px;
`
const ItemButton = styled.span<{ isActivate: boolean }>`
  font-size: 12px;
  font-weight: 400;
  color: ${({ isActivate }) => (isActivate ? '#FFFFFF' : '#919599')};
  text-decoration: ${({ isActivate }) => (isActivate ? 'underline' : 'none')};
  cursor: pointer;
  padding: 4px 8px;
`
const VerticalDivider = styled.span`
  color: #919599;
`

const SwitchButton: React.FC = () => {
  const [switchOptions, setSwitchOptions] = useState<SwitchButtonOptions>('new')
  const switchOption = (option: SwitchButtonOptions) => {
    setSwitchOptions(option)
  }

  return (
    <SwitchButtonContainer>
      <ItemButton isActivate={switchOptions === 'new'} onClick={() => switchOption('new')}>
        New
      </ItemButton>
      <VerticalDivider>|</VerticalDivider>
      <ItemButton isActivate={switchOptions === 'recurring'} onClick={() => switchOption('recurring')}>
        Recurring
      </ItemButton>
    </SwitchButtonContainer>
  )
}

export default SwitchButton
