import MenuIcons from 'components/Icons/MenuIcons'
import React, { useEffect, useState } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { IAssetsTable, ICreateAssets } from 'views/Portfolio/types'
import { getPortfoiliList } from 'views/Portfolio/actions'
import { useGetAssetData, useGetCurrentBalanceStatus } from 'views/Portfolio/hooks'
import { createAssetsList } from '../../views/Portfolio/actions'
import GreenPercent from 'views/Portfolio/components/GreenPercent'
import RedPercent from 'views/Portfolio/components/RedPercent'
import { AiOutlineDollarCircle } from 'react-icons/ai'
import Loader from 'components/Loader'
import { useMenuAudio } from 'components/DomainsDashboardTable/hooks'

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 280px;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid;
  font-size: 14px;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
  }
`

interface IRow {
  row: ICreateAssets['asset']
}
const Row: React.FC<IRow> = ({ row }) => {
  const dispatch = useDispatch()
  const [isInitialRender, setIsInitialRender] = useState(true)
  const rowData = useGetAssetData(row)

  useEffect(() => {
    if (rowData && isInitialRender) {
      setIsInitialRender(false)
      const rowDataTemp = { ...rowData }
      dispatch(createAssetsList({ ...rowDataTemp }))
    }
  }, [rowData])

  let noIcon = (rowData as IAssetsTable).usdPrice == 0 ? true : false
  return (
    <>
      {rowData && (
        <tr className="tab-tr">
          <td className="width10">
            {noIcon ? (
              <AiOutlineDollarCircle size={25} color={'green'} />
            ) : (
              <img src={rowData.logo} className="tab-logo-img" alt="" />
            )}
          </td>
          <td className="width30">
            <span className="asset-title">{rowData.symbol}</span>
          </td>
          <td className="width30">
            {rowData.isPositive ? (
              <GreenPercent percent={rowData.dailyChangePercent} />
            ) : (
              <RedPercent percent={rowData.dailyChangePercent} />
            )}
          </td>
          <td className="width30">
            <span className="asset-supply">${rowData.usdAmount}</span>
          </td>
        </tr>
      )}
    </>
  )
}

const CommandTab = () => {
  const dispatch = useDispatch()
  const portfolioList = useSelector<AppState, ICreateAssets['asset'][]>(({ portfolio }) =>
    portfolio.get('listPortfolio')
  )
  useEffect(() => {
    dispatch(getPortfoiliList())
  }, [])
  const assetsList = useSelector<AppState, IAssetsTable[]>(({ portfolio }) => portfolio.get('listAssets'))
  const balanceStatus = useGetCurrentBalanceStatus(assetsList)

  const themeMode = useGetThemeMode()
  const { playAudio } = useMenuAudio()
  const filterList = portfolioList.filter((item: any) => Number(item.remove) == 0)

  return (
    <Container
      style={{
        background: themeMode == 'main' ? '#00000090' : '#ffffff90',
        borderColor: themeMode == 'main' ? '#02569d' : 'gray',
      }}
    >
      <div className="top-item">
        <div className="top-left">
          <MenuIcons url="portfolio-white" themeMode={themeMode} size={'18px'} />
          <h3 style={{ marginLeft: '-20px' }}>
            <NavLink
              to="/portfolio"
              onClick={() => playAudio('tab')}
              style={{ color: themeMode === 'main' ? 'white' : 'black' }}
            >
              TAB
            </NavLink>
          </h3>
        </div>
        <NavLink to="/portfolio" onClick={() => playAudio('tab')} target={'_self'}>
          <FiExternalLink size={20} color={themeMode === 'light' ? '#000' : '#fff'} />
        </NavLink>
      </div>
      <div className="tab-bottom-item">
        <div className="">
          <span className="gray-font">CURRENT BALANCE</span>
          <div className="balance-div">
            <h2>${balanceStatus.totalBalance > 0 ? balanceStatus.totalBalance.toFixed(6) : '------'}</h2>
            <span style={{ color: '#12d48c' }}>
              {balanceStatus.profitAmount < 0 ? '-' : '+'} $
              {balanceStatus.profitAmount !== 0 ? Math.abs(balanceStatus.profitAmount).toFixed(6) : '------'} (24H)
            </span>
          </div>
        </div>
        <div className="gray-font">ASSETS</div>
        {filterList.length > 0 ? (
          <div className="tab-table-div">
            <table
              className="tab-table"
              cellSpacing="0"
              cellPadding="10"
              width="100%"
              style={{
                background:
                  themeMode == 'main' ? 'rgba(0, 0, 0, 0.5)' : themeMode == 'light' ? 'white' : 'rgba(0, 0, 0, 0.5)',
                color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
              }}
            >
              <tbody>
                {filterList.map((item, index) => {
                  let keyVal = item.id + '-' + index
                  return <Row key={keyVal} row={item} />
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <span className="tab-loader-spinner">
            <Loader stroke="green" size="30px" />
          </span>
        )}
      </div>
    </Container>
  )
}
export default CommandTab
