import React from 'react'
import { NavLink } from 'react-router-dom'
import { Tooltip } from 'react-tippy'

import { AiFillStar, AiOutlineStar } from 'react-icons/ai'

import cn from './cn.module.scss'

interface IStarConfig {
  size: string
  isFilled: boolean
  link?: string
  toolTipText?: string
}

export const Star: React.FC<IStarConfig> = ({ size, isFilled, link, toolTipText }) => {
  return (
    <div className={cn.starContainer} style={{ height: size }}>
      {isFilled && (
        <NavLink to={link ? '/profile/' : '#'} style={{ fontSize: size }}>
          <AiFillStar />
        </NavLink>
      )}
      {!isFilled && (
        <NavLink to={link ? '/profile/' : '#'} style={{ fontSize: size }}>
          <AiOutlineStar />
        </NavLink>
      )}
    </div>
  )
}
