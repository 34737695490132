import React, { useEffect } from 'react'
import MainContent from '../../components/MainContent'
import MainSearch from '../../components/MainSearch'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'

const StartPage: React.FC<any> = () => {
  const history = useHistory()
  const isLogIn = useSelector<AppState, Init['isLogin']>(({ user }) => user.get('isLogin'))
  if (isLogIn) {
    history.push('/domainsresult')
  }

  const search = useLocation().search

  useEffect(() => {
    const url = new URLSearchParams(search)

    const referralLink = {
      ref: url.get('ref'),
      cryptoRef: url.get('cryptoRef'),
      cryptoType: url.get('cryptoType'),
      userID: Number(url.get('userID')),
    }

    if (referralLink.ref) {
      window.localStorage.setItem('referralLink', JSON.stringify(referralLink))
    }
  }, [search])

  return (
    <MainContent cn__maincontent_startpage="cn__maincontent_startpage">
      <MainSearch />
    </MainContent>
  )
}

export default StartPage
