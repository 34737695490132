import React, { ChangeEvent, useEffect, useState } from 'react'
import { Star } from '../Icons/Star'

import {
  Root,
  AutoCompleteContainer,
  Input,
  AutoCompleteItem,
  AutoCompleteItemButton,
  NickName,
  LevelStar,
} from './styles'

interface IDataList {
  nick: string
  paypalAddress: string
  mhcAddress: string
  ethAddress: string
  bnbAddress: string
}

interface autoCompleteProps {
  iconColor?: string
  inputStyle?: React.CSSProperties
  optionsStyle?: React.CSSProperties
  nameValue?: string
  themeMode?: string

  data: IDataList[]
  onChange: (e: string) => void
}

export const AutoComplete: React.FC<autoCompleteProps> = ({
  iconColor,
  inputStyle,
  optionsStyle,
  data,
  onChange,
  nameValue,
  themeMode,
}) => {
  const [search, setSearch] = useState({
    text: nameValue,
    suggestions: [
      {
        nick: '',
        paypalAddress: '',
        mhcAddress: '',
        ethAddress: '',
        bnbAddress: '',
      },
    ],
  })

  const [isComponentVisible, setIsComponentVisible] = useState(false)

  const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const prefix = '#'

    let suggestions: IDataList[] = []

    if (value.length > 0) {
      suggestions = data.sort().filter((v: IDataList) => v.nick.toLowerCase().includes(value.toLowerCase()))
      onChange(value)
    }
    if (suggestions.length > 0) {
      setIsComponentVisible(true)
    }
    if (value.substr(0, 1) !== '#') {
      setSearch({ suggestions, text: prefix + value })
      return
    }

    if (value.includes(prefix) && value.substr(prefix.length) === '') {
      return setSearch({ suggestions, text: '' })
    }

    setSearch({ suggestions, text: value })
  }

  const suggestionSelected = (value: IDataList) => {
    setIsComponentVisible(false)

    onChange(value.nick)

    setSearch({
      text: value.nick,
      suggestions: [],
    })
  }
  const { suggestions } = search

  useEffect(() => {
    if (nameValue === '') {
      setSearch({ suggestions, text: '' })
    }
  }, [nameValue])

  return (
    <Root>
      <div
        onClick={() => setIsComponentVisible(false)}
        style={{
          display: isComponentVisible ? 'block' : 'none',
          width: '200vw',
          height: '200vh',
          backgroundColor: 'transparent',
          position: 'fixed',
          zIndex: 0,
          top: 0,
          left: 0,
        }}
      />
      <div>
        <Input
          id="input"
          autoComplete="off"
          value={search.text}
          onChange={onTextChanged}
          type={'text'}
          placeholder={'#NickName'}
          style={{
            background: themeMode == 'main' ? 'black' : 'white',
            color: themeMode == 'main' ? 'white' : 'black',
            borderRadius: '5px',
            borderColor: themeMode == 'main' ? 'black' : 'white',
          }}
        />
      </div>
      {suggestions.length > 0 && isComponentVisible && (
        <AutoCompleteContainer
          style={{
            background: themeMode === 'main' ? 'black' : 'white',
            color: themeMode === 'main' ? 'white' : 'black',
          }}
        >
          {suggestions.map((item: IDataList, index: number) => (
            <AutoCompleteItem key={index}>
              <AutoCompleteItemButton
                key={index}
                onClick={() => suggestionSelected(item)}
                style={{
                  color: themeMode === 'main' ? 'white' : 'black',
                }}
              >
                <NickName>{item.nick}</NickName>
                <LevelStar>
                  <Star size={'19px'} isFilled={item.nick?.length > 0} />
                  <Star size={'19px'} isFilled={item.paypalAddress?.length > 0} />
                  <Star size={'19px'} isFilled={item.mhcAddress?.length > 0} />
                  <Star size={'19px'} isFilled={item.ethAddress?.length > 0} />
                  <Star size={'19px'} isFilled={item.bnbAddress?.length > 0} />
                </LevelStar>
              </AutoCompleteItemButton>
            </AutoCompleteItem>
          ))}
        </AutoCompleteContainer>
      )}
    </Root>
  )
}
