import React, { CSSProperties } from 'react'
import classes from 'classnames'
import cn from '../FormInput/cn.module.css'

interface FormSelectP {
  error?: boolean
  selinp?: string
  categoryselect?: boolean
  hashtagselect?: boolean
  label?: string
  option: Array<string[]>
  value?: string
  onChange?: (e: React.FormEvent<HTMLSelectElement>) => void
  defoltDescription?: string | undefined
  style?: CSSProperties
  themeMode?: string
}

const FormSelect: React.FC<FormSelectP> = ({
  error,
  selinp,
  categoryselect,
  hashtagselect,
  label,
  option,
  value = '',
  onChange,
  defoltDescription,
  style = {},
  themeMode,
}) => {
  return (
    <div
      style={style}
      className={`${cn.input} ${error ? cn.error : ''} ${hashtagselect ? cn.hashtagselect : ''} ${
        categoryselect ? cn.categoryselect : ''
      } ${classes({
        selinp: selinp,
      })}`}
    >
      <label>{label}</label>
      <div
        className={cn.select}
        style={{
          borderColor: themeMode == 'light' ? 'gray' : 'white',
        }}
      >
        <select
          value={value}
          onChange={onChange}
          style={{
            borderColor: themeMode == 'light' ? 'black' : 'white',
            background: themeMode == 'main' ? '#00000090' : '#ffffff90',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        >
          {defoltDescription && (
            <option value={''} disabled>
              {defoltDescription}
            </option>
          )}
          {option.map((item) => (
            <option key={item[0]} value={item[0]}>
              {item[1]}
            </option>
          ))}
        </select>
        <div className={themeMode == 'light' ? cn.selectArrow_l : cn.selectArrow} />
      </div>
    </div>
  )
}
export default FormSelect
