import { createSlice } from '@reduxjs/toolkit'

interface IState {
  showHeaderSearchResult: boolean
}

export const initialState: IState = {
  showHeaderSearchResult: false,
}

const headerSearchResultSlice = createSlice({
  name: 'headerSearchResult',
  initialState,
  reducers: {
    setShowHeaderSearchResult(state, action) {
      state.showHeaderSearchResult = action.payload
    },
  },
})

export const { setShowHeaderSearchResult } = headerSearchResultSlice.actions
export default headerSearchResultSlice.reducer
