import React, { useMemo, useState } from 'react'
import { FlexColumn, FlexRow, TitleText } from '../../styled'
import { INavigationListItem, INavigationListProps } from '../../types'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import styled from 'styled-components'

const NavigationArrowBtn = styled.span`
  width: 14px;
  height: 14px;
  padding: 1px;
  cursor: pointer;
  &:hover {
    color: #fdbe08;
  }
`

export const NavigationList: React.FC<INavigationListProps> = ({ list }) => {
  const [selected, setSelected] = useState<INavigationListItem>(list[0])

  useMemo(() => setSelected(list[0]), [list])

  const navigateList = (direction: 'up' | 'down') => {
    const selectedIdx = list.findIndex((item) => item === selected)
    if (direction === 'up' && selectedIdx === 0) return
    if (direction === 'down' && selectedIdx === list.length - 1) return

    setSelected(list[direction === 'up' ? selectedIdx - 1 : selectedIdx + 1])
  }

  return (
    <FlexRow>
      <TitleText>{selected?.name}</TitleText>
      <FlexColumn width={'auto'}>
        <NavigationArrowBtn onClick={() => navigateList('up')}>
          {list.findIndex((item) => item === selected) > 0 && <IoIosArrowUp size={18} />}
        </NavigationArrowBtn>
        <NavigationArrowBtn onClick={() => navigateList('down')}>
          {list.findIndex((item) => item === selected) < list.length - 1 && <IoIosArrowDown size={18} />}
        </NavigationArrowBtn>
      </FlexColumn>
    </FlexRow>
  )
}
