import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { AppState } from 'redusers/config'
import { onAccountGetList } from 'views/Admin/Accounts/actions'
import { Init } from 'views/Admin/Dashboard/redusers'
import {
  onDashboardGetBridgeEarning,
  onDashboardGetDomainAnalytics,
  onDashboardGetMonthlyRevenue,
  onDashboardGetPurchases,
} from '../action'
import { useDatePickerContext } from '../contexts/DatePickerContext'

export const useGetPurchases = () => {
  const { currentDate } = useDatePickerContext()
  const dispatch = useDispatch()

  const momorizedDispatch = useCallback(() => {
    dispatch(
      onDashboardGetPurchases({
        year: currentDate.year,
        month: currentDate.month + 1,
      })
    )
    dispatch(onAccountGetList())
    dispatch(
      onDashboardGetMonthlyRevenue({
        year: currentDate.year,
        month: currentDate.month + 1,
      })
    )
    dispatch(
      onDashboardGetDomainAnalytics({
        year: currentDate.year,
        month: currentDate.month + 1,
      })
    )
    dispatch(
      onDashboardGetBridgeEarning({
        year: currentDate.year,
        month: currentDate.month + 1,
      })
    )
  }, [currentDate, dispatch])

  useEffect(() => {
    momorizedDispatch()
  }, [momorizedDispatch])
}

export const useGetPurchaseLineChartConfig = () => {
  useGetPurchases()

  const purchasesList = useSelector<AppState, Init['purchasesList']>(
    ({ dashboard }) => dashboard.get('purchasesList'),
    shallowEqual
  )

  const purchaseLineChartConfig = useMemo(() => {
    if (purchasesList.length === 0) return LineChartConfig()
    const categories = [...new Set(purchasesList.map((item) => item.date))]
    const domainCountsList = categories.map((date) => {
      const temp = purchasesList.filter((item) => item.date === date && !item.isRenewed)
      const temp2 = purchasesList.filter((item) => item.date === date && item.isRenewed)
      return {
        new: temp.length > 0 ? temp[0].count : 0,
        recurring: temp2.length > 0 ? temp2[0].count : 0,
      }
    })

    return LineChartConfig(
      categories,
      [...domainCountsList.map((item) => item.new)],
      [...domainCountsList.map((item) => item.recurring)]
    )
  }, [purchasesList])

  return purchaseLineChartConfig
}

const LineChartConfig = (
  categories: string[] = [],
  newDomainData: number[] = [],
  recurringDomainData: number[] = []
) => {
  const state: any = {
    options: {
      chart: {
        id: 'purcharse-line',
        toolbar: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        categories: categories,
        labels: {
          show: false,
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        show: true,
        showAlways: false,
        showForNullSeries: true,
        forceNiceScale: true,
        labels: {
          formatter: (val: number) => {
            return val
          },
          style: {
            colors: ['#919599'],
          },
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.5,
          opacityTo: 0.1,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      tooltip: {
        followCursor: true,
        fillSeriesColor: false,
        theme: 'dark',
        style: {
          fontSize: '12px',
        },
        x: {
          show: true,
        },
        y: {
          formatter: undefined,
          title: {
            formatter: (seriesName: any) => seriesName,
          },
        },
      },
      noData: {
        text: 'No #Domains at that month',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined,
        },
      },
      legend: {
        labels: {
          colors: '#ffffff',
        },
      },
    },
    series: [
      {
        name: 'new #Domains',
        data: newDomainData,
      },
      {
        name: 'recurring #Domains',
        data: recurringDomainData,
      },
    ],
  }
  return state
}
