import { SagaIterator } from 'redux-saga'
import { put, call, apply, CallEffect, all, SagaReturnType } from 'redux-saga/effects'
import { errorsM, Response } from '../../../utils/restReq'
import { checkErorr, getCookie, getToken } from '../../../sagas/helper'
import { TFetchUserData } from '../types'
import { fork } from '@redux-saga/core/effects'
import { TRInfoByNick, userGetInfoByNick } from '../../../utils/api'
import { string } from 'io-ts'
import { TItemR } from '../../../types'
import { TR_Cart } from '../../Cart/reduser'
import { TR_ViewProfile } from '../reduser'

export function* workerFetchUserData({ payload }: TFetchUserData) {
  try {
    yield put<TItemR<TR_ViewProfile, 'infoByNick'>>({
      type: 'infoByNick',
      payload: null,
    })

    const infoByNick: TRInfoByNick = yield call(userGetInfoByNick, {
      nick: payload,
    })
    yield put<TItemR<TR_ViewProfile, 'infoByNick'>>({
      type: 'infoByNick',
      payload: infoByNick,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}
