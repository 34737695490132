import { UseDomainsDashboard, useMenuAudio } from 'components/DomainsDashboardTable/hooks'
import MenuIcons from 'components/Icons/MenuIcons'
import React, { useState } from 'react'
import { BsCircleFill } from 'react-icons/bs'
import { FiExternalLink } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import { HashtagItem } from 'utils/api'
import './styles.scss'

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100px;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid;
  font-size: 14px;
  margin-right: 5px;
  margin-top: 10px;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
  }
`
const CommandControl = () => {
  const themeMode = useGetThemeMode()
  const { playAudio } = useMenuAudio()
  const { hashtagList, expiredList } = UseDomainsDashboard(10000)
  const expireCount = expiredList.length
  const activeCount = hashtagList.length - expireCount
  const inactiveFilterKey = 'url'
  const inactiveCount = hashtagList.filter((o) => inactiveFilterKey in o && o[inactiveFilterKey] == '').length

  let keywordCount = 0
  hashtagList.map((item: HashtagItem, index) => {
    const keywordArr = item.keywords
    if (keywordArr) {
      keywordCount = keywordCount + keywordArr.length
    }
    return keywordCount
  })

  return (
    <Container
      style={{
        background: themeMode == 'main' ? '#00000090' : '#ffffff90',
        borderColor: themeMode == 'main' ? '#02569d' : 'gray',
      }}
    >
      <div className="top-item">
        <div className="top-left">
          <MenuIcons url="domains-white" themeMode={themeMode} size={'18px'} />
          <h3 style={{ marginLeft: '-20px' }}>
            <NavLink
              to="/domainsdashboard/"
              onClick={() => playAudio('control')}
              style={{ color: themeMode === 'light' ? 'black' : 'white' }}
            >
              CONTROL
            </NavLink>
          </h3>
        </div>
        <NavLink to="/domainsdashboard/" onClick={() => playAudio('control')}>
          <FiExternalLink size={20} color={themeMode === 'light' ? '#000' : '#fff'} />
        </NavLink>
      </div>
      <div className="bottom-item">
        <div className="item-rect" style={{ background: themeMode == 'light' ? '#c2cad3' : '' }}>
          <div className="status-lay">
            <BsCircleFill size={10} color={'#2ec814'} />
            <h5 className="marginLeft">ACTIVE</h5>
          </div>
          <h2>{activeCount}</h2>
        </div>
        <div className="item-rect" style={{ background: themeMode == 'light' ? '#c2cad3' : '' }}>
          <div className="status-lay">
            <BsCircleFill size={10} color={'#c89714'} />
            <h5 className="marginLeft">INACTIVE</h5>
          </div>
          <h2>{inactiveCount}</h2>
        </div>
        <div className="item-rect" style={{ background: themeMode == 'light' ? '#c2cad3' : '' }}>
          <div className="status-lay">
            <BsCircleFill size={10} color={'#ff0d0d'} />
            <h5 className="marginLeft">EXPIRED</h5>
          </div>
          <h2>{expireCount}</h2>
        </div>
        <div className="item-rect" style={{ background: themeMode == 'light' ? '#c2cad3' : '' }}>
          <div className="status-lay">
            <BsCircleFill size={10} color={'#00c0ff'} />
            <h5 className="marginLeft">#KEYWORDS</h5>
          </div>
          <h2>{keywordCount}</h2>
        </div>
      </div>
    </Container>
  )
}
export default CommandControl
