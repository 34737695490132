import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { onAddInfo } from '../../../../actions/info'

export default function useArticle() {
  const dispatch = useDispatch()
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [error, setError] = useState<'' | 'description' | 'title'>('')

  const memoizedSetTitle = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setTitle(e.currentTarget.value)
    setError('')
  }, [])
  const memoizedDescription = useCallback((e: React.FormEvent<HTMLTextAreaElement>) => {
    setDescription(e.currentTarget.value)
    setError('')
  }, [])
  const memoizedSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (title.length < 3) {
        setError('title')
        return
      }
      if (description.length < 3) {
        setError('description')
        return
      }
      dispatch(onAddInfo({ title, description }))
    },
    [title, description, dispatch]
  )

  return {
    title,
    description,
    error,
    memoizedSetTitle,
    memoizedDescription,
    memoizedSubmit,
  }
}
