import { AudioItem } from 'components/Menu/tooltip'
import { SOCIAL_URL } from 'constants/urls'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { BsArrowRightShort } from 'react-icons/bs'

import {
  Root,
  AutoNickCompleteContainer,
  Input,
  AutoNickCompleteItem,
  AutoNickCompleteItemButton,
  NickName,
  LevelStar,
} from './styles'

interface IDataList {
  nick: string
  paypalAddress: string
  mhcAddress: string
}

interface autoNickCompleteProps {
  iconColor?: string
  inputStyle?: React.CSSProperties
  optionsStyle?: React.CSSProperties
  nameValue?: string
  themeMode?: string

  nickData: IDataList[]
  onChange: (e: string) => void
}

export const AutoNickComplete: React.FC<autoNickCompleteProps> = ({
  iconColor,
  inputStyle,
  optionsStyle,
  nickData,
  onChange,
  nameValue,
  themeMode,
}) => {
  const [search, setSearch] = useState({
    text: nameValue,
    suggestions: [{ nick: '', paypalAddress: '', mhcAddress: '' }],
  })

  const [isComponentVisible, setIsComponentVisible] = useState(false)

  const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const prefix = '#'

    let suggestions: IDataList[] = []

    if (value.length > 0) {
      suggestions = nickData.sort().filter((v: IDataList) => v.nick.toLowerCase().includes(value.toLowerCase()))
      onChange(value)
    }
    if (suggestions.length > 0) {
      setIsComponentVisible(true)
    }
    if (value.substr(0, 1) !== '#') {
      setSearch({ suggestions, text: prefix + value })
      return
    }

    if (value.includes(prefix) && value.substr(prefix.length) === '') {
      return setSearch({ suggestions, text: '' })
    }

    setSearch({ suggestions, text: value })
  }

  const suggestionSelected = (value: IDataList) => {
    setIsComponentVisible(false)

    onChange(value.nick)

    setSearch({
      text: value.nick,
      suggestions: [],
    })
  }
  const { suggestions } = search

  useEffect(() => {
    if (nameValue === '') {
      setSearch({ suggestions, text: '' })
    }
  }, [nameValue, suggestions])

  const goSpaceBar = (nick: string) => {
    AudioItem('btn-go').play()
    window.open(SOCIAL_URL + nick.substring(1), '_blank')
  }
  return (
    <Root>
      <div
        onClick={() => setIsComponentVisible(false)}
        className="input-div"
        style={{ display: isComponentVisible ? 'block' : 'none' }}
      />
      <div>
        <Input
          id="input"
          autoComplete="off"
          value={search.text}
          onChange={onTextChanged}
          type={'text'}
          placeholder={'#Profile'}
          style={{
            background: themeMode === 'main' ? 'black' : 'white',
            color: themeMode === 'main' ? 'white' : 'black',
            height: '40px',
          }}
          // style={{
          //   background: '#ffffff',
          //   color: 'black',
          //   height: '38px',
          // }}
        />
      </div>
      {suggestions.length > 0 && isComponentVisible && (
        <AutoNickCompleteContainer
          style={{
            background: themeMode === 'main' ? 'black' : 'white',
          }}
        >
          {suggestions.map((item: IDataList, index: number) => (
            <AutoNickCompleteItem key={index}>
              <AutoNickCompleteItemButton
                key={index}
                onClick={() => suggestionSelected(item)}
                style={{
                  color: themeMode === 'main' ? 'white' : 'black',
                }}
              >
                <NickName>{item.nick}</NickName>
                <LevelStar onClick={() => goSpaceBar(item.nick)}>
                  VISIT <BsArrowRightShort size={15} />
                </LevelStar>
              </AutoNickCompleteItemButton>
            </AutoNickCompleteItem>
          ))}
        </AutoNickCompleteContainer>
      )}
    </Root>
  )
}
