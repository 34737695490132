import '@reach/dialog/styles.css'
import { ConnectedRouter } from 'connected-react-router/immutable'
import React from 'react'
import { Provider } from 'react-redux'
import store, { history } from './redusers/config'
import * as serviceWorker from './serviceWorker'

export const Providers: React.FC = ({ children }) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>{children}</ConnectedRouter>
    </Provider>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
