import { TAddItem, TDelItem, TInitCart, TPayForCart } from './types'

export function addItemCart(item: TAddItem['payload']): TAddItem {
  return {
    type: 'CART_ADD_ITEM',
    payload: item,
  }
}
export function delItemCart(id: TDelItem['payload']): TDelItem {
  return {
    type: 'CART_DEL_ITEM',
    payload: id,
  }
}
export function initCart(): TInitCart {
  return {
    type: 'CART_INIT',
  }
}
export function payForCart(): TPayForCart {
  return {
    type: 'PAY_FOR_CART',
  }
}
