import React, { FC } from 'react'
import { MdFilterNone } from 'react-icons/md'
import { useGetThemeMode } from 'state/theme/hook'
import { useItem } from './hooks'

export const ItemTypes = {
  CARD: 'card',
}
export interface DragItem {
  index: number
  id: number
  type: string
}
export type PItem = {
  title: string
  onDelete: () => void
  onEdit: () => void
  id: number
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  onDrop: () => void
}

const Item: FC<PItem> = ({ title, onDelete, onEdit, id, index, moveCard, onDrop }) => {
  const { ref, isDragging } = useItem({ id, index, moveCard, onDrop })
  const themeMode = useGetThemeMode()

  const opacity = isDragging ? 'opacity' : ''

  return (
    <div ref={ref} className={`dashboard-table__body-item category-table__body ${opacity}`}>
      <div className="cell1">
        <div className="drag">
          {' '}
          <MdFilterNone /> {title}
        </div>
      </div>
      <div className="cell2">
        <div
          className="btn"
          style={{
            background: 'linear-gradient(#969696, #292929)',
            borderRadius: '5px',
          }}
          onClick={onDelete}
        >
          Delete
        </div>
        <div
          className="btn"
          style={{
            background: 'linear-gradient(#969696, #292929)',
            borderRadius: '5px',
          }}
          onClick={onEdit}
        >
          Edit
        </div>
      </div>
    </div>
  )
}

export default Item
