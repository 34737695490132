import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { sendMhcWithdrawData } from '../../../../actions/transaction'
import { AdminTransactionListContainer } from '../style'

const ConfirmMhcWithdraw = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const url = new URL(window.location.href)
    const id = url.searchParams.get('id')
    const amount = url.searchParams.get('amount')
    const type = url.searchParams.get('type')
    const withdrawAddress = url.searchParams.get('withdrawAddress')

    if (
      url.pathname === '/admin/confirmWithdraw' &&
      id !== null &&
      amount !== null &&
      type !== null &&
      withdrawAddress !== null
    ) {
      dispatch(sendMhcWithdrawData({ id, amount, type, withdrawAddress }))
    }
  }, [window.location.href])

  return (
    <AdminTransactionListContainer>
      <h4>Please wait</h4>
    </AdminTransactionListContainer>
  )
}

export default ConfirmMhcWithdraw
