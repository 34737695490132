import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onGetSettings } from '../../../actions/hashtag'
import { AppState } from '../../../redusers/config'
import { SettingItems, TAccountList } from '../../../utils/api'
import { onAccountGetList } from './actions'

export function useAccounts() {
  const dispatch = useDispatch()
  const accountList = useSelector<AppState, TAccountList>(({ accounts }) => accounts.get('accountList'))
  const settings = useSelector<AppState, SettingItems>(({ actions }) => actions.get('settings'))

  useEffect(() => {
    if (settings.pay_mhc_address === '' || settings.pay_buff_address === '') {
      dispatch(onGetSettings())
    }
  }, [settings])

  useEffect(() => {
    dispatch(onAccountGetList())
  }, [dispatch])

  const [search, setSearch] = useState<string>('')
  const [onlyBalance, setOnlyBalance] = useState<boolean>(false)
  const [onlyDomains, setOnlyDomains] = useState<boolean>(false)
  const [onlyPurchased, setOnlyPurchased] = useState<boolean>(false)
  const [onlyDemo, setOnlyDemo] = useState<boolean>(false)

  const memoizedOnSearch = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value)
  }, [])

  const memoizedOnChekedB = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setOnlyBalance(e.currentTarget.checked)
    },
    [setOnlyBalance]
  )
  const memoizedOnChekedD = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setOnlyDomains(e.currentTarget.checked)
    },
    [setOnlyDomains]
  )
  const memoizedOnChekedPD = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setOnlyPurchased(e.currentTarget.checked)
    },
    [setOnlyPurchased]
  )
  const memoizedOnChekedDD = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setOnlyDemo(e.currentTarget.checked)
    },
    [setOnlyDemo]
  )

  const filterData: TAccountList | undefined = useMemo(() => {
    const list =
      search === ''
        ? accountList
        : accountList.filter((item) => item.email.toUpperCase().includes(search.toUpperCase()))

    if (onlyBalance) {
      return list.filter((item) => item.balance > 0)
    }
    if (onlyDomains) {
      return list.filter((item) => item.domains > 0)
    }
    if (onlyPurchased) {
      return list.filter((item) => item.domains_stat.live > 0)
    }
    if (onlyDemo) {
      return list.filter((item) => item.domains_stat.demo > 0)
    }

    return list
  }, [search, accountList, onlyBalance, onlyDomains, onlyPurchased, onlyDemo])

  return {
    filterData,
    memoizedOnSearch,
    search,
    onlyBalance,
    memoizedOnChekedB,
    onlyDomains,
    memoizedOnChekedD,
    onlyPurchased,
    memoizedOnChekedPD,
    onlyDemo,
    memoizedOnChekedDD,
  }
}
