import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { setThemeMode, TThemeMode } from 'state/theme/reducer'
import { useGetThemeMode } from 'state/theme/hook'
import DarkIcon from '../../../assets/icons/dark2.svg'
import LightIcon from '../../../assets/icons/dark1.svg'
import { tooltipSearch } from 'components/Menu/tooltip'

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
`
const Icon = styled('img')`
  width: 16px;
  cursor: pointer;
`

const DarkLightToggleButton: React.FC = () => {
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()

  const handleThemeMode = useCallback(
    (theme: TThemeMode) => {
      dispatch(setThemeMode(theme))
    },
    [dispatch]
  )

  return (
    <Container>
      {themeMode == 'main' ? (
        <Icon
          src={DarkIcon}
          onClick={() => handleThemeMode('light')}
          data-tip={tooltipSearch.darkIcon}
          data-for="symbol-tip"
        />
      ) : (
        <Icon
          src={LightIcon}
          onClick={() => handleThemeMode('main')}
          data-tip={tooltipSearch.lightIcon}
          data-for="symbol-tip"
        />
      )}
    </Container>
  )
}

export default DarkLightToggleButton
