import { Record } from 'immutable'
import {
  TDashboardBridgeEarning,
  TDashboardDomainAnalytics,
  TDashboardMonthlyRevenue,
  TDashboardPurchases,
} from './types'

export interface Init {
  purchasesList: TDashboardPurchases[]
  monthlyRevenue: TDashboardMonthlyRevenue
  domainAnalytics: TDashboardDomainAnalytics
  bridgeEarning: TDashboardBridgeEarning
}

export const init: Init = {
  purchasesList: [],
  monthlyRevenue: { purchaseAmount: 0, commissionAmount: 0 },
  domainAnalytics: {
    droppedBilling: undefined,
    soldDomains: undefined,
    resoldDomains: undefined,
    keywords: undefined,
    parkedDomains: undefined,
  },
  bridgeEarning: {
    paypalEarning: 0,
    mhcEarning: 0,
    profit: 0,
  },
}

type Actions<T> = { [P in keyof T]: { type: P; payload: T[P] } } extends {
  [P in keyof { [P in keyof T]: { type: P; payload: T[P] } }]: infer P
}
  ? P
  : never

export type TActionsDashboard = Actions<Init>

const State: Record.Factory<Init> = Record(init)

const reducer = function (state: Record<Init> = new State(), action: TActionsDashboard): Record<Init> {
  return state.set(action.type, action.payload)
}

export default reducer
