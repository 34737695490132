import React from 'react'
import { IoMdArrowDropup } from 'react-icons/io'
import cn from './cn.module.scss'

interface IPercentValue {
  percent: number
}
const GreenPercent: React.FC<IPercentValue> = ({ percent }) => {
  let percentValue = 0
  if (percent) percentValue = percent

  return (
    <span className={cn.greenPercent}>
      <IoMdArrowDropup />
      {percentValue.toFixed(2)}%
    </span>
  )
}

export default GreenPercent
