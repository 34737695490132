import { FC } from 'react'
import MetaHash from './Icons/MetaHash'
import Metamask from './Icons/Metamask'
import BinanceChain from './Icons/BinanceChain'

export enum ConnectorNames {
  MetaHash = 'metahash',
  Injected = 'injected',
  BSC = 'bsc',
}

export interface Config {
  title: string
  icon: FC<any>
  connectorId: ConnectorNames
  isMetaHash: boolean
}

export const defaultConnector: Config = {
  title: 'MetaHash',
  icon: MetaHash,
  connectorId: ConnectorNames.MetaHash,
  isMetaHash: true,
}

const connectors: Config[] = [
  {
    title: 'MetaHash',
    icon: MetaHash,
    connectorId: ConnectorNames.MetaHash,
    isMetaHash: true,
  },
  {
    title: 'MetaMask',
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
    isMetaHash: false,
  },
  {
    title: 'Binance Chain Wallet',
    icon: BinanceChain,
    connectorId: ConnectorNames.BSC,
    isMetaHash: false,
  },
]

export default connectors
