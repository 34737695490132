import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import {
  getHashtagList,
  onClearHashtagList,
  onGetSettings,
  onShowModalManage,
  stopResale,
  TonShowModalManage,
} from '../../actions/hashtag'
import { AppState } from '../../redusers/config'
import { Init } from '../../redusers/actions'
import { AudioMenu } from 'components/Menu/tooltip'

export function UseDomainsDashboard(limit: number) {
  const dispatch = useDispatch()

  const hashtagList = useSelector<AppState, Init['hashtagList']>(({ actions }) => actions.get('hashtagList'))
  const expiredList = useSelector<AppState, Init['expiredList']>(({ actions }) => actions.get('expiredList'))
  const hashtagListСount = useSelector<AppState, Init['hashtagListСount']>(({ actions }) =>
    actions.get('hashtagListСount')
  )

  useEffect(() => {
    dispatch(getHashtagList({ search: '', limit: limit, isClear: true }))
    return () => {
      dispatch(onClearHashtagList())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(onGetSettings())
  }, [dispatch])

  // const onGetExpiredItem = useCallback((item: string) => {
  //   setSearchVal(item)
  // }, [])

  const onStopResale = useCallback(
    (id: string) => () => {
      dispatch(stopResale(id))
    },
    [dispatch]
  )

  const onShowModalURL = useCallback(
    (data: TonShowModalManage['payload']) => {
      dispatch(onShowModalManage(data))
    },
    [dispatch]
  )

  return {
    hashtagList,
    expiredList,
    hashtagListСount,
    onShowModalURL,
    onStopResale,
  }
}

export const useMenuAudio = () => {
  const playAudio = useCallback((page: string) => {
    AudioMenu(page).play()
  }, [])

  return {
    playAudio,
  }
}
