import { Token } from '@uniswap/sdk-core'
import seoCoinABI from 'abis/seoCoinABI'
import CREDIT_ICON_URL from '../assets/icons/hash-large.svg'
import HASHTAG_C_ICON_URL from '../assets/icons/hashtag-c.png'
import HASHTAG_B_ICON_URL from '../assets/icons/hashtag-b.png'
import SEO_COIN_ICON_URL from '../assets/icons/seo-coin.png'
import { HASHTAG_URL } from './urls'

export const SEO_COIN = new Token(4, '0x7411F6c596A7E132ef008fe7f3f4dF73126E5C84', 18, 'SEO', 'SEO Coin')
export const HASHTAG_COIN = new Token(4, '0x1FA322c468F5223CF2F8C1b22A352B68a5d4412f', 18, '$Hashtag', 'Hashtag Coin')
export const HASHTAG_B_COIN = new Token(4, '0x1FA322c468F5223CF2F8C1b22A352B68a5d4412f', 18, '$Hashtag', 'Hashtag Coin')

export interface IAffiliateCoin {
  name: string
  symbol: string
  iconLink: string
  siteLink: string
  address?: string
  tokenABI?: any
}
export const AFFILIATE_COIN_LIST: { [key: string]: IAffiliateCoin } = {
  SEO: {
    name: SEO_COIN.name!,
    symbol: SEO_COIN.symbol!,
    address: SEO_COIN.address,
    iconLink: SEO_COIN_ICON_URL,
    siteLink: 'https://seo.money',
    tokenABI: seoCoinABI,
  },
  HASHTAG: {
    name: HASHTAG_COIN.name!,
    symbol: HASHTAG_COIN.symbol!,
    address: HASHTAG_COIN.address,
    iconLink: HASHTAG_C_ICON_URL,
    siteLink: HASHTAG_URL,
  },
  HASHTAG_B: {
    name: HASHTAG_COIN.name!,
    symbol: HASHTAG_COIN.symbol!,
    address: HASHTAG_COIN.address,
    iconLink: HASHTAG_B_ICON_URL,
    siteLink: HASHTAG_URL,
  },
  CREDIT: {
    name: 'Credit',
    symbol: '$USD',
    iconLink: CREDIT_ICON_URL,
    siteLink: HASHTAG_URL,
  },
}
