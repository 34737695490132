import { call, fork, put, takeLatest } from '@redux-saga/core/effects'
import { push } from 'connected-react-router'
import {
  dashboardGetBridgeEarning,
  dashboardGetDomainAnalytics,
  dashboardGetMothlyRevenue,
  dashboardGetPurchases,
} from 'utils/api'
import { checkErorr, getToken } from '../../../sagas/helper'
import { TActionsDashboard } from './redusers'
import {
  TDashboardBridgeEarning,
  TDashboardDomainAnalytics,
  TDashboardMonthlyRevenue,
  TDashboardPurchases,
  TonDashboardGetBridgeEarning,
  TonDashboardGetDomainAnalytics,
  TonDashboardGetMonthlyRevenue,
  TonDashboardGetPurchases,
} from './types'

export function* watcherSaga() {
  yield takeLatest('ON_DASHBOARD_GET_PURCHASES', workerDashboardGetPurchases)
  yield takeLatest('ON_DASHBOARD_GET_MONTHLY_REVENUE', workerDashboardGetMonthlyRevenue)
  yield takeLatest('ON_DASHBOARD_GET_DOMAIN_ANALYTICS', workerDashboardGetDomainAnalytics)
  yield takeLatest('ON_DASHBOARD_GET_BRIDGE_EARNING', workerDashboardGetBridgeEarning)
}

function* workerDashboardGetPurchases({ payloads }: TonDashboardGetPurchases) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const res: TDashboardPurchases[] = yield call(dashboardGetPurchases, payloads, token)

    const action: TActionsDashboard = {
      type: 'purchasesList',
      payload: res,
    }

    yield put(action)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerDashboardGetMonthlyRevenue({ payloads }: TonDashboardGetMonthlyRevenue) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const res: TDashboardMonthlyRevenue = yield call(dashboardGetMothlyRevenue, payloads, token)

    const action: TActionsDashboard = {
      type: 'monthlyRevenue',
      payload: res,
    }

    yield put(action)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerDashboardGetDomainAnalytics({ payloads }: TonDashboardGetDomainAnalytics) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const res: TDashboardDomainAnalytics = yield call(dashboardGetDomainAnalytics, payloads, token)

    const action: TActionsDashboard = {
      type: 'domainAnalytics',
      payload: res,
    }

    yield put(action)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerDashboardGetBridgeEarning({ payloads }: TonDashboardGetBridgeEarning) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const res: TDashboardBridgeEarning = yield call(dashboardGetBridgeEarning, payloads, token)

    const action: TActionsDashboard = {
      type: 'bridgeEarning',
      payload: res,
    }

    yield put(action)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export default watcherSaga
