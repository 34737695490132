import { TSearchOnReq, TClearActionALL, TUpdateInstructionList, TClearAction } from './types'

export function onSearch(request: TSearchOnReq['payload']): TSearchOnReq {
  return {
    type: 'SEARCH_ON_REQ',
    payload: request,
  }
}

export function onClearData(): TClearActionALL {
  return {
    type: 'SEARCH_CLEAR_ALL',
  }
}
export function onChangeFavAddStatus(val: boolean) {
  return {
    type: 'ADD_FAVORITE_STATUS',
    val,
  }
}
export function onChangeWishAddStatus(val: boolean) {
  return {
    type: 'ADD_WISH_STATUS',
    val,
  }
}

export function isSearch(val: boolean) {
  return {
    type: 'IS_SEARCH',
    val,
  }
}

export function updateShowCoin(val: boolean) {
  return {
    type: 'UPDATE_SHOW_COIN',
    val,
  }
}

export function checkExists(payload: Array<string>) {
  return {
    type: 'CHECK_EXIST_DOMAIN',
    payload,
  }
}

export function getAllDomainList() {
  return {
    type: 'SEARCHENGINE_GET_ALL_DOMAIN_LIST',
  }
}

export function getAllSoldDomainList() {
  return {
    type: 'SEARCHENGINE_GET_ALL_SOLD_DOMAIN_LIST',
  }
}

export function getAllSEODomainList() {
  return {
    type: 'SEARCHENGINE_GET_ALL_SEO_DOMAIN_LIST',
  }
}

export function getInstruction() {
  return {
    type: 'SEARCHENGINE_GET_INSTRUCTION',
  }
}

export function updateInstruction(payload: TUpdateInstructionList['payload']) {
  return {
    type: 'SEARCHENGINE_UPDATE_INSTRUCTION',
    payload,
  }
}

export function getAllSearchLogList() {
  return {
    type: 'SEARCHENGINE_GET_SEARCH_LOG_LIST',
  }
}
