const phoneCode = [
  ['7 840', 'AB'],
  ['61', 'AU'],
  ['43', 'AT'],
  ['994', 'AZ'],
  ['355', 'AL'],
  ['213', 'DZ'],
  ['244', 'AO'],
  ['376', 'AD'],
  ['1 268', 'AG'],
  ['54', 'AR'],
  ['374', 'AM'],
  ['93', 'AF'],
  ['1 242', 'BS'],
  ['880', 'BD'],
  ['1 246', 'BB'],
  ['973', 'BH'],
  ['501', 'BZ'],
  ['375', 'BY'],
  ['32', 'BE'],
  ['229', 'BJ'],
  ['359', 'BG'],
  ['591', 'BO'],
  ['387', 'BA'],
  ['267', 'BW'],
  ['55', 'BR'],
  ['673', 'BN'],
  ['226', 'BF'],
  ['257', 'BI'],
  ['975', 'BT'],
  ['678', 'VU'],
  ['379', 'VA'],
  ['44', 'GB'],
  ['36', 'HU'],
  ['58', 'VE'],
  ['670', 'TL'],
  ['84', 'VN'],
  ['241', 'GA'],
  ['509', 'HT'],
  ['592', 'GY'],
  ['220', 'GM'],
  ['233', 'GH'],
  ['502', 'GT'],
  ['224', 'GN'],
  ['245', 'GW'],
  ['49', 'DE'],
  ['504', 'HN'],
  ['970', 'PS'],
  ['1 473', 'GD'],
  ['30', 'GR'],
  ['995', 'GE'],
  ['45', 'DK'],
  ['253', 'DJ'],
  ['1 767', 'DM'],
  ['1 809', 'DO'],
  ['243', 'CD'],
  ['20', 'EG'],
  ['260', 'ZM'],
  ['263', 'ZW'],
  ['972', 'IL'],
  ['91', 'IN'],
  ['62', 'ID'],
  ['962', 'JO'],
  ['964', 'IQ'],
  ['98', 'IR'],
  ['353', 'IE'],
  ['354', 'IS'],
  ['34', 'ES'],
  ['39', 'IT'],
  ['967', 'YE'],
  ['238', 'CV'],
  ['+7', 'KZ'],
  ['855', 'KH'],
  ['237', 'CM'],
  ['+1', 'CA'],
  ['974', 'QA'],
  ['254', 'KE'],
  ['357', 'CY'],
  ['996', 'KG'],
  ['686', 'KI'],
  ['86', 'CN'],
  ['850', 'KP'],
  ['57', 'CO'],
  ['269', 'KM'],
  ['506', 'CR'],
  ['225', 'CI'],
  ['53', 'CU'],
  ['965', 'KW'],
  ['856', 'LA'],
  ['371', 'LV'],
  ['266', 'LS'],
  ['231', 'LR'],
  ['961', 'LB'],
  ['218', 'LY'],
  ['370', 'LT'],
  ['423', 'LI'],
  ['352', 'LU'],
  ['230', 'MU'],
  ['222', 'MR'],
  ['261', 'MG'],
  ['265', 'MW'],
  ['60', 'MY'],
  ['223', 'ML'],
  ['960', 'MV'],
  ['356', 'MT'],
  ['212', 'MA'],
  ['692', 'MH'],
  ['52', 'MX'],
  ['258', 'MZ'],
  ['373', 'MD'],
  ['377', 'MC'],
  ['976', 'MN'],
  ['95', 'MM'],
  ['264', 'NA'],
  ['674', 'NR'],
  ['977', 'NP'],
  ['227', 'NE'],
  ['234', 'NG'],
  ['31', 'NL'],
  ['505', 'NI'],
  ['64', 'NZ'],
  ['47', 'NO'],
  ['971', 'AE'],
  ['968', 'OM'],
  ['92', 'PK'],
  ['680', 'PW'],
  ['507', 'PA'],
  ['675', 'PG'],
  ['595', 'PY'],
  ['51', 'PE'],
  ['48', 'PL'],
  ['351', 'PT'],
  ['242', 'CG'],
  ['82', 'KR'],
  ['+7', 'RU'],
  ['250', 'RW'],
  ['40', 'RO'],
  ['503', 'SV'],
  ['685', 'WS'],
  ['378', 'SM'],
  ['239', 'ST'],
  ['966', 'SA'],
  ['389', 'MK'],
  ['248', 'SC'],
  ['221', 'SN'],
  ['1 784', 'VC'],
  ['1 869', 'KN'],
  ['1 758', 'LC'],
  ['381', 'RS'],
  ['65', 'SG'],
  ['963', 'SY'],
  ['421', 'SK'],
  ['386', 'SI'],
  ['677', 'SB'],
  ['252', 'SO'],
  ['249', 'SD'],
  ['597', 'SR'],
  ['+1', 'US'],
  ['232', 'SL'],
  ['992', 'TJ'],
  ['66', 'TH'],
  ['255', 'TZ'],
  ['228', 'TG'],
  ['676', 'TO'],
  ['1 868', 'TT'],
  ['688', 'TV'],
  ['216', 'TN'],
  ['993', 'TM'],
  ['90', 'TR'],
  ['256', 'UG'],
  ['998', 'UZ'],
  ['380', 'UA'],
  ['598', 'UY'],
  ['691', 'FM'],
  ['679', 'FJ'],
  ['63', 'PH'],
  ['358', 'FI'],
  ['33', 'FR'],
  ['385', 'HR'],
  ['236', 'CF'],
  ['235', 'TD'],
  ['382', 'ME'],
  ['420', 'CZ'],
  ['56', 'CL'],
  ['41', 'CH'],
  ['46', 'SE'],
  ['94', 'LK'],
  ['593', 'EC'],
  ['240', 'GQ'],
  ['291', 'ER'],
  ['268', 'SZ'],
  ['372', 'EE'],
  ['251', 'ET'],
  ['27', 'ZA'],
  ['+7', 'OS'],
  ['211', 'SS'],
  ['1 876', 'JM'],
  ['81', 'JP'],
]

export default phoneCode
