import React, { useState } from 'react'
import cn from './cn.module.scss'
import { FaChartLine, FaChartPie, FaChartArea } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../redusers/config'
import { IAssetsTable } from '../../types'
import { useGetCurrentBalanceStatus } from '../../hooks'
import LineChart from './LineChart'
import PieChart from './PieChart'
import Statistic from './Statistic'
import { useGetThemeMode } from 'state/theme/hook'

type TChartOption = 'LineChart' | 'PieChart' | 'Statistic'

const ChartContainer: React.FC<any> = () => {
  const assetsList = useSelector<AppState, IAssetsTable[]>(({ portfolio }) => portfolio.get('listAssets'))
  const [selectedOption, setSelectedOption] = useState<TChartOption>('Statistic')
  const balanceStatus = useGetCurrentBalanceStatus(assetsList)
  const themeMode = useGetThemeMode()

  return (
    <div
      className={cn.chartContainer}
      style={{
        background: themeMode === 'main' ? '#00000090' : '#ffffff90',
        color: themeMode === 'light' ? 'black' : 'white',
      }}
    >
      <div className={cn.title}>CURRENT BALANCE</div>
      <div className={cn.balanceContainer}>
        <div className={cn.balance}>
          ${balanceStatus.totalBalance > 0 ? balanceStatus.totalBalance.toFixed(6) : '------'}
        </div>
        <div className={cn.percent}>{balanceStatus.profitPercent ? balanceStatus.profitPercent.toFixed(2) : '--'}%</div>
      </div>
      <div className={cn.dailyChange}>
        {balanceStatus.profitAmount < 0 ? '-' : '+'} $
        {balanceStatus.profitAmount !== 0 ? Math.abs(balanceStatus.profitAmount).toFixed(6) : '------'} (24H)
      </div>
      <div className={cn.chartOptionTab}>
        <div
          className={`${cn.chartOption} ${cn.lineChart} ${selectedOption === 'LineChart' && cn.active}`}
          onClick={() => setSelectedOption('LineChart')}
        >
          <FaChartLine />
          Line Chart
        </div>
        <div
          className={`${cn.chartOption} ${cn.pieChart} ${selectedOption === 'PieChart' && cn.active}`}
          onClick={() => setSelectedOption('PieChart')}
        >
          <FaChartPie />
          Pie Chart
        </div>
        <div
          className={`${cn.chartOption} ${cn.statistics} ${selectedOption === 'Statistic' && cn.active}`}
          onClick={() => setSelectedOption('Statistic')}
        >
          <FaChartArea />
          Statistic
        </div>
      </div>
      <div className={cn.chartWrapper}>
        {selectedOption === 'LineChart' && <LineChart assetsList={assetsList} />}
        {selectedOption === 'PieChart' && <PieChart assetsList={assetsList} />}
        {selectedOption === 'Statistic' && <Statistic assetsList={assetsList} />}
      </div>
    </div>
  )
}

export default ChartContainer
