import React, { ReactNode } from 'react'
import cn from './cn.module.css'

interface FormKeywordsFieldP {
  error?: boolean
  children?: ReactNode
  themeMode?: string
}

const FormKeywordsField: React.FC<FormKeywordsFieldP> = ({ error, children, themeMode }) => {
  return (
    <div
      className={`${cn.keyField} ${error ? cn.error : ''}`}
      style={{
        borderColor: themeMode == 'light' ? 'gray' : 'white',
        background: themeMode == 'main' ? '#00000090' : '#ffffff90',
        color: themeMode == 'main' ? 'white' : 'black',
      }}
    >
      {children}
    </div>
  )
}
export default FormKeywordsField
