import { providers } from 'views/Portfolio/blockchainAssets'
import Web3 from 'web3'
import { CoinGeckoClient } from 'coingecko-api-v3'
import { ethers } from 'ethers'
import { showToast } from 'sagas/domain'

export const useWallet = () => {
  const getEthBalance = async (walletAddress: string, flag: string) => {
    const web3: Web3 = new Web3(providers[flag === 'eth' ? '5' : '97'])
    const balance = await web3.eth.getBalance(walletAddress)
    return Number(web3.utils.fromWei(balance, 'ether'))
  }

  const cgClient = new CoinGeckoClient({
    timeout: 10000,
    autoRetry: true,
  })

  const getEthPrice = async () => {
    const data = await cgClient.simplePrice({
      vs_currencies: 'usd',
      ids: 'ethereum',
    })
    return data.ethereum.usd
  }
  const getBNBPrice = async () => {
    const data = await cgClient.simplePrice({
      vs_currencies: 'usd',
      ids: 'binancecoin',
    })
    return data.binancecoin.usd
  }

  const walletConnecting = async (toAddress: string, type: string, chainId: number, tokenAmount: string) => {
    if (!window.ethereum) {
      showToast('Please install wallet', 'info')
      return null
    } else {
      let provider = new ethers.providers.Web3Provider(window.ethereum)
      const id = await provider.getNetwork()
      if (id.chainId !== chainId) {
        const data = [{ chainId: type === 'eth' ? '0x5' : '0x61' }] // ethereum mainnet: 0x1, BSC: 0x38
        await provider.send('wallet_switchEthereumChain', data)
        provider = new ethers.providers.Web3Provider(window.ethereum, chainId)
      }
      await provider.send('eth_requestAccounts', [])
      const signer = provider.getSigner()
      ethers.utils.getAddress(toAddress)
      const tx = await signer.sendTransaction({
        to: toAddress,
        value: ethers.utils.parseEther(tokenAmount),
      })
      const res = await tx.wait()
      return res
    }
  }

  return {
    getEthBalance,
    cgClient,
    getEthPrice,
    getBNBPrice,
    walletConnecting,
  }
}
