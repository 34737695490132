import '@rainbow-me/rainbowkit/dist/index.css'
import cn from 'classnames'
import GlobalModal from 'components/Modals/GlobalModal'
import React, { useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation, withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import HashTag1 from './assets/icons/tag1.svg'
import HashTag2 from './assets/icons/tag2.svg'
import HashTag3 from './assets/icons/tag3.svg'
import HashTag4 from './assets/icons/tag4.svg'
import HashTag5 from './assets/icons/tag5.svg'
import HashTag6 from './assets/icons/tag6.svg'
import HashTag7 from './assets/icons/tag7.svg'
import Footer from './components/Footer'
import ModalManage from './components/Modals/ModalManage'
import HashTag from './components/Tags'
import AdminRoutng from './views/Admin/Routing'
import HeaderWrap from './views/Header'
import ForgotPassword from './views/Login/ForgotPassword'
import ResetPassword from './views/Login/ResetPassword'
import SignIn from './views/Login/SignIn'
import SignUP from './views/Login/SignUP'
import NoAccess from './views/NoAccess/NoAccess'
import NotFound from './views/NotFound'
import Portfolio from './views/Portfolio'
import PrivacyPolicy from './views/PrivacyPolicy'
import StartPage from './views/StartPage'
import UserRouting from './views/UserRouting'
import { useGetThemeMode } from 'state/theme/hook'
import { useDispatch, useSelector } from 'react-redux'
import { setThemeMode } from 'state/theme/reducer'
import ViewSocialProfile from 'views/viewSocialProfile'
import { SearchEngineContextProvider } from 'views/Search/contexts/SearchEngineContext'
import { chain, configureChains, createClient, useAccount, useDisconnect, WagmiConfig } from 'wagmi'
import { RainbowKitProvider, darkTheme, lightTheme, getDefaultWallets } from '@rainbow-me/rainbowkit'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import { checkLogin, onLogOut } from 'actions/user'
import { CHROME_EXTENSION_ID, CHROME_EXTENSION_ID_MAC, HASHTAG_URL, SOCIAL_URL } from 'constants/urls'
import SignUpBuy from 'views/Login/SignUpBuy'
import useSWR from 'swr'
import { bookHashtag } from 'actions/hashtag'
import { useCommonCheck } from 'hooks/useCommon'

const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY || 'wOEEEmDd2N7l0MobliD9YGx2NW0oexii'

const { chains, provider } = configureChains(
  [chain.mainnet, chain.optimism, chain.arbitrum, chain.goerli, chain.polygon, chain.polygonMumbai],
  [alchemyProvider({ alchemyId: alchemyKey })]
)

const { connectors } = getDefaultWallets({
  appName: 'HashtagSpace',
  chains,
})

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
})

const hashTagList = [HashTag1, HashTag2, HashTag5, HashTag6, HashTag3, HashTag7, HashTag4]

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const AnimatedSwitch = withRouter(({ location }) => (
  <Switch>
    <Route exact path="/" component={StartPage} />
    <Route exact path="/login" component={SignIn} />
    <Route exact path="/register/" component={SignUP} />
    <Route path="/forgotPassword" component={ForgotPassword} />
    <Route path="/resetPassword/:code" component={ResetPassword} />
    <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
    <Route path="/No_access" component={NoAccess} />
    <Route path="/viewprofile/:userID" component={ViewSocialProfile} />
    <Route path="/portfolio" component={Portfolio} />
    <Route
      path={[
        '/dashboard',
        '/domainsresult',
        '/pay',
        '/domainsdashboard',
        '/domain_edit',
        '/profile',
        '/search',
        '/enter',
        '/Affiliates',
        '/CryptoAffiliates',
        '/dreaming',
        '/coming_soon',
        '/marketspace',
        '/delete',
      ]}
      component={UserRouting}
    />

    <Route path="/admin/" component={AdminRoutng} />
    <Route component={NotFound} />
  </Switch>
))

const BackgroundHashtagCredits = () => (
  <section className="floatingHashtags">
    <div className="hashtags">
      <div className="hashtag"></div>
      <div className="hashtag"></div>
      <div className="hashtag"></div>
      <div className="hashtag"></div>
      <div className="hashtag"></div>
      <div className="hashtag"></div>
      <div className="hashtag"></div>
      <div className="hashtag"></div>
    </div>
  </section>
)

const App = () => {
  const path = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { postChromeExtensionMessage, isChromeBrowser } = useCommonCheck()
  const accessToken = window.localStorage.getItem('access_token')
  const isLogIn = useSelector<AppState, Init['isLogin']>(({ user }) => user.get('isLogin'))
  const curUrl = window.location.href

  const [mainClass, setMainClass] = useState<string>('home')

  if (
    (curUrl.includes('/login') && !curUrl.includes('?rtn=')) ||
    curUrl.includes('/register') ||
    curUrl.includes('/buy') ||
    curUrl.includes('/forgotPassword')
  ) {
    if (accessToken && accessToken !== null && isLogIn) {
      // dispatch(checkLogin())
      history.push('/domainsresult')
    }
  }

  // Communicate with Extension(onMessageExternal of backend.ts) for logout button of Extension
  const postLoginStatus = () => {
    if (!isChromeBrowser()) return

    if (chrome.runtime) {
      let extensionId = CHROME_EXTENSION_ID
      const platform = navigator.platform.toLowerCase()
      if (!platform.includes('win')) extensionId = CHROME_EXTENSION_ID_MAC

      chrome.runtime.sendMessage(extensionId, { login: 'logout' }, function (response) {
        if (response && response.icon === 'logout') {
          dispatch(onLogOut())
        }
      })
    }
  }
  useSWR('login status', postLoginStatus, { refreshInterval: 1000 })

  useEffect(() => {
    const msg = isLogIn ? 'yes' : 'no'
    const rToken = window.localStorage.getItem('refresh_token') ?? ''
    postChromeExtensionMessage(msg, rToken)

    const themeMode = localStorage.getItem('themeMode') ? (localStorage.getItem('themeMode') as string) : 'main'
    dispatch(setThemeMode(themeMode))
  }, [dispatch])

  useEffect(() => {
    const currentPath = path.pathname.replace(/[^\w\s]/gi, '')
    setMainClass(currentPath)
    if (isLogIn && curUrl.includes('/buy#')) {
      const domain = curUrl.split('/buy')[1]
      dispatch(bookHashtag(domain))
    }

    // TODO <FooterBottom />
    // setTimeout(() => {
    //   const chatObj = document.getElementById('chat-widget-container')
    //   if (chatObj != null) {
    //     console.log('====chat itme-login::', isLogIn, Math.pow(1.01, 365))
    //     if (isLogIn) {
    //       chatObj.style.cssText =
    //         'opacity: 1;visibility: visible;z-index: 2147483639;position: fixed;width: 281px;height: 63px;max-width: 100%;max-height: calc(100% - 0px);min-height: 0px;min-width: 0px;background-color: transparent;border: 0px;overflow: hidden;right: 0px;transition: none 0s ease 0s !important;bottom: 112px !important; margin-right: -12px; float: right'
    //     } else {
    //       chatObj.style.cssText =
    //         'opacity: 1;visibility: visible;z-index: 2147483639;position: fixed;width: 281px;height: 63px;max-width: 100%;max-height: calc(100% - 0px);min-height: 0px;min-width: 0px;background-color: transparent;border: 0px;overflow: hidden;right: 0px;transition: none 0s ease 0s !important;bottom: 50px !important; margin-right: -12px; float: right'
    //     }
    //   }
    // }, 1000)
  }, [curUrl, dispatch, isLogIn, path])

  const themeMode = useGetThemeMode()

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={
          themeMode === 'main'
            ? darkTheme({
                accentColor: 'linear-gradient(#873f99, #561e64)',
                accentColorForeground: 'white',
                borderRadius: 'large',
                fontStack: 'system',
                overlayBlur: 'small',
              })
            : lightTheme({
                accentColor: 'linear-gradient(#b7eaf6, #777fc4)',
                accentColorForeground: 'black',
                borderRadius: 'large',
                fontStack: 'system',
                overlayBlur: 'small',
              })
        }
        showRecentTransactions
        coolMode
      >
        <GlobalModal>
          {mainClass === 'buy' && (
            <div>
              {/* {hashTagList.map((tag: string, index: number) => {
                return (
                  <HashTag
                    key={index}
                    url={tag}
                    positionTop={`${
                      index % 2 === 0 ? randomIntFromInterval(10, 500) : randomIntFromInterval(500, 1000)
                    }px`}
                    positionLeft={`${randomIntFromInterval(400 * index, 400 * (index + 1))}px`}
                    imgWidth={`${randomIntFromInterval(70, 160)}px`}
                  />
                )
              })} */}
              <SignUpBuy />
              <Footer />
            </div>
          )}
          {mainClass !== 'buy' && (
            <div className={cn('main', mainClass)}>
              <div className={themeMode == 'main' ? 'bg-main' : 'bg-l'}>
                {/* {hashTagList.map((tag: string, index: number) => {
                  return (
                    <HashTag
                      key={index}
                      url={tag}
                      positionTop={`${
                        index % 2 === 0 ? randomIntFromInterval(10, 500) : randomIntFromInterval(500, 1000)
                      }px`}
                      positionLeft={`${randomIntFromInterval(400 * index, 400 * (index + 1))}px`}
                      imgWidth={`${randomIntFromInterval(70, 160)}px`}
                    />
                  )
                })}
                <BackgroundHashtagCredits /> */}
                {mainClass === 'portfolio' && themeMode === 'main' && (
                  <div
                    className="portfolioImg"
                    style={{
                      background: themeMode === 'main' ? '' : '#e8eff7',
                    }}
                  ></div>
                )}
                <SearchEngineContextProvider>
                  <HeaderWrap />
                  <AnimatedSwitch />
                </SearchEngineContextProvider>
                {mainClass !== 'dreaming' && <Footer />}
              </div>
            </div>
          )}
          <ModalManage />
          <ToastContainer autoClose={5000} closeButton={true} pauseOnHover={true} position="top-right" />
        </GlobalModal>
      </RainbowKitProvider>
    </WagmiConfig>
  )
}
export default App
