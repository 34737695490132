import styled, { css } from 'styled-components'

export const Root = styled.div`
  position: relative;
  width: 100%;
`

export const baseButtonMixin = css`
  background: none;
  border: none;
  padding: 0;
`

export const Input = styled.input`
  display: flex;
  border: none; // 1px solid white;
  height: 38px;
  font-size: 18px;
  padding: 0 10px;
  color: white;
  width: 100%;
  outline: none;
`
export const InputTarget = styled.input`
  display: flex;
  border: 1px solid white;
  height: 38px;
  font-size: 14px;
  padding: 0 10px;
  color: black;
  width: 100%;
  outline: none;
`

export const AutoNickCompleteContainer = styled.ul`
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 8px 0;
  list-style-type: none;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #b6c1ce;
  border-radius: 2px;
  margin-top: 2px;
  box-sizing: border-box;
  max-height: 280px;
  overflow-y: auto;
  z-index: 1;
`

export const AutoNickCompleteItem = styled.li`
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
`

export const AutoNickCompleteItemButton = styled.div`
  ${baseButtonMixin} width: 100%;
  line-height: 20px;
  text-align: left;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  &:hover {
    outline: none;
    color: #fbb212;
    background: transparent;
    cursor: pointer;
  }
`

export const NickName = styled.div`
  max-width: 68%;
  word-break: break-all;
`

export const LevelStar = styled.span`
  display: flex;
  color: #000;
  font-weight: 700;
  display: flex;
  align-items: center;
  background: #fbb212;
  border-radius: 3px;
  padding: 5px;
  font-size: 14px;
`
