import React, { useState } from 'react'
import cn from './cn.module.scss'
import { BiCheck } from 'react-icons/bi'
import { GradientButton } from '../../../../components/Buttons/GradientButton'
import { DepositNetworkList, NetworkList } from '../../blockchainAssets'
import { INetwork } from '../../types'

interface INetworkContent {
  network: INetwork
  selectNetwork: (v: INetwork) => void
}

const NetworkContent: React.FC<INetworkContent> = (props) => {
  return (
    <div className={cn.networkContent} onClick={() => props.selectNetwork(props.network)}>
      <GradientButton backgroundColor={'transparent'}>
        <img className={cn.networkLogo} src={props.network.logoUrl} alt={props.network.name} />
        {props.network.name === 'Binance_Smart_Chain' ? 'Binance Chain' : props.network.name}
      </GradientButton>
      {props.network.isSelected && (
        <div className={cn.checkIcon}>
          <BiCheck />
        </div>
      )}
    </div>
  )
}
interface INetworkWrapper {
  setSelectedNetwork: (v: INetwork) => void
  flag?: string
}
const NetworkWrapper: React.FC<INetworkWrapper> = ({ setSelectedNetwork, flag }) => {
  const [networkLists, setNetworkLists] = useState<INetwork[]>(flag === 'deposit' ? DepositNetworkList : NetworkList)

  const onSelectNetwork = (item: INetwork) => () => {
    setSelectedNetwork(item)

    const newNetworkList = networkLists.map((elem) => {
      let newItem = { ...elem }
      if (newItem.name === item.name) {
        newItem.isSelected = true
      } else {
        newItem.isSelected = false
      }
      return newItem
    })
    setNetworkLists(newNetworkList)
  }

  return (
    <div className={cn.networkWrapper}>
      {networkLists.map((item: INetwork) => (
        <NetworkContent key={item.id} network={item} selectNetwork={onSelectNetwork(item)} />
      ))}
    </div>
  )
}

export default NetworkWrapper
