import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useGetThemeMode } from 'state/theme/hook'
import { getBalance, getListTransaction, getListTransactionForAdm } from '../../../actions/transaction'
import VaultCreditIcon from '../../../assets/header/vault_credit.svg'
import ActiveNodesIcon from '../../../assets/header/active_nodes.svg'
import AffPayoutIcon from '../../../assets/header/affiliate_payout.svg'
import CoinsIcon from '../../../assets/header/coins.svg'
import DomMintedIcon from '../../../assets/header/domain_minted.svg'
import LiqPoolIcon from '../../../assets/header/liquidity_pool.svg'
import NodePayoutIcon from '../../../assets/header/nodes_payout.svg'

import { AppState } from '../../../redusers/config'
import { Init } from '../../../redusers/user'
import { GetBalanceR, UserInfo } from '../../../utils/api'
import './styles.scss'

const HeaderItemlist: React.FC<any> = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const isAdmin = useSelector<AppState, Init['isAdmin']>(({ user }) => user.get('isAdmin'), shallowEqual)
  const isLogIn = useSelector<AppState, Init['isLogin']>(({ user }) => user.get('isLogin'))

  const [ethBalance, setEthBalance] = useState<string>('')
  const [bnbBalance, setBnbBalance] = useState<string>('')

  const themeMode = useGetThemeMode()

  return (
    <div className="tokenContainer">
      <div className="sContainer">
        <div className="sub_container">
          <div className="item_top">
            <img src={VaultCreditIcon} className="item_icon" />
            <span className="nowrap">#Vault Credits</span>
          </div>
          <div className="item_bottom">
            <span className="txt_bottom">21</span>
            <MdOutlineArrowDropUp color="green" size={25} />
          </div>
        </div>
        <div className="sub_container">
          <div className="item_top">
            <img src={ActiveNodesIcon} className="item_icon" />
            <span className="nowrap">#Active Nodes</span>
          </div>
          <div className="item_bottom">
            <span className="txt_bottom">123,245</span>
            <MdOutlineArrowDropUp color="green" size={25} />
          </div>
        </div>
        <div className="sub_container">
          <div className="item_top">
            <img src={LiqPoolIcon} className="item_icon" />
            <span className="nowrap">#Liquidity Pool</span>
          </div>
          <div className="item_bottom">
            <span className="txt_bottom">$2,789.28</span>
            <MdOutlineArrowDropUp color="green" size={25} />
          </div>
        </div>
      </div>
      <div className="sContainer">
        <div className="sub_container">
          <div className="item_top">
            <img src={DomMintedIcon} className="item_icon" />
            <span className="nowrap">#Domains Minted</span>
          </div>
          <div className="item_bottom">
            <span className="txt_bottom">$18,214</span>
            <MdOutlineArrowDropDown color="red" className="item_icon" />
          </div>
        </div>
        <div className="sub_container">
          <div className="item_top">
            <img src={AffPayoutIcon} className="item_icon" />
            <span className="nowrap">#Affiliate Payouts</span>
          </div>
          <div className="item_bottom">
            <span className="txt_bottom">8024</span>
            <MdOutlineArrowDropUp color="green" className="item_icon" />
            {/* <MdOutlineArrowDropDown color="red" size={10} /> */}
          </div>
        </div>
        <div className="sub_container">
          <div className="item_top">
            <img src={NodePayoutIcon} className="item_icon" />
            <span className="nowrap">#Node Payouts</span>
          </div>
          <div className="item_bottom">
            <span className="txt_bottom">4014</span>
            <MdOutlineArrowDropUp color="green" className="item_icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderItemlist
