import React from 'react'
import { useGetThemeMode } from 'state/theme/hook'
import { FlexRow, FlexRowContent, SubContainer, SubTitleText } from '../../styled'

export const CrossBridge: React.FC = () => {
  const themeMode = useGetThemeMode()

  return (
    <SubContainer width={'20%'} height={'200px'} themeMode={themeMode}>
      <FlexRowContent>
        <SubTitleText fontSize={'12px'}>CROSS THE BRIDGE</SubTitleText>
      </FlexRowContent>
    </SubContainer>
  )
}
