import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { PGetQuantityReport, TRGetComplexReport, TRgetTotalAmountReport, TRLastSalesReport } from './api'

export interface AffiliatesState {
  referralCode: string
  quantityReport: PGetQuantityReport
  complexReport: TRGetComplexReport
  lastSalesReport: TRLastSalesReport
  totalAmountReport: null | TRgetTotalAmountReport
}

export const initialState: AffiliatesState = {
  referralCode: '',
  quantityReport: [],
  complexReport: [],
  lastSalesReport: [],
  totalAmountReport: null,
}

const affiliatesStateSlice = createSlice({
  name: 'affiliates',
  initialState,
  reducers: {
    setReferralCode(state, action: PayloadAction<string>) {
      state.referralCode = action.payload
    },
    setQuantityReport(state, action: PayloadAction<AffiliatesState['quantityReport']>) {
      state.quantityReport = action.payload
    },
    delQuantityReport(state) {
      state.quantityReport = initialState.quantityReport
    },

    setComplexReport(state, action: PayloadAction<AffiliatesState['complexReport']>) {
      state.complexReport = action.payload
    },
    delComplexReport(state) {
      state.complexReport = initialState.complexReport
    },
    setLastSalesReport(state, action: PayloadAction<AffiliatesState['lastSalesReport']>) {
      state.lastSalesReport = action.payload
    },
    delLastSalesReport(state) {
      state.lastSalesReport = initialState.lastSalesReport
    },
    setTotalAmountReport(state, action: PayloadAction<AffiliatesState['totalAmountReport']>) {
      state.totalAmountReport = action.payload
    },
    delTotalAmountReport(state) {
      state.totalAmountReport = initialState.totalAmountReport
    },
  },
})

export const {
  setReferralCode,
  setQuantityReport,
  delQuantityReport,
  setComplexReport,
  delComplexReport,
  setLastSalesReport,
  delLastSalesReport,
  setTotalAmountReport,
  delTotalAmountReport,
} = affiliatesStateSlice.actions
export const affiliatesReducer = affiliatesStateSlice.reducer
