import { createSlice } from '@reduxjs/toolkit'

export type TSearchSymbol = '#' | '$' | '/' | '@' | '%' | '~' | '()' | '!' | '+' | '-' | '&' | '^' | '*' | '_' | '='

interface IState {
  searchSymbol: TSearchSymbol
}

export const initialState: IState = {
  searchSymbol: '#',
}

const searchSymbolSlice = createSlice({
  name: 'searchSymbol',
  initialState,
  reducers: {
    setSearchSymbol(state, action) {
      state.searchSymbol = action.payload
      localStorage.setItem('searchSymbol', action.payload)
    },
  },
})

export const { setSearchSymbol } = searchSymbolSlice.actions
export default searchSymbolSlice.reducer
