import React from 'react'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'
import styled from 'styled-components'
import { BridgeData } from './components/BridgeData'
import { DomainAnalytics } from './components/DomainAnalytics'
import { MonthlyRevenue } from './components/MonthlyRevenue'
import { PurchaseChart } from './components/PurchaseChart'
import { UnusedFunds } from './components/UnusedFunds'
import { DatePickerContextProvider } from './contexts/DatePickerContext'
import { FlexRow } from './styled'

const DashboardContainer = styled.div`
  width: 100%;
  font-size: 14px;
`

const AdminDashboardContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `calc(100% - ${width})`};
  z-index: 0;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const AdminDashboard: React.FC = () => {
  const isCollapseMenu = useIsCollapseMenu()

  return (
    <DatePickerContextProvider>
      <AdminDashboardContainer width={isCollapseMenu ? '65px' : '235px'}>
        <FlexRow>
          <PurchaseChart />
          <UnusedFunds />
          <MonthlyRevenue />
        </FlexRow>
        <FlexRow margin={'20px 0'}>
          <DomainAnalytics />
        </FlexRow>
        <FlexRow>
          <BridgeData />
        </FlexRow>
      </AdminDashboardContainer>
    </DatePickerContextProvider>
  )
}

export default AdminDashboard
