import { chain, fold } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/pipeable'
import { Errors, getRestError, Response, restReq } from '../../../utils/restReq'
import { ICreateAssets, TAremoveAsset } from '../types'

export const createAssets = async (params: ICreateAssets, token: string): Promise<Error | ICreateAssets[]> => {
  const data = await restReq<ICreateAssets, ICreateAssets[]>('portfolio.createAssets')(params, token)
  return pipe(
    data,
    chain(getRestError),
    fold<Errors, Response<ICreateAssets[]>, Error | ICreateAssets[]>(
      (data: any) => {
        throw data
      },
      (data: any) => data.result
    )
  )
}

export const removeAsset = async (
  params: TAremoveAsset['payload'],
  token: string
): Promise<Error | ICreateAssets[]> => {
  const data = await restReq<TAremoveAsset['payload'], ICreateAssets[]>('portfolio.removeAsset')(params, token)
  return pipe(
    data,
    chain(getRestError),
    fold<Errors, Response<ICreateAssets[]>, Error | ICreateAssets[]>(
      (data: any) => {
        throw data
      },
      (data: any) => data.result
    )
  )
}

export const getPortfolioList = async (token: string): Promise<Error | ICreateAssets[]> => {
  const data = await restReq<Object, ICreateAssets[]>('portfolio.getPortfolioList')({}, token)
  return pipe(
    data,
    chain(getRestError),
    fold<Errors, Response<ICreateAssets[]>, Error | ICreateAssets[]>(
      (data: any) => {
        throw data
      },
      (data: any) => data.result
    )
  )
}

export interface IGetMHCTokenBalanceP {
  address: string
}

export const getMHCTokenBalance = async (params: IGetMHCTokenBalanceP, token: string): Promise<Error | number> => {
  return pipe(
    await restReq<IGetMHCTokenBalanceP, number>('transaction.getUserMHCBalance')(params, token),
    chain(getRestError),
    fold<Errors, Response<number>, Error | number>(
      (data: any) => {
        throw data
      },
      (data: any) => data.result
    )
  )
}
