import { createAction } from 'typesafe-actions'
import { TPPreviewTemplate, TPSetTemplate } from './api'

export const fetchGetTmpl = createAction('@@SendEmailAdmin/fetchGetTmpl')()
export const fetchSetTmpl = createAction('@@SendEmailAdmin/fetchSetTmpl')<string>()
export const fetchGetTemplateList = createAction('@@SendEmailAdmin/fetchGetTemplateList')()
export const fetchGetTemplate = createAction('@@SendEmailAdmin/fetchGetTemplate')<string>()

export const fetchSetTemplate = createAction('@@SendEmailAdmin/fetchSetTemplate')<TPSetTemplate>()
export const fetchPreviewTemplate = createAction('@@SendEmailAdmin/fetchPreviewTemplate')<TPPreviewTemplate>()
export const fetchSetStatusTemplate = createAction('@@SendEmailAdmin/fetchSetStatusTemplate')<{
  code: string
  status: boolean
}>()
