import { UseDomainCommand } from 'components/DomainsCommand/hooks'
import { useMenuAudio } from 'components/DomainsDashboardTable/hooks'
import MenuIcons from 'components/Icons/MenuIcons'
import { CAPSLOCK_LOGIN_URL } from 'constants/urls'
import { useCommonCheck } from 'hooks/useCommon'
import React, { useState } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import './styles.scss'

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: 280px;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid;
  font-size: 14px;
  margin-right: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
  }
  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
`
const IconWrapper = styled.div<{
  width: string
  height: string
  bgColor: string
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`

interface RowP {
  item: any
  themeMode: string
}
function Row({ item, themeMode }: RowP) {
  return (
    <tr>
      <td className="email_user">
        <IconWrapper width={'40px'} height={'40px'} bgColor={item.bgColor}>
          {item.userTxt}
        </IconWrapper>
      </td>
      <td>
        <span className="email-title">{item.title}</span> <br />
        <span className="email-time">{item.content}</span>
      </td>
      <td>
        <span className="email-time">{item.time}</span>
      </td>
    </tr>
  )
}

const CommandCapslock = () => {
  const { generateColor, generateUserTxt, splitText } = UseDomainCommand()
  const { hashCode, intToRGB } = useCommonCheck()
  const themeMode = useGetThemeMode()
  const { playAudio } = useMenuAudio()
  const EmailList = [
    {
      id: 0,
      time: '06:26 PM',
      title: 'Your the super slave',
      content:
        'Hey! Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      user: '#cluster',
    },
    {
      id: 1,
      time: '07:26 PM',
      title: 'This is great',
      content: 'As always, great work. looks good',
      user: '#blackHorse',
    },
    {
      id: 2,
      time: '08:26 PM',
      title: 'Gambeling on the go',
      content: 'Get up to 50% cashback on',
      user: '#robert',
    },
    {
      id: 3,
      time: '09:26 PM',
      title: 'You born to be handsome',
      content: 'Hey! I am really lorem ipsum',
      user: '#ivanslab',
    },
    {
      id: 4,
      time: '09:26 PM',
      title: 'Thank you for signing up with Jarvis Cole. Enjoy yourself with it',
      content: 'WELCOME TO JARVIS COLE. Thank you for signing up with Jarvis Cole, the leaders in eCommerce',
      user: '#georgy',
    },
    {
      id: 5,
      time: '09:26 PM',
      title: 'Thank you for signing up with Jarvis Cole. Enjoy yourself with it',
      content: 'WELCOME TO JARVIS COLE. Thank you for signing up with Jarvis Cole, the leaders in eCommerce',
      user: '#plannick',
    },
    {
      id: 6,
      time: '09:26 PM',
      title: 'Thank you for signing up with Jarvis Cole. Enjoy yourself with it',
      content: 'WELCOME TO JARVIS COLE. Thank you for signing up with Jarvis Cole, the leaders in eCommerce',
      user: '#vabblek',
    },
  ]

  return (
    <Container
      style={{
        background: themeMode == 'main' ? '#00000090' : '#ffffff90',
        borderColor: themeMode == 'main' ? '#02569d' : 'gray',
      }}
    >
      <div className="top-item">
        <div className="top-left" onClick={() => playAudio('capslock')}>
          <MenuIcons url="dmail-white" themeMode={themeMode} size={'18px'} />
          <h3 style={{ marginLeft: '-20px' }}>
            <a href={CAPSLOCK_LOGIN_URL} target="_self" style={{ color: themeMode === 'light' ? 'black' : 'white' }}>
              CAPSLOCK
            </a>
          </h3>
        </div>
        <a href={CAPSLOCK_LOGIN_URL} onClick={() => playAudio('capslock')} target="_blank" rel="noreferrer">
          <FiExternalLink size={20} color={themeMode === 'light' ? '#000' : '#fff'} />
        </a>
      </div>
      <div className="capslock-bottom-item">
        <table
          className="spacebar-table"
          cellSpacing="0"
          cellPadding="10"
          width="100%"
          style={{
            background:
              themeMode == 'main' ? 'rgba(0, 0, 0, 0.5)' : themeMode == 'light' ? 'white' : 'rgba(0, 0, 0, 0.5)',
            color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
          }}
        >
          <tbody>
            {EmailList.map((item, index) => {
              let keyVal = item.id + '-' + index
              const bgColor = '#' + intToRGB(hashCode(item.user + '$$$')) //generateColor(item.user)
              const userTxt = generateUserTxt(item.user)
              const title = splitText(item.title, 20)
              const content = splitText(item.content, 30)
              const showItem = {
                userTxt: userTxt,
                bgColor: bgColor,
                title: title,
                content: content,
                time: item.time,
              }
              return <Row key={keyVal} item={showItem} themeMode={themeMode} />
            })}
          </tbody>
        </table>
      </div>
    </Container>
  )
}
export default CommandCapslock
