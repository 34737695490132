import React from 'react'
import { IoMdArrowDropdown } from 'react-icons/io'
import cn from './cn.module.scss'

interface IPercentValue {
  percent: number
}
const RedPercent: React.FC<IPercentValue> = ({ percent }) => {
  return (
    <span className={cn.redPercent}>
      <IoMdArrowDropdown />
      {Math.abs(percent).toFixed(2)}%
    </span>
  )
}

export default RedPercent
