import styled from 'styled-components'

export const FormContainer = styled.form`
  color: white;
  margin-top: 50px;
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    // margin-top: -100px;
  }
`
export const FormTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center;
  width: 100%;
`
export const FormButton = styled.button`
  border: none;
  outline: none;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: black;
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: 'Montserrat', sans-serif !important;
  cursor: pointer;
  margin-top: 30px;
  border-radius: 50px;
  &:hover {
    background: linear-gradient(#fbb212, #f48f33);
  }
  &:active {
    background: linear-gradient(#f48f33, #fbb212);
  }
  @media screen and (max-width: 768px) {
    height: 50px;
    margin-top: 20px;
  }
`
