import { Record } from 'immutable'
import React, { useEffect, useMemo, useReducer } from 'react'
import { connect } from 'react-redux'
import Switch from 'react-switch'
import { useGetThemeMode } from 'state/theme/hook'
import { onGetSettings, onSetSettings, TonSetSettings } from '../../../actions/hashtag'
import FormInput from '../../../components/FormInput'
import FormSelect from '../../../components/FormSelect'
import { Init as Actons } from '../../../redusers/actions'
import { SettingItems } from '../../../utils/api'
import cn from './cn.module.css'

interface SettingsP {
  settings: SettingItems
  onSetSettings: (v: TonSetSettings['payload']) => TonSetSettings
  onGetSettings: Function
  isLoadFSeting: boolean
}

const onChangeDP =
  (dispatch: Function, type: itemKeys, dispatchError: Function, name: string, oldData: SettingItems['domain_price']) =>
  (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value
    if (val !== '') {
      const reg = /^\d+?\.?\d*$/
      if (!reg.test(val)) return

      const [_, float = ''] = val.split('.')
      if (float.length > 2) return

      const numAmount = Number.parseInt(val)

      if (numAmount > 1000000) return
    }

    const newData = oldData.map((item) => {
      if (item.name === name) {
        return { name, value: val }
      }
      return item
    })

    dispatch({ type: type, val: newData })
    dispatchError({ type: type, val: '' })
  }
const onChangeNumber =
  (dispatch: Function, type: itemKeys, dispatchError: Function) => (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value
    if (val === '') {
      return dispatch({ type: type, val: '' })
    }

    const reg = /^\d+?\.?\d*$/
    if (!reg.test(val)) return null

    const [_, float = ''] = val.split('.')
    if (float.length > 2) return null

    const numAmount = Number.parseInt(val)

    if (numAmount > 9999) return null
    if (numAmount > 255 && ['period_before_domain_renewal'].includes(type)) return null
    if (numAmount > 100 && ['discount', 'resale_commission'].includes(type)) return null

    dispatch({ type: type, val: e.currentTarget.value })
    dispatchError({ type: type, val: false })
  }
const onChangeString =
  (dispatch: Function, type: itemKeys, dispatchError: Function) => (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value

    dispatch({ type: type, val: val })
    dispatchError({ type: type, val: false })
  }

const selectChange = (dispatch: Function, type: itemKeys) => (e: React.FormEvent<HTMLSelectElement>) => {
  dispatch({ type: type, val: e.currentTarget.value })
}

export interface Item {
  domain_price: SettingItems['domain_price']
  renewal_price: number
  keyword_price: number
  stop_sale: boolean
  pay_mhc_address: string
  pay_buff_address: string
  discount: number
  resale_commission: string
  referral: SettingItems['referral']
  referral_reward: number
  notification: boolean
  period_before_domain_renewal: number
}

export interface ItemError {
  domain_price: string
  renewal_price: boolean
  keyword_price: boolean
  pay_mhc_address: boolean
  pay_buff_address: boolean
  discount: boolean
  resale_commission: boolean
  referral_reward: boolean
  period_before_domain_renewal: boolean
}

export let initialState: Item = {
  domain_price: [],
  renewal_price: 0,
  keyword_price: 0,
  stop_sale: false,
  pay_mhc_address: '',
  pay_buff_address: '',
  discount: 0,
  resale_commission: '0',
  referral: 'on',
  referral_reward: 0,
  notification: false,
  period_before_domain_renewal: 0,
}
export let initialError: ItemError = {
  domain_price: '',
  renewal_price: false,
  keyword_price: false,
  pay_mhc_address: false,
  pay_buff_address: false,
  discount: false,
  resale_commission: false,
  referral_reward: false,
  period_before_domain_renewal: false,
}

export type itemKeys = keyof Item

export const State: Record.Factory<Item> = Record(initialState)

export const reducer = function (
  state: Record<Item> = new State(),
  action: {
    type: itemKeys
    val: number | boolean | string | SettingItems['domain_price']
  }
): Record<Item> {
  return state.set(action.type, action.val)
}

export const StateError: Record.Factory<ItemError> = Record(initialError)
export type itemKeysError = keyof ItemError
export const reducerError = function (
  state: Record<ItemError> = new StateError(),
  action: { type: itemKeysError; val: boolean }
): Record<ItemError> {
  return state.set(action.type, action.val)
}

const onSubmit =
  (state: Record<Item>, dispatchError: Function, onSetSettings: SettingsP['onSetSettings']) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const mhcAdr: string = state.get('pay_mhc_address')
    const buffAdr: string = state.get('pay_buff_address')
    const resale_commission = state.get('resale_commission')

    let errorPriseTag = ''
    state.get('domain_price').forEach((item) => {
      const numPrice = Number.parseFloat(item.value.toString())
      if (isNaN(numPrice)) {
        errorPriseTag = item.name
      }
    })

    if (errorPriseTag !== '') {
      dispatchError({ type: 'domain_price', val: errorPriseTag })
      return
    }

    if (mhcAdr === '') {
      dispatchError({ type: 'pay_mhc_address', val: true })
      return
    }

    if (buffAdr === '') {
      dispatchError({ type: 'pay_buff_address', val: true })
      return
    }

    const numKeyword_price = Number.parseFloat(state.get('keyword_price').toString())
    if (isNaN(numKeyword_price)) {
      dispatchError({ type: 'keyword_price', val: true })
      return
    }

    const numRenewalPrice = Number.parseFloat(state.get('renewal_price').toString())
    if (isNaN(numRenewalPrice) || numRenewalPrice == 0) {
      dispatchError({ type: 'renewal_price', val: true })
      return
    }

    if (resale_commission === '') {
      dispatchError({ type: 'resale_commission', val: true })
      return
    }

    onSetSettings({
      domain_price: state.get('domain_price'),
      renewal_price: numRenewalPrice,
      keyword_price: numKeyword_price,
      stop_sale: state.get('stop_sale'),
      pay_mhc_address: mhcAdr,
      pay_buff_address: buffAdr,
      discount: state.get('discount'),
      resale_commission: parseFloat(state.get('resale_commission')),
      referral: state.get('referral'),
      referral_reward: state.get('referral_reward'),
      notification: state.get('notification'),
      period_before_domain_renewal: state.get('period_before_domain_renewal'),
    })
  }

const switchChange = (dispatch: Function) => (checked: boolean) => {
  dispatch({ type: 'stop_sale', val: !checked })
}
const switchN = (dispatch: Function) => (checked: boolean) => {
  dispatch({ type: 'notification', val: checked })
}

function Settings({ settings, onSetSettings, onGetSettings, isLoadFSeting }: SettingsP) {
  const [state, dispatch] = useReducer(reducer, new State())
  const [stateError, dispatchError] = useReducer(reducerError, new StateError())

  useEffect(() => {
    onGetSettings()
  }, [])

  useEffect(() => {
    let prop: keyof Item
    for (prop in initialState) {
      if (settings[prop] !== undefined) {
        dispatch({ type: prop, val: settings[prop] })
      }
    }
  }, [settings])

  const payPeriod = useMemo(() => {
    const d = settings.pay_period / 60 / 60 / 24
    return d.toString(10)
  }, [settings])

  const themeMode = useGetThemeMode()

  return (
    <div className={cn.wraper}>
      <form
        className={cn.form}
        onSubmit={onSubmit(state, dispatchError, onSetSettings)}
        style={{
          background: themeMode === 'light' ? 'white' : '',
          color: themeMode === 'light' ? 'black' : 'white',
        }}
      >
        <div className={cn.formSetting}>
          <p className={cn.formTit}>General settings</p>
          <div className={cn.formRow}>
            {state.get('domain_price').map((item) => {
              return (
                <div className={cn.threecol} key={item.name}>
                  <FormInput
                    value={item.value + ''}
                    onChange={onChangeDP(dispatch, 'domain_price', dispatchError, item.name, state.get('domain_price'))}
                    label={`Domain price`}
                    error={stateError.get('domain_price') === item.name}
                    themeMode={themeMode}
                  />
                  <div className={cn.mark}>USD</div>
                </div>
              )
            })}
            <div className={cn.threecol}>
              <FormInput
                value={state.get('keyword_price').toString()}
                onChange={onChangeNumber(dispatch, 'keyword_price', dispatchError)}
                label="Keyword price"
                error={stateError.get('keyword_price')}
                themeMode={themeMode}
              />
              <span className={cn.mark}>USD</span>
            </div>
          </div>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <FormInput
                value={state.get('discount').toString()}
                onChange={onChangeNumber(dispatch, 'discount', dispatchError)}
                label="Discount"
                error={stateError.get('discount')}
                themeMode={themeMode}
              />
              <span className={cn.mark}>%</span>
            </div>
            <div className={cn.threecol}>
              <FormInput
                value={state.get('resale_commission').toString()}
                onChange={onChangeNumber(dispatch, 'resale_commission', dispatchError)}
                label="Percentage of the deal"
                error={stateError.get('resale_commission')}
                themeMode={themeMode}
              />
              <span className={cn.mark}>%</span>
            </div>
          </div>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <FormSelect
                label="Affiliates"
                option={[
                  ['on', 'on'],
                  ['off', 'off'],
                  ['custom', 'custom'],
                ]}
                value={state.get('referral') ?? 'on'}
                onChange={selectChange(dispatch, 'referral')}
                defoltDescription={'Select'}
                themeMode={themeMode}
              />
            </div>
            <div className={cn.threecol}>
              <FormInput
                value={state.get('referral_reward').toString()}
                onChange={onChangeNumber(dispatch, 'referral_reward', dispatchError)}
                label="Referral reward"
                error={stateError.get('referral_reward')}
                themeMode={themeMode}
              />
              <span className={cn.mark}>%</span>
            </div>
          </div>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <FormInput
                value={state.get('period_before_domain_renewal').toString()}
                onChange={onChangeNumber(dispatch, 'period_before_domain_renewal', dispatchError)}
                label="Period before domain renewal"
                error={stateError.get('period_before_domain_renewal')}
                themeMode={themeMode}
              />
              <span className={cn.mark}>days</span>
            </div>
          </div>
          <div className={cn.threecol}>
            <FormInput
              value={state.get('renewal_price').toString()}
              onChange={onChangeNumber(dispatch, 'renewal_price', dispatchError)}
              label="Renewal price"
              error={stateError.get('renewal_price')}
              themeMode={themeMode}
            />
            <span className={cn.mark}>USD</span>
          </div>
        </div>
        <div className={cn.formSetting}>
          <p className={cn.formTit}>Payment settings</p>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <FormInput label="Pay periods (days)" readOnly={true} value={payPeriod} themeMode={themeMode} />
            </div>
          </div>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <FormInput label="Paypal address" readOnly={true} value={'Hashtag@mhc.com'} themeMode={themeMode} />
            </div>
          </div>
          <div className={cn.formRow}>
            <div className={`${cn.threecol} ${cn.long}`}>
              <FormInput
                label="MHC address"
                value={state.get('pay_mhc_address')}
                error={stateError.get('pay_mhc_address')}
                onChange={onChangeString(dispatch, 'pay_mhc_address', dispatchError)}
                themeMode={themeMode}
              />
            </div>
          </div>
          <div className={cn.formRow}>
            <div className={`${cn.threecol} ${cn.long}`}>
              <FormInput
                label="BUFF MarketPlace address"
                value={state.get('pay_buff_address')}
                error={stateError.get('pay_buff_address')}
                onChange={onChangeString(dispatch, 'pay_buff_address', dispatchError)}
                themeMode={themeMode}
              />
            </div>
          </div>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <label className={cn.switchlabel}>
                <span>Sale available</span>
                <Switch
                  onChange={switchChange(dispatch)}
                  checked={!state.get('stop_sale')}
                  className={cn.switch}
                  onColor={themeMode === 'main' ? '#f9a71d' : themeMode === 'light' ? '#8790ea' : '#582c63'}
                />
              </label>
            </div>
            <div className={cn.threecol}>
              <label className={cn.switchlabel}>
                <span>Notification</span>
                <Switch
                  onChange={switchN(dispatch)}
                  checked={state.get('notification')}
                  className={cn.switch}
                  onColor={themeMode === 'main' ? '#f9a71d' : themeMode === 'light' ? '#8790ea' : '#582c63'}
                />
              </label>
            </div>
          </div>
          <div className={cn.formRow}>
            <p className={cn.formTit}></p>
            <button
              disabled={isLoadFSeting}
              className={cn.formBtn}
              style={{
                background:
                  themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                color: themeMode == 'main' ? 'white' : 'black',
              }}
            >
              SAVE
            </button>
          </div>
        </div>
        {/* <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <p className={cn.formTit}></p>
            <button
              disabled={isLoadFSeting}
              className={cn.formBtn}
              style={{
                background:
                  themeMode == 'main'
                    ? 'linear-gradient(#ffc500, #ef744a)'
                    : themeMode == 'light'
                    ? 'linear-gradient(#b7eaf6, #777fc4)'
                    : 'linear-gradient(#873f99, #311438)',
              }}
            >
              SAVE
            </button>
          </div>
        </div> */}
      </form>
    </div>
  )
}

function mapStateToProps({ actions }: { actions: Record<Actons> }) {
  return {
    settings: actions.get('settings'),
    isLoadFSeting: actions.get('isLoadFSeting'),
  }
}

export default connect(mapStateToProps, { onGetSettings, onSetSettings })(Settings)
