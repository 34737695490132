import React from 'react'
import css from './styles.module.scss'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { shallowEqual, useSelector } from 'react-redux'
import { AppState } from '../../../redusers/config'
import { SendEmailAdmin } from '../../../views/Admin/settingEmail/reduser'

const modalRoot: Element = document.getElementById('root-modal') as Element

interface ModalP {
  onCloseModal?: () => void
}

const Modal: React.FC<ModalP> = ({ onCloseModal }) => {
  const previewTemplate = useSelector<AppState, SendEmailAdmin['previewTemplate']>(
    ({ sendEmailAdminReducer }) => sendEmailAdminReducer.previewTemplate,
    shallowEqual
  )

  const previewData = previewTemplate.get('data')

  return (
    <div className={css.modal}>
      <div className={css.content}>
        <div className={css.email}>
          <div dangerouslySetInnerHTML={{ __html: previewData?.body ?? '' }} />
        </div>
      </div>
      <div className={css.actions}>
        <button onClick={onCloseModal}>
          <span>CLOSE</span>
        </button>
      </div>
    </div>
  )
}

const timeout = { enter: 500, exit: 100 }

interface UnifiedModalP {
  showModal: boolean
  onCloseModal?: () => void
}

const EmailPreviewModal: React.FC<UnifiedModalP> = ({ showModal, onCloseModal }) => {
  return createPortal(
    <CSSTransition classNames="animated" in={showModal} unmountOnExit timeout={timeout}>
      <Modal onCloseModal={onCloseModal} />
    </CSSTransition>,
    modalRoot
  )
}

export default EmailPreviewModal
