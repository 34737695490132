import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import cn from './cn.module.scss'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { IoIosClose } from 'react-icons/io'
import { FaCaretDown } from 'react-icons/fa'
import { GradientButton } from '../../../../components/Buttons/GradientButton'
import NetworkList from '../networkList'
import WalletList from '../WalletList'
import { Config, defaultConnector } from '../WalletList/config'
import connectors from '../WalletList/config'
import FormInput from '../../../../components/FormInput'
import EthereumTokenList from '../EthereumTokenList'
import { defaultNetwork, ethereumTokenList, binanceTokenList, mhcTokenList } from '../../blockchainAssets'
import { ICreateAssets, INetwork, ITokenP } from '../../types'
import { useGetTokenInfo, useGetTokenPrice, useValidateWalletAddress } from '../../hooks'
import { createAssets, getMHCTokenBalance } from '../../actions'

const modalRoot: Element = document.getElementById('root-modal') as Element

const onChangeWalletAddress =
  (setVal: (v: string) => void, setIsValidated: (v: string | false) => void, selectedNetwork: INetwork) =>
  (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value

    if (selectedNetwork.name !== 'MetaHash') {
      setIsValidated(useValidateWalletAddress(val))
    } else {
      if (val.match(/^(0x)?[0-9a-fA-F]{50}$/)) {
        setIsValidated(val)
      } else {
        setIsValidated(false)
      }
    }

    setVal(val)
  }

const onChangeCustomTokenAddress =
  (
    setVal: (v: ITokenP) => void,
    setCustomTokenAddress: (v: string) => void,
    setIsValidatedToken: (v: string | false) => void,
    walletAddress: string,
    selectedNetwork: INetwork
  ) =>
  (e: React.FormEvent<HTMLInputElement>) => {
    if (selectedNetwork.name === 'MetaHash') return
    const val = e.currentTarget.value
    const validatedAddress = useValidateWalletAddress(val)
    setIsValidatedToken(validatedAddress)

    if (validatedAddress === false) {
      return
    }

    setCustomTokenAddress(validatedAddress)

    useGetTokenInfo(selectedNetwork.name === 'Ethereum' ? 1 : 56, validatedAddress, walletAddress).then(
      (customToken) => {
        setVal(customToken)
      }
    )
  }

interface ModalP {
  onClose: (e: React.FormEvent<HTMLElement>) => void
}
const Modal: React.FC<ModalP> = ({ onClose }) => {
  const [selectedNetwork, setSelectedNetwork] = useState<INetwork>(defaultNetwork)
  const [isOpenWalletList, setIsOpenWalletList] = useState<boolean>(false)
  const [selectedWallet, setSelectedWallet] = useState<Config>(defaultConnector)
  const [walletAddress, setWalletAddress] = useState<string>('')
  const [isValidated, setIsValidated] = useState<string | false>('')
  const [isOpenTokenList, setIsOpenTokenList] = useState<boolean>(false)
  const [tokenList, setTokenList] = useState<ITokenP[]>(mhcTokenList)
  const [selectedToken, setSelectedToken] = useState<ITokenP | undefined>()
  const [isValidatedToken, setIsValidatedToken] = useState<string | false>(false)
  const [customTokenAddress, setCustomTokenAddress] = useState<string>('')
  const [selectedTokenPrice, setSelectedTokenPrice] = useState<number | false | undefined>(undefined)

  const dispatch = useDispatch()
  const currentTokenPrice = useGetTokenPrice(selectedNetwork.name, selectedToken?.address.toLowerCase())

  useEffect(() => {
    return () => {
      initState()
    }
  }, [])

  useEffect(() => {
    setWalletAddress('')
    setSelectedToken(undefined)
    if (selectedNetwork.name === 'MetaHash') {
      setSelectedWallet(defaultConnector)
      setTokenList(mhcTokenList)
    } else if (selectedNetwork.name === 'Ethereum') {
      setSelectedWallet(connectors[1])
      setTokenList(ethereumTokenList)
    } else {
      setSelectedWallet(connectors[1])
      setTokenList(binanceTokenList)
    }
  }, [selectedNetwork])

  useEffect(() => {
    if (!selectedToken) {
      setSelectedTokenPrice(undefined)
    } else {
      if (currentTokenPrice === false) {
        setSelectedTokenPrice(0)
      } else {
        setSelectedTokenPrice(currentTokenPrice)
      }
    }
  }, [currentTokenPrice])

  const initState = () => {
    setSelectedNetwork(defaultNetwork)
    setSelectedWallet(defaultConnector)
    setWalletAddress('')
    setSelectedToken(undefined)
    setCustomTokenAddress('')
  }

  const openTokenList = (isOpen: boolean) => {
    if (isOpen) {
      setCustomTokenAddress('')
    }

    dispatch(getMHCTokenBalance({ address: walletAddress }))
    setIsOpenTokenList(isOpen)
  }

  const onCreateAssets = async (
    event: React.FormEvent<HTMLElement>,
    onClose: (e: React.FormEvent<HTMLElement>) => void
  ) => {
    if (selectedToken && selectedTokenPrice !== undefined && selectedTokenPrice !== false) {
      const asset: ICreateAssets['asset'] = {
        id: 0,
        chainId: selectedToken?.chainId,
        network: selectedNetwork.name,
        walletAddress: walletAddress,
        symbol: selectedToken?.symbol,
        tokenName: selectedToken?.name,
        tokenAddress: selectedToken ? selectedToken.address.toLowerCase() : '',
        logo: selectedToken?.logoURI,
        amount: selectedToken?.balance!,
        price: selectedTokenPrice,
      }

      dispatch(createAssets({ asset }))
      onClose(event)
    } else {
      return
    }
  }

  return (
    <div className="modal">
      <div className={cn.modalContent}>
        <div className={cn.headerContent}>
          <p>Create Assets</p>
          <div className="modal-close" onClick={onClose}>
            <IoIosClose size={24} />
          </div>
        </div>

        <div className={cn.bodyContent}>
          <div className={cn.labelText}>Select a Network</div>
          <NetworkList setSelectedNetwork={setSelectedNetwork} />

          <div className={cn.labelText}>Select a Wallet</div>
          <div className={cn.walletWrapper}>
            <GradientButton width={'35%'} height={'42px'} onClick={() => setIsOpenWalletList(true)}>
              {selectedWallet.title}
              <FaCaretDown />
            </GradientButton>
            {isOpenWalletList && (
              <>
                <div
                  onClick={() => setIsOpenWalletList(false)}
                  style={{
                    display: isOpenWalletList ? 'block' : 'none',
                    width: '200vw',
                    height: '200vh',
                    backgroundColor: 'transparent',
                    position: 'fixed',
                    zIndex: 0,
                    top: 0,
                    left: 0,
                  }}
                />
                <WalletList
                  selectedNetwork={selectedNetwork}
                  selectedWallet={setSelectedWallet}
                  setIsOpenWalletList={setIsOpenWalletList}
                />
              </>
            )}
            <div className={cn.walletAddressWrapper}>
              <FormInput
                value={walletAddress}
                placeholder={'Add Selected Wallet Address'}
                onChange={onChangeWalletAddress(setWalletAddress, setIsValidated, selectedNetwork)}
                isFocusStyle={true}
                error={!isValidated}
              />
            </div>
          </div>

          <div className={cn.tokenWrapper}>
            <div className={cn.tokenSelectionWrapper}>
              <div className={cn.labelText}>Select a Token</div>
              <GradientButton
                height={'42px'}
                disabled={isValidated === false || walletAddress === ''}
                onClick={() => openTokenList(true)}
              >
                {selectedToken === undefined ? (
                  'Select Token'
                ) : (
                  <div className={cn.selectedTokenWrapper}>
                    <img src={selectedToken.logoURI} width="20px" height="20px" alt="" />
                    <span>{selectedToken.symbol}</span>
                  </div>
                )}
                <FaCaretDown />
              </GradientButton>
              {isOpenTokenList && (
                <>
                  <div
                    onClick={() => openTokenList(false)}
                    style={{
                      display: isOpenTokenList ? 'block' : 'none',
                      width: '200vw',
                      height: '200vh',
                      backgroundColor: 'transparent',
                      position: 'fixed',
                      zIndex: 0,
                      top: 0,
                      left: 0,
                    }}
                  />
                  <EthereumTokenList
                    selectedNetwork={selectedNetwork}
                    selectedWallet={selectedWallet}
                    walletAddress={walletAddress}
                    tokenList={tokenList}
                    selectedToken={setSelectedToken}
                    setIsOpenTokenList={setIsOpenTokenList}
                  />
                </>
              )}
            </div>

            <div className={cn.customTokenWrapper}>
              <div className={cn.labelText}>Add Custom Token Address if your Asset does not exist in the list</div>
              <div className={cn.customTokenAddress}>
                <FormInput
                  value={customTokenAddress}
                  placeholder={'0x000'}
                  onChange={onChangeCustomTokenAddress(
                    setSelectedToken,
                    setCustomTokenAddress,
                    setIsValidatedToken,
                    walletAddress,
                    selectedNetwork
                  )}
                  isFocusStyle={true}
                  error={!isValidatedToken}
                />
              </div>
            </div>
          </div>

          <div className={cn.tokenDetailWrapper}>
            {selectedToken !== undefined && <div className={cn.labelText}>Amount: {selectedToken?.balance}</div>}
          </div>

          <div className={cn.createAssetsBtn}>
            <GradientButton
              width={'240px'}
              onClick={(e) => onCreateAssets(e, onClose)}
              disabled={selectedToken === undefined || selectedToken.balance === 0}
            >
              Create Assets
            </GradientButton>
          </div>
        </div>
      </div>
    </div>
  )
}

const timeout = { enter: 500, exit: 100 }

interface IMainModal {
  showModal: boolean
  onClose: (e: React.FormEvent<HTMLElement>) => void
}

const AddAssetsMainModal: React.FC<IMainModal> = ({ showModal, onClose }) => {
  return createPortal(
    <CSSTransition classNames="animated" in={showModal} unmountOnExit timeout={timeout}>
      <Modal onClose={onClose} />
    </CSSTransition>,
    modalRoot
  )
}

export default AddAssetsMainModal
