import React from 'react'
import { useGetThemeMode } from 'state/theme/hook'
import './styles.scss'

interface LoginFormInpProp {
  label: string
  name?: string
  type?: string
  isError?: boolean
  onChange?: (val: string) => void
  value?: string
  themeMode?: string
}

const changeInput = (onChange: LoginFormInpProp['onChange']) => (e: React.FormEvent<HTMLInputElement>) => {
  const val = e.currentTarget.value
  if (typeof onChange === 'function') onChange(val)
}

const LoginFormInp: React.FC<LoginFormInpProp> = ({
  label,
  type = 'text',
  isError = false,
  onChange = () => {},
  value = '',
  name,
  themeMode = null,
}) => {
  return (
    <div className={`login-inp ${isError ? 'error' : ''}`}>
      <label htmlFor="" style={{ color: themeMode == 'main' ? 'white' : 'black' }}>
        {label}
      </label>
      <input type={type} name={name} onChange={changeInput(onChange)} value={value} />
    </div>
  )
}
export default LoginFormInp
