import { fork, put, takeLatest, delay, call, all } from '@redux-saga/core/effects'
import { TCheckExistDomain, TInstructionItem, TUpdateInstructionList, TSearchOnReq, TResponseS } from './types'
import {
  checkHashtagExists,
  CheckHashtagR,
  getAllDomainList,
  getAllSEODomainList,
  getSearchEngineInstruction,
  getSearchLogList,
  getSoldDomainList,
  IGetAllDomainListR,
  IGetAllSEODomainListR,
  searchTag,
  searchTagP,
  TGetSearchLogListR,
  updateSearchEngineInstruction,
} from '../../utils/api'
import { checkErorr, getToken } from '../../sagas/helper'
import { CategoryInfoP } from './interfaces'
import { IsHashtagBooked } from '../../redusers/actions'
import { push } from 'connected-react-router'

export function* watcherSearch() {
  yield takeLatest('SEARCH_ON_REQ', workerSearchOnReq)
  yield takeLatest('SEARCHENGINE_GET_ALL_DOMAIN_LIST', workerGetAllDomainList)
  yield takeLatest('SEARCHENGINE_GET_ALL_SEO_DOMAIN_LIST', workerGetAllSEODomainList)
  yield takeLatest('SEARCHENGINE_GET_ALL_SOLD_DOMAIN_LIST', workerGetAllSoldDomainList)
  yield takeLatest('CHECK_EXIST_DOMAIN', workerCheckExistDomain)
  yield takeLatest('SEARCHENGINE_GET_INSTRUCTION', workerGetSearchEngineInstruction)
  yield takeLatest('SEARCHENGINE_UPDATE_INSTRUCTION', workerUpdateSearchEngineInstruction)
  yield takeLatest('SEARCHENGINE_GET_SEARCH_LOG_LIST', workerGetSearchLogList)
}

function* workerSearchOnReq({ payload }: TSearchOnReq) {
  try {
    const res: TResponseS = yield call(searchTag, payload)
    yield put({
      type: 'search_response',
      payload: res,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* workerGetSearchLogList() {
  try {
    const res: TGetSearchLogListR[] = yield call(getSearchLogList)
    yield put({
      type: 'GET_SEARCH_LOG_LIST',
      payload: res,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* workerGetAllDomainList() {
  try {
    const res: IGetAllDomainListR[] = yield call(getAllDomainList)
    yield put({
      type: 'GET_ALL_DOMAIN_LIST',
      payload: res,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* workerGetAllSEODomainList() {
  const token: string = yield call(getToken)
  if (!token) {
    yield put(push(`/login/`))
    return
  }

  try {
    const res: IGetAllSEODomainListR[] = yield call(getAllSEODomainList)
    yield put({
      type: 'GET_ALL_SEO_DOMAIN_LIST',
      payload: res,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* workerGetAllSoldDomainList() {
  try {
    const res: TGetSearchLogListR[] = yield call(getSoldDomainList)

    yield put({
      type: 'GET_ALL_SOLD_DOMAIN_LIST',
      payload: res,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* workerCheckExistDomain({ payload }: TCheckExistDomain) {
  try {
    const isExist: Array<IsHashtagBooked & { id: string }> = yield all(
      payload.map((category) => {
        return call(checkHashtagExists, {
          id: `#${category}`,
        })
      })
    )

    const categoryList: CategoryInfoP[] = isExist.map((item) => {
      return {
        id: item.id,
        popularity: 1234567,
        cartPrice: item.price,
        availability: item.status ? 'sold' : 'available',
      }
    })

    yield put({
      type: 'CREATE_CATEGORY_LIST',
      payload: categoryList,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* workerGetSearchEngineInstruction() {
  const token: string = yield call(getToken)
  if (!token) {
    yield put(push(`/login/`))
    return
  }

  try {
    const res: TInstructionItem[] = yield call(getSearchEngineInstruction, token)
    if (res.length > 0) {
      yield put({
        type: 'SEARCHENGINE_INSTRUCTION_LIST',
        payload: res,
      })
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* workerUpdateSearchEngineInstruction({ payload }: TUpdateInstructionList) {
  const token: string = yield call(getToken)
  if (!token) {
    yield put(push(`/login/`))
    return
  }

  try {
    const isUpdated: boolean = yield call(updateSearchEngineInstruction, token, {
      instructions: payload,
    })
    if (isUpdated) {
      const instructList: TInstructionItem[] = yield call(getSearchEngineInstruction, token)
      if (instructList.length > 0) {
        yield put({
          type: 'SEARCHENGINE_INSTRUCTION_LIST',
          payload: instructList,
        })
      }
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

export default watcherSearch
