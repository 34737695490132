export declare enum ChainId {
  ETHEREUM = '1',
  GOERLI = '5',
  BINANCECHAIN = '56',
  BINANCECHAIN_TESTNET = '97',
}

let INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (INFURA_KEY == undefined || INFURA_KEY == null) {
  INFURA_KEY = 'e1038c9b69c146ffabb697554de8bbd4'
}
export const providers = {
  '1': `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  '5': `https://goerli.infura.io/v3/${INFURA_KEY}`,
  '56': `https://bsc-dataseed.binance.org`,
  '97': `https://data-seed-prebsc-1-s1.binance.org:8545`,
}
