import React, { useEffect, useMemo, useState } from 'react'
import { useSearchEngineContext } from 'views/Search/contexts/SearchEngineContext'
import styled from 'styled-components'
import Pagination from 'components/Pagination'
import { SEO_DOMAIN_IMG_URL } from 'constants/urls'
import { useGetThemeMode } from 'state/theme/hook'

const SearchEngineResponseContainer = styled.div`
  // height: calc(100vh - 60px);
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`
const SearchEngineResponseList = styled.ul`
  list-style-type: none;
`
const SearchEngineResponseListItem = styled.li`
  margin: 10px 0;
`
const ListItemTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
`
const ListItemLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #cfd0d1;
  &:hover {
    text-decoration: none;
    color: #fdbe08;
  }
`
const ListItemDetailContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`
const ListItemImg = styled.img`
  width: 120px;
  height: 80px;
  border-radius: 7px;
`
const ListItemDescription = styled.div`
  width: calc(100% - 120px);
  height: 80px;
  font-size: 14px;
  font-weight: 600;
`
const CloseIcon = styled.div`
  text-align: end;
  cursor: pointer;
`

const PageSize = 10

export interface ICloseSearchProps {
  closeSearch: boolean
  handleCloseSearch: () => void
}

const SearchEngineResponse: React.FC<ICloseSearchProps> = ({ closeSearch, handleCloseSearch }) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const { domainList, seoDomainList } = useSearchEngineContext()

  const currentDomainList = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize

    const seoDomains = seoDomainList.map((item) => {
      return {
        id: item.domainId,
        domainName: item.keywords[0].restitle,
        description: item.keywords[0].resshorttext,
        url: item.domainName,
        img: `${SEO_DOMAIN_IMG_URL}${item.domainId}`,
      }
    })
    const hashtagDomains = domainList.map((item) => {
      return {
        id: item.id,
        domainName: item.domainName,
        description: item.description,
        url: item.url,
        img: item.img,
      }
    })

    const currentPageList = [...seoDomains, ...hashtagDomains]
    return currentPageList.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, domainList, seoDomainList])

  const themeMode = useGetThemeMode()

  return (
    <>
      {closeSearch === false && (
        <SearchEngineResponseContainer
          style={{
            background: themeMode === 'main' ? '#00000066' : '#ffffffbd',
            color: themeMode === 'main' ? '#ffffffbd' : '#00000066',
          }}
        >
          <SearchEngineResponseList>
            {currentDomainList.length > 0 &&
              currentDomainList.map((item) => (
                <SearchEngineResponseListItem key={item.id}>
                  <ListItemTitle style={{ color: themeMode == 'light' ? 'black' : 'white' }}>
                    {item.domainName}
                  </ListItemTitle>
                  {item.url && (
                    <ListItemLink
                      href={
                        item.url.startsWith('http://') || item.url.startsWith('https://')
                          ? item.url
                          : `https://${item.url}`
                      }
                      target="_blank"
                      style={{
                        color: themeMode == 'light' ? 'black' : 'white',
                      }}
                    >
                      {item.url}
                    </ListItemLink>
                  )}
                  <ListItemDetailContainer>
                    {item.img && <ListItemImg src={item.img} />}
                    {item.description && <ListItemDescription>{item.description}</ListItemDescription>}
                  </ListItemDetailContainer>
                </SearchEngineResponseListItem>
              ))}
          </SearchEngineResponseList>
          {domainList.length > 1 && (
            <Pagination
              currentPage={currentPage}
              totalCount={domainList.length}
              pageSize={PageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          )}
        </SearchEngineResponseContainer>
      )}
    </>
  )
}

export default SearchEngineResponse
