import { createSlice } from '@reduxjs/toolkit'

interface IState {
  isCollapseMenu: boolean
}

export const initialState: IState = {
  isCollapseMenu: false,
}

const collapseMenuSlice = createSlice({
  name: 'collapse-menu',
  initialState,
  reducers: {
    setIsCollapseMenu(state, action) {
      state.isCollapseMenu = action.payload
    },
  },
})

export const { setIsCollapseMenu } = collapseMenuSlice.actions
export default collapseMenuSlice.reducer
