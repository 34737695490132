import { TGetMetaTagsAction, TSetMetaTagsAction } from './types'

export function setMetaTagsAction(code: TSetMetaTagsAction['payload']): TSetMetaTagsAction {
  return {
    type: 'SET_META_TAGS',
    payload: code,
  }
}

export function getMetaTagsAction(): TGetMetaTagsAction {
  return {
    type: 'GET_META_TAGS',
  }
}
