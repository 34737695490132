export const UNISWAP_LINK = 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency='
export const API_URL = process.env.REACT_APP_API || 'https://api.hashtag.org/api/'
export const SEO_DOMAIN_IMG_URL = 'https://hashtagspace.marketing/includes/snapshotfromdb.php?domainid='
export const HASHTAG_URL = 'https://space.hashtag.org/'
export const SOCIAL_URL = 'https://spacebar.hashtag.org/'
export const DREAMING_URL = 'https://dream.hashtag.org/'
export const SEOMONEY_URL = 'https://seomoney.hashtag.org/'
export const CAPSLOCK_URL = 'https://capslock.hashtag.org/'
export const GAMEBLING_URL = 'https://gamebling.hashtag.org/' // 'http://localhost:3000/'
export const CAPSLOCK_LOGIN_URL = CAPSLOCK_URL + 'auth/login'
export const META_BROWSER_URL = 'https://metagate.metahash.org/'
export const MHC_BUY_URL = 'https://www.kucoin.com/'
export const BINANCE_URL = 'https://www.binance.com/'
export const MOONPAY_URL = 'https://www.moonpay.com/'
export const METAMASK_URL = 'https://metamask.io/download/'
export const EXODUS_URL = 'https://www.exodus.com/download/'

export const WALLETCONNECT_BRIDGE_URL = 'https://uniswap.bridge.walletconnect.org'

export const coinmarketcap_coin = 'https://s2.coinmarketcap.com/static/img/coins/64x64/'
export const MHC_ICON = coinmarketcap_coin + '3756.png'
export const ETH_ICON = coinmarketcap_coin + '1027.png'
export const BNB_ICON = coinmarketcap_coin + '1839.png'

export const FB_URL = 'https://www.facebook.com/'
export const IN_URL = 'https://www.instagram.com/'
export const TW_URL = 'https://twitter.com/'
export const LN_URL = 'https://www.linkedin.com/'

export const MHC_EXPLORER_URL = 'https://venus.mhscan.com/?page=tx&id='
export const ETH_EXPLORER_URL = 'https://etherscan.io/tx/'
export const BNB_EXPLORER_URL = 'https://bscscan.com/tx/'

export const ETH_EXPLORER_TEST_URL = 'https://rinkeby.etherscan.io/tx/'
export const BNB_EXPLORER_TEST_URL = 'https://testnet.bscscan.com/tx/'

export const CHROME_EXTENSION_ID = 'amocmjepbglbiecnbcoebikhabdphfhe'
export const CHROME_EXTENSION_ID_MAC = 'glkcjlboknjiikgabngenpdnokaelonm'

export const CRYPTO_BASE_API = 'https://resolve.unstoppabledomains.com/domains/'
export const CRYPTO_API_KEY = '03b8101f-36cb-4b80-8a53-f2046639d30c'
export const ENS_BASE_API = 'https://ethereum.rest.mnemonichq.com/ens/v1beta1/entity/by_name/'
export const ENS_API_KEY = 'PwmLff3alQ1xxhU3UvDnsRjASJJrasUAz7xyMBGVDZTGWDLH'

export const Public_links = 'https://www.notion.so/Public-links-1b4269d84aaf4b468be5c0de409959ce'
export const HDNS_integration = 'https://www.notion.so/HDNS-Integration-Documents-43308c59a61f4b7fbef2667d9ad9edcb'
export const Documentation = 'https://www.notion.so/Documentation-68903be41d3e4558806230ebdf8443cd'
export const Project_Requirements = 'https://www.notion.so/Project-Requirements-88518ba980b647ddb75e06b70e9cc247'
export const Hashtag_Decentralized =
  'https://www.notion.so/Hashtag-Decentralized-Network-f1a807fcb20a4cbe93627316f9d1e3a7'
export const Fiat_integration = 'https://www.notion.so/Fiat-Integration-Requirements-ff1d4ae392694a2ca32f30da93740e11'
