import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useGetMarketSpaceList } from './hook'
import MarketSpaceDetail from './marketSpaceDetail'
import { BsFillBarChartFill } from 'react-icons/bs'
import { GradientButton } from 'components/Buttons/GradientButton'
import { useSelector, shallowEqual } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import { useGetThemeMode } from 'state/theme/hook'
import { TMarketSpaceItem } from './types'
import { useHistory } from 'react-router-dom'
import SearchForm from 'components/MainSearch/SearchForm'
import { HASHTAG_URL } from 'constants/urls'
import NFTDOMAIN from '../../assets/backslash/nftdomain.png'
import KEYWORD from '../../assets/backslash/keyword.png'
import SEOMONEY from '../../assets/backslash/seomoney.png'
import GAMEBLING from '../../assets/backslash/gamebling.png'
import THEBRIDGE from '../../assets/backslash/thebridge.png'
import HASHTAGCOIN from '../../assets/backslash/hashtagcoin.png'
import PIRATE from '../../assets/backslash/pirate.png'
import DRINK from '../../assets/backslash/drink.png'
import ADDSERVICE from '../../assets/backslash/addservice.png'
import HASHTAGLOGO from '../../assets/icons/hashtag-c.png'
import ADS from '../../assets/icons/ads.png'
import ADSW from '../../assets/icons/ads-w.png'
import { RiStackLine } from 'react-icons/ri'

const MarketSpaceContent = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: auto auto auto;
  background: transparent;
  grid-gap: 15px;
  margin: 20px;
  @media screen and (max-width: 1280px) and (min-width: 640px) {
    grid-template-columns: auto auto;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: auto;
    margin: 15px;
  }
  @media screen and (max-width: 450px) {
    grid-gap: 10px;
    margin: 5px;
  }
`
const MarketSpaceDetailContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 250px;
  padding: 0 20px;
  font-size: 14px;
  position: relative;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 10px;
    width: 100%;
    font-size: 14px;
  }
`
const TitleWrapper = styled.div`
  margin: 20px 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`
const TitleBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  font-size: 25px;
  font-weight: 700;
  @media screen and (max-width: 1536px) {
    font-size: 18px;
    margin: 0px;
  }
`
const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 40px;
  @media screen and (max-width: 1536px) {
    bottom: 0px;
  }
  @media screen and (max-width: 1024px) {
    bottom: 10px;
  }
`

const MarketSpaceBody: React.FC = () => {
  const history = useHistory()
  const marketSpaceList = useGetMarketSpaceList()
  const themeMode = useGetThemeMode()

  const handleClick = useCallback((item: TMarketSpaceItem) => {
    if (item.url === 'Coming Soon!') return
    else if (Number(item.isExternalLink) === 1) window.open(item.url, '_blank')
    else {
      history.push(item.url)
    }
  }, [])

  return (
    <>
      <MarketSpaceContent>
        {marketSpaceList.map((item, index) => {
          let bg
          let topIcon = false
          let bottomIcon = false
          let titleIcon = false
          switch (index) {
            case 0:
              bg = NFTDOMAIN
              break
            case 1:
              bg = KEYWORD
              break
            case 2:
              bg = SEOMONEY
              break
            case 3:
              bg = GAMEBLING
              break
            case 4:
              bg = THEBRIDGE
              titleIcon = true
              break
            case 5:
              bg = HASHTAGCOIN
              bottomIcon = true
              break
            case 6:
              bg = PIRATE
              break
            case 7:
              bg = DRINK
              break
            default:
              bg = ADDSERVICE
              topIcon = true
          }
          return (
            <MarketSpaceDetailContainer
              key={index}
              // width={item.url === HASHTAG_URL ? 'calc((200% - 18px) / 3)' : 'calc((100% - 36px) / 3)'}
              style={{
                background: themeMode == 'main' ? '' : '#ffffffd5',
                backgroundImage: themeMode == 'main' ? `url(${bg})` : '',
              }}
            >
              <TitleWrapper>
                {titleIcon && <RiStackLine color={themeMode == 'main' ? '#fff' : '#000'} size={36} />}
                <TitleBodyWrapper>
                  <MarketSpaceDetail item={item} type="title" value={item.title} themeMode={themeMode} />
                  {topIcon && <img src={themeMode === 'main' ? ADSW : ADS} width="40" />}
                </TitleBodyWrapper>
              </TitleWrapper>
              <MarketSpaceDetail item={item} type="detail" value={item.detail} themeMode={themeMode} />
              <ActionContainer>
                {item.url === HASHTAG_URL ? (
                  <SearchForm />
                ) : (
                  <>
                    {bottomIcon && <img src={HASHTAGLOGO} width="40" height="40" style={{ marginRight: '30px' }} />}
                    <GradientButton
                      type="button"
                      borderRadius="4px"
                      width="fit-content"
                      backgroundColor={
                        themeMode === 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)'
                      }
                      color={themeMode == 'main' ? 'white' : 'black'}
                      hoverColor={
                        themeMode === 'main' ? 'linear-gradient(#311438, #873f99)' : 'linear-gradient(#777fc4, #b7eaf6)'
                      }
                      onClick={() => handleClick(item)}
                      style={{ fontWeight: '900' }}
                    >
                      {item.buttonText.toUpperCase()}
                    </GradientButton>
                  </>
                )}
              </ActionContainer>
            </MarketSpaceDetailContainer>
          )
        })}
      </MarketSpaceContent>
    </>
  )
}

export default MarketSpaceBody
