import { connectRouter, routerMiddleware } from 'connected-react-router/immutable'
import { createBrowserHistory, History } from 'history'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { save, load } from 'redux-localstorage-simple'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../sagas/rootSaga'
import themeModeReducer from '../state/theme/reducer'
import searchSymbolReducer from '../state/headerButtons/reducer'
import tooltipReducer from '../state/toolTip/reducer'
import collapseMenuReducer from '../state/collapseMenu/reducer'
import avatarReducer from '../state/avatar/reducer'
import headerSearchResultReducer from '../state/headerSearch/reducer'
import playSoundReducer from '../state/playSound/reducer'
import accounts from '../views/Admin/Accounts/redusers'
import dashboard from '../views/Admin/Dashboard/redusers'
import { metaTags_reducer } from '../views/Admin/MetaTags/reduser'
import { sendEmailAdminReducer } from '../views/Admin/settingEmail/reduser'
import { affiliatesReducer } from '../views/Affiliates/reduser'
import { cart_reducer } from '../views/Cart/reduser'
import portfolio from '../views/Portfolio/reducers'
import { search_reducer } from '../views/Search/reduser'
import { sendEmailReducer } from '../views/SendEmail/reduser'
import { viewProfile_reducer } from '../views/viewprofile/reduser'
import actions from './actions'
import info from './info'
import { marketSpace_reducer } from './marketspace'
import modals from './modals'
import transaction from './transaction'
import user from './user'

const sagaMiddleware = createSagaMiddleware()

export const history = createBrowserHistory()

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    actions,
    user,
    modals,
    transaction,
    info,
    accounts,
    metaTags_reducer,
    cart_reducer,
    search_reducer,
    viewProfile_reducer,
    affiliatesReducer,
    sendEmailReducer,
    sendEmailAdminReducer,
    portfolio,
    dashboard,
    marketSpace_reducer,
    themeModeReducer,
    searchSymbolReducer,
    tooltipReducer,
    collapseMenuReducer,
    avatarReducer,
    playSoundReducer,
    headerSearchResultReducer,
  })

export const rootReducer = createRootReducer(history)
const PERSISTED_KEYS: string[] = ['themeMode']

const store = createStore(
  rootReducer,

  //applyMiddleware(routerMiddleware(history), sagaMiddleware)
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), sagaMiddleware, save({ states: PERSISTED_KEYS, debounce: 1000 }))
  )
)

export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

sagaMiddleware.run(rootSaga)

export default store
