import { GradientButton } from 'components/Buttons/GradientButton'
import React from 'react'
import styled from 'styled-components'
import { RiCloseLine } from 'react-icons/ri'

const TwoButModalContainer = styled.div`
  width: 30%;
  z-index: 99;
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
  padding: 20px;
  box-size: border-box;
  border: 1px solid gray;
  border-radius: 5px;
`
const ModalTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-align: left;
`
const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  margin: 24px 0 12px 0;
  word-break: break-word;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const CloseButton = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
`
interface ITwoBtnModal {
  children: React.ReactNode
  title: string
  confirm: Function
  onClose: Function
  themeMode?: string
  okBtn?: string
}

const TwoBtnModal: React.FC<ITwoBtnModal> = ({ children, title, confirm, onClose, themeMode, okBtn }) => {
  return (
    <TwoButModalContainer
      style={{
        background: themeMode == 'main' ? 'black' : 'white',
        color: themeMode == 'main' ? 'white' : 'black',
      }}
    >
      <ModalTitle>{title}</ModalTitle>
      <ModalBody>{children}</ModalBody>
      <ButtonContainer>
        <GradientButton
          type={'button'}
          backgroundColor={'grey'}
          width={'72px'}
          height={'36px'}
          borderRadius={'20px'}
          margin={'5px'}
          onClick={() => onClose()}
        >
          Cancel
        </GradientButton>
        <GradientButton type={'button'} width={'72px'} height={'36px'} borderRadius={'20px'} onClick={() => confirm()}>
          {okBtn ? okBtn : 'Ok'}
        </GradientButton>
      </ButtonContainer>
      <CloseButton onClick={() => onClose()}>
        <RiCloseLine size={24} />
      </CloseButton>
    </TwoButModalContainer>
  )
}

export default TwoBtnModal
