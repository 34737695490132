import React from 'react'
import {
  SubContainer,
  SubContainerBody,
  FlexRow,
  TitleText,
  BigValueText,
  HorizontalDivider,
  FlexRowContent,
} from '../../styled'
import { IDomainAnalyticsProps } from '../../types'
import { NavigationList } from './NavigationList'

export const DomainAnalyticsItems: React.FC<IDomainAnalyticsProps> = ({ title, value, color, list, themeMode }) => {
  return (
    <SubContainer width={'25%'} height={'142px'} themeMode={themeMode}>
      <SubContainerBody>
        <FlexRowContent>
          <TitleText>{title}</TitleText>
          <BigValueText color={color!} fontSize={'26px'}>
            {value}
          </BigValueText>
        </FlexRowContent>
        <HorizontalDivider />
        {list && <NavigationList list={list} />}
      </SubContainerBody>
    </SubContainer>
  )
}
