import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onForgotPassword } from '../../../actions/user'
import LoginFormInp from '../LoginInp'
import MainContent from '../../../components/MainContent'
import { AppState } from '../../../redusers/config'
import { Init } from '../../../redusers/user'
import { FormButton, FormContainer, FormTitle } from '../styles'
import { useGetThemeMode } from 'state/theme/hook'

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email.toLowerCase())
}

const ForgotPassword: React.FC<any> = () => {
  const [email, setEmail] = useState<string>('')
  const [errors, setErrors] = useState<Array<string>>([])
  const isSent = useSelector<AppState, Init['isSentToEmail']>(({ user }) => user.get('isSentToEmail'))
  const dispatch = useDispatch()

  const sendRequest = () => {
    const errors = []

    if (!validateEmail(email)) {
      errors.push('email')
    }

    setErrors(errors)
    if (!errors.length) dispatch(onForgotPassword(email))
  }

  const themeMode = useGetThemeMode()

  return (
    <MainContent cn__maincontent_startpage="cn__maincontent_startpage">
      <FormContainer>
        <FormTitle>
          <span
            style={{
              color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
            }}
          >
            Forgot Password
          </span>
        </FormTitle>
        <LoginFormInp
          label={'Email address'}
          type={'email'}
          name={'email'}
          value={email}
          themeMode={themeMode}
          onChange={setEmail}
          isError={errors.includes('email')}
        />
        <FormButton
          type="button"
          onClick={() => sendRequest()}
          style={{
            background: themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        >
          Send Request
        </FormButton>
        <div className="resetPwdConfirmContainer">
          {isSent === true ? (
            <span
              style={{
                color: themeMode == 'main' ? '#e68105' : 'black',
              }}
            >
              Please check your email box to get reset Link
            </span>
          ) : isSent === false ? (
            <span className="resetPwdErrorTxt">Error Occured, Please try once again</span>
          ) : (
            <></>
          )}
        </div>
      </FormContainer>
    </MainContent>
  )
}

export default ForgotPassword
