import React from 'react'
import './styles.scss'

function ComingSoon() {
  return (
    <div className="comingSoonMain">
      <h1>Coming Soon!</h1>
    </div>
  )
}

export default ComingSoon
