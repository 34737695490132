import {
  IAssetsTable,
  ICreateAssets,
  TAassetsChartList,
  TAassetsList,
  TAcreateAssets,
  TAgetMHCTokenBalance,
  TAgetPortfolioList,
  TAremoveAsset,
  TAssetChartList,
} from '../types'

export function createAssets(payload: ICreateAssets): TAcreateAssets {
  return {
    type: 'CREATE_ASSETS',
    payload,
  }
}

export function createAssetsList(payload: IAssetsTable): TAassetsList {
  return {
    type: 'LIST_ASSETS',
    val: payload,
  }
}

export function createAssetsChartList(payload: TAssetChartList): TAassetsChartList {
  return {
    type: 'LIST_ASSETS_CHARTS',
    val: payload,
  }
}

export function removeAsset(payload: TAremoveAsset['payload']): TAremoveAsset {
  return {
    type: 'REMOVE_ASSET',
    payload,
  }
}

export function getPortfoiliList(): TAgetPortfolioList {
  return {
    type: 'GET_PORTFOLIO_LIST',
  }
}

export function getMHCTokenBalance(payload: TAgetMHCTokenBalance['payload']): TAgetMHCTokenBalance {
  return {
    type: 'GET_MHC_TOKEN_BALANCE',
    payload,
  }
}
