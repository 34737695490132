import React, { useCallback, useEffect, useState } from 'react'
import {
  BsArrowBarDown,
  BsArrowBarUp,
  BsArrowRight,
  BsCircleFill,
  BsDiscord,
  BsFlagFill,
  BsPlusCircle,
  BsWallet,
} from 'react-icons/bs'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import { useGetThemeMode } from 'state/theme/hook'
import './styles.scss'
import styled from 'styled-components'
import TrophyIcon from '../../assets/bodyheat/trophy.svg'
import DownDollarIcon from '../../assets/bodyheat/dollar-coin.svg'
import SumIcon from '../../assets/bodyheat/Toatl.svg'
import ClaimIcon from '../../assets/bodyheat/claim.png'
import GameblingIcon from '../../assets/bodyheat/gamebling.png'
import BodyImg from '../../assets/bodyheat/BodyHeat.svg'
import StatImg from '../../assets/bodyheat/stats.svg'
import MapImg from '../../assets/bodyheat/map.png'
import { FaLongArrowAltDown, FaLongArrowAltUp, FaUserSecret } from 'react-icons/fa'
import { GiMouse, GiPistolGun } from 'react-icons/gi'
import Loader from 'components/Loader'
import { BiLinkExternal } from 'react-icons/bi'
import Avatar from 'react-avatar'
import ReactTable, { createTheme } from 'react-data-table-component'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'

const dummyData = {
  balance: 21,
  won: 7,
  lost: 4,
  total: 3,
}

const dummyActivityData = [
  { date: '2023 - 07 - 21', bling: 3, price: 30, rise: true },
  { date: '2023 - 06 - 21', bling: 5, price: 50, rise: true },
  { date: '2023 - 05 - 21', bling: 2, price: 23, rise: false },
  { date: '2023 - 04 - 21', bling: 4, price: 35, rise: true },
  { date: '2023 - 03 - 21', bling: 1, price: 10, rise: true },
  { date: '2023 - 02 - 21', bling: 3, price: 25, rise: false },
  { date: '2023 - 01 - 21', bling: 3, price: 30, rise: true },
  { date: '2023 - 03 - 21', bling: 1, price: 10, rise: true },
  { date: '2023 - 02 - 21', bling: 3, price: 25, rise: false },
  { date: '2023 - 01 - 21', bling: 3, price: 30, rise: true },
]

const dummyBodyData = {
  match: 180,
  kd: 2.1,
  kill: 3000,
  death: 0,
  adr: 26,
  headshot: 25,
  hsp: '0.25%',
}

const dummyMatchData = [
  {
    id: 1,
    title: 'CSGO',
    date: 'Oct 11, 2023',
    owner: '#MacSlave',
    type: '1 vs 1 ladder',
    killPrice: '$0.10 per kill',
    icon: '',
    ownerImg: '',
  },
  {
    id: 2,
    title: 'STEAM FIFA',
    date: 'Oct 21, 2023',
    owner: '#Martin',
    type: '1 vs 1 ladder',
    killPrice: '$0.10 per kill',
    icon: '',
    ownerImg: '',
  },
  {
    id: 3,
    title: 'EMPIRE',
    date: 'Jan 11, 2023',
    owner: '#James',
    type: '1 vs 1 ladder',
    killPrice: '$0.10 per kill',
    icon: '',
    ownerImg: '',
  },
  {
    id: 4,
    title: 'CS',
    date: 'Apr 05, 2023',
    owner: '#MacSlave',
    type: '1 vs 1 ladder',
    killPrice: '$0.10 per kill',
    icon: '',
    ownerImg: '',
  },
  {
    id: 5,
    title: 'BUMB',
    date: 'Oct 11, 2023',
    owner: '#MacSlave',
    type: '1 vs 1 ladder',
    killPrice: '$0.10 per kill',
    icon: '',
    ownerImg: '',
  },
]

createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
    fontFamily: '"Montserrat", sans-serif',
  },
  background: {
    default: '#00000050',
  },
  context: {
    background: '#FFFFFF',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: '#FFFFFF',
    hover: '#FFFFFF',
    disabled: '#FFFFFF',
  },
  highlightOnHover: {
    default: '#000000',
    text: '#FFFFFF',
  },
  sortFocus: {
    default: '#FFFFFF',
  },
})

createTheme('solarized_light', {
  text: {
    primary: '#000',
    secondary: '#000',
    fontFamily: '"Montserrat", sans-serif',
  },
  background: {
    default: '#ffffff50',
  },
  context: {
    background: '#000',
    text: '#000',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: '#000',
    hover: '#000',
    disabled: '#000',
  },
  highlightOnHover: {
    default: '#00000030',
    text: '#000',
  },
  sortFocus: {
    default: '#000ff0',
  },
})

const EnterContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `calc(100% - ${width})`};
  z-index: 0;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const ItemContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 150px;
  padding: 5px 10px;
  border: 1px solid;
  font-size: 14px;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
  }
`

const Row: React.FC<any> = ({ rowData }) => {
  return (
    <tr className="act-tr">
      <td>
        <span className="asset-title">{rowData.date}</span>
      </td>
      <td>{rowData.rise ? <BsArrowBarUp size={20} color={'green'} /> : <BsArrowBarDown size={30} color={'red'} />}</td>
      <td className="act-td">
        <span className="g-green">{rowData.bling} #bling</span>
        <span className="asset-supply">
          {rowData.rise ? '+' : '-'} ${rowData.price}
        </span>
      </td>
    </tr>
  )
}

const BodyRow: React.FC<any> = ({ body, themeMode }) => {
  return (
    <tr>
      <td>
        <div className="b_item">{body.match}</div>
      </td>
      <td>
        <div className="b_item">{body.kd}</div>
      </td>
      <td>
        <div className="b_item">{body.kill}</div>
      </td>
      <td>
        <div className="b_item">{body.death}</div>
      </td>
      <td>
        <div className="b_item">{body.adr}</div>
      </td>
      <td>
        <div className="b_item">{body.headshot}</div>
      </td>
      <td>
        <div className="b_item">{body.hsp}</div>
      </td>
      <td>
        <div className="b_item">
          <select
            className="body-select"
            // onChange={handleArena}
            // value={arenaValue}
            style={{
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <option value={0}>1v1 - $0.10/kill</option>
            <option value={1}>2v1 - $0.20/kill</option>
            <option value={2}>3v1 - $0.30/kill</option>
            <option value={3}>4v1 - $0.40/kill</option>
            <option value={4}>5v1 - $0.50/kill</option>
          </select>
        </div>
      </td>
      <td>
        <div className="b_item">
          <select
            className="body-select"
            // onChange={handleArena}
            // value={arenaValue}
            style={{
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <option value={0}>All Time</option>
            <option value={1}>Time - 1</option>
            <option value={2}>Time - 2</option>
            <option value={3}>Time - 3</option>
            <option value={4}>Time - 4</option>
          </select>
        </div>
      </td>
      <td>
        <div className="b_item">
          <select
            className="body-select"
            // onChange={handleArena}
            // value={arenaValue}
            style={{
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <option value={0}>Map - 0</option>
            <option value={1}>Map - 1</option>
            <option value={2}>Map - 2</option>
            <option value={3}>Map - 3</option>
            <option value={4}>Map - 4</option>
          </select>
        </div>
      </td>
    </tr>
  )
}

const EnterWeb: React.FC = () => {
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const isCollapseMenu = useIsCollapseMenu()
  const isAdmin = useSelector<AppState, Init['isAdmin']>(({ user }) => user.get('isAdmin'), shallowEqual)
  const userInfo = useSelector<AppState, Init['userInfo']>(({ user }) => user.get('userInfo'))

  const [statsClick, setStatsClick] = useState<boolean>(false)
  const [activityClick, setActivityClick] = useState<boolean>(false)
  const [isOnline, setIsOnline] = useState<boolean>(false)
  const [arenaValue, setArenaValue] = useState<number>(0)

  const handleStats = (flag: string) => {
    if (flag === 'stats') {
      setStatsClick(true)
      setActivityClick(false)
    }
    if (flag === 'activity') {
      setActivityClick(true)
      setStatsClick(false)
    }
  }

  const handleDiscord = () => {
    window.open('https://support.discord.com/', '_blank')
  }

  const handlePlus = () => {
    alert('plus')
  }

  const handleArena = () => {}

  const handleGo = (flag: number) => {
    alert('GO' + flag)
  }

  useEffect(() => {
    setStatsClick(true)
    setIsOnline(true)
  }, [])

  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        paddingLeft: '20px',
        paddingRight: '8px',
      },
    },
    pagination: {
      pageButtonsStyle: {
        fill: themeMode === 'main' ? 'white' : 'black',
        '&:disabled': {
          fill: themeMode === 'main' ? 'gray' : 'gray',
        },
        '&:hover:not(:disabled)': {
          backgroundColor: 'gray',
        },
      },
    },
  }
  const columns = [
    {
      name: 'title',
      selector: (row: any) => 'title',
      cell: (row: any) => {
        return (
          <div className="g-title">
            {/* <img src={row.icon} alt="game icon" /> */}
            <GiPistolGun size={20} style={{ marginRight: '10px' }} />
            <div className="b_item">
              <h3>{row.title}</h3>
              <span className="font12">{row.date}</span>
            </div>
          </div>
        )
      },
      sortable: true,
      width: '25%',
    },
    {
      name: 'owner',
      selector: (row: any) => 'owner',
      cell: (row: any) => {
        return (
          <div className="g-title">
            {/* <img src={row.ownerImg} alt="owner photo" /> */}
            <FaUserSecret size={30} style={{ marginRight: '10px' }} />
            <h3>{row.owner}</h3>
          </div>
        )
      },
      sortable: true,
      width: '25%',
    },
    {
      name: '',
      selector: (row: any) => 'type',
      cell: (row: any) => {
        return (
          <div className="b_item">
            <h3>{row.type}</h3>
            <span className="font12">{row.killPrice}</span>
          </div>
        )
      },
      sortable: true,
      width: '25%',
    },
    {
      name: 'action',
      cell: (row: any) => {
        return (
          <button className="go_btn" onClick={() => handleGo(row.id)}>
            <span>GO !</span>
            <BsArrowRight />
          </button>
        )
      },
      sortable: true,
      width: '25%',
    },
  ]

  return (
    <EnterContainer
      width={isCollapseMenu ? '65px' : '235px'}
      style={{
        background: themeMode === 'main' ? '#00000090' : '#ffffff90',
        color: themeMode === 'main' ? 'white' : 'black',
      }}
    >
      <div
        className="enterTop"
        style={{
          background: themeMode === 'main' ? '#000000' : '#ffffff',
        }}
      >
        <div
          className="eTopLeft"
          style={{
            background:
              themeMode === 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
          }}
        >
          <img src={GameblingIcon} className="gamebling_img" alt="" />
          <BsDiscord size={20} className="discord_icon" onClick={handleDiscord} />
        </div>
        <div className="eTopRight">
          <div className="eTopRight-l">
            <div className="e-desc">
              <BsFlagFill color="#12d48c" size={20} />
              <span className="improve">IMPROVE YOUR GAME</span>
              <span>-</span>
              <span className="better">Gamebling is developing an AI to help you game better.!</span>
            </div>
            <div className="e-desc">
              <BsPlusCircle size={20} className="plus_icon" onClick={handlePlus} />
              <BsPlusCircle size={20} className="plus_icon" onClick={handlePlus} />
              <BsPlusCircle size={20} className="plus_icon" onClick={handlePlus} />
              <BsPlusCircle size={20} className="plus_icon" onClick={handlePlus} />
            </div>
          </div>
          <div className="eTopRight-r">
            <div className="userdetail">
              <div className="game_title">
                <BsFlagFill color="#12d48c" size={20} />
                <span className="margin5">STEAM</span>
                <span className="g-green">Online</span>
              </div>
              <span className="welcome">Welcome back!</span>
              <span className="user_nick">#{userInfo.nick}</span>
            </div>
            <div className="user_avatar">
              <Avatar
                src={userInfo.img ? userInfo.img : undefined}
                name={`${userInfo.first_name} ${userInfo.last_name}`}
                size="50"
                round="50px"
                color={themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)'}
              />
              <BsCircleFill color={isOnline ? '#12d48c' : 'red'} size={15} className="dot_online" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="enterMain"
        style={{
          background: themeMode === 'main' ? '#00000090' : '#ffffff90',
        }}
      >
        <div className="subEMain">
          <div className="eMainLeft">
            <div className="eBalance">
              <ItemContainer
                style={{
                  background: themeMode == 'main' ? '#000000b0' : '#ffffffb0',
                  borderColor: themeMode == 'main' ? '#02569d' : 'gray',
                }}
              >
                <div className="game-balance-container">
                  <div className="game-balance-header">
                    <div className="g-title">
                      <BsWallet size={20} style={{ marginRight: '10px' }} /> BALANCE :
                    </div>
                    <div className="g-title g-green">
                      <span className="g-num">{dummyData.balance} </span>#bling
                    </div>
                  </div>
                  <div className="game-table-div">
                    <div className="g-table-row dash_border">
                      <div className="g-title">
                        <img src={TrophyIcon} className="trophy_icon" />
                        WON
                      </div>
                      <div className="g-title">
                        <span className="g-num">{dummyData.won}</span>
                        <span>#bling</span>
                        <FaLongArrowAltUp size={18} color="#12d48c" />
                      </div>
                    </div>
                    <div className="g-table-row dash_border">
                      <div className="g-title">
                        <img src={DownDollarIcon} className="trophy_icon" />
                        LOST
                      </div>
                      <div className="g-title">
                        <span className="g-num">{dummyData.lost}</span>
                        <span>#bling</span>
                        <FaLongArrowAltDown size={18} color="red" />
                      </div>
                    </div>
                    <div className="g-table-row">
                      <div className="g-title">
                        <img src={SumIcon} className="trophy_icon" />
                        Total:
                      </div>
                      <div className="g-title">
                        <span className="g-num">{dummyData.total}</span>
                        <span>#bling</span>
                        <FaLongArrowAltUp size={18} color="#12d48c" />
                      </div>
                    </div>
                  </div>
                </div>
              </ItemContainer>
            </div>
            <div
              className="eStats"
              style={{
                borderColor: themeMode === 'main' ? '#873f99' : 'gray',
              }}
            >
              <div
                className="heat_stats"
                onClick={() => handleStats('stats')}
                style={{
                  background: statsClick
                    ? themeMode === 'main'
                      ? 'linear-gradient(#873f99, #311438)'
                      : 'linear-gradient(#b7eaf6, #777fc4)'
                    : themeMode === 'main'
                    ? '#000000b0'
                    : '#ffffffb0',
                }}
              >
                <span>MY HEAT/STATS</span>
                <span>
                  <img src={StatImg} className="stat_img" />
                </span>
              </div>
              <div
                className="heat_stats"
                onClick={() => handleStats('activity')}
                style={{
                  background: activityClick
                    ? themeMode === 'main'
                      ? 'linear-gradient(#873f99, #311438)'
                      : 'linear-gradient(#b7eaf6, #777fc4)'
                    : themeMode === 'main'
                    ? '#000000b0'
                    : '#ffffffb0',
                }}
              >
                <span>RECENT MATCHES</span>
                <span>
                  <GiMouse size={22} />
                </span>
              </div>
            </div>
            <div
              className="activity_table_container"
              style={{
                background: themeMode === 'main' ? '#000000b0' : '#ffffffb0',
                borderColor: themeMode == 'main' ? '#02569d' : 'gray',
              }}
            >
              <div className="activ_table_head">
                <span>Recent Activity</span>
                <span>
                  See All <BiLinkExternal size={15} />
                </span>
              </div>
              {dummyActivityData.length > 0 ? (
                <div className="act-table">
                  <table className="act-table" cellSpacing="0" cellPadding="10" width="100%">
                    <tbody>
                      {dummyActivityData.map((item, index) => {
                        return <Row key={index} rowData={item} />
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Loader stroke="green" size="30px" />
              )}
            </div>
          </div>
          <div className="eMainRight">
            <div className="chooseArea">
              <div className="chooseLeft" style={{ background: themeMode === 'main' ? '#000000b0' : '#ffffffb0' }}>
                Choose Arena
                <select
                  className="choose-select"
                  onChange={handleArena}
                  value={arenaValue}
                  style={{
                    color: themeMode === 'main' ? 'white' : 'black',
                  }}
                >
                  <option value={0}>1v1 ladder - $0.10/kill</option>
                  <option value={1}>2v1 ladder - $0.20/kill</option>
                  <option value={2}>3v1 ladder - $0.30/kill</option>
                  <option value={3}>4v1 ladder - $0.40/kill</option>
                  <option value={4}>5v1 ladder - $0.50/kill</option>
                </select>
                <div>
                  <img src={ClaimIcon} className="claim_icon" />
                </div>
              </div>
              <div className="chooseRight">
                PLAY <br />
                NOW
              </div>
            </div>

            <div className="bodyArea" style={{ borderColor: themeMode === 'main' ? '#873f99' : '#b7eaf6' }}>
              {statsClick ? (
                <>
                  <div
                    className="bodyValueContainer"
                    style={{ background: themeMode === 'main' ? '#00000090' : '#ffffff90' }}
                  >
                    <table cellSpacing="0" cellPadding="10" width="100%">
                      <thead>
                        <tr>
                          <th>Matches</th>
                          <th>K.D.</th>
                          <th>Kills</th>
                          <th>Deaths</th>
                          <th>ADR</th>
                          <th>Headshots</th>
                          <th>hSP</th>
                          <th>Arena</th>
                          <th>Timeframe</th>
                          <th>Map</th>
                        </tr>
                      </thead>
                      <tbody>
                        <BodyRow body={dummyBodyData} themeMode={themeMode} />
                      </tbody>
                    </table>
                  </div>
                  <div className="bodyDetailContainer">
                    <div className="b_left">
                      <div className="b_item">
                        <div>
                          <span>View by: </span>
                          <select
                            className="body-select"
                            // onChange={handleArena}
                            // value={arenaValue}
                            style={{
                              color: themeMode === 'main' ? 'white' : 'black',
                            }}
                          >
                            <option value={0}>1 Month</option>
                            <option value={1}>3 Month</option>
                            <option value={2}>6 Month</option>
                            <option value={3}>12 Month</option>
                            <option value={4}>24 Month</option>
                          </select>
                        </div>

                        <div className="p_circle" style={{ borderColor: 'yellow', marginTop: '30px' }}>
                          <h2>56.7%</h2>
                          <span className="font12">K.D Ratio</span>
                        </div>
                        <div className="p_circle" style={{ borderColor: 'green' }}>
                          <h2>$250</h2>
                          <span className="font12">Earnings</span>
                        </div>
                        <div className="p_circle" style={{ borderColor: '#32bce6' }}>
                          <h2>1729</h2>
                          <span className="font12">ELO</span>
                        </div>
                      </div>
                      <div className="b_item">
                        <span>Map: am_grass2</span>
                        <span style={{ marginTop: '10px' }}>
                          <img src={BodyImg} className="b_img" alt="body image" />
                        </span>
                      </div>
                    </div>

                    <div className="b_right">
                      <img src={MapImg} className="b_map" alt="map image" />
                    </div>
                  </div>
                </>
              ) : (
                <ReactTable
                  noTableHead={true}
                  title="Recent Matches"
                  theme={themeMode === 'light' ? 'solarized_light' : 'solarized'}
                  data={dummyMatchData}
                  columns={columns}
                  customStyles={customStyles}
                  pagination
                  highlightOnHover
                />
              )}
            </div>
          </div>
        </div>
        <div className="subEBottom">
          <ReactTable
            noTableHead={true}
            title="Queued Matches"
            theme={themeMode === 'light' ? 'solarized_light' : 'solarized'}
            data={dummyMatchData}
            columns={columns}
            customStyles={customStyles}
            pagination
            highlightOnHover
          />
        </div>
      </div>
    </EnterContainer>
  )
}

export default EnterWeb
