import React, { useEffect, useState } from 'react'
import './styles.scss'
import { FaPlus } from 'react-icons/fa'
import { connect } from 'react-redux'
import { Record } from 'immutable'
import { Init as Actions } from '../../redusers/actions'
import { CategoryList } from '../../utils/api'
import { categoryAddForAdm, getCategoryList, categoryRemoveForAdm, categoryEditForAdm } from '../../actions/hashtag'
import UnifiedModal from '../Modals/UnifiedModal'
import { useGetThemeMode } from 'state/theme/hook'

interface CategoriesP {
  name: string
  id: string
  categoryRemove: (val: string) => { type: string; val: string }
  onEdit: (id: string, name: string) => void | null
}

const removeCat = (id: string, categoryRemove: CategoriesP['categoryRemove']) => () => {
  categoryRemove(id)
}

const onEditCat = (id: string, name: string, onEdit: CategoriesP['onEdit']) => () => {
  onEdit(id, name)
}

function Categories({ name, id, categoryRemove, onEdit }: CategoriesP) {
  return (
    <div className="dashboard-table__body-item category-table__body">
      <div className="cell1">
        <div>{name}</div>
      </div>
      <div className="cell2">
        <div className="btn" onClick={removeCat(id, categoryRemove)}>
          Delete
        </div>
        <div className="btn" onClick={onEditCat(id, name, onEdit)}>
          Edit
        </div>
      </div>
    </div>
  )
}

const openModal = (setShowModal: Function) => (e: React.FormEvent<HTMLElement>) => {
  setShowModal(true)
}
const closeModal = (setShowModal: Function) => (e: React.FormEvent<HTMLElement>) => {
  setShowModal(false)
}

function checkData(data: string): boolean {
  if (data.length < 3) {
    return false
  }
  return true
}

const addCategory = (categoryAddForAdm: Function, setShowModal: Function) => (data: string) => {
  categoryAddForAdm(data)
  setShowModal(false)
}

const onEdit = (setEditData: Function, setShowModalEdit: Function) => (id: string, name: string) => {
  if (!id) return
  if (!name) return
  setEditData({ id, name })
  setShowModalEdit(true)
  return
}

interface CategoryTableP {
  categoryList: CategoryList
  getCategoryList: Function
  categoryAddForAdm: Function
  categoryRemoveForAdm: (val: string) => { type: string; val: string }
  categoryEditForAdm: (val: { id: string; name: string }) => {
    type: string
    val: { id: string; name: string }
  }
}

const editCat =
  (categoryEditForAdm: CategoryTableP['categoryEditForAdm'], showModalEdit: (d: boolean) => void, id: string) =>
  (data: string) => {
    if (!id) {
      return
    }
    categoryEditForAdm({ id, name: data })
    showModalEdit(false)
  }

const CategoryTable: React.FC<CategoryTableP> = ({
  categoryList,
  getCategoryList,
  categoryAddForAdm,
  categoryRemoveForAdm,
  categoryEditForAdm,
}) => {
  useEffect(() => {
    getCategoryList()
  }, [])

  interface EditData {
    id: string
    name: string
  }

  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false)
  const [editData, setEditData] = useState<EditData>({ id: '', name: '' })
  const themeMode = useGetThemeMode()
  return (
    <div
      className="category-table"
      style={{
        background: themeMode === 'light' ? 'white' : '',
        color: themeMode === 'light' ? 'black' : 'white',
      }}
    >
      <div
        className="category-table__header"
        style={{
          background: themeMode === 'light' ? '#d4e3ee' : '',
          color: themeMode === 'light' ? 'black' : 'white',
        }}
      >
        <div className="cell1">Categories</div>
        <div className="cell2">
          <div
            className="category-table__addcategory"
            style={{
              background:
                themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
              color: themeMode == 'main' ? 'white' : 'black',
            }}
            onClick={openModal(setShowModal)}
          >
            <FaPlus />
            Add Category
          </div>
        </div>
      </div>

      {categoryList.length === 0 && (
        <div className="dashboard-table__body-item category-table__body">
          <div className="cell1">
            <div>List is empty</div>
          </div>
        </div>
      )}

      {categoryList.map((item) => {
        return (
          <Categories
            key={item.id}
            name={item.name}
            id={item.id}
            categoryRemove={categoryRemoveForAdm}
            onEdit={onEdit(setEditData, setShowModalEdit)}
          />
        )
      })}

      <UnifiedModal
        showModal={showModal}
        onClose={closeModal(setShowModal)}
        title={'Add Category'}
        inputName={'Category'}
        inputType={'text'}
        onSave={addCategory(categoryAddForAdm, setShowModal)}
        checkData={checkData}
      />
      <UnifiedModal
        showModal={showModalEdit}
        onClose={closeModal(setShowModalEdit)}
        title={'Edit Category'}
        inputName={'Category name'}
        inputType={'text'}
        onSave={editCat(categoryEditForAdm, setShowModalEdit, editData.id)}
        checkData={checkData}
        value={editData.name}
      />
    </div>
  )
}

function mapStateToProps({ actions }: { actions: Record<Actions> }) {
  return { categoryList: actions.get('categoryList') }
}

export default connect(mapStateToProps, {
  getCategoryList,
  categoryAddForAdm,
  categoryRemoveForAdm,
  categoryEditForAdm,
})(CategoryTable)
