import React, { FC } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { FaPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useGetThemeMode } from 'state/theme/hook'
import '../../../components/CategoryTable/styles.scss'
import { useInfoList } from './hooks'
import Item from './InfoItem'

const InfoList: FC = () => {
  const { listData, memoizedDelete, memoizedEdit, moveCard, onDrop } = useInfoList()
  const themeMode = useGetThemeMode()

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="category-table"
        style={{
          background: themeMode === 'light' ? 'white' : '',
          color: themeMode === 'light' ? 'black' : 'white',
        }}
      >
        <div
          className="category-table__header"
          style={{
            background: themeMode === 'light' ? '#d4e3ee' : '',
            color: themeMode === 'light' ? 'black' : 'white',
          }}
        >
          <div className="cell1">Info</div>
          <div className="cell2">
            <Link
              className="category-table__addcategory"
              to="./addArticle"
              style={{
                background:
                  themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                color: themeMode == 'main' ? 'white' : 'black',
              }}
            >
              <FaPlus />
              Add Article
            </Link>
          </div>
        </div>
        {listData.map((item, index) => (
          <Item
            key={item.id}
            id={item.id}
            title={item.title}
            onDelete={memoizedDelete(item.id)}
            onEdit={memoizedEdit(item.id)}
            index={index}
            moveCard={moveCard}
            onDrop={onDrop}
          />
        ))}
      </div>
    </DndProvider>
  )
}

export default InfoList
