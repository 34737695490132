import { Record } from 'immutable'
import { IAssetsTable, ICreateAssets, TAssetChartList } from '../types'

interface Action<T, V> {
  val: V
  type: T
}

export interface Init {
  isLoadPortfolio: boolean
  listPortfolio: Array<ICreateAssets['asset']>
  listAssets: Array<IAssetsTable>
  listAssetsChartList: Array<TAssetChartList>
  mhcTokenBalance: number
}

export const init: Init = {
  listPortfolio: [],
  listAssets: [],
  listAssetsChartList: [],
  isLoadPortfolio: false,
  mhcTokenBalance: 0,
}

export type Actions =
  | Action<'LIST_PORTFOLIO', ICreateAssets['asset'][]>
  | Action<'LIST_ASSETS', IAssetsTable>
  | Action<'LIST_ASSETS_CHARTS', TAssetChartList>
  | Action<'LIST_ASSETS_REMOVE', number>
  | Action<'IS_LOAD_PORTFOLIO', boolean>
  | Action<'MHC_TOKEN_BALANCE', number>

const State: Record.Factory<Init> = Record(init)

const reducer = function (state: Record<Init> = new State(), action: Actions): Record<Init> {
  switch (action.type) {
    case 'LIST_PORTFOLIO':
      return state.set('listPortfolio', action.val)
    case 'LIST_ASSETS':
      const assetsList = state.get('listAssets')
      if (action.val) {
        if (assetsList.length > 0) {
          const isExist = assetsList.some((asset) => asset.id === action.val.id)
          if (isExist) {
            const updatedAssetsList = assetsList.map((item) => {
              if (item.id === action.val.id) return { ...action.val }
              else return item
            })
            return state.set('listAssets', [...updatedAssetsList])
          } else {
            return state.set('listAssets', [...assetsList, { ...action.val }])
          }
        } else {
          return state.set('listAssets', [...[action.val]])
        }
      }
      return state.set('listAssets', assetsList)
    case 'LIST_ASSETS_CHARTS':
      const assetsChartList = state.get('listAssetsChartList')
      if (action.val) {
        if (assetsChartList.length > 0) {
          const isExist = assetsChartList.some((asset) => asset.id === action.val.id)
          if (isExist) {
            const updatedAssetsChartList = assetsChartList.map((item) => {
              if (item.id === action.val.id) return { ...action.val }
              else return item
            })
            return state.set('listAssetsChartList', [...updatedAssetsChartList])
          } else {
            return state.set('listAssetsChartList', [...assetsChartList, { ...action.val }])
          }
        } else {
          return state.set('listAssetsChartList', [...[action.val]])
        }
      }
      return state.set('listAssetsChartList', assetsChartList)

    case 'LIST_ASSETS_REMOVE':
      const assetsListSecond = state.get('listAssets').filter((item) => item.id !== action.val)
      return state.set('listAssets', [...assetsListSecond])
    case 'IS_LOAD_PORTFOLIO':
      return state.set('isLoadPortfolio', action.val)
    case 'MHC_TOKEN_BALANCE':
      return state.set('mhcTokenBalance', action.val)

    default:
      return state
  }
}

export default reducer
