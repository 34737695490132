import React from 'react'
import styled from 'styled-components'

export const GradientButton = styled.button<{
  width?: string
  height?: string
  padding?: string
  color?: string
  borderRadius?: string
  backgroundColor?: string
  hoverColor?: string
  margin?: string
}>`
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    background: ${({ hoverColor }) => (hoverColor ? hoverColor : 'linear-gradient(#fbb212, #f48f33)')};
    // transform: scale(1.05);
  }

  &:disabled {
    background: #606061;
    color: #caced2;
    cursor: default;
  }

  color: ${({ color }) => (color ? color : '#ffffff')};
  padding: ${({ padding }) => (padding ? padding : '8px 16px')};
  margin: ${({ margin }) => (margin ? margin : '0')};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '40px')};
  background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#0093fc')};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '0px')};
  @media screen and (max-width: 1536px) {
    height: 30px;
  }
`
