import { createSlice } from '@reduxjs/toolkit'

interface IState {
  isClickAvatar: boolean
}

export const initialState: IState = {
  isClickAvatar: false,
}

const avatarSlice = createSlice({
  name: 'avatarclick',
  initialState,
  reducers: {
    setIsClickAvatar(state, action) {
      state.isClickAvatar = action.payload
    },
  },
})

export const { setIsClickAvatar } = avatarSlice.actions
export default avatarSlice.reducer
