import { shallowEqual, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init } from '../redusers'

export const useGetBridgeEarning = () => {
  const amount = useSelector<AppState, Init['bridgeEarning']>(
    ({ dashboard }) => dashboard.get('bridgeEarning'),
    shallowEqual
  )

  return amount
}
