import MenuIcons from 'components/Icons/MenuIcons'
import { DREAMING_URL, SOCIAL_URL } from 'constants/urls'
import { some } from 'fp-ts/lib/ReadonlyRecord'
import React, { useCallback, useState } from 'react'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import './styles.scss'
import { IGetAllDomainListR, TAccountItem, TGetClaimListR } from 'utils/api'
import { Init as TransactionInit } from 'redusers/transaction'
import Loader from 'components/Loader'
import { MdAddShoppingCart } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { AudioItem } from 'components/Menu/tooltip'
import Avatar from 'react-avatar'

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  // max-height: 280px;
  border-radius: 20px;
  border: 1px solid;
  font-size: 14px;
  padding: 5px 10px;
  // margin-right: 15px;
  // margin-top: 15px;
  // margin-bottom: 15px;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
  }
`

interface RowP {
  listItem: TAccountItem
  themeMode: string
  rank: number
}
function Row({ listItem, rank, themeMode }: RowP) {
  const goSpaceBar = (nick: string) => {
    AudioItem('btn-go').play()
    window.open(SOCIAL_URL + nick, '_blank')
  }
  const userName = listItem.first_name + ' ' + listItem.last_name

  return (
    <tr>
      <td>{rank}</td>
      <td>
        <Avatar
          src={typeof listItem.img === 'string' ? listItem.img : undefined}
          name={userName}
          size="44"
          round="50px"
          color={themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)'}
        />
      </td>
      <td className="f-weight">#{listItem.nick}</td>
      <td>${listItem.balance}</td>
      <td>
        <div className="btn-visit" onClick={() => goSpaceBar(listItem.nick)}>
          Visit
        </div>
      </td>
    </tr>
  )
}

export interface IPageProps {
  itemCount: number
  eanerData: TAccountItem[]
}

const TopEarner: React.FC<IPageProps> = ({ itemCount, eanerData }) => {
  const themeMode = useGetThemeMode()
  const listdata = eanerData.filter((v: TAccountItem) => v.nick != null)

  return (
    <Container
      style={{
        background: themeMode == 'main' ? '#000000cc' : '#e8eff7',
        borderColor: themeMode == 'main' ? '#02569d' : 'gray',
      }}
    >
      <span className="claim-title">
        <h4>Top #Earners</h4>
      </span>
      <div className="claim-bottom-item">
        {listdata.length > 0 ? (
          <table
            className="claim-table"
            cellSpacing="0"
            cellPadding="10"
            width="100%"
            style={{
              background:
                themeMode == 'main' ? 'rgba(0, 0, 0, 0.5)' : themeMode == 'light' ? 'white' : 'rgba(0, 0, 0, 0.5)',
              color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
            }}
          >
            <thead>
              <tr>
                <th>Rank</th>
                <th></th>
                <th>#Profile</th>
                <th>Earning</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listdata.map((item, index) => {
                let keyVal = item.id + '-' + index
                return <Row key={keyVal} rank={index + 1} listItem={item} themeMode={themeMode} />
              })}
            </tbody>
          </table>
        ) : (
          <span className="loader-spinner">
            {/* <Loader stroke="green" size="30px" /> */}
            No Data
          </span>
        )}
      </div>
    </Container>
  )
}
export default TopEarner
