import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useAvailableCategoryList } from 'views/Search/hooks/availableCategoryList'
import { CategoryInfoP } from '../../../interfaces'
import CategoryItem from '../CategoryItem'
import styled from 'styled-components'
import { useGetThemeMode } from 'state/theme/hook'
import { useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { TR_Cart } from 'views/Cart/reduser'
import { ICloseSearchProps } from '../../searchEngineResponse'

const CategoryWraper = styled.div`
  position: relative;
  padding: 0 12px;
`
const CategoryContainer = styled.div`
  width: 100%;
  padding: 0px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  overflow-x: auto;
  gap: 20px;
  white-space: nowrap;
  cursor: pointer;
  ::-webkit-scrollbar {
    display: none;
  }
`
const CategoryRightContainer = styled.div`
  display: flex;
  gap: 20px;
  white-space: nowrap;
  position: absolute;
  flex-direction: column;
  right: -17.3%;
  // top: 80%;
  ::-webkit-scrollbar {
    display: none;
  }
`

const CategoryMainContainer: React.FC<ICloseSearchProps> = ({ closeSearch, handleCloseSearch }) => {
  const increaseRef = useRef<HTMLDivElement>(null)
  const allCategoryList = useAvailableCategoryList()
  const listItems = useSelector<AppState, TR_Cart['cart_listItems']>(({ cart_reducer }) =>
    cart_reducer.get('cart_listItems')
  )
  const cartCount = listItems?.toArray() ? listItems?.toArray().length : 1
  const topPercent = 80 + cartCount * 20 + '%'

  const categoryList = useMemo(() => {
    return [...allCategoryList.filter((item) => item.availability !== 'sold')]
  }, [allCategoryList])

  const themeMode = useGetThemeMode()

  return (
    <>
      {!closeSearch && (
        <CategoryWraper
          style={{
            background: themeMode === 'main' ? '#00000090' : '#ffffff90',
          }}
        >
          <CategoryContainer ref={increaseRef}>
            {categoryList.map((item: CategoryInfoP, index: number) => (
              <>
                {index < 4 && (
                  <CategoryItem
                    key={item.id + index}
                    id={item.id}
                    popularity={item.popularity}
                    cartPrice={item.cartPrice}
                    availability={item.availability}
                  />
                )}
              </>
            ))}
          </CategoryContainer>
          <CategoryRightContainer ref={increaseRef} style={{ top: topPercent }}>
            {categoryList.map((item: CategoryInfoP, index: number) => (
              <>
                {index >= 4 && (
                  <CategoryItem
                    key={item.id + index}
                    id={item.id}
                    popularity={item.popularity}
                    cartPrice={item.cartPrice}
                    availability={item.availability}
                    type={1}
                  />
                )}
              </>
            ))}
          </CategoryRightContainer>
        </CategoryWraper>
      )}
    </>
  )
}

export default CategoryMainContainer
