import React, { FC, useEffect } from 'react'
import ReactTable, { createTheme } from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useGetThemeMode } from 'state/theme/hook'
import { AppState } from '../../../../redusers/config'
import { TAccountDomainItem, TAccountDomains } from '../../../../utils/api'
import { onAccountClearItemDomains, onAccountGetItemDomains } from '../actions'
import cn from '../cn.module.css'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'
import styled from 'styled-components'

const AdminAccountContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `calc(100% - ${width})`};
  z-index: 0;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
    fontFamily: '"Montserrat", sans-serif',
  },
  background: {
    default: 'rgba(0,0,0,0.5)',
  },
  context: {
    background: '#FFFFFF',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: '#FFFFFF',
    hover: '#FFFFFF',
    disabled: '#FFFFFF',
  },
  highlightOnHover: {
    default: '#FFFFFF',
    text: '#FFFFFF',
  },
  sortFocus: {
    default: '#FFFFFF',
  },
})
createTheme('solarized_light', {
  text: {
    primary: '#000',
    secondary: '#000',
    fontFamily: '"Montserrat", sans-serif',
  },
  background: {
    default: 'rgba(255,255,255,0.5)',
  },
  context: {
    background: '#000',
    text: '#000',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: '#000',
    hover: '#000',
    disabled: '#000',
  },
  highlightOnHover: {
    default: '#000',
    text: '#000',
  },
  sortFocus: {
    default: '#000',
  },
})

const columns = [
  {
    name: 'Date',
    selector: (row: TAccountDomainItem) => 'date',
    cell: (row: TAccountDomainItem) => {
      return row.date.split(' ')[0]
    },
    sortable: true,
    width: '100px',
  },
  {
    name: 'Expire',
    selector: (row: TAccountDomainItem) => 'expire',
    cell: (row: TAccountDomainItem) => {
      return row.expire.split(' ')[0]
    },
    sortable: true,
    width: '100px',
  },
  {
    name: 'Email',
    selector: (row: TAccountDomainItem) => 'user_email',
    cell: (row: TAccountDomainItem) => {
      if (row.user_email === null) return 'no email'
      return row.user_email
    },
    sortable: true,
  },
  {
    name: 'Domain',
    selector: (row: TAccountDomainItem) => 'id',
    cell: (row: TAccountDomainItem) => {
      return row.id
    },
    sortable: true,
  },
  {
    name: 'Is demo',
    selector: (row: TAccountDomainItem) => 'is_demo',
    cell: (row: TAccountDomainItem) => {
      if (row.is_demo === true) return 'Yes'
      return 'No'
    },
    sortable: true,
    width: '100px',
    compact: true,
  },
  {
    name: 'Url',
    selector: (row: TAccountDomainItem) => 'url',
    cell: (row: TAccountDomainItem) => {
      return row.url
    },
    sortable: true,
  },
  {
    name: 'Category',
    selector: (row: TAccountDomainItem) => 'category_name',
    cell: (row: TAccountDomainItem) => {
      return row.category_name.join(',')
    },
    sortable: true,
    //  width:'max-content'
  },
]
const Account: FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id?: string }>()
  useEffect(() => {
    return () => {
      dispatch(onAccountClearItemDomains())
    }
  }, [])
  useEffect(() => {
    if (typeof id === 'undefined') return
    dispatch(onAccountGetItemDomains(id))
  }, [id])

  const accountItemDomains = useSelector<AppState, TAccountDomains['items']>(({ accounts }) =>
    accounts.get('accountItemDomains')
  )

  const themeMode = useGetThemeMode()
  const isCollapseMenu = useIsCollapseMenu()

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
        fontSize: '14px',
      },
    },
    subHeader: {
      style: {
        padding: 0,
        minHeight: '43px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '15px',
        fontWeight: 600,
        borderTop: '2px solid #025498',
        background: themeMode === 'main' ? 'black' : '#b7eaf6',
        color: themeMode === 'main' ? 'white' : 'black',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  }

  return (
    <AdminAccountContainer width={isCollapseMenu ? '65px' : '235px'}>
      <ReactTable
        noHeader={true}
        theme={themeMode === 'light' ? 'solarized_light' : 'solarized'}
        data={accountItemDomains}
        columns={columns}
        customStyles={customStyles}
        fixedHeader={true}
        fixedHeaderScrollHeight="100vh"
      />
    </AdminAccountContainer>
  )
}

export default Account
