import React, { useCallback, useEffect, useState } from 'react'
import { useGetThemeMode } from 'state/theme/hook'
import CommandBackslash from 'components/CommandBackslash'
import CommandReturn from 'components/CommandReturn'
import './styles.scss'
import CommandTab from 'components/CommandTab'
import CommandSwift from 'components/CommandSwift'
import CommandHolo from 'components/CommandHolo'
import CommandControl from 'components/CommandControl'
import CommandSpacebar from 'components/CommandSpacebar'
import CommandDream from 'components/CommandDream'
import CommandCapslock from 'components/CommandCapslock'
import FreedomImg from '../../assets/command/freedom.png'
import FreedomGrayImg from '../../assets/command/freedom-gray.png'
import CommerceImg from '../../assets/command/commerce.png'
import SpeechImg from '../../assets/command/speech.png'
import Cart from 'views/Cart'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { BsChatDots } from 'react-icons/bs'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import MyCart from 'views/Cart'
import { UserInfo } from 'utils/api'
import { onAddNickAll, onAddNickToHashtag } from 'actions/hashtag'
import { UseDomainsDashboard } from 'components/DomainsDashboardTable/hooks'
import { useGetDomainList } from 'views/Search/hooks/availableCategoryList'
import { HASHTAG_URL, SOCIAL_URL } from 'constants/urls'

const DomainsCommand: React.FC<any> = () => {
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const { hashtagList } = UseDomainsDashboard(0)
  const { accountList } = useGetDomainList()
  const userInfo = useSelector<AppState, UserInfo>(({ user }) => user.get('userInfo'))
  const [showCart, setShowCart] = useState<boolean>(false)
  const countCart = useSelector<AppState, number>(({ cart_reducer }) => cart_reducer.get('cart_listItems')?.size!)

  const onShowCart = useCallback(() => {
    showCart ? setShowCart(false) : setShowCart(true)
  }, [showCart])

  const addAllNick = () => {
    let test = false
    const nickArr: string[] = []
    const urlArr: string[] = []
    const userIdArr: number[] = []
    accountList.map((v) => {
      if (v.nick !== null && v.nick !== undefined && v.nick !== '') {
        const hashtag = '#' + v.nick
        nickArr.push(hashtag)
        urlArr.push(HASHTAG_URL + hashtag)
        userIdArr.push(v.id)
      }
    })
    if (test) dispatch(onAddNickAll({ nickArr, userIdArr, urlArr }))
  }

  useEffect(() => {
    // addAllNick()
    if (userInfo.nick !== '') {
      const hashtag = '#' + userInfo.nick
      const flist = hashtagList.filter((v) => v.id === hashtag)
      if (flist.length === 0) dispatch(onAddNickToHashtag(hashtag))
    }
  }, [dispatch])

  return (
    <div className="dashboard-wrapper">
      <div
        className="dashboard-wrapper__main"
        style={{
          background: themeMode == 'main' ? '#00000090' : '#ffffff90',
          color: themeMode == 'main' ? 'white' : 'black',
          borderTopColor: themeMode == 'main' ? '#02569d' : 'gray',
        }}
      >
        <div className="paragrah">
          <div className="command_left">
            <div className="left-row">
              <CommandReturn />
              <CommandBackslash />
            </div>
            <div className="left-row-three">
              <div className="tab-swift">
                <CommandTab />
                <CommandSwift />
              </div>
              <CommandHolo />
            </div>
          </div>
          <div className="command_right">
            <div className="iconCart" onClick={onShowCart}>
              <AiOutlineShoppingCart fontSize={20} />
              {countCart > 0 ? <span className="cartStic">{countCart}</span> : null}
              {showCart && (
                <div className="myCartDropdown">
                  <MyCart />
                </div>
              )}
            </div>
            <div className="para-1">
              <img src={themeMode === 'light' ? FreedomGrayImg : FreedomImg} className="left-img" alt="" />
              <img src={CommerceImg} className="left-img" alt="" />
            </div>
          </div>
        </div>

        <div className="paragrah">
          <div className="command_left">
            <div className="left-row">
              <CommandControl />
            </div>
            <div className="left-row-three">
              <div className="tab-swift">
                <CommandSpacebar />
                <CommandDream isSearch={false} />
              </div>
              <CommandCapslock />
            </div>
          </div>
          <div className="command_right">
            <div className={'iconCart'} onClick={onShowCart}>
              <BsChatDots fontSize={20} />
            </div>
            <div className="para-1">
              <img src={themeMode === 'light' ? FreedomGrayImg : FreedomImg} className="left-img" alt="" />
              <img src={SpeechImg} className="left-img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DomainsCommand
