import React from 'react'
import { MonthDatePicker } from '.'
import { MonthNameList } from '../../constants'
import { useDatePickerContext } from '../../contexts/DatePickerContext'
import { OverlayContainer, SubContainer, TitleText } from '../../styled'
import { MonthSelectBoxContainer } from './styled'
import { YearPicker } from './YearPicker'
import styled from 'styled-components'

const SelectedMonthText = styled(TitleText)`
  cursor: pointer;
  &:hover {
    color: #fdbe08;
  }
`

export const MonthSelectBox = () => {
  const { currentDate } = useDatePickerContext()
  const [open, setOpen] = React.useState(false)

  const toggle = () => setOpen(!open)

  return (
    <>
      <SelectedMonthText onClick={toggle}>
        {`${MonthNameList[currentDate.month]}, ${currentDate.year}`}
      </SelectedMonthText>
      {open && (
        <MonthSelectBoxContainer>
          <SubContainer width={'320px'}>
            <YearPicker />
            <MonthDatePicker />
          </SubContainer>
          <OverlayContainer onClick={toggle} />
        </MonthSelectBoxContainer>
      )}
    </>
  )
}
