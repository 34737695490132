import React from 'react'
import './styles.scss'
import { NavLink } from 'react-router-dom'

interface HeaderLoginLinksProps {
  text: string
  to: string
  label: string
  themeMode?: string
}

const HeaderLoginLinks: React.FC<HeaderLoginLinksProps> = ({ text, to, label, themeMode }) => {
  return (
    <div className="header-login-links">
      <span style={{ color: themeMode === 'main' ? 'white' : 'black' }}>{text}</span>
      <NavLink to={to} style={{ color: themeMode === 'main' ? 'white' : 'black' }}>
        {label}
      </NavLink>
    </div>
  )
}
export default HeaderLoginLinks
