import React, { FC, useContext, useEffect, useState } from 'react'
import cn from './cn.module.scss'
import { CategoryInfoP } from '../../../interfaces'
import { FaShoppingCart } from 'react-icons/fa'
import HashTagVolumeIcon from 'assets/icons/hashtag_volume.svg'
import { Tooltip } from 'react-tippy'
import { addItemCart } from 'views/Cart/actions'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { TR_Cart } from 'views/Cart/reduser'

const CategoryItem: React.FC<CategoryInfoP> = ({ id, popularity, cartPrice, availability, style, type }) => {
  const dispatch = useDispatch()
  const listItems = useSelector<AppState, TR_Cart['cart_listItems']>(({ cart_reducer }) =>
    cart_reducer.get('cart_listItems')
  )
  const [cartAvailability, setCartAvailability] = useState<'available' | 'sold' | 'cart'>(availability)

  useEffect(() => {
    const isCart = listItems?.find((item) => item.id === id)
    if (isCart !== undefined) setCartAvailability('cart')
    else setCartAvailability(availability)
  }, [listItems])

  const buyHashtags = () => {
    if (availability !== 'available') return
    dispatch(
      addItemCart({
        id: id,
        isDemo: false,
        price: cartPrice,
        applyDiscount: false,
        type: 'domain',
        keyword: '',
      })
    )
    setCartAvailability('cart')
  }
  return (
    <div
      className={cn.categoryContainer}
      style={{
        marginTop: type == 1 ? '0px' : '',
        marginBottom: type == 1 ? '0px' : '',
        width: type == 1 ? '210px' : '',
        minWidth: type == 1 ? '210px' : '',
      }}
    >
      <div className={cn.Header}>
        <Tooltip title={id} position="top" className={cn.domainName}>
          <span>{id}</span>
        </Tooltip>
        {availability === 'available' && <span className={cn.availability}>Available</span>}
      </div>
      <div className={cn.itemBody}>
        <div className={cn.popularityContainer}>
          <span className={cn.popularityHeader}>POPULARITY</span>
          <div className={cn.popularityBody}>
            <img src={HashTagVolumeIcon} alt="" />
            <div className={cn.volume}>
              <span className={cn.volumeNumber}>{popularity.toLocaleString()}</span>
              <span className={cn.highVolume}>HIGH VOLUME</span>
            </div>
          </div>
        </div>
        <div
          className={`${cartAvailability === 'available' ? cn.cartBtn : cn.disabledCardBtn}`}
          onClick={() => buyHashtags()}
        >
          {cartAvailability === 'available' ? (
            <>
              <FaShoppingCart />
              <span className={cn.cartPrice}>${cartPrice.toLocaleString()}</span>
              <span className={cn.anualUnit}>/yr</span>
            </>
          ) : cartAvailability === 'cart' ? (
            <span style={{ fontSize: '24px', paddingTop: '6px' }}>
              <FaShoppingCart />
            </span>
          ) : (
            <span>SOLD</span>
          )}
        </div>
      </div>
    </div>
  )
}
export default CategoryItem
