import React from 'react'
import MainContent from '../../components/MainContent'
import './style.css'

function PrivacyPolicy() {
  return (
    <MainContent>
      <div className={'text'}>
        <h2>Privacy Policy</h2>
        <p></p>
        <p>
          space.hashtag.org website administration is obliged to maintain your privacy on the Internet. We pay great
          attention to securing the data you provided to us. Our privacy policy is based on the General Data Protection
          Regulation (GDPR) of the European Union. The purposes, for which we collect your personal data are:
          improvement of our service, communication with visitors to this site, presenting the information requested by
          the user, providing services associated with the website&apos;s specialization, , and for other actions listed
          below.
        </p>

        <h4>Personal data storage and processing</h4>
        <p>
          We collect and process your personal data only with your willing consent. With your permission, we can collect
          and process the following data: name and surname, e-mail address, . Collection and processing of your personal
          information is carried out in accordance with the laws of the European Union and the USA.
        </p>

        <h4>Data storage, alteration, and removal </h4>
        <p>
          The user, who has provided space.hashtag.org with their personal data, has the right for their alteration and
          removal, as well as the right to recall the agreement to data processing. Time, during which your personal
          data will be stored is: time the personal data is used that is necessary for the website&apos;s main activity.
          After finishing with the processing of your personal data, the website&apos;s administration will permanently
          delete it. To access your personal data, you can contact the administration on: space.hashtag.org. We will be
          able to pass your data to a third party only with your willing consent. If the data was transferred to a third
          party, which is not associated with our organization, we cannot perform any changes to that data.
        </p>

        <h4>Processing of visiting technical data </h4>
        <p>
          Records of your IP address, time of visit, browser settings, operational system and other technical
          information is saved in the database when you visit space.hashtag.org. This data is necessary for the correct
          display of the website&apos;s content. It is impossible to identify the person of the visitor using this data.
        </p>

        <h4>Children&apos;s personal information </h4>
        <p>
          If you are a parent or a legal guardian of an underage child, and you know that the child has provided us with
          their personal information without your consent, please contact us on: space.hashtag.org. It is forbidden to
          enter personal data of underage users without the agreement of parents or lawful guardians.
        </p>

        <h4>Cookies processing </h4>
        <p>
          We use cookie files for the correct display of the website&apos;s content and for the convenience of browsing
          space.hashtag.org. They are small files, that are stored on your device. They help the website to remember
          information about you, such as in which language you use the website and which pages have you already opened.
          This information will be useful in the next visit. Thanks to cookie files, the website&apos;s browsing becomes
          much more convenient. You can learn more about these files here. You can set up cookies reception and blocking
          your browser yourself. Inability to receive cookie files may limit the functionality of the website.
        </p>

        <h4>Processing of personal data by other services </h4>
        <p>
          This website uses third-party online services, which perform data collecting, independent from us. Such
          services include: Google Analytics , Google AdSense.
          <br />
          Data collected by these services may be provided to other services within those organizations. They can use
          the data for advertising personalization of their own advertising network. You can learn about user agreements
          of those organizations on their websites. You can also refuse their collection of your personal data. For
          example, Google Analytics Opt-out Browser Add-on can be found here . We do not pass any personal data to other
          organizations or services, which are not listed in this privacy policy. As an exception, the collected data
          may be provided on a lawful request of state authorities, that are authorized to request such information.
        </p>

        <h4>Links to other websites </h4>
        <p>
          Our website space.hashtag.org may contain links to other websites, which are not under our control. We are not
          responsible for the content of these websites. We recommend you familiarize yourself with the privacy policy
          of every website that you visit, if such policy exists.
        </p>

        <h4>Changes to the privacy policy </h4>
        <p>
          From time to time, our website space.hashtag.org may update our privacy policy. We inform about any changes to
          the privacy policy, placed on this webpage. We are monitoring any changes in legislation, which is related to
          personal data in European Union and USA. If you have entered any of your personal data on our website, we will
          inform you about the changes in our privacy policy. If your personal data, and more specifically, your contact
          information was entered incorrectly, we will not be able to contact you.
        </p>

        <h4>Feedback and final clauses </h4>
        <p>
          You can contact the administration of space.hashtag.org regarding any questions related to privacy policy on:
          space.hashtag.org, or by filling a contact form specified in a corresponding section of this website. If you
          do not agree with this privacy policy, you cannot use the services of space.hashtag.org. In this case you
          should avoid visiting our website.
        </p>
      </div>
    </MainContent>
  )
}

export default PrivacyPolicy
