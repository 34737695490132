import React from 'react'
import Chart from 'react-apexcharts'
import { useGetPurchaseLineChartConfig } from '../../hooks/usePurchase'

export const LineChart: React.FC = () => {
  const purchaseLineChartConfig = useGetPurchaseLineChartConfig()

  return (
    <Chart
      type="area"
      options={purchaseLineChartConfig.options}
      series={purchaseLineChartConfig.series}
      width={'100%'}
      height={'200px'}
    />
  )
}
