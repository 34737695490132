import CommandReturn from 'components/CommandReturn'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'
import styled from 'styled-components'
import DiagramImg from '../../assets/command/Return-Diagram-Dark.svg'
import DiagramLImg from '../../assets/command/Return-Diagram-Light.svg'
import { useGetThemeMode } from 'state/theme/hook'
import './styles.scss'
import MenuIcons from 'components/Icons/MenuIcons'
import { AppState } from 'redusers/config'
import { AffiliatesState } from './reduser'
import { fetchGetComplexReport, fetchGetQuantityReport, fetchGetTotalAmountReport, fetchReferralCode } from './actions'
import { HASHTAG_URL } from 'constants/urls'
import { fetchGetList } from 'views/SendEmail/actions'
import { MdContentCopy, MdFileCopy } from 'react-icons/md'
import { BsFillAwardFill } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'
import { FiUserPlus } from 'react-icons/fi'
import type { SendEmail as TSendEmail } from '../../views/SendEmail/reduser'
import SendEmail from 'views/SendEmail'
import Reports from './Reports'
import SeeUsers from './SeeUsers'

const AffiliateContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `calc(100% - ${width})`};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const IconWrapper = styled.div<{
  width: string
  height: string
  borderColor: string
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1280px) {
    width: 30px;
    height: 30px;
  }
`
export default function Affiliates(): JSX.Element {
  const dispatch = useDispatch()
  const [ref, setRef] = useState<string>('')
  const [invitCount, setInvitCount] = useState<number>(0)
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [isNotExistRef, setIsNotExistRef] = useState<boolean>(false)
  const [isInvite, setIsInvite] = useState<boolean>(false)
  const [isUsers, setIsUsers] = useState<boolean>(false)
  const [isRewards, setIsRewards] = useState<boolean>(false)

  const referralCode = useSelector<AppState, AffiliatesState['referralCode']>(
    ({ affiliatesReducer }) => affiliatesReducer.referralCode,
    shallowEqual
  )
  const quantityReport = useSelector<AppState, AffiliatesState['quantityReport']>(
    ({ affiliatesReducer }) => affiliatesReducer.quantityReport,
    shallowEqual
  )
  const totalAmountReport = useSelector<AppState, AffiliatesState['totalAmountReport']>(
    ({ affiliatesReducer }) => affiliatesReducer.totalAmountReport,
    shallowEqual
  )
  const emailList = useSelector<AppState, TSendEmail['emailList']>(
    ({ sendEmailReducer }) => sendEmailReducer.emailList,
    shallowEqual
  )

  useEffect(() => {
    dispatch(fetchReferralCode())
    dispatch(fetchGetQuantityReport())
    dispatch(fetchGetComplexReport({ filter: {} }))
    dispatch(fetchGetTotalAmountReport())
  }, [dispatch])

  useEffect(() => {
    if (referralCode === '') return
    setRef(`${HASHTAG_URL}?ref=${referralCode}`)
  }, [referralCode])

  useEffect(() => {
    dispatch(fetchGetList())
    if (emailList) {
      const len = emailList.get('data')?.length as number
      setInvitCount(len)
    }
  }, [dispatch])

  const copyReferralLink = () => {
    setIsCopied(false)
    setIsNotExistRef(false)
    if (ref) {
      navigator.clipboard.writeText(ref)
      setIsCopied(true)
    } else {
      setIsNotExistRef(true)
    }
    const timeId = setTimeout(() => {
      setIsCopied(false)
      setIsNotExistRef(false)
    }, 1500)
    return () => {
      clearTimeout(timeId)
    }
  }

  const handleRewards = () => {
    handleReturn()
    setIsRewards(true)
  }
  const handleInvite = () => {
    handleReturn()
    setIsInvite(true)
  }
  const handleUsers = () => {
    handleReturn()
    setIsUsers(true)
  }
  const handleReturn = () => {
    setIsInvite(false)
    setIsUsers(false)
    setIsRewards(false)
  }

  const themeMode = useGetThemeMode()
  const isCollapseMenu = useIsCollapseMenu()

  return (
    <AffiliateContainer width={isCollapseMenu ? '65px' : '235px'}>
      <div
        className="a-container1"
        style={{
          background: themeMode == 'main' ? '#00000090' : '#ffffff90',
          color: themeMode == 'main' ? 'white' : 'black',
        }}
      >
        <div className="a-topContainer">
          <div className="a-return-container">
            <div className="a-return-left">
              <div className="a-return-header" onClick={handleReturn}>
                <MenuIcons url="affiliate" themeMode={themeMode} size={'18px'} />
                <h3 style={{ marginLeft: '-15px' }}>RETURN</h3>
              </div>
              <div className="a-return-desc">
                <div>
                  Earn up to 25% lifetime on every <br />
                  referral. Anything they buy.you earn.
                </div>
                <button
                  className="a-button-copy-link"
                  onClick={() => copyReferralLink()}
                  style={{
                    background:
                      themeMode === 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                    color: themeMode == 'main' ? 'white' : 'black',
                  }}
                >
                  COPY REFERRAL LINK
                  <span style={{ marginLeft: '10px', marginTop: '5px' }}>
                    {isCopied ? <MdFileCopy /> : <MdContentCopy />}
                  </span>
                </button>
                {isNotExistRef && (
                  <span className="a-error-msg" style={{ color: 'red' }}>
                    No Referral Link
                  </span>
                )}
                {!isNotExistRef && isCopied && <span className="a-error-msg">Copied</span>}
              </div>
            </div>
            <div className="a-return-right">
              <div className="a-return-right-item" onClick={handleRewards}>
                <span className={isRewards ? 'overline' : ''}>REWARDS</span>
                <IconWrapper width={'50px'} height={'50px'} borderColor={'#01b1f3'}>
                  <BsFillAwardFill color="#01b1f3" size={20} />
                </IconWrapper>
                <span>${totalAmountReport?.all.reward ?? 0}</span>
              </div>
              <div className="a-return-right-item" onClick={handleInvite}>
                <span className={isInvite ? 'overline' : ''}>INVITES</span>
                <IconWrapper width={'50px'} height={'50px'} borderColor={'#13c783'}>
                  <AiOutlineMail color="#13c783" size={20} />
                </IconWrapper>
                <span>{emailList.get('data')?.length ?? 0}</span>
              </div>
              <div className="a-return-right-item" onClick={handleUsers}>
                <span className={isUsers ? 'overline' : ''}>USERS</span>
                <IconWrapper width={'50px'} height={'50px'} borderColor={'#ff5900'}>
                  <FiUserPlus color="#ff5900" size={20} />
                </IconWrapper>
                <span>{quantityReport.reduce((total, n) => total + n.value, 0)}</span>
              </div>
            </div>
          </div>
          {!isInvite && !isUsers && !isRewards && (
            <div className="a-diagramContainer">
              <img
                src={themeMode === 'main' ? DiagramLImg : DiagramImg}
                className="diagram_img"
                alt="diagram for affiliates"
              />
            </div>
          )}
        </div>
        {!isInvite && !isUsers && !isRewards && (
          <div className="token-desc">Hashtag Token Distribution Model(#bling)</div>
        )}
      </div>
      {isInvite && (
        <div
          className="a-container2"
          style={{
            background: themeMode == 'main' ? '#00000090' : '#ffffff90',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        >
          <SendEmail />
        </div>
      )}
      {isUsers && (
        <div
          className="a-container2"
          style={{
            background: themeMode == 'main' ? '#00000090' : '#ffffff90',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        >
          <SeeUsers />
        </div>
      )}
      {isRewards && (
        <div
          className="a-container2"
          style={{
            background: themeMode == 'main' ? '#00000090' : '#ffffff90',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        >
          <Reports />
        </div>
      )}
    </AffiliateContainer>
  )
}
