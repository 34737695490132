export const Instruction = {
  mainDescription:
    ' We are simplifying Search by going with a point value system, we are simply listing the results with the highest point values first.',
  subTitle: {
    pointValue: 'Point value - Description',
    factors: 'Factors to include if domains are matched on the point level',
  },
  description: {
    pointValue: [
      { point: 5, detail: 'Keyword selected for the hashtag domain.' },
      {
        point: 1,
        detail:
          'Client choose one of the 3 options for their hashtag (product, service, information). The point is just for picking one and not leaving it blank.',
      },
      { point: 1, detail: 'Client entered an address for the Hashtag domain.' },
      {
        point: 0.25,
        detail: 'For each Social link they entered for the Hashtag domain.',
      },
      {
        point: 1,
        detail: 'If they provided a description for the Hashtag domain.',
      },
      { point: 1, detail: 'If they provided an image for their result.' },
      {
        point: 1,
        detail: 'If they selected a category for their hashtag domain.',
      },
      {
        point: 1,
        detail: 'If they have multiple hashtags pointed to the Hashtag (1 extra point per hashtag pointed).',
      },
      {
        point: 1,
        detail:
          'If they have the same keyword used for more then one domain pointed to the hashtag domain (max of 3 times for 3 points).',
      },
      { point: 1, detail: 'If they have the SEO.money plugin install.' },
      {
        point: 1,
        detail: 'If the target URL has a link back to space.hashtag.org.',
      },

      {
        point: 0.1,
        detail:
          'An extra .1 of a point per hashtag domain they own, meaning if they own 20 hashtag domains they get an extra 2 points.',
      },
      {
        point: 0.1,
        detail:
          'An extra .1 of a point per keyword they have purchased across all hashtag domains.  Meaning if they have purchased a total of 200 keywords across all of their domains they get an extra 20 points.',
      },
      {
        point: 1,
        detail:
          'If they are paying for paid ads in our network they get an extra point for any keywords they are using.',
      },
      {
        point: 1,
        detail: 'If the domain owner has referrals in the system that are spending money.',
      },
      {
        point: 1,
        detail: 'If the domain owner has a balance of MHC in their wallet.',
      },
      {
        point: 1,
        detail:
          'If the domain owner has a social profile in our social network which has its own point system for how much they use it, we would need to create a scale, something like if they have up to 100 points in the social they get an extra point, if they have 200 points in the social they get 2 points, etc, up to 5 total extra points.',
      },
    ],
    factors: [
      'Hashtag domains with higher popularity will rank higher.  This popularity will be determined from a combined total of facebook/twitter/linked in, etc to give each hashtag a popularity score.',
      'Early adoption ranking boost.  Hashtags that have been paying for the keyword longer would rank higher.',
      'Sending and using our decentralized email system will get a rank boost (more emails sent for a higher boost).',
      'Hashtag owners using our bridge get a rank boost.',
    ],
  },
}
