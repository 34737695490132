import { Star } from 'components/Icons/Star'
import React from 'react'
import Logo from '../../assets/icons/credit-icon.svg'
import './styles.scss'

const Title: React.FC<any> = ({ title, feePercent, info = null, themeMode }) => {
  return (
    <div className="title">
      <div className="formTitle">
        <img src={Logo} alt="" />
        <span
          style={{
            color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
          }}
        >
          {title}
        </span>
      </div>
      {info !== null && (
        <div className="starLevel">
          <Star size={'19px'} isFilled={info.nick} link="nick" />
          <Star size={'19px'} isFilled={info.paypal_address} link="paypal" />
          <Star size={'19px'} isFilled={info.mhc_address} link="mhc" />
          <Star size={'19px'} isFilled={info.eth_address} link="eth" />
          <Star size={'19px'} isFilled={info.bnb_address} link="bnb" />
        </div>
      )}
      <h4
        style={{
          color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
        }}
      >
        {feePercent}
      </h4>
    </div>
  )
}
export default Title
