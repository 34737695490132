import { SOCIAL_URL } from 'constants/urls'
import React, { useCallback, useState } from 'react'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import './styles.scss'
import { TGetClaimListR } from 'utils/api'
import { Init as TransactionInit } from 'redusers/transaction'
import { MdAddShoppingCart } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { AudioItem } from 'components/Menu/tooltip'

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  // max-height: 280px;
  border-radius: 20px;
  border: 1px solid;
  font-size: 14px;
  padding: 5px 10px;
  // margin-right: 15px;
  // margin-top: 15px;
  // margin-bottom: 15px;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
  }
`

interface RowP {
  listItem: TGetClaimListR
  themeMode: string
  claimdrop: string
  domainPrice: number
}
function Row({ listItem, claimdrop, domainPrice, themeMode }: RowP) {
  let itemUrl = listItem.url
  if (itemUrl != null) {
    if (itemUrl.length > 25) {
      itemUrl = itemUrl.substring(0, 25) + '...'
    }
  } else {
    itemUrl = ''
  }
  let itemDomain = listItem.domainName
  if (itemDomain.length > 15) itemDomain = itemDomain.substring(0, 15) + '...'
  let itemNick = listItem.nick
  if (itemNick.length > 15) itemNick = itemNick.substring(0, 15) + '...'

  const goTargetUrl = (url: string) => {
    AudioItem('tab-launch').play()
    url.split(',').map((item) => {
      window.open(item.trim(), '_blank')
    })
  }
  const goSpaceBar = (nick: string) => {
    AudioItem('btn-go').play()
    window.open(SOCIAL_URL + nick, '_blank')
  }
  const claimDomain = (domainName: string) => {}

  return (
    <tr>
      <td className="f-weight">{itemDomain}</td>
      <td>#{itemNick}</td>
      <td>{itemUrl}</td>
      {claimdrop == 'claimed' && (
        <>
          <td>{listItem.price}</td>
          <td>
            {itemUrl != '' ? (
              <div className="btn-go" onClick={() => goTargetUrl(listItem.url)}>
                Go
              </div>
            ) : (
              <div className="btn-visit" onClick={() => goSpaceBar(listItem.nick)}>
                Visit
              </div>
            )}
          </td>
        </>
      )}
      {claimdrop == 'dropped' && (
        <>
          <td>{domainPrice}</td>
          <td>
            <div className="btn-claim" onClick={() => claimDomain(itemDomain)}>
              Claim
              <MdAddShoppingCart size={15} />
            </div>
          </td>
        </>
      )}
    </tr>
  )
}

export interface IPageProps {
  itemCount: number
  claimDomainItems: TGetClaimListR[]
  claimdrop: string
}

const DomainClaimed: React.FC<IPageProps> = ({ itemCount, claimDomainItems, claimdrop }) => {
  const themeMode = useGetThemeMode()
  const domainPrice = useSelector<AppState, TransactionInit['price']>(({ transaction }) => transaction.get('price'))
  const listdata = claimDomainItems.filter((v: TGetClaimListR) => v.nick != null)

  return (
    <Container
      style={{
        background: themeMode == 'main' ? '#000000cc' : '#e8eff7',
        borderColor: themeMode == 'main' ? '#02569d' : 'gray',
      }}
    >
      <span className="claim-title">
        <h4>#Domains recently {claimdrop}</h4>
      </span>
      <div className="claim-bottom-item">
        {listdata.length > 0 ? (
          <table
            className="claim-table"
            cellSpacing="0"
            cellPadding="10"
            width="100%"
            style={{
              background: themeMode == 'main' ? 'rgba(0, 0, 0, 0.5)' : 'white',
              color: themeMode == 'main' ? 'white' : 'black',
            }}
          >
            <thead>
              <tr>
                <th>#Domain Name</th>
                <th>#User</th>
                <th>#URL</th>
                <th>#Price</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listdata.map((item, index) => {
                let keyVal = item.id + '-' + index
                return (
                  <Row
                    key={keyVal}
                    listItem={item}
                    claimdrop={claimdrop}
                    domainPrice={domainPrice}
                    themeMode={themeMode}
                  />
                )
              })}
            </tbody>
          </table>
        ) : (
          <span className="loader-spinner">
            {/* <Loader stroke="green" size="30px" /> */}
            No Data
          </span>
        )}
      </div>
    </Container>
  )
}
export default DomainClaimed
