import { call, fork, put, takeLatest } from '@redux-saga/core/effects'
import { push } from 'connected-react-router'
import { checkErorr, getToken } from '../../../sagas/helper'
import { createAssets, getMHCTokenBalance, getPortfolioList, removeAsset } from '../api'
import { Actions as ActionsPortfolio } from '../reducers'
import { ICreateAssets, TAcreateAssets, TAgetMHCTokenBalance, TAremoveAsset } from '../types'

export function* watcherSaga() {
  yield takeLatest('CREATE_ASSETS', workerCreateAssets)
  yield takeLatest('REMOVE_ASSET', workerRemoveAsset)
  yield takeLatest('GET_PORTFOLIO_LIST', workerGetPortfolioList)
  yield takeLatest('GET_MHC_TOKEN_BALANCE', workerGetMHCTokenBalance)
}

function* workerCreateAssets({ payload }: TAcreateAssets) {
  try {
    yield put<ActionsPortfolio>({
      type: 'IS_LOAD_PORTFOLIO',
      val: true,
    })

    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const res: ICreateAssets['asset'][] = yield call(createAssets, payload, token)

    if (res) {
      yield put<ActionsPortfolio>({
        type: 'LIST_PORTFOLIO',
        val: res,
      })
    }
  } catch (e: any) {
    console.error(e.message)
    yield fork(checkErorr, e)
  } finally {
    yield put<ActionsPortfolio>({
      type: 'IS_LOAD_PORTFOLIO',
      val: false,
    })
  }
}

function* workerRemoveAsset({ payload }: TAremoveAsset) {
  try {
    yield put<ActionsPortfolio>({
      type: 'IS_LOAD_PORTFOLIO',
      val: true,
    })

    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const res: ICreateAssets['asset'][] = yield call(removeAsset, payload, token)

    if (res) {
      yield put<ActionsPortfolio>({
        type: 'LIST_PORTFOLIO',
        val: res,
      })
      yield put<ActionsPortfolio>({
        type: 'LIST_ASSETS_REMOVE',
        val: payload.id,
      })
    }
  } catch (e: any) {
    console.error(e.message)
    yield fork(checkErorr, e)
  } finally {
    yield put<ActionsPortfolio>({
      type: 'IS_LOAD_PORTFOLIO',
      val: false,
    })
  }
}

function* workerGetPortfolioList() {
  try {
    yield put<ActionsPortfolio>({
      type: 'IS_LOAD_PORTFOLIO',
      val: true,
    })

    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const res: ICreateAssets['asset'][] = yield call(getPortfolioList, token)

    if (res) {
      yield put<ActionsPortfolio>({
        type: 'LIST_PORTFOLIO',
        val: res,
      })
    }
  } catch (e: any) {
    console.error(e.message)
    yield fork(checkErorr, e)
  } finally {
    yield put<ActionsPortfolio>({
      type: 'IS_LOAD_PORTFOLIO',
      val: false,
    })
  }
}

function* workerGetMHCTokenBalance({ payload }: TAgetMHCTokenBalance) {
  try {
    yield put<ActionsPortfolio>({
      type: 'IS_LOAD_PORTFOLIO',
      val: true,
    })

    const token: string = yield call(getToken)
    if (!token) {
      return
    }

    const mhcTokenBalance: number = yield call(
      getMHCTokenBalance,
      {
        address: payload.address,
      },
      token
    )

    yield put<ActionsPortfolio>({
      type: 'MHC_TOKEN_BALANCE',
      val: mhcTokenBalance,
    })
    yield put<ActionsPortfolio>({
      type: 'IS_LOAD_PORTFOLIO',
      val: false,
    })
  } catch (e: any) {
    console.error({ e })
  }
}

export default watcherSaga
