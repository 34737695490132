import { Record } from 'immutable'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { connect } from 'react-redux'
import { useGetThemeMode } from 'state/theme/hook'
import { onChangeModalKeyWords, onDomainEdit, onHashtagGetItem, onRerenderEditForm } from '../../actions/hashtag'
import { Init as Actions } from '../../redusers/actions'
import { Init as Modals } from '../../redusers/modals'
import { Init as User } from '../../redusers/user'
import { CategoryList, hashtagGetItemRes } from '../../utils/api'
import countryList from '../../utils/countryList'
import Checkbox from '../Checkbox'
import CheckboxSmallText from '../Checkbox/CheckboxSmallText'
import FormInput from '../FormInput'
import SelectInput from '../FormInput/SelectInput'
import FormKeywordsField from '../FormKeywordsField'
import FormSelect from '../FormSelect'
import Key from '../Keywords'
import UnifiedModal1 from '../Modals/UnifiedModal1'
import cn from './cn.module.css'
import {
  changeModalKeyWords,
  checkboxChange,
  initialState,
  inputChange,
  Item,
  keyChange,
  onChangeFile,
  onSubmit,
  reducer,
  reducerError,
  selectChange,
  State,
  StateError,
  textareaChange,
} from './events'

interface DomainEditFormP {
  onDomainEdit: (val: Item) => void
  onHashtagGetItem: Function
  hashtagItem: hashtagGetItemRes | undefined
  categoryList: CategoryList
  showModalKeyWords: boolean
  priseCountKeyWords: number
  onChangeModalKeyWords: (val: boolean) => void
  onRerenderEditForm: Function
  isLoadFEdit: boolean
  stopSale: boolean
}

const DomainEditForm: React.FC<DomainEditFormP> = ({
  onDomainEdit,
  onHashtagGetItem,
  hashtagItem,
  categoryList,
  showModalKeyWords,
  priseCountKeyWords,
  onChangeModalKeyWords,
  onRerenderEditForm,
  isLoadFEdit,
  stopSale,
}) => {
  const [state, dispatch] = useReducer(reducer, new State())
  const [stateError, dispatchError] = useReducer(reducerError, new StateError())
  const [categoryes, setCategoryes] = useState<Array<string[]>>([])

  const [keyCount, setKeyCount] = useState<number>(0)
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    onHashtagGetItem()
    return () => {
      onRerenderEditForm()
    }
  }, [])

  useEffect(() => {
    if (typeof hashtagItem === 'undefined') {
      return
    }
    let prop: keyof Item
    for (prop in initialState) {
      if (hashtagItem[prop] !== undefined) {
        dispatch({ type: prop, val: hashtagItem[prop] })
      }
    }
  }, [hashtagItem])

  useEffect(() => {
    const arr: Array<string[]> = categoryList.map((item) => [item.id, item.name])
    setCategoryes(arr)
  }, [categoryList])

  const maxTags = useMemo(() => {
    if (typeof hashtagItem === 'undefined') {
      return 0
    }
    if (stopSale === true) {
      return +hashtagItem.max_keywords
    }
    if (hashtagItem.is_demo === true) {
      return 5
    }
    return 50
  }, [hashtagItem, stopSale])

  const extraKey: number = useMemo(() => {
    let diff = 0
    if (typeof hashtagItem === 'undefined') return diff

    const key = state.get('keywords')
    if (key === null) return diff
    if (keyCount === 0) setKeyCount(key?.length)
    if (keyCount == 0) return diff

    diff = key.length - keyCount
    return diff
  }, [hashtagItem, keyCount, state])

  const feePrice: number = useMemo(() => {
    let price = 0
    if (extraKey > 0) {
      for (let i = 0; i < extraKey; i++) {
        price += Math.pow(2, i)
      }
    }
    return price
  }, [extraKey])

  const themeMode: string = useGetThemeMode()

  return (
    <>
      <form
        className={themeMode == 'light' ? cn.form_edit_l : cn.form_edit}
        onSubmit={onSubmit(state, onDomainEdit, dispatchError)}
      >
        <div className={cn.formCol1}>
          <div className={cn.checkGroup}>
            <p className={cn.formTit}>Describe your Domain</p>
            <div className={cn.domainDescribe}>
              <Checkbox
                text="Services Domain"
                checked={!!state.get('is_service_domain')}
                onChange={checkboxChange(dispatch, dispatchError, 'is_service_domain')}
                themeMode={themeMode}
              >
                <CheckboxSmallText text="I sell services." />
              </Checkbox>
              <Checkbox
                text="Product Domain"
                checked={!!state.get('is_product_domain')}
                onChange={checkboxChange(dispatch, dispatchError, 'is_product_domain')}
                themeMode={themeMode}
              >
                <CheckboxSmallText text="I sell products." />
              </Checkbox>
              <Checkbox
                text="Information Domain"
                checked={!!state.get('is_info_domain')}
                onChange={checkboxChange(dispatch, dispatchError, 'is_info_domain')}
                themeMode={themeMode}
              >
                <CheckboxSmallText text="I don’t sell anything." />
              </Checkbox>
            </div>
          </div>
          <div className={cn.checkGroup}>
            <p className={cn.formTit}>My target market is?</p>
            <div className={cn.domainDescribe}>
              <Checkbox
                cn__sinccheck={'cn__sinccheck'}
                text="Local"
                checked={!!state.get('is_local_target')}
                onChange={checkboxChange(dispatch, dispatchError, 'is_local_target')}
                themeMode={themeMode}
              />
              <Checkbox
                cn__sinccheck={'cn__sinccheck'}
                text="National"
                checked={!!state.get('is_national_target')}
                onChange={checkboxChange(dispatch, dispatchError, 'is_national_target')}
                themeMode={themeMode}
              />
              <Checkbox
                cn__sinccheck={'cn__sinccheck'}
                text="International"
                checked={!!state.get('is_international_target')}
                onChange={checkboxChange(dispatch, dispatchError, 'is_international_target')}
                themeMode={themeMode}
              />
            </div>
          </div>
          <div className={cn.border} />
          <p className={cn.modtit}>Phone number and physical address for this #domain.</p>
          <div className={cn.formRow}>
            <div className={cn.twocol}>
              <SelectInput
                label="Primary Phone"
                selinp="selinp"
                optionValue={state.get('phone_code') || ''}
                optiononChange={selectChange(dispatch, dispatchError, 'phone_code')}
                value={state.get('phone') || ''}
                onChange={inputChange(dispatch, dispatchError, 'phone')}
                type={'tel'}
                error={stateError.get('phone') || stateError.get('phone_code')}
                themeMode={themeMode}
              />
            </div>
            <div className={cn.twocol}>
              <FormInput
                label="Company Name"
                value={state.get('company_name')}
                onChange={inputChange(dispatch, dispatchError, 'company_name')}
                themeMode={themeMode}
              />
            </div>
          </div>
          <div className={cn.formRow}>
            <div className={cn.onecol}>
              <FormInput
                label="Address"
                value={state.get('address')}
                onChange={inputChange(dispatch, dispatchError, 'address')}
                themeMode={themeMode}
              />
            </div>
          </div>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <FormInput
                label="City"
                value={state.get('city')}
                onChange={inputChange(dispatch, dispatchError, 'city')}
                themeMode={themeMode}
              />
            </div>
            <div className={cn.threecol}>
              <FormSelect
                label="Country"
                option={countryList}
                value={state.get('country') || ''}
                onChange={selectChange(dispatch, dispatchError, 'country')}
                defoltDescription={'Selected country'}
                themeMode={themeMode}
              />
            </div>
            <div className={cn.threecol}>
              <FormInput
                label="Zip Code"
                value={state.get('zip')}
                onChange={inputChange(dispatch, dispatchError, 'zip')}
                type="number"
                themeMode={themeMode}
              />
            </div>
          </div>
          <div className={cn.border} />
          <p className={cn.modtit}>Do you have Social Media associated with this Domain?</p>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <div className={cn.social}>
                <FormInput
                  social="social"
                  label="Facebook"
                  value={state.get('soc_facebook')}
                  onChange={inputChange(dispatch, dispatchError, 'soc_facebook')}
                  error={stateError.get('soc_facebook')}
                  themeMode={themeMode}
                />
              </div>
            </div>
            <div className={cn.threecol}>
              <div className={cn.social}>
                <FormInput
                  social="social"
                  label="Twitter"
                  value={state.get('soc_twitter')}
                  onChange={inputChange(dispatch, dispatchError, 'soc_twitter')}
                  error={stateError.get('soc_twitter')}
                  themeMode={themeMode}
                />
              </div>
            </div>
            <div className={cn.threecol}>
              <div className={cn.social}>
                <FormInput
                  social="social"
                  label="Instagram"
                  value={state.get('soc_instagram')}
                  onChange={inputChange(dispatch, dispatchError, 'soc_instagram')}
                  error={stateError.get('soc_instagram')}
                  themeMode={themeMode}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={cn.formCol2}>
          <p className={cn.formTit}>Describe your website</p>
          <FormSelect
            label="Category"
            categoryselect={true}
            option={categoryes}
            defoltDescription={'Selected category'}
            onChange={selectChange(dispatch, dispatchError, 'category')}
            value={state.get('category')}
            error={stateError.get('category')}
            themeMode={themeMode}
          />
          <p className={cn.modtit}>5 keyword phrases description for your website</p>
          <FormKeywordsField error={stateError.get('keywords')} themeMode={themeMode}>
            <Key
              placeholder={'Type keywords suggestion'}
              maxTags={maxTags}
              tags={state.get('keywords')}
              onChange={keyChange(dispatch, dispatchError, 'keywords')}
              themeMode={themeMode}
            />
          </FormKeywordsField>
          <div className={cn.descriptionContainer}>
            <label>Description</label>
            <textarea
              className={cn.descriptionTextArea}
              value={state.get('description')}
              onChange={textareaChange(dispatch, dispatchError, 'description')}
              spellCheck="false"
              style={{
                background: themeMode == 'main' ? '#00000090' : '#ffffff90',
                color: themeMode == 'light' ? 'black' : 'white',
                borderColor: themeMode == 'light' ? 'gray' : 'white',
              }}
            />
          </div>
          <div className={cn.imageContainer}>
            <label>Upload Image (Max file size 1MB)</label>
            <input accept=".jpeg, .jpg, .png, .svg" type="file" onChange={onChangeFile(dispatch, dispatchError)} />
            <img
              src={
                state.get('imageSrc')
                  ? state.get('imageSrc')
                  : state.get('img').split('/uploads/').pop()
                  ? state.get('img')
                  : undefined
              }
              alt=""
              title=" "
            />
            {state.get('imageSrc') === '' && state.get('img').split('/uploads/')[1] === '' ? (
              <div
                className={cn.defaultImg}
                style={{
                  background:
                    themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                  color: themeMode == 'main' ? 'white' : 'black',
                }}
              >
                Click Here to Upload Image
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <button
          disabled={isLoadFEdit}
          className={cn.formBtn}
          style={{
            background: themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        >
          {isLoadFEdit ? 'SAVED' : 'SAVE'}
        </button>
      </form>
      {extraKey > 0 && (
        <UnifiedModal1
          showModal={showModalKeyWords}
          onClose={changeModalKeyWords(onChangeModalKeyWords, false)}
          title={`A fee of keyword per year will be charged. You are adding ${extraKey} extra keywords beyond your 5 free keywords, your total is: $${feePrice.toFixed(
            2
          )}`}
          onSave={changeModalKeyWords(onChangeModalKeyWords, true)}
        />
      )}
    </>
  )
}

function mapStateToProps({
  user,
  actions,
  modals,
}: {
  user: Record<User>
  actions: Record<Actions>
  modals: Record<Modals>
}) {
  return {
    hashtagItem: actions.get('hashtagItem'),
    categoryList: actions.get('categoryList'),
    priseCountKeyWords: actions.get('priseCountKeyWords'),
    showModalKeyWords: modals.get('showModalKeyWords'),
    isLoadFEdit: actions.get('isLoadFEdit'),
    stopSale: actions.get('stopSale'),
  }
}
export default connect(mapStateToProps, {
  onDomainEdit,
  onHashtagGetItem,
  onChangeModalKeyWords,
  onRerenderEditForm,
})(DomainEditForm)
