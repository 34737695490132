import request from '../../utils/api/request'

export type TPAddEmails = {
  /* 	Массив валидных email адресов */
  list: string[]
}

export const addEmails = request<boolean, TPAddEmails>({
  method: 'mailing.add',
})

export type TRGetList = {
  email: string
  lastSend: number | null
}[]
export const getList = request<TRGetList>({
  method: 'mailing.getList',
})
export type TRemove = {
  email: string
}

export const remove = request<boolean, TRemove>({
  method: 'mailing.remove',
})

export const sendEmails = request<boolean>({
  method: 'mailing.send',
})

export type TPSendTo = {
  email: string
}
export const sendTo = request<boolean, TPSendTo>({
  method: 'mailing.sendTo',
})
