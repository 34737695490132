import { ButtonSecondary } from 'components/Buttons/Button'
import React from 'react'
import styled from 'styled-components'
import { MonthNameList } from '../../constants'
import { useDatePickerContext } from '../../contexts/DatePickerContext'
import { FlexRow } from '../../styled'

const MonthPickerContainer = styled(FlexRow)`
  flex-wrap: wrap;
  gap: 12px;
`

export const MonthDatePicker = () => {
  const { currentDate, setCurrentDate } = useDatePickerContext()

  return (
    <MonthPickerContainer>
      {MonthNameList.map((month, index) => {
        return (
          <ButtonSecondary
            key={index}
            width={'30%'}
            activedStyle={currentDate.month === index}
            onClick={() => setCurrentDate({ ...currentDate, month: index })}
          >
            {month}
          </ButtonSecondary>
        )
      })}
    </MonthPickerContainer>
  )
}
