import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redusers/config'
import { Init } from '../../redusers/user'
import Logo from '../Logo'
import './styles.scss'
import cn from 'classnames'
import { useGetThemeMode } from 'state/theme/hook'
import { CAPSLOCK_URL, GAMEBLING_URL, SEOMONEY_URL, SOCIAL_URL } from 'constants/urls'
import HeaderTop from './HeaderTop'
import CoinChart from 'components/CoinChart'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useAccount, useNetwork } from 'wagmi'
import { onLogOut } from 'actions/user'

const Header: React.FC<any> = ({ children }) => {
  const { address: walletAddress, isConnected } = useAccount()
  const dispatch = useDispatch()

  const isLogIn = useSelector<AppState, Init['isLogin']>(({ user }) => user.get('isLogin'))
  const isSocial = useSelector<AppState, Init['isSocial']>(({ user }) => user.get('isSocial'))
  const isSeomoney = useSelector<AppState, Init['isSeomoney']>(({ user }) => user.get('isSeomoney'))
  const isGamebling = useSelector<AppState, Init['isGamebling']>(({ user }) => user.get('isGamebling'))
  const [showGuide, setShowGuide] = useState<boolean>(false)

  const login = useSelector<AppState, string>(({ user }) => {
    const first_name = user.get('userInfo').first_name
    const last_name = user.get('userInfo').last_name
    if (first_name && last_name) {
      return `${first_name} ${last_name}`
    }
    return user.get('userInfo').email
  })

  useEffect(() => {
    let postMsg: any = { accessToken: '' }
    let accessToken: any = { token: '', ttl: 0 }
    let refreshToken: any = { token: '', ttl: 0 }
    if (isLogIn) {
      accessToken = JSON.parse(window.localStorage.getItem('access_token') ?? '')
      refreshToken = JSON.parse(window.localStorage.getItem('refresh_token') ?? '')

      postMsg = { accessToken: accessToken.token }

      if (isSocial) window.location.href = SOCIAL_URL
      if (isSeomoney) window.location.href = SEOMONEY_URL
      if (isGamebling) window.location.href = GAMEBLING_URL

      if (walletAddress === undefined || !isConnected) dispatch(onLogOut())
    }

    postCrossDomainMessage(postMsg)
  }, [isLogIn, isSocial, isSeomoney, walletAddress, isConnected, dispatch, isGamebling])

  const postCrossDomainMessage = (msg: any) => {
    const iFrameSpacebar = document.getElementById('ifr_spacebar') as HTMLIFrameElement
    const win_spacebar = iFrameSpacebar.contentWindow
    if (win_spacebar != null) {
      win_spacebar.postMessage(msg, SOCIAL_URL)
    }

    const iFrameSEOMoney = document.getElementById('ifr_seomoney') as HTMLIFrameElement
    const win_seomoney = iFrameSEOMoney.contentWindow
    if (win_seomoney != null) {
      win_seomoney.postMessage(msg, SEOMONEY_URL)
    }

    const iFrameCapslock = document.getElementById('ifr_capslock') as HTMLIFrameElement
    const win_capslock = iFrameCapslock.contentWindow
    if (win_capslock != null) {
      win_capslock.postMessage(msg, CAPSLOCK_URL)
    }

    const iFrameGamebling = document.getElementById('ifr_gamebling') as HTMLIFrameElement
    const win_gamebling = iFrameGamebling.contentWindow
    if (win_gamebling != null) {
      win_gamebling.postMessage(msg, GAMEBLING_URL)
    }
  }

  const openModal = () => {
    setShowGuide(true)
  }
  const closeModal = () => {
    setShowGuide(false)
  }

  const themeMode = useGetThemeMode()

  // const chainIds = {
  //   ganache: 1337,
  //   goerli: 5,
  //   hardhat: 31337,
  //   kovan: 42,
  //   mainnet: 1,
  //   rinkeby: 4,
  //   ropsten: 3,
  //   bscTest: 97, // BSC testnet
  //   bscMain: 56, // BSC mainnet
  //   mumbai: 80001, // Polygon testnet
  //   matic: 137, // Polygon mainnet
  //   fuji: 43113, // Avalance testnet
  //   avax: 43114, // Avalance mainnet
  // }

  return (
    <header className="header">
      {isLogIn && <HeaderTop />}
      <div className="headerContainer">
        <Logo />
        <div className="twoContent">
          {isLogIn ? <CoinChart header={true} /> : <div />}
          <div
            className={isLogIn ? cn('headerContent') : cn('headerContentUnlogin')}
            style={{
              backgroundColor:
                themeMode == 'main' && isLogIn
                  ? '#00000090'
                  : themeMode == 'light' && isLogIn
                  ? '#ffffff90'
                  : isLogIn
                  ? '#00000090'
                  : 'transparent',
              color: themeMode == 'main' ? 'white' : 'black',
            }}
          >
            {children}
            <div className={isLogIn ? cn('wallet-btn') : cn('wallet-btn1')}>
              <ConnectButton chainStatus={'none'} accountStatus={'address'} showBalance={false} />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
