import { createSlice } from '@reduxjs/toolkit'

export type TThemeMode = 'light' | 'main'

interface IState {
  themeMode: TThemeMode
}

export const initialState: IState = {
  themeMode: 'main',
}

const themeModeSlice = createSlice({
  name: 'themeMode',
  initialState,
  reducers: {
    setThemeMode(state, action) {
      state.themeMode = action.payload
      localStorage.setItem('themeMode', action.payload)
    },
  },
})

export const { setThemeMode } = themeModeSlice.actions
export default themeModeSlice.reducer
