import React, { FC, useCallback } from 'react'
import ReactTable, { Alignment, createTheme } from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useGetThemeMode } from 'state/theme/hook'
import { AppState } from '../../../redusers/config'
import { SettingItems, TAccountItem } from '../../../utils/api'
import { fetchReferralStatus } from './actions'
import cn from './cn.module.css'
import { useAccounts } from './hooks'
import styled from 'styled-components'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'

const AdminAccountContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `calc(100% - ${width})`};
  z-index: 0;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
    fontFamily: '"Montserrat", sans-serif',
  },
  background: {
    default: 'rgba(0,0,0,0.5)',
  },
  context: {
    background: '#FFFFFF',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: '#FFFFFF',
    hover: '#FFFFFF',
    disabled: '#FFFFFF',
  },
  highlightOnHover: {
    default: '#FFFFFF',
    text: '#FFFFFF',
  },
  sortFocus: {
    default: '#FFFFFF',
  },
})
createTheme('solarized_light', {
  text: {
    primary: '#000',
    secondary: '#000',
    fontFamily: '"Montserrat", sans-serif',
  },
  background: {
    default: 'rgba(255,255,255,0.5)',
  },
  context: {
    background: '#000',
    text: '#000',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: '#000',
    hover: '#000',
    disabled: '#000',
  },
  highlightOnHover: {
    default: '#000',
    text: '#000',
  },
  sortFocus: {
    default: '#000',
  },
})
const CelReferralCustom: FC<{ row: TAccountItem }> = ({ row }) => {
  const dispatch = useDispatch()
  const onChecked = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      dispatch(fetchReferralStatus({ id: row.id, status: e.currentTarget.checked }))
    },
    [dispatch]
  )
  return (
    <input
      type="checkbox"
      placeholder={'Only balance'}
      defaultChecked={row.referral_status_personal}
      onChange={onChecked}
      // checked={row.referral_status_personal}
    />
  )
}

const CelReferralStatus: FC<{ row: TAccountItem }> = ({ row }) => {
  const settings = useSelector<AppState, SettingItems>(({ actions }) => actions.get('settings'))

  if (settings.referral !== 'off' && (row.referral_status || row.referral_status_personal)) {
    return <span>on</span>
  } else {
    return <span>off</span>
  }
}

const columns = [
  {
    name: 'Email',
    selector: (row: TAccountItem) => 'email',
    // eslint-disable-next-line react/display-name
    cell: (row: TAccountItem) => {
      return (
        <Link className={cn.link} to={`/admin/accounts/${row.id}`}>
          {row.email}
        </Link>
      )
    },
    sortable: true,
  },
  {
    name: 'Balance',
    selector: (row: TAccountItem) => 'balance',
    sortable: true,
    center: true,
  },
  {
    name: 'Domains',
    selector: (row: TAccountItem) => 'domains',
    sortable: true,
    center: true,
  },
  {
    name: 'Purchased domains',
    selector: (row: TAccountItem) => 'domains_stat.live',
    sortable: true,
    center: true,
  },
  {
    name: 'Demo domains',
    selector: (row: TAccountItem) => 'domains_stat.demo',
    sortable: true,
    center: true,
  },
  {
    name: 'Referral status',
    selector: (row: TAccountItem) => 'referral_status',
    sortable: true,
    center: true,
    width: '100px',
    // eslint-disable-next-line react/display-name
    cell: (row: TAccountItem) => <CelReferralStatus row={row} />,
  },
  {
    name: 'Referral custom',
    selector: (row: TAccountItem) => 'referral_status_personal',
    sortable: true,
    center: true,
    width: '100px',
    // eslint-disable-next-line react/display-name
    cell: (row: TAccountItem) => <CelReferralCustom row={row} />,
  },
]

const Accounts: FC = () => {
  const {
    filterData,
    memoizedOnSearch,
    search,
    onlyBalance,
    memoizedOnChekedB,
    onlyDomains,
    memoizedOnChekedD,
    onlyPurchased,
    memoizedOnChekedPD,
    onlyDemo,
    memoizedOnChekedDD,
  } = useAccounts()

  const themeMode = useGetThemeMode()
  const isCollapseMenu = useIsCollapseMenu()

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
        fontSize: '14px',
      },
    },
    subHeader: {
      style: {
        padding: 0,
        minHeight: '43px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '15px',
        fontWeight: 600,
        borderTop: '2px solid #025498',
        // background:themeMode === 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)'
        background: themeMode === 'main' ? 'black' : '#b7eaf6',
        color: themeMode === 'main' ? 'white' : 'black',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  }
  return (
    <AdminAccountContainer width={isCollapseMenu ? '65px' : '235px'}>
      <ReactTable
        keyField={'id'}
        theme={themeMode === 'main' ? 'solarized' : 'solarized_light'}
        noHeader={true}
        data={filterData}
        columns={columns}
        customStyles={customStyles}
        fixedHeader={true}
        fixedHeaderScrollHeight="100vh"
        subHeaderComponent={
          <>
            <input
              type={'text'}
              placeholder={'Search Email'}
              className={cn.search}
              onChange={memoizedOnSearch}
              value={search}
              style={{
                background: themeMode === 'main' ? '#00000090' : '#ffffff90',
                color: themeMode === 'main' ? '#fff' : '#000',
              }}
            />

            <div className={cn.headerConteiner} style={{ color: themeMode === 'light' ? 'black' : 'white' }}>
              <label>
                {' '}
                Only balance
                <input
                  type="checkbox"
                  placeholder={'Only balance'}
                  checked={onlyBalance}
                  onChange={memoizedOnChekedB}
                  style={{ marginLeft: '5px' }}
                />
              </label>

              <label>
                {' '}
                Only domains
                <input
                  type="checkbox"
                  placeholder={'Only domains'}
                  checked={onlyDomains}
                  onChange={memoizedOnChekedD}
                  style={{ marginLeft: '5px' }}
                />
              </label>
              <label>
                {' '}
                Only purchased domains
                <input
                  type="checkbox"
                  placeholder={'Only purchased domains'}
                  checked={onlyPurchased}
                  onChange={memoizedOnChekedPD}
                  style={{ marginLeft: '5px' }}
                />
              </label>
              <label>
                {' '}
                Only demo domains
                <input
                  type="checkbox"
                  placeholder={'Only demo domains'}
                  checked={onlyDemo}
                  onChange={memoizedOnChekedDD}
                  style={{ marginLeft: '5px' }}
                />
              </label>
            </div>
          </>
        }
        subHeaderAlign={Alignment.LEFT}
        subHeader={true}
      />
    </AdminAccountContainer>
  )
}

export default Accounts
