import HeaderLoginLinks from 'components/Header/HeaderLogin/HeaderLoginLinks'
import { useCommonCheck } from 'hooks/useCommon'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import { useGetThemeMode } from 'state/theme/hook'
import LoginFormInp from 'views/Login/LoginInp'
import { clearErrorRegistration, onRegistration } from '../../../actions/user'
import { FormButton } from '../styles'
import 'react-phone-number-input/style.css'
import './styles.scss'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useAccount } from 'wagmi'
import Logo from 'components/Logo'
import metamaskIcon from '../../../assets/icons/metamask.png'
import exodusIcon from '../../../assets/icons/exodus.svg'
import HArrowImg from '../../../assets/icons/h-arrow.png'
import { EXODUS_URL, METAMASK_URL } from 'constants/urls'
import CartHashtag from 'views/CartHashtag'
import { bookHashtag } from 'actions/hashtag'

const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const SignUpBuy: React.FC<any> = () => {
  const { showToastMsg } = useCommonCheck()
  const errorRegistration = useSelector<AppState, Init['errorRegistration']>(({ user }) =>
    user.get('errorRegistration')
  )
  const isActiveUser = useSelector<AppState, Init['isActiveUser']>(({ user }) => user.get('isActiveUser'))
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [cPassword, setCPassword] = useState<string>('')
  const [errors, setErrors] = useState<Array<string>>([])
  const [phone, setPhone] = useState<any>(0)

  const [buyDomain, setBuyDomain] = useState<string>('')

  const dispatch = useDispatch()
  const history = useHistory()
  const { address: wallet, isConnected, isDisconnected } = useAccount()

  useEffect(() => {
    const curUrl = window.location.href
    if (curUrl.includes('/buy#')) {
      const domain = curUrl.split('/buy')[1]
      setBuyDomain(domain)
      dispatch(bookHashtag(domain))
    }
    dispatch(clearErrorRegistration())
    return () => {
      dispatch(clearErrorRegistration())
    }
  }, [dispatch])

  useEffect(() => {
    if (!isActiveUser && errorRegistration == 'no_error') {
      showToastMsg(
        'Congratulation on your registration! You should activate your account via email to login.',
        'success'
      )
      history.push('/login/')
    }
  }, [errorRegistration, isActiveUser])

  const signUp = () => {
    if (!phone || phone == 0 || phone.length < 12 || phone.length > 17) {
      showToastMsg('Invalid phone number', 'error')
      return
    }

    if (!isConnected || wallet == undefined) {
      showToastMsg('Invalid wallet connection', 'error')
      return
    }

    if (!validateEmail(email)) {
      showToastMsg('Invalid email address', 'error')
      return
    }

    if (!password) {
      showToastMsg('Invalid password', 'error')
      return
    }

    if (password.length < 6) {
      showToastMsg('Password has more than 6 characters', 'error')
      return
    }

    if (password !== cPassword) {
      showToastMsg('Invalid confirm password', 'error')
      return
    }

    dispatch(onRegistration({ phone, wallet, email, password }))
  }

  const showErrorTxt = () => {
    return showToastMsg(errorRegistration, 'error')
  }

  const handleGetWallet = (wallet: string) => {
    if (wallet === 'exodus') window.open(EXODUS_URL, '_blank')
    else if (wallet === 'metamask') window.open(METAMASK_URL, '_blank')
  }
  const themeMode = useGetThemeMode()

  return (
    <div className="container">
      <div className="container-left">
        <Logo />
        <div className="description">
          <span
            className="desc1"
            dangerouslySetInnerHTML={{
              __html: 'Decentralized <br/>#hashtags for web 3, <br/>websites, wallets, & more.',
            }}
          ></span>
          <span
            className="desc2"
            dangerouslySetInnerHTML={{
              __html:
                'Do not miss out on #web3 the #metaverse, #crypto and more, reserve your #name, <br/>#digitalid or #brandname today.',
            }}
          ></span>

          <div className="arrow-img">
            <img src={HArrowImg} alt="" className="arr-img" />
          </div>
        </div>
      </div>

      <div className="container-right">
        <div className="formContainer">
          <button className="meta-get">
            <span>Don’t have wallet yet? Get Here</span>
            <div className="wrp">
              <div className="wallet-wrapper" onClick={() => handleGetWallet('exodus')}>
                <img src={exodusIcon} width={15} style={{ marginRight: '3px' }} /> EXODUS
              </div>
              <div className="wallet-wrapper" onClick={() => handleGetWallet('metamask')}>
                <img src={metamaskIcon} width={15} style={{ marginRight: '3px' }} /> METAMASK
              </div>
            </div>
          </button>

          <div className="form-text1">
            You are about to purchase <span className="domain">{buyDomain}</span>
          </div>

          <div className="form-text2">*The personal info you provide below will only be seen by you.</div>

          <PhoneInput
            placeholder="Enter phone number"
            international
            defaultCountry="US"
            value={phone}
            onChange={setPhone}
          />
          <LoginFormInp
            label={'Email address'}
            type={'email'}
            name={'email'}
            value={email}
            onChange={setEmail}
            isError={errors.includes('email')}
          />
          <LoginFormInp
            label={'Password'}
            type={'password'}
            value={password}
            onChange={setPassword}
            isError={errors.includes('password')}
          />
          <LoginFormInp
            label={'Confirm password'}
            type={'password'}
            value={cPassword}
            onChange={setCPassword}
            isError={errors.includes('cPassword')}
          />
          <div className="form-text3">No Renewal Fees. No Minting Fees. No Gas Fees</div>
          <FormButton
            type="button"
            onClick={() => signUp()}
            style={{
              background: 'linear-gradient(#873f99, #311438)',
              color: 'white',
            }}
          >
            Sign up
          </FormButton>
          <div className="sigin">
            <HeaderLoginLinks text={'Have an account?'} to={'/login'} label={'Sign in'} />
          </div>

          {errorRegistration && errorRegistration != 'no_error' && showErrorTxt()}
        </div>
        <div className="leftContainer">
          <ConnectButton chainStatus={'none'} accountStatus={'address'} showBalance={false} />
          <div className="shop-cart">
            {/* <MyCart /> */}
            <CartHashtag />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpBuy
