import { showToast } from 'sagas/domain'
import { errorsM } from '../restReq'

interface ErrorProps {
  code: number
  data: {
    mnemonic: string
    description: string
    fields: string[]
    location: string[]
  }
  message: string
}

export default (error: ErrorProps | undefined, method: string, showError = true): void => {
  const erMessage = error?.code ? errorsM.get(error?.code) : undefined

  if (error?.code === undefined) {
    showToast('Unknown Error', 'error')
    return
  }
  if (!showError) return
  if (error?.code === 610 && error.data.description) {
    showToast(error.data.description, 'error')
    return
  }

  if (typeof erMessage === 'string') {
    showToast(erMessage, 'error')
    return
  }
  showToast('Something went wrong', 'error')
}
