import React from 'react'
import { useGetThemeMode } from 'state/theme/hook'
import { useGetMonthlyRevenue } from '../../hooks/useMonthlyRevenue'
import {
  BigValueText,
  FlexRow,
  FlexRowContent,
  HorizontalDivider,
  SubContainer,
  SubContainerBody,
  SubTitleText,
  TitleText,
} from '../../styled'

export const MonthlyRevenue: React.FC = () => {
  const { purchaseAmount, commissionAmount } = useGetMonthlyRevenue()
  const themeMode = useGetThemeMode()

  return (
    <SubContainer width={'38%'} height={'260px'} themeMode={themeMode}>
      <FlexRowContent>
        <TitleText>Monthly Revenue</TitleText>
      </FlexRowContent>
      <SubContainerBody>
        <FlexRowContent margin={'12px 0'}>
          <SubTitleText>New &#38; Recurring Purchases</SubTitleText>
          <BigValueText color={themeMode === 'light' ? 'black' : '#ffffff'} fontSize={'18px'}>
            {`$ ${purchaseAmount.toFixed(2)}`}
          </BigValueText>
        </FlexRowContent>
        <FlexRowContent margin={'12px 0'}>
          <SubTitleText>Commissions</SubTitleText>
          <BigValueText color={themeMode === 'light' ? 'black' : '#ffffff'} fontSize={'18px'}>
            {`$ ${commissionAmount.toFixed(2)}`}
          </BigValueText>
        </FlexRowContent>
        <HorizontalDivider margin={'20px 0'} />
        <FlexRowContent margin={'12px 0'}>
          <TitleText>Total Revenue</TitleText>
          <BigValueText color={'#2ec814'} fontSize={'26px'}>
            {`$ ${(purchaseAmount - commissionAmount).toFixed(2)}`}
          </BigValueText>
        </FlexRowContent>
      </SubContainerBody>
    </SubContainer>
  )
}
