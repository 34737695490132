import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'
import {
  fetchGetTemplate,
  fetchGetTemplateList,
  fetchGetTmpl,
  fetchPreviewTemplate,
  fetchSetStatusTemplate,
  fetchSetTemplate,
  fetchSetTmpl,
} from '../actions'
import {
  workerGetTemplate,
  workerGetTmpl,
  workerPreviewTemplate,
  workerSetStatusTemplate,
  workerSetTemplate,
  workerSetTmpl,
  workerTemplateList,
} from './workers'

export default function* watchSendEmailsAdmin(): SagaIterator {
  yield takeEvery<typeof fetchGetTmpl>(fetchGetTmpl, workerGetTmpl)
  yield takeEvery<typeof fetchSetTmpl>(fetchSetTmpl, workerSetTmpl)
  yield takeEvery<typeof fetchGetTemplateList>(fetchGetTemplateList, workerTemplateList)
  yield takeEvery<typeof fetchGetTemplate>(fetchGetTemplate, workerGetTemplate)
  yield takeEvery<typeof fetchSetTemplate>(fetchSetTemplate, workerSetTemplate)
  yield takeEvery<typeof fetchPreviewTemplate>(fetchPreviewTemplate, workerPreviewTemplate)
  yield takeEvery<typeof fetchSetStatusTemplate>(fetchSetStatusTemplate, workerSetStatusTemplate)
}
