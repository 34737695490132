import { shallowEqual, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init } from '../redusers'

export const useGetDomainAnalytics = () => {
  const domainAnalytics = useSelector<AppState, Init['domainAnalytics']>(
    ({ dashboard }) => dashboard.get('domainAnalytics'),
    shallowEqual
  )

  return domainAnalytics
}
