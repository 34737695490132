import { call, fork, put, takeLatest } from '@redux-saga/core/effects'
import { push } from 'connected-react-router'
import { showToast } from 'sagas/domain'
import { checkErorr, getToken } from '../../../sagas/helper'
import { getSettings, setSettings, SettingItems } from '../../../utils/api'
import { TSetMetaTagsAction } from './types'

export function* watcherSaga() {
  yield takeLatest('SET_META_TAGS', workerSetMetaTags)
  yield takeLatest('GET_META_TAGS', workerGetMetaTags)
}

function* workerSetMetaTags({ payload }: TSetMetaTagsAction) {
  try {
    yield put({
      type: 'metaTags_isLoad',
      payload: true,
    })

    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    const settings: boolean = yield call(setSettings, { fields: { meta: payload } }, token)

    yield fork(workerGetMetaTags)
    showToast('Data saved', 'success')
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
    yield put({
      type: 'metaTags_isLoad',
      payload: false,
    })
  }
}

function* workerGetMetaTags() {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    const settings: SettingItems = yield call(getSettings, token)

    yield put({
      type: 'metaTags_code',
      payload: settings.meta,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
    yield put({
      type: 'metaTags_isLoad',
      payload: false,
    })
  }
}

export default watcherSaga
