import { Record } from 'immutable'
import moment from 'moment'
import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'
import styled from 'styled-components'
import { getListTransaction, sendPayData } from '../../actions/transaction'
import DepositForm from '../../components/DepositForm'
import PayTable, { ColumnDefs } from '../../components/PayTable'
import SendForm from '../../components/SendForm'
import WithdrawForm from '../../components/WithdrawForm'
import { Init as Transaction, ListTransaction } from '../../redusers/transaction'
import { Init } from '../../redusers/user'
import { ItemTransaction } from '../../utils/api'
import cn from './cn.module.css'

const PayContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  width: ${({ width }) => `calc(100% - ${width})`};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const PayMethodContent = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: auto auto auto;
  grid-gap: 15px;
  margin-bottom: 10px;
  @media screen and (max-width: 1280px) and (min-width: 640px) {
    grid-template-columns: auto auto;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: auto;
  }
`

const columnDefs: ColumnDefs = [
  { headerName: '', field: 'hash', isKey: true, hide: true },
  {
    headerName: 'Date',
    field: 'date_timestamp',
    display: (row: any) => moment(row.dateTimestamp * 1000).format('MMM DD, YYYY  HH:mm'),
  },
  {
    headerName: 'Amount',
    field: 'amount',
    display: (row: any) => row.amount.toFixed(2) + ' ' + row.currency,
  },
  {
    headerName: 'Origin amount',
    field: 'originAmount',
    display: (row: any) =>
      (row.product === 'MHC' ||
      row.product === 'ETH' ||
      row.product === 'BNB' ||
      row.originCurrency === 'ETH' ||
      row.originCurrency === 'BNB'
        ? row.originAmount.toFixed(6)
        : row.originAmount.toFixed(2)) +
      ' ' +
      row.originCurrency,
  },
  {
    headerName: 'Description',
    field: 'description',
    display: (row: any) => {
      if ((row.product === '#Credit' || row.product === 'Direct') && row.description.slice(1) !== '#') {
        return `#${row.description}`
      } else if (row.description.indexOf('renewed_domain') !== -1) {
        return row.description.replace('renewed_domain', '')
      }
      return row.description
    },
  },
  {
    headerName: 'Type',
    field: 'type',
    display: (row: any) => {
      if (row.product === '#Credit') {
        return row.type === 'deposit' ? 'Received' : 'Sent'
      } else if (row.product === 'Direct') {
        if (row.paySystem === 'ETH') {
          return row.type === 'deposit' ? 'E-Received' : 'E-Sent'
        } else if (row.paySystem === 'BNB') {
          return row.type === 'deposit' ? 'B-Received' : 'B-Sent'
        } else {
          return row.type === 'deposit' ? 'D-Received' : 'D-Sent'
        }
      } else if (row.product === 'GIFT_DOMAIN') {
        return row.type === 'deposit' ? 'G-Received' : 'G-Sent'
      } else if (row.description.indexOf('renewed_domain') !== -1) {
        return 'renewed'
      }
      return row.type
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    display: (row: any) => {
      return row.status
    },
  },
]

interface PayPageP {
  getListTransaction: Function
  sendPayData: Function
  listTransaction: ListTransaction
}

const PayPage: React.FC<PayPageP> = ({ getListTransaction, listTransaction, sendPayData }) => {
  const isLogIn = useSelector<AppState, Init['isLogin']>(({ user }) => user.get('isLogin'))
  const isCollapseMenu = useIsCollapseMenu()

  useEffect(() => {
    if (isLogIn) getListTransaction()

    const interval = setInterval(() => {
      if (isLogIn) getListTransaction()
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [getListTransaction, isLogIn])

  return (
    <PayContainer width={isCollapseMenu ? '65px' : '235px'}>
      <PayMethodContent>
        <DepositForm />
        <SendForm />
        <WithdrawForm />
      </PayMethodContent>
      {/* <PayTable<Omit<ItemTransaction, 'user_email'>>
        classname={cn.paytable}
        columnDefs={columnDefs}
        columnRow={listTransaction}
      /> */}
      <PayTable classname={cn.paytable} columnDefs={columnDefs} columnRow={listTransaction} />
    </PayContainer>
  )
}

function mapStateToProps({ transaction }: { transaction: Record<Transaction> }) {
  return {
    listTransaction: transaction.get('listTransaction'),
  }
}

export default connect(mapStateToProps, { getListTransaction, sendPayData })(PayPage)
