import React, { ReactNode, useState } from 'react'
import './styles.scss'
import { IoIosClose } from 'react-icons/io'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { useGetThemeMode } from 'state/theme/hook'

const modalRoot: Element = document.getElementById('root-modal') as Element

interface ModalP {
  onClose?: (e: React.FormEvent<HTMLElement>) => void
  title: string
  onSave?: Function
  noOk?: boolean
  btnCloseName?: string
  btnSaveName?: string
  children?: ReactNode
}

const onSubmit = (onSave: ModalP['onSave']) => (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault()
  if (typeof onSave === 'function') {
    onSave()
  }
}

const Modal: React.FC<ModalP> = ({
  onClose,
  title,
  onSave,
  noOk,
  btnCloseName = 'Cancel',
  btnSaveName = 'Ok',
  children,
}) => {
  const themeMode = useGetThemeMode()

  return (
    <div className="modal">
      <div className="modal-content">
        <form onSubmit={onSubmit(onSave)} style={{ display: 'block' }}>
          <div className="modalTitle">
            <p>{title}</p>
            <div className="modal-close" onClick={onClose}>
              <IoIosClose size={30} />
            </div>
          </div>
          {children}
          <div className="modal-content__btns">
            <div
              className="btn"
              onClick={onClose}
              style={{
                background:
                  themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                color: themeMode === 'main' ? 'white' : 'black',
              }}
            >
              {btnCloseName}
            </div>
            {!noOk && (
              <button className="btn" type="submit">
                {btnSaveName}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

const timeout = { enter: 500, exit: 100 }

interface UnifiedModalP {
  showModal: boolean
  onClose?: (e: React.FormEvent<HTMLElement>) => void
  title: string
  onSave?: (val: string) => void
  children?: ReactNode
  noOk?: boolean
  btnCloseName?: string
  btnSaveName?: string
}
const UnifiedModal1: React.FC<UnifiedModalP> = ({
  showModal,
  onClose,
  title,
  onSave,
  children,
  noOk,
  btnCloseName,
  btnSaveName,
}) => {
  return createPortal(
    <CSSTransition classNames="animated" in={showModal} unmountOnExit timeout={timeout}>
      <Modal
        onClose={onClose}
        title={title}
        onSave={onSave}
        noOk={noOk}
        btnCloseName={btnCloseName}
        btnSaveName={btnSaveName}
      >
        {children}
      </Modal>
    </CSSTransition>,
    modalRoot
  )
}

export default UnifiedModal1
