import MenuIcons from 'components/Icons/MenuIcons'
import React, { useEffect, useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { BsFillAwardFill } from 'react-icons/bs'
import { FiExternalLink, FiUserPlus } from 'react-icons/fi'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { AppState } from 'redusers/config'
import { useGetThemeMode } from 'state/theme/hook'
import type { SendEmail as TSendEmail } from '../../views/SendEmail/reduser'
import styled from 'styled-components'
import {
  fetchGetComplexReport,
  fetchGetQuantityReport,
  fetchGetTotalAmountReport,
  fetchReferralCode,
} from 'views/Affiliates/actions'
import { AffiliatesState } from 'views/Affiliates/reduser'
import './styles.scss'
import { fetchGetList } from 'views/SendEmail/actions'
import { MdContentCopy, MdFileCopy } from 'react-icons/md'
import { useMenuAudio } from 'components/DomainsDashboardTable/hooks'
import { HASHTAG_URL } from 'constants/urls'

const Container = styled.div<{}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 176px;
  border-radius: 5px;
  padding: 5px 10px 5px 30px;
  border: 1px solid;
  font-size: 14px;
  @media screen and (max-width: 1660px) {
    font-size: 14px;
    width: 50%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 420px) {
    padding: 5px 10px;
  }
`
const IconWrapper = styled.div<{
  width: string
  height: string
  borderColor: string
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1280px) {
    width: 30px;
    height: 30px;
  }
`
const CommandReturn = () => {
  const dispatch = useDispatch()
  const [ref, setRef] = useState<string>('')
  const [invitCount, setInvitCount] = useState<number>(0)
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [isNotExistRef, setIsNotExistRef] = useState<boolean>(false)

  const referralCode = useSelector<AppState, AffiliatesState['referralCode']>(
    ({ affiliatesReducer }) => affiliatesReducer.referralCode,
    shallowEqual
  )
  const quantityReport = useSelector<AppState, AffiliatesState['quantityReport']>(
    ({ affiliatesReducer }) => affiliatesReducer.quantityReport,
    shallowEqual
  )
  const totalAmountReport = useSelector<AppState, AffiliatesState['totalAmountReport']>(
    ({ affiliatesReducer }) => affiliatesReducer.totalAmountReport,
    shallowEqual
  )
  const emailList = useSelector<AppState, TSendEmail['emailList']>(
    ({ sendEmailReducer }) => sendEmailReducer.emailList,
    shallowEqual
  )

  // const userInfo = useSelector<AppState, Init['userInfo']>(({ user }) => user.get('userInfo'), shallowEqual)
  useEffect(() => {
    // if (userInfo.referral_status) return
    dispatch(fetchReferralCode())
    dispatch(fetchGetQuantityReport())
    dispatch(fetchGetComplexReport({ filter: {} }))
    dispatch(fetchGetTotalAmountReport())
  }, [dispatch])

  useEffect(() => {
    if (referralCode === '') return
    setRef(`${HASHTAG_URL}?ref=${referralCode}`)
  }, [referralCode])

  useEffect(() => {
    dispatch(fetchGetList())
    if (emailList) {
      const len = emailList.get('data')?.length as number
      setInvitCount(len)
    }
  }, [dispatch])

  const copyReferralLink = () => {
    setIsCopied(false)
    setIsNotExistRef(false)
    if (ref) {
      navigator.clipboard.writeText(ref)
      setIsCopied(true)
    } else {
      setIsNotExistRef(true)
    }
    const timeId = setTimeout(() => {
      setIsCopied(false)
      setIsNotExistRef(false)
    }, 1500)
    return () => {
      clearTimeout(timeId)
    }
  }
  const themeMode = useGetThemeMode()
  const { playAudio } = useMenuAudio()

  return (
    <Container
      style={{
        background: themeMode == 'main' ? '#00000090' : '#ffffff90',
        borderColor: themeMode == 'main' ? '#02569d' : 'gray',
        color: themeMode == 'main' ? 'white' : 'black',
      }}
    >
      <div className="return-left">
        <div className="return-header">
          <MenuIcons url="affiliate" themeMode={themeMode} size={'18px'} />
          <h3 style={{ marginLeft: '-20px' }}>
            <NavLink
              to="/Affiliates/"
              onClick={() => playAudio('return')}
              style={{ color: themeMode === 'main' ? 'white' : 'black' }}
            >
              RETURN
            </NavLink>
          </h3>
        </div>
        <div className="return-desc">
          <div>
            Earn up to 25% lifetime on every <br />
            referral. Anything they buy.you earn.
          </div>
          <button
            className="button-copy-link"
            onClick={() => copyReferralLink()}
            style={{
              background:
                themeMode === 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
              color: themeMode == 'main' ? 'white' : 'black',
            }}
          >
            COPY REFERRAL LINK
            <span style={{ marginLeft: '10px', marginTop: '5px' }}>
              {isCopied ? <MdFileCopy /> : <MdContentCopy />}
            </span>
          </button>
          {isNotExistRef && (
            <span className="error-msg" style={{ color: 'red' }}>
              No Referral Link
            </span>
          )}
          {!isNotExistRef && isCopied && <span className="error-msg">Copied</span>}
        </div>
      </div>
      <div className="return-right">
        <div className="return-right-item">
          <span>REWARDS</span>
          <IconWrapper width={'50px'} height={'50px'} borderColor={'#01b1f3'}>
            <BsFillAwardFill color="#01b1f3" size={20} />
          </IconWrapper>
          <span>${totalAmountReport?.all.reward ?? 0}</span>
        </div>
        <div className="return-right-item">
          <span>INVITES</span>
          <IconWrapper width={'50px'} height={'50px'} borderColor={'#13c783'}>
            <AiOutlineMail color="#13c783" size={20} />
          </IconWrapper>
          <span>{emailList.get('data')?.length ?? 0}</span>
        </div>
        <div className="return-right-item">
          <span>USERS</span>
          <IconWrapper width={'50px'} height={'50px'} borderColor={'#ff5900'}>
            <FiUserPlus color="#ff5900" size={20} />
          </IconWrapper>
          <span>{quantityReport.reduce((total, n) => total + n.value, 0)}</span>
        </div>
      </div>
      <div className="external-icon">
        <NavLink to="/Affiliates" onClick={() => playAudio('return')}>
          <FiExternalLink size={20} color={themeMode === 'light' ? '#000' : '#fff'} />
        </NavLink>
      </div>
    </Container>
  )
}
export default CommandReturn
