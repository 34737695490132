import { Record } from 'immutable'
import { getListTransaction, sendPayData } from 'actions/transaction'
import MenuIcons from 'components/Icons/MenuIcons'
import React, { useEffect, useState } from 'react'
import { BsCheck2Circle } from 'react-icons/bs'
import { FiExternalLink } from 'react-icons/fi'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useGetThemeMode } from 'state/theme/hook'
import { Init as Transaction, ListTransaction } from '../../redusers/transaction'
import styled from 'styled-components'
import './styles.scss'
import Loader from 'components/Loader'
import { useMenuAudio } from 'components/DomainsDashboardTable/hooks'

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 280px;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid;
  font-size: 14px;
  margin: 0 5px 0 20px;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
    margin: 0 5px;
  }
  @media screen and (max-width: 768px) {
    margin: 5px 0 0 0;
  }
`

interface RowP {
  item: any
  themeMode: string
}
function Row({ item, themeMode }: RowP) {
  let tranType = item.type == 'purchase' ? 'Sent' : 'Received'
  const dateTime = item.date
  const date = dateTime.split(' ')[0]
  const time = dateTime.split(' ')[1]
  return (
    <tr className="transaction-tr">
      <td>
        <h4>
          {item.originAmount} {item.currency}
        </h4>
        <div className="trans-time">
          {date} | {time}
        </div>
      </td>
      <td className="">
        <h4 className="trans-proj">{item.product}</h4>
        <div className="trans-type">
          {tranType} <BsCheck2Circle color={'#1fe216'} size={12} />
        </div>
      </td>
    </tr>
  )
}

interface IntCommandSwiftP {
  getListTransaction: Function
  sendPayData: Function
  listTransaction: ListTransaction
}

const CommandSwift: React.FC<IntCommandSwiftP> = ({ getListTransaction, listTransaction }) => {
  const themeMode = useGetThemeMode()
  const { playAudio } = useMenuAudio()
  useEffect(() => {
    getListTransaction()
  }, [getListTransaction])

  const transactionList = listTransaction.filter((item: any) => Number(item.originAmount) > 0)

  return (
    <Container
      style={{
        background: themeMode == 'main' ? '#00000090' : '#ffffff90',
        borderColor: themeMode == 'main' ? '#02569d' : 'gray',
      }}
    >
      <div className="top-item">
        <div className="top-left">
          <MenuIcons url="thebridge-white" themeMode={themeMode} size={'18px'} />
          <h3 style={{ marginLeft: '-20px' }}>
            <NavLink
              to="/pay/"
              onClick={() => playAudio('shift')}
              style={{ color: themeMode === 'main' ? 'white' : 'black' }}
            >
              SHIFT
            </NavLink>
          </h3>
        </div>
        <NavLink to="/pay/" onClick={() => playAudio('shift')} target={'_self'}>
          <FiExternalLink size={20} color={themeMode === 'light' ? '#000' : '#fff'} />
        </NavLink>
      </div>
      <div className="shift-bottom-item">
        <div className="gray-font">RECENT TRANSACTIONS</div>
        {transactionList.length > 0 ? (
          <div className="trans-table-div">
            <table
              className="trans-table"
              cellSpacing="0"
              cellPadding="10"
              width="100%"
              style={{
                background:
                  themeMode == 'main' ? 'rgba(0, 0, 0, 0.5)' : themeMode == 'light' ? 'white' : 'rgba(0, 0, 0, 0.5)',
                color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
              }}
            >
              <tbody>
                {transactionList.slice(0, 20).map((item, index) => {
                  let keyVal = item.id + '-' + index
                  return <Row key={keyVal} item={item} themeMode={themeMode} />
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <span className="shift-loader-spinner">
            <Loader stroke="green" size="30px" />
          </span>
        )}
      </div>
    </Container>
  )
}
function mapStateToProps({ transaction }: { transaction: Record<Transaction> }) {
  return {
    listTransaction: transaction.get('listTransaction'),
  }
}

export default connect(mapStateToProps, { getListTransaction, sendPayData })(CommandSwift)
