import { Record } from 'immutable'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { checkedRole } from '../../../actions/user'
import AdminDomainsDashboardTable from '../../../components/AdminDomainsDashboardTable'
import CategoryTable from '../../../components/CategoryTable'
import MainContent from '../../../components/MainContent'
import Menu from '../../../components/Menu'
import { Init as User, Сondition3 } from '../../../redusers/user'
import EmailSettings from '../../EmailSettings'
import Accounts from '../Accounts'
import Account from '../Accounts/Account'
import InfoList from '../Info'
import AddArticle from '../Info/AddArticle/AddArticle'
import ChanfeInfo from '../Info/ChanfeInfo'
import ListTransaction from '../ListTransaction'
import ConfirmMhcWithdraw from '../ListTransaction/confirmMhcWithdraw'
import { MetaTags } from '../MetaTags'
import { SettingEmail } from '../settingEmail'
import Settings from '../Settings'
import AdminDashboard from '../Dashboard'

interface HastagListP {
  checkedRole: (condition: Сondition3) => void
  isAdmin: Сondition3
}

function Routing({ checkedRole, isAdmin }: HastagListP) {
  useEffect(() => {
    checkedRole(isAdmin)
  }, [isAdmin])

  return (
    <MainContent>
      <Menu />
      <Switch>
        <Route path="/admin/categorylist" component={CategoryTable} />
        <Route path="/admin/hastagList" component={AdminDashboard} />
        <Route path="/admin/listTransaction" component={ListTransaction} />
        <Route path="/admin/confirmWithdraw" component={ConfirmMhcWithdraw} />
        {/*<Route path="/admin/PremiumDomains" component={PremiumTags} />*/}
        <Route path="/admin/settings" component={Settings} />
        <Route exact path="/admin/accounts/" component={Accounts} />
        <Route path="/admin/accounts/:id" component={Account} />
        <Route exact path="/admin/changeInfo" component={InfoList} />
        <Route exact path="/admin/setMetaTags/" component={MetaTags} />
        <Route exact path="/admin/settingEmail/" component={SettingEmail} />
        <Route exact path="/admin/changeInfo/addArticle" component={AddArticle} />
        <Route path="/admin/changeInfo/changeArticle/:id" component={ChanfeInfo} />
        <Route
          path={['/admin/notification-settings', '/admin/notification-settings/:code']}
          exact
          component={EmailSettings}
        />
      </Switch>
    </MainContent>
  )
}

function mapStateToProps({ user }: { user: Record<User> }) {
  return { isAdmin: user.get('isAdmin') }
}

export default connect(mapStateToProps, {
  checkedRole,
})(Routing)
