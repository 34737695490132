import React, { useState } from 'react'
import './styles.scss'
import { IoIosClose } from 'react-icons/io'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'

const modalRoot: Element = document.getElementById('root-modal') as Element

interface ModalP {
  onClose: (e: React.FormEvent<HTMLElement>) => void
  title: string
  inputName: string
  inputType: string
  checkData?: (val: string) => boolean
  onSave: (val: string) => void
  value: string
}

const onSubmit =
  (onEditUrl: ModalP['onSave'], setIsError: (d: boolean) => void, checkData?: ModalP['checkData']) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(e.currentTarget)
    const value = data.get('value') as string

    if (!checkData) {
      checkData = (d: string) => true
    }

    if (checkData(value)) {
      onEditUrl(value)
      setIsError(false)
    } else {
      setIsError(true)
    }

    e.preventDefault()
  }

const changeVal = (setVal: Function) => (e: React.FormEvent<HTMLInputElement>) => {
  setVal(e.currentTarget.value)
}

const Modal: React.FC<ModalP> = ({ onClose, title, onSave, inputName, inputType, checkData, value }) => {
  const [isError, setIsError] = useState<boolean>(false)
  const [val, setVal] = useState<string>(value)

  return (
    <div className="modal">
      <div className="modal-content">
        <form onSubmit={onSubmit(onSave, setIsError, checkData)} style={{ display: 'block' }}>
          <div className="modal-content__header">
            <p>{title}</p>
            <div className="modal-close" onClick={onClose}>
              <IoIosClose size={36} />
            </div>
          </div>
          <div className={`modal-inp  ${isError ? 'error' : ''}`}>
            <label htmlFor="">{inputName}</label>
            <input type={inputType} name="value" value={val} onChange={changeVal(setVal)} />
          </div>
          <div className="modal-content__btns">
            <div className="btn" onClick={onClose}>
              Cancel
            </div>
            <button className="btn" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const timeout = { enter: 500, exit: 100 }

interface UnifiedModalP {
  showModal: boolean
  onClose: (e: React.FormEvent<HTMLElement>) => void
  title: string
  inputName: string
  inputType: string
  onSave: (val: string) => void
  checkData?: (val: string) => boolean
  value?: string
}
const UnifiedModal: React.FC<UnifiedModalP> = ({
  showModal,
  onClose,
  title,
  inputName,
  inputType,
  onSave,
  checkData,
  value = '',
}) => {
  return createPortal(
    <CSSTransition classNames="animated" in={showModal} unmountOnExit timeout={timeout}>
      <Modal
        onClose={onClose}
        title={title}
        inputName={inputName}
        inputType={inputType}
        onSave={onSave}
        checkData={checkData}
        value={value}
      />
    </CSSTransition>,
    modalRoot
  )
}

export default UnifiedModal
