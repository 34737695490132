import { SagaIterator } from 'redux-saga'
import { call, fork, put, SagaReturnType } from 'redux-saga/effects'
import { showToast } from 'sagas/domain'
import { genFetchedData } from '../../../../utils/api/fetchedData'
import { makeReqWithRD, TMakeReqWithRD } from '../../../../utils/api/makeReqWithRD'
import {
  fetchGetTemplate,
  fetchPreviewTemplate,
  fetchSetStatusTemplate,
  fetchSetTemplate,
  fetchSetTmpl,
} from '../actions'
import {
  getTemplate,
  getTemplateList,
  getTmpl,
  previewTemplate,
  setTemplateN,
  setTmpl,
  TRGetTemplateList,
} from '../api'
import { setIsSetTemplate, setIsTmpl, setMailTmpl, setPreviewTemplate, setTemplate, setTemplateList } from '../reduser'

export function* workerGetTmpl(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getTmpl>>(makeReqWithRD, {
      fetcher: getTmpl,
      fill: setMailTmpl,
    })
  } catch (e: any) {
    console.error({ e })
  }
}

export function* workerSetTmpl({ payload }: ReturnType<typeof fetchSetTmpl>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof setTmpl>>(makeReqWithRD, {
      fetcher: setTmpl,
      fill: setIsTmpl,
      parameters: { text: payload },
    })
    yield fork(workerGetTmpl)
  } catch (e: any) {
    console.error({ e })
  }
}
export function* workerGetTemplate({ payload }: ReturnType<typeof fetchGetTemplate>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getTemplate>>(makeReqWithRD, {
      fetcher: getTemplate,
      fill: setTemplate,
      parameters: { code: payload },
    })
  } catch (e: any) {
    console.error({ e })
  }
}

export function* workerTemplateList(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getTemplateList>>(makeReqWithRD, {
      fetcher: getTemplateList,
      fill: setTemplateList,
    })
  } catch (e: any) {
    console.error({ e })
  }
}
export function* workerTemplateListSoftUpdate(): SagaIterator<void> {
  let receivedData = genFetchedData<TRGetTemplateList[]>(null)

  try {
    const res: SagaReturnType<typeof getTemplateList> = yield call(getTemplateList)
    receivedData = receivedData.set('data', res)
  } catch (e: any) {
    console.error({ e })
    receivedData = receivedData.set('error', {
      isError: true,
      message: e.message,
      code: e?.code,
    })
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now())
    yield put(setTemplateList(receivedData))
  }
}

export function* workerSetTemplate({ payload }: ReturnType<typeof fetchSetTemplate>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof setTemplateN>>(makeReqWithRD, {
      fetcher: setTemplateN,
      fill: setIsSetTemplate,
      parameters: payload,
    })

    yield call(workerGetTemplate, fetchGetTemplate(payload.code))
    yield fork(workerTemplateListSoftUpdate)
    showToast('Changes saved.', 'success')
  } catch (e: any) {
    console.error({ e })
  }
}
export function* workerPreviewTemplate({ payload }: ReturnType<typeof fetchPreviewTemplate>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof previewTemplate>>(makeReqWithRD, {
      fetcher: previewTemplate,
      fill: setPreviewTemplate,
      parameters: payload,
    })
  } catch (e: any) {
    console.error({ e })
  }
}
export function* workerSetStatusTemplate({ payload }: ReturnType<typeof fetchSetStatusTemplate>): SagaIterator<void> {
  try {
    yield call(setTemplateN, {
      code: payload.code,
      fields: { status: payload.status },
    })
    yield fork(workerTemplateListSoftUpdate)
  } catch (e: any) {
    console.error({ e })
  }
}
