import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { hashtagGetItemRes, IGetAllDomainListR, TGetKeywordListR } from 'utils/api'
import './styles.scss'
import { Root, AutoSearchCompleteContainer, AutoSearchDomainContainer } from './styles'
import { GetDomainList } from 'views/Search/hooks/availableCategoryList'
import { addItemCart } from 'views/Cart/actions'
import { connect, useDispatch, useSelector } from 'react-redux'
import { BsArrowRightShort, BsStar, BsStarFill } from 'react-icons/bs'
import { MdAddShoppingCart } from 'react-icons/md'
import { onAddWishDomain, onSaveKeyWord, topSearchProcess } from 'actions/hashtag'
import TwoBtnModal from 'components/Modals/TwoBtnModal'
import { MODAL_TYPES, useGlobalModalContext } from 'components/Modals/GlobalModal'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import { Init as UserInit } from 'redusers/user'
import { useCommonCheck } from 'hooks/useCommon'
import { getNickList } from 'actions/transaction'
import { AudioItem } from 'components/Menu/tooltip'
import { useIsShowTooltip } from 'state/toolTip/hook'
import Freedom from '../../assets/command/freedom-info.png'
import FormInput from 'components/FormInput'

interface RowP {
  item: any
  themeMode?: string
  clickItem: () => void
  keyword: (e: string) => void
  domainListShow: (e: boolean) => void
  componentShow: (e: boolean) => void
  login: boolean
  loginUserId: number
}
function Row({ item, themeMode, clickItem, domainListShow, componentShow, keyword, login, loginUserId }: RowP) {
  const dispatch = useDispatch()
  const { showToastMsg } = useCommonCheck()
  const { showModal } = useGlobalModalContext()
  const isShowTooltip = useIsShowTooltip()
  const [isWish, setIsWish] = useState<boolean>(false)

  const wishesVal = useMemo(() => item.wishes, [item.wishes])

  const setWishVal = useCallback(() => {
    if (wishesVal != null && wishesVal != '') {
      if (wishesVal.split(',').includes(loginUserId.toString())) {
        setIsWish(true)
      }
    }
  }, [loginUserId, wishesVal])

  const onShowURLModal = (url: string, domainN: string, nick: string, name: string, fav: string, userId: number) => {
    showModal(MODAL_TYPES.TARGET_URL_MODAL, {
      url,
      domainN,
      nick,
      name,
      fav,
      userId,
      isShowTooltip,
    })
    clickItem()
    componentShow(false)
  }

  const goTarget = () => {
    const targetUrl = item.url
    if (targetUrl && targetUrl !== null && targetUrl !== 'unknown') {
      dispatch(topSearchProcess(item.domainName))

      const urlArr = targetUrl.split(',')
      if (urlArr.length > 1) {
        onShowURLModal(targetUrl, item.domainName, item.nick, item.name, item.favorites, item.userId)
      } else {
        window.open(urlArr[0], '_blank')
        AudioItem('btn-go').play()
      }
      clickItem()
      componentShow(false)
    }
  }

  const addShopping = () => {
    if (login) {
      dispatch(
        addItemCart({
          id: item.domainName,
          isDemo: false,
          price: item.resalePrice,
          applyDiscount: false,
          type: 'domain',
          keyword: '',
        })
      )
    } else {
      showToastMsg('You must login or signup for this function.', 'info')
    }
  }

  const addKeyword = (word: string) => {
    if (login) {
      componentShow(true)
      domainListShow(true)
      keyword(word)
    } else {
      showToastMsg('You must login or signup for this function.', 'info')
    }
  }

  const onProcessWishList = useCallback(() => {
    const selectedDomain = item.domainName
    let wish_val = ''
    if (wishesVal == '' || wishesVal == null) {
      wish_val = loginUserId.toString()
      setIsWish(true)
    } else {
      if (isWish) {
        const wishArr = wishesVal.split(',').filter((v: string) => v != loginUserId.toString())
        wish_val = wishArr.join()
        setIsWish(false)
      } else {
        wish_val = wishesVal + ',' + loginUserId
        setIsWish(true)
      }
    }
    dispatch(
      onAddWishDomain({
        domainId: selectedDomain,
        wishVal: wish_val,
      })
    )
  }, [dispatch, isWish, item, loginUserId, wishesVal])

  useEffect(() => {
    setWishVal()
  }, [setWishVal])

  return (
    <>
      {item.keyword !== '$$$' ? (
        <div className="tr-c">
          <div className="td-domain" onClick={clickItem}>
            {item.domainName.length > 15 ? item.domainName.substring(0, 15) + '...' : item.domainName}
          </div>
          <div className="td-url" onClick={clickItem}>
            {item.resalePrice > 0 && (
              <>
                <span className="resale-price">{'$' + item.resalePrice}</span>
                {item.url.length > 0 && ' | '}
              </>
            )}
            {item.url.length > 25 ? item.url.substring(0, 25) + '...' : item.url}
          </div>
          <div className="td-star">
            {item.resalePrice > 0 && (
              <span style={{ marginTop: '5px' }} onClick={onProcessWishList}>
                {isWish ? (
                  <BsStarFill size={18} color={themeMode === 'light' ? '#ff5900' : '#ffa600'} />
                ) : (
                  <BsStar size={18} color={themeMode === 'light' ? '#ff5900' : '#ffa600'} />
                )}
              </span>
            )}
          </div>
          <div className="td-btn">
            {item.url !== '' && item.url !== 'unknown' && (
              <div
                className="btn-dropdown"
                style={{ background: 'linear-gradient(#fbb212, #f48f33)' }}
                onClick={() => goTarget()}
              >
                GO <BsArrowRightShort size={15} />
              </div>
            )}
            {item.resalePrice > 0 && (
              <div
                className="btn-dropdown"
                style={{ background: 'linear-gradient(#60fb12, #41ae0a)' }}
                onClick={() => addShopping()}
              >
                BUY <MdAddShoppingCart size={15} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="tr-c">
          <div className="td-domain-keyword" onClick={clickItem}>
            {item.domainName.length > 40 ? item.domainName.substring(0, 40) + '...' : item.domainName}
          </div>
          <div className="td-btn-keyword">
            <div
              className="btn-dropdown-keyword"
              style={{ background: 'linear-gradient(#60fb12, #41ae0a)' }}
              onClick={() => addKeyword(item.domainName)}
            >
              BUY KEYWORD
              <MdAddShoppingCart size={15} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

interface autoSearchProps {
  optionsStyle?: React.CSSProperties
  inputValue?: string
  themeMode?: string
  dataList: TGetKeywordListR[]
  keywordList: TGetKeywordListR[]
  userDomainList: IGetAllDomainListR[]
  onChange: (e: string) => void
  showDropdown: boolean
  setShowDropdown: (e: boolean) => void
  hashtagItem?: hashtagGetItemRes | undefined
  onAutoSearchGetItem?: Function
  onDomainEdit?: Function
  placeString?: string
  isDisabled?: boolean
  flag?: string
}

export const AutoSearchComplete: React.FC<autoSearchProps> = ({
  dataList,
  keywordList,
  userDomainList,
  onChange,
  inputValue,
  setShowDropdown,
  showDropdown,
  placeString,
  isDisabled,
  themeMode,
  flag,
}) => {
  const dispatch = useDispatch()
  const isLogIn = useSelector<AppState, Init['isLogin']>(({ user }) => user.get('isLogin'))
  const userInfo = useSelector<AppState, UserInit['userInfo']>(({ user }) => user.get('userInfo'))
  const loginUserId = userInfo.id
  console.log('=====userInfo::', JSON.stringify(userInfo))
  const [search, setSearch] = useState({
    text: inputValue,
    suggestions: [
      {
        keyword: '',
        domainName: '',
        url: '',
        resaleStatus: false,
        resalePrice: 0,
        favorites: '',
        wishes: '',
        nick: '',
        name: '',
        userId: 0,
        price: 0,
      },
    ],
  })
  const [keywordItem, setKeywordItem] = useState({
    keywordTxt: '',
    domainName: '',
    keywordCount: 0,
  })

  const [tag, setTag] = useState<string>('#')
  const [isDomainListVisible, setIsDomainListVisible] = useState(false)
  const [showKeyConfirmModal, setShowKeyConfirmModal] = useState(false)
  const [keyword, setKeyword] = useState('')
  const { isContainSpace } = GetDomainList()
  const { showToastMsg } = useCommonCheck()

  const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setIsDomainListVisible(false)
    const value = e.target.value
    let suggestions: TGetKeywordListR[] = []

    if (value.length > 0) {
      if (isContainSpace(value)) {
        const searchTxt = value.trim()
        suggestions = keywordList
          .sort()
          .filter((v: TGetKeywordListR) => v.keyword.toLowerCase().includes(searchTxt.toLowerCase()))

        const item = {
          keyword: '$$$',
          domainName: searchTxt,
          url: '',
          resaleStatus: false,
          resalePrice: 0,
          favorites: '',
          wishes: '',
          nick: '',
          name: '',
          userId: 0,
          price: 0,
        }
        suggestions.unshift(item)
      } else {
        suggestions = dataList
          .sort()
          .filter((v: TGetKeywordListR) => v.domainName.toLowerCase().includes(tag + value.toLowerCase()))
      }
      onChange(value)
    }

    if (suggestions.length > 0) {
      setShowDropdown(true)
    }
    setSearch({ suggestions, text: value })
  }

  const suggestionSelected = (value: TGetKeywordListR) => {
    setShowDropdown(false)
    setIsDomainListVisible(false)
    onChange(value.domainName.indexOf('#') === 0 ? value.domainName.substring(1) : value.domainName)
    setSearch({
      text: value.domainName.indexOf('#') === 0 ? value.domainName.substring(1) : value.domainName,
      suggestions: [],
    })
  }

  const { suggestions } = search

  useEffect(() => {
    if (inputValue === '') {
      setSearch({ suggestions, text: '' })
    }
  }, [inputValue, suggestions])

  const closeKeywordModal = useCallback(() => {
    setKeywordItem({ keywordTxt: '', domainName: '', keywordCount: 0 })
    setShowKeyConfirmModal(false)
  }, [suggestions])

  const buyKeyWord = useCallback(() => {
    if (isLogIn) {
      const payout = keywordItem.keywordCount < 6 ? 0 : Math.pow(2, keywordItem.keywordCount - 6)
      const val = {
        domain: keywordItem.domainName,
        keyword: keywordItem.keywordTxt,
        payout: payout,
      }

      dispatch(onSaveKeyWord(val))
      setShowKeyConfirmModal(false)
      setShowDropdown(false)
      setIsDomainListVisible(false)
      setSearch({ suggestions, text: '' })
    } else {
      showToastMsg('You must login or signup for this function.', 'info')
    }
  }, [dispatch, keywordItem, suggestions])

  const selectDomainForKeyword = useCallback(
    (item: IGetAllDomainListR) => {
      const keywordsTxt = item.keywords
      setKeywordItem({
        keywordTxt: keywordsTxt.join() + ',' + keyword,
        domainName: item.domainName,
        keywordCount: keywordsTxt.length + 1,
      })
      setIsDomainListVisible(false)
      setShowKeyConfirmModal(true)
    },
    [keyword]
  )

  return (
    <Root
      style={{
        background: themeMode === 'main' ? 'black' : 'white',
      }}
    >
      <div onClick={() => setShowDropdown(false)} />
      {!isDisabled && (
        <span
          className={flag === 'command_search' ? 'tagPrefix' : 'tagPrefix_home'}
          style={{
            color: themeMode === 'main' ? 'white' : 'black',
          }}
        >
          {tag}
        </span>
      )}
      <div className="freedom-input">
        <input
          type="text"
          className="inputTag"
          disabled={isDisabled}
          placeholder={placeString}
          value={search.text}
          onChange={onTextChanged}
          style={{
            background: themeMode === 'main' ? 'black' : 'white',
            color: themeMode === 'main' ? 'white' : 'black',
          }}
        />
        <img src={Freedom} alt="" className="freedom-img" />
      </div>
      {suggestions.length > 0 && showDropdown && (
        <AutoSearchCompleteContainer
          style={{
            background: themeMode === 'main' ? 'black' : 'white',
            color: themeMode === 'main' ? 'white' : 'black',
          }}
        >
          {suggestions.map((item: TGetKeywordListR, index: number) => {
            return (
              <Row
                key={index}
                item={item}
                keyword={setKeyword}
                domainListShow={setIsDomainListVisible}
                componentShow={setShowDropdown}
                clickItem={() => suggestionSelected(item)}
                login={isLogIn}
                loginUserId={loginUserId}
                themeMode={themeMode}
              />
            )
          })}
        </AutoSearchCompleteContainer>
      )}
      {userDomainList.length > 0 && isDomainListVisible && (
        <AutoSearchDomainContainer
          style={{
            background: themeMode === 'light' ? '#d4e3ee' : '',
            color: themeMode === 'light' ? 'black' : 'white',
          }}
        >
          {userDomainList.map((item: IGetAllDomainListR, index: number) => {
            return (
              <div key={index} className="domain-c" onClick={() => selectDomainForKeyword(item)}>
                {item.domainName}
              </div>
            )
          })}
        </AutoSearchDomainContainer>
      )}
      {showKeyConfirmModal && (
        <TwoBtnModal
          title={`Confirm Adding Keyword to ${keywordItem.domainName}`}
          onClose={closeKeywordModal}
          confirm={buyKeyWord}
          themeMode={themeMode}
        >
          <div>
            Fee of extra keyword({keyword}) :
            {keywordItem.keywordCount < 6 ? ' Free' : ' $' + Math.pow(2, keywordItem.keywordCount - 6) + '.00'}
          </div>
        </TwoBtnModal>
      )}
    </Root>
  )
}
