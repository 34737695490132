import React, { useState, CSSProperties } from 'react'
import cn from './cn.module.css'
import styled from 'styled-components'
import PayPalSymbolImg from '../../assets/icons/symbol-paypal.svg'
import { useGetThemeMode } from 'state/theme/hook'
import { BNB_ICON, ETH_ICON, MHC_ICON } from 'constants/urls'

const Main = styled('div')<{ color?: string; bgColor?: string }>`
  font-family: Montserrat;
  background: ${({ bgColor }) => bgColor};
  display: flex;
  border: 1px solid;
  height: 40px;
  font-size: 14px;
  padding: 0 10px;
  margin-top: 4px;
  margin-right: 10px;
  color: ${({ color }) => color};
  width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
`

const DropDownContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const DropDownHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const DropDownArrowDown = styled('span')`
  font-size: 12px;
  padding-top: 4px;
`

const DropDownArrowUp = styled('span')`
  font-size: 12px;
  padding-bottom: 4px;
`

const DropDownListContainer = styled('div')`
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 45px;
`

const DropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
`

const ListItem = styled('li')`
  border-bottom: 1px solid;
  padding: 10px 12px;
  list-style: none;
  &:hover {
    color: #fd9e46;
  }
`

const options: tags[] = ['paypal', 'metahash', 'userETH', 'userBNB']
type tags = 'paypal' | 'metahash' | 'userETH' | 'userBNB'

interface FormSelectP {
  error?: boolean
  selinp?: string
  categoryselect?: boolean
  hashtagselect?: boolean
  label?: string
  value?: string
  onOptionChange: (val: tags) => void
  defoltDescription?: string | undefined
  style?: CSSProperties
}

const CustomSelectBox: React.FC<FormSelectP> = ({
  error,
  selinp,
  categoryselect,
  hashtagselect,
  label,
  value = '',
  onOptionChange,
  defoltDescription,
  style = {},
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<tags>('paypal')

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = (value: tags) => () => {
    setSelectedOption(value)
    setIsOpen(false)
    onOptionChange(value)
  }

  const themeMode = useGetThemeMode()

  return (
    <Main
      color={themeMode == 'main' ? '#ffffff' : '#000000'}
      bgColor={themeMode == 'main' ? '#00000090' : '#ffffff90'}
      style={{
        borderColor: themeMode == 'main' ? '#ffffff90' : '#00000090',
      }}
    >
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          {selectedOption === 'paypal' && <div className={cn.mhcTtext}>PayPal</div>}
          {selectedOption === 'metahash' && (
            <div className={cn.iconDiv}>
              <img src={MHC_ICON} style={{ width: '15px', position: 'inherit' }} />
              <span className={cn.mhcTtext}>MHC</span>
            </div>
          )}
          {selectedOption === 'userETH' && (
            <div className={cn.iconDiv}>
              <img src={ETH_ICON} style={{ width: '15px', position: 'inherit' }} />
              <span className={cn.mhcTtext}>ETH</span>
            </div>
          )}
          {selectedOption === 'userBNB' && (
            <div className={cn.iconDiv}>
              <img src={BNB_ICON} style={{ width: '15px', position: 'inherit' }} />
              <span className={cn.mhcTtext}>BNB</span>
            </div>
          )}
          {/* {selectedOption === 'paypal' ? (
            <span className={cn.fontItaly}>PayPal</span>
          ) : (
            <div>
              <span className={cn.fontItaly}># MHC</span>
            </div>
          )} */}
          {isOpen ? (
            <DropDownArrowUp style={{ color: themeMode === 'main' ? 'white' : 'black' }}>&#65087;</DropDownArrowUp>
          ) : (
            <DropDownArrowDown style={{ color: themeMode === 'main' ? 'white' : 'black' }}>&#65088;</DropDownArrowDown>
          )}
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList
              style={{
                background: themeMode == 'main' ? '#000000d5' : '#ffffffd5',
                color: themeMode == 'main' ? '#ffffff' : '#000000',
              }}
            >
              {options.map((option) => (
                <ListItem
                  onClick={onOptionClicked('paypal')}
                  key={option}
                  style={{
                    borderBottomColor: themeMode == 'main' ? '#ffffff' : '#000000',
                  }}
                >
                  {option === 'paypal' && <span className={cn.mhcTtext}>PayPal</span>}
                  {option === 'metahash' && (
                    <div className={cn.iconDiv}>
                      <img src={MHC_ICON} style={{ width: '15px', position: 'inherit' }} />
                      <span className={cn.mhcTtext}>MHC</span>
                    </div>
                  )}
                  {option === 'userETH' && (
                    <div className={cn.iconDiv}>
                      <img src={ETH_ICON} style={{ width: '15px', position: 'inherit' }} />
                      <span className={cn.mhcTtext}>ETH</span>
                    </div>
                  )}
                  {option === 'userBNB' && (
                    <div className={cn.iconDiv}>
                      <img src={BNB_ICON} style={{ width: '15px', position: 'inherit' }} />
                      <span className={cn.mhcTtext}>BNB</span>
                    </div>
                  )}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Main>
  )
}

export default CustomSelectBox
