import { SagaIterator } from 'redux-saga'
import { call, fork, put, SagaReturnType, select } from 'redux-saga/effects'
import { SendEmail, setAddEmails, setEmailList, setRemoveEmail, setSendEmails } from '../reduser'
import { fetchAddEmails, fetchRemoveEmail, fetchSendTo, onSetLastSend } from '../actions'
import { addEmails, getList, remove, sendEmails, sendTo } from '../api'
import { makeReqWithRD, TMakeReqWithRD } from '../../../utils/api/makeReqWithRD'
import { AppState } from '../../../redusers/config'
import { showToast } from 'sagas/domain'

const getEmailList = ({ sendEmailReducer }: AppState): SendEmail['emailList'] => sendEmailReducer.emailList

export function* workerGetList(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getList>>(makeReqWithRD, {
      fetcher: getList,
      fill: setEmailList,
    })
  } catch (e: any) {
    console.error({ e })
  }
}
export function* workerSoftGetList(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getList>>(makeReqWithRD, {
      fetcher: getList,
      fill: setEmailList,
      softUpdate: true,
    })
  } catch (e: any) {
    console.error({ e })
  }
}
export function* workerSetLastSend({ payload }: ReturnType<typeof onSetLastSend>): SagaIterator<void> {
  try {
    const emailList: SendEmail['emailList'] = yield select(getEmailList)

    const emailListData = emailList.get('data')
    if (!emailListData) return

    const index = emailListData.findIndex((item) => item.email === payload)
    if (index < 0) return

    const nemailListData = [...emailListData]

    nemailListData[index] = {
      email: emailListData[index].email,
      lastSend: +new Date() / 1000,
    }

    yield put(setEmailList(emailList.set('data', nemailListData)))
  } catch (e: any) {
    console.error({ e })
  }
}

export function* workerAddEmails({ payload }: ReturnType<typeof fetchAddEmails>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof addEmails>>(makeReqWithRD, {
      fetcher: addEmails,
      fill: setAddEmails,
      parameters: { list: payload },
    })
    yield fork(workerGetList)
  } catch (e: any) {
    console.error({ e })
  }
}
export function* workerRemoveEmail({ payload }: ReturnType<typeof fetchRemoveEmail>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof remove>>(makeReqWithRD, {
      fetcher: remove,
      fill: setRemoveEmail,
      parameters: { email: payload },
    })
    yield fork(workerSoftGetList)
  } catch (e: any) {
    console.error({ e })
  }
}
export function* workerSendEmails(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof sendEmails>>(makeReqWithRD, {
      fetcher: sendEmails,
      fill: setSendEmails,
    })
  } catch (e: any) {
    console.error({ e })
  }
}
export function* workerSendTo({ payload }: ReturnType<typeof fetchSendTo>): SagaIterator<void> {
  try {
    yield call(sendTo, { email: payload })
    showToast(`Send on  ${payload} `, 'success')

    yield call(workerSetLastSend, onSetLastSend(payload))
  } catch (e: any) {
    console.error({ e })
    yield fork(workerSoftGetList)
  }
}
