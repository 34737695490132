import React from 'react'
import {
  BigValueText,
  FlexColumn,
  FlexRow,
  FlexRowContent,
  HorizontalDivider,
  SubContainer,
  SubContainerBody,
  SubTitleText,
} from '../../styled'
import { FaRegCheckCircle, FaExclamationCircle } from 'react-icons/fa'
import { IBridgeStatusProps, IRecurringCreditProps } from '../../types'
import { useGetThemeMode } from 'state/theme/hook'

const StatusIcon = {
  Process: <FaExclamationCircle color={'#ee7a09'} />,
  Success: <FaRegCheckCircle color={'#2ec814'} />,
  Pending: undefined,
}

const RecurringCreditItem: React.FC<IRecurringCreditProps> = ({ item }) => {
  return (
    <FlexColumn>
      <FlexRowContent>
        <BigValueText color={'#919599'} fontSize={'14px'}>
          {`To pay on ${item.date}`}
        </BigValueText>
        {StatusIcon[item.status]}
      </FlexRowContent>
      <BigValueText color={'#ffffff'} fontSize={'18px'}>{`$ ${item.amount}`}</BigValueText>
    </FlexColumn>
  )
}
export const RecurringCredits: React.FC = () => {
  const recurringCredit: IBridgeStatusProps[] = [
    { id: 1, date: 'Jan 29, 2021', amount: 1502.26, status: 'Process' },
    { id: 2, date: 'Jan 29, 2021', amount: 1502.26, status: 'Success' },
  ]

  const themeMode = useGetThemeMode()

  return (
    <SubContainer width={'20%'} height={'200px'} themeMode={themeMode}>
      <FlexRowContent>
        <SubTitleText fontSize={'12px'}>RECURRING #CREDITS</SubTitleText>
      </FlexRowContent>
      <HorizontalDivider />
      <SubContainerBody>
        <RecurringCreditItem item={recurringCredit[0]} />
        <RecurringCreditItem item={recurringCredit[1]} />
      </SubContainerBody>
    </SubContainer>
  )
}
