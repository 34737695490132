import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../../redusers/config'
import { IAssetsTable, ICreateAssets } from '../../types'
import './styles.scss'
import { AiFillDelete } from 'react-icons/ai'
import { useGetAssetData } from '../../hooks'
import { GradientButton } from '../../../../components/Buttons/GradientButton'
import RedPercent from '../RedPercent'
import GreenPercent from '../GreenPercent'
import { createAssetsList, removeAsset } from '../../actions'
import { useGetThemeMode } from 'state/theme/hook'

interface IRow {
  row: ICreateAssets['asset']
}

const Row: React.FC<IRow> = ({ row }) => {
  const dispatch = useDispatch()
  const [isOpenRemovePopUp, setIsOpenRemovePopUp] = useState<boolean>(false)
  const rowData = useGetAssetData(row)
  useEffect(() => {
    if (rowData) {
      const rowDataTemp = { ...rowData }
      dispatch(createAssetsList({ ...rowDataTemp }))
    }
  }, [rowData])

  const removeAssets = (id: number, closePopUp: (v: boolean) => void) => {
    dispatch(removeAsset({ id }))
    closePopUp(false)
  }

  return (
    <>
      {rowData && (
        <tr className="tableRow">
          <td className="nameCell">
            <img className="logo" src={rowData.logo} alt="" />
            {`${rowData.name} (${rowData.symbol})`}
          </td>
          <td>${rowData.usdPrice}</td>
          <td>
            {rowData.isPositive ? (
              <GreenPercent percent={rowData.dailyChangePercent} />
            ) : (
              <RedPercent percent={rowData.dailyChangePercent} />
            )}
          </td>
          <td>
            {rowData.originAmount} {row.symbol}
          </td>
          <td className="usdAmountCell">
            <span>${rowData.usdAmount}</span>
            {/* <span className={cn.mhcAmount}> ({rowData.mhcAmount} MHC)</span> */}
          </td>
          <td>
            <span>{`${rowData.isProfit ? '+' : '-'} $${Math.abs(rowData.profit).toFixed(6)}`}</span>
            {rowData.isProfit ? (
              <GreenPercent percent={rowData.profitPercent} />
            ) : (
              <RedPercent percent={rowData.profitPercent} />
            )}
          </td>
          <td className="action_td" onClick={() => setIsOpenRemovePopUp(true)}>
            <AiFillDelete size={20} />
          </td>
          {isOpenRemovePopUp && (
            <div className="popup">
              <div className="title">Remove Asset</div>
              <div className="desc">Are you sure want to remove this Asset?</div>
              <GradientButton onClick={() => removeAssets(row.id, setIsOpenRemovePopUp)}>Remove</GradientButton>
              <GradientButton backgroundColor={'transparent'} onClick={() => setIsOpenRemovePopUp(false)}>
                Cancel
              </GradientButton>
            </div>
          )}
        </tr>
      )}
      {!rowData && <div>Loading . . .</div>}
    </>
  )
}

const AssetsTable: React.FC<any> = () => {
  const portfolioList = useSelector<AppState, ICreateAssets['asset'][]>(({ portfolio }) =>
    portfolio.get('listPortfolio')
  )
  const themeMode = useGetThemeMode()
  const header = ['Name', 'Price', 'DailyChange', 'Holding', 'USDAmount', 'Profit', 'Actions']

  return (
    <div
      className="assetsTableContainer"
      style={{
        background: themeMode === 'main' ? '#00000090' : '#ffffff90',
        color: themeMode === 'main' ? '#ffffff' : '#000000',
      }}
    >
      <div className="title">Your Assets</div>
      <div className="tableBody">
        <table className="assetTable" cellSpacing="0" cellPadding="0" width="100%">
          <thead>
            <tr
              className="header_tr"
              style={{
                background: themeMode === 'main' ? '#292c27' : '#e4f1ef',
                color: themeMode === 'main' ? '#ffffff' : '#000000',
              }}
            >
              {header.map((item, index) => {
                return (
                  <th key={index} className="th">
                    {item === 'DailyChange' ? '24H' : item === 'Profit' ? 'Profit/Loss' : item}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {portfolioList.map((item, index) => {
              return <Row key={index} row={item} />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AssetsTable
