import HeaderItemlist from 'components/Header/HeaderItemlist'
import { Record } from 'immutable'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router'
import Header from '../../components/Header'
import HeaderLogin from '../../components/Header/HeaderLogin'
import HeaderLoginButtons from '../../components/Header/HeaderLogin/HeaderLoginButtons'
import { Init as User } from '../../redusers/user'

interface FromDefaultP {
  isLogin: boolean
}
function FromDefault({ isLogin }: FromDefaultP) {
  let match = useRouteMatch('/viewprofile/:userID')

  if (match?.isExact) return null

  if (isLogin) {
    return <HeaderItemlist />
  }

  return (
    <HeaderLogin>
      <HeaderLoginButtons />
    </HeaderLogin>
  )
}

interface HeaderWrapP {
  isLogin: User['isLogin']
}

function HeaderWrap({ isLogin }: HeaderWrapP) {
  return (
    <Header>
      <Switch>
        <Route exact path="/register/" />
        <Route path="/login/" />
        <Route path="/*" render={() => <FromDefault isLogin={isLogin}></FromDefault>} />
      </Switch>
    </Header>
  )
}

function mapStateToProps({ user }: { user: Record<User> }) {
  return { isLogin: user.get('isLogin') }
}

export default connect(mapStateToProps)(HeaderWrap)
