import React, { useRef } from 'react'
import styled from 'styled-components'
import ContentEditable from 'react-contenteditable'
import { TInstructionItem } from '../../types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { updateInstruction } from '../../actions'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'

const InstructionDetailBody = styled(ContentEditable)`
  padding: 12px;
  /* border: none; */
  /* outline: none; */
`
interface IInstructionDetail {
  item: TInstructionItem
  type: 'detail' | 'point'
  value: string
  themeMode?: string
}
const InstructionDetail: React.FC<IInstructionDetail> = ({ item, type, value, themeMode }) => {
  const dispatch = useDispatch()
  const detailRef = useRef(value)
  const isAdmin = useSelector<AppState, Init['isAdmin']>(({ user }) => user.get('isAdmin'), shallowEqual)

  const handleChange = (e: any) => {
    detailRef.current = e.target.value
  }
  const handleSave = () => {
    dispatch(updateInstruction([{ ...item, detail: detailRef.current }]))
  }

  return (
    <InstructionDetailBody
      html={detailRef.current}
      disabled={isAdmin !== 'YES'} // use true to disable edition
      onChange={(e) => handleChange(e)} // handle innerHTML change
      onBlur={handleSave}
      style={{
        color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
      }}
    />
  )
}

export default InstructionDetail
