import { HASHTAG_URL } from 'constants/urls'
import React, { useEffect, useState } from 'react'
import { FaRegCopy } from 'react-icons/fa'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ReactTable, { createTheme } from 'react-data-table-component'
import { useGetThemeMode } from 'state/theme/hook'
import { AppState } from '../../../redusers/config'
import { Init } from '../../../redusers/user'
import css from './styles.module.scss'

createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
    fontFamily: '"Montserrat", sans-serif',
  },
  background: {
    default: '#00000030',
  },
  context: {
    background: '#FFFFFF',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: '#FFFFFF',
    hover: '#FFFFFF',
    disabled: '#FFFFFF',
  },
  highlightOnHover: {
    default: '#00000030',
    text: '#FFFFFF',
  },
  sortFocus: {
    default: '#FFFFFF',
  },
})

createTheme('solarized_light', {
  text: {
    primary: '#000',
    secondary: '#000',
    fontFamily: '"Montserrat", sans-serif',
  },
  background: {
    default: '#ffffff50',
  },
  context: {
    background: '#000',
    text: '#000',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: '#000',
    hover: '#000',
    disabled: '#000',
  },
  highlightOnHover: {
    default: '#00000030',
    text: '#000',
  },
  sortFocus: {
    default: '#000ff0',
  },
})

export default function SeeUsers(): JSX.Element {
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const [ref, setRef] = useState<string>('')

  const referralCode = 'EwCQXYv66th4K3dQkGupzBXzMiDaIXW6'
  const userInfo = useSelector<AppState, Init['userInfo']>(({ user }) => user.get('userInfo'), shallowEqual)

  useEffect(() => {
    setRef(`${HASHTAG_URL}?ref=${referralCode}`)
  }, [referralCode])

  if (!userInfo.referral_status) {
    return <div className={css.wraper} />
  }

  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
        fontSize: '14px',
      },
    },
    subHeader: {
      style: {
        padding: 0,
        minHeight: '43px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '20px',
        paddingRight: '8px',
        fontSize: '14px',
        fontWeight: 600,
      },
    },
    cells: {
      style: {
        paddingLeft: '20px',
        paddingRight: '8px',
      },
    },
    pagination: {
      pageButtonsStyle: {
        fill: themeMode === 'main' ? 'white' : 'black',
        '&:disabled': {
          fill: themeMode === 'main' ? 'gray' : 'gray',
        },
        '&:hover:not(:disabled)': {
          backgroundColor: 'gray',
        },
      },
    },
  }
  const columns = [
    {
      name: 'No',
      selector: (row: any) => row.ID,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row: any) => row.userName,
      sortable: true,
    },
    {
      name: 'Domain Owned',
      selector: (row: any) => row.domainCount,
      sortable: true,
    },
    {
      name: '#bling',
      selector: (row: any) => row.bling,
      sortable: true,
    },
  ]

  const dummyData = [
    {
      ID: 1,
      userName: 'Aaron Ramsey',
      PlayerImage: 'https://resources.premierleague.com/premierleague/photos/players/40x40/p447715.png',
      domainCount: 22,
      bling: 23,
    },
    {
      ID: 2,
      userName: 'Alex Telles',
      PlayerImage: 'https://resources.premierleague.com/premierleague/photos/players/40x40/p152590.png',
      domainCount: 21,
      bling: 10,
    },
    {
      ID: 3,
      userName: 'Allan',
      PlayerImage: 'https://resources.premierleague.com/premierleague/photos/players/40x40/p119765.png',
      domainCount: 30,
      bling: 23,
    },
    {
      ID: 4,
      userName: 'Dele Alli',
      PlayerImage: 'https://resources.premierleague.com/premierleague/photos/players/40x40/p108823.png',
      domainCount: 3,
      bling: 27,
    },
  ]
  return (
    <div className={css.wraper}>
      <div>
        {/* <div className={css.header}>
          <div className={css.side}>
            <div className={css.row}>
              <button
                className={css.btnCopy}
                onClick={() => {
                  navigator.clipboard.writeText(ref)
                }}
              >
                <FaRegCopy />
              </button>
              {ref}
            </div>
            <div className={css.row}>
              <p>Total referrals - 0</p>
            </div>
          </div>
          <div className={css.side}>
            <div className={css.row}>
              <p>Total: 0 USD</p>
            </div>
            <div className={css.row}>
              <p>Yearly total: 0 USD</p>
            </div>
          </div>
        </div> */}
        <ReactTable
          title=""
          theme={themeMode === 'main' ? 'solarized' : 'solarized_light'}
          columns={columns}
          data={dummyData}
          defaultSortFieldId={'domainCount'}
          customStyles={customStyles}
          pagination
          highlightOnHover
        />
      </div>
    </div>
  )
}
