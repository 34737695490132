import { fork, select } from '@redux-saga/core/effects'
import { push } from 'connected-react-router'
import { Map } from 'immutable'
import { SagaIterator } from 'redux-saga'
import { all, apply, call, put, SagaReturnType } from 'redux-saga/effects'
import { AppState } from '../../../redusers/config'
import { modalQA, reloadHashagList, showToast, workerCheckedBuyDomen } from '../../../sagas/domain'
import { checkErorr, getCookie, getToken } from '../../../sagas/helper'
import { TItemR } from '../../../types'
import { getDiscount, hashtagAdd, userGetInfo, UserInfo } from '../../../utils/api'
import { errorsM, Response } from '../../../utils/restReq'
import { getLogin } from '../../../utils/utils'
import { TR_Cart } from '../reduser'
import { TAddItem, TCartItem, TDelItem } from '../types'

type TActionlistItems = TItemR<TR_Cart, 'cart_listItems'>

const getlistItems = ({ cart_reducer }: AppState): TR_Cart['cart_listItems'] => cart_reducer.get('cart_listItems')

export function* workerAddToCart({ payload }: TAddItem): SagaIterator<void> {
  try {
    const { id, isDemo, price, applyDiscount, type, keyword } = payload
    const listItems: SagaReturnType<typeof getlistItems> = yield select(getlistItems)
    let newlistItems = listItems ? listItems.set(id, payload) : Map([[id, payload]])
    if (applyDiscount) {
      newlistItems = yield call(workerСalculateDiscont, newlistItems)
    }

    const newlistItemsJSON = yield apply(JSON, JSON.stringify, [newlistItems.toJSON()])

    yield apply(sessionStorage, sessionStorage.setItem, ['cart_listItems', newlistItemsJSON])
    yield put<TActionlistItems>({
      type: 'cart_listItems',
      payload: newlistItems,
    })
    showToast(`Claimed ${id} successfully.`, 'success')
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export function* workerDelItemCart({ payload }: TDelItem) {
  try {
    const listItems: SagaReturnType<typeof getlistItems> = yield select(getlistItems)
    let newlistItems = listItems?.delete(payload)
    newlistItems = yield call(workerСalculateDiscont, newlistItems!)
    const newlistItemsJSON: string = yield apply(JSON, JSON.stringify, [newlistItems?.toJSON()])

    yield apply(sessionStorage, sessionStorage.setItem, ['cart_listItems', newlistItemsJSON])
    yield put<TActionlistItems>({
      type: 'cart_listItems',
      payload: newlistItems,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export function* worcerInitCart() {
  try {
    const jsonItems: SagaReturnType<typeof sessionStorage.getItem> = yield apply(
      sessionStorage,
      sessionStorage.getItem,
      ['cart_listItems']
    )

    if (jsonItems === null) return

    const objInit: Map<string, TCartItem> = yield apply(JSON, JSON.parse, [jsonItems])
    const newlistItems: Map<string, TCartItem> = yield call(workerСalculateDiscont, Map(objInit))
    yield put<TActionlistItems>({
      type: 'cart_listItems',
      payload: newlistItems,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export function* worcerPayForCart() {
  try {
    const isLogin: SagaReturnType<typeof getLogin> = yield call(getLogin)
    if (!isLogin) {
      if (getCookie('isAuth') === 'true') {
        yield put(push(`/login/`))
      } else {
        yield put(push(`/register/`))
      }

      return
    }

    const listItems: SagaReturnType<typeof getlistItems> = yield select(getlistItems)
    if (listItems === undefined) return

    const total = listItems.reduce<number>((r, v) => {
      const price = v.isDemo ? 0 : v.price
      return r + price
    }, 0)

    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    let gacode: string = ''
    if (total > 0) {
      const { enabled_ga }: UserInfo = yield call(userGetInfo, token)

      if (enabled_ga) {
        gacode = yield call(modalQA)
        if (gacode === '') return
      }
    }

    const params = listItems.toArray().map(([_, val]) => {
      return {
        id: val.id,
        url: null,
        demo: val.isDemo,
        price: val.price,
        gacode: gacode || null,
      }
    })
    const result: SagaReturnType<typeof hashtagAdd> = yield call(hashtagAdd, params, token)
    if (!Array.isArray(result)) {
      showToast('Unknown Error, Unknown data', 'error')
      return
    }
    const resData: Array<{ id: string; isErr: boolean; message: string }> = []
    result.forEach((item: Response<boolean>, i) => {
      if (item.error !== undefined) {
        const message = errorsM.get(item.error.code) || 'Unknown Error'
        showToast(`Error ${params[i].id}: ${message}`, 'error')
        resData.push({
          id: params[i].id,
          isErr: true,
          message: message,
        })
      } else resData.push({ id: params[i].id, isErr: false, message: '' })
    })

    const countError = resData.reduce((r, item) => {
      if (item.isErr) return ++r
      return r
    }, 0)

    if (countError === params.length) return

    if (result) {
      yield put(push(`/domainsdashboard/`))
    }
    yield all([
      put({ type: 'GET_BALANCE' }),
      put<TActionlistItems>({
        type: 'cart_listItems',
        payload: undefined,
      }),
    ])
    window.sessionStorage.removeItem('cart_listItems')
    yield fork(reloadHashagList)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export function* workerСalculateDiscont(val: Map<string, TCartItem>): SagaIterator<Map<string, TCartItem>> {
  try {
    const token = yield call(getToken)

    if (!token) {
      yield put(push(`/login/`))
      return val
    }
    //получить скидку может и не нужно каждый раз запрашивать...
    const discont: SagaReturnType<typeof getDiscount> = yield call(getDiscount, token)

    let amount = discont.amount
    const discount = discont.discount

    const newList = val.map((value, key) => {
      if (value.isDemo === true) {
        value.discount = 0
        return value
      }
      if (value.applyDiscount === false) {
        value.discount = 0
        return value
      }
      if (value.price === 0) {
        value.discount = 0
        return value
      }
      if (value.price > amount) {
        value.discount = 0
        return value
      }
      amount = amount - value.price
      value.discount = discount
      return value
    })

    return newList
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
    return val
  }
}
