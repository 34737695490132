import React from 'react'
import './cn.module.scss'
import { IoIosClose } from 'react-icons/io'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'

const modalRoot: Element = document.getElementById('root-modal') as Element

interface ModalP {
  onClose: (e: React.FormEvent<HTMLElement>) => void
  title: string
  messageText: string
  description?: string
}

const Modal: React.FC<ModalP> = ({ onClose, title, messageText, description }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-content-header">
          <div className="header-content">
            <p>{title}</p>
            <div className="modal-close" onClick={onClose}>
              <IoIosClose size={24} />
            </div>
          </div>
        </div>

        <div className="confirmMessage">
          <span className="messageText">{messageText}</span>
          <span className="description">{description}</span>
        </div>

        <div className="withdrawBtn">
          <button className="btn" onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  )
}

const timeout = { enter: 500, exit: 100 }

interface NotificationModalP {
  showModal: boolean
  onClose: (e: React.FormEvent<HTMLElement>) => void
  title: string
  messageText: string
  description?: string
}
const NotificationModal: React.FC<NotificationModalP> = ({ showModal, onClose, title, messageText, description }) => {
  return createPortal(
    <CSSTransition classNames="animated" in={showModal} unmountOnExit timeout={timeout}>
      <Modal onClose={onClose} title={title} messageText={messageText} description={description} />
    </CSSTransition>,
    modalRoot
  )
}

export default NotificationModal
