import React from 'react'
import cn from './cn.module.css'

interface IURL {
  url: string
  showAnimation?: boolean
  themeMode?: string
  size?: string
}

const MenuIcons: React.FC<IURL> = ({ url, showAnimation, themeMode, size }) => {
  const image = require('../../../assets/icons/' + url + '.svg')
  return (
    <div className={cn.menuIconContainer}>
      <img
        className={themeMode == 'light' ? cn.menuIcon_d : cn.menuIcon}
        src={image.default}
        alt=""
        style={{ width: size }}
      />
      {/* {showAnimation && <img className={cn.sparkEffect} src={SparkEffect} alt="sparking..." />} */}
    </div>
  )
}
export default MenuIcons
