import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import './styles.scss'
import { useCommonCheck } from 'hooks/useCommon'
const Planet = require('@stefftek/planet.js')

const IconWrapper = styled.div<{ size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  // height: ${({ size }) => (size ? size + 'px' : '32px')};
  // width: ${({ size }) => (size ? size + 'px' : '32px')};
`

interface IPlanetIcon {
  size: number
  seed: string
  themeMode?: string
}
const PlanetIcon: React.FC<IPlanetIcon> = ({ size, seed, themeMode }) => {
  const { hashCode, intToRGB } = useCommonCheck()
  const [imgURL, setImgURL] = useState<string>('')

  const drawPlanet = () => {
    const land_color = intToRGB(hashCode(seed + '$$$#'))
    const beach_color = intToRGB(hashCode(seed + '$$#$'))
    const shore_color = intToRGB(hashCode(seed + '$#$$'))
    const ocean_color = intToRGB(hashCode(seed + '#$$$'))
    const mountain_color = intToRGB(hashCode(seed + '##$$'))
    const crater_color = intToRGB(hashCode(seed + '###$'))
    const pole_color = intToRGB(hashCode(seed + '####'))

    const colors = {
      land_color: '#' + land_color, //Color of the Main Land//4cfa69
      beach_color: '#' + beach_color, //Color of the Beaches
      shore_color: '#' + shore_color, //Color of the Shores
      ocean_color: '#' + ocean_color, //Color of the Deep Ocean//0744a6
      mountain_color: '#' + mountain_color, //Color of the Mountains
      mountain_top_color: '#ffffff', //Color of the Mountain Top (e.g. Snow)
      crater_color: '#' + crater_color, //Main Color of Craters
      pole_color: '#' + pole_color, //Color of Poles (Ice)//BDDEEC
      cloud_color: '#ffffff', //Cloud Color
      atmosphere_color: '#94b3d9', //Atmosphere Color //4F7AAD
      cloud_opacity: 70, //Cloud Base Opacity
      atmosphere_opacity: 40, //Atmosphere Opacity/Density
      shading_level: 2, //Shading Level (Float 0-2, 2 = Maximum)
      add_detail: true, //Plain Map or a bit more detailed?
    }
    const planet_radius = size / 2 - (size * 10) / 100 //40
    const atmosphere_radius = size / 2 + (size * 10) / 100 //60
    const planet_options = {
      planet_radius: planet_radius, //==Planet Radius
      atmosphere_radius: atmosphere_radius, //==Atmosphere Radius
      cloud_radius: planet_radius, //==Cloud Radius
      sea_level: 0.42, // ALL LEVELS ARE VALUES BETWEEN 0 AND 1
      shore_level: 0.48,
      beach_level: 0.3,
      mountain_level: 0.62,
      mountain_top_level: 0.65,
      cloud_level: 0.62, // CLOUD LEVEL IS CUSTOM GENERATED AND NOT AFFECTED BY THE OTHER LEVELS
      pole_level: 0.5, //How big the Poles should be (Float 0-2, 2 = Full Coverage)
      craters: true, //Should Craters Spawn?
      clouds: true, //Should Clouds Spawn?
      atmosphere: true, //Should the Planet have an atmosphere
      poles: true, //Should the Planet have icy poles?
      hard_pole_lines: false, //Should the pole line be a hard or a soft cut?
    }

    const generator_options = {
      octaveCount: 5, //Perlin Noise Octave (How Often)
      amplitude: 10, //Perlin Noise Amp (How Big)
      persistence: 0.5, //Perlin Noise persistence (How Smooth, smaller number = smoother)
    }

    const cloud_generator = {
      octaveCount: 6,
      amplitude: 6,
      persistence: 0.5,
    }

    const image = Planet.generatePlanet(
      size,
      planet_options,
      colors,
      seed + '$$$$$',
      generator_options,
      cloud_generator
    )
    return image.toDataURL()
  }

  useEffect(() => {
    setImgURL(drawPlanet())
  }, [seed])

  return (
    <IconWrapper>
      {imgURL !== '' ? <img src={imgURL} className="blur" /> : <span className="load_icon"></span>}
    </IconWrapper>
  )
}

export default PlanetIcon
