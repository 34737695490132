import { ITokenP } from '../types'

export const mhcTokenList: ITokenP[] = [
  {
    chainId: 1,
    name: '#MetaHash',
    address: 'metahash',
    symbol: 'MHC',
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3756.png',
  },
]
