import React, { useCallback, useEffect, useRef, useState } from 'react'
import './styles.scss'
import { SettingItems, TAccountItem, TGetClaimListR, TGetTickerListR } from 'utils/api'
import { FiExternalLink } from 'react-icons/fi'
import { BsDot, BsGiftFill, BsGrid3X3GapFill } from 'react-icons/bs'
import { BiChevronsLeft, BiChevronsRight, BiDollar, BiHide, BiShow, BiVolumeFull, BiVolumeMute } from 'react-icons/bi'
import { AiFillWarning, AiOutlineDesktop, AiOutlineMinus, AiOutlinePlus, AiOutlineShoppingCart } from 'react-icons/ai'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import Menu from 'components/Menu'
import { FaRocket } from 'react-icons/fa'
import { useIsPlaySound } from 'state/playSound/hook'
import { setIsPlaySound } from 'state/playSound/reducer'
import { useGetDomainList, useGetLogList, useGetTransactionList } from 'views/Search/hooks/availableCategoryList'
import { MODAL_TYPES, useGlobalModalContext } from 'components/Modals/GlobalModal'
import { getAllSearchLogList } from 'views/Search/actions'
import { useGetThemeMode } from 'state/theme/hook'
import DarkLightToggleButton from 'components/Buttons/DarkLightToggleButton'
import SearchShowIcon from '../../../assets/icons-svg/search-show.svg'
import SearchHideIcon from '../../../assets/icons-svg/search-hide.svg'
import HeaderTopSearch from '../HeaderTopSearch'
import MyCart from 'views/Cart'
// import Marquee from 'react-fast-marquee'
import Marquee from 'react-easy-marquee'
import { onGetSettings } from 'actions/hashtag'
import { CHROME_EXTENSION_ID, CHROME_EXTENSION_ID_MAC } from 'constants/urls'
import { useCommonCheck } from 'hooks/useCommon'

const HeaderTop: React.FC<any> = () => {
  const { domainList, dropDomainList, accountList, soldDomainList } = useGetDomainList()
  const { transactionList } = useGetTransactionList()
  const giftDomainList = transactionList.filter((v: any) => v.product === 'GIFT_DOMAIN' && v.status === 'SUCCESS')
  const countCart = useSelector<AppState, number>(({ cart_reducer }) => cart_reducer.get('cart_listItems')?.size!)
  const settings = useSelector<AppState, SettingItems>(({ actions }) => actions.get('settings'))

  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const { showModal } = useGlobalModalContext()
  const { searchLogList } = useGetLogList()
  const { isChromeBrowser } = useCommonCheck()
  const isPlaySound = useIsPlaySound()
  const dotNumArray = [...Array(12).keys()]

  const [isExpand, setIsExpand] = useState<boolean>(true)
  const [showCart, setShowCart] = useState<boolean>(false)
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [isShowSearch, setIsShowSearch] = useState<boolean>(true)
  const [installPlugin, setInstallPlugin] = useState<boolean>(false)

  const [logData, setLogData] = useState<TGetTickerListR[]>([])
  const [claimData, setClaimData] = useState<TGetTickerListR[]>([])
  const [giftData, setGiftData] = useState<TGetTickerListR[]>([])
  const [resoldData, setResoldData] = useState<TGetTickerListR[]>([])
  const [dropData, setDropData] = useState<TGetTickerListR[]>([])

  const [marqueeSpeed, setMarqueeSpeed] = useState<number>(0)
  // const [domainPrice, setDomainPrice] = useState<number>(0)

  const getDateDiff = (targetDate: string) => {
    const difference = new Date().getTime() - new Date(targetDate).getTime()
    const years = Math.floor(difference / (1000 * 60 * 60 * 24) / 365.25)
    const months = Math.floor(difference / (1000 * 60 * 60 * 24) / (4 * 7))
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    let hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((difference / 1000 / 60) % 60)
    // const seconds = Math.floor((difference / 1000) % 60)
    const seconds = new Date().getUTCSeconds()

    const tz = new Date().getTimezoneOffset() / 60
    const addTZ = tz - 4
    hours = hours + addTZ

    if (years > 0) return years + ' year'
    else if (months > 0) return months + ' month'
    else if (days > 0) return days + ' day'
    else if (hours > 0) return hours + ' hour'
    else if (minutes > 0) return minutes + ' min'
    else return seconds + ' sec'
  }

  const onMuteTotalSound = () => {
    localStorage.setItem('soundKiller_total', '' + !isPlaySound)
    dispatch(setIsPlaySound(!isPlaySound))
  }

  const onShowSearchbox = () => {
    setIsShowSearch(!isShowSearch)
  }

  const onShowCart = useCallback(() => {
    setShowCart(!showCart)
    if (showMenu) setShowMenu(false)
  }, [showCart, showMenu])

  const onShowMenu = useCallback(() => {
    setShowMenu(!showMenu)
    if (showCart) setShowCart(false)
  }, [showCart, showMenu])

  const onExpand = () => {
    setIsExpand(!isExpand)
  }

  const onTargetUrl = useCallback(
    (url: string, domainN: string, nick: string, name: string, fav: string, userId: number) => {
      if (url.includes(',')) {
        showModal(MODAL_TYPES.TARGET_URL_MODAL, {
          url,
          domainN,
          nick,
          name,
          fav,
          userId,
        })
      } else {
        window.open(url, '_blank')
      }
    },
    [showModal]
  )

  const scrollRef = useRef<HTMLDivElement>(null)
  // const onMoveScroll = useCallback((flag: string) => {
  //   if (flag == 'left') {
  //     if (scrollRef.current) {
  //       scrollRef.current.scrollTo(scrollRef.current.scrollLeft + scrollRef.current.offsetWidth, 0)
  //     }
  //   } else {
  //     if (scrollRef.current) {
  //       scrollRef.current.scrollTo(scrollRef.current.scrollLeft - scrollRef.current.offsetWidth, 0)
  //     }
  //   }
  // }, [])

  const postChromeExtensionMessage = (msg: string, rToken: string) => {
    if (!isChromeBrowser()) return

    if (chrome.runtime) {
      let extensionId = CHROME_EXTENSION_ID
      const platform = navigator.platform.toLowerCase()
      if (!platform.includes('win')) extensionId = CHROME_EXTENSION_ID_MAC

      chrome.runtime.sendMessage(extensionId, { login: msg, rToken: rToken }, function (response) {
        if (!response) {
          console.log('not installed it')
          setInstallPlugin(false)
        } else {
          setInstallPlugin(true)
        }
      })
    }
  }

  const openWebStore = () => {
    let extensionId = CHROME_EXTENSION_ID
    const platform = navigator.platform.toLowerCase()
    if (!platform.includes('win')) extensionId = CHROME_EXTENSION_ID_MAC

    const url = 'https://chrome.google.com/webstore/detail/' + extensionId
    window.open(url, '_blank')
  }

  useEffect(() => {
    // searchLogList
    let log_data: TGetTickerListR[] = []
    searchLogList.map((v) => {
      domainList.map((item) => {
        if (v.hashtag === item.domainName && item.url != null && item.url != '') {
          const diff = getDateDiff(v.date)
          const arr: TAccountItem[] = accountList.filter((vv: TAccountItem) => vv.id === item.userId)
          log_data.push({
            domainName: item.domainName,
            url: item.url,
            favorites: item.favorites,
            wishes: item.wishes,
            nick: arr[0] ? arr[0].nick : '',
            name: arr[0] ? arr[0].first_name + ',' + arr[0].last_name : '',
            userId: item.userId,
            price: item.price,
            date: diff,
          })
        }
      })
    })

    // claimData
    let claim_data: TGetTickerListR[] = []
    const filterDomainlist = domainList.sort((a, b) => +new Date(b.date) - +new Date(a.date))
    filterDomainlist.map((item) => {
      const arr: TAccountItem[] = accountList.filter((vv: TAccountItem) => vv.id === item.userId)
      claim_data.push({
        domainName: item.domainName,
        url: item.url != null && item.url != '' ? item.url : '',
        favorites: item.favorites,
        wishes: item.wishes,
        nick: arr[0] ? arr[0].nick : '',
        name: arr[0] ? arr[0].first_name + ',' + arr[0].last_name : '',
        userId: item.userId,
        price: item.price,
        date: '',
      })
    })

    // dropData
    let drop_data: TGetTickerListR[] = []
    const dropFData = dropDomainList.filter((v: TGetClaimListR) => new Date(v.expire).getTime() < new Date().getTime())
    dropFData.sort((a, b) => +new Date(a.date) - +new Date(b.date))
    dropFData.map((tt) => {
      domainList.map((item) => {
        if (tt.id == item.id && item.url != null && item.url != '') {
          const arr: TAccountItem[] = accountList.filter((vv: TAccountItem) => vv.id === item.userId)
          drop_data.push({
            domainName: item.domainName,
            url: item.url,
            favorites: item.favorites,
            wishes: item.wishes,
            nick: arr[0] ? arr[0].nick : '',
            name: arr[0] ? arr[0].first_name + ',' + arr[0].last_name : '',
            userId: item.userId,
            price: item.price,
            date: '',
          })
        }
      })
    })

    // giftData
    let gift_data: TGetTickerListR[] = []
    const giftList = giftDomainList.sort((a, b) => +new Date(b.date) - +new Date(a.date))
    const uniqueIds: any[] = []
    giftList.map((tt) => {
      domainList.map((item) => {
        if (item.domainName === tt.description) {
          const isDuplicate = uniqueIds.includes(item.domainName)
          if (!isDuplicate) {
            uniqueIds.push(item.domainName)
            const arr: TAccountItem[] = accountList.filter((vv: TAccountItem) => vv.id === item.userId)
            gift_data.push({
              domainName: item.domainName,
              url: item.url != null && item.url != '' ? item.url : '',
              favorites: item.favorites,
              wishes: item.wishes,
              nick: arr[0] ? arr[0].nick : '',
              name: arr[0] ? arr[0].first_name + ',' + arr[0].last_name : '',
              userId: item.userId,
              price: item.price,
              date: '',
            })
          }
        }
      })
    })

    // resoldData
    let resold_data: TGetTickerListR[] = []
    soldDomainList.map((tt) => {
      if (Number(tt.price) > 0) {
        domainList.map((item) => {
          if (item.domainName === tt.hashtag) {
            const isDuplicate = uniqueIds.includes(item.domainName)
            if (!isDuplicate) {
              uniqueIds.push(item.domainName)
              const arr: TAccountItem[] = accountList.filter((vv: TAccountItem) => vv.id === item.userId)
              resold_data.push({
                domainName: item.domainName,
                url: item.url != null && item.url != '' ? item.url : '',
                favorites: item.favorites,
                wishes: item.wishes,
                nick: arr[0] ? arr[0].nick : '',
                name: arr[0] ? arr[0].first_name + ',' + arr[0].last_name : '',
                userId: item.userId,
                price: item.price,
                date: '',
              })
            }
          }
        })
      }
    })

    let totalNum = claim_data.length + drop_data.length + gift_data.length + resold_data.length
    if (totalNum > 80) {
      claim_data = claim_data.slice(0, 20)
      drop_data = drop_data.slice(0, 20)
      gift_data = gift_data.slice(0, 20)
      resold_data = resold_data.slice(0, 20)
      totalNum = claim_data.length + drop_data.length + gift_data.length + resold_data.length
    }
    const num = Math.ceil(10000 * totalNum)
    setMarqueeSpeed(num)

    setLogData(log_data)
    setClaimData(claim_data)
    setDropData(drop_data)
    setGiftData(gift_data)
    setResoldData(resold_data)
  }, [domainList, searchLogList, accountList])

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem('soundKiller_total', '' + false)
      dispatch(setIsPlaySound(false))
    }, 1000)
  }, [dispatch, settings])

  useEffect(() => {
    if (countCart > 0) setShowCart(true)

    const interval = setInterval(() => {
      dispatch(getAllSearchLogList())
      postChromeExtensionMessage('install', 'extension')
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [countCart, dispatch])

  return (
    <div className="topBarContainer">
      <div className={'line-top'} style={{ height: isExpand ? '24px' : '0px' }}>
        <Marquee pauseOnHover={true} duration={marqueeSpeed} reverse={true}>
          {claimData.map((item, inx) => {
            const targetUrl = item.url
            return (
              <div key={inx} className="item-sold text-wrap">
                <span className="marginR">
                  <BiDollar size={15} color={themeMode === 'light' ? '#02fa02' : '#02fa02'} />
                </span>
                <span className="sold-domain">
                  {item.domainName}
                  {item.domainName && <span className="v-line">-</span>}
                </span>
                <span>
                  {item.nick}
                  {item.nick && <span className="v-line">-</span>}
                </span>
                <span>
                  {targetUrl.length > 20 ? targetUrl.substring(0, 20) + '...' : targetUrl}
                  {targetUrl && <span className="v-line">-</span>}
                </span>
                <span>{`$${item.price}`}</span>
                {targetUrl.length > 0 && (
                  <span
                    className="marginL headerIcon"
                    onClick={() =>
                      onTargetUrl(targetUrl, item.domainName, item.nick, item.name, item.favorites, item.userId)
                    }
                  >
                    <FiExternalLink size={17} color={themeMode === 'light' ? '#3aadfa' : '#3aadfa'} />
                  </span>
                )}
                <span className="h-line">|</span>
              </div>
            )
          })}
          {dropData.map((item, inx) => {
            const targetUrl = item.url
            return (
              <div key={inx} className="item-sold text-wrap">
                <span className="marginR">
                  <AiFillWarning size={15} color={themeMode === 'light' ? '#ff0000' : '#ff0000'} />
                </span>
                <span className="expired-domain">
                  {item.domainName}
                  {item.domainName && <span className="v-line">-</span>}
                </span>
                <span>
                  {item.nick}
                  {item.nick && <span className="v-line">-</span>}
                </span>
                <span>
                  {targetUrl.length > 20 ? targetUrl.substring(0, 20) + '...' : targetUrl}
                  {targetUrl && <span className="v-line">-</span>}
                </span>
                <span>{'Expired'}</span>
                {targetUrl.length > 0 && (
                  <span
                    className="marginL headerIcon"
                    onClick={() =>
                      onTargetUrl(targetUrl, item.domainName, item.nick, item.name, item.favorites, item.userId)
                    }
                  >
                    <FiExternalLink size={17} color={themeMode === 'light' ? '#3aadfa' : '#3aadfa'} />
                  </span>
                )}
                <span className="h-line">|</span>
              </div>
            )
          })}
          {resoldData.map((item, inx) => {
            const targetUrl = item.url
            return (
              <div key={inx} className="item-sold text-wrap">
                <span className="marginR">
                  <AiOutlineDesktop size={15} color={themeMode === 'light' ? '#ffae00' : '#ffae00'} />
                </span>
                <span className="resold-domain">
                  {item.domainName}
                  {item.domainName && <span className="v-line">-</span>}
                </span>
                <span>
                  {`$${item.price}`}
                  <span className="v-line">-</span>
                </span>
                <span>{'Resold'}</span>
                {targetUrl.length > 0 && (
                  <span
                    className="marginL headerIcon"
                    onClick={() =>
                      onTargetUrl(targetUrl, item.domainName, item.nick, item.name, item.favorites, item.userId)
                    }
                  >
                    <FiExternalLink size={17} color={themeMode === 'light' ? '#3aadfa' : '#3aadfa'} />
                  </span>
                )}
                <span className="h-line">|</span>
              </div>
            )
          })}
          {giftData.map((item, inx) => {
            const targetUrl = item.url
            return (
              <div key={inx} className="item-sold text-wrap">
                <span className="marginR">
                  <BsGiftFill size={15} color={themeMode === 'light' ? '#ff00ff' : '#ff00ff'} />
                </span>
                <span className="gift-domain">
                  {item.domainName}
                  {item.domainName && <span className="v-line">-</span>}
                </span>
                <span>
                  {item.nick}
                  {item.nick && <span className="v-line">-</span>}
                </span>
                <span>
                  {targetUrl.length > 20 ? targetUrl.substring(0, 20) + '...' : targetUrl}
                  {targetUrl && <span className="v-line">-</span>}
                </span>
                <span>{'Gift'}</span>
                {targetUrl.length > 0 && (
                  <span
                    className="marginL headerIcon"
                    onClick={() =>
                      onTargetUrl(targetUrl, item.domainName, item.nick, item.name, item.favorites, item.userId)
                    }
                  >
                    <FiExternalLink size={17} color={themeMode === 'light' ? '#3aadfa' : '#3aadfa'} />
                  </span>
                )}
                <span className="h-line">|</span>
              </div>
            )
          })}
        </Marquee>
      </div>
      <div
        className="line-middle"
        style={{
          background:
            themeMode == 'main'
              ? 'linear-gradient(#4a4a4a, #1e1e1e, #000000, #383838, #606060)'
              : 'linear-gradient(#f0efef, #ffffff, #ffffff, #dad8d8, #acabab)',
        }}
      >
        <span className="middle-left">
          <input type="range" min="1" max="100" value="100" readOnly className="slider" id="myRange" />
        </span>
        <span className="middle-center">
          {dotNumArray.map((item) => {
            return (
              <span key={item}>
                <BsDot size={22} color={'gray'} />
              </span>
            )
          })}
        </span>
        <span className="middle-right">
          <input type="range" min="1" max="100" value="1" readOnly className="slider" id="myRange" />
        </span>
      </div>
      <div
        className="line-bottom"
        style={{
          background:
            themeMode === 'main'
              ? 'linear-gradient(#000, #181818, #222222, #3b3b3b, #222222, #181818, #000)'
              : 'linear-gradient(#bdbcbc, #f3f3f3, #fff, #fff, #fff, #f3f3f3, #bdbcbc)',
        }}
      >
        <div className="item-left">
          <span className="h-line headerIcon icon-expand" onClick={onExpand}>
            {isExpand ? <AiOutlineMinus size={16} color={'gray'} /> : <AiOutlinePlus size={16} color={'gray'} />}
          </span>
          <span className="h-line headerIcon icon-sound" onClick={onMuteTotalSound}>
            {isPlaySound ? <BiVolumeFull size={16} color={'gray'} /> : <BiVolumeMute size={16} color={'gray'} />}
          </span>
          <span className="h-line headerIcon icon-search" onClick={onShowSearchbox}>
            {isShowSearch ? <img src={SearchHideIcon} width="16" /> : <img src={SearchShowIcon} width="16" />}
          </span>
        </div>
        <div className="item-center" ref={scrollRef}>
          {isShowSearch && <HeaderTopSearch />}
          <div className="item-log">
            {logData.map((item, inx) => {
              const targetUrl = item.url
              return (
                <div key={inx} className="bottom-item-div text-wrap">
                  <span className="marginRR">
                    <FaRocket size={13} color={themeMode === 'main' ? '#ffa2f3' : '#0077ff'} />
                  </span>
                  <span
                    className="bottom-domain text-wrap"
                    style={{
                      color: themeMode == 'main' ? '#ffa2f3' : '',
                    }}
                  >
                    {item.domainName}
                  </span>
                  <span
                    className="v-line"
                    style={{
                      color: themeMode == 'main' ? '#d3cfcf' : '',
                    }}
                  >
                    -
                  </span>
                  <span
                    style={{
                      color: themeMode == 'main' ? '#d3cfcf' : '',
                    }}
                  >
                    {targetUrl.length > 20 ? targetUrl.substring(0, 20) + '...' : targetUrl}
                  </span>
                  {targetUrl.length > 0 && (
                    <>
                      <span
                        className="marginLL headerIcon"
                        onClick={() =>
                          onTargetUrl(targetUrl, item.domainName, item.nick, item.name, item.favorites, item.userId)
                        }
                      >
                        <FiExternalLink size={17} color={themeMode === 'main' ? '#d3cfcf' : '#0077ff'} />
                      </span>
                      <span
                        className="v-line"
                        style={{
                          color: themeMode == 'main' ? '#d3cfcf' : '',
                        }}
                      >
                        -
                      </span>
                    </>
                  )}
                  <div
                    className="text-wrap"
                    style={{
                      color: themeMode == 'main' ? '#d3cfcf' : '',
                    }}
                  >
                    {item.date} ago
                  </div>
                  <span
                    className="h-line"
                    style={{
                      color: themeMode == 'main' ? '#d3cfcf' : '',
                    }}
                  >
                    |
                  </span>
                </div>
              )
            })}
          </div>
        </div>
        {!installPlugin && (
          <div
            className="item-plugin"
            style={{
              textShadow: themeMode === 'main' ? '2px 2px 1px #0077ff' : '2px 2px 1px #9384a3',
              borderColor: themeMode === 'main' ? 'white' : 'black',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
            onClick={openWebStore}
          >
            <BiChevronsRight className="marginR" size={18} />
            New Browser Plugin Available!
            <BiChevronsLeft className="marginL" size={18} />
          </div>
        )}
        <div className="item-right">
          <div className="h-line topIconCart icon-cart" onClick={onShowCart}>
            <AiOutlineShoppingCart fontSize={16} className="headerIcon" />
            {countCart > 0 ? <span className="topCart">{countCart}</span> : null}
            {showCart && (
              <div className="myCartDropdown">
                <MyCart />
              </div>
            )}
          </div>
          <div className="h-line icon-dark">
            <DarkLightToggleButton />
          </div>
          <div className="icon-menu">
            <span className="h-line headerIcon" onClick={onShowMenu}>
              <BsGrid3X3GapFill size={16} color={'gray'} />
            </span>
            {showMenu && (
              <div className="headerTopAccountDropdown">
                <Menu dropdownLeft={true} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default HeaderTop
