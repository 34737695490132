import React, { useState } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import GameBlingImg from '../../assets/command/gamebling.png'
import GameBlingLImg from '../../assets/command/gamebling-l.png'
import DecentralTxtImg from '../../assets/command/decentral-txt.png'
import './styles.scss'

const Container = styled.div<{}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 300px;
  font-size: 14px;
  position: relative;
  margin-right: -5px;
  color: #ffffff;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
  }
  @media screen and (max-width: 1024px) {
    margin: -5px 0 0 0;
    width: 104%;
  }
`
const CommandHolo = () => {
  const themeMode = useGetThemeMode()

  return (
    <Container style={{ color: themeMode === 'main' ? 'white' : 'black' }}>
      <img src={themeMode === 'main' ? GameBlingImg : GameBlingLImg} className="background-img" alt="" />
      <div className="holo-wrapper__main">
        <div className="left-holo">
          <a href={'#'} target="_self" style={{ color: themeMode === 'main' ? 'white' : 'black' }}>
            <FiExternalLink size={20} />
          </a>
        </div>
        <h4 className="game-txt">GAMEBLING:</h4>
        <img src={DecentralTxtImg} className="holo-txt" alt="" />
        <div style={{ fontSize: '12px' }}>Earn real money per kill using #bling</div>
        <button
          className="button-lay"
          style={{
            background: themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
            color: themeMode == 'main' ? 'white' : 'black',
            marginTop: '35px',
          }}
        >
          Learn More
        </button>

        <div className="left-holo">
          {/* <a href={'#'} target="_self" style={{ color: themeMode === 'main' ? 'white' : 'black' }}>
          <FiExternalLink size={20} />
        </a> */}
          <span className="shadow-blue">COMING 2023</span>
        </div>
      </div>
    </Container>
  )
}
export default CommandHolo
