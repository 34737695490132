import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetThemeMode } from 'state/theme/hook'
import { useGlobalModalContext } from '../GlobalModal'
import TwoBtnModal from '../TwoBtnModal'
import { DefaultEditor } from 'react-simple-wysiwyg'
import { onSendEmail } from 'actions/user'
import { AppState } from 'redusers/config'
import { Init as UserInit } from 'redusers/user'
import './styles.scss'
import { useCommonCheck } from 'hooks/useCommon'

const EmailSendModal: React.FC = () => {
  const dispatch = useDispatch()
  const { hideModal } = useGlobalModalContext()
  const { validateEmail, showToastMsg } = useCommonCheck()
  const userInfo = useSelector<AppState, UserInit['userInfo']>(({ user }) => user.get('userInfo'))

  const [body, setBody] = React.useState('')
  const [to, setTo] = React.useState('')
  const [title, setTitle] = React.useState('')

  const onBodyChange = (e: any) => {
    setBody(e.target.value)
  }

  const onToChange = (e: any) => {
    setTo(e.target.value)
  }

  const onTitleChange = (e: any) => {
    setTitle(e.target.value)
  }

  const confirmSending = () => {
    const from = userInfo.email
    if (from !== '' && to !== '' && title !== '' && body !== '') {
      if (validateEmail(from) && validateEmail(to)) {
        dispatch(onSendEmail({ from, to, title, body }))
        hideModal()
      } else {
        showToastMsg('Invalid email.', 'info')
      }
    } else {
      showToastMsg('Should not be empty fields.', 'info')
    }
  }

  const themeMode = useGetThemeMode()

  return (
    <TwoBtnModal title={''} onClose={hideModal} confirm={confirmSending} okBtn={'Send'} themeMode={themeMode}>
      <div className="emailsend-container">
        <input type="email" className="to-email" placeholder="To" value={to} onChange={onToChange} />
        <input type="text" className="email-title" placeholder="Add a subject" value={title} onChange={onTitleChange} />
        <DefaultEditor value={body} styles={{ editor: { minHeight: '300px' } }} onChange={onBodyChange} />
      </div>
    </TwoBtnModal>
  )
}

export default EmailSendModal
