import { TUpdateMarketSpaceList } from 'views/MarketSpace/types'

export function getMarketSpaceList() {
  return {
    type: 'MARKETSPACE_GET_LIST',
  }
}

export function updateMarketSpaceDetail(payload: TUpdateMarketSpaceList['payload']) {
  return {
    type: 'MARKETSPACE_UPDATE_DETAIL',
    payload,
  }
}
