import React from 'react'
import cn from './cn.module.scss'
import connectors from './config'
import WalletWrapper from './WalletWrapper'
import { Config } from './config'
import { INetwork } from '../../types'

interface IWalletList {
  selectedNetwork: INetwork
  selectedWallet: (val: Config) => void
  setIsOpenWalletList: (val: boolean) => void
}
const WalletList: React.FC<IWalletList> = ({ selectedNetwork, selectedWallet, setIsOpenWalletList }) => {
  const onSelectWallet = (wallet: Config) => () => {
    selectedWallet(wallet)
    setIsOpenWalletList(false)
  }

  const availableWalletList = connectors.filter((item) => item.isMetaHash === (selectedNetwork.name === 'MetaHash'))

  return (
    <div className={cn.walletListWrapper}>
      {availableWalletList.map((item) => {
        return <WalletWrapper key={item.title} wallet={item} selectWallet={onSelectWallet(item)} />
      })}
    </div>
  )
}

export default WalletList
