import React, { FC } from 'react'
import Article from '../Article'
import useArticle from '../Article/useArticle'

const AddArticle: FC = () => {
  const { title, description, error, memoizedSetTitle, memoizedDescription, memoizedSubmit } = useArticle()

  return (
    <Article
      title={title}
      description={description}
      error={error}
      memoizedSetTitle={memoizedSetTitle}
      memoizedDescription={memoizedDescription}
      memoizedSubmit={memoizedSubmit}
    />
  )
}

export default AddArticle
