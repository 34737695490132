import { SOCIAL_URL } from 'constants/urls'
import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import MainContent from '../../components/MainContent'

function ViewSocialProfile() {
  const match = useRouteMatch<{ userID: string }>('/viewprofile/:userID')
  useEffect(() => {
    if (match === null) {
      return
    }
    if (match.params.hasOwnProperty('userID') === false) {
      return
    }
    // window.location.href = SOCIAL_URL + match.params.userID
    window.open(SOCIAL_URL + match.params.userID, '_blank')
  }, [match])

  return <MainContent> </MainContent>
}

export default ViewSocialProfile
