import { bookHashtag } from 'actions/hashtag'
import { AutoSearchComplete } from 'components/AutoSearchComplete'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetThemeMode } from 'state/theme/hook'
import { IGetAllDomainListR, TGetHashtagListR, TGetKeywordListR } from 'utils/api'
import { useSearchEngineContext } from 'views/Search/contexts/SearchEngineContext'
import { generateCategoryList, pointedOnlyDomainList } from 'views/Search/utils'
import './styles.scss'
import {
  BsArrowRightShort,
  BsBookmark,
  BsCheck2Circle,
  BsChevronDown,
  BsDisplay,
  BsSearch,
  BsTag,
} from 'react-icons/bs'
import { MdAddShoppingCart } from 'react-icons/md'
import { GetDomainList } from 'views/Search/hooks/availableCategoryList'
import { getAllDomainList } from 'views/Search/actions'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import { useCommonCheck } from 'hooks/useCommon'
import { InputCredit, InputItem, InputTarget } from 'components/AutoNickComplete/styles'
import { AutoDomainName } from 'components/AutoDomainName'
import { AudioItem, placeTxt, tooltipSearch } from 'components/Menu/tooltip'
import { SOCIAL_URL } from 'constants/urls'
import { useHistory } from 'react-router-dom'

const SearchForm: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const { postChromeExtensionMessage } = useCommonCheck()

  const [request, setRequest] = useState<string>('')
  const [tag, setTag] = useState<string>('#')
  const [isError, setIsError] = useState<boolean>(false)
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [symbol, setSymbol] = useState('#')
  const [domainVal, setDomainVal] = useState<string>('')
  const [nameVal, setNameVal] = useState<string>('')
  const [targetUrl, setTargetUrl] = useState<string>('')
  const { domainList, dataList, keywordList, userDomainList, isDomain, isContainSpace } = GetDomainList()
  const { setDomainList } = useSearchEngineContext()
  const { showToastMsg } = useCommonCheck()

  const isLogIn = useSelector<AppState, Init['isLogin']>(({ user }) => user.get('isLogin'))
  useEffect(() => {
    dispatch(getAllDomainList())
  }, [dispatch])

  useEffect(() => {
    if (request.length < 3 && request !== '') {
      setIsError(true)
    }
    setIsError(false)
  }, [request])

  const onSearchProcess = (flag: string) => {
    if (flag == 'profile') {
      if (nameVal.length > 3) {
        const profileVal = isDomain(nameVal) ? nameVal.substring(1) : nameVal
        const targetUrl = SOCIAL_URL + profileVal
        window.open(targetUrl, '_blank')
      } else {
        showToastMsg('Please enter profile name', 'info')
      }
    }
  }

  const buttonObj = (flag: string) => {
    return (
      <React.Fragment>
        {flag == 'buy' && (
          <button
            type="submit"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#60fb12, #41ae0a)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span style={{ marginRight: '10px' }}>BUY</span>
              <MdAddShoppingCart size={20} />
            </span>
          </button>
        )}
        {flag == 'go' && (
          <button
            type="submit"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#fbb212, #f48f33)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span style={{ marginRight: '10px' }}>GO</span>
              <BsArrowRightShort size={25} />
            </span>
          </button>
        )}
        {flag == 'search' && (
          <button
            type="submit"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#9a12fb, #5812fb)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span style={{ marginRight: '10px' }}>EXPLORE</span>
              <BsSearch size={15} />
            </span>
          </button>
        )}
        {flag == 'visit' && (
          <button
            type="submit"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#fbb212, #f48f33)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span style={{ marginRight: '10px' }}>VISIT</span>
            </span>
          </button>
        )}
        {flag == 'dream' && (
          <button
            type="button"
            onClick={() => onSearchProcess(flag)}
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#fc3e3e, #aa0b0b)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span style={{ marginRight: '10px' }}>BROWSE</span>
              <div className="live-icon">
                <BsDisplay size={18} />
                <span style={{ fontSize: '10px' }}>LIVE</span>
              </div>
            </span>
          </button>
        )}
        {flag == 'claim' && (
          <button
            type="button"
            onClick={() => onSearchProcess(flag)}
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#ce39f3, #80069e)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <div className="claimed-btn">
                <span>CLAIMED</span>
                <span className="recently-txt">RECENTLY</span>
              </div>
              <BsTag style={{ marginRight: '10px' }} size={22} />
            </span>
          </button>
        )}
        {flag == 'drop' && (
          <button
            type="button"
            onClick={() => onSearchProcess(flag)}
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#f78558, #d84409)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <div className="claimed-btn">
                <span>DROPPED</span>
                <span className="recently-drop-txt">RECENTLY</span>
              </div>
              <BsTag style={{ marginRight: '10px' }} size={22} />
            </span>
          </button>
        )}
        {flag == 'target-url' && (
          <button
            type="button"
            className="btnTag"
            onClick={() => onSearchProcess(flag)}
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#58c5f7, #0f88c0)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
              width: '170px',
            }}
          >
            <span className="search-btn">
              <BsBookmark style={{ marginLeft: '10px' }} size={18} />
              <div className="confirm-btn">
                <span>CONFIRM</span>
                <span className="bookmark-txt">bookmark</span>
              </div>
            </span>
          </button>
        )}
        {flag == 'profile' && (
          <button
            type="button"
            onClick={() => onSearchProcess(flag)}
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#fbb212, #af7b09)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span>VISIT</span>
            </span>
          </button>
        )}
        {flag == 'earn' && (
          <button
            type="button"
            onClick={() => onSearchProcess(flag)}
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#58f76d, #09961c)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <div className="claimed-btn">
                <span>$$$</span>
              </div>
              <BsCheck2Circle style={{ marginRight: '10px' }} size={20} />
            </span>
          </button>
        )}
        {flag == 'send-credit' && (
          <button
            type="button"
            onClick={() => onSearchProcess(flag)}
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#4297dd, #03569c)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span style={{ marginRight: '10px' }}>SEND</span>
            </span>
          </button>
        )}
        {flag == 'coming soon' && (
          <button
            type="button"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#58f76d, #09961c)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <div className="claimed-btn">
                <span>Coming</span>
              </div>
              <BsCheck2Circle style={{ marginRight: '10px' }} size={20} />
            </span>
          </button>
        )}
      </React.Fragment>
    )
  }

  const buttonChange = (valu: string) => {
    const val = tag + valu
    let data
    if (isContainSpace(valu)) {
      data = keywordList.filter((v: TGetKeywordListR) =>
        v.keyword.toLowerCase().split(',').includes(request.toLowerCase())
      )
      return buttonObj('search')
    } else {
      data = dataList.filter((v: TGetKeywordListR) => v.domainName.toLowerCase() === val.toLowerCase())
      if (data && data.length > 0) {
        if (data[0].url != '' && data[0].url !== 'unknown') {
          return buttonObj('go')
        } else if (data[0].resalePrice > 0) {
          return buttonObj('buy')
        }
      } else {
        if (valu.length >= 3) {
          if (valu.startsWith('http://') || valu.startsWith('https://')) {
            return buttonObj('go')
          } else {
            return buttonObj('buy')
          }
        }
      }
    }
    return buttonObj('search')
  }
  const onChangeNameVal = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setNameVal(value)
  }

  const searchKeyword = async (list: TGetKeywordListR[]) => {
    let searchKey = request
    if (isDomain(request)) {
      searchKey = request.substring(1)
    }

    if (list != null && list !== undefined) {
      if (list.length > 0) {
        const targetUrl = list[0].url
        const tUrl = targetUrl.split(',')[0]
        postChromeExtensionMessage('search', searchKey)
        window.open(tUrl, '_blank')
        AudioItem('btn-go').play()
        return
      }
    }

    // const categoryList = generateCategoryList(searchKey)
    // if (categoryList.length > 0) {
    //   dispatch(checkExists(categoryList))
    // }

    // const { domainSearchResults } = await pointedOnlyDomainList(searchKey, domainList)
    // setDomainList(domainSearchResults)
    // setIsError(false)
  }

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      // if (request.length >= 3) {
      setRequest(request)
      setShowDropdown(false)

      let list
      if (isContainSpace(request)) {
        list = keywordList.filter((v: TGetKeywordListR) =>
          v.keyword.toLowerCase().split(',').includes(request.toLowerCase().trim())
        )
      } else {
        list = dataList.filter((v: TGetKeywordListR) => v.domainName.toLowerCase() === tag + request.toLowerCase())
      }

      if (list.length > 0) {
        if (isContainSpace(request)) {
          searchKeyword(list)
        } else {
          const targetUrl = list[0].url
          if (targetUrl && targetUrl !== null && targetUrl !== '') {
            const tUrl = targetUrl.split(',')[0]
            window.open(tUrl, '_blank')
          } else {
            if (isLogIn) searchKeyword(list)
            else showToastMsg('You must login or signup for this function.', 'info')
          }
        }
      } else {
        if (isContainSpace(request)) {
          if (request.endsWith(' ')) {
          } else if (request.substring(request.lastIndexOf(' ') + 1).length >= 3) {
            if (isLogIn) searchKeyword(list)
            else showToastMsg('You must login or signup for this function.', 'info')
          }
        } else {
          if (request.length >= 3) {
            if (isLogIn) dispatch(bookHashtag(tag + request))
            else {
              const hashtag = tag + request
              history.push(`/buy${hashtag}`)
            }
          }
        }
      }
    },
    [request, domainList, dispatch]
  )

  const openRandomDomains = () => {
    const list = domainList.filter((v: IGetAllDomainListR) => v.url != null)
    for (let i = 0; i < 3; i++) {
      const randomNum = Math.floor(Math.random() * list.length)
      window.open(list[randomNum].url, '_blank')
    }
  }
  const selectSymbol = (val: string) => {
    setRequest('')
    setShowDropdown(false)
    if (val == '#' || val == '/') {
      setSymbol(val)
    } else if (val == '&') {
      openRandomDomains()
    } else if (val == '@') {
      showToastMsg('You must login or signup for this function.', 'info')
    } else {
      showToastMsg('You must login or signup for this function.', 'info')
      setSymbol(val)
    }
    AudioItem(val).play()
  }

  return (
    <div
      className="home-search-container"
      style={{
        background: 'transparent',
        color: themeMode == 'main' ? 'white' : 'black',
      }}
    >
      <div className="home-top-search">
        <div className="dropdown">
          <div className="drop-btn">
            <BsChevronDown size={15} color={'white'} />
          </div>
          <div className="home-dropdown-content">
            <div className="home-dropdown-sub">
              <a href="#" onClick={() => selectSymbol('#')} data-tip={tooltipSearch.symbol_1} data-for="symbol-tip">
                #
              </a>
              <a href="#" onClick={() => selectSymbol('$')} data-tip={tooltipSearch.symbol_2} data-for="symbol-tip">
                $
              </a>
              <a href="#" onClick={() => selectSymbol('/')} data-tip={tooltipSearch.symbol_3} data-for="symbol-tip">
                /
              </a>
              <a href="#" onClick={() => selectSymbol('@')} data-tip={tooltipSearch.symbol_4} data-for="symbol-tip">
                @
              </a>
              <a href="#" onClick={() => selectSymbol('%')} data-tip={tooltipSearch.symbol_5} data-for="symbol-tip">
                %
              </a>
            </div>
            <div className="home-dropdown-sub">
              <a href="#" onClick={() => selectSymbol('~')} data-tip={tooltipSearch.symbol_6} data-for="symbol-tip">
                ~
              </a>
              <a href="#" onClick={() => selectSymbol('()')} data-tip={tooltipSearch.symbol_7} data-for="symbol-tip">
                ()
              </a>
              <a href="#" onClick={() => selectSymbol('!')} data-tip={tooltipSearch.symbol_8} data-for="symbol-tip">
                !
              </a>
              <a href="#" onClick={() => selectSymbol('+')} data-tip={tooltipSearch.symbol_9} data-for="symbol-tip">
                +
              </a>
              <a href="#" onClick={() => selectSymbol('-')} data-tip={tooltipSearch.symbol_10} data-for="symbol-tip">
                -
              </a>
            </div>
            <div className="home-dropdown-sub">
              <a href="#" onClick={() => selectSymbol('&')} data-tip={tooltipSearch.symbol_11} data-for="symbol-tip">
                &
              </a>
              <a href="#" onClick={() => selectSymbol('^')} data-tip={tooltipSearch.symbol_12} data-for="symbol-tip">
                ^
              </a>
              <a href="#" onClick={() => selectSymbol('*')} data-tip={tooltipSearch.symbol_13} data-for="symbol-tip">
                *
              </a>
              <a href="#" onClick={() => selectSymbol('_')} data-tip={tooltipSearch.symbol_14} data-for="symbol-tip">
                _
              </a>
              <a href="#" onClick={() => selectSymbol('=')} data-tip={tooltipSearch.symbol_15} data-for="symbol-tip">
                =
              </a>
            </div>
          </div>
        </div>
        {symbol === '#' && (
          <form onSubmit={onSubmit}>
            <AutoSearchComplete
              dataList={dataList}
              keywordList={keywordList}
              userDomainList={userDomainList}
              onChange={setRequest}
              inputValue={request}
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
              placeString={placeTxt.keyword}
              isDisabled={false}
              themeMode={'main'}
              flag={'home_search'}
            />
            {buttonChange(request)}
          </form>
        )}
        {symbol === '$' && (
          <form>
            <span className="line_bar">|</span>
            <InputItem type="url" placeholder={placeTxt.nick} readOnly />
            <span className="line_bar">|</span>
            <InputItem type="url" placeholder={placeTxt.dollar} readOnly />
            {buttonObj('send-credit')}
          </form>
        )}
        {symbol === '/' && (
          <form>
            <InputItem placeholder={placeTxt.profile} onChange={onChangeNameVal} value={nameVal} />
            {buttonObj('profile')}
          </form>
        )}
        {symbol === '~' && (
          <form>
            <InputItem placeholder={placeTxt.dream} readOnly />
            {buttonObj('dream')}
          </form>
        )}
        {symbol === '+' && (
          <form>
            <InputItem placeholder={placeTxt.claim} readOnly />
            {buttonObj('claim')}
          </form>
        )}
        {symbol === '-' && (
          <form>
            <InputItem placeholder={placeTxt.drop} readOnly />
            {buttonObj('drop')}
          </form>
        )}
        {symbol === '()' && (
          <form>
            <span className="line_bar">|</span>
            <InputItem placeholder={placeTxt.url} readOnly />
            <span className="line_bar">|</span>
            <div className="main-search-input">
              <AutoDomainName domainData={userDomainList} onChange={setDomainVal} themeMode={'main'} isDisable={true} />
            </div>
            {buttonObj('target-url')}
          </form>
        )}
        {symbol === '%' && (
          <form>
            <span className="line_bar">|</span>
            <InputItem placeholder={placeTxt.earn} readOnly />
            {buttonObj('earn')}
          </form>
        )}
        {symbol === '!' && (
          <form>
            <span className="h_line">|</span>
            <span className="span_txt">! coming soon</span>
            {buttonObj('coming soon')}
          </form>
        )}
        {symbol === '^' && (
          <form>
            <span className="h_line">|</span>
            <span className="span_txt">^ coming soon</span>
            {buttonObj('coming soon')}
          </form>
        )}
        {symbol === '*' && (
          <form>
            <span className="h_line">|</span>
            <span className="span_txt">* coming soon</span>
            {buttonObj('coming soon')}
          </form>
        )}
        {symbol === '_' && (
          <form>
            <span className="h_line">|</span>
            <span className="span_txt">_ coming soon</span>
            {buttonObj('coming soon')}
          </form>
        )}
        {symbol === '=' && (
          <form>
            <span className="h_line">|</span>
            <span className="span_txt">= coming soon</span>
            {buttonObj('coming soon')}
          </form>
        )}
      </div>
    </div>
  )
}

export default SearchForm
