import React, { useState, createContext, useContext } from 'react'
import styled from 'styled-components'
import CartModal from '../CartModal'
import DomainResaleModal from '../DomainResaleModal'
import EmailSendModal from '../EmailSendModal'
import FavoritesModal from '../FavoritesModal'
import GiftSendingModal from '../GiftSendingModal'
import PlanetImgModal from '../PlanetImgModal'
import RenewModal from '../RenewModal'
import TargetURLModal from '../TargetURLModal'
import VideoConfirmModal from '../VideoConfirmModal'
import WishModal from '../WishModal'

const BackgroundOverlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
`

export const MODAL_TYPES = {
  CART_MODAL: 'CART_MODAL',
  DOMAIN_RESALE_MODAL: 'DOMAIN_RESALE_MODAL',
  GIFT_SENDING_MODAL: 'GIFT_SENDING_MODAL',
  DOMAIN_RENEW_MODAL: 'DOMAIN_RENEW_MODAL',
  DOMAIN_PLANET_MODAL: 'DOMAIN_PLANET_MODAL',
  VIDEO_CONFIRM_MODAL: 'VIDEO_CONFIRM_MODAL',
  EMAIL_SEND_MODAL: 'EMAIL_SEND_MODAL',
  TARGET_URL_MODAL: 'TARGET_URL_MODAL',
  FAVORITES_MODAL: 'FAVORITES_MODAL',
  WISH_MODAL: 'WISH_MODAL',
}

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.CART_MODAL]: CartModal,
  [MODAL_TYPES.DOMAIN_RESALE_MODAL]: DomainResaleModal,
  [MODAL_TYPES.GIFT_SENDING_MODAL]: GiftSendingModal,
  [MODAL_TYPES.DOMAIN_RENEW_MODAL]: RenewModal,
  [MODAL_TYPES.DOMAIN_PLANET_MODAL]: PlanetImgModal,
  [MODAL_TYPES.VIDEO_CONFIRM_MODAL]: VideoConfirmModal,
  [MODAL_TYPES.EMAIL_SEND_MODAL]: EmailSendModal,
  [MODAL_TYPES.TARGET_URL_MODAL]: TargetURLModal,
  // [MODAL_TYPES.TARGET_URL_MODAL]: TargetURL,
  [MODAL_TYPES.FAVORITES_MODAL]: FavoritesModal,
  [MODAL_TYPES.WISH_MODAL]: WishModal,
}

type ContextType = {
  showModal: (modalType: string, modalProps?: any) => void
  hideModal: () => void
  store: { modalType: string | undefined; modalProps: any }
}

const initalState: ContextType = {
  showModal: () => {},
  hideModal: () => {},
  store: { modalType: undefined, modalProps: undefined },
}

const GlobalModalContext = createContext(initalState)
export const useGlobalModalContext = () => useContext(GlobalModalContext)

const GlobalModal: React.FC<{}> = ({ children }) => {
  // debugger
  const [store, setStore] = useState<ContextType['store']>({
    modalType: undefined,
    modalProps: undefined,
  })
  const { modalType, modalProps } = store!

  const showModal = (modalType: string, modalProps: any) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    })
  }

  const hideModal = () => {
    setStore({
      ...store,
      modalType: undefined,
      modalProps: undefined,
    })
  }

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType!]
    if (!modalType || !ModalComponent) {
      return null
    }
    return <ModalComponent id="global-modal" {...modalProps} />
  }

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {store.modalType && <BackgroundOverlay onClick={hideModal}></BackgroundOverlay>}
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  )
}

export default GlobalModal
