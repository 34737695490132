import { onSendingGift, onSetResale, onVideoCallJWT } from 'actions/hashtag'
import { useGlobalModalContext } from 'components/Modals/GlobalModal'
import { DREAMING_URL } from 'constants/urls'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/actions'
import { Init as UserInit } from 'redusers/user'
import { Init as TransactionInit } from 'redusers/transaction'
import { useGetThemeMode } from 'state/theme/hook'
import { useCommonCheck } from './useCommon'
import { getNickList } from 'actions/transaction'

export const useDomainResale = () => {
  const dispatch = useDispatch()
  const settings = useSelector<AppState, Init['settings']>(({ actions }) => actions.get('settings'))
  const [idResale, setIdResale] = useState<string>('')
  const [costResale, setCostResale] = useState<string>('')

  const { hideModal, store } = useGlobalModalContext()
  const { modalProps } = store

  useEffect(() => {
    setIdResale(modalProps)
  }, [modalProps])

  const onChangeCostResale = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value
      if (val === '') {
        return setCostResale(val)
      }
      const reg = /^\d+?\.?\d*$/
      if (!reg.test(val)) {
        return null
      }
      const [_, float = ''] = val.split('.')
      if (float.length > 2) {
        return null
      }
      const numAmount = Number.parseInt(val)

      if (numAmount > 1000000) {
        return null
      }
      setCostResale(val)
    },
    [setCostResale]
  )

  const onCloseResale = useCallback(() => {
    setIdResale('')
    setCostResale('')
    hideModal()
  }, [hideModal])

  const onSaveResale = useCallback(() => {
    if (modalProps === '' || costResale === '') return

    onCloseResale()
    dispatch(onSetResale({ idResale, costResale }))
  }, [modalProps, costResale, onCloseResale, dispatch, idResale])

  return {
    onChangeCostResale,
    onCloseResale,
    onSaveResale,
    idResale,
    costResale,
    settings,
  }
}

export const useSendingGift = () => {
  const themeMode = useGetThemeMode()
  const dispatch = useDispatch()
  const { showToastMsg } = useCommonCheck()
  const userInfo = useSelector<AppState, UserInit['userInfo']>(({ user }) => user.get('userInfo'))
  const listNickName = useSelector<AppState, TransactionInit['listNickName']>(({ transaction }) =>
    transaction.get('listNickName')
  )
  const [giftId, setGiftId] = useState<string>('')
  const [receiverNickName, setReceiverNickName] = useState<string>('')

  const { hideModal, store } = useGlobalModalContext()
  const { modalProps } = store

  const getNickName = useCallback(() => {
    dispatch(getNickList())
  }, [dispatch])

  useEffect(() => {
    getNickName()
  }, [])

  useEffect(() => {
    setGiftId(modalProps)
  }, [modalProps])

  const onCloseSendingGift = useCallback(() => {
    setGiftId('')
    setReceiverNickName('')
    hideModal()
  }, [hideModal])

  const onSendingGifts = useCallback(() => {
    if (modalProps === '') {
      return
    }
    if (receiverNickName === '') {
      showToastMsg(`Please Input #NickName to send Gift`, 'info')
      return
    }

    onCloseSendingGift()
    dispatch(onSendingGift({ giftId, receiverNickName: receiverNickName.slice(1) }))
  }, [modalProps, receiverNickName, onCloseSendingGift, dispatch, giftId])

  return {
    onCloseSendingGift,
    onSendingGifts,
    setReceiverNickName,
    giftId,
    receiverNickName,
    userInfo,
    listNickName,
  }
}

export const useRenewalProcess = () => {
  const [renewId, setRenewId] = useState<string>('')
  const [renewPrice, setRenewPrice] = useState<number>(0)

  const { store } = useGlobalModalContext()
  const { modalProps } = store

  useEffect(() => {
    setRenewId(modalProps.id)
    setRenewPrice(modalProps.price)
  }, [modalProps])

  return {
    renewId,
    renewPrice,
  }
}

export const useVideoCallJWT = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector<AppState, UserInit['userInfo']>(({ user }) => user.get('userInfo'))

  const { hideModal, store } = useGlobalModalContext()
  const { modalProps } = store

  const onCloseVideoConfirm = useCallback(() => {
    hideModal()
  }, [hideModal])

  const onVideoCallback = useCallback(() => {
    if (modalProps.id === '' || userInfo === null || userInfo === undefined) {
      return
    }

    const originTargetUrl = modalProps.url
    const flag = modalProps.flag === undefined ? 1 : modalProps.flag // 0=>main menu item, 1=>launch icon
    let urlParam = modalProps.id
    if (urlParam.indexOf('#') > -1) {
      urlParam = urlParam.substring(1)
    }
    const realName = userInfo.first_name + ' ' + userInfo.last_name
    const nickName = urlParam
    const email = userInfo.email
    const avatar = ''
    const url = DREAMING_URL + urlParam
    navigator.clipboard.writeText(url)
    onCloseVideoConfirm()
    dispatch(
      onVideoCallJWT({
        realName,
        nickName,
        email,
        avatar,
        flag,
        originTargetUrl,
      })
    )
  }, [])

  return {
    onCloseVideoConfirm,
    onVideoCallback,
    modalProps,
    userInfo,
  }
}

export const useTargetURLProcess = () => {
  const [urlData, setUrlData] = useState<string>('')
  const [domainN, setDomainN] = useState<string>('')
  const [nick, setNick] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [favoriteVal, setFavoriteVal] = useState<string>('')
  const [userId, setUserId] = useState<number>(0)
  const [showTooltip, setShowTooltip] = useState<boolean>(true)

  const { store } = useGlobalModalContext()
  const { modalProps } = store

  useEffect(() => {
    setUrlData(modalProps.url)
    setDomainN(modalProps.domainN)
    setNick(modalProps.nick)
    setName(modalProps.name)
    setUserId(modalProps.userId)
    setFavoriteVal(modalProps.fav === null ? '' : modalProps.fav)
    setShowTooltip(modalProps.isShowTooltip)
  }, [modalProps])

  return {
    urlData,
    domainN,
    nick,
    name,
    userId,
    favoriteVal,
    showTooltip,
  }
}
