import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/transaction'
import { Init as AccountInit } from 'views/Admin/Accounts/redusers'

export const useGetUnUsedFunds = () => {
  const balance = useSelector<AppState, Init['balance']>(({ transaction }) => transaction.get('balance'), shallowEqual)
  const accountList = useSelector<AppState, AccountInit['accountList']>(
    ({ accounts }) => accounts.get('accountList'),
    shallowEqual
  )

  const unUsedFunds = useMemo(() => {
    const paypalBalance: number = balance.paypal
    const hashtagCreditBalance: number = accountList.reduce((total, { balance }) => total + balance, 0)
    return { paypal: paypalBalance, hashtagCredit: hashtagCreditBalance }
  }, [accountList, balance.paypal])

  return unUsedFunds
}
