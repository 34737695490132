import { getAddress } from '@ethersproject/address'
import useSWR from 'swr'
import Web3 from 'web3'
import buffTokenABI from '../../../abis/buffTokenABI'
import { providers } from '../blockchainAssets'
import { IAssetsTable, IChainId, ICreateAssets, ITokenP } from '../types'

const fetcher = (url: string) => fetch(url).then((res) => res.json())
// const coingeckoAPI = process.env.REACT_APP_COINGECKO_API;
const coingeckoAPI = 'https://api.coingecko.com/api/v3/'

export async function useTokenBalance(walletAddress: string, token: ITokenP): Promise<number> {
  return new Promise(async (resolve) => {
    const web3: Web3 = new Web3(providers[token.chainId])
    if (token.symbol === 'ETH' || token.symbol === 'BNB') {
      web3.eth.getBalance(walletAddress).then((balance) => {
        resolve(Number(web3.utils.fromWei(balance, 'ether')))
      })
    } else {
      const tokenInst = new web3.eth.Contract(buffTokenABI, token.address)
      const tokenDecimal = await tokenInst.methods.decimals().call()
      const tokenBalance = await tokenInst.methods.balanceOf(walletAddress).call()
      resolve(tokenBalance / Math.pow(10, tokenDecimal))
    }
  })
}

export function useValidateWalletAddress(address: any): string | false {
  try {
    return getAddress(address)
  } catch {
    return false
  }
}

export async function useGetTokenInfo(
  chainId: IChainId,
  tokenAddress: string,
  walletAddress: string
): Promise<ITokenP> {
  const web3: Web3 = new Web3(providers[chainId])
  const tokenInst = new web3.eth.Contract(buffTokenABI, tokenAddress)

  const tokenDecimal = await tokenInst.methods.decimals().call()
  const tokenName = await tokenInst.methods.name().call()
  const tokenSymbol = await tokenInst.methods.symbol().call()
  const tokenLogoUrl = getTokenLogoURL(chainId, tokenAddress)

  const customTokenTemp: ITokenP = {
    chainId: chainId,
    address: tokenAddress,
    name: tokenName,
    symbol: tokenSymbol,
    decimals: tokenDecimal,
    logoURI: tokenLogoUrl,
  }

  const tokenBalance = await useTokenBalance(walletAddress, customTokenTemp)
  const customToken = { ...customTokenTemp, balance: tokenBalance }

  return customToken
}

export const getTokenLogoURL = (chainId: number, address: string) => {
  const trustWalletURL = 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/'
  return `${trustWalletURL}${chainId === 1 ? 'ethereum' : 'binance'}/assets/${address}/logo.png`
}

export function useGetSWRResponse(network: string, tokenAddress: string | undefined) {
  const url = useGenerateAPIURL(network.toLowerCase(), tokenAddress?.toLowerCase())
  const { data, error } = useSWR(url, fetcher)
  if (error) {
    return false
  }

  return data
}

export function useGenerateAPIURL(networkId: string | undefined, tokenAddress: string | undefined): string {
  if (tokenAddress === undefined) {
    return ''
  } else if (tokenAddress === 'ethereum' || tokenAddress === 'binancecoin' || tokenAddress === 'metahash') {
    return `${coingeckoAPI}simple/price?ids=${tokenAddress}&vs_currencies=usd&include_24hr_change=true`
  } else {
    return `${coingeckoAPI}simple/token_price/${networkId}?contract_addresses=${tokenAddress}&vs_currencies=usd&include_24hr_change=true`
  }
}

export function useGenerateMarketAPIURL(networkId: string, tokenAddress: string, from: number, to: number): string {
  if (tokenAddress === 'ethereum' || tokenAddress === 'binancecoin' || tokenAddress === 'metahash') {
    return `${coingeckoAPI}coins/${tokenAddress}/market_chart/range?vs_currency=usd&from=${from}&to=${to}`
  } else {
    return `${coingeckoAPI}coins/${networkId}/contract/${tokenAddress}/market_chart/range?vs_currency=usd&from=${from}&to=${to}`
  }
}

export function useGetAssetData(row: ICreateAssets['asset']): IAssetsTable | false {
  const mhcPrice = useGetMHCPrice()
  const data = useGetSWRResponse(row.network, row.tokenAddress)

  if (!mhcPrice) {
    return false
  }

  if (!data) {
    return false
  }

  if (data[row.tokenAddress]) {
    const tokenToUsd = data[row.tokenAddress].usd * row.amount
    const tableData: IAssetsTable = {
      id: row.id,
      logo: row.logo!,
      network: row.network,
      tokenAddress: row.tokenAddress,
      name: row.tokenName!,
      symbol: row.symbol!,
      usdPrice: data[row.tokenAddress].usd,
      dailyChangePercent: data[row.tokenAddress].usd_24h_change,
      isPositive: data[row.tokenAddress].usd_24h_change >= 0,
      originAmount: Number(row.amount.toFixed(6)),
      profit: Number((tokenToUsd - row.amount * row.price).toFixed(6)),
      profitPercent: row.price !== 0 ? (100 * (tokenToUsd - row.amount * row.price)) / (row.amount * row.price) : 0,
      isProfit: tokenToUsd - row.amount * row.price > 0,
      usdAmount: Number(tokenToUsd.toFixed(6)),
      mhcAmount: Number((tokenToUsd / mhcPrice).toFixed(6)),
      createdDate: row.date!,
    }

    return tableData
  } else {
    const tokenToUsd = 0
    const tableData: IAssetsTable = {
      id: row.id,
      logo: row.logo!,
      network: row.network,
      tokenAddress: row.tokenAddress,
      name: row.tokenName!,
      symbol: row.symbol!,
      usdPrice: tokenToUsd,
      dailyChangePercent: 0,
      isPositive: true,
      originAmount: Number(row.amount.toFixed(6)),
      profit: Number((tokenToUsd - row.amount * row.price).toFixed(6)),
      profitPercent: row.price !== 0 ? (100 * (tokenToUsd - row.amount * row.price)) / (row.amount * row.price) : 0,
      isProfit: tokenToUsd - row.amount * row.price > 0,
      usdAmount: Number(tokenToUsd.toFixed(6)),
      mhcAmount: Number((tokenToUsd / mhcPrice).toFixed(6)),
      createdDate: row.date!,
    }

    return tableData
  }
}

interface IBalanceStatus {
  totalBalance: number
  profitAmount: number
  profitPercent: number
}

export function useGetCurrentBalanceStatus(assetsList: IAssetsTable[]): IBalanceStatus {
  const totalBalance = assetsList.map((asset: IAssetsTable) => asset.usdAmount).reduce((a, b) => a + b, 0)
  let profitAmount = 0
  const currentTimeStamp = new Date().getTime()
  assetsList.map((asset) => {
    if (currentTimeStamp - asset.createdDate * 1000 > 24 * 60 * 60 * 1000) {
      profitAmount += (asset.usdAmount * asset.dailyChangePercent) / (100 + Math.abs(asset.dailyChangePercent))
      return asset
    } else {
      profitAmount += asset.usdAmount
      return asset
    }
  })

  const profitPercent = (100 * profitAmount) / totalBalance
  return { totalBalance, profitAmount, profitPercent }
}

export function useGetTokenPrice(network: string, tokenAddress: string | undefined) {
  const data = useGetSWRResponse(network, tokenAddress)
  if (!data || !tokenAddress) {
    return data
  }

  if (!data[tokenAddress]) {
    return 0
  }

  return data[tokenAddress].usd
}

export function useGetMHCPrice(): number | false {
  const data = useGetSWRResponse('', 'metahash')

  if (!data) {
    return false
  }

  return data['metahash'].usd
}

export function useUsdToMhc(usdAmount: number, mhcPrice: number): number {
  return mhcPrice ? usdAmount / mhcPrice : 0
}

export function useGetTotalProfit(
  assetsList: IAssetsTable[]
): { totalProfit: number; totalProfitPercent: number } | undefined {
  if (assetsList.length === 0) {
    return undefined
  }

  const totalProfit = assetsList.map((asset: IAssetsTable) => asset.profit).reduce((a, b) => a + b, 0)
  let totalOriginAmount = 0
  assetsList.map((asset) => {
    totalOriginAmount += asset.profitPercent !== 0 ? (100 * asset.profit) / asset.profitPercent : 0
    return asset
  })

  const totalProfitPercent = totalOriginAmount !== 0 ? (totalProfit / totalOriginAmount) * 100 : 0

  return { totalProfit, totalProfitPercent }
}

export function useGetPerformance(
  assetsList: IAssetsTable[]
): { bestPerformance: IAssetsTable; worstPerformance: IAssetsTable } | undefined {
  if (assetsList.length === 0) {
    return undefined
  }

  const bestPerformance = assetsList.reduce((prev, current) => {
    return prev.profitPercent > current.profitPercent ? prev : current
  })

  const worstPerformance = assetsList.reduce((prev, current) => {
    return prev.profitPercent < current.profitPercent ? prev : current
  })

  return { bestPerformance, worstPerformance }
}

export function useGetTokenMarketChartData(asset: IAssetsTable, from: number, to: number) {
  const url = useGenerateMarketAPIURL(
    asset.network.toLowerCase(),
    asset.tokenAddress,
    Math.floor(from / 1000),
    Math.floor(to / 1000)
  )
  const { data, error } = useSWR(url, fetcher)
  if (error) {
    return false
  }

  if (!data) {
    return { id: asset.id, tokenBalanceRange: [] }
  }

  if (!data.prices) {
    return { id: asset.id, tokenBalanceRange: [] }
  }

  const tokenBalanceRange = data.prices.map((item: any) => {
    const amount = asset.createdDate * 1000 > item[0] ? 0 : item[1] * asset.originAmount
    return { time: item[0], amount: amount }
  })

  return { id: asset.id, tokenBalanceRange }
}
