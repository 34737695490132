import styled from 'styled-components'

export const FlexRow = styled.div<{
  width?: string
  margin?: string
  padding?: string
}>`
  width: ${({ width }) => (width ? width : '100%')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => (margin ? margin : '0')};
  padding: ${({ padding }) => (padding ? padding : '0')};
  gap: 20px;
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
  @media screen and (max-width: 1660px) {
    gap: 10px;
  }
`
export const FlexRowContent = styled.div<{
  width?: string
  margin?: string
  padding?: string
}>`
  width: ${({ width }) => (width ? width : '100%')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => (margin ? margin : '0')};
  padding: ${({ padding }) => (padding ? padding : '0')};
  gap: 20px;
`
export const FlexColumn = styled.div<{
  width?: string
  margin?: string
  padding?: string
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: ${({ width }) => (width ? width : '100%')};
  margin: ${({ margin }) => (margin ? margin : '0')};
  padding: ${({ padding }) => (padding ? padding : '0')};
`
export const HorizontalDivider = styled.div<{ margin?: string }>`
  width: 100%;
  height: 0px;
  border-top: 1px solid #919599;
  margin: ${({ margin }) => (margin ? margin : '12px 0')};
`
export const SubContainer = styled.div<{
  width: string
  height?: string
  themeMode?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ width }) => width};
  height: ${({ height }) => (height ? height : 'auto')};
  background-color: ${({ themeMode }) => (themeMode === 'light' ? 'white' : 'rgba(0, 0, 0, 0.7)')};
  color: ${({ themeMode }) => (themeMode === 'light' ? 'black' : 'white')};
  border: 1px solid ${({ themeMode }) => (themeMode === 'light' ? 'black' : '#025498')};
  border-top: 2px solid ${({ themeMode }) => (themeMode === 'light' ? 'black' : '#025498')};
  padding: 24px 16px;
  font-weight: 600;
  line-height: 22px;
  overflow-y: auto;
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`
export const SubContainerBody = styled(FlexColumn)<{ justifyContent?: string }>`
  width: 100%;
  height: 100%;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'space-between')};
`
export const TitleText = styled.span`
  font-size: 14px;
  font-weight: 700;
`
export const SubTitleText = styled.span<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  font-weight: 500;
  @media screen and (max-width: 1024px) {
    font-size: 10px;
  }
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`
export const BigValueText = styled.span<{ color: string; fontSize: string }>`
  display: flex;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 700;
  color: ${({ color }) => color};
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
`
export const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  background-color: transparent;
  z-index: 0;
`
