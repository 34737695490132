import { ItemTransaction, TGetNickNameListR } from '../utils/api'
import { Record } from 'immutable'

interface Action<T, V> {
  val: V
  type: T
}

export interface Balance {
  paypal: number
  metahash: number
  userETH: number
  userBNB: number
  userPaypal: number
}

export type ListTransaction = Array<Omit<ItemTransaction, 'user_email'>>

export interface Init {
  listTransaction: ListTransaction
  // balance: number
  balance: Balance
  isLoadSendPay: boolean
  isLoadSendWithdraw: boolean
  listTransactionForAdm: Array<ItemTransaction>
  listTransactionCountForAdm: number
  TRWithdrawCountForAdm: number
  listTRFilterForAdmin: string
  price: number
  isLoadingRecheckIncomplete: boolean
  listNickName: Array<TGetNickNameListR>
}

export const init: Init = {
  listTransaction: [],
  // balance: 0.0,
  balance: {
    paypal: 0.0,
    metahash: 0.0,
    userETH: 0.0,
    userBNB: 0.0,
    userPaypal: 0.0,
  },
  isLoadSendPay: false,
  isLoadSendWithdraw: false,
  listTransactionForAdm: [],
  listTransactionCountForAdm: 0,
  TRWithdrawCountForAdm: 0,
  listTRFilterForAdmin: '',
  price: 0,
  isLoadingRecheckIncomplete: false,
  listNickName: [],
}

export type Actions =
  | Action<'LIST_TRANSACTION', ListTransaction>
  // | Action<'BALANCE', number>
  | Action<'BALANCE', Balance>
  | Action<'IS_LOAD_SEND_PAY', boolean>
  | Action<'IS_LOAD_SEND_WITHDRAW', boolean>
  | Action<'LIST_TRANSACTION_FOR_ADM', Array<ItemTransaction>>
  | Action<'PRICE_#', number>
  | Action<'LIST_TRANSACTION_СOUNT_FOR_ADM', number>
  | Action<'LIST_TRANSACTION_WITHDRAW_REQ_СOUNT_FOR_ADM', number>
  | Action<'LIST_TRANSACTION_FILTER_TEXT_FOR_ADM', string>
  | Action<'IS_LOADING_RECHECKINCOMPLETE', boolean>
  | Action<'LIST_NICK_NAME', Array<TGetNickNameListR>>

const State: Record.Factory<Init> = Record(init)

const reducer = function (state: Record<Init> = new State(), action: Actions): Record<Init> {
  switch (action.type) {
    case 'LIST_TRANSACTION':
      return state.set('listTransaction', action.val)
    // case 'BALANCE':
    //   return state.set('balance', action.val)
    case 'BALANCE':
      return state.set('balance', action.val)
    case 'IS_LOAD_SEND_PAY':
      return state.set('isLoadSendPay', action.val)
    case 'IS_LOAD_SEND_WITHDRAW':
      return state.set('isLoadSendWithdraw', action.val)
    case 'LIST_TRANSACTION_FOR_ADM':
      return state.set('listTransactionForAdm', action.val)
    case 'LIST_TRANSACTION_СOUNT_FOR_ADM':
      return state.set('listTransactionCountForAdm', action.val)
    case 'LIST_TRANSACTION_WITHDRAW_REQ_СOUNT_FOR_ADM':
      return state.set('TRWithdrawCountForAdm', action.val)
    case 'LIST_TRANSACTION_FILTER_TEXT_FOR_ADM':
      return state.set('listTRFilterForAdmin', action.val)
    case 'PRICE_#':
      return state.set('price', action.val)
    case 'IS_LOADING_RECHECKINCOMPLETE':
      return state.set('isLoadingRecheckIncomplete', action.val)
    case 'LIST_NICK_NAME':
      return state.set('listNickName', action.val)

    default:
      return state
  }
}

export default reducer
