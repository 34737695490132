import { useCommonCheck } from 'hooks/useCommon'
import { Record } from 'immutable'
import React from 'react'

export interface Item {
  first_name: string
  last_name: string
  email: string | undefined
  phone_code: string
  phone: string
  company_name: string
  address: string
  city: string
  country: string
  zip: string
  category: string
  description: string
  imageUrl: string
  imageSrc: string
  img: string
  file: FormData | null
  clearFile: boolean
  keywords: Array<string> | null
  is_service_domain: number | undefined
  is_product_domain: number | undefined
  is_info_domain: number | undefined
  is_local_target: number | undefined
  is_national_target: number | undefined
  privacy: number | undefined
  is_international_target: number | undefined
  soc_facebook: string | undefined
  soc_twitter: string | undefined
  soc_instagram: string | undefined
}

export interface ItemError {
  first_name: boolean
  last_name: boolean
  email: boolean
  phone_code: boolean
  phone: boolean
  company_name: boolean
  address: boolean
  city: boolean
  country: boolean
  zip: boolean
  category: boolean
  description: boolean
  imageUrl: boolean
  img: boolean
  keywords: boolean
  is_service_domain: boolean
  is_product_domain: boolean
  is_info_domain: boolean
  is_local_target: boolean
  is_national_target: boolean
  privacy: boolean
  is_international_target: boolean
  soc_facebook: boolean
  soc_twitter: boolean
  soc_instagram: boolean
}

export const initialState: Item = {
  first_name: '',
  last_name: '',
  email: undefined,
  phone_code: '',
  phone: '',
  company_name: '',
  address: '',
  city: '',
  country: '',
  zip: '',
  category: '',
  description: '',
  imageUrl: '',
  imageSrc: '',
  img: '',
  file: null,
  clearFile: true,
  keywords: null,
  is_service_domain: undefined,
  is_product_domain: undefined,
  is_info_domain: undefined,
  is_local_target: undefined,
  is_national_target: undefined,
  privacy: undefined,
  is_international_target: undefined,
  soc_facebook: undefined,
  soc_twitter: undefined,
  soc_instagram: undefined,
}
export const initialError: ItemError = {
  first_name: false,
  last_name: false,
  email: false,
  phone_code: false,
  phone: false,
  company_name: false,
  address: false,
  city: false,
  country: false,
  zip: false,
  category: false,
  description: false,
  imageUrl: false,
  img: false,
  keywords: false,
  is_service_domain: false,
  is_product_domain: false,
  is_info_domain: false,
  is_local_target: false,
  is_national_target: false,
  is_international_target: false,
  privacy: false,
  soc_facebook: false,
  soc_twitter: false,
  soc_instagram: false,
}

export type itemKeys = keyof Item
export const State: Record.Factory<Item> = Record(initialState)

export const reducer = function (
  state: Record<Item> = new State(),
  action: {
    type: itemKeys
    val: string | null | undefined | string[] | number | FormData | boolean
  }
): Record<Item> {
  return state.set(action.type, action.val)
}

export const StateError: Record.Factory<ItemError> = Record(initialError)
export type itemKeysError = keyof ItemError
export const reducerError = function (
  state: Record<ItemError> = new StateError(),
  action: { type: itemKeysError; val: boolean }
): Record<ItemError> {
  return state.set(action.type, action.val)
}

export const inputChange =
  (dispatch: Function, dispatchError: Function, type: itemKeys) => (e: React.FormEvent<HTMLInputElement>) => {
    dispatch({ type: type, val: e.currentTarget.value || null })
    dispatchError({ type: type, val: false })
  }

export const textareaChange =
  (dispatch: Function, dispatchError: Function, type: itemKeys) => (e: React.FormEvent<HTMLTextAreaElement>) => {
    dispatch({ type: type, val: e.currentTarget.value || null })
    dispatchError({ type: type, val: false })
  }

export const checkboxChange =
  (dispatch: Function, dispatchError: Function, type: itemKeys) => (e: React.FormEvent<HTMLInputElement>) => {
    dispatch({ type: type, val: +e.currentTarget.checked })
    dispatchError({ type: type, val: false })
  }
export const selectChange =
  (dispatch: Function, dispatchError: Function, type: itemKeys) => (e: React.FormEvent<HTMLSelectElement>) => {
    dispatch({ type: type, val: e.currentTarget.value })
    dispatchError({ type: type, val: false })
  }

export const keyChange = (dispatch: Function, dispatchError: Function, type: itemKeys) => (t: Array<string>) => {
  dispatch({ type: type, val: t })
  dispatchError({ type: type, val: false })
}

export const onChangeFile = (dispatch: Function, dispatchError: Function) => (e: React.FormEvent<HTMLInputElement>) => {
  const fileList = e.currentTarget.files
  const { showToastMsg } = useCommonCheck()
  if (fileList === null) {
    e.currentTarget.value = ''
    dispatch({ type: 'clearFile' })
    return
  }
  if (fileList.length === 0) {
    e.currentTarget.value = ''
    dispatch({ type: 'clearFile' })
    return
  }
  const file = fileList[0]

  if (file.size > 1e6) {
    showToastMsg('Max file size 1MB', 'error')
    e.currentTarget.value = ''
    dispatch({ type: 'clearFile' })
    return
  }
  const src = window.URL.createObjectURL(fileList[0])

  dispatch({ type: 'imageSrc', val: src })
  const formData = new FormData()
  formData.append('file', file)

  dispatch({ type: 'file', val: formData })
}

export function chekedUrl(url: string | undefined) {
  if (!url) return false
  const pattern = /^(http(s)?:\/\/)+[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  return !pattern.test(url)
}

export const onSubmit =
  (state: Record<Item>, onDomainEdit: (val: Item) => void, dispatchError: Function) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const items = state.toObject()

    // if (!items.category) {
    //   dispatchError({ type: 'category', val: true });
    //   return;
    // }
    if (!items.keywords || !items.keywords.length) {
      dispatchError({ type: 'keywords', val: true })
      return
    }
    if (chekedUrl(items.soc_facebook)) {
      dispatchError({ type: 'soc_facebook', val: true })
      return
    }
    if (chekedUrl(items.soc_twitter)) {
      dispatchError({ type: 'soc_twitter', val: true })
      return
    }
    if (chekedUrl(items.soc_instagram)) {
      dispatchError({ type: 'soc_instagram', val: true })
      return
    }

    if (items.phone_code && !items.phone) {
      dispatchError({ type: 'phone', val: true })
      return
    }

    if (!items.phone_code && items.phone) {
      dispatchError({ type: 'phone_code', val: true })
      return
    }

    const imageUrlTemp = items.img.split('/uploads/').pop()
    onDomainEdit({ ...items, img: imageUrlTemp ? imageUrlTemp : '' })
  }

export const changeModalKeyWords = (onChangeModalKeyWords: (v: boolean) => void, v: boolean) => () => {
  onChangeModalKeyWords(v)
}
