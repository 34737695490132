import React from 'react'
import ReactTagInput from '@pathofdev/react-tag-input'
import '@pathofdev/react-tag-input/build/index.css'
import './style.css'
import styled from 'styled-components'

const TagInputWrapper = styled.div<{ themeMode: string }>`
  .react-tag-input__input {
    color: ${({ themeMode }) => (themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white')};
  }
  .react-tag-input__tag {
    color: ${({ themeMode }) => (themeMode == 'main' ? 'black' : themeMode == 'light' ? 'white' : 'black')};
    background: ${({ themeMode }) => (themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white')};
  }
  .react-tag-input__tag__remove {
    background: ${({ themeMode }) =>
      themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)'};
  }
`

interface KeyP {
  maxTags: number
  tags?: Array<string> | null
  onChange: (t: Array<string>) => void
  placeholder: string
  themeMode?: string
}

const Key: React.FC<KeyP> = ({ maxTags, tags = [], onChange, placeholder, themeMode }) => {
  return (
    <TagInputWrapper themeMode={themeMode!}>
      <ReactTagInput tags={tags || []} placeholder={placeholder} onChange={onChange} maxTags={maxTags} />
    </TagInputWrapper>
  )
}
export default Key
