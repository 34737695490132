import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import { checkExists, getAllDomainList, getAllSEODomainList, getInstruction, isSearch } from './actions'
import CategoryMainContainer from './components/Category/CategoryMain'
import SearchEngineResponse from './components/searchEngineResponse'
import InstructionBody from './components/Instruction/instructionBody'
import HeaderButtons from 'components/Header/HeaderButtons'
import OptionSearchForm from './components/OptionSearchForm'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'
import { Instruction } from './components/Instruction/data'
import { MdOutlineWifiProtectedSetup } from 'react-icons/md'
import Web3Frame from 'views/Web3Frame'

const OptionContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `calc(100% - ${width})`};
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const SearchEngineContainer = styled.div`
  position: relative;
  width: 100%;
`
const InstructionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  // width: 100%;
`
const InstructionDescription = styled.div`
  line-height: 28px;
  margin-left: 15px;
  display: flex;
  justify-content: flex-start;
  // width: 100%;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    font-size: 14px;
  }
`
const InstructionBold = styled.span`
  font-weight: 600;
  margin-right: 5px;
  white-space: nowrap;
`
const SwitchItem = styled.span`
  font-weight: 600;
  margin: 5px 15px 0 5px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
`

const Search: React.FC = () => {
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const isCollapseMenu = useIsCollapseMenu()
  const [flag, setFlag] = useState<boolean>(false)
  const [closeSearch, setCloseSearch] = useState<boolean>(true)
  const [switchBody, setSwitchBody] = useState<boolean>(false)

  const handleCloseSearch = useCallback(() => {
    setCloseSearch(!closeSearch)
  }, [closeSearch])

  const momorizedDispatch = useCallback(() => {
    dispatch(isSearch(true))
    setFlag(true)
    dispatch(getAllDomainList())
    dispatch(getAllSEODomainList())
    dispatch(getInstruction())
  }, [dispatch])

  useEffect(() => {
    momorizedDispatch()

    return () => {
      dispatch(isSearch(false))
      setFlag(false)
    }
  }, [dispatch, momorizedDispatch])

  const handleSwitch = () => {
    setSwitchBody(!switchBody)
  }

  return (
    <OptionContainer width={isCollapseMenu ? '65px' : '235px'}>
      {/* // TODO */}
      {/* <HeaderButtons /> */}
      <CSSTransition
        in={flag}
        timeout={4500}
        classNames="searchEngineFadeTransition"
        unmountOnExit
        onEnter={() => setFlag(true)}
        onExited={() => setFlag(false)}
      >
        <SearchEngineContainer
          style={{
            background: themeMode == 'main' ? '#00000090' : '#ffffff90',
            borderTop: themeMode == 'main' ? '2px solid #025498' : '',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        >
          <OptionSearchForm closeSearch={closeSearch} handleCloseSearch={handleCloseSearch} />
          {/* <SearchEngineInstruction /> */}
          {/* <CategoryMainContainer closeSearch={closeSearch} handleCloseSearch={handleCloseSearch} />
          <SearchEngineResponse closeSearch={closeSearch} handleCloseSearch={handleCloseSearch} /> */}
          <InstructionContainer>
            <InstructionDescription style={{ color: themeMode == 'main' ? '#fff' : '#000' }}>
              <InstructionBold>SEARCH ENGINE RANKING:</InstructionBold>
              {Instruction.mainDescription}
            </InstructionDescription>
            <SwitchItem onClick={handleSwitch}>
              SEE {switchBody ? 'WEB3' : 'INSTRUCTIONS'}{' '}
              <MdOutlineWifiProtectedSetup size={25} style={{ marginLeft: '3px', marginTop: '-3px' }} />
            </SwitchItem>
          </InstructionContainer>

          {switchBody ? <InstructionBody /> : <Web3Frame />}
        </SearchEngineContainer>
      </CSSTransition>
    </OptionContainer>
  )
}
export default Search
