import React from 'react'
import './styles.scss'
import { IoIosClose } from 'react-icons/io'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { onCloseModalManage, onEditUrl } from '../../../actions/hashtag'
import { useGetThemeMode } from 'state/theme/hook'

const modalRoot: Element = document.getElementById('root-modal') as Element

interface ModalP {
  onClose: () => void
  manageId: string
  onEditUrl: (val: string) => void
}

const onSubmit = (onEditUrl: ModalP['onEditUrl']) => (e: React.FormEvent<HTMLFormElement>) => {
  const data = new FormData(e.currentTarget)
  const url = data.get('url') as string
  if (url) onEditUrl(url)
  e.preventDefault()
}

const Modal: React.FC<ModalP> = ({ onClose, manageId, onEditUrl }) => {
  const themeMode = useGetThemeMode()
  return (
    <div className="modal">
      <div
        className="modal-content"
        style={{
          background: themeMode == 'main' ? '' : 'white',
          color: themeMode == 'main' ? '' : 'black',
        }}
      >
        <form onSubmit={onSubmit(onEditUrl)} style={{ display: 'block' }}>
          <div className="modal-content__header">
            <p>Manage {manageId}</p>
            <div className="modal-close" onClick={onClose}>
              <IoIosClose size={36} />
            </div>
          </div>
          <div className="modal-inp">
            <label htmlFor="">Site URL</label>
            <input type="url" name="url" />
          </div>
          <div className="modal-content__btns">
            <div className="btn" onClick={onClose}>
              Cancel
            </div>
            <button
              className="btn"
              type="submit"
              style={{
                background:
                  themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                color: themeMode == 'main' ? 'white' : 'black',
              }}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const timeout = { enter: 500, exit: 100 }
interface ModalManageP {
  showModalmanage: boolean
  onCloseModalManage: () => void
  manageId: string
  onEditUrl: (val: string) => void
}
const ModalManage: React.FC<ModalManageP> = ({ showModalmanage, onCloseModalManage, manageId, onEditUrl }) => {
  return createPortal(
    <CSSTransition classNames="animated" in={showModalmanage} unmountOnExit timeout={timeout}>
      <Modal onClose={onCloseModalManage} manageId={manageId} onEditUrl={onEditUrl} />
    </CSSTransition>,
    modalRoot
  )
}

function mapStateToProps({ modals, actions }: any) {
  return {
    showModalmanage: modals.get('showModalmanage'),
    manageId: actions.get('manageId'),
  }
}

export default connect(mapStateToProps, {
  onCloseModalManage,
  onEditUrl,
})(ModalManage)
