import { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeLatest } from '@redux-saga/core/effects'
import { push } from 'connected-react-router'
import { select } from 'redux-saga/effects'
import {
  RegistrationVal,
  SendEmailVal,
  TAffiliateData,
  TonActivateUser,
  TonForgotPassword,
  TonRegistrationGA,
  TonResetPassword,
  TonUnbindGA,
  TonUserEdit,
  TUserInfo,
} from '../actions/user'
import { init, Сondition3 } from '../redusers/user'
import { TItemR } from '../types'
import {
  auth,
  AuthRes,
  forgotPassword,
  getUploadPhotoUrl,
  PUserEdit,
  registration,
  RegistrationP,
  resetPassword,
  sendImage,
  TsendImmageRes,
  userAuthGA,
  userBindGA,
  userEdit,
  userGetInfo,
  UserInfo,
  userSavePhoto,
  userUnBindGA,
  addAffiliateData,
  getUserEmailInfo,
  userActivate,
  SendEmailP,
  sendemail,
  updateUserPrivate,
  updateUserOther,
} from '../utils/api'
import { TR_Cart } from '../views/Cart/reduser'
import { checkErorr, getToken, setToken } from './helper'
import { Record } from 'immutable'
import { match, matchPath } from 'react-router-dom'
import { showToast } from './domain'
import { AudioMenu } from 'components/Menu/tooltip'
import { CHROME_EXTENSION_ID, CHROME_EXTENSION_ID_MAC } from 'constants/urls'

interface Action<T> {
  val: T
  type: string
}

export function* watcherSaga() {
  yield fork(getUserInfo)
  yield takeLatest('ON_REGISTRATION', onRegistration)
  yield takeLatest('ON_UPDATE_USER_PRIVATE', onUpdateUserPrivate)
  yield takeLatest('ON_UPDATE_USER_OTHER', onUpdateUserOther)

  yield takeLatest('ON_SENDING_EMAIL', onSendEmail)
  yield takeLatest('ON_AUTH', onAuth)
  yield takeLatest('ON_SOCIAL', onSocial)
  yield takeLatest('ON_SEOMONEY', onSeomoney)
  yield takeLatest('ON_GAMEBLING', onGamebling)
  yield takeLatest('CHECK_LOGIN', checkLogin)
  yield takeLatest('CLEAR_LOGIN', clearLogin)
  yield takeLatest('ON_LOG_OUT', onLogOut)
  yield takeLatest('CHECKED_ROLE', checkedRole)
  yield takeLatest('ON_INSUFFICIENT_FUNDS', worcerOnInsufficientFunds)
  yield takeLatest('ON_USER_EDIT', worceronUserEdit)
  yield takeLatest('ON_ACT_USER', onActiveUser)
  yield takeLatest('ON_GET_QR_GA', worceronGetQRGA)
  yield takeLatest('ON_CLEAR_QR_GA', worcerOnClear)
  yield takeLatest('ON_REGISTRATION_GA', worcerOnRegistrationGA)
  yield takeLatest('ON_CLOSE_MODAL_CODE', worcerOnCloseModalCode)
  yield takeLatest('ON_UNBIND_GA', worcerOnUnbindGA)
  yield takeLatest('ON_FORGOTPASSWORD', workerOnForgotPassword)
  yield takeLatest('ON_RESETPASSWORD', workerOnResetPassword)
  yield takeLatest('ON_GET_RESET_CODE', workeronGetCode)
  yield takeLatest('ADD_AFFILIATE_DATA', workerAddAffiliateData)
  yield takeLatest('GET_USER_INFO', workerGetUserEmailInfo)
}

const getPathname = ({ router }: { router: Record<any> }) => router.get('location').get('pathname')

function getLogin(): boolean {
  if (!window.localStorage.refresh_token) {
    return false
  }
  const token = JSON.parse(window.localStorage.refresh_token)

  if (token.ttl < +new Date()) {
    return false
  }
  return true
}
type TActionlistItems = TItemR<TR_Cart, 'cart_listItems'>
export function* setLogin(): SagaIterator<boolean> {
  const isLogin = getLogin()
  yield put({
    type: 'IS_LOGIN',
    val: isLogin,
  })

  return isLogin
}

export function* getUserInfo(): SagaIterator<UserInfo | void> {
  try {
    const isLogin = yield call(setLogin)
    let userInfo = init.userInfo
    let isAdmin: Сondition3 = 'NO'
    if (!isLogin) {
      yield all([
        put({
          type: 'USER_INFO',
          val: userInfo,
        }),
        put({
          type: 'IS_ADMIN',
          val: isAdmin,
        }),
      ])
      return userInfo
    }

    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    userInfo = yield call(userGetInfo, token)
    isAdmin = ['ADMIN', 'SUPERADMIN'].includes(userInfo.role) ? 'YES' : 'NO'
    yield all([
      put({
        type: 'USER_INFO',
        val: userInfo,
      }),
      put({
        type: 'IS_ADMIN',
        val: isAdmin,
      }),
    ])
    return userInfo
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function isChromeBrowser() {
  const ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
      return true
    } else {
      return false
    }
  }
  return false
}
// Communicate with Extension(onMessageExternal of backend.ts)
const postChromeExtensionMessage = (msg: string) => {
  if (!isChromeBrowser()) return

  if (chrome.runtime) {
    let extensionId = CHROME_EXTENSION_ID
    const platform = navigator.platform.toLowerCase()
    if (!platform.includes('win')) extensionId = CHROME_EXTENSION_ID_MAC

    const rToken = window.localStorage.getItem('refresh_token')
    chrome.runtime.sendMessage(extensionId, { login: msg, rToken: rToken }, function (response) {})
  }
}

function* onAuth({ val }: Action<RegistrationVal>) {
  try {
    const { phone, wallet, email, password } = val
    yield put({ type: 'ERROR_AUTH', val: '' })

    const params: RegistrationP = { phone, wallet, email, password }
    const res: AuthRes = yield call(auth, params)
    setToken(res.token)

    // const currentDate = new Date()
    window.document.cookie = `_mh_usr=${JSON.stringify(res.userDetail)}; max-age=31536000; path=/; domain=.hashtag.org`

    const lastHashtag = window.sessionStorage.getItem('lastHashtag') || ''
    if (lastHashtag) {
      window.sessionStorage.removeItem('lastHashtag')
    }
    const userInfo: UserInfo | void = yield call(getUserInfo)

    postChromeExtensionMessage('yes')

    if (userInfo && userInfo.nick) {
      AudioMenu('login').play()
      yield put(push(`/domainsresult/${encodeURIComponent(lastHashtag)}`))
    } else {
      AudioMenu('tab').play()
      yield put(push(`/profile`))
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
    // yield put({ type: 'ERROR_AUTH', val: e.message })
    showToast(e.data, 'error')
  }
}

function* onSocial(value: any) {
  yield put({
    type: 'IS_SOCIAL',
    val: value.val,
  })
}

function* onSeomoney(value: any) {
  yield put({
    type: 'IS_SEOMONEY',
    val: value.val,
  })
}

function* onGamebling(value: any) {
  yield put({
    type: 'IS_GAMEBLING',
    val: value.val,
  })
}

function* onSendEmail({ val }: Action<SendEmailVal>) {
  try {
    const { from, to, title, body } = val

    const params: SendEmailP = { from, to, title, body }
    const isSentEmail: boolean = yield call(sendemail, params)

    if (isSentEmail) {
      showToast(`Sent an email to ${to} successfully`, 'success')
    } else {
      showToast(`Failed to send an email`, 'error')
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* onRegistration({ val }: Action<RegistrationVal>) {
  try {
    const { phone, wallet, email, password } = val

    yield put({ type: 'ERROR_REGISTRATION', val: '' })

    const params: RegistrationP = { phone, wallet, email, password }
    const referralLink = JSON.parse(localStorage.getItem('referralLink')!)
    if (referralLink && referralLink.ref) {
      params.referral = `${referralLink.ref}`
    }
    const isRegistration: boolean = yield call(registration, params)

    if (!isRegistration) {
      yield put({
        type: 'ERROR_REGISTRATION',
        val: 'Undefined internal error',
      })
      return
    }
    localStorage.removeItem('referralLink')
    yield put({
      type: 'ERROR_REGISTRATION',
      val: 'no_error',
    })
    // yield call(onAuth, { val, type: 'auto' })
  } catch (e: any) {
    yield fork(checkErorr, e)
    yield put({ type: 'ERROR_REGISTRATION', val: e.message })
  }
}

function* onUpdateUserPrivate({ val }: Action<RegistrationVal>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const { phone, wallet, email, password } = val
    const params: RegistrationP = { phone, wallet, email, password }
    const isUpdate: boolean = yield call(updateUserPrivate, params, token)
    if (isUpdate) {
      showToast(`Updated personal information successfully. \n Please sign in with new information`, 'success')
      yield call(onLogOut)
    } else {
      showToast(`Failed to update personal information`, 'error')
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* onUpdateUserOther({
  val,
}: Action<{
  eth: string
  bnb: string
  btc: string
  gps: string
}>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const isUpdate: boolean = yield call(updateUserOther, val, token)
    if (isUpdate) {
      showToast(`Updated personal information successfully.`, 'success')
    } else {
      showToast(`Failed to update personal information`, 'error')
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* checkLogin() {
  try {
    const isLogin: SagaIterator<boolean> = yield call(setLogin)
    if (!isLogin) {
      yield put(push(`/login/`))
      return
    }
    yield fork(getUserInfo)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* clearLogin() {
  yield put({
    type: 'IS_LOGIN',
    val: false,
  })
}

export function* onLogOut() {
  try {
    window.sessionStorage.removeItem('cart_listItems')
    window.localStorage.removeItem('refresh_token')
    window.localStorage.removeItem('access_token')
    window.document.cookie = `_mh_usr=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.hashtag.org;`

    postChromeExtensionMessage('no')

    yield call(clearLogin)
    yield put(push(`/`))
    if (chrome.storage) chrome.storage.local.clear()
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* checkedRole({ val }: Action<Сondition3>) {
  try {
    if (val === 'NO') {
      yield put(push(`/No_access`))
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* worceronUserEdit({ val }: TonUserEdit) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const updateData: PUserEdit = { fields: {} }
    updateData.fields.nick = val.nick
    updateData.fields.mhc_address = val.mhc_address || ''
    updateData.fields.eth_address = val.eth_address || ''
    updateData.fields.bnb_address = val.bnb_address || ''
    updateData.fields.paypal_address = val.paypal_address || ''
    updateData.fields.first_name = val.first_name || ''
    updateData.fields.last_name = val.last_name || ''

    if (val.password) {
      updateData.fields.password = val.password
    }

    updateData.fields.info = val.info || ''

    updateData.fields.social_network = {}
    updateData.fields.social_network.facebook = val.facebook || ''
    updateData.fields.social_network.instagram = val.instagram || ''
    updateData.fields.social_network.telegram = val.telegram || ''
    updateData.fields.social_network.skype = val.skype || ''

    if (Object.keys(updateData.fields).length) {
      yield call(userEdit, token, updateData)
    }

    if (val.file !== null) {
      const url: string = yield call(getUploadPhotoUrl, token)
      const sendI: TsendImmageRes = yield call(sendImage, url, val.file)
      yield call(userSavePhoto, token, sendI)
    }
    showToast('Saved', 'success')
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
    yield fork(getUserInfo)
  }
}

function* workerOnForgotPassword({ val }: TonForgotPassword) {
  try {
    const isSent: boolean = yield call(forgotPassword, { email: val })
    yield put({
      type: 'IS_SENT_EMAIL',
      val: isSent,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerOnResetPassword({ val }: TonResetPassword) {
  try {
    const isSet: boolean = yield call(resetPassword, val)
    if (isSet) {
      yield put(push(`/login/`))
      return
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workeronGetCode() {
  const pathname: string = yield select(getPathname)

  interface Params {
    code: string
  }

  const matchD = matchPath(pathname, {
    path: '/resetPassword/:code',
    strict: true,
  }) as match<Params> | null

  let code = ''

  if (matchD) {
    code = decodeURIComponent(matchD.params.code)
  }

  yield put({
    type: 'RESET_PWD_CODE',
    val: code,
  })
}

function* onActiveUser({ val }: TonActivateUser) {
  try {
    let isActive = false
    isActive = yield call(userActivate, val)
    if (isActive) {
      showToast('Congratulation! You are activated successfully', 'success')
    }
    yield put({
      type: 'IS_ACT_USER',
      val: isActive,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* worceronGetQRGA() {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const urlQR: string = yield call(userAuthGA, token)
    yield put({
      type: 'URL_QR_GA',
      val: urlQR,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* worcerOnClear() {
  try {
    yield put({
      type: 'URL_QR_GA',
      val: '',
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}
function* worcerOnCloseModalCode() {
  try {
    yield put({
      type: 'UN_BIND_GA',
      val: '',
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* worcerOnRegistrationGA({ val }: TonRegistrationGA) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    const code: string = yield call(userBindGA, token, { code: val })

    yield put({
      type: 'UN_BIND_GA',
      val: code,
    })
    yield fork(getUserInfo)
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* worcerOnUnbindGA({ val }: TonUnbindGA) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    yield call(userUnBindGA, token, { code: val })
    yield fork(getUserInfo)
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* worcerOnInsufficientFunds() {
  yield put(push(`/pay`))
}

function* workerAddAffiliateData({ val }: Action<TAffiliateData>) {
  try {
    const params = val
    const code: boolean = yield call(addAffiliateData, params)

    if (code) {
      const referralLink = JSON.parse(window.localStorage.getItem('referralLink')!)
      window.localStorage.removeItem('referralLink')
      window.localStorage.setItem(
        'referralLink',
        JSON.stringify({
          ...referralLink,
          cryptoRef: undefined,
          cryptoType: undefined,
        })
      )
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* workerGetUserEmailInfo({ val }: Action<TUserInfo>) {
  try {
    const params = val
    const code: boolean = yield call(getUserEmailInfo, params)
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

export default watcherSaga
