import { bookHashtag, onAddTargetUrl, onAddWishDomain, onEditTargetUrl, topSearchProcess } from 'actions/hashtag'
import { AutoDomainName } from 'components/AutoDomainName'
import { AutoNickComplete } from 'components/AutoNickComplete'
import { Input, InputTarget } from 'components/AutoNickComplete/styles'
import { AutoSearchComplete } from 'components/AutoSearchComplete'
import CommandDream from 'components/CommandDream'
import DomainClaimed from 'components/DomainClaimed'
import { AudioItem, placeTxt, tooltipMenu, tooltipSearch } from 'components/Menu/tooltip'
import { MODAL_TYPES, useGlobalModalContext } from 'components/Modals/GlobalModal'
import SendCreditForm from 'components/SendCreditForm'
import TopEarner from 'components/TopEarner'
import { SOCIAL_URL } from 'constants/urls'
import { useCommonCheck } from 'hooks/useCommon'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { BiBookmark, BiStar } from 'react-icons/bi'
import {
  BsArrowRightShort,
  BsBookmark,
  BsCheck2Circle,
  BsChevronDown,
  BsDisplay,
  BsFillCaretDownFill,
  BsSearch,
  BsStar,
  BsStarFill,
  BsTag,
  BsThreeDots,
  BsXCircle,
} from 'react-icons/bs'
import { MdAddShoppingCart } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { AppState } from 'redusers/config'
import { Init as TransactionInit } from 'redusers/transaction'
import { useGetThemeMode } from 'state/theme/hook'
import { useIsShowTooltip } from 'state/toolTip/hook'
import styled from 'styled-components'
import { IGetAllDomainListR, TAccountItem, TGetClaimListR, TGetKeywordListR } from 'utils/api'
import { useSearchEngineContext } from 'views/Search/contexts/SearchEngineContext'
import { GetDomainList, useGetDomainList } from 'views/Search/hooks/availableCategoryList'
import { generateCategoryList, pointedDomainList } from 'views/Search/utils'
import { checkExists, getAllDomainList } from '../../actions'
import { Init as UserInit } from 'redusers/user'
import cn from '../../cn.module.css'
import './styles.scss'
import { setSearchSymbol, TSearchSymbol } from 'state/headerButtons/reducer'
import { useGetSearchSymbol } from 'state/headerButtons/hook'
import { addItemCart } from 'views/Cart/actions'
import { ICloseSearchProps } from '../searchEngineResponse'
import { useSendingGift } from 'hooks/useModal'

const CloseIcon = styled.div`
  position: absolute;
  float: right;
  bottom: -30px;
  right: 15px;
  cursor: pointer;
  z-index: 2;
  &:hover {
    transform: scale(1.1);
  }
`

const OptionSearchForm: React.FC<ICloseSearchProps> = ({ closeSearch, handleCloseSearch }) => {
  const userInfo = useSelector<AppState, UserInit['userInfo']>(({ user }) => user.get('userInfo'))
  const loginUserId = userInfo.id
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const searchSymbol = useGetSearchSymbol()
  const { listNickName } = useSendingGift()
  const { showToastMsg, postChromeExtensionMessage } = useCommonCheck()
  const isShowTooltip = useIsShowTooltip()

  const [request, setRequest] = useState<string>('')
  const [tag, setTag] = useState<string>('#')
  const [domainVal, setDomainVal] = useState<string>('')
  const [nameVal, setNameVal] = useState<string>('')
  const [targetUrl, setTargetUrl] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [isSubPlaySound, setIsSubPlaySound] = useState<boolean>(true)

  const {
    domainList,
    seoDomainList,
    dataList,
    keywordList,
    userDomainList,
    claimDomainList,
    dropDomainList,
    accountList,
  } = useGetDomainList()
  const { isDomain, isContainSpace } = GetDomainList()
  const { setDomainList, setSEODomainList } = useSearchEngineContext()
  const { showModal } = useGlobalModalContext()

  useEffect(() => {
    dispatch(getAllDomainList())

    const isPlay = localStorage.getItem('soundKiller_search')
    if (isPlay) {
      if (isPlay === 'true') setIsSubPlaySound(true)
      else setIsSubPlaySound(false)
    }
  }, [dispatch])

  let claimData = claimDomainList.sort((a, b) => +new Date(b.date) - +new Date(a.date))
  claimData = claimData.slice(0, 10)

  let dropData = dropDomainList.filter((v: TGetClaimListR) => new Date(v.expire).getTime() < new Date().getTime())
  dropData.sort((a, b) => +new Date(a.date) - +new Date(b.date))
  dropData = dropData.slice(0, 10)

  let eanerData = accountList.filter((v: TAccountItem) => v.balance > 0)
  eanerData.sort((a, b) => b.balance - a.balance)
  eanerData = eanerData.slice(0, 10)

  const buttonObj = (flag: string, isWish: boolean = false) => {
    return (
      <React.Fragment>
        {flag == 'buy' && (
          <>
            <button
              type="submit"
              className="btnTag"
              style={{
                background:
                  themeMode === 'main'
                    ? 'linear-gradient(#60fb12, #41ae0a)'
                    : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
                color: themeMode === 'main' ? 'white' : 'black',
              }}
            >
              <span className="search-btn">
                <span style={{ marginRight: '10px' }}>BUY</span>
                <MdAddShoppingCart size={20} />
              </span>
            </button>
            <span className="star-icon" style={{ marginLeft: '10px' }} onClick={onProcessWishList}>
              {isWish ? (
                <BsStarFill size={20} color={themeMode === 'light' ? '#ff5900' : '#ffa600'} />
              ) : (
                <BsStar size={20} color={themeMode === 'light' ? '#ff5900' : '#ffa600'} />
              )}
            </span>
          </>
        )}
        {flag == 'go' && (
          <button
            type="submit"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#fbb212, #f48f33)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span style={{ marginRight: '10px' }}>GO</span>
              <BsArrowRightShort size={25} />
            </span>
          </button>
        )}
        {flag == 'search' && (
          <button
            type="submit"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#9a12fb, #5812fb)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span style={{ marginRight: '10px' }}>EXPLORE</span>
              <BsSearch size={15} />
            </span>
          </button>
        )}
        {flag == 'visit' && (
          <button
            type="submit"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#fbb212, #f48f33)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span style={{ marginRight: '10px' }}>VISIT</span>
            </span>
          </button>
        )}
        {flag == 'dream' && (
          <button
            type="button"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#fc3e3e, #aa0b0b)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span style={{ marginRight: '10px' }}>BROWSE</span>
              <div className="live-icon">
                <BsDisplay size={18} />
                <span style={{ fontSize: '10px' }}>LIVE</span>
              </div>
            </span>
          </button>
        )}
        {flag == 'claim' && (
          <button
            type="button"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#ce39f3, #80069e)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <div className="claimed-btn">
                <span>CLAIMED</span>
                <span className="recently-txt">RECENTLY</span>
              </div>
              <BsTag style={{ marginRight: '10px' }} size={22} />
            </span>
          </button>
        )}
        {flag == 'drop' && (
          <button
            type="button"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#f78558, #d84409)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <div className="claimed-btn">
                <span>DROPPED</span>
                <span className="recently-drop-txt">RECENTLY</span>
              </div>
              <BsTag style={{ marginRight: '10px' }} size={22} />
            </span>
          </button>
        )}
        {flag == 'target-url' && (
          <button
            type="button"
            className="btnTag"
            onClick={() => addTargetUrl()}
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#58c5f7, #0f88c0)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
              width: '170px',
            }}
          >
            <span className="search-btn">
              <BsBookmark style={{ marginLeft: '10px' }} size={18} />
              <div className="confirm-btn">
                <span>CONFIRM</span>
                <span className="bookmark-txt">bookmark</span>
              </div>
            </span>
          </button>
        )}
        {flag == 'profile' && (
          <button
            type="button"
            onClick={() => goSpaceBar()}
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#fbb212, #af7b09)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <span>VISIT</span>
            </span>
          </button>
        )}
        {flag == 'earn' && (
          <button
            type="button"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#58f76d, #09961c)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <div className="claimed-btn">
                <span>$$$</span>
              </div>
              <BsCheck2Circle style={{ marginRight: '10px' }} size={20} />
            </span>
          </button>
        )}
        {flag == 'coming soon' && (
          <button
            type="button"
            className="btnTag"
            style={{
              background:
                themeMode === 'main'
                  ? 'linear-gradient(#58f76d, #09961c)'
                  : 'linear-gradient(#777fc4, #b7eaf6, #777fc4)',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <span className="search-btn">
              <div className="claimed-btn">
                <span>Coming</span>
              </div>
              <BsCheck2Circle style={{ marginRight: '10px' }} size={20} />
            </span>
          </button>
        )}
      </React.Fragment>
    )
  }

  const buttonChange = (valu: string) => {
    const val = tag + valu
    let data
    if (isContainSpace(valu)) {
      data = keywordList.filter((v: TGetKeywordListR) =>
        v.keyword.toLowerCase().split(',').includes(request.toLowerCase().trim())
      )
      return buttonObj('search')
    } else {
      data = dataList.filter((v: TGetKeywordListR) => v.domainName.toLowerCase() === val.toLowerCase())
      if (data && data.length > 0) {
        if (data[0].url != '' && data[0].url !== 'unknown') {
          return buttonObj('go')
        } else if (data[0].resalePrice > 0) {
          const wishVal = data[0].wishes
          if (wishVal == null || wishVal == '') {
            return buttonObj('buy', false)
          } else {
            if (wishVal.split(',').includes(loginUserId.toString())) {
              return buttonObj('buy', true)
            } else {
              return buttonObj('buy', false)
            }
          }
        }
      } else {
        if (valu.length >= 3) {
          if (valu.startsWith('http://') || valu.startsWith('https://')) {
            return buttonObj('go')
          } else {
            const filterCount = listNickName.filter((item: any) => item.nick.toLowerCase() === val.toLowerCase()).length
            if (filterCount > 0) {
              return buttonObj('visit')
            } else {
              return buttonObj('buy', false)
            }
          }
        }
      }
    }
    return buttonObj('search')
  }

  const searchKeyword = async (list: TGetKeywordListR[]) => {
    if (closeSearch) handleCloseSearch()

    let searchKey = request.trim()
    if (isDomain(request)) {
      searchKey = request.substring(1)
    }
    if (list != null && list !== undefined) {
      if (list.length > 0) {
        const targetUrl = list[0].url
        const tUrl = targetUrl.split(',')[0]
        postChromeExtensionMessage('search', searchKey)
        window.open(tUrl, '_blank')
        AudioItem('btn-go').play()
        return
      }
    }
    setIsError(false)
  }

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      // if (request.length >= 3) {
      setRequest(request)
      setShowDropdown(false)

      let list
      if (isContainSpace(request)) {
        list = keywordList.filter((v: TGetKeywordListR) =>
          v.keyword.toLowerCase().split(',').includes(request.toLowerCase().trim())
        )
      } else {
        list = dataList.filter((v: TGetKeywordListR) => v.domainName.toLowerCase() === tag + request.toLowerCase())
      }

      if (list.length > 0) {
        if (isContainSpace(request)) {
          searchKeyword(list)
        } else {
          const targetUrl = list[0].url
          if (targetUrl && targetUrl !== null && targetUrl !== 'unknown') {
            const domainValue =
              request.toLowerCase() === list[0].domainName.substring(1).toLowerCase()
                ? list[0].domainName
                : '#' + request

            dispatch(topSearchProcess(domainValue))

            const urlArr = targetUrl.split(',')
            if (urlArr.length > 1) {
              onShowURLModal(targetUrl, '#' + request, list[0].nick, list[0].name, list[0].favorites, list[0].userId)
            } else {
              window.open(urlArr[0], '_blank')
              AudioItem('btn-go').play()
            }
          } else if (list[0].resalePrice > 0) {
            dispatch(
              addItemCart({
                id: '#' + request,
                isDemo: false,
                price: list[0].resalePrice,
                applyDiscount: false,
                type: 'domain',
                keyword: '',
              })
            )
          } else {
            searchKeyword(list)
          }
        }
      } else {
        if (isContainSpace(request)) {
          searchKeyword(list)
        } else {
          if (request.startsWith('http://') || request.startsWith('https://')) {
            window.open(request, '_blank')
            AudioItem('btn-go').play()
          } else {
            const filterCount = listNickName.filter(
              (item: any) => item.nick.toLowerCase() === '#' + request.toLowerCase()
            ).length
            if (filterCount > 0) {
              const url = SOCIAL_URL + request
              window.open(url, '_blank')
              AudioItem('btn-go').play()
            } else {
              dispatch(bookHashtag(tag + request))
            }
          }
        }
      }
    },
    [request, dispatch]
  )

  const onShowFavoriteListModal = () => {
    showModal(MODAL_TYPES.FAVORITES_MODAL)
  }
  const onShowWishListModal = () => {
    showModal(MODAL_TYPES.WISH_MODAL)
  }
  const onShowEmailModal = () => {
    showModal(MODAL_TYPES.EMAIL_SEND_MODAL)
  }
  const onShowURLModal = (url: string, domainN: string, nick: string, name: string, fav: string, userId: number) => {
    showModal(MODAL_TYPES.TARGET_URL_MODAL, {
      url,
      domainN,
      nick,
      name,
      fav,
      userId,
      isShowTooltip,
    })
  }
  const openRandomDomains = () => {
    const list = domainList.filter((v: IGetAllDomainListR) => v.url != null)
    for (let i = 0; i < 3; i++) {
      const randomNum = Math.floor(Math.random() * list.length)
      window.open(list[randomNum].url, '_blank')
    }
  }
  const goSpaceBar = () => {
    AudioItem('btn-go').play()
    window.open(SOCIAL_URL + nameVal.substring(1), '_blank')
  }
  const changeTargetUrl = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setTargetUrl(value.trim())
  }
  const addTargetUrl = () => {
    if (targetUrl.includes('http://') || targetUrl.includes('https://')) {
      let urlData
      const data = userDomainList.filter((v: any) => v.domainName.toLowerCase() === domainVal.toLowerCase())
      if (data[0]) {
        const urlTxt = data[0].url
        if (urlTxt != null) {
          if (urlTxt.includes(targetUrl)) {
            showToastMsg('You have already target URL in your domain', 'info')
            return
          } else {
            urlData = urlTxt + ',' + targetUrl
          }
        } else {
          urlData = targetUrl
        }
        dispatch(onAddTargetUrl({ originId: domainVal, originUrl: urlData }))
        AudioItem('btn-bookmark').play()
      }
    } else {
      showToastMsg('Please enter target URL correct', 'info')
      return
    }
  }
  const onProcessWishList = () => {
    const selectedDomain = '#' + request
    const wishesVal = dataList.filter((v: TGetKeywordListR) => selectedDomain === v.domainName)[0].wishes
    let wish_val = ''
    if (wishesVal == '' || wishesVal == null) {
      wish_val = loginUserId.toString()
    } else {
      if (wishesVal.split(',').includes(loginUserId.toString())) {
        const wishArr = wishesVal.split(',').filter((v) => v != loginUserId.toString())
        wish_val = wishArr.join()
      } else {
        wish_val = wishesVal + ',' + loginUserId
      }
    }
    dispatch(
      onAddWishDomain({
        domainId: selectedDomain,
        wishVal: wish_val,
      })
    )
  }
  // const selectSymbol = (val: string) => {
  //   setRequest('')
  //   setShowDropdown(false)
  //   if (val == '@') {
  //     onShowEmailModal()
  //   } else if (val == '&') {
  //     openRandomDomains()
  //   } else {
  //     setSymbol(val)
  //   }
  //   AudioItem(val).play()
  // }

  const selectSymbol = useCallback(
    (symbol: TSearchSymbol) => {
      setRequest('')
      setShowDropdown(false)
      if (symbol === '@') {
        onShowEmailModal()
      } else if (symbol === '&') {
        openRandomDomains()
      } else {
        dispatch(setSearchSymbol(symbol))
      }
      AudioItem(symbol).play()
    },
    [dispatch, searchSymbol]
  )

  const soundKiller = () => {
    setIsSubPlaySound(!isSubPlaySound)
    localStorage.setItem('soundKiller_search', '' + !isSubPlaySound)
  }

  return (
    <div className={cn.columContainer}>
      <div
        className="optionSearchEngin"
        style={{
          background: themeMode === 'main' ? '#00000090' : '#ffffff90',
          color: themeMode === 'light' ? 'black' : 'white',
          borderTopColor: themeMode === 'main' ? '#02569d' : 'gray',
        }}
      >
        <div className="introduction-search">
          <span className="mSpace">Search, Buy and Resolve #domains</span>
          <span className="mSpace">& more in Web 2.0 and</span>
          <span className="mSpace">Web 3.0 #space.</span>
        </div>
        <div
          className="option-top-search"
          style={{
            background: themeMode === 'main' ? 'black' : 'white',
          }}
        >
          <div className="dropdown">
            <div className="dropbtn">
              <BsChevronDown
                size={15}
                style={{
                  color: themeMode === 'main' ? 'white' : 'black',
                }}
              />
            </div>
            <div
              className="dropdown-content"
              style={{
                background: themeMode === 'main' ? 'black' : 'white',
              }}
            >
              <div className={themeMode === 'main' ? 'dropdown-sub-d' : 'dropdown-sub'}>
                <a href="#" onClick={() => selectSymbol('#')} data-tip={tooltipSearch.symbol_1} data-for="symbol-tip">
                  #
                </a>
                <a href="#" onClick={() => selectSymbol('$')} data-tip={tooltipSearch.symbol_2} data-for="symbol-tip">
                  $
                </a>
                <a href="#" onClick={() => selectSymbol('/')} data-tip={tooltipSearch.symbol_3} data-for="symbol-tip">
                  /
                </a>
                <a href="#" onClick={() => selectSymbol('@')} data-tip={tooltipSearch.symbol_4} data-for="symbol-tip">
                  @
                </a>
                <a href="#" onClick={() => selectSymbol('%')} data-tip={tooltipSearch.symbol_5} data-for="symbol-tip">
                  %
                </a>
              </div>
              <div className={themeMode === 'main' ? 'dropdown-sub-d' : 'dropdown-sub'}>
                <a href="#" onClick={() => selectSymbol('~')} data-tip={tooltipSearch.symbol_6} data-for="symbol-tip">
                  ~
                </a>
                <a href="#" onClick={() => selectSymbol('()')} data-tip={tooltipSearch.symbol_7} data-for="symbol-tip">
                  ()
                </a>
                <a href="#" onClick={() => selectSymbol('!')} data-tip={tooltipSearch.symbol_8} data-for="symbol-tip">
                  !
                </a>
                <a href="#" onClick={() => selectSymbol('+')} data-tip={tooltipSearch.symbol_9} data-for="symbol-tip">
                  +
                </a>
                <a href="#" onClick={() => selectSymbol('-')} data-tip={tooltipSearch.symbol_10} data-for="symbol-tip">
                  -
                </a>
              </div>
              <div className={themeMode === 'main' ? 'dropdown-sub-d' : 'dropdown-sub'}>
                <a href="#" onClick={() => selectSymbol('&')} data-tip={tooltipSearch.symbol_11} data-for="symbol-tip">
                  &
                </a>
                <a href="#" onClick={() => selectSymbol('^')} data-tip={tooltipSearch.symbol_12} data-for="symbol-tip">
                  ^
                </a>
                <a href="#" onClick={() => selectSymbol('*')} data-tip={tooltipSearch.symbol_13} data-for="symbol-tip">
                  *
                </a>
                <a href="#" onClick={() => selectSymbol('_')} data-tip={tooltipSearch.symbol_14} data-for="symbol-tip">
                  _
                </a>
                <a href="#" onClick={() => selectSymbol('=')} data-tip={tooltipSearch.symbol_15} data-for="symbol-tip">
                  =
                </a>
              </div>
            </div>
          </div>
          {searchSymbol === '#' && (
            <form onSubmit={onSubmit}>
              <AutoSearchComplete
                dataList={dataList}
                keywordList={keywordList}
                userDomainList={userDomainList}
                onChange={setRequest}
                inputValue={request}
                setShowDropdown={setShowDropdown}
                showDropdown={showDropdown}
                placeString={placeTxt.keyword}
                isDisabled={false}
                themeMode={themeMode}
                flag={'command_search'}
              />
              {buttonChange(request)}
            </form>
          )}
          {searchSymbol === '$' && <SendCreditForm />}
          {searchSymbol === '/' && (
            <form>
              <AutoNickComplete
                nickData={listNickName}
                onChange={setNameVal}
                nameValue={nameVal}
                themeMode={themeMode}
              />
              {buttonObj('profile')}
            </form>
          )}
          {searchSymbol === '~' && (
            <>
              <form>
                <span className="h_line">|</span>
                <span className="span_txt">{placeTxt.dream}</span>
                {buttonObj('dream')}
              </form>
              <div className="streaming-container">
                <CommandDream isSearch={true} />
              </div>
            </>
          )}
          {searchSymbol === '+' && (
            <>
              <form>
                <span className="h_line">|</span>
                <span className="span_txt">{placeTxt.claim}</span>
                {buttonObj('claim')}
              </form>
              <div className="streaming-container">
                <DomainClaimed itemCount={5} claimDomainItems={claimData} claimdrop={'claimed'} />
              </div>
            </>
          )}
          {searchSymbol === '-' && (
            <>
              <form>
                <span className="h_line">|</span>
                <span className="span_txt">{placeTxt.drop}</span>
                {buttonObj('drop')}
              </form>
              <div className="streaming-container">
                <DomainClaimed itemCount={5} claimDomainItems={dropData} claimdrop={'dropped'} />
              </div>
            </>
          )}
          {searchSymbol === '()' && (
            <form>
              <span className="h_line">|</span>
              <InputTarget
                type="url"
                placeholder={placeTxt.url}
                value={targetUrl}
                onChange={changeTargetUrl}
                style={{
                  background: themeMode === 'main' ? 'black' : 'white',
                  color: themeMode === 'main' ? 'white' : 'black',
                }}
              />
              <span className="h_line">|</span>
              <div className="domain_input">
                <AutoDomainName
                  domainData={userDomainList}
                  onChange={setDomainVal}
                  nameValue={domainVal}
                  isDisable={false}
                  themeMode={themeMode}
                />
              </div>
              {buttonObj('target-url')}
            </form>
          )}
          {searchSymbol === '%' && (
            <>
              <form>
                <span className="h_line">|</span>
                <span className="span_txt">{placeTxt.earn}</span>
                {buttonObj('earn')}
              </form>
              <div className="streaming-container">
                <TopEarner itemCount={5} eanerData={eanerData} />
              </div>
            </>
          )}
          {searchSymbol === '!' && (
            <form>
              <span className="h_line">|</span>
              <span className="span_txt">! coming soon</span>
              {buttonObj('coming soon')}
            </form>
          )}
          {searchSymbol === '^' && (
            <form>
              <span className="h_line">|</span>
              <span className="span_txt">^ coming soon</span>
              {buttonObj('coming soon')}
            </form>
          )}
          {searchSymbol === '*' && (
            <form>
              <span className="h_line">|</span>
              <span className="span_txt">* coming soon</span>
              {buttonObj('coming soon')}
            </form>
          )}
          {searchSymbol === '_' && (
            <form>
              <span className="h_line">|</span>
              <span className="span_txt">_ coming soon</span>
              {buttonObj('coming soon')}
            </form>
          )}
          {searchSymbol === '=' && (
            <form>
              <span className="h_line">|</span>
              <span className="span_txt">= coming soon</span>
              {buttonObj('coming soon')}
            </form>
          )}
        </div>
        <div className="left-icons">
          <span onClick={soundKiller} data-tip={tooltipSearch.dotIcon} data-for="symbol-tip">
            {isSubPlaySound ? (
              <BsThreeDots size={15} color={themeMode === 'light' ? '#000000' : '#ffffff'} />
            ) : (
              <BsThreeDots size={15} color={'red'} />
            )}
          </span>
          <span onClick={onShowWishListModal}>
            <BiStar size={15} color={themeMode === 'light' ? '#ff5900' : '#ffa600'} />
          </span>
          <span onClick={onShowFavoriteListModal}>
            <BiBookmark size={15} color={themeMode === 'light' ? '#01b1f3' : '#4fc1fc'} />
          </span>
        </div>
      </div>

      {!closeSearch && (
        <CloseIcon onClick={handleCloseSearch}>
          <BsXCircle style={{ color: themeMode === 'main' ? 'white' : 'black' }} size="20" />
        </CloseIcon>
      )}
      <ReactTooltip
        id="symbol-tip"
        effect="solid"
        type={themeMode === 'light' ? 'dark' : 'info'}
        className="tooltip-search"
        disable={isShowTooltip ? false : true}
      />
    </div>
  )
}

export default OptionSearchForm
