import { onLogOut } from 'actions/user'
import { CHROME_EXTENSION_ID, CHROME_EXTENSION_ID_MAC } from 'constants/urls'
import { ethers } from 'ethers'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const useCommonCheck = () => {
  const dispatch = useDispatch()

  const validateEmail = (email: string): boolean => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email.toLowerCase())
  }
  const validateWallet = (wAddress: string) => {
    if (ethers.utils.isAddress(wAddress)) return true
    else return false
  }

  const showToastMsg = (msg: string, type: string) => {
    if (type === 'success') {
      return toast.success(msg, {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      })
    } else if (type === 'error') {
      return toast.error(msg, {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      })
    } else if (type === 'warn') {
      return toast.warning(msg, {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      })
    } else if (type === 'info') {
      return toast.info(msg, {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      })
    } else if (type === 'dark') {
      return toast.dark(msg, {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      })
    } else {
      // type === 'dismiss'
      toast.dismiss()
      return ''
    }
  }

  const hashCode = (str: string) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
  }
  const intToRGB = (i: number) => {
    const c = (i & 0x00ffffff).toString(16).toUpperCase()
    return '00000'.substring(0, 6 - c.length) + c
  }

  const isChromeBrowser = () => {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        return true
      } else {
        return false
      }
    }
    return false
  }
  // Communicate with Extension(onMessageExternal of backend.ts)
  const postChromeExtensionMessage = (msg: string, rToken: string) => {
    if (!isChromeBrowser()) return

    if (chrome.runtime) {
      let extensionId = CHROME_EXTENSION_ID
      const platform = navigator.platform.toLowerCase()
      if (!platform.includes('win')) extensionId = CHROME_EXTENSION_ID_MAC

      chrome.runtime.sendMessage(extensionId, { login: msg, rToken: rToken }, function (response) {})
    }
  }

  return {
    validateEmail,
    validateWallet,
    showToastMsg,
    hashCode,
    intToRGB,
    postChromeExtensionMessage,
    isChromeBrowser,
  }
}
