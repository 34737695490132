import { takeLatest, all, call, put, select, throttle, fork, take } from '@redux-saga/core/effects'
import {
  checkHashtagExists,
  hashtagGetList,
  hashtagEdit,
  getCategoryGetList,
  HashtagListRes,
  HashtagItem,
  hashtagGetItem,
  addCategory,
  hashtagGetItemRes,
  getBalance,
  SettingItems,
  getSettings,
  CheckHashtagR,
  userGetInfo,
  UserInfo,
  HashtagEditP,
  hashtagResale,
  hashtagСancelResale,
  CategoryList,
  getUploadPhotoUrl,
  sendImage,
  TsendImmageRes,
  hashtagSendingGift,
  domainRenewalProcess,
  videoGetJWT,
  hashtagKeywordUpdate,
  getAllDomainList,
  IGetAllDomainListR,
  hashtagFavUpdate,
  domainTopSearch,
  nickAdd,
  hashtagSetDefault,
  hashtagUpdate,
  nickAddAll,
} from '../utils/api'
import { push } from 'connected-react-router'
import { match, matchPath } from 'react-router-dom'
import { getToken, checkHashtag as isCheckHashtag, checkErorr } from './helper'
import { Init as Actions, IsHashtagBooked } from '../redusers/actions'
import { Record } from 'immutable'
import { Item } from '../components/DomainEditForm/events'
import { toast } from 'react-toastify'
import { SagaIterator } from 'redux-saga'
import { TActRenewal, TActStopResale, TgetHashtagList, TonShowModalManage } from '../actions/hashtag'
import { AppState } from '../redusers/config'
import { TonAnswerAuthGA } from '../actions/user'
import { DREAMING_URL, HASHTAG_URL } from 'constants/urls'
import { TAddItem, TCartItem } from 'views/Cart/types'
import { Integer } from 'io-ts'

interface Action<T> {
  val: T
  type: string
}
export function* watcherSaga() {
  yield takeLatest('CHECK_HASHTAG', checkHashtag)
  yield takeLatest('SEARCH_ENGINE_HASHTAG', searchEngineHashtag)
  yield takeLatest('ON_LOADING_DOMAINSRESULT', onLoadingDomainsresult)
  yield takeLatest('SEARCH_HASHTAG', searchHashtag)
  yield takeLatest('BOOKING_HASHTAG', bookHashtag)
  yield takeLatest('ON_ADD_NICK_TO_HASHTAG', onAddNickToHashtag)
  yield takeLatest('ON_ADD_NICK_ALL', onAddNickAll)

  yield takeLatest('ON_SHOW_MODAL_MANAGE', onShowModalManage)
  yield takeLatest('ON_CLOSE_MODAL_MANAGE', onCloseModalManage)
  yield takeLatest('ON_EDIT_URL', onEditUrl)
  yield takeLatest('ON_EDIT_TARGET_URL', onEditTargetUrl)
  yield takeLatest('ON_ADD_TARGET_URL', onAddTargetUrl)
  yield takeLatest('ON_ADD_FAVORITE_DOMAIN', onAddFavorDomain)
  yield takeLatest('ON_ADD_WISH_DOMAIN', onAddWishDomain)
  yield takeLatest('ON_SET_DEFAULT_DOMAIN', onSetDefaultDomain)
  yield takeLatest('ON_UPDATE_HASHTAG', onUpdateHashtag)

  yield takeLatest('GET_CATEGORY_LIST', workerGetCategoryList)
  yield throttle(500, 'GET_HASHTAG_LIST', worcerGetHashtagList)
  yield takeLatest('ON_CLEAR_HASHTAG_LIST', workerOnClearHashtagList)
  yield takeLatest('ON_DOMAIN_EDIT', workerOnDomainEdit)
  yield takeLatest('ON_HASHTAG_GET_ITEM', workerOnHashtagGetItem)
  yield takeLatest('ON_SAVE_KEYWORD', workerOnSaveKeyword)
  yield takeLatest('ON_RERENDER_EDITFORM', workerOnRerenderEditForm)
  yield takeLatest('ON_SET_RESALE', workerOnSetResale)
  yield takeLatest('ON_STOP_RESALE', workerOnStopResale)
  yield takeLatest('ON_SENDING_GIFT', workerOnSendingGift)
  yield takeLatest('ON_RENEWAL_PROCESS', workerOnRenewalProcess)
  yield takeLatest('ON_TOP_DOMAIN_SEARCH', workerOnTopDomainSearch)
  yield takeLatest('ON_VIDEO_CALL_JWT', workerOnGetVideoJWT)
}

const getPathname = ({ router }: { router: Record<any> }) => router.get('location').get('pathname')
const getManageId = ({ actions }: { actions: Record<Actions> }): string => actions.get('manageId')
const getSearchValDomens = ({ modals }: AppState): string => modals.get('searchValDomens')

const getHashtagList = ({ actions }: { actions: Record<Actions> }): Array<HashtagItem> => actions.get('hashtagList')

const getHashtagItem = ({ actions }: { actions: Record<Actions> }): hashtagGetItemRes | undefined =>
  actions.get('hashtagItem')

export const showToast = (msg: string, type: string) => {
  if (type === 'success') {
    return toast.success(msg, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
    })
  } else if (type === 'error') {
    return toast.error(msg, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
    })
  } else if (type === 'warn') {
    return toast.warn(msg, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
    })
  } else if (type === 'info') {
    return toast.info(msg, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
    })
  } else {
    // type === 'dismiss'
    toast.dismiss()
    return ''
  }
}

function* checkHashtag({ val }: Action<string>) {
  try {
    yield put(push(`/domainsresult/${encodeURIComponent(val)}`))
  } catch (error: any) {
    console.error({ error })
  }
}

function* searchEngineHashtag({ val }: Action<string>) {
  try {
    yield put(push(`/search?q=${encodeURIComponent(val)}`))
  } catch (error: any) {
    console.error({ error })
  }
}

function* onLoadingDomainsresult() {
  const pathname: string = yield select(getPathname)

  interface Params {
    hastag: string
  }

  yield put({
    type: 'IS_CHECK_HASHTAG_ERR',
    val: false,
  })

  const matchD = matchPath(pathname, {
    path: '/domainsresult/:hastag',
    strict: true,
  }) as match<Params> | null

  let hastag = ''

  if (matchD) {
    const mHastag = decodeURIComponent(matchD.params.hastag)
    hastag = isCheckHashtag(mHastag) ? mHastag : ''
  }

  yield put({
    type: 'CHECKING_HASHTAG',
    val: hastag,
  })
  if (hastag) {
    yield call(searchHashtag, { val: hastag, type: 'auto' })
  }
}

function* bookHashtag({ val, type }: Action<string>) {
  try {
    const hashTag: string = val
    if (hashTag.length < 4) {
      showToast(`${hashTag} is not allowed to buy!`, 'error')
      return
    }

    const isHashtagBooked: CheckHashtagR = yield call(checkHashtagExists, {
      id: hashTag,
    })

    if (isHashtagBooked.allowedBuy) {
      const defaultPrice = isHashtagBooked.price
      let buyPrice = defaultPrice
      if (hashTag.length === 4) buyPrice = defaultPrice * 3
      else if (hashTag.length === 5) buyPrice = defaultPrice * 2
      else buyPrice = defaultPrice

      const item: TCartItem = {
        id: hashTag,
        isDemo: false,
        price: buyPrice,
        applyDiscount: false,
      }
      const data: TAddItem = { type: 'CART_ADD_ITEM', payload: item }
      yield put(data) //=== call workerAddToCart() = dispatch of component
    } else {
      showToast(`${hashTag} is not allowed to buy!`, 'error')
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* onAddNickToHashtag({ val }: Action<string>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const params = { id: val, url: HASHTAG_URL + val }
    const result: boolean = yield call(nickAdd, params, token)
    if (result) {
      const list: HashtagListRes = yield call(hashtagGetList, { filter: { search: '', limit: 0, offset: 0 } }, token)
      const listItems = list.items
      const flist = listItems.sort((a, b) => b.is_default_domain - a.is_default_domain)
      yield all([
        put({
          type: 'HASHTAG_LIST',
          val: flist,
        }),
      ])
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* onAddNickAll({ val }: Action<{ nickArr: Array<string>; userIdArr: Array<number>; urlArr: Array<string> }>) {
  try {
    const params = { ids: val.nickArr, userIds: val.userIdArr, urls: val.urlArr }
    const result: boolean = yield call(nickAddAll, params)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* searchHashtag({ val, type }: Action<string>) {
  try {
    const hashTag: string = val

    if (!isCheckHashtag(hashTag)) {
      yield all([
        put({
          type: 'SHOW_SEARCH_RESULT',
          val: true,
        }),
        put({
          type: 'IS_CHECK_HASHTAG_ERR',
          val: true,
        }),
      ])
      return
    }

    const isHashtagBooked: CheckHashtagR = yield call(checkHashtagExists, {
      id: hashTag,
    })

    const isHash: IsHashtagBooked = {
      status: isHashtagBooked.status,
      type: isHashtagBooked.type,
      is_demo: isHashtagBooked.is_demo,
      allowedBuy: isHashtagBooked.allowedBuy,
      price: isHashtagBooked.price,
      expire: isHashtagBooked.expire,
      applyDiscount: isHashtagBooked.applyDiscount,
      allowedDemoBuy: isHashtagBooked.allowedDemoBuy,
    }

    yield all([
      put({
        type: 'IS_HASHTAG_BOOKED',
        val: isHash,
      }),
      put({
        type: 'CHECKING_HASHTAG',
        val: hashTag,
      }),
      put({
        type: 'SHOW_SEARCH_RESULT',
        val: true,
      }),
      put({
        type: 'IS_CHECK_HASHTAG_ERR',
        val: false,
      }),
    ])
    yield put(push(`/domainsresult/${encodeURIComponent(hashTag)}`))
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* worcerGetHashtagList({ payload }: TgetHashtagList) {
  try {
    const { search, limit, isClear } = payload
    const oldList: Array<HashtagItem> = isClear ? [] : yield select(getHashtagList)
    const token: string = yield call(getToken)

    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const list: HashtagListRes = yield call(
      hashtagGetList,
      { filter: { search: search, limit, offset: oldList.length } },
      token
    )

    const listItems = [...oldList, ...list.items]
    const flist = listItems.sort((a, b) => b.is_default_domain - a.is_default_domain)

    if (flist.length > list.total_count) return

    yield all([
      put({
        type: 'HASHTAG_LIST',
        val: flist,
      }),
      put({
        type: 'EXPIRED_LIST',
        val: list.expireList,
      }),
      put({
        type: 'HASHTAG_LIST_СOUNT',
        val: list.total_count,
      }),
      put({
        type: 'SEARCH_VAL_DOMENS',
        val: search,
      }),
    ])
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerOnClearHashtagList() {
  yield all([
    put({
      type: 'HASHTAG_LIST',
      val: [],
    }),
    put({
      type: 'HASHTAG_LIST_СOUNT',
      val: 0,
    }),
  ])
}

function* onShowModalManage({ payload }: TonShowModalManage) {
  try {
    const { id, searchVal } = payload
    yield all([
      put({
        type: 'SHOW_MODALMANAGE',
        val: true,
      }),
      put({
        type: 'MANAGE_ID',
        val: id,
      }),
      put({
        type: 'SEARCH_VAL_DOMENS',
        val: searchVal,
      }),
    ])
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}
function* onCloseModalManage() {
  try {
    yield all([
      put({
        type: 'SHOW_MODALMANAGE',
        val: false,
      }),
      put({
        type: 'MANAGE_ID',
        val: '',
      }),
    ])
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* onEditUrl({ val }: Action<string>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const hashtag: string = yield select(getManageId)
    const edit: Promise<Error | boolean> = yield call(hashtagEdit, { id: hashtag, fields: { url: val } }, token)
    if (!!edit) {
      yield fork(reloadHashagList)
    }
    yield call(onCloseModalManage)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}
export function* onEditTargetUrl({
  val,
}: Action<{
  originId: string
  originUrl: string
}>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    const edit: Promise<Error | boolean> = yield call(
      hashtagEdit,
      { id: val.originId, fields: { url: val.originUrl } },
      token
    )
    if (!!edit) {
      yield fork(reloadHashagList)
      window.localStorage.removeItem('originTargetUrl')
      showToast('TargetURL updated successfully', 'success')
    }
    yield call(onCloseModalManage)
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export function* onAddFavorDomain({
  val,
}: Action<{
  domainId: string
  favoriteVal: string
}>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const params = { id: val.domainId, favData: val.favoriteVal, flag: 'fav' }
    const result: Promise<Error | boolean> = yield call(hashtagFavUpdate, params, token)
    if (!!result) {
      yield put({
        type: 'ADD_FAVORITE_STATUS',
        val: true,
      })
      const res: IGetAllDomainListR[] = yield call(getAllDomainList)
      yield put({
        type: 'GET_ALL_DOMAIN_LIST',
        payload: res,
      })
    } else {
      yield put({
        type: 'ADD_FAVORITE_STATUS',
        val: false,
      })
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export function* onAddWishDomain({
  val,
}: Action<{
  domainId: string
  wishVal: string
}>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const params = { id: val.domainId, favData: val.wishVal, flag: 'wish' }
    const result: Promise<Error | boolean> = yield call(hashtagFavUpdate, params, token)
    if (!!result) {
      yield put({
        type: 'ADD_WISH_STATUS',
        val: true,
      })
      const res: IGetAllDomainListR[] = yield call(getAllDomainList)
      yield put({
        type: 'GET_ALL_DOMAIN_LIST',
        payload: res,
      })
    } else {
      yield put({
        type: 'ADD_WISH_STATUS',
        val: false,
      })
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export function* onSetDefaultDomain({
  val,
}: Action<{
  newId: string
  isDefault: boolean
}>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const result: boolean = yield call(hashtagSetDefault, val, token)
    if (result) {
      const list: HashtagListRes = yield call(hashtagGetList, { filter: { search: '', limit: 0, offset: 0 } }, token)
      const listItems = list.items
      const flist = listItems.sort((a, b) => b.is_default_domain - a.is_default_domain)
      yield all([
        put({
          type: 'HASHTAG_LIST',
          val: flist,
        }),
      ])
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export function* onUpdateHashtag({
  val,
}: Action<{
  id: string
  desc: string
  keyword: string
  url: string
}>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const result: boolean = yield call(hashtagUpdate, val, token)
    if (result) {
      const list: HashtagListRes = yield call(hashtagGetList, { filter: { search: '', limit: 0, offset: 0 } }, token)
      const listItems = list.items
      const flist = listItems.sort((a, b) => b.is_default_domain - a.is_default_domain)
      yield all([
        put({
          type: 'HASHTAG_LIST',
          val: flist,
        }),
      ])
      // showToast(`Updated hashtag information successfully.`, 'success')
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export function* onAddTargetUrl({
  val,
}: Action<{
  originId: string
  originUrl: string
}>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    const edit: Promise<Error | boolean> = yield call(
      hashtagEdit,
      { id: val.originId, fields: { url: val.originUrl } },
      token
    )
    if (!!edit) {
      showToast('TargetURL added successfully', 'success')
      const res: IGetAllDomainListR[] = yield call(getAllDomainList)
      yield put({
        type: 'GET_ALL_DOMAIN_LIST',
        payload: res,
      })
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export function* workerGetCategoryList() {
  try {
    const catigories: CategoryList = yield call(getCategoryGetList)
    yield put({
      type: 'CATEGORY_LIST',
      val: catigories,
    })
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerOnSaveKeyword({ val }: Action<{ domain: string; keyword: string; payout: number }>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    const balance: number = yield call(getBalance, token)
    if (balance < val.payout) {
      // insufficient balance
      return
    }
    const params = { id: val.domain, keyword: val.keyword, payout: val.payout }
    const returnVal: boolean = yield call(hashtagKeywordUpdate, params, token)
    if (returnVal) {
      const res: IGetAllDomainListR[] = yield call(getAllDomainList)
      yield put({
        type: 'GET_ALL_DOMAIN_LIST',
        payload: res,
      })
      showToast(`Keyword has been added to ${val.domain} successfully`, 'success')
    } else {
      showToast(`Failed to add a keyword to ${val.domain}`, 'error')
    }
    yield put({ type: 'GET_BALANCE' })
  } catch (e: any) {
    yield fork(checkErorr, e)
    yield call(workerOnHashtagGetItem)
  } finally {
    yield put({
      type: 'IS_LOAD_F_EDIT',
      val: false,
    })
  }
}

function* workerOnDomainEdit({ val }: Action<Item>) {
  try {
    yield put({
      type: 'IS_LOAD_F_EDIT',
      val: true,
    })

    const pathname: string = yield select(getPathname)

    interface Params {
      hastag: string
    }

    const matchD = matchPath(pathname, {
      path: '/domain_edit/:hastag',
      strict: true,
    }) as match<Params> | null

    let hastag = ''

    if (matchD) {
      const mHastag = decodeURIComponent(matchD.params.hastag)
      hastag = isCheckHashtag(mHastag) ? mHastag : ''
    }

    if (!hastag) {
      console.error('!hastag')
      return
    }

    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const { category, ...fields } = val
    const countKeyWord: number = Array.isArray(fields.keywords) ? fields.keywords.length : 0
    const checkedPayKeyWords: TcheckedPayKeyWords = yield call(worcerCheckedPayKeyWords, countKeyWord, token)

    if (!checkedPayKeyWords.isPay) {
      return
    }

    let gacode = ''
    if (checkedPayKeyWords.count > 0) {
      const { enabled_ga }: UserInfo = yield call(userGetInfo, token)
      if (enabled_ga) {
        gacode = yield call(modalQA)
        if (gacode === '') return
      }
    }

    const params: HashtagEditP = { id: hastag, fields }

    if (gacode !== '') {
      params.gacode = gacode
    }

    if (val.file !== null) {
      const url: string = yield call(getUploadPhotoUrl, token)
      const sendI: TsendImmageRes = yield call(sendImage, url, val.file)
      params.fields.imageUrl = sendI.file
    }

    if (category === '') {
      yield call(hashtagEdit, params, token)
    } else {
      yield all([call(hashtagEdit, params, token), call(addCategory, { id: hastag, category }, token)])
    }

    yield put({ type: 'GET_BALANCE' })
    yield put(push(`/domainsdashboard/`))
  } catch (e: any) {
    yield fork(checkErorr, e)
    yield call(workerOnHashtagGetItem)
  } finally {
    yield put({
      type: 'IS_LOAD_F_EDIT',
      val: false,
    })
  }
}

type TcheckedPayKeyWords = {
  isPay: boolean
  count: number
}
function* worcerCheckedPayKeyWords(countKeyWord: number, token: string): SagaIterator<TcheckedPayKeyWords> {
  try {
    const { max_keywords } = yield select(getHashtagItem)
    const countFreeWords = Number(max_keywords)
    if (countKeyWord <= countFreeWords) {
      return { isPay: true, count: 0 }
    }

    const { keyword_price }: SettingItems = yield call(getSettings, token)
    const balance: number = yield call(getBalance, token)
    const count = countKeyWord - countFreeWords
    const prise = count * keyword_price
    if (prise === 0) {
      return { isPay: true, count }
    }
    if (prise > balance) {
      showToast('INSUFFICIENT FUNDS. Please top up.', 'error')
      return { isPay: false, count }
    }

    yield all([
      put({
        type: 'SHOW_MODAL_KEYWORDS',
        val: true,
      }),
      put({
        type: 'PRISE_COUNTKEY_WORDS',
        val: prise,
      }),
    ])

    const modal = yield take('ON_CHANGE_MODAL_KEYWORDS')

    yield put({
      type: 'SHOW_MODAL_KEYWORDS',
      val: false,
    })

    return { isPay: modal.val, count }
  } catch (e: any) {
    yield fork(checkErorr, e)
    return { isPay: false, count: 0 }
  }
}

function* workerOnRerenderEditForm() {
  yield all([
    put({
      type: 'SHOW_MODAL_KEYWORDS',
      val: false,
    }),
    put({
      type: 'PRISE_COUNTKEY_WORDS',
      val: 0,
    }),
    put({
      type: 'ON_CHANGE_MODAL_KEYWORDS',
      val: false,
    }),
    put({
      type: 'IS_LOAD_F_EDIT',
      val: false,
    }),
  ])
}

export function* workerOnGetVideoJWT({
  val,
}: Action<{
  realName: string
  nickName: string
  email: string
  avatar: string
  flag: number
  originTargetUrl: string
}>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    const jwt: string = yield call(videoGetJWT, token, {
      realname: val.realName,
      hashdomainname: val.nickName,
      email: val.email,
      avatar: val.avatar,
    })
    if (jwt) {
      const meetingUrl = DREAMING_URL + val.nickName + '?jwt=' + jwt
      if (val.flag == 0) window.open(meetingUrl, '_self')
      else if (val.flag == 1) window.open(meetingUrl, '_blank')
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
    yield put(push(`/not_found/`))
  }
}

function* workerOnHashtagGetItem(): any {
  try {
    const pathname: string = yield select(getPathname)

    interface Params {
      hastag: string
    }

    const matchD = matchPath(pathname, {
      path: '/domain_edit/:hastag',
      strict: true,
    }) as match<Params> | null

    let hastag = ''

    if (matchD) {
      const mHastag = decodeURIComponent(matchD.params.hastag)
      hastag = isCheckHashtag(mHastag) ? mHastag : ''
    }

    if (!hastag) {
      console.error('!hastag')
      return
    }

    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    let [item, { stop_sale }] = yield all([call(hashtagGetItem, { id: hastag }, token), call(getSettings, token)])

    if (Array.isArray(item.category)) {
      item.category = item.category[0] || ''
    }

    yield all([
      put({
        type: 'HASHTAG_ITEM',
        val: item,
      }),
      put({
        type: 'STOP_SALE',
        val: stop_sale,
      }),
    ])

    yield call(workerGetCategoryList)
  } catch (e: any) {
    yield fork(checkErorr, e)
    yield put(push(`/not_found/`))
  }
}

export function* workerCheckedBuyDomen(price: number): SagaIterator<boolean> {
  if (price === 0) {
    return true
  }
  yield all([
    put({
      type: 'SHOW_MODAL_BUY_DOMEN',
      val: true,
    }),
    put({
      type: 'PRICE_BUY_DOMEN',
      val: price,
    }),
  ])

  const modal = yield take('ON_CHANGE_MODAL_BUYDOMEN')

  yield put({
    type: 'SHOW_MODAL_BUY_DOMEN',
    val: false,
  })

  return modal.val
}

export function* modalQA(): SagaIterator<string> {
  yield put({
    type: 'IS_OPEN_AUTH_GA',
    val: true,
  })

  const modal: TonAnswerAuthGA = yield take('ON_ANSWER_AUTH_GA')

  yield put({
    type: 'IS_OPEN_AUTH_GA',
    val: false,
  })

  return modal.val
}

export function* reloadHashagList() {
  const searchValDomens: string = yield select(getSearchValDomens)
  const oldList: Array<HashtagItem> = yield select(getHashtagList)
  const action: TgetHashtagList = {
    type: 'GET_HASHTAG_LIST',
    payload: {
      search: searchValDomens,
      limit: oldList.length,
      isClear: true,
    },
  }
  yield call(worcerGetHashtagList, action)
}

function* workerOnSetResale({ val }: Action<{ idResale: string; costResale: string }>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    // TODO stop temporarily
    // yield call(hashtagResale, token, { id: val.idResale, price: parseFloat(val.costResale) })
    // yield fork(reloadHashagList)
    showToast('This was stopped temporarily until Sep 1', 'info')
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerOnStopResale({ payload }: TActStopResale) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    // TODO stop temporarily
    // yield call(hashtagСancelResale, token, { id: payload })
    // showToast(`Domain ${payload} has been removed from sale`, 'success')
    // yield fork(reloadHashagList)
    showToast('This was stopped temporarily until Sep 1', 'info')
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerOnSendingGift({ val }: Action<{ giftId: string; receiverNickName: string }>) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }

    const status: boolean = yield call(hashtagSendingGift, token, {
      id: val.giftId,
      receiverNickName: val.receiverNickName,
    })

    yield fork(reloadHashagList)

    if (status) {
      showToast(`Gift ${val.giftId} was sent to ${val.receiverNickName} successfully`, 'success')
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerOnRenewalProcess({ payload }: TActRenewal) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    // TODO stop temporarily
    // yield call(setAutorenewal, token, payload)
    // const status: boolean = yield call(domainRenewalProcess, token, {
    //   id: payload,
    // })
    // if (status) {
    //   showToast(`Domain ${payload} has been renewed successfully`, 'success')
    //   yield fork(reloadHashagList)
    // } else {
    //   showToast(`Domain ${payload} has not been renewed with some reason like your balance`, 'error')
    // }

    showToast('This was stopped temporarily until Sep 1', 'info')
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

function* workerOnTopDomainSearch({ payload }: TActRenewal) {
  try {
    const token: string = yield call(getToken)
    if (!token) {
      yield put(push(`/login/`))
      return
    }
    const status: boolean = yield call(domainTopSearch, token, {
      id: payload,
    })

    if (status) {
      yield fork(reloadHashagList)
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export default watcherSaga
