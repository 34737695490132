import React from 'react'
import classes from 'classnames'
import cn from './cn.module.scss'
import phoneCode from '../../../utils/phoneCode'

interface SelectInputP {
  error?: boolean
  selinp?: string
  label: string
  value?: string
  placeholder?: string
  type?: string
  optionValue?: string
  optiononChange?: (e: React.FormEvent<HTMLSelectElement>) => void
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  themeMode?: string
}

const SelectInput: React.FC<SelectInputP> = ({
  error = false,
  selinp = '',
  label,
  value,
  placeholder = '',
  type = 'text',
  optionValue = '',
  optiononChange,
  onChange,
  themeMode,
}) => {
  return (
    <div className={cn.input + ' ' + classes(error ? cn.error : '') + ' ' + classes({ selinp: selinp })}>
      <label>{label}</label>
      <div className={cn.selectContainer}>
        <div className={cn.selectWrapper}>
          <select className={cn.select} onChange={optiononChange} value={optionValue}>
            <option value={''} disabled>
              select
            </option>
            {phoneCode.map((item) => (
              <option key={item[1]} value={`${item[1]} ${item[0]}`}>{`${item[1]} ${item[0]}`}</option>
            ))}
          </select>
          <div className={cn.selectArrow} />
        </div>
        <input
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          style={{
            color: themeMode == 'main' ? 'white' : 'black',
            background: themeMode == 'main' ? '' : '#e8eff7',
            borderColor: themeMode == 'main' ? '' : 'gray',
          }}
        />
      </div>
    </div>
  )
}
export default SelectInput
