import React, { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import { useGetThemeMode } from 'state/theme/hook'
import './styles.scss'

const Dashboard: React.FC = () => {
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const isAdmin = useSelector<AppState, Init['isAdmin']>(({ user }) => user.get('isAdmin'), shallowEqual)

  useEffect(() => {}, [])

  return (
    <div className="dashboardContainer">
      <div></div>
    </div>
  )
}

export default Dashboard
