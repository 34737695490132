import React, { useState, useEffect } from 'react'
import './styles.scss'
import { CSSTransition } from 'react-transition-group'
import { useGetThemeMode } from 'state/theme/hook'
import IntroText from './IntroText'
import SearchForm from './SearchForm'

const MainSearch = () => {
  const [flag, setFlag] = useState<boolean>(true)

  const themeMode = useGetThemeMode()

  return (
    <CSSTransition in={flag} timeout={2000} classNames="homeFadeTransition" unmountOnExit mountOnEnter>
      <div className="main-search">
        <h1 className={themeMode == 'main' ? 'hs' : themeMode == 'light' ? 'hs-d' : 'hs'}>
          <div className="introduction-wrapper">
            <IntroText txt={'#HashtagSpace'} themeMode={themeMode} />
          </div>
        </h1>
        <SearchForm />
        <div className={themeMode == 'main' ? 'd-hs' : themeMode == 'light' ? 'd-hs-d' : 'd-hs'}>
          <div className="introduction-wrapper">
            <IntroText txt={'Search, Buy and Resolve #domains in Web 2.0 & Web 3.0 Space.'} themeMode={themeMode} />
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default MainSearch
