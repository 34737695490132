import React, { useEffect, useState } from 'react'
import cn from './cn.module.css'
import Title from '../FormTitle'
import FormInput from '../FormInput'
import { Record } from 'immutable'
import { Init as Transaction } from '../../redusers/transaction'
import { connect, useDispatch, useSelector } from 'react-redux'
import { payHashtag, sendCryptoPayData } from '../../actions/transaction'
import { PayC, PayP, UserInfo } from '../../utils/api'
import { CSSTransition } from 'react-transition-group'
import { useGetThemeMode } from 'state/theme/hook'
import AddCryptoBalanceModal from './AddCryptoBalanceModal'
import { AppState } from 'redusers/config'
import { showToast } from 'sagas/domain'
import { useWallet } from 'hooks/useWallet'
import { SupportedChainId } from 'constants/chains'
import { TO_ADDRESS } from 'constants/addresses'

interface DepositFormP {
  payHashtag: (val: PayP) => void
  isLoadSendPay: boolean
  price: number
}

const onPay =
  (payHashtag: DepositFormP['payHashtag'], amount: string, type: PayP['type']) =>
  (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (amount === null || typeof amount !== 'string') return

    const numAmount = Number.parseFloat(amount.slice(1))
    if (isNaN(numAmount)) return

    const transactionType: PayP['transactionType'] = 'pay'
    payHashtag({ amount: numAmount, type, transactionType })
  }

const onChange = (setVal: (v: string) => void) => (e: React.FormEvent<HTMLInputElement>) => {
  const val = e.currentTarget.value
  const prefix = '$'

  if (val === '') return setVal(val)

  if (!val.includes('$') && !isNaN(parseInt(val))) return setVal(prefix + val)

  if (val.includes(prefix) && val.substr(prefix.length) === '') return setVal('')

  const reg = /^[$]+?\d+?\.?\d*$/
  if (!reg.test(val)) return null

  const [int, float = ''] = val.substr(prefix.length).split('.')
  if (float.length > 2) return null

  const numAmount = Number.parseInt(val.substr(prefix.length))
  if (numAmount > 9999) return null

  setVal(val)
}

const DepositForm: React.FC<DepositFormP> = ({ payHashtag, isLoadSendPay, price }) => {
  const dispatch = useDispatch()
  const userInfo = useSelector<AppState, UserInfo>(({ user }) => user.get('userInfo'))
  const [val, setVal] = useState<string>('')
  const [disabled, setdisabled] = useState<boolean>(true)
  const [flag, setFlag] = useState<boolean>(false)
  const [showAddBalanceModal, setShowAddBalanceModal] = useState<boolean>(false)
  const { getEthBalance, cgClient, getEthPrice, getBNBPrice, walletConnecting } = useWallet()
  useEffect(() => {
    setFlag(true)
    return () => {
      setFlag(false)
    }
  }, [])

  useEffect(() => {
    setVal('')
  }, [isLoadSendPay])

  useEffect(() => {
    if (isLoadSendPay === true) {
      setdisabled(true)
      return
    }
    if (val === '') {
      setdisabled(true)
      return
    }
    setdisabled(false)
  }, [isLoadSendPay, val])

  const onShowBalanceModal = (setShowAddBalanceModal: Function) => (e: React.FormEvent<HTMLElement>) => {
    setShowAddBalanceModal(true)
    e.preventDefault()
  }
  const onCloseBalanceModal = (setShowAddBalanceModal: Function) => (e: React.FormEvent<HTMLElement>) => {
    setShowAddBalanceModal(false)
  }

  const onPayCrypto = (amount: string, type: PayP['type']) => async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (amount === null || typeof amount !== 'string') return
    const numAmount = Number.parseFloat(amount.slice(1))
    if (isNaN(numAmount)) return

    if (type == 'eth') {
      if (userInfo.eth_address === '' || userInfo.eth_address == null) {
        showToast('Please enter your ETH address in Profile page', '')
        return
      } else {
        const price = await getEthPrice()
        const tokenBalance = await getEthBalance(userInfo.eth_address, type)
        const ethAmount = (numAmount / price).toFixed(6)
        if (tokenBalance < Number(ethAmount)) {
          showToast('Insufficient ETH Balance', 'error')
          return
        } else {
          await payTokenFromWallet(TO_ADDRESS, SupportedChainId.GOERLI, type, ethAmount, numAmount, price)
        }
      }
    } else if (type == 'bnb') {
      if (userInfo.bnb_address === '' || userInfo.bnb_address == null) {
        showToast('Please enter your BNB address in Profile page', '')
        return
      } else {
        const price = await getBNBPrice()
        const bnbAmount = (numAmount / price).toFixed(6)
        const tokenBalance = await getEthBalance(userInfo.bnb_address, type)
        if (tokenBalance < Number(bnbAmount)) {
          showToast('Insufficient BNB Balance', 'error')
          return
        } else {
          await payTokenFromWallet(TO_ADDRESS, SupportedChainId.BNB_TEST, type, bnbAmount, numAmount, price)
        }
      }
    }
  }

  const payTokenFromWallet = async (
    walletAddress: string,
    chainId: number,
    type: PayC['type'],
    tokenAmount: string,
    usdAmount: number,
    rate: number
  ) => {
    try {
      const res = await walletConnecting(walletAddress, type, chainId, tokenAmount)
      if (res && res?.confirmations > 0 && res?.blockNumber > 0) {
        const transactionType: PayC['transactionType'] = 'payCrypto'
        const hash = res.transactionHash
        const amount = usdAmount.toString()
        dispatch(sendCryptoPayData({ amount, rate, type, transactionType, hash }))
      } else {
        showToast('Failed to send token with some reasons', 'error')
      }
    } catch (err: any) {
      if (err.message.includes('rejected')) {
        showToast('Action rejected', 'error')
      } else if (err.message.includes('insufficient funds')) {
        showToast('Insufficient funds! Please check your wallet balance', 'info')
      } else if (err.message.includes('detectedNetwork')) {
        showToast('Invalid network!', 'info')
      } else {
        showToast('Error:' + err.message, 'error')
      }
    }
  }
  const themeMode = useGetThemeMode()

  return (
    <CSSTransition in={flag} timeout={2500} classNames="bridgeFadeTransition" unmountOnExit>
      <div className={themeMode == 'main' ? cn.depositForm : cn.depositForm_l}>
        <Title title="Add #Credits" feePercent={val ? val : '$0.00'} themeMode={themeMode}></Title>
        <label
          className={cn.formLabel}
          style={{
            color: themeMode == 'main' ? '#fff' : themeMode == 'light' ? 'black' : '#fff',
          }}
        >
          Amount in USD (MHC, ETH, BNB will be converted automatically)
        </label>
        <form>
          <div className={cn.payInp}>
            <FormInput
              value={val}
              placeholder={'$ 0.00'}
              isFocusStyle={true}
              onChange={onChange(setVal)}
              themeMode={themeMode}
            />
          </div>
          <div className={cn.btnCont}>
            <button
              className={cn.payBtn}
              disabled={disabled}
              onClick={onPay(payHashtag, val, 'paypal')}
              style={{
                background: disabled
                  ? '#606061'
                  : themeMode === 'main'
                  ? 'linear-gradient(#873f99, #311438)'
                  : 'linear-gradient(#b7eaf6, #777fc4)',
                color: disabled ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
              }}
            >
              {/* <img src={PayPalSymbolImg} alt="" /> */}
              PayPal
            </button>
            <button
              className={cn.payBtn}
              disabled={true}
              onClick={onPay(payHashtag, val, 'metahash')}
              style={{
                background: true
                  ? '#606061'
                  : themeMode === 'main'
                  ? 'linear-gradient(#873f99, #311438)'
                  : 'linear-gradient(#b7eaf6, #777fc4)',
                color: disabled ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
              }}
            >
              <span className={cn.mhcTtext}># MHC</span>
            </button>
            <button
              className={cn.payBtn}
              disabled={true}
              onClick={onPayCrypto(val, 'eth')}
              style={{
                background: true
                  ? '#606061'
                  : themeMode === 'main'
                  ? 'linear-gradient(#873f99, #311438)'
                  : 'linear-gradient(#b7eaf6, #777fc4)',
                color: disabled ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
                width: '50px',
              }}
            >
              <span>ETH</span>
            </button>
            <button
              className={cn.payBtn}
              disabled={true}
              onClick={onPayCrypto(val, 'bnb')}
              style={{
                background: true
                  ? '#606061'
                  : themeMode === 'main'
                  ? 'linear-gradient(#873f99, #311438)'
                  : 'linear-gradient(#b7eaf6, #777fc4)',
                color: disabled ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
                width: '50px',
              }}
            >
              <span>BNB</span>
            </button>
          </div>
        </form>
        <AddCryptoBalanceModal showModal={showAddBalanceModal} onClose={onCloseBalanceModal(setShowAddBalanceModal)} />
      </div>
    </CSSTransition>
  )
}

function mapStateToProps({ transaction }: { transaction: Record<Transaction> }) {
  return {
    isLoadSendPay: transaction.get('isLoadSendPay'),
    price: transaction.get('price'),
  }
}

export default connect(mapStateToProps, { payHashtag })(DepositForm)
