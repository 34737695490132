import { Record } from 'immutable'
import { TAccountDomains, TAccountList } from '../../../utils/api'

export interface Init {
  accountList: TAccountList
  accountItemDomains: TAccountDomains['items']
}

export const init: Init = {
  accountList: [],
  accountItemDomains: [],
}

type Actions<T> = { [P in keyof T]: { type: P; payload: T[P] } } extends {
  [P in keyof { [P in keyof T]: { type: P; payload: T[P] } }]: infer P
}
  ? P
  : never

export type TActionsAccounts = Actions<Init>

const State: Record.Factory<Init> = Record(init)

const reducer = function (state: Record<Init> = new State(), action: TActionsAccounts): Record<Init> {
  return state.set(action.type, action.payload)
}

export default reducer
