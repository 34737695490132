import React from 'react'
import { FlexRow, SubContainer, TitleText } from '../../styled'
import { EarningData } from '.'
import { MHCBuying } from './MHCBuying'
import { RecurringCredits } from './RecurringCredits'
import { MHCLoans } from './MHCLoans'
import { CrossBridge } from './CrossBridge'
import { useGetBridgeEarning } from '../../hooks/useBridgeEarning'
import { useGetThemeMode } from 'state/theme/hook'

export const BridgeData: React.FC = () => {
  const { paypalEarning, mhcEarning, profit } = useGetBridgeEarning()
  const themeMode = useGetThemeMode()

  return (
    <SubContainer width={'100%'} themeMode={themeMode}>
      <FlexRow>
        <TitleText>Bridge Data</TitleText>
      </FlexRow>
      <FlexRow margin={'24px 0'}>
        <EarningData title={'TOTAL EARNED IN USD'} value={`$ ${paypalEarning.toFixed(2)}`} />
        <EarningData title={'TOTAL EARNED IN MHC'} value={`${mhcEarning.toFixed(6)} MHC`} />
        <EarningData title={'%PROFIT'} value={`${profit.toFixed(2)}%`} />
      </FlexRow>
      <FlexRow>
        <MHCBuying />
        <RecurringCredits />
        <MHCLoans />
        <CrossBridge />
      </FlexRow>
    </SubContainer>
  )
}
