import React from 'react'
import { NavLink } from 'react-router-dom'
import { useGetThemeMode } from 'state/theme/hook'
import './styles.scss'
import LOGO from '../../assets/logo.png'
const Logo = () => {
  const themeMode = useGetThemeMode()

  return (
    <NavLink to="/">
      <img src={LOGO} alt="logo image" className="logo_img" />
    </NavLink>
  )
}
export default Logo
