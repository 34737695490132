import { Record } from 'immutable'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FiRefreshCw } from 'react-icons/fi'
import { connect } from 'react-redux'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import {
  getListTransactionForAdm,
  onRecheckIncomplete,
  rernderListTransactionForAdm,
  setListTransactionFilterTextForAdmin,
} from '../../../actions/transaction'
import PayTable, { ColumnDefs } from '../../../components/PayTable'
import { Init as Transaction } from '../../../redusers/transaction'
import { ItemTransaction } from '../../../utils/api'
import { AdminTransactionListContainer, RecheckBtn, TransactionSearchContainer, TransactionSearchInput } from './style'

const PayContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  width: ${({ width }) => `calc(100% - ${width})`};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const columnDefs: ColumnDefs = [
  { headerName: '', field: 'hash', isKey: true, hide: true },
  {
    headerName: 'Date',
    field: 'date_timestamp',
    display: (row: any) => moment(row.dateTimestamp * 1000).format('MMM DD, YYYY  HH:mm'),
    styleClass: 'dateCell',
  },
  {
    headerName: 'User',
    field: 'user_email',
    display: (row: any) => row.user_email.split('@')[0],
    styleClass: 'userCell',
  },
  {
    headerName: 'Amount',
    field: 'amount',
    display: (row: any) => row.amount.toFixed(2) + ' ' + row.currency,
  },
  {
    headerName: 'Discount',
    field: 'discount',
    display: (row: any) => row.discount + ' %',
  },
  {
    headerName: 'Origin amount',
    field: 'originAmount',
    display: (row: any) =>
      (row.product === 'MHC' || row.product === 'ETH' || row.product === 'BNB'
        ? row.originAmount.toFixed(6)
        : row.originAmount.toFixed(2)) +
      ' ' +
      row.originCurrency,
  },
  {
    headerName: 'Description',
    field: 'description',
    display: (row: any) => {
      row.description.replace(' resale', '')
      if ((row.product === '#Credit' || row.product === 'Direct') && row.description.slice(1) !== '#') {
        return `#${row.description}`
      } else if (row.description.indexOf('renewed_domain') !== -1) {
        return row.description.replace('renewed_domain', '')
      }

      return row.description
    },
  },
  {
    headerName: 'Type',
    field: 'type',
    display: (row: any) => {
      if (row.description.includes(' resale')) {
        row.type = 'resold'
      }
      if (row.product === '#Credit') {
        return row.type === 'deposit' ? 'Received' : 'Sent'
      } else if (row.product === 'Direct') {
        if (row.paySystem === 'ETH') {
          return row.type === 'deposit' ? 'E-Received' : 'E-Sent'
        } else if (row.paySystem === 'BNB') {
          return row.type === 'deposit' ? 'B-Received' : 'B-Sent'
        } else {
          return row.type === 'deposit' ? 'D-Received' : 'D-Sent'
        }
      } else if (row.product === 'GIFT_DOMAIN') {
        return row.type === 'deposit' ? 'G-Received' : 'G-Sent'
      } else if (row.description.indexOf('renewed_domain') !== -1) {
        return 'renewed'
      }
      return row.type
    },
  },
  {
    headerName: 'Product',
    field: 'product',
    display: (row: any) => {
      if (row.product === 'Direct') {
        return row.paySystem === 'paypal' ? 'USD' : 'MHC'
      } else if (row.product === 'GIFT_DOMAIN') {
        return 'DOMAIN'
      }
      return row.product
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    display: (row: any) => row.status,
  },
]

const limit = 10
interface ListTransactionP {
  getListTransactionForAdm: typeof getListTransactionForAdm
  listTransactionForAdm: Array<ItemTransaction>
  listTransactionCountForAdm: number
  rernderListTransactionForAdm: Function
  setListTransactionFilterTextForAdmin: Function
  onRecheckIncomplete: () => void
  isLoadingRecheckIncomplete: boolean
}

const onChangeSearch =
  (
    getListTransactionForAdm: ListTransactionP['getListTransactionForAdm'],
    setListTransactionFilterTextForAdmin: ListTransactionP['setListTransactionFilterTextForAdmin'],
    setSearchVal: (v: string) => void
  ) =>
  (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value
    setSearchVal(val)
    if (val.length < 3 && val !== '') return
    setListTransactionFilterTextForAdmin(val)
    getListTransactionForAdm({ search: val, limit, isClear: true })
  }

const getList = (searchVal: string, getListTransactionForAdm: ListTransactionP['getListTransactionForAdm']) => () => {
  getListTransactionForAdm({ search: searchVal, limit, isClear: false })
}

const ListTransaction: React.FC<ListTransactionP> = ({
  getListTransactionForAdm,
  listTransactionForAdm,
  listTransactionCountForAdm,
  rernderListTransactionForAdm,
  onRecheckIncomplete,
  setListTransactionFilterTextForAdmin,
  isLoadingRecheckIncomplete,
}) => {
  const isCollapseMenu = useIsCollapseMenu()
  const [searchVal, setSearchVal] = useState<string>('')

  useEffect(() => {
    getListTransactionForAdm({ search: '', limit: limit, isClear: true })
    return () => rernderListTransactionForAdm()
  }, [])

  const themeMode = useGetThemeMode()

  return (
    <PayContainer width={isCollapseMenu ? '65px' : '235px'}>
      <TransactionSearchContainer>
        <TransactionSearchInput
          type="text"
          placeholder={'Search'}
          onChange={onChangeSearch(getListTransactionForAdm, setListTransactionFilterTextForAdmin, setSearchVal)}
          value={searchVal}
          style={{
            background: themeMode == 'main' ? '#00000090' : '#ffffff90',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        />
        <RecheckBtn
          disabled={isLoadingRecheckIncomplete}
          onClick={isLoadingRecheckIncomplete ? undefined : onRecheckIncomplete}
          style={{
            background: themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
            color: themeMode == 'main' ? '#fff' : '#000',
          }}
        >
          <FiRefreshCw />
        </RecheckBtn>
      </TransactionSearchContainer>
      <PayTable<ItemTransaction>
        columnDefs={columnDefs}
        columnRow={listTransactionForAdm}
        loadf={getList(searchVal, getListTransactionForAdm)}
        dataLength={listTransactionForAdm.length}
        hasMore={listTransactionCountForAdm > listTransactionForAdm.length}
      />
    </PayContainer>
  )
}

function mapStateToProps({ transaction }: { transaction: Record<Transaction> }) {
  return {
    listTransactionForAdm: transaction.get('listTransactionForAdm'),
    listTransactionCountForAdm: transaction.get('listTransactionCountForAdm'),
    isLoadingRecheckIncomplete: transaction.get('isLoadingRecheckIncomplete'),
  }
}

export default connect(mapStateToProps, {
  getListTransactionForAdm,
  rernderListTransactionForAdm,
  onRecheckIncomplete,
  setListTransactionFilterTextForAdmin,
})(ListTransaction)
