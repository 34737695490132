import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import { BsBookmarkPlus, BsFacebook, BsFillBookmarkCheckFill, BsInstagram, BsLinkedin, BsTwitter } from 'react-icons/bs'
import { BiBookmark, BiCollapse, BiExpand } from 'react-icons/bi'
import ReactTooltip from 'react-tooltip'
import { AudioItem } from 'components/Menu/tooltip'
import { FiExternalLink } from 'react-icons/fi'
import Avatar from 'react-avatar'
import { onAddFavoriteDomain } from 'actions/hashtag'
import { FB_URL, IN_URL, LN_URL, SOCIAL_URL, TW_URL } from 'constants/urls'
import { AppState } from 'redusers/config'
import { TR_Search } from 'views/Search/types'
import { onChangeFavAddStatus } from 'views/Search/actions'
import { Init as UserInit } from 'redusers/user'
import { useTargetURLProcess } from 'hooks/useModal'

const ModalContainer = styled.div`
  width: 80%;
  height: 85%;
  z-index: 99;
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
  padding: 20px;
  box-size: border-box;
  border: 1px solid gray;
  border-radius: 10px;
`
const Head = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 700;
  margin: -10px 0 10px 0;
`
const ModalUser = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
`
const ModalTitle = styled.span`
  font-size: 40px;
  text-align: center;
`
const ModalMark = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const CloseButton = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`
const BookMark = styled.span`
  display: flex;
  align-items: center;
  margin-right: 25px;
  font-size: 14px;
  :hover {
    transform: scale(1.1);
  }
`
const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  height: 94%;
`
const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 100%;
  padding: 10px 0;
  overflow: auto;
`
const Social = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
  padding: 0 5px 0 15px;
`
const ViewOn = styled.div`
  font-size: 14px;
  margin: 5px 0;
`
const RowItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const SocialIcons = styled.span`
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`
const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const TargetLink = styled.span`
  display: flex;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
`
const ExternalLink = styled.span`
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  :hover {
    transform: scale(1.1);
  }
`
const URLBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 3px;
  margin-left: 5px;
`
const TargetURLModal: React.FC<any> = () => {
  const userInfo = useSelector<AppState, UserInit['userInfo']>(({ user }) => user.get('userInfo'))
  const loginUserId = userInfo.id
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()

  const { urlData, domainN, nick, name, favoriteVal, showTooltip } = useTargetURLProcess()
  const arr = urlData.split(',')
  const isAddedFav = useSelector<AppState, TR_Search['favAddStatus']>(({ search_reducer }) =>
    search_reducer.get('favAddStatus')
  )

  const [eachUrl, setEachUrl] = useState<string>('')
  const [isExpend, setIsExpend] = useState<boolean>(false)
  const [isFaved, setIsFaved] = useState<boolean>(false)

  const setFavoriteStatus = useCallback(() => {
    if (favoriteVal != '') {
      const favVal = favoriteVal
      const favArr = favVal.split(',')
      if (favArr.includes(loginUserId.toString())) setIsFaved(true)
    }
  }, [favoriteVal, loginUserId])

  useEffect(() => {
    ReactTooltip.rebuild()
    AudioItem('target-pop').play()
    setFavoriteStatus()
  }, [favoriteVal, setFavoriteStatus])

  const onHover = useCallback(() => {
    ReactTooltip.rebuild()
  }, [])

  const openNewTab = useCallback((item: string) => {
    AudioItem('tab-launch').play()
    window.open(item, '_blank')
  }, [])

  const onModalExpend = useCallback(() => {
    setIsExpend(!isExpend)
  }, [isExpend])

  const onProcessFavorite = () => {
    let fav_val = ''
    if (isFaved) {
      if (favoriteVal != '') {
        const favArr = favoriteVal.split(',').filter((v) => v != loginUserId.toString())
        fav_val = favArr.join()
      }
    } else {
      if (favoriteVal == '') {
        fav_val = loginUserId.toString()
      } else {
        if (favoriteVal.split(',').includes(loginUserId.toString())) return
        fav_val = favoriteVal + ',' + loginUserId
      }
    }
    dispatch(
      onAddFavoriteDomain({
        domainId: domainN,
        favoriteVal: fav_val,
      })
    )
  }

  const onSelectURL = useCallback(
    (url) => () => {
      let showUrl = url
      if (url.includes('youtube.com') && !url.includes('embed')) {
        showUrl = url.replace('watch?v=', 'embed/')
      }
      setEachUrl(showUrl)
    },
    []
  )
  const goSpaceBar = () => {
    AudioItem('btn-go').play()
    window.open(SOCIAL_URL + nick, '_blank')
  }

  let urlTxt = eachUrl === '' ? arr[0] : eachUrl
  const userName = name.split(',')[0] + ' ' + name.split(',')[1]

  useEffect(() => {
    if (isAddedFav) {
      setIsFaved(!isFaved)
    }
    dispatch(onChangeFavAddStatus(false))
  }, [dispatch, isAddedFav])

  const openSocial = useCallback((flag: string, domain_name: string) => {
    AudioItem('tab-launch').play()
    const domainStr = domain_name.substring(1).toLocaleLowerCase()
    let base_social = ''
    if (flag == 'fb') base_social = FB_URL + 'hashtag/' + domainStr
    else if (flag == 'in') base_social = IN_URL + 'explore/tags/' + domainStr
    else if (flag == 'tw') base_social = TW_URL + 'search?q=%23' + domainStr + '&src=typed_query&f=top'
    else if (flag == 'ln') base_social = LN_URL + 'feed/hashtag/' + domainStr

    window.open(base_social, '_blank')
  }, [])

  return (
    <ModalContainer
      style={{
        background: themeMode == 'main' ? '#000000d5' : '#ffffffe5',
        color: themeMode == 'main' ? 'white' : 'black',
        width: isExpend ? '100%' : '',
        height: isExpend ? '100%' : '',
      }}
    >
      <Head>
        <ModalUser onClick={goSpaceBar}>
          <Avatar
            src={undefined}
            name={userName}
            size="44"
            round="50px"
            color={themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)'}
            onClick={goSpaceBar}
          />
        </ModalUser>
        <ModalTitle>{domainN}</ModalTitle>
        <ModalMark>
          <BookMark onClick={onProcessFavorite} style={{ color: isFaved ? '#ef744a' : '' }}>
            {isFaved ? (
              <BsFillBookmarkCheckFill size={20} style={{ marginRight: '3px' }} />
            ) : (
              <BsBookmarkPlus size={20} style={{ marginRight: '3px' }} />
            )}
            BOOKMARK
          </BookMark>
          <CloseButton onClick={onModalExpend}>
            {isExpend ? <BiCollapse size={30} /> : <BiExpand size={30} />}
          </CloseButton>
        </ModalMark>
      </Head>
      <ModalBody>
        <FrameContainer>
          {urlTxt !== '' && (
            <iframe
              id="ifr_target"
              src={urlTxt}
              style={{
                borderRadius: '10px',
                height: '100%',
                border: '0.1px solid gray',
              }}
              loading="eager"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              sandbox="allow-scripts allow-same-origin allow-presentation allow-popups"
            ></iframe>
          )}
        </FrameContainer>
        <PreviewContainer>
          <div style={{ height: 'calc(100vh - 60px)' }}>
            {arr.map((item, index) => (
              <URLBody
                key={index}
                data-for="target-tip"
                data-tip={item}
                style={{
                  color:
                    themeMode === 'light'
                      ? urlTxt === item
                        ? '#0095ff'
                        : '#575555'
                      : urlTxt === item
                      ? '#0095ff'
                      : '#c5c4c4',
                  border:
                    themeMode === 'light'
                      ? urlTxt === item
                        ? '0.5px solid #0095ff'
                        : ''
                      : urlTxt === item
                      ? '0.5px solid #0095ff'
                      : '',
                }}
              >
                <TargetLink onClick={onSelectURL(item)} onMouseOver={onHover}>
                  <BiBookmark size={25} />
                  <span style={{ marginLeft: '5px' }}>#{index + 1}</span>
                </TargetLink>
                <ExternalLink onClick={() => openNewTab(item)}>
                  <FiExternalLink size={20} color={themeMode === 'light' ? '#000' : '#fff'} />
                </ExternalLink>
              </URLBody>
            ))}
          </div>
          <Social>
            <ViewOn>VIEW ON:</ViewOn>
            <RowItem>
              <SocialIcons style={{ borderColor: '#4b2feb' }} onClick={() => openSocial('fb', domainN)}>
                <BsFacebook size={18} color={'#4b2feb'} />
              </SocialIcons>
              <SocialIcons style={{ borderColor: '#bf2feb' }} onClick={() => openSocial('in', domainN)}>
                <BsInstagram size={18} color={'#bf2feb'} />
              </SocialIcons>
              <SocialIcons style={{ borderColor: '#2f87eb' }} onClick={() => openSocial('tw', domainN)}>
                <BsTwitter size={18} color={'#2f87eb'} />
              </SocialIcons>
              <SocialIcons style={{ borderColor: '#0c4fe0' }} onClick={() => openSocial('ln', domainN)}>
                <BsLinkedin size={18} color={'#0c4fe0'} />
              </SocialIcons>
            </RowItem>
          </Social>
        </PreviewContainer>
      </ModalBody>
      <ReactTooltip
        id="target-tip"
        effect="solid"
        place="left"
        className="tooltip-open"
        multiline={true}
        type={themeMode === 'light' ? 'dark' : 'info'}
        disable={showTooltip ? false : true}
      />
    </ModalContainer>
  )
}
export default TargetURLModal
