import Loader from 'components/Loader'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import { BsBookmarkPlus, BsFillBookmarkCheckFill } from 'react-icons/bs'
import { FiExternalLink } from 'react-icons/fi'
import { onAddFavoriteDomain } from 'actions/hashtag'
import { IGetAllDomainListR, TAccountItem } from 'utils/api'
import { useGetDomainList } from 'views/Search/hooks/availableCategoryList'
import { MdClose } from 'react-icons/md'
import { MODAL_TYPES, useGlobalModalContext } from '../GlobalModal'
import { useIsShowTooltip } from 'state/toolTip/hook'

const ModalContainer = styled.div`
  width: 550px;
  height: 650px;
  z-index: 99;
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
  box-size: border-box;
  border: 1px solid gray;
  border-radius: 10px;
`
const ModalTitle = styled.div`
  font-size: 25px;
  font-weight: 700;
  padding: 30px 20px;
  text-decoration: underline;
`
const CloseButton = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`
const BookMark = styled.span`
  display: flex;
`
const BookMarkItem = styled.span`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
`
const ExternalLink = styled.span`
  margin-left: 25px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
`
const RowItem = styled.span`
  display: flex;
  align-items: center;
  font-size: 18px;
`
const ModalBody = styled.div`
  font-size: 18px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 20px;
  height: 100%;
`
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0 3px 0;
  font-weight: 600;
  border-bottom: 1px solid gray;
  border-bottom-style: dashed;
`
const FavoritesModal: React.FC<any> = () => {
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const { hideModal, showModal } = useGlobalModalContext()
  const isShowTooltip = useIsShowTooltip()
  const { domainList, accountList, userInfo } = useGetDomainList()

  const [isFaved, setIsFaved] = useState<boolean>(true)

  const onRemoveFavorite = (domainN: string, favoriteVal: string) => {
    let fav_val = ''
    if (isFaved) {
      if (favoriteVal != '') {
        const favArr = favoriteVal.split(',').filter((v) => v != userInfo.id.toString())
        fav_val = favArr.join()
      }

      dispatch(
        onAddFavoriteDomain({
          domainId: domainN,
          favoriteVal: fav_val,
        })
      )
    }
  }

  const filterArr = domainList.filter(
    (v: IGetAllDomainListR) =>
      v.favorites !== null && v.favorites !== '' && v.favorites.split(',').includes(userInfo.id.toString())
  )

  const openTargetURLModal = (url: string, domainN: string, fav: string, userId: number) => {
    const arr: TAccountItem[] = accountList.filter((v: TAccountItem) => v.id === userId)
    const nick = arr[0] ? arr[0].nick : ''
    const name = arr[0] ? arr[0].first_name + ',' + arr[0].last_name : ''
    showModal(MODAL_TYPES.TARGET_URL_MODAL, {
      url,
      domainN,
      nick,
      name,
      fav,
      userId,
      isShowTooltip,
    })
  }

  return (
    <ModalContainer
      style={{
        background: themeMode == 'main' ? '#000000d5' : '#ffffffe5',
        color: themeMode == 'main' ? 'white' : 'black',
      }}
    >
      <ModalTitle>Favorite Domain list</ModalTitle>
      <ModalBody>
        {filterArr.length == 0 && (
          <Loading className="shift-loader-spinner">
            <Loader stroke="#58d7f7" size="40px" />
          </Loading>
        )}
        {filterArr.map((item, index) => (
          <Row key={index}>
            <RowItem>
              {index + 1} {item.domainName}
            </RowItem>
            <BookMark>
              <BookMarkItem
                style={{ color: isFaved ? '#fd6430' : '' }}
                onClick={() => onRemoveFavorite(item.domainName, item.favorites)}
              >
                {isFaved ? (
                  <BsFillBookmarkCheckFill size={20} style={{ marginRight: '3px' }} />
                ) : (
                  <BsBookmarkPlus size={20} style={{ marginRight: '3px' }} />
                )}
                BOOKMARK
              </BookMarkItem>
              <ExternalLink onClick={() => openTargetURLModal(item.url, item.domainName, item.favorites, item.userId)}>
                <FiExternalLink size={22} />
              </ExternalLink>
            </BookMark>
          </Row>
        ))}
      </ModalBody>
      <CloseButton onClick={hideModal}>
        <MdClose size={28} />
      </CloseButton>
    </ModalContainer>
  )
}
export default FavoritesModal
