import { createSlice } from '@reduxjs/toolkit'

interface IState {
  isShowTooltip: boolean
}

export const initialState: IState = {
  isShowTooltip: false,
}

const tooltipSlice = createSlice({
  name: 'tooltip',
  initialState,
  reducers: {
    setIsShowTooltip(state, action) {
      state.isShowTooltip = action.payload
    },
  },
})

export const { setIsShowTooltip } = tooltipSlice.actions
export default tooltipSlice.reducer
