import React, { CSSProperties, ReactElement } from 'react'
import cn from 'classnames'
import css from './styles.module.scss'

interface CheckboxP {
  cn__sinccheck?: string
  text?: string
  childre?: ReactElement
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  checked?: boolean
  readOnly?: boolean
  type?: 'toggle'
  style?: CSSProperties
  disabled?: boolean
  themeMode?: string
}

const Checkbox: React.FC<CheckboxP> = ({
  cn__sinccheck = '',
  text = '',
  children,
  onChange,
  checked = false,
  readOnly = false,
  type,
  style,
  disabled,
  themeMode,
}) => {
  return (
    <label
      className={cn(css.control, {
        cn__sinccheck: cn__sinccheck,
        readOnly: readOnly ? 'readOnly' : '',

        'control--checkbox': type !== 'toggle',
        'control--toggle': type === 'toggle',
      })}
      style={style}
    >
      {text}
      {children}
      <input type="checkbox" onChange={onChange} checked={checked} readOnly={readOnly} disabled={disabled} />
      <div
        className={css.indicator}
        style={{
          borderColor: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
        }}
      />
      <div className={css.toggle}>
        <div className={css.bar}></div>
      </div>
    </label>
  )
}
export default Checkbox
