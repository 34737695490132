import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onClear, onGetQRGA } from '../../actions/user'
import Preloader from '../../assets/gif/Preloader.gif'
import UnifiedModal1 from '../../components/Modals/UnifiedModal1'
import { AppState } from '../../redusers/config'
import modal from './modal.module.css'

type PModalGA = {
  isOpen: boolean
  setisOpenModal: (v: boolean) => void
  onSend: (v: string) => void
  title: string
}

const ModalGA: FC<PModalGA> = ({ isOpen, setisOpenModal, onSend, title }) => {
  const dispatch = useDispatch()
  const [code, setCode] = useState<string>('')
  const [codeError, setCodeError] = useState<boolean>(false)
  const urlQR = useSelector<AppState, string>(({ user }) => user.get('urlQR'))

  useEffect(() => {
    if (isOpen) {
      dispatch(onGetQRGA())
    } else {
      setCode('')
      setCodeError(false)
      dispatch(onClear())
    }
  }, [isOpen])

  const memoModalClose = useCallback(() => {
    setisOpenModal(false)
  }, [setisOpenModal])

  const memoOnChangeCode = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setCodeError(false)
      setCode(e.currentTarget.value)
    },
    [setCode]
  )

  const memoOnSave = useCallback(() => {
    if (code.length < 6) {
      setCodeError(true)
      return
    }
    setisOpenModal(false)
    onSend(code)
  }, [setisOpenModal, code])

  return (
    <UnifiedModal1 title={title} showModal={isOpen} onClose={memoModalClose} onSave={memoOnSave} btnSaveName={'Send'}>
      <div className={modal.container}>
        {urlQR ? <img className={modal.qr} src={urlQR} /> : <img className={modal.preloader} src={Preloader} />}
        <label className={codeError ? modal.error : ''}>Enter the received code</label>
        <input type="text" value={code} maxLength={6} onChange={memoOnChangeCode} />
      </div>
    </UnifiedModal1>
  )
}

export default ModalGA
