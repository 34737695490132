import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useDatePickerContext } from '../../contexts/DatePickerContext'
import { BigValueText, FlexRow } from '../../styled'

const currentYear = new Date().getUTCFullYear()

export const YearPicker = () => {
  const { currentDate, setCurrentDate } = useDatePickerContext()

  // const navigateList = (direction: 'prev' | 'next') => {
  //   if (direction === 'prev') {
  //     if (currentDate.year === 2020) return
  //     setCurrentDate({ ...currentDate, year: currentDate.year - 1 })
  //   } else {
  //     if (currentDate.year === currentYear) return
  //     setCurrentDate({ ...currentDate, year: currentDate.year + 1 })
  //   }
  // }

  return (
    <FlexRow margin={'0 0 12px'}>
      <BigValueText color={'#ffffff'} fontSize={'24px'}>
        {currentDate.year}
      </BigValueText>
    </FlexRow>
  )
}
