import React from 'react'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'
import styled from 'styled-components'
import './styles.scss'

const DomainsSearchContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `calc(100% - ${width})`};
  z-index: 0;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const DomainsSearch: React.FC<any> = ({ children }) => {
  const isCollapseMenu = useIsCollapseMenu()

  return <DomainsSearchContainer width={isCollapseMenu ? '65px' : '235px'}>{children}</DomainsSearchContainer>
}
export default DomainsSearch
