import cn from 'classnames'
import { Editor, EditorChange } from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/theme/material.css'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Controlled as CodeMirror } from 'react-codemirror2'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useGetThemeMode } from 'state/theme/hook'
import { onGetSettings } from '../../actions/hashtag'
import Checkbox from '../../components/Checkbox'
import FormInput from '../../components/FormInput'
import EmailPreviewModal from '../../components/Modals/EmailPreviewModal'
import { Init } from '../../redusers/actions'
import { AppState } from '../../redusers/config'
import {
  fetchGetTemplate,
  fetchGetTemplateList,
  fetchPreviewTemplate,
  fetchSetStatusTemplate,
  fetchSetTemplate,
} from '../Admin/settingEmail/actions'
import { delIsPreviewTemplate, SendEmailAdmin } from '../Admin/settingEmail/reduser'
import css from './styles.module.scss'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'
import styled from 'styled-components'

const codeOptions = {
  mode: 'htmlmixed',
  theme: 'material',
  lineNumbers: true,
  showCursorWhenSelecting: true,
}

const EmailSettingContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: row;
  width: ${({ width }) => `calc(100% - ${width})`};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column-reverse;
  }
`

const EmailSettings: FC = () => {
  const [htmlCode, sethtmlCode] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [thisCode, setThisCode] = useState<string>('')
  const [status, setStatus] = useState<boolean>(false)
  const dispatch = useDispatch()

  let { code } = useParams<{ code: string | undefined }>()

  useEffect(() => {
    dispatch(fetchGetTemplateList())
    dispatch(onGetSettings())
  }, [])

  const settings = useSelector<AppState, Init['settings']>(({ actions }) => actions.get('settings'), shallowEqual)

  const templateList = useSelector<AppState, SendEmailAdmin['templateList']>(
    ({ sendEmailAdminReducer }) => sendEmailAdminReducer.templateList,
    shallowEqual
  )
  const template = useSelector<AppState, SendEmailAdmin['template']>(
    ({ sendEmailAdminReducer }) => sendEmailAdminReducer.template,
    shallowEqual
  )
  const templateD = template.get('data')

  useEffect(() => {
    if (!templateD) return
    sethtmlCode(templateD.template.body)
    setTitle(templateD.template.title)
    setStatus(templateD.template.status)
  }, [templateD])

  useEffect(() => {
    if (!code) return
    setThisCode(code)
  }, [code])

  useEffect(() => {
    if (code) return
    const TLD = templateList.get('data')
    if (!TLD) return
    setThisCode(TLD[0].code)
  }, [templateList])

  useEffect(() => {
    if (thisCode) {
      dispatch(fetchGetTemplate(thisCode))
      return
    }
  }, [thisCode])

  const memoizedDescription = useCallback((editor: Editor, data: EditorChange, value: string) => {
    sethtmlCode(value)
  }, [])

  const templateListD = templateList.get('data')

  const onSaveTempl = useCallback(() => {
    if (!templateD) return
    dispatch(
      fetchSetTemplate({
        code: templateD.template.code,
        fields: { body: htmlCode, title, status },
      })
    )
  }, [templateD, htmlCode, title, status])

  const onCansel = useCallback(() => {
    if (!templateD) return

    dispatch(fetchGetTemplate(templateD.template.code))
  }, [templateD])

  const themeMode = useGetThemeMode()
  const isCollapseMenu = useIsCollapseMenu()

  return (
    <>
      <EmailSettingContainer width={isCollapseMenu ? '65px' : '235px'}>
        <div className={themeMode === 'light' ? css.template_light : css.template}>
          <div className={css.title}>
            <span>{templateD?.template.name}</span>
            <FormInput
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
              themeMode={themeMode}
            />
          </div>
          <Tabs className={css.tabs}>
            <TabList className={css.tablist}>
              <Tab className={css.tab}>Code</Tab>
              <Tab className={css.tab}>Info</Tab>
            </TabList>
            <TabPanel className={css.tabpanel}>
              <CodeMirror
                value={htmlCode}
                options={codeOptions}
                onBeforeChange={memoizedDescription}
                className={css.CodeMirror}
              />
              {templateD?.macro.map((item) => {
                return (
                  <p key={item.name} className="hint">
                    {item.name} - {item.description}
                  </p>
                )
              })}
            </TabPanel>
            <TabPanel className={css.tabpanel}>
              <hr />
              <h4>Sending options</h4>
              <p>
                Need an option for them to turn on auto renewal for their domains so they autodial when the year is up.
              </p>
              <p>
                We would like to email them a notice when they are 30 days away from their domain expiring, again 1 week
                prior, again on the day it expires, and once more 30 days after it expires.
              </p>
              <h4>Status</h4>
              <Checkbox
                text="Send as planned"
                type="toggle"
                style={{ marginTop: '20px' }}
                onChange={(e) => {
                  const v = e.currentTarget.checked
                  setStatus(v)
                  if (!thisCode) return
                  dispatch(
                    fetchSetStatusTemplate({
                      code: thisCode,
                      status: v,
                    })
                  )
                }}
                checked={status}
                disabled={!settings.notification}
              />
              <p>
                Go to <Link to="/admin/settings/">General Settings</Link>
              </p>
            </TabPanel>
          </Tabs>
          <div className={css.actions}>
            <button onClick={onCansel} className={cn(css.button, 'transparent')}>
              <span>Cancel</span>
            </button>
            <div className="spacer"></div>
            <button
              className={cn(css.button)}
              onClick={() => {
                if (!thisCode) return
                dispatch(
                  fetchPreviewTemplate({
                    code: thisCode,
                    fields: { body: htmlCode },
                  })
                )
                setOpenModal(true)
              }}
            >
              <span>Preview</span>
            </button>
            <button onClick={onSaveTempl} className={cn(css.button)}>
              <span>save template</span>
            </button>
          </div>
        </div>
        <div className={themeMode === 'light' ? css.theme_light : css.theme}>
          {templateListD?.map((item) => {
            return (
              <NavLink
                to={`/admin/notification-settings/${item.code}`}
                key={item.code}
                className={cn(css.item, settings.notification ? (item.status ? 'green' : 'red') : 'red')}
              >
                <div className={css.status} />
                <div className={css.text}>
                  <span>{item.name}</span>
                </div>
              </NavLink>
            )
          })}
        </div>
      </EmailSettingContainer>
      <EmailPreviewModal
        showModal={openModal}
        onCloseModal={() => {
          setOpenModal(false)
          dispatch(delIsPreviewTemplate())
        }}
      />
    </>
  )
}

export default EmailSettings
