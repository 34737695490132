import React from 'react'
import { useGetThemeMode } from 'state/theme/hook'
import { BigValueText, FlexRow, FlexRowContent, SubContainer, SubTitleText } from '../../styled'
import { IEarningDataProps } from '../../types'

export const EarningData: React.FC<IEarningDataProps> = (data) => {
  const themeMode = useGetThemeMode()

  return (
    <SubContainer width={'33%'} height="70px" themeMode={themeMode}>
      <FlexRowContent>
        <SubTitleText fontSize={'12px'}>{data.title}</SubTitleText>
        <BigValueText color={'#0084ff'} fontSize={'26px'}>
          {data.value}
        </BigValueText>
      </FlexRowContent>
    </SubContainer>
  )
}
