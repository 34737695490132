import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import './styles.scss'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchAddEmails, fetchGetList, fetchRemoveEmail, fetchSendEmails, fetchSendTo } from './actions'
import { AppState } from '../../redusers/config'
import type { SendEmail as TSendEmail } from './reduser'
import ReactTable, { createTheme } from 'react-data-table-component'
import { useGetThemeMode } from 'state/theme/hook'
import { useCommonCheck } from 'hooks/useCommon'

createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
    fontFamily: '"Montserrat", sans-serif',
  },
  background: {
    default: '#00000030',
  },
  context: {
    background: '#FFFFFF',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: '#FFFFFF',
    hover: '#FFFFFF',
    disabled: '#FFFFFF',
  },
  highlightOnHover: {
    default: '#000000',
    text: '#FFFFFF',
  },
  sortFocus: {
    default: '#FFFFFF',
  },
})

createTheme('solarized_light', {
  text: {
    primary: '#000',
    secondary: '#000',
    fontFamily: '"Montserrat", sans-serif',
  },
  background: {
    default: '#ffffff50',
  },
  context: {
    background: '#000',
    text: '#000',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: '#000',
    hover: '#000',
    disabled: '#000',
  },
  highlightOnHover: {
    default: '#00000020',
    text: '#000',
  },
  sortFocus: {
    default: '#000',
  },
})

const SendEmail: FC = () => {
  const themeMode = useGetThemeMode()
  const dispatch = useDispatch()
  const { showToastMsg } = useCommonCheck()

  const formik = useFormik({
    initialValues: {
      emailList: '',
    },
    validationSchema: Yup.object({
      emailList: Yup.string().required('Required'),
    }),
    onSubmit: (values, formikHelpers) => {
      const listEmails = values.emailList.split('\n')
      dispatch(fetchAddEmails(listEmails))
    },
  })
  const addEmails = useSelector<AppState, TSendEmail['addEmails']>(
    ({ sendEmailReducer }) => sendEmailReducer.addEmails,
    shallowEqual
  )
  const emailList = useSelector<AppState, TSendEmail['emailList']>(
    ({ sendEmailReducer }) => sendEmailReducer.emailList,
    shallowEqual
  )
  console.log('=====emailList::', JSON.stringify(emailList))
  const sendEmails = useSelector<AppState, TSendEmail['sendEmails']>(
    ({ sendEmailReducer }) => sendEmailReducer.sendEmails,
    shallowEqual
  )
  useEffect(() => {
    if (sendEmails.get('data') === true && sendEmails.get('isLoading') === true) {
      showToastMsg('Mail has been sent', 'success')
    }
  }, [sendEmails])

  const onDelEmail = useCallback(
    (email: string) => () => {
      dispatch(fetchRemoveEmail(email))
    },
    []
  )
  const onSendEmail = useCallback(
    (email: string) => () => {
      dispatch(fetchSendTo(email))
    },
    []
  )

  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
        fontSize: '14px',
      },
    },
    subHeader: {
      style: {
        padding: 0,
        minHeight: '43px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '20px',
        paddingRight: '8px',
        fontSize: '14px',
        fontWeight: 600,
      },
    },
    cells: {
      style: {
        paddingLeft: '20px',
        paddingRight: '8px',
      },
    },
    pagination: {
      pageButtonsStyle: {
        fill: themeMode === 'main' ? 'white' : 'black',
        '&:disabled': {
          fill: themeMode === 'main' ? 'gray' : 'gray',
        },
        '&:hover:not(:disabled)': {
          backgroundColor: 'gray',
        },
      },
    },
  }

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => 'nick',
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: 'Last Sent',
      selector: (row: any) => row.lastSend,
      cell: ({ lastSend }: { lastSend: number | null }) => {
        if (!lastSend) return null
        const tM = lastSend * 1000
        return moment(tM).format('MMM DD, YYYY  hh:mm')
      },
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row: { email: string }) => {
        return (
          <div className="action-btn-container">
            <button onClick={onSendEmail(row.email)} className="tableBtn">
              Send email
            </button>
            <button onClick={onDelEmail(row.email)} className="tableBtn">
              delete
            </button>
          </div>
        )
      },
      sortable: true,
    },
  ]

  useEffect(() => {
    const data = addEmails.get('data')
    if (data) {
      formik.resetForm()
      return
    }
    const er = addEmails.get('error')
    if (!er.isError) return
    formik.setFieldError('emailList', er.message)
  }, [addEmails])

  useEffect(() => {
    dispatch(fetchGetList())
  }, [dispatch])

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className="invite-form"
        style={{
          background: themeMode == 'main' ? '#00000090' : '#ffffff90',
          color: themeMode == 'main' ? 'white' : 'black',
        }}
      >
        <div className="invite-friend">
          <div className="desc-container">
            <h4 className="">Invite Friends</h4>
            <div className="email-desc">Enter a list of emails separated by a line break </div>
          </div>
          <textarea
            className="txtareaitem"
            value={formik.values.emailList}
            name="emailList"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            cols={3}
            style={{
              background: themeMode === 'main' ? '#00000090' : '#ffffff90',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          />
          <div className="btn-container">
            <button
              className="formBtn"
              disabled={!formik.isValid}
              style={{
                background:
                  themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                color: themeMode == 'main' ? 'white' : 'black',
              }}
            >
              ADD
            </button>
            <button
              className="formBtn"
              disabled={!formik.isValid}
              style={{
                background:
                  'linear-gradient(rgb(165, 165, 165), rgb(243, 243, 243), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(243, 243, 243), rgb(165, 165, 165))',
                color: 'black',
              }}
            >
              SYNCHRONIZE
            </button>
          </div>
        </div>
      </form>
      <div className="invite-table">
        <ReactTable
          keyField={'email'}
          theme={themeMode === 'main' ? 'solarized' : 'solarized_light'}
          data={emailList.get('data') ?? []}
          columns={columns}
          customStyles={customStyles}
          defaultSortFieldId={'email'}
          highlightOnHover
          pagination
          noDataComponent={<></>}
        />
      </div>
    </>
  )
}

export default SendEmail
