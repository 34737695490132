import React from 'react'
import cn from 'classnames'
import './styles.scss'

const MainContent: React.FC<any> = ({ children, cn__maincontent_startpage, cn__maincontent_domaineditpage }) => {
  return (
    <section
      className={cn('main-contentss', {
        cn__maincontent_startpage: cn__maincontent_startpage,
        cn__maincontent_domaineditpage: cn__maincontent_domaineditpage,
      })}
    >
      {children}
    </section>
  )
}
export default MainContent
