import React from 'react'
import './styles.scss'

const Dreaming: React.FC = () => {
  return (
    <div className="comingSoonMain">
      <h1>Dreaming Coming Soon!</h1>
    </div>
  )
}

export default Dreaming
