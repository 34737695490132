import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import ReactTable, { createTheme } from 'react-data-table-component'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import colors from '../../../components/colors'
import { AppState } from '../../../redusers/config'
import { fetchGetLastSalesReport } from '../actions'
import { TLastSalesReportItem } from '../api'
import { AffiliatesState } from '../reduser'
import cn from './cn.module.css'

createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
    fontFamily: '"Montserrat", sans-serif',
  },
  background: {
    default: 'rgba(0, 0, 0, 0.5)',
  },
  context: {
    background: '#FFFFFF',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: '#FFFFFF',
    hover: '#FFFFFF',
    disabled: '#FFFFFF',
  },
  highlightOnHover: {
    default: 'rgba(0, 0, 0, 0.5)',
    text: '#FFFFFF',
  },
  sortFocus: {
    default: '#FFFFFF',
  },
})

const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
      fontSize: '14px',
    },
  },
  subHeader: {
    style: {
      padding: 0,
      minHeight: '43px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontSize: '14px',
      fontWeight: 600,
      borderTop: '2px solid #025498',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
}

const options = {
  responsive: true,
  title: {
    display: true,
    text: 'Affiliates rewards',
  },

  tooltips: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'D.MM.YYYY',
          },
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}
const columns = [
  {
    name: 'Email',
    selector: (row: TLastSalesReportItem) => 'email',
    sortable: true,
    center: true,
    width: '200px',
  },
  {
    name: 'Date',
    selector: (row: TLastSalesReportItem) => 'date',
    cell: (row: TLastSalesReportItem) => {
      return new Intl.DateTimeFormat().format(new Date(row.timestamp * 1000 + 1.08e7))
    },
    sortable: true,
    center: true,
  },
  {
    name: 'Reward',
    selector: (row: TLastSalesReportItem) => 'reward',
    sortable: true,
    center: true,
  },
  {
    name: 'Reward percent',
    selector: (row: TLastSalesReportItem) => 'reward_percent',
    sortable: true,
    center: true,
  },
  {
    name: 'Amount',
    selector: (row: TLastSalesReportItem) => 'amount',
    sortable: true,
    center: true,
  },
  {
    name: 'Product',
    selector: (row: TLastSalesReportItem) => 'product',
    sortable: true,
    center: true,
  },
  {
    name: 'Description',
    selector: (row: TLastSalesReportItem) => 'description',
    sortable: true,
    center: true,
    width: '200px',
  },
]

export default function Reports(): JSX.Element {
  const { email } = useParams<{ email?: string }>()
  const dispatch = useDispatch()
  const [filtredReport, setfiltredReport] = useState<AffiliatesState['lastSalesReport']>([])
  const [isAll, setisAll] = useState(false)
  useEffect(() => {
    dispatch(fetchGetLastSalesReport({ filter: { limit: 1000 } }))
  }, [])

  const lastSalesReport = useSelector<AppState, AffiliatesState['lastSalesReport']>(
    ({ affiliatesReducer }) => affiliatesReducer.lastSalesReport,
    shallowEqual
  )
  const memoizedOnChekedPD = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setisAll(e.currentTarget.checked)
    },
    [setisAll]
  )
  useEffect(() => {
    if (!email) return setfiltredReport(lastSalesReport)
    if (isAll) return setfiltredReport(lastSalesReport)
    const f = lastSalesReport.filter((item) => item.email === decodeURI(email))
    return setfiltredReport(f)
  }, [lastSalesReport, isAll])

  const data = useMemo(() => {
    const map: string[] = []
    type TDatasets = {
      label: string
      data: { t: string; y: number }[]
      backgroundColor: string
      borderColor: string
    }[]
    const datasets: TDatasets = []
    const dates: string[] = []
    filtredReport.forEach((item) => {
      const index = map.findIndex((i) => i === item.email)
      const [d] = item.date.split(' ')
      dates.push(d)
      if (index < 0) {
        map.push(item.email)
        datasets.push({
          label: item.email,
          backgroundColor: colors[map.length],
          borderColor: colors[map.length],
          data: [{ t: d, y: item.reward }],
        })
      } else {
        const i = datasets[index].data.findIndex((i) => i.t === d)
        if (i < 0) {
          datasets[index].data.push({ t: d, y: item.reward })
        } else {
          const oldVal = datasets[index].data[i].y
          datasets[index].data[i].y = oldVal + item.reward
        }
      }
    })

    const countDay = 30

    for (let i = countDay; i >= 0; i--) {
      const d = new Date()
      const day = d.getUTCDate() - i
      d.setUTCDate(day)
      const timestamp = d.setUTCHours(0, 0, 0, 0)
      const date = new Date(timestamp)

      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)

      dates.push(`${ye}-${mo}-${da}`)
    }

    datasets.forEach((item) => {
      dates.forEach((d) => {
        const index = item.data.findIndex((i) => i.t === d)
        if (index < 0) {
          item.data.push({ t: d, y: 0 })
        }
      })
      item.data.sort((a, b) => +new Date(a.t) - +new Date(b.t))
    })

    return {
      responsive: true,
      title: {
        position: 'top',
        display: true,
      },
      datasets,
    }
  }, [filtredReport])

  return (
    <div className={cn.wraper}>
      <div className={cn.form}>
        <div className={cn.formCol2}>
          {email && <div className={cn.formRow}>Affiliates rewards {email}</div>}
          <div className={cn.formRow}>
            <label>
              All rewards
              <input
                style={{ marginLeft: '5px' }}
                className="all_input"
                type="checkbox"
                placeholder={'All rewards'}
                checked={isAll}
                onChange={memoizedOnChekedPD}
              />
            </label>
          </div>
          <div className={cn.formRow}>
            <Bar data={data} options={options} />
          </div>
          <div className={cn.formRow}>
            <ReactTable
              keyField={'hash'}
              theme="solarized"
              noHeader={true}
              data={filtredReport}
              columns={columns}
              customStyles={customStyles}
              fixedHeader={true}
              fixedHeaderScrollHeight="100vh"
              noDataComponent={<></>}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
