import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AppState } from '../../redusers/config'
import { getNickList, sendCredit, directlyPay, onCryptoDirectPay } from '../../actions/transaction'
import { PayC, TGetNickNameListR, TSendCreditP } from '../../utils/api'
import Title from '../FormTitle'
import FormInput from '../FormInput'
import { AutoComplete } from '../Autocomplete'
import { Star } from '../Icons/Star'
import { CSSTransition } from 'react-transition-group'
import cn from './cn.module.css'
import { useGetThemeMode } from 'state/theme/hook'
import { useCommonCheck } from 'hooks/useCommon'
import { AudioItem } from 'components/Menu/tooltip'
import { Balance } from 'redusers/transaction'
import { showToast } from 'sagas/domain'
import { useWallet } from 'hooks/useWallet'

const onCreditChange =
  (setCreditVal: (v: string) => void, setDisabled: (d: boolean) => void, balance: number) =>
  (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value
    const prefix = '$'

    if (balance === 0) {
      return null
    }

    if (val === '') {
      return setCreditVal(val)
    }

    const onlyNumberofVal = val.includes('$') ? val.substr(prefix.length) : val
    if (!val.includes('$') && !isNaN(parseInt(val))) {
      return setCreditVal(prefix + val)
    }

    if (val.includes(prefix) && val.substr(prefix.length) === '') {
      return setCreditVal('')
    }

    const reg = /^[$]+?\d+?\.?\d*$/

    if (!reg.test(val)) {
      return null
    }

    const [int, float = ''] = val.substr(prefix.length).split('.')
    if (float.length > 2) {
      return null
    }

    setCreditVal(val)
  }

interface SendFormP {
  balance: Balance
  listNickName: TGetNickNameListR[]
  getNickList: Function
  sendCredit: Function
  userInfo: any
  directlyPay: Function
  isLoadSendPay: boolean
}

const SendForm: React.FC<SendFormP> = ({
  isLoadSendPay,
  balance,
  listNickName,
  getNickList,
  sendCredit,
  userInfo,
  directlyPay,
}) => {
  const dispatch = useDispatch()
  const { getEthBalance, getEthPrice, getBNBPrice, walletConnecting } = useWallet()
  const { showToastMsg } = useCommonCheck()
  const [nameVal, setNameVal] = useState<string>('')
  const [creditVal, setCreditVal] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(true)
  const [flag, setFlag] = useState<boolean>(false)
  const [isOpenOption, setIsOpenOption] = useState<boolean>(false)
  const [receiverInfo, setReceiverInfo] = useState<TGetNickNameListR>({
    nick: '',
    paypalAddress: '',
    mhcAddress: '',
    ethAddress: '',
    bnbAddress: '',
  })

  const onCalcMax = () => (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault()

    const maxVal = balance.userPaypal
    setCreditVal('$' + maxVal.toFixed(2))
  }

  const sendingOptionPanel = () => {
    if (Number(creditVal.slice(1)) === 0) {
      showToastMsg('0 amount cannot be transferred', 'error')
      return
    }
    setIsOpenOption(!isOpenOption)
    AudioItem('auto-paypal').play()
  }

  const onSendCredit = () => (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault()
    if (!isNaN(Number(creditVal.slice(1))) && Number(creditVal.slice(1)) > balance.userPaypal) {
      showToastMsg('#Credits more than the balance cannot be transferred.', 'error')
      return
    }

    const sendCreditData: TSendCreditP = {
      receiver: nameVal.slice(1),
      amount: Number(creditVal.slice(1)),
    }

    AudioItem('send-credit').play()
    sendCredit(sendCreditData)
    setNameVal('')
    setIsOpenOption(false)
  }

  const directlyPayment = (type: string, nick: string) => (e: React.FormEvent<HTMLElement>) => {
    directlyPay({
      type: type,
      nick: nick.slice(1),
      amount: Number(creditVal.slice(1)),
      address: type === 'paypal' ? receiverInfo.paypalAddress : receiverInfo.mhcAddress,
    })
    setIsOpenOption(false)
  }

  const payTokenFromWallet = async (
    walletAddress: string,
    nick: string,
    chainId: number,
    type: PayC['type'],
    tokenAmount: string,
    usdAmount: number,
    rate: number
  ) => {
    try {
      const res = await walletConnecting(walletAddress, type, chainId, tokenAmount)
      if (res && res?.confirmations > 0 && res?.blockNumber > 0) {
        const hash = res.transactionHash
        const amount = usdAmount.toString()
        dispatch(onCryptoDirectPay({ nick, amount, rate, hash, type }))
        setIsOpenOption(false)
      } else {
        showToast('Failed to send token with some reasons', 'error')
      }
    } catch (err: any) {
      if (err.message.includes('rejected')) {
        showToast('Action rejected', 'error')
        setIsOpenOption(false)
      } else if (err.message.includes('insufficient funds')) {
        showToast('Insufficient funds! Please check your wallet balance', 'info')
      } else if (err.message.includes('detectedNetwork')) {
        showToast('Invalid network!', 'info')
      } else {
        showToast('Error:' + err.message, 'error')
      }
    }
  }

  useEffect(() => {
    setFlag(true)
    getNickList()

    return () => {
      setFlag(false)
    }
  }, [getNickList])

  useEffect(() => {
    setNameVal('')
    setCreditVal('')
  }, [isLoadSendPay])

  useEffect(() => {
    if (isLoadSendPay === true) {
      setDisabled(true)
      return
    }
    if (nameVal === '' || creditVal === '' || Number(creditVal.slice(1)) === 0) {
      setDisabled(true)
      return
    }

    const receiver: TGetNickNameListR = listNickName.filter((item: TGetNickNameListR) => item.nick === nameVal)[0]
    if (receiver === undefined) {
      setDisabled(true)
      return
    }

    setReceiverInfo(receiver)
    setDisabled(false)
  }, [isLoadSendPay, nameVal, creditVal, listNickName])

  const themeMode = useGetThemeMode()

  return (
    <CSSTransition in={flag} timeout={4000} classNames="bridgeFadeTransition" unmountOnExit>
      <div className={themeMode == 'main' ? cn.sendForm : cn.sendForm_l}>
        <Title
          title="Send #Credits"
          feePercent={creditVal ? creditVal : '$0.00'}
          info={userInfo}
          themeMode={themeMode}
        ></Title>
        <label
          className={cn.formLabel}
          style={{
            color: themeMode == 'main' ? '#fff' : 'black',
          }}
        >
          Type in your destinations #name and hit Send.
        </label>
        <form>
          <div className={cn.payInp}>
            <AutoComplete
              data={
                userInfo.id !== 1042
                  ? listNickName.filter((item: TGetNickNameListR) => item.nick.slice(1) !== userInfo.nick)
                  : listNickName
              }
              onChange={setNameVal}
              nameValue={nameVal}
              themeMode={themeMode}
            />
            <div className={cn.sendCreditInp}>
              <FormInput
                value={creditVal}
                placeholder={'$ 0.00'}
                isFocusStyle={true}
                onChange={onCreditChange(setCreditVal, setDisabled, balance.userPaypal)}
                themeMode={themeMode}
              />
              {/* <button type="button" className={cn.maxBtn} onClick={onCalcMax()}>
                Max
              </button> */}
            </div>
          </div>
          <div className={cn.btnCont}>
            <button
              type="button"
              className={cn.sendBtn}
              disabled={disabled}
              onClick={sendingOptionPanel}
              style={{
                background: disabled
                  ? '#606061'
                  : themeMode === 'main'
                  ? 'linear-gradient(#873f99, #311438)'
                  : 'linear-gradient(#b7eaf6, #777fc4)',
                color: disabled ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
              }}
            >
              Send
            </button>
          </div>
          {isOpenOption && (
            <>
              <div
                onClick={() => setIsOpenOption(false)}
                style={{
                  display: isOpenOption ? 'block' : 'none',
                  width: '200vw',
                  height: '200vh',
                  backgroundColor: 'transparent',
                  position: 'fixed',
                  zIndex: 0,
                  top: 0,
                  left: 0,
                }}
              />
              <div className={cn.sendingOptionPanel}>
                <div className={cn.senCreditContainer}>
                  <span className={cn.sendCreditText}>Send Credits to</span>
                  <span className={cn.nickNameText}>{receiverInfo?.nick}</span>
                </div>

                <div className={cn.divideLine}></div>

                <div className={cn.btnContainer}>
                  <span className={cn.zeroPercentText}>
                    Send for <strong className={cn.zeroText}>0</strong>% fees.
                  </span>
                  <button
                    type="button"
                    className={`${cn.sendConfirmBtn} ${cn.sendBtn}`}
                    disabled={Number(creditVal.slice(1)) > balance.userPaypal}
                    onClick={onSendCredit()}
                    style={{
                      background:
                        Number(creditVal.slice(1)) > balance.userPaypal
                          ? '#606061'
                          : themeMode === 'main'
                          ? 'linear-gradient(#873f99, #311438)'
                          : 'linear-gradient(#b7eaf6, #777fc4)',
                      color: disabled ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
                    }}
                  >
                    Confirm and Send
                  </button>
                </div>

                <div className={cn.divideLine}></div>

                <div className={cn.directPaymentContainer}>
                  <span className={cn.directPaymentTitle}>Account native platform fees will apply.</span>
                  <div className={cn.directPaymentOption}>
                    <button
                      type="button"
                      className={`${cn.paymentBtn} ${cn.sendBtn}`}
                      disabled={receiverInfo?.paypalAddress === ''}
                      onClick={directlyPayment('paypal', receiverInfo.nick)}
                      style={{
                        background:
                          receiverInfo?.paypalAddress === ''
                            ? '#606061'
                            : themeMode === 'main'
                            ? 'linear-gradient(#873f99, #311438)'
                            : 'linear-gradient(#b7eaf6, #777fc4)',
                        color: disabled ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
                      }}
                    >
                      <Star size={'15px'} isFilled={receiverInfo?.paypalAddress !== ''} />
                      <div className={cn.optionText}>PayPal</div>
                    </button>

                    <button
                      type="button"
                      className={`${cn.paymentBtn} ${cn.sendBtn}`}
                      // disabled={receiverInfo?.mhcAddress === ''}
                      disabled={true}
                      // onClick={directlyPayment('metahash', receiverInfo.nick)}
                      style={{
                        background: true //receiverInfo?.mhcAddress === ''
                          ? '#606061'
                          : themeMode === 'main'
                          ? 'linear-gradient(#873f99, #311438)'
                          : 'linear-gradient(#b7eaf6, #777fc4)',
                        color: disabled ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
                      }}
                    >
                      <Star size={'15px'} isFilled={receiverInfo?.mhcAddress !== ''} />
                      <div className={cn.optionText}>MHC</div>
                    </button>

                    <button
                      type="button"
                      className={`${cn.paymentBtn} ${cn.sendBtn}`}
                      // disabled={receiverInfo?.ethAddress === ''}
                      disabled={true}
                      // onClick={directlyCryptoPay('eth', receiverInfo.nick, receiverInfo.ethAddress)}
                      style={{
                        background: true //receiverInfo?.ethAddress === ''
                          ? '#606061'
                          : themeMode === 'main'
                          ? 'linear-gradient(#873f99, #311438)'
                          : 'linear-gradient(#b7eaf6, #777fc4)',
                        color: disabled ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
                      }}
                    >
                      <Star size={'15px'} isFilled={receiverInfo?.ethAddress !== ''} />
                      <div className={cn.optionText}>ETH</div>
                    </button>

                    <button
                      type="button"
                      className={`${cn.paymentBtn} ${cn.sendBtn}`}
                      // disabled={receiverInfo?.bnbAddress === ''}
                      disabled={true}
                      // onClick={directlyCryptoPay('bnb', receiverInfo.nick, receiverInfo.bnbAddress)}
                      style={{
                        background: true //receiverInfo?.bnbAddress === ''
                          ? '#606061'
                          : themeMode === 'main'
                          ? 'linear-gradient(#873f99, #311438)'
                          : 'linear-gradient(#b7eaf6, #777fc4)',
                        color: disabled ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
                      }}
                    >
                      <Star size={'15px'} isFilled={receiverInfo?.bnbAddress !== ''} />
                      <div className={cn.optionText}>BNB</div>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </CSSTransition>
  )
}

function mapStateToProps({ transaction, user }: AppState) {
  return {
    isLoadSendPay: transaction.get('isLoadSendPay'),
    balance: transaction.get('balance'),
    listNickName: transaction.get('listNickName'),
    userInfo: user.get('userInfo'),
  }
}

export default connect(mapStateToProps, {
  getNickList,
  sendCredit,
  directlyPay,
})(SendForm)
