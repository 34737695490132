import React from 'react'
import { useDispatch } from 'react-redux'
import { useGetThemeMode } from 'state/theme/hook'
import { payForCart } from 'views/Cart/actions'
import { useGetTotalCartAmount } from 'views/Cart/hooks'
import { useGlobalModalContext } from '../GlobalModal'
import TwoBtnModal from '../TwoBtnModal'

const CartModal: React.FC = () => {
  const dispatch = useDispatch()
  const { hideModal } = useGlobalModalContext()
  const { total } = useGetTotalCartAmount()

  const confirmOrdering = () => {
    dispatch(payForCart())
    hideModal()
  }

  const themeMode = useGetThemeMode()

  return (
    <TwoBtnModal title={'Order Confirmation'} onClose={hideModal} confirm={confirmOrdering} themeMode={themeMode}>
      <div>
        You will be charged{' '}
        <span style={{ color: themeMode === 'main' ? 'yellow' : 'green' }}>${total?.toFixed(2)}</span> from your
        account. Do you want to continue?
      </div>
    </TwoBtnModal>
  )
}

export default CartModal
