import React from 'react'
import TwoBtnModal from '../TwoBtnModal'
import FormInput from 'components/FormInput'
import { useGetThemeMode } from 'state/theme/hook'
import { useDomainResale } from 'hooks/useModal'

const DomainResaleModal: React.FC = () => {
  const { onChangeCostResale, onCloseResale, onSaveResale, idResale, costResale, settings } = useDomainResale()
  const themeMode = useGetThemeMode()

  return (
    <TwoBtnModal title={`${idResale} Resale`} onClose={onCloseResale} confirm={onSaveResale} themeMode={themeMode}>
      <FormInput
        label={`Please enter the price (Fee: ${settings.resale_commission}% Commission)`}
        type="text"
        onChange={onChangeCostResale}
        value={costResale}
        themeMode={themeMode}
      />
    </TwoBtnModal>
  )
}

export default DomainResaleModal
