import { AudioItem } from 'components/Menu/tooltip'
import { BINANCE_URL, UNISWAP_LINK } from 'constants/urls'
import React, { useCallback, useEffect, useState } from 'react'
import { SiBinance } from 'react-icons/si'
import { GiHorseHead } from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { useGetThemeMode } from 'state/theme/hook'
import './styles.scss'
import { IAssetsTable, TAssetChartList } from 'views/Portfolio/types'
import { useGetTokenMarketChartData } from 'views/Portfolio/hooks'
import IconWrapper from 'components/Icons/IconWrapper'
import { AFFILIATE_COIN_LIST } from 'constants/affiliateCoins'
import { useHistory } from 'react-router-dom'
import { FaEthereum } from 'react-icons/fa'
import CommandLineChart from 'views/Portfolio/components/ChartsContainer/LineChart/CommandLineChart'
import { createAssetsChartList } from 'views/Portfolio/actions'
import Loader from 'components/Loader'
import GreenPercent from 'views/Portfolio/components/GreenPercent'
import RedPercent from 'views/Portfolio/components/RedPercent'
import SpaceIcon from '../../assets/icons/space.svg'

interface ITokenLineChartP {
  asset: IAssetsTable
  timeOption: {
    from: number
    to: number
  }
}
const TokenBalance: React.FC<ITokenLineChartP> = ({ asset, timeOption }) => {
  const dispatch = useDispatch()
  const tokenMarketChart = useGetTokenMarketChartData(asset, timeOption.from, timeOption.to)

  useEffect(() => {
    if (tokenMarketChart && tokenMarketChart.tokenBalanceRange.length > 0) {
      const tokenMarketChartTemp = { ...tokenMarketChart }
      dispatch(createAssetsChartList({ ...tokenMarketChartTemp }))
    }
  }, [dispatch, tokenMarketChart])

  return <></>
}
interface CoinChartI {
  header: boolean
  shift?: boolean
}
const CoinChart: React.FC<CoinChartI> = ({ header, shift }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const themeMode = useGetThemeMode()

  const assetsChartList1 = [
    {
      id: 47,
      tokenBalanceRange: [
        { time: 1659979247789, amount: 0.10797256426175748 },
        { time: 1659979499569, amount: 0.30814146306150096 },
        { time: 1659979788066, amount: 0.80819312536322119 },
        { time: 1659980148301, amount: 0.30821702959695727 },
        { time: 1659980451259, amount: 0.50949488176699444 },
        { time: 1659980712069, amount: 0.00940539376439284 },
        { time: 1659981038763, amount: 0.10938628345037173 },
        { time: 1659981357545, amount: 0.40946288714428065 },
        { time: 1659981714563, amount: 0.8093406027345013 },
        { time: 1659981965733, amount: 0.60948710929239307 },
        { time: 1659979247789, amount: 0.1591619949635443 },
        { time: 1659979499569, amount: 0.25925454060241186 },
        { time: 1659979788066, amount: 0.45928284820865493 },
        { time: 1659980148301, amount: 0.65929594618558611 },
        { time: 1659980451259, amount: 0.0599961266820372 },
        { time: 1659980712069, amount: 0.45994709303357833 },
        { time: 1659981038763, amount: 0.859936621815175777 },
        { time: 1659981357545, amount: 0.65997859569422751 },
        { time: 1659981714563, amount: 0.95991159173183252 },
        { time: 1659981965733, amount: 0.35999186786771372 },
      ],
    },
  ]
  const [timeOption1, setTimeOption1] = useState<number>(1)
  const [timeRange1, setTimeRange1] = useState<{ from: number; to: number }>({
    from: 0,
    to: 0,
  })
  // const showCoin = useSelector<AppState, TR_Search['updateShowCoin']>(({ search_reducer }) =>
  //   search_reducer.get('updateShowCoin')
  // )

  useEffect(() => {
    const currentDate = new Date().getTime()
    setTimeRange1({
      from: currentDate - 24 * timeOption1 * 60 * 60 * 1000,
      to: currentDate,
    })

    return () => {
      setTimeRange1({ from: 0, to: 0 })
    }
  }, [timeOption1])

  const timeOptions = [
    { val: 1, label: '24h' },
    { val: 7, label: '7d' },
    { val: 30, label: '30d' },
    { val: 90, label: '90d' },
  ]

  const cReward = () => {
    history.push('/Affiliates/')
  }

  const timeHandleChange1 = useCallback((e: any) => {
    setTimeOption1(e.target.value)
  }, [])

  const percents = [-12.54, 24.01]

  const goSite = useCallback((flag: string) => {
    AudioItem('btn-go').play()
    if (flag == 'uniswap') {
      window.open(UNISWAP_LINK, '_blank')
    } else {
      window.open(BINANCE_URL, '_blank')
    }
  }, [])

  return (
    <div
      className="coinChartContainer"
      style={{
        background: themeMode === 'main' ? '#00000090' : '#ffffff90',
        color: themeMode === 'light' ? 'black' : 'white',
        borderTopColor: themeMode === 'main' ? '#02569d' : 'gray',
      }}
    >
      <div className="itemContent">
        <div className="icon">
          <IconWrapper iconLink={AFFILIATE_COIN_LIST['HASHTAG'].iconLink} onClickImg={cReward} alt={'HASHTAG Coin'} />
        </div>
        <div className="mainContent">
          {assetsChartList1.length > 0 ? (
            <div className="content">
              <div className="topSetting">
                <div className="coinleft">
                  <span
                    style={{
                      color: themeMode === 'main' ? '#c5c3c3' : '#242424',
                    }}
                  >
                    View
                  </span>
                  <span className="timeDropdown">
                    <select
                      name="etherum"
                      value={timeOption1}
                      className="selBox"
                      style={{
                        background: themeMode === 'main' ? '#00000050' : '#ffffff00',
                        color: themeMode === 'main' ? 'white' : 'black',
                      }}
                      onChange={timeHandleChange1}
                    >
                      {timeOptions.map((option, index) => (
                        <option key={index} value={option.val}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </span>
                  <span className="h-line">|</span>
                  <span className="percent">
                    {percents[0] > 0 ? <GreenPercent percent={percents[0]} /> : <RedPercent percent={percents[0]} />}
                  </span>
                </div>
                <div className="coinright">
                  <span
                    className="view"
                    style={{
                      color: themeMode === 'main' ? '#c5c3c3' : '#242424',
                    }}
                  >
                    Buy on :
                  </span>
                  <span className="binanceicon" onClick={() => goSite('uniswap')}>
                    <img src={SpaceIcon} width={70} alt="space icon" />
                  </span>
                </div>
              </div>
              <div className="bottomChart">
                <CommandLineChart chartData={assetsChartList1} chartColor={'#7BD39A'} header={header} />
              </div>
            </div>
          ) : (
            <div className="coinSpinner">
              <Loader stroke="green" size="30px" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CoinChart
