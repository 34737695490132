import { renewalProcess } from 'actions/hashtag'
import { useRenewalProcess } from 'hooks/useModal'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useGetThemeMode } from 'state/theme/hook'
import { useGlobalModalContext } from '../GlobalModal'
import TwoBtnModal from '../TwoBtnModal'

const RenewModal: React.FC = () => {
  const dispatch = useDispatch()
  const { renewId, renewPrice } = useRenewalProcess()
  const { hideModal } = useGlobalModalContext()
  const confirmRenew = () => {
    dispatch(renewalProcess(renewId))
    hideModal()
  }

  const themeMode = useGetThemeMode()

  return (
    <TwoBtnModal title={'Renew Confirmation'} onClose={hideModal} confirm={confirmRenew} themeMode={themeMode}>
      <div>
        You will be charged <span style={{ color: 'red' }}>{renewPrice?.toFixed(2)}</span> USD from your account. Do you
        want to renew a domain(
        <span style={{ color: 'yellow' }}>{renewId}</span>)
      </div>
    </TwoBtnModal>
  )
}

export default RenewModal
