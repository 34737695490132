import React from 'react'
import cn from './cn.module.scss'
import { IAssetsTable } from '../../../types'
import GreenPercent from '../../GreenPercent'
import PerformanceContent from '../PerformanceContent'
import { useGetPerformance, useGetTotalProfit } from '../../../hooks'
import RedPercent from '../../RedPercent'
import { useGetThemeMode } from 'state/theme/hook'

interface IStatisticsP {
  assetsList: IAssetsTable[]
}

const Statistic: React.FC<IStatisticsP> = ({ assetsList }) => {
  const totalProfitStatus = useGetTotalProfit(assetsList)
  const performanceStatus = useGetPerformance(assetsList)
  const themeMode = useGetThemeMode()

  return (
    <div className={cn.statisticsContainer}>
      <div className={cn.totalProfitWrapper}>
        <div className={cn.totalProfitTitle}>Total Profit/Loss</div>
        <div className={cn.totalProfitContent}>
          <span className={cn.totalProfitAmount}>
            {totalProfitStatus !== undefined
              ? `${totalProfitStatus.totalProfit < 0 ? '-' : '+'} $${Math.abs(totalProfitStatus.totalProfit).toFixed(
                  6
                )}`
              : '------'}
          </span>
          {totalProfitStatus !== undefined ? (
            totalProfitStatus.totalProfitPercent > 0 ? (
              <GreenPercent percent={totalProfitStatus.totalProfitPercent} />
            ) : (
              <RedPercent percent={totalProfitStatus.totalProfitPercent} />
            )
          ) : (
            '--'
          )}
        </div>
      </div>
      <div className={cn.performanceWrapper}>
        <PerformanceContent asset={performanceStatus?.bestPerformance} isBest={true} themeMode={themeMode} />
        <PerformanceContent asset={performanceStatus?.worstPerformance} isBest={false} themeMode={themeMode} />
      </div>
    </div>
  )
}

export default Statistic
