import { createSlice } from '@reduxjs/toolkit'

interface IState {
  isPlaySound: boolean
}

export const initialState: IState = {
  isPlaySound: true,
}

const playSoundSlice = createSlice({
  name: 'playSound',
  initialState,
  reducers: {
    setIsPlaySound(state, action) {
      state.isPlaySound = action.payload
    },
  },
})

export const { setIsPlaySound } = playSoundSlice.actions
export default playSoundSlice.reducer
