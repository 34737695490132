import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { onEditInfo, onGetItemInfo, onRerenderInfo } from '../../../../actions/info'
import { AppState } from '../../../../redusers/config'
import { InfoItemID } from '../../../../utils/api'

export default function useChangeInfo() {
  const dispatch = useDispatch()
  const { id } = useParams<{ id?: string }>()
  useEffect(() => {
    return () => {
      dispatch(onRerenderInfo())
    }
  }, [])

  useEffect(() => {
    if (typeof id !== 'string') return
    dispatch(onGetItemInfo(+id))
  }, [dispatch, id])

  const itemInfo = useSelector<AppState, InfoItemID>(({ info }) => info.get('itemInfo'))
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [error, setError] = useState<'' | 'description' | 'title'>('')

  useEffect(() => {
    setTitle(itemInfo.title)
  }, [itemInfo.title])

  useEffect(() => {
    setDescription(itemInfo.description)
  }, [itemInfo.description])

  const memoizedSetTitle = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setTitle(e.currentTarget.value)
    setError('')
  }, [])
  const memoizedDescription = useCallback((e: React.FormEvent<HTMLTextAreaElement>) => {
    setDescription(e.currentTarget.value)
    setError('')
  }, [])

  const memoizedSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (title.length < 3) {
        setError('title')
        return
      }
      if (description.length < 3) {
        setError('description')
        return
      }
      if (typeof id !== 'string') {
        return
      }
      dispatch(onEditInfo({ id: +id, fields: { title, description } }))
    },
    [title, description, id, dispatch]
  )

  return {
    title,
    description,
    error,
    memoizedSetTitle,
    memoizedDescription,
    memoizedSubmit,
  }
}
