import React, { ChangeEvent, useEffect, useState } from 'react'
import { BsBookmark } from 'react-icons/bs'
import { IGetAllDomainListR } from 'utils/api'
import './styles.scss'

import {
  Root,
  AutoNickCompleteContainer,
  Input,
  AutoNickCompleteItem,
  AutoNickCompleteItemButton,
  NickName,
} from './styles'

interface IDataList {
  domain: string
}

interface autoDomainProps {
  nameValue?: string
  themeMode?: string
  domainData: IGetAllDomainListR[]
  onChange: (e: string) => void
  isDisable: boolean
}

export const AutoDomainName: React.FC<autoDomainProps> = ({
  domainData,
  onChange,
  nameValue,
  isDisable,
  themeMode,
}) => {
  const [search, setSearch] = useState({
    text: nameValue,
    suggestions: [{ domain: '' }],
  })

  const [isComponentVisible, setIsComponentVisible] = useState(false)
  const [countURL, setCountURL] = useState(0)

  const data = domainData.map((item: IGetAllDomainListR) => {
    return { domain: item.domainName }
  })

  const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setCountURL(0)
    const value = e.target.value
    const prefix = '#'

    let suggestions: IDataList[] = []

    if (value.length > 0) {
      suggestions = data.sort().filter((v: any) => v.domain.toLowerCase().includes(value.toLowerCase()))
      onChange(value)
    }
    if (suggestions.length > 0) {
      setIsComponentVisible(true)
    }
    if (value.substr(0, 1) !== '#') {
      setSearch({ suggestions, text: prefix + value })
      return
    }

    if (value.includes(prefix) && value.substr(prefix.length) === '') {
      return setSearch({ suggestions, text: '' })
    }

    setSearch({ suggestions, text: value })
  }

  const suggestionSelected = (value: IDataList) => {
    setIsComponentVisible(false)
    const data = domainData.filter((v: any) => v.domainName.toLowerCase() === value.domain.toLowerCase())
    if (data[0].url != null) {
      const urlArr = data[0].url.split(',')
      setCountURL(urlArr.length)
    }
    onChange(value.domain)

    setSearch({
      text: value.domain,
      suggestions: [],
    })
  }

  const { suggestions } = search
  useEffect(() => {
    if (nameValue === '') {
      setSearch({ suggestions, text: '' })
    } else {
      const data = domainData.filter((v: any) => v.domainName.toLowerCase() === nameValue?.toLowerCase())
      if (data[0]) {
        if (data[0].url != null) {
          const urlArr = data[0].url.split(',')
          setCountURL(urlArr.length)
        }
      }
    }
  }, [domainData, nameValue, suggestions])

  return (
    <Root>
      <div
        onClick={() => setIsComponentVisible(false)}
        className="input-div"
        style={{ display: isComponentVisible ? 'block' : 'none' }}
      />
      <div className="sub-contain">
        <Input
          id="input"
          autoComplete="off"
          value={search.text}
          onChange={onTextChanged}
          type={'text'}
          placeholder={'#Domain'}
          disabled={isDisable}
          style={{
            background: themeMode === 'main' ? 'black' : 'white',
            color: themeMode === 'main' ? 'white' : 'black',
            height: '40px',
          }}
        />

        <div className="">
          <BsBookmark style={{ color: themeMode === 'main' ? '#fff' : '#000' }} size={18} />
          {countURL > 0 ? <span className="count-icon">{countURL}</span> : null}
        </div>
      </div>
      {suggestions.length > 0 && isComponentVisible && (
        <AutoNickCompleteContainer
          style={{
            background: themeMode === 'main' ? 'black' : 'white',
          }}
        >
          {suggestions.map((item: IDataList, index: number) => (
            <AutoNickCompleteItem key={index}>
              <AutoNickCompleteItemButton
                key={index}
                onClick={() => suggestionSelected(item)}
                style={{
                  color: themeMode === 'main' ? 'white' : 'black',
                }}
              >
                <NickName>{item.domain}</NickName>
              </AutoNickCompleteItemButton>
            </AutoNickCompleteItem>
          ))}
        </AutoNickCompleteContainer>
      )}
    </Root>
  )
}
