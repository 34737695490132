import { Record } from 'immutable'
import { TActionsR } from '../../types'
import {
  AddFavoriteStatus,
  AddWishStatus,
  IsSearch,
  UpdateShowCoin,
  TClearAction,
  TClearActionALL,
  TCreatCategoryList,
  TGetAllDomainList,
  TGetAllSEODomainList,
  TGetAllSalesDomainList,
  TGetInstructionList,
  TR_Search,
  TGetSearchLogList,
} from './types'

export const rInitRSearch: TR_Search = {
  search_response: [],
  favAddStatus: false,
  wishAddStatus: false,
  search_isSearch: false,
  updateShowCoin: true,
  search_error: false,
  availableDomainList: [],
  allDomainList: [],
  allSEODomainList: [],
  allSoldDomainList: [],
  instructionList: [],
  searchLogList: [],
}

const State: Record.Factory<TR_Search> = Record(rInitRSearch)

export type TActionsR_Search =
  | TActionsR<TR_Search>
  | TClearAction
  | TClearActionALL
  | AddFavoriteStatus
  | AddWishStatus
  | IsSearch
  | UpdateShowCoin
  | TCreatCategoryList
  | TGetAllDomainList
  | TGetAllSEODomainList
  | TGetAllSalesDomainList
  | TGetInstructionList
  | TGetSearchLogList

export const search_reducer = function (
  state: Record<TR_Search> = new State(),
  action: TActionsR_Search
): Record<TR_Search> {
  if (action.type === 'SEARCH_CLEAR') {
    return state.delete(action.payload)
  }
  if (action.type === 'SEARCH_CLEAR_ALL') {
    return state.clear()
  }
  if (action.type === 'ADD_FAVORITE_STATUS') {
    return state.set('favAddStatus', action.val)
  }
  if (action.type === 'ADD_WISH_STATUS') {
    return state.set('wishAddStatus', action.val)
  }
  if (action.type === 'IS_SEARCH') {
    return state.set('search_isSearch', action.val)
  }
  if (action.type === 'UPDATE_SHOW_COIN') {
    return state.set('updateShowCoin', action.val)
  }
  if (action.type === 'CREATE_CATEGORY_LIST') {
    return state.set('availableDomainList', action.payload)
  }
  if (action.type === 'GET_ALL_DOMAIN_LIST') {
    return state.set('allDomainList', action.payload)
  }
  if (action.type === 'GET_ALL_SEO_DOMAIN_LIST') {
    return state.set('allSEODomainList', action.payload)
  }
  if (action.type === 'GET_ALL_SOLD_DOMAIN_LIST') {
    return state.set('allSoldDomainList', action.payload)
  }
  if (action.type === 'SEARCHENGINE_INSTRUCTION_LIST') {
    return state.set('instructionList', action.payload)
  }
  if (action.type === 'GET_SEARCH_LOG_LIST') {
    return state.set('searchLogList', action.payload)
  }

  return state.set(action.type, action.payload)
}
