import { useCommonCheck } from 'hooks/useCommon'
import React, { useCallback, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onUserEdit } from '../../actions/user'
import { AppState } from '../../redusers/config'
import { UserInfo } from '../../utils/api'

type Tinit = {
  src: string
  file: FormData | null
  isButtonDisabled: boolean
  first_name: string
  last_name: string
  password: string
  confirmPassword: string
  isPasswordErr: boolean
  isConfirmPassErr: boolean
  info: string
  instagram: string
  isInstagramE: boolean
  facebook: string
  isFacebookE: boolean
  telegram: string
  isTelegramE: boolean
  skype: string
  nick: string
  mhc_address: string
  eth_address: string
  bnb_address: string
  btc_address: string
  paypal_address: string
  gps_info: string
  isNickE: boolean
  isChangeNick: boolean
  isSkypeE: boolean
}
const init: Tinit = {
  src: '',
  file: null,
  isButtonDisabled: true,
  first_name: '',
  last_name: '',
  password: '',
  confirmPassword: '',
  isPasswordErr: false,
  isConfirmPassErr: false,
  info: '',
  instagram: '',
  facebook: '',
  telegram: '',
  skype: '',
  isInstagramE: false,
  isFacebookE: false,
  isTelegramE: false,
  isSkypeE: false,
  nick: '',
  mhc_address: '',
  eth_address: '',
  bnb_address: '',
  btc_address: '',
  paypal_address: '',
  gps_info: '',
  isNickE: false,
  isChangeNick: false,
}

type Taction<A extends string, P> = {
  type: A
  payload: P
}
type TactionOne<A extends string> = Pick<Taction<A, never>, 'type'>
type Tactions =
  | Taction<'SET_SRC', string>
  | Taction<'SET_FILE', FormData>
  | TactionOne<'CLEAR_FILE'>
  | TactionOne<'CLEAR_STATE'>
  | Taction<'SET_PASSWORD', string>
  | Taction<'SET_CONFIRMPASSWORD', string>
  | Taction<'SET_LAST_NAME', string>
  | Taction<'SET_FIRST_NAME', string>
  | Taction<'SET_NICK', string>
  | Taction<'SET_MHC_ADDRESS', string>
  | Taction<'SET_ETH_ADDRESS', string>
  | Taction<'SET_BNB_ADDRESS', string>
  | Taction<'SET_BTC_ADDRESS', string>
  | Taction<'SET_PAYPAL_ADDRESS', string>
  | Taction<'SET_GPS_INFO', string>
  | Taction<'SET_INFO', string>
  | Taction<'SET_instagram', string>
  | Taction<'SET_facebook', string>
  | Taction<'SET_telegram', string>
  | Taction<'SET_skype', string>

function reducer(state: Tinit, action: Tactions): Tinit {
  const newState = (() => {
    switch (action.type) {
      case 'SET_SRC':
        return {
          ...state,
          src: action.payload,
        }
      case 'SET_FILE':
        return {
          ...state,
          file: action.payload,
        }
      case 'CLEAR_FILE':
        return {
          ...state,
          file: init.file,
          src: init.src,
        }
      case 'SET_INFO':
        return {
          ...state,
          info: action.payload,
        }
      case 'SET_PASSWORD':
        return {
          ...state,
          password: action.payload,
          isPasswordErr: !(action.payload === '' || action.payload.length > 5),
          isConfirmPassErr: !(state.confirmPassword === action.payload),
        }
      case 'SET_CONFIRMPASSWORD':
        return {
          ...state,
          confirmPassword: action.payload,
          isConfirmPassErr: !(state.password === action.payload),
        }
      case 'SET_LAST_NAME':
        return {
          ...state,
          last_name: /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(action.payload) ? state.last_name : action.payload,
        }
      case 'SET_FIRST_NAME':
        return {
          ...state,
          first_name: /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(action.payload) ? state.first_name : action.payload,
        }
      case 'SET_NICK':
        const isNickE = !/[a-zA-Z0-9_-]{5,32}$/g.test(action.payload || '')
        return {
          ...state,
          isNickE: isNickE,
          isChangeNick: isNickE ? false : true,
          nick: action.payload,
        }
      case 'SET_MHC_ADDRESS':
        return {
          ...state,
          mhc_address: action.payload,
        }
      case 'SET_ETH_ADDRESS':
        return {
          ...state,
          eth_address: action.payload,
        }
      case 'SET_BNB_ADDRESS':
        return {
          ...state,
          bnb_address: action.payload,
        }
      case 'SET_BTC_ADDRESS':
        return {
          ...state,
          btc_address: action.payload,
        }
      case 'SET_PAYPAL_ADDRESS':
        return {
          ...state,
          paypal_address: action.payload,
        }
      case 'SET_GPS_INFO':
        return {
          ...state,
          gps_info: action.payload,
        }
      case 'SET_instagram':
        return {
          ...state,
          instagram: action.payload,
        }
      case 'SET_facebook':
        return {
          ...state,
          facebook: action.payload,
        }
      case 'SET_telegram':
        return {
          ...state,
          telegram: action.payload,
        }
      case 'SET_skype':
        return {
          ...state,
          skype: action.payload,
        }

      case 'CLEAR_STATE':
        return init

      default:
        //const a:never ;
        throw new Error()
    }
  })()

  if (['SET_LAST_NAME', 'SET_FIRST_NAME'].includes(action.type) && !newState.isChangeNick) {
    const last_name: string = (newState?.last_name[0]?.toUpperCase() ?? '') + newState?.last_name?.slice(1) ?? ''
    const nick = `${newState.first_name}${last_name}`
    if (/[a-zA-Z0-9_-]{5,32}$/g.test(nick)) {
      newState.nick = nick
      newState.isNickE = false
    }
  }

  if (newState.isPasswordErr === true) return { ...newState, isButtonDisabled: true }
  if (newState.isConfirmPassErr === true) return { ...newState, isButtonDisabled: true }
  if (newState.isFacebookE === true) return { ...newState, isButtonDisabled: true }
  if (newState.isTelegramE === true) return { ...newState, isButtonDisabled: true }
  if (newState.isInstagramE === true) return { ...newState, isButtonDisabled: true }
  if (newState.isNickE === true) return { ...newState, isButtonDisabled: true }
  if (newState.isSkypeE === true) return { ...newState, isButtonDisabled: true }

  if (newState.src.length) return { ...newState, isButtonDisabled: false }
  if (newState.first_name.length) return { ...newState, isButtonDisabled: false }
  if (newState.last_name.length) return { ...newState, isButtonDisabled: false }
  if (newState.info.length) return { ...newState, isButtonDisabled: false }
  if (newState.password.length) return { ...newState, isButtonDisabled: false }
  if (newState.confirmPassword.length) return { ...newState, isButtonDisabled: false }

  return { ...newState, isButtonDisabled: false }
}

export function useProfile() {
  const asyncDispatch = useDispatch()
  const { showToastMsg } = useCommonCheck()
  const [state, dispatch] = useReducer(reducer, init)

  const userInfo = useSelector<AppState, UserInfo>(({ user }) => user.get('userInfo'))

  useEffect(() => {
    dispatch({
      type: 'SET_FIRST_NAME',
      payload: userInfo.first_name ? userInfo.first_name : '',
    })
    dispatch({
      type: 'SET_LAST_NAME',
      payload: userInfo.last_name ? userInfo.last_name : '',
    })
    dispatch({ type: 'SET_INFO', payload: userInfo.info ? userInfo.info : '' })
    dispatch({
      type: 'SET_facebook',
      payload: userInfo.social_network?.facebook ? userInfo.social_network?.facebook : '',
    })
    dispatch({
      type: 'SET_instagram',
      payload: userInfo.social_network?.instagram ? userInfo.social_network?.instagram : '',
    })
    dispatch({
      type: 'SET_telegram',
      payload: userInfo.social_network?.telegram ? userInfo.social_network?.telegram : '',
    })
    dispatch({
      type: 'SET_skype',
      payload: userInfo.social_network?.skype ? userInfo.social_network?.skype : '',
    })
    dispatch({
      type: 'SET_NICK',
      payload: userInfo.nick ? userInfo.nick : '',
    })
    dispatch({
      type: 'SET_MHC_ADDRESS',
      payload: userInfo.mhc_address ? userInfo.mhc_address : '',
    })
    dispatch({
      type: 'SET_ETH_ADDRESS',
      payload: userInfo.eth_address ? userInfo.eth_address : '',
    })
    dispatch({
      type: 'SET_BNB_ADDRESS',
      payload: userInfo.bnb_address ? userInfo.bnb_address : '',
    })
    dispatch({
      type: 'SET_BTC_ADDRESS',
      payload: userInfo.btc_address ? userInfo.btc_address : '',
    })
    dispatch({
      type: 'SET_PAYPAL_ADDRESS',
      payload: userInfo.paypal_address ? userInfo.paypal_address : '',
    })
    dispatch({
      type: 'SET_GPS_INFO',
      payload: userInfo.gps_info ? userInfo.gps_info : '',
    })
  }, [userInfo])

  const onChangeFile = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const fileList = e.currentTarget.files
      if (fileList === null) {
        e.currentTarget.value = ''
        dispatch({ type: 'CLEAR_FILE' })
        return
      }
      if (fileList.length === 0) {
        e.currentTarget.value = ''
        dispatch({ type: 'CLEAR_FILE' })
        return
      }
      const file = fileList[0]

      if (file.size > 1e6) {
        showToastMsg('Max file size 1MB', 'error')
        e.currentTarget.value = ''
        dispatch({ type: 'CLEAR_FILE' })
        return
      }
      const src = window.URL.createObjectURL(fileList[0])
      dispatch({ type: 'SET_SRC', payload: src })
      const formData = new FormData()
      formData.append('file', file)

      dispatch({ type: 'SET_FILE', payload: formData })
    },
    [showToastMsg]
  )

  const onChangeText = useCallback(
    (
        type:
          | 'SET_PASSWORD'
          | 'SET_CONFIRMPASSWORD'
          // | 'SET_NAME'
          | 'SET_INFO'
          | 'SET_instagram'
          | 'SET_facebook'
          | 'SET_telegram'
          | 'SET_skype'
          | 'SET_NICK'
          | 'SET_MHC_ADDRESS'
          | 'SET_ETH_ADDRESS'
          | 'SET_BNB_ADDRESS'
          | 'SET_BTC_ADDRESS'
          | 'SET_PAYPAL_ADDRESS'
          | 'SET_GPS_INFO'
          | 'SET_LAST_NAME'
          | 'SET_FIRST_NAME'
      ) =>
      (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({ type, payload: e.currentTarget.value })
      },
    [dispatch]
  )

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      asyncDispatch(
        onUserEdit({
          file: state.file,
          first_name: state.first_name,
          last_name: state.last_name,
          info: state.info,
          password: state.password,
          instagram: state.instagram,
          facebook: state.facebook,
          telegram: state.telegram,
          skype: state.skype,
          nick: state.nick,
          mhc_address: state.mhc_address,
          eth_address: state.eth_address,
          bnb_address: state.bnb_address,
          paypal_address: state.paypal_address,
        })
      )
      dispatch({ type: 'CLEAR_STATE' })
    },
    [asyncDispatch, state]
  )

  return {
    state,
    onChangeFile,
    onChangeText,
    onSubmit,
    userInfo,
  }
}
