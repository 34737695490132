import { takeEvery } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { workerAddEmails, workerGetList, workerRemoveEmail, workerSendEmails, workerSendTo } from './workers'
import { fetchAddEmails, fetchGetList, fetchRemoveEmail, fetchSendEmails, fetchSendTo } from '../actions'

export default function* watchSendEmails(): SagaIterator {
  yield takeEvery<typeof fetchAddEmails>(fetchAddEmails, workerAddEmails)
  yield takeEvery<typeof fetchGetList>(fetchGetList, workerGetList)
  yield takeEvery<typeof fetchRemoveEmail>(fetchRemoveEmail, workerRemoveEmail)
  yield takeEvery<typeof fetchSendEmails>(fetchSendEmails, workerSendEmails)
  yield takeEvery<typeof fetchSendTo>(fetchSendTo, workerSendTo)
}
