import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { getMarketSpaceList } from 'actions/marketspace'
import MarketSpaceBody from './marketSpaceBody'
import { useGetThemeMode } from 'state/theme/hook'

const MarketSpaceContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border-top: 2px solid;
`

const MarketSpace: React.FC = () => {
  const themeMode = useGetThemeMode()
  const [flag, setFlag] = useState<boolean>(false)

  const momorizedDispatch = useCallback(() => {
    setFlag(true)
  }, [])

  useEffect(() => {
    momorizedDispatch()

    return () => {
      setFlag(false)
    }
  }, [momorizedDispatch])

  return (
    <>
      <CSSTransition
        in={flag}
        timeout={4500}
        classNames="searchEngineFadeTransition"
        unmountOnExit
        onEnter={() => setFlag(true)}
        onExited={() => setFlag(false)}
      >
        <MarketSpaceContainer
          style={{
            background: themeMode == 'main' ? '#00000090' : '#ffffff90',
            borderTopColor: themeMode == 'main' ? '#015297' : 'transparent',
          }}
        >
          <MarketSpaceBody />
        </MarketSpaceContainer>
      </CSSTransition>
    </>
  )
}
export default MarketSpace
