import { AudioMenu } from 'components/Menu/tooltip'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import { useGetThemeMode } from 'state/theme/hook'
import {
  checkLogin,
  clearErrorAuth,
  clearLogin,
  onAuth,
  onGamebling,
  onSeomoney,
  onSocial,
} from '../../../actions/user'
import HeaderLoginLinks from '../../../components/Header/HeaderLogin/HeaderLoginLinks'
import MainContent from '../../../components/MainContent'
import LoginFormInp from '../LoginInp'
import { FormButton, FormContainer, FormTitle } from '../styles'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useAccount } from 'wagmi'
import { useCommonCheck } from 'hooks/useCommon'

const Login: React.FC<any> = () => {
  const dispatch = useDispatch()
  const { address: wallet, isConnected } = useAccount()
  const { showToastMsg, validateEmail } = useCommonCheck()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errors, setErrors] = useState<Array<string>>([])
  const [social, setSocial] = useState<boolean>(false)
  const [seomoney, setSeomoney] = useState<boolean>(false)
  const [gamebling, setGamebling] = useState<boolean>(false)
  const [phone, setPhone] = useState<any>('')

  useEffect(() => {
    AudioMenu('show_login').play()
  }, [])

  useEffect(() => {
    const currentUrl = window.location.href
    if (currentUrl.includes('?rtn=social')) {
      setSocial(true)
    }
    if (currentUrl.includes('?rtn=seomoney')) {
      setSeomoney(true)
    }
    if (currentUrl.includes('?rtn=gamebling')) {
      setGamebling(true)
    }

    dispatch(onSocial(social))
    dispatch(onSeomoney(seomoney))
    dispatch(onGamebling(gamebling))
    // dispatch(clearErrorAuth())
    // dispatch(clearLogin())
    // return () => {
    //   dispatch(clearErrorAuth())
    // }
  }, [dispatch, social, seomoney, gamebling])

  const signIn = () => {
    if (!phone || phone == 0 || phone.length < 12 || phone.length > 17) {
      showToastMsg('Invalid phone number', 'error')
      return
    }

    if (!isConnected || wallet == undefined) {
      showToastMsg('Invalid wallet connection', 'error')
      return
    }

    if (!validateEmail(email)) {
      showToastMsg('Invalid email address', 'error')
      return
    }

    if (!password || password.length < 6) {
      showToastMsg('Empty or Invalid password', 'error')
      return
    }

    dispatch(onAuth({ phone, wallet, email, password }))
  }
  const themeMode = useGetThemeMode()

  return (
    <MainContent cn__maincontent_startpage="cn__maincontent_startpage">
      <FormContainer>
        <FormTitle>
          <span style={{ color: themeMode == 'main' ? 'white' : 'black' }}>Sign in</span>
        </FormTitle>
        {social && (
          <div className="headerLoginLinksContainer">
            <h3>
              <span style={{ color: themeMode == 'main' ? 'white' : 'black' }}>
                Please Sign in here to access our Social site
              </span>
            </h3>
          </div>
        )}
        {seomoney && (
          <div className="headerLoginLinksContainer">
            <h3>
              <span style={{ color: themeMode == 'main' ? 'white' : 'black' }}>
                Please Sign in here to access Seomoney site
              </span>
            </h3>
          </div>
        )}
        {gamebling && (
          <div className="headerLoginLinksContainer">
            <h3>
              <span style={{ color: themeMode == 'main' ? 'white' : 'black' }}>
                Please Sign in here to access Gamebling site
              </span>
            </h3>
          </div>
        )}

        <PhoneInput
          placeholder="Enter phone number"
          international
          defaultCountry="US"
          value={phone}
          onChange={setPhone}
        />

        <LoginFormInp
          label={'Email address'}
          type={'email'}
          name={'email'}
          value={email}
          themeMode={themeMode}
          onChange={setEmail}
          isError={errors.includes('email')}
        />
        <LoginFormInp
          label={'Password'}
          type={'password'}
          value={password}
          themeMode={themeMode}
          onChange={setPassword}
          isError={errors.includes('password')}
        />
        <div className="forgotPwdLinkContainer">
          <NavLink
            to="/forgotPassword/"
            className={themeMode == 'main' ? 'forgotP' : themeMode == 'light' ? 'forgotP-d' : 'forgotP'}
          >
            Forgot Password?
          </NavLink>
        </div>

        <FormButton
          type="button"
          onClick={() => signIn()}
          style={{
            background: themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        >
          Sign in
        </FormButton>
        <div className="headerLoginLinksContainer">
          <HeaderLoginLinks text={'New user?'} to={'/register/'} label={'Sign up'} themeMode={themeMode} />
        </div>
      </FormContainer>
    </MainContent>
  )
}

export default Login
