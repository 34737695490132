import { Editor, EditorChange } from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/theme/material.css'
import { useCommonCheck } from 'hooks/useCommon'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Controlled as CodeMirror } from 'react-codemirror2'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useGetThemeMode } from 'state/theme/hook'
import { AppState } from '../../../redusers/config'
import { fetchGetTmpl, fetchSetTmpl } from './actions'
import cn from './cn.module.css'
import { SendEmailAdmin } from './reduser'

const codeOptions = {
  mode: 'htmlmixed',
  theme: 'material',
  lineNumbers: true,
  showCursorWhenSelecting: true,
}

export const SettingEmail: FC = () => {
  const themeMode = useGetThemeMode()
  const dispatch = useDispatch()
  const { showToastMsg } = useCommonCheck()

  const [code, setCode] = useState<string>('')

  const isSetTmpl = useSelector<AppState, SendEmailAdmin['isSetTmpl']>(
    ({ sendEmailAdminReducer }) => sendEmailAdminReducer.isSetTmpl,
    shallowEqual
  )

  const mailTmpl = useSelector<AppState, SendEmailAdmin['mailTmpl']>(
    ({ sendEmailAdminReducer }) => sendEmailAdminReducer.mailTmpl,
    shallowEqual
  )

  useEffect(() => {
    if (isSetTmpl.get('data') === true && isSetTmpl.get('isLoading') === true) {
      showToastMsg('Temple has been change', 'success')
    }
  }, [isSetTmpl])

  useEffect(() => {
    setCode(mailTmpl.get('data') ?? '')
  }, [mailTmpl])

  const memoizedDescription = useCallback(
    (editor: Editor, data: EditorChange, value: string) => {
      setCode(value)
    },
    [setCode]
  )

  const memoizedSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      dispatch(fetchSetTmpl(code))
    },
    [dispatch, code]
  )

  useEffect(() => {
    dispatch(fetchGetTmpl())
  }, [])

  return (
    <div
      className={cn.wraper}
      style={{
        background: themeMode === 'light' ? 'white' : '',
        color: themeMode === 'light' ? 'black' : 'white',
      }}
    >
      <form className={cn.form} onSubmit={memoizedSubmit}>
        <CodeMirror
          value={code}
          options={codeOptions}
          onBeforeChange={memoizedDescription}
          className="code-send-email"
          //onChange={memoizedDescription}
        />
        <p>
          #SENDER_NICKNAME# - This macros will be replaced by Sender Nickname (If the nickname is absent, then the
          user&apos;s mail will be substituted for it).{' '}
        </p>
        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <button
              className={cn.formBtn}
              style={{
                background:
                  themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                color: themeMode == 'main' ? 'white' : 'black',
              }}
            >
              SAVE
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
