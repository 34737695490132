import React, { useEffect, useState } from 'react'
import cn from './cn.module.scss'
import TokenWrapper from './TokenWrapper'
import { Config } from '../WalletList/config'
import { INetwork, ITokenP } from '../../types'
import { useTokenBalance } from '../../hooks'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../redusers/config'

interface IGetBalances {
  walletAddress: string
  token: ITokenP
}
const GetTokenBalances = async (v: IGetBalances) => {
  const newTokenList = await useTokenBalance(v.walletAddress, v.token)
  return newTokenList
}

interface IEthereumTokenList {
  selectedNetwork: INetwork
  selectedWallet: Config
  walletAddress: string
  tokenList: ITokenP[]
  selectedToken: (val: ITokenP) => void
  setIsOpenTokenList: (val: boolean) => void
}

const EthereumTokenList: React.FC<IEthereumTokenList> = ({
  selectedNetwork,
  selectedWallet,
  walletAddress,
  tokenList,
  selectedToken,
  setIsOpenTokenList,
}) => {
  const [newTokenList, setNewTokenList] = useState<ITokenP[]>([])

  const mhcTokenBalance = useSelector<AppState, number>(({ portfolio }) => portfolio.get('mhcTokenBalance'))
  const isLoadPortfolio = useSelector<AppState, boolean>(({ portfolio }) => portfolio.get('isLoadPortfolio'))

  const onSelectWallet = (token: ITokenP) => () => {
    selectedToken(token)
    setIsOpenTokenList(false)
  }

  const tokenListTemp: ITokenP[] = []
  const promises: Array<any> = []

  useEffect(() => {
    if (selectedNetwork.name === 'MetaHash') {
      const tokenListTemp = [...tokenList]
      tokenListTemp[0] = { ...tokenListTemp[0], balance: mhcTokenBalance }
      tokenList = [...tokenListTemp]
      if (!isLoadPortfolio) {
        setNewTokenList(tokenList)
      }
    } else {
      tokenList.forEach((item, index) => {
        promises.push(
          GetTokenBalances({ walletAddress: walletAddress, token: item }).then((elem) => {
            item.balance = elem
            tokenListTemp.push(item)
          })
        )
      })
      Promise.all(promises).then(() => {
        setNewTokenList(tokenListTemp)
      })
    }

    return () => {
      setNewTokenList([...[]])
      tokenListTemp.splice(0, tokenListTemp.length)
    }
  }, [mhcTokenBalance, isLoadPortfolio])

  return (
    <>
      {newTokenList.length > 0 && (
        <div className={cn.tokenListWrapper}>
          {newTokenList.map((item, index) => {
            return <TokenWrapper key={index} token={item} selectToken={onSelectWallet(item)} />
          })}
        </div>
      )}
    </>
  )
}

export default EthereumTokenList
