import React from 'react'
import { useGetThemeMode } from 'state/theme/hook'
import { FlexRow, FlexRowContent, SubContainer, TitleText } from '../../styled'
import { MonthSelectBox } from '../MonthPicker'
import { LineChart } from './LineChart'

export const PurchaseChart: React.FC = () => {
  const themeMode = useGetThemeMode()
  return (
    <SubContainer width={'38%'} height={'260px'} themeMode={themeMode}>
      <FlexRow>
        <FlexRowContent>
          <TitleText>Purchases</TitleText>
          <MonthSelectBox />
        </FlexRowContent>
      </FlexRow>
      <LineChart />
    </SubContainer>
  )
}
