import HeaderLoginLinks from 'components/Header/HeaderLogin/HeaderLoginLinks'
import { useCommonCheck } from 'hooks/useCommon'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import { useGetThemeMode } from 'state/theme/hook'
import LoginFormInp from 'views/Login/LoginInp'
import { clearErrorRegistration, onRegistration } from '../../../actions/user'
import MainContent from '../../../components/MainContent'
import { FormButton, FormContainer, FormTitle } from '../styles'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useAccount } from 'wagmi'

const SignUp: React.FC<any> = () => {
  const { showToastMsg, validateEmail } = useCommonCheck()
  const errorRegistration = useSelector<AppState, Init['errorRegistration']>(({ user }) =>
    user.get('errorRegistration')
  )
  const isActiveUser = useSelector<AppState, Init['isActiveUser']>(({ user }) => user.get('isActiveUser'))
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [cPassword, setCPassword] = useState<string>('')
  const [errors, setErrors] = useState<Array<string>>([])
  const [phone, setPhone] = useState<any>(0)

  const dispatch = useDispatch()
  const history = useHistory()
  const { address: wallet, isConnected, isDisconnected } = useAccount()

  useEffect(() => {
    dispatch(clearErrorRegistration())
    return () => {
      dispatch(clearErrorRegistration())
    }
  }, [dispatch])

  useEffect(() => {
    if (!isActiveUser && errorRegistration == 'no_error') {
      showToastMsg(
        'Congratulation on your registration! You should activate your account via email to login.',
        'success'
      )
      history.push('/login/')
    }
  }, [errorRegistration, isActiveUser])

  const signUp = () => {
    if (!phone || phone == 0 || phone.length < 12 || phone.length > 17) {
      showToastMsg('Invalid phone number', 'error')
      return
    }

    if (!isConnected || wallet == undefined) {
      showToastMsg('Invalid wallet connection', 'error')
      return
    }

    if (!validateEmail(email)) {
      showToastMsg('Invalid email address', 'error')
      return
    }

    if (!password) {
      showToastMsg('Invalid password', 'error')
      return
    }

    if (password.length < 6) {
      showToastMsg('Password has more than 6 characters', 'error')
      return
    }

    if (password !== cPassword) {
      showToastMsg('Invalid confirm password', 'error')
      return
    }

    dispatch(onRegistration({ phone, wallet, email, password }))
  }

  const showErrorTxt = () => {
    return showToastMsg(errorRegistration, 'error')
  }

  const themeMode = useGetThemeMode()

  return (
    <MainContent cn__maincontent_startpage="cn__maincontent_startpage">
      <FormContainer>
        <FormTitle>
          <span style={{ color: themeMode == 'main' ? 'white' : 'black' }}>Sign up</span>
        </FormTitle>
        <PhoneInput
          placeholder="Enter phone number"
          international
          defaultCountry="US"
          value={phone}
          onChange={setPhone}
        />
        <LoginFormInp
          label={'Email address'}
          type={'email'}
          name={'email'}
          value={email}
          themeMode={themeMode}
          onChange={setEmail}
          isError={errors.includes('email')}
        />
        <LoginFormInp
          label={'Password'}
          type={'password'}
          value={password}
          themeMode={themeMode}
          onChange={setPassword}
          isError={errors.includes('password')}
        />
        <LoginFormInp
          label={'Confirm password'}
          type={'password'}
          value={cPassword}
          themeMode={themeMode}
          onChange={setCPassword}
          isError={errors.includes('cPassword')}
        />
        <FormButton
          type="button"
          onClick={() => signUp()}
          style={{
            background: themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        >
          Sign up
        </FormButton>
        <div className="headerLoginLinksContainer">
          <HeaderLoginLinks text={'Have an account?'} to={'/login/'} label={'Sign in'} themeMode={themeMode} />
        </div>

        {errorRegistration && errorRegistration != 'no_error' && showErrorTxt()}
      </FormContainer>
    </MainContent>
  )
}

export default SignUp
