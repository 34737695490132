import { INetwork } from '../types'

export const defaultNetwork: INetwork = {
  id: 1,
  name: 'MetaHash',
  logoUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3756.png',
  isSelected: true,
}

export const NetworkList: INetwork[] = [
  {
    id: 1,
    name: 'MetaHash',
    logoUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3756.png',
    isSelected: true,
  },
  {
    id: 2,
    name: 'Ethereum',
    logoUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
    isSelected: false,
  },
  {
    id: 3,
    name: 'Binance-Smart-Chain',
    logoUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
    isSelected: false,
  },
]

export const defaultDepositNetwork: INetwork = {
  id: 1,
  name: 'Ethereum',
  logoUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  isSelected: true,
}

export const DepositNetworkList: INetwork[] = [
  {
    id: 1,
    name: 'Ethereum',
    logoUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
    isSelected: true,
  },
  {
    id: 2,
    name: 'Binance-Smart-Chain',
    logoUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
    isSelected: false,
  },
]
