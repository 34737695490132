import React from 'react'
import {
  BigValueText,
  FlexColumn,
  FlexRow,
  FlexRowContent,
  HorizontalDivider,
  SubContainer,
  SubContainerBody,
  SubTitleText,
  TitleText,
} from '../../styled'
import { useGetUnUsedFunds } from '../../hooks/useUnusedFunds'
import { useGetThemeMode } from 'state/theme/hook'

export const UnusedFunds: React.FC = () => {
  const { paypal, hashtagCredit } = useGetUnUsedFunds()
  const themeMode = useGetThemeMode()

  return (
    <SubContainer width={'24%'} height={'260px'} themeMode={themeMode}>
      <FlexRowContent>
        <TitleText>Unused Funds</TitleText>
      </FlexRowContent>
      <SubContainerBody justifyContent={'center'}>
        <FlexColumn>
          <SubTitleText fontSize={'10px'}>AVAILABLE USD</SubTitleText>
          <BigValueText color={'#0084ff'} fontSize={'26px'}>
            {`$${paypal}`}
          </BigValueText>
        </FlexColumn>
        <HorizontalDivider margin={'28px 0'} />
        <FlexColumn>
          <SubTitleText fontSize={'10px'}>HASHTAG CREDITS</SubTitleText>
          <BigValueText color={'#0084ff'} fontSize={'26px'}>
            {`${hashtagCredit.toFixed(2)} #Credits`}
          </BigValueText>
        </FlexColumn>
      </SubContainerBody>
    </SubContainer>
  )
}
