import { onAddWebItem, onGetWebItemList } from 'actions/info'
import FormInput from 'components/FormInput'
import React, { useCallback, useEffect, useState } from 'react'
import { BsChatLeft, BsHandThumbsDown, BsHandThumbsUp, BsPencil } from 'react-icons/bs'
import ReactPaginate from 'react-paginate'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import { useGetThemeMode } from 'state/theme/hook'
import { TWebItem } from 'utils/api'
import { List } from 'immutable'
import './styles.scss'
import { MdLaunch } from 'react-icons/md'

const Web3Frame: React.FC = () => {
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const isAdmin = useSelector<AppState, Init['isAdmin']>(({ user }) => user.get('isAdmin'), shallowEqual)
  const listWebInfo = useSelector<AppState, List<TWebItem>>(({ info }) => info.get('listWebInfo'))
  const [totalItems, setTotalItems] = useState<any>([])

  const handleAddTargetUrl = () => {
    const item = { id: 0, tid: totalItems.length + 1, url: '', like: '', comment: '' }
    const totalArray = [...totalItems, item]
    setTotalItems(totalArray)
  }

  const onChangeText = useCallback(
    (num: number) => (e: React.FormEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value
      const arr: any[] = []
      totalItems.map((v: any, inx: number) => {
        if (inx === num) arr.push({ id: Number(v.id), tid: inx + 1, url: val, like: '', comment: '' })
        else arr.push(v)
      })
      setTotalItems(arr)
    },
    [totalItems]
  )

  const onKeyPressUrl = (num: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return

    const item = totalItems.filter((v: any, inx: number) => inx === num)
    if (item.length > 0) {
      const urlVal = item[0].url
      if (urlVal !== '' && (urlVal.includes('https://') || urlVal.includes('http://'))) {
        dispatch(onAddWebItem(item[0]))
      }
    }
  }

  const openWebUrl = (url: string) => {
    if (url === '') return

    if (url.startsWith('https://') || url.startsWith('http://')) {
      window.open(url, '_blank')
    }
  }

  useEffect(() => {
    if (listWebInfo.size === 0) dispatch(onGetWebItemList())
    else setTotalItems(listWebInfo)
  }, [dispatch, listWebInfo])

  return (
    <div className="web3_container">
      <div className="grid_container">
        {totalItems.map((v: any, inx: number) => {
          let tUrl = ''
          const targetUrl = v.url
          if (targetUrl !== '' && (targetUrl.startsWith('https://') || targetUrl.startsWith('http://'))) {
            tUrl = targetUrl
          }
          return (
            <div
              key={inx}
              className="grid_item"
              style={{
                background: themeMode === 'main' ? '#00000090' : '#ffffff90',
                color: themeMode === 'main' ? 'white' : 'black',
                borderColor: themeMode === 'main' ? '#ffffff90' : '#26a5e0ab',
              }}
            >
              {isAdmin === 'YES' ? (
                <div className="web_input">
                  <FormInput
                    value={targetUrl}
                    placeholder="Add URL here"
                    onChange={onChangeText(inx)}
                    onKeyPress={onKeyPressUrl(inx)}
                    paddingRight="30px"
                    themeMode={themeMode}
                  />
                  <MdLaunch className="launch_icon" size={30} onClick={() => openWebUrl(targetUrl)} />
                </div>
              ) : (
                <span className="target_url" onClick={() => openWebUrl(targetUrl)}>
                  {targetUrl}
                </span>
              )}

              {tUrl !== '' && (
                <>
                  <iframe
                    src={tUrl}
                    className="web_frame"
                    style={{ height: isAdmin === 'YES' ? '260px' : '290px' }}
                    sandbox="allow-scripts allow-same-origin allow-presentation"
                  ></iframe>
                  <div className="bottom_item">
                    <div className="b_item">
                      <BsHandThumbsUp />
                      <BsHandThumbsDown color="gray" />
                      <span className="b_val">{10} %</span> Upvoted
                    </div>
                    <div className="b_item">
                      <BsChatLeft />
                      <span className="b_val">{25}</span> comments
                    </div>
                  </div>
                </>
              )}
            </div>
          )
        })}
      </div>
      <div className="count_container">
        {isAdmin === 'YES' && (
          <button
            className="save_btn"
            style={{
              background:
                themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
              color: themeMode == 'main' ? 'white' : 'black',
            }}
            onClick={handleAddTargetUrl}
          >
            Add URL
          </button>
        )}
      </div>
    </div>
  )
}

export default Web3Frame
