import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchedData, genFetchedData } from '../../../utils/api/fetchedData'
import type { TRGetTemplate, TRPreviewTemplate } from './api'
import { TRGetTemplateList } from './api'

export interface SendEmailAdmin {
  isSetTmpl: FetchedData<boolean>
  mailTmpl: FetchedData<string>
  templateList: FetchedData<TRGetTemplateList[]>
  template: FetchedData<TRGetTemplate>
  isSetTemplate: FetchedData<boolean>
  previewTemplate: FetchedData<TRPreviewTemplate>
}

export const initialState: SendEmailAdmin = {
  isSetTmpl: genFetchedData<boolean>(null),
  mailTmpl: genFetchedData<string>(null),
  templateList: genFetchedData<TRGetTemplateList[]>(null),
  template: genFetchedData<TRGetTemplate>(null),
  isSetTemplate: genFetchedData<boolean>(null),
  previewTemplate: genFetchedData<TRPreviewTemplate>(null),
}

const sendEmailSliceAdmin = createSlice({
  name: 'SendEmailAdmin',
  initialState,
  reducers: {
    setIsTmpl(state, action: PayloadAction<SendEmailAdmin['isSetTmpl']>) {
      state.isSetTmpl = action.payload
    },
    delIsTmpl(state) {
      state.isSetTmpl = initialState.isSetTmpl
    },
    setMailTmpl(state, action: PayloadAction<SendEmailAdmin['mailTmpl']>) {
      state.mailTmpl = action.payload
    },
    delMailTmpl(state) {
      state.mailTmpl = initialState.mailTmpl
    },
    setTemplateList(state, action: PayloadAction<SendEmailAdmin['templateList']>) {
      state.templateList = action.payload
    },
    delTemplateList(state) {
      state.templateList = initialState.templateList
    },
    setTemplate(state, action: PayloadAction<SendEmailAdmin['template']>) {
      state.template = action.payload
    },
    delTemplate(state) {
      state.template = initialState.template
    },
    setIsSetTemplate(state, action: PayloadAction<SendEmailAdmin['isSetTemplate']>) {
      state.isSetTemplate = action.payload
    },
    delIsSetTemplate(state) {
      state.isSetTemplate = initialState.isSetTemplate
    },
    setPreviewTemplate(state, action: PayloadAction<SendEmailAdmin['previewTemplate']>) {
      state.previewTemplate = action.payload
    },
    delIsPreviewTemplate(state) {
      state.previewTemplate = initialState.previewTemplate
    },
  },
})

export const {
  setIsTmpl,
  delIsTmpl,
  setMailTmpl,
  delMailTmpl,
  setTemplateList,
  delTemplateList,
  setTemplate,
  delTemplate,
  setIsSetTemplate,
  delIsSetTemplate,
  setPreviewTemplate,
  delIsPreviewTemplate,
} = sendEmailSliceAdmin.actions
export const sendEmailAdminReducer = sendEmailSliceAdmin.reducer
