import Loader from 'components/Loader'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import { BsStarFill } from 'react-icons/bs'
import { onAddWishDomain } from 'actions/hashtag'
import { IGetAllDomainListR } from 'utils/api'
import { useGetDomainList } from 'views/Search/hooks/availableCategoryList'
import { MdAddShoppingCart, MdClose } from 'react-icons/md'
import { useGlobalModalContext } from '../GlobalModal'
import { addItemCart } from 'views/Cart/actions'

const ModalContainer = styled.div`
  width: 550px;
  height: 650px;
  z-index: 99;
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
  box-size: border-box;
  border: 1px solid gray;
  border-radius: 10px;
`
const ModalTitle = styled.div`
  font-size: 25px;
  font-weight: 700;
  padding: 30px 20px;
  text-decoration: underline;
`
const CloseButton = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`
const BookMark = styled.span`
  display: flex;
`
const StarIcon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 25px;
  font-size: 14px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
`
const BuyButton = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 0px;
  border: 1px solid;
  border-radius: 5px;
  font-size: 12px;
  width: 70px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
`
const RowItem = styled.span`
  display: flex;
  align-items: center;
  font-size: 18px;
`
const ModalBody = styled.div`
  font-size: 18px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 20px;
  height: 100%;
`
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0 3px 0;
  font-weight: 600;
  border-bottom: 1px solid gray;
  border-bottom-style: dashed;
`
const WishModal: React.FC<any> = () => {
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const { hideModal, showModal } = useGlobalModalContext()
  const { domainList, accountList, userInfo } = useGetDomainList()

  const onRemoveWish = (domainN: string, wishVal: string) => {
    if (wishVal != '') {
      const wishArr = wishVal.split(',').filter((v) => v != userInfo.id.toString())
      const wish_val = wishArr.join()

      dispatch(
        onAddWishDomain({
          domainId: domainN,
          wishVal: wish_val,
        })
      )
    }
  }

  const addShopping = (domainN: string, resalePrice: number, wishVal: string) => {
    dispatch(
      addItemCart({
        id: domainN,
        isDemo: false,
        price: resalePrice,
        applyDiscount: false,
        type: 'domain',
        keyword: '',
      })
    )

    // onRemoveWish(domainN, wishVal)
  }

  const filterArr = domainList.filter(
    (v: IGetAllDomainListR) =>
      v.wishes !== null && v.wishes !== '' && v.wishes.split(',').includes(userInfo.id.toString())
  )

  return (
    <ModalContainer
      style={{
        background: themeMode == 'main' ? '#000000d5' : '#ffffffe5',
        color: themeMode == 'main' ? 'white' : 'black',
      }}
    >
      <ModalTitle>Wish Domain list</ModalTitle>
      <ModalBody>
        {filterArr.length == 0 && (
          <Loading className="shift-loader-spinner">
            <Loader stroke="#58d7f7" size="40px" />
          </Loading>
        )}
        {filterArr.map((item, index) => (
          <Row key={index}>
            <RowItem>
              {index + 1} {item.domainName}
            </RowItem>
            <BookMark>
              <StarIcon onClick={() => onRemoveWish(item.domainName, item.wishes)}>
                <BsStarFill size={20} color={themeMode === 'light' ? '#ff5900' : '#ffa600'} />
              </StarIcon>
              {item.resale.status ? (
                <BuyButton
                  style={{
                    background: 'linear-gradient(#60fb12, #41ae0a)',
                    borderColor: themeMode === 'main' ? 'white' : 'yellow',
                  }}
                  onClick={() => addShopping(item.domainName, item.resale.price, item.wishes)}
                >
                  BUY <MdAddShoppingCart size={15} />
                </BuyButton>
              ) : (
                <BuyButton style={{ background: 'gray' }}>
                  SOLD <MdAddShoppingCart size={15} />
                </BuyButton>
              )}
            </BookMark>
          </Row>
        ))}
      </ModalBody>
      <CloseButton onClick={hideModal}>
        <MdClose size={28} />
      </CloseButton>
    </ModalContainer>
  )
}
export default WishModal
