import React from 'react'
import Chart from 'react-apexcharts'
import { IAssetsTable } from '../../../types'

interface IPieChartP {
  assetsList: IAssetsTable[]
}

const PieChart: React.FC<IPieChartP> = ({ assetsList }) => {
  const series = assetsList.map((asset) => asset.usdAmount)
  const labels = assetsList.map((asset) => asset.name)

  const formatter = (w: any) => {
    const total = w.globals.seriesTotals.reduce((a: any, b: any) => {
      return a + b
    }, 0)

    return `$${total.toFixed(6)}`
  }

  const state: any = {
    series: series,
    options: {
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: false,
                label: 'Total',
                fontSize: '12px',
                fontWeight: 400,
                color: '#919599',
                offsetY: 4,
                formatter: (w: any) => {
                  return formatter(w)
                },
              },
              value: {
                show: true,
                fontSize: '16px',
                fontWeight: 600,
                color: '#fff',
                offsetY: 4,
                formatter: function (val: any) {
                  return `$${val}`
                },
              },
            },
          },
        },
      },
      labels: labels,
      legend: {
        position: 'bottom',
      },
    },
  }

  return (
    <>
      <Chart options={state.options} series={state.series} type="donut" width={'100%'} height={280} />
    </>
  )
}

export default PieChart
