import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import cn from './cn.module.scss'
import styled from 'styled-components'

import MainContent from '../../components/MainContent'
import { GradientButton } from '../../components/Buttons/GradientButton'
import ChartContainer from './components/ChartsContainer'
import AssetsTable from './components/AssetsTable'
import AddAssetsMainModal from './components/AddAssetsMainModal'
import { getPortfoiliList } from './actions'
import { useGetThemeMode } from 'state/theme/hook'

const AddAssetsButton = styled(GradientButton)`
  font-weight: 700;
  font-size: 16px;
  color: #000;
  border-radius: 5px;
  @media only screen and (max-width: 1024px) {
    font-size: 15px;
    width: 140px;
  }
  @media only screen and (max-width: 640px) {
    font-size: 13px;
    width: 120px;
  }
`

const onAddAssets = (setShowAddAssetsModal: Function) => (e: React.FormEvent<HTMLElement>) => {
  setShowAddAssetsModal(true)
  e.preventDefault()
}

const closeAddAssetsModal = (setShowAddAssetsModal: Function) => (e: React.FormEvent<HTMLElement>) => {
  setShowAddAssetsModal(false)
}

const Portfolio: React.FC<any> = () => {
  const [showAddAssetsModal, setShowAddAssetsModal] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPortfoiliList())
  }, [])

  const themeMode = useGetThemeMode()

  return (
    <MainContent>
      <div className={cn.portfolioContainer} style={{ color: themeMode === 'light' ? 'black' : 'white' }}>
        <div className={cn.portfolioHeaderContainer}>
          <div className={cn.titleWrapper}>
            <span className={cn.title}>Your Portfolio</span>
            <span className={cn.description}>Accurately tracking your crypto investment assets.</span>
          </div>
          <div className={cn.buttonContainer}>
            <AddAssetsButton
              width={'200px'}
              padding={'0px'}
              onClick={onAddAssets(setShowAddAssetsModal)}
              style={{
                background:
                  themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                color: themeMode == 'main' ? 'white' : 'black',
              }}
            >
              + Add Assets
            </AddAssetsButton>
          </div>
        </div>
        <div className={cn.portfolioBodyContainer}>
          <ChartContainer />
          <AssetsTable />
        </div>
      </div>
      <AddAssetsMainModal showModal={showAddAssetsModal} onClose={closeAddAssetsModal(setShowAddAssetsModal)} />
    </MainContent>
  )
}

export default Portfolio
