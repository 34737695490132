import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { sendPayData } from '../../../actions/transaction'
import cn from '../cn.module.css'

const Payment = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const url = new URL(window.location.href)
    const amount = url.searchParams.get('amount')
    const type = url.searchParams.get('type')
    const transactionType = url.searchParams.get('transactionType')

    if (url.pathname === '/pay/payment' && amount !== null && type !== null && transactionType !== null) {
      dispatch(sendPayData({ amount, type, transactionType }))
    }
  }, [window.location.href])

  return (
    <div className={cn.payMain}>
      <h4>Please wait</h4>
    </div>
  )
}
export default Payment
