import MenuIcons from 'components/Icons/MenuIcons'
import { DREAMING_URL } from 'constants/urls'
import React, { useCallback, useState } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import './styles.scss'
import { BsChatLeft, BsEye, BsFillEmojiSmileFill, BsFillHandThumbsUpFill, BsHeartFill } from 'react-icons/bs'
import { DreamingList } from 'constants/fakeData'
import { useMenuAudio } from 'components/DomainsDashboardTable/hooks'

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: 280px;
  border-radius: 5px;
  border: 1px solid;
  font-size: 14px;
  padding: 5px 10px;
  margin: 0 15px;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
    margin: 0 5px;
  }
  @media screen and (max-width: 768px) {
    margin: 15px 0 0 0;
  }
`

const Container1 = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid;
  font-size: 14px;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
  }
`

interface RowP {
  seener: string
  title: string
  owner: string
  follow: string
  comment: string
  img: any
  themeMode: string
}
function Row({ seener, title, owner, follow, comment, img, themeMode }: RowP) {
  return (
    <tr>
      <td className="dream-td">
        <img src={img} className="dream-img" alt="" />
      </td>
      <td>
        <div className="dream-row-line1">
          <span className="live">LIVE</span>
          <BsEye color="gray" size={18} />
          <span className="seener">{seener}</span>
        </div>
        <div className="dream-row-line2">
          <span className="dream-title">{title}</span>
          <div>
            <span className="msg-time">by</span> {owner}
          </div>
        </div>
        <div className="dream-row-line1" style={{ marginTop: '7px' }}>
          <BsFillHandThumbsUpFill color="yellow" size={14} />
          <BsFillEmojiSmileFill color="yellow" size={14} />
          <BsHeartFill color="red" size={14} />
          <span className="follow">{follow}</span>
          <BsChatLeft color="gray" size={14} />
          <span className="comment">{comment} Comments</span>
        </div>
      </td>
    </tr>
  )
}

function SteamItem({ seener, title, owner, follow, comment, img, themeMode }: RowP) {
  return (
    <div className="search-dream-item">
      <div className="dream-td">
        <img src={img} className="stream-img" alt="" />
      </div>
      <div className="stream-row-line1">
        <span className="live">LIVE</span>
        <BsEye color="gray" size={18} />
        <span className="seener">{seener}</span>
      </div>
      <div className="stream-row-line2">
        <span className="dream-title">{title}</span>
        {/* <div> */}
        <span className="msg-time">by {owner}</span>
        {/* </div> */}
      </div>
    </div>
  )
}

export interface IPageProps {
  isSearch: boolean
}

const CommandDream: React.FC<IPageProps> = ({ isSearch }) => {
  const themeMode = useGetThemeMode()
  const { playAudio } = useMenuAudio()
  const [streamCount, setStreamCount] = useState<number>(3)

  const seeMoreSteams = () => {}
  return (
    <>
      {!isSearch && (
        <Container
          style={{
            background: themeMode == 'main' ? '#00000090' : '#ffffff90',
            borderColor: themeMode == 'main' ? '#02569d' : 'gray',
          }}
        >
          <div className="top-item">
            <div className="top-left">
              <MenuIcons url="dreaming-white" themeMode={themeMode} size={'18px'} />
              <h3 style={{ marginLeft: '-20px' }}>
                <a
                  href={DREAMING_URL}
                  onClick={() => playAudio('dream')}
                  target="_self"
                  style={{ color: themeMode === 'light' ? 'black' : 'white' }}
                >
                  DREAMING
                </a>
              </h3>
            </div>
            <a href={DREAMING_URL} onClick={() => playAudio('dream')} target="_blank" rel="noreferrer">
              <FiExternalLink size={20} color={themeMode === 'light' ? '#000' : '#fff'} />
            </a>
          </div>
          <div className="dream-space-item">
            <table
              className="dream-table"
              cellSpacing="0"
              cellPadding="10"
              width="100%"
              style={{
                background:
                  themeMode == 'main' ? 'rgba(0, 0, 0, 0.5)' : themeMode == 'light' ? 'white' : 'rgba(0, 0, 0, 0.5)',
                color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
              }}
            >
              <tbody>
                {DreamingList.map((item, index) => {
                  return (
                    <Row
                      key={item.id + index}
                      seener={item.seener}
                      title={item.title}
                      owner={item.owner}
                      follow={item.follow}
                      comment={item.comment}
                      img={item.img}
                      themeMode={themeMode}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        </Container>
      )}

      {isSearch && (
        <Container1
          style={{
            background: themeMode == 'main' ? '#000000cc' : '#e8eff7',
            borderColor: themeMode == 'main' ? '#02569d' : 'gray',
          }}
        >
          <span className="stream-title">
            <h4>Streaming Live Now</h4>
          </span>
          <div className="search-dream-container">
            {DreamingList.map((item, index) => {
              return (
                <>
                  {index < streamCount && (
                    <SteamItem
                      key={item.id + index}
                      seener={item.seener}
                      title={item.title}
                      owner={item.owner}
                      follow={item.follow}
                      comment={item.comment}
                      img={item.img}
                      themeMode={themeMode}
                    />
                  )}
                </>
              )
            })}
          </div>
          {streamCount == 3 && (
            <span className="stream-bottom-title" onClick={() => setStreamCount(DreamingList.length)}>
              See more live streams...
            </span>
          )}
          {streamCount > 3 && (
            <span className="stream-bottom-title" onClick={() => setStreamCount(3)}>
              See top live streams...
            </span>
          )}
        </Container1>
      )}
    </>
  )
}
export default CommandDream
