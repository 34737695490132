import { Editor, EditorChange } from 'codemirror'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redusers/config'
import { getMetaTagsAction, setMetaTagsAction } from './actions'
import { TRInitMetaTags } from './reduser'

export function useMetaTags() {
  const dispatch = useDispatch()
  const [code, setCode] = useState<string>('')

  const isLoad = useSelector<AppState, TRInitMetaTags['metaTags_isLoad']>(({ metaTags_reducer }) =>
    metaTags_reducer.get('metaTags_isLoad')
  )
  const metaTags_code = useSelector<AppState, TRInitMetaTags['metaTags_code']>(({ metaTags_reducer }) =>
    metaTags_reducer.get('metaTags_code')
  )

  useEffect(() => {
    dispatch(getMetaTagsAction())
  }, [])

  useEffect(() => {
    setCode(metaTags_code)
  }, [metaTags_code])

  const memoizedDescription = useCallback(
    (editor: Editor, data: EditorChange, value: string) => {
      setCode(value)
    },
    [setCode]
  )

  const memoizedSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      dispatch(setMetaTagsAction(code))
    },
    [dispatch, code]
  )

  return { code, memoizedDescription, memoizedSubmit, isLoad }
}
