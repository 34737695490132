import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../../../../redusers/config'
import { TAssetChartList } from '../../../../types'

const TokenLineChart: React.FC<any> = () => {
  const assetsChartList = useSelector<AppState, TAssetChartList[]>(({ portfolio }) =>
    portfolio.get('listAssetsChartList')
  )
  const sortedList: TAssetChartList[] = [...assetsChartList].sort((item1, item2) => {
    return item2.tokenBalanceRange.length - item1.tokenBalanceRange.length
  })
  const maxLength = sortedList[0]?.tokenBalanceRange.length
  const result = sortedList
    .map((item) => {
      const itemLength = item.tokenBalanceRange.length
      for (let ii = itemLength; ii < maxLength; ii++) {
        item.tokenBalanceRange.push(item.tokenBalanceRange[itemLength - 1])
      }
      return item.tokenBalanceRange
    })
    .reduce((reduced: any, dataItem) => {
      const len = dataItem.length
      const newVal = []
      for (let idx = 0; idx < len; idx++) {
        const delta = reduced.length > idx ? reduced[idx] : 0
        newVal.push(Number(delta) + Number(dataItem[idx].amount))
      }
      return newVal
    }, [])

  const state: any = {
    options: {
      chart: {
        id: 'portfolio-line',
        toolbar: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        categories:
          sortedList.length > 0
            ? sortedList[0].tokenBalanceRange.map((item) => new Date(item.time).toLocaleString())
            : [],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        show: true,
        showAlways: false,
        showForNullSeries: true,
        forceNiceScale: true,
        labels: {
          formatter: (val: any) => {
            return `$${val.toFixed(2)}`
          },
          style: {
            colors: ['#919599'],
          },
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.5,
          opacityTo: 0.1,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      tooltip: {
        followCursor: true,
        fillSeriesColor: false,
        theme: 'dark',
        style: {
          fontSize: '12px',
        },
        x: {
          show: true,
        },
        y: {
          formatter: undefined,
          title: {
            formatter: (seriesName: any) => seriesName,
          },
        },
      },

      noData: {
        text: 'No Balance Now',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined,
        },
      },
    },
    series: [
      {
        name: 'Current Balance: ',
        data: result,
      },
    ],
  }

  return <>{assetsChartList.length > 0 && <Chart type="area" options={state.options} series={state.series} />}</>
}

export default TokenLineChart
