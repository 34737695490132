import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redusers/config'
import { onCloseModalCode, onRegistrationGA, onUnbindGA } from '../../actions/user'

export function useModal() {
  const asyncDispatch = useDispatch()
  const [isOpenModal, setisOpenModal] = useState<boolean>(false)
  const [isOpenModalUnGA, setisOpenModalUnGA] = useState<boolean>(false)
  const [codeUnGA, setCodeUnGA] = useState<string>('')
  const [codeUnGAEr, setCodeUnGAEr] = useState<boolean>(false)

  const unBindGA = useSelector<AppState, string>(({ user }) => user.get('unBindGA'))

  const memoModalOpen = useCallback(() => {
    setisOpenModal(true)
  }, [setisOpenModal])

  const memoModalOpenUnGA = useCallback(() => {
    setisOpenModalUnGA(true)
  }, [setisOpenModalUnGA])
  const memoModalCloseUnGA = useCallback(() => {
    setisOpenModalUnGA(false)
    setCodeUnGAEr(false)
    setCodeUnGA('')
  }, [setisOpenModalUnGA])

  const memoOnChangeCodeUnGA = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setCodeUnGA(e.currentTarget.value)
      setCodeUnGAEr(false)
    },
    [setCodeUnGA]
  )

  const memoModalCodeClose = useCallback(() => {
    asyncDispatch(onCloseModalCode())
  }, [asyncDispatch])

  const memoModalonSend = useCallback(
    (code: string) => {
      asyncDispatch(onRegistrationGA(code))
    },
    [asyncDispatch]
  )
  const memoModalonSendUnGA = useCallback(() => {
    if (codeUnGA === '') {
      setCodeUnGAEr(true)
      return
    }
    asyncDispatch(onUnbindGA(codeUnGA))
    memoModalCloseUnGA()
  }, [codeUnGA, asyncDispatch, memoModalCloseUnGA])

  return {
    isOpenModal,
    setisOpenModal,
    memoModalOpen,
    memoModalCodeClose,
    memoModalonSend,
    unBindGA,
    isOpenModalUnGA,
    memoModalOpenUnGA,
    memoModalCloseUnGA,
    memoOnChangeCodeUnGA,
    codeUnGA,
    memoModalonSendUnGA,
    codeUnGAEr,
  }
}
