import React, { Fragment, useCallback, useState } from 'react'

interface IParticleEffect {
  txt: string
  themeMode: string
}

const IntroText: React.FC<IParticleEffect> = ({ txt, themeMode }) => {
  function IntroLetter({ char }: { char: string }) {
    const [hoverClass, setHoverClass] = useState('single-letter')
    return (
      <span
        className={hoverClass}
        onMouseOver={() => setHoverClass('single-letter hovered')}
        onMouseLeave={() => setHoverClass('single-letter unhovered')}
      >
        {char}
      </span>
    )
  }

  function IntroWord({ word }: { word: string }) {
    return (
      <span style={{ display: 'inline-block' }}>
        {word.split('').map((letter: string, letterIndex: number) => (
          <IntroLetter key={letterIndex} char={letter} />
        ))}
      </span>
    )
  }
  return (
    <div style={{ color: themeMode === 'light' ? 'black' : 'white' }}>
      <p className="intro-text">
        {txt.split(' ').map((word, wordIndex) => (
          <Fragment key={wordIndex}>
            <IntroWord key={wordIndex} word={word} />
            <span>&nbsp;</span>
          </Fragment>
        ))}
      </p>
    </div>
  )
}

export default IntroText
