import { getListTransaction, getNickList } from 'actions/transaction'
import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init as TransactionInit } from 'redusers/transaction'
import { IGetAllDomainListR, TAccountItem, TAccountList, TGetClaimListR, TGetKeywordListR, UserInfo } from 'utils/api'
import { onAccountGetList } from 'views/Admin/Accounts/actions'
import { getAllDomainList, getAllSearchLogList, getAllSEODomainList, getAllSoldDomainList } from '../actions'
import { TR_Search } from '../types'

export const useAvailableCategoryList = () => {
  return useSelector<AppState, TR_Search['availableDomainList']>(({ search_reducer }) =>
    search_reducer.get('availableDomainList')
  )
}

export const useGetDomainList = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector<AppState, UserInfo>(({ user }) => user.get('userInfo'))
  const listNickName = useSelector<AppState, TransactionInit['listNickName']>(({ transaction }) =>
    transaction.get('listNickName')
  )
  const nickNameList = useMemo(() => {
    if (listNickName && listNickName.length > 0) return [...listNickName]
    else {
      dispatch(getNickList())
      return []
    }
  }, [listNickName, dispatch])

  // account list
  const allAccountList = useSelector<AppState, TAccountList>(({ accounts }) => accounts.get('accountList'))
  const accountList = useMemo(() => {
    if (allAccountList && allAccountList.length > 0) return [...allAccountList]
    else {
      dispatch(onAccountGetList())
      return []
    }
  }, [allAccountList, dispatch])

  // domail list
  const allDomainList = useSelector<AppState, TR_Search['allDomainList']>(
    ({ search_reducer }) => search_reducer.get('allDomainList'),
    shallowEqual
  )
  const domainList = useMemo(() => {
    if (allDomainList && allDomainList.length > 0) return [...allDomainList]
    else {
      dispatch(getAllDomainList())
      return []
    }
  }, [allDomainList, dispatch])

  // seo domain list
  const allSEODomainList = useSelector<AppState, TR_Search['allSEODomainList']>(
    ({ search_reducer }) => search_reducer.get('allSEODomainList'),
    shallowEqual
  )
  const seoDomainList = useMemo(() => {
    if (allSEODomainList && allSEODomainList.length > 0) return [...allSEODomainList]
    else {
      dispatch(getAllSEODomainList())
      return []
    }
  }, [allSEODomainList, dispatch])

  // sold domain list by resale
  const allSoldDomainList = useSelector<AppState, TR_Search['allSoldDomainList']>(
    ({ search_reducer }) => search_reducer.get('allSoldDomainList'),
    shallowEqual
  )
  const soldDomainList = useMemo(() => {
    if (allSoldDomainList && allSoldDomainList.length > 0) return [...allSoldDomainList]
    else {
      dispatch(getAllSoldDomainList())
      return []
    }
  }, [allSoldDomainList, dispatch])

  const dataList = useMemo(() => {
    let data_1: TGetKeywordListR[] = []
    let data_2: TGetKeywordListR[] = []
    let data_3: TGetKeywordListR[] = []
    domainList.map((item) => {
      const arr: TAccountItem[] = accountList.filter((v: TAccountItem) => v.id === item.userId)
      if (item.url !== null || item.resale.status) {
        data_1.push({
          keyword: '',
          domainName: item.domainName,
          url: item.url !== null ? item.url : '',
          resaleStatus: item.resale.status,
          resalePrice: item.resale.price,
          favorites: item.favorites,
          wishes: item.wishes,
          nick: arr[0] ? arr[0].nick : '',
          name: arr[0] ? arr[0].first_name + ',' + arr[0].last_name : '',
          userId: item.userId,
          price: item.price,
        })
      }
    })
    domainList.map((item) => {
      if (item.url == null && !item.resale.status) {
        const arr: TAccountItem[] = accountList.filter((v: TAccountItem) => v.id === item.userId)
        data_2.push({
          keyword: '',
          domainName: item.domainName,
          url: 'unknown',
          resaleStatus: item.resale.status,
          resalePrice: item.resale.price,
          favorites: item.favorites,
          wishes: item.wishes,
          nick: arr[0] ? arr[0].nick : '',
          name: arr[0] ? arr[0].first_name + ',' + arr[0].last_name : '',
          userId: item.userId,
          price: item.price,
        })
      }
    })
    nickNameList.map((item) => {
      const nickname = item.nick
      data_3.push({
        keyword: '',
        domainName: nickname,
        url: 'https://spacebar.hashtag.org/' + nickname.substring(1),
        resaleStatus: false,
        resalePrice: 0,
        favorites: '',
        wishes: '',
        nick: nickname,
        name: '',
        userId: Number(item.id),
        price: 0,
      })
    })
    const list = [...data_1, ...data_3, ...data_2]
    const u_data: any[] = []
    const unique = list.filter((v) => {
      const isDuplicate = u_data.includes(v.domainName)
      if (!isDuplicate) {
        u_data.push(v.domainName)
        return true
      }
      return false
    })
    return unique
  }, [accountList, domainList, nickNameList])

  const keywordList = useMemo(() => {
    let data_1: TGetKeywordListR[] = []
    let data_2: TGetKeywordListR[] = []
    domainList.map((item) => {
      if (item.keywords) {
        if (item.keywords.length > 0 && item.url != null && item.url != '') {
          const arr: TAccountItem[] = accountList.filter((v: TAccountItem) => v.id === item.userId)
          data_1.push({
            keyword: item.keywords.join(),
            domainName: item.domainName,
            url: item.url,
            resaleStatus: item.resale.status,
            resalePrice: item.resale.price,
            favorites: item.favorites,
            wishes: item.wishes,
            nick: arr[0] ? arr[0].nick : '',
            name: arr[0] ? arr[0].first_name + ',' + arr[0].last_name : '',
            userId: item.userId,
            price: item.price,
          })
        }
      }
    })
    domainList.map((item) => {
      if (item.keywords) {
        if (item.keywords.length > 0 && item.url != null && item.url != '') {
        } else {
          const arr: TAccountItem[] = accountList.filter((v: TAccountItem) => v.id === item.userId)
          data_2.push({
            keyword: item.keywords.join(),
            domainName: item.domainName,
            url: 'unknown',
            resaleStatus: item.resale.status,
            resalePrice: item.resale.price,
            favorites: item.favorites,
            wishes: item.wishes,
            nick: arr[0] ? arr[0].nick : '',
            name: arr[0] ? arr[0].first_name + ',' + arr[0].last_name : '',
            userId: item.userId,
            price: item.price,
          })
        }
      }
    })
    return [...data_1, ...data_2]
  }, [accountList, domainList])

  const userDomainList = useMemo(() => {
    const data = domainList.filter((v: IGetAllDomainListR) => v.userId === userInfo.id)
    return data
  }, [domainList, userInfo.id])

  const claimDomainList = useMemo(() => {
    let data: TGetClaimListR[] = []
    domainList.map((item) => {
      if (item.url != null || item.resale.price > 0) {
        const arr: TAccountItem[] = accountList.filter((v: TAccountItem) => v.id === item.userId)
        data.push({
          id: item.id,
          domainName: item.domainName,
          nick: arr[0] ? arr[0].nick : '',
          url: item.url,
          price: item.price,
          // price: item.resale.price,
          date: item.date,
          expire: item.expire,
        })
      }
    })
    return data
  }, [accountList, domainList])

  const dropDomainList = useMemo(() => {
    let data: TGetClaimListR[] = []
    domainList.map((item) => {
      if (!item.isRenewal) {
        const arr: TAccountItem[] = accountList.filter((v: TAccountItem) => v.id === item.userId)
        data.push({
          id: item.id,
          domainName: item.domainName,
          nick: arr[0] ? arr[0].nick : '',
          url: item.url,
          price: item.price,
          // price: item.resale.price,
          date: item.date,
          expire: item.expire,
        })
      }
    })
    return data
  }, [accountList, domainList])

  return {
    userInfo,
    domainList,
    seoDomainList,
    dataList,
    keywordList,
    userDomainList,
    accountList,
    claimDomainList,
    dropDomainList,
    soldDomainList,
    nickNameList,
  }
}

export const useGetLogList = () => {
  const dispatch = useDispatch()
  const allSearchLogList = useSelector<AppState, TR_Search['searchLogList']>(
    ({ search_reducer }) => search_reducer.get('searchLogList'),
    shallowEqual
  )
  const searchLogList = useMemo(() => {
    if (allSearchLogList && allSearchLogList.length > 0) return [...allSearchLogList]
    else {
      dispatch(getAllSearchLogList())
      return []
    }
  }, [allSearchLogList, dispatch])

  return {
    searchLogList,
  }
}

export const useGetTransactionList = () => {
  const dispatch = useDispatch()
  const allTRList = useSelector<AppState, TransactionInit['listTransaction']>(({ transaction }) =>
    transaction.get('listTransaction')
  )
  const transactionList = useMemo(() => {
    if (allTRList && allTRList.length > 0) return [...allTRList]
    else {
      dispatch(getListTransaction())
      return []
    }
  }, [allTRList, dispatch])

  return {
    transactionList,
  }
}

export const GetDomainList = () => {
  const dispatch = useDispatch()

  const userInfo = useSelector<AppState, UserInfo>(({ user }) => user.get('userInfo'))
  const allDomainList = useSelector<AppState, TR_Search['allDomainList']>(
    ({ search_reducer }) => search_reducer.get('allDomainList'),
    shallowEqual
  )

  const listNickName = useSelector<AppState, TransactionInit['listNickName']>(({ transaction }) =>
    transaction.get('listNickName')
  )

  const domainList = useMemo(() => {
    if (allDomainList && allDomainList.length > 0) return [...allDomainList]
    else {
      dispatch(getAllDomainList())
      return []
    }
  }, [allDomainList, dispatch])

  const nickNameList = useMemo(() => {
    if (listNickName && listNickName.length > 0) return [...listNickName]
    else {
      dispatch(getNickList())
      return []
    }
  }, [listNickName, dispatch])

  const dataList = useMemo(() => {
    let data_1: TGetKeywordListR[] = []
    let data_2: TGetKeywordListR[] = []
    let data_3: TGetKeywordListR[] = []
    domainList.map((item) => {
      if (item.url !== null || item.resale.status) {
        data_1.push({
          keyword: '',
          domainName: item.domainName,
          url: item.url !== null ? item.url : '',
          resaleStatus: item.resale.status,
          resalePrice: item.resale.price,
          favorites: item.favorites,
          wishes: item.wishes,
          nick: '',
          name: '',
          userId: 0,
          price: item.price,
        })
      }
    })
    domainList.map((item) => {
      if (item.url == null && !item.resale.status) {
        data_2.push({
          keyword: '',
          domainName: item.domainName,
          url: 'unknown',
          resaleStatus: item.resale.status,
          resalePrice: item.resale.price,
          favorites: item.favorites,
          wishes: item.wishes,
          nick: '',
          name: '',
          userId: 0,
          price: item.price,
        })
      }
    })
    nickNameList.map((item) => {
      const nickname = item.nick
      data_3.push({
        keyword: '',
        domainName: nickname,
        url: 'https://spacebar.hashtag.org/' + nickname.substring(1),
        resaleStatus: false,
        resalePrice: 0,
        favorites: '',
        wishes: '',
        nick: nickname,
        name: '',
        userId: Number(item.id),
        price: 0,
      })
    })
    const list = [...data_1, ...data_3, ...data_2]
    const u_data: any[] = []
    const unique = list.filter((v) => {
      const isDuplicate = u_data.includes(v.domainName)
      if (!isDuplicate) {
        u_data.push(v.domainName)
        return true
      }
      return false
    })
    return unique
  }, [domainList, nickNameList])

  const keywordList = useMemo(() => {
    let data_1: TGetKeywordListR[] = []
    let data_2: TGetKeywordListR[] = []
    domainList.map((item) => {
      if (item.keywords) {
        if (item.keywords.length > 0 && item.url != null && item.url != '') {
          data_1.push({
            keyword: item.keywords.join(),
            domainName: item.domainName,
            url: item.url,
            resaleStatus: item.resale.status,
            resalePrice: item.resale.price,
            favorites: item.favorites,
            wishes: item.wishes,
            nick: '',
            name: '',
            userId: 0,
            price: item.price,
          })
        }
      }
    })
    domainList.map((item) => {
      if (item.keywords) {
        if (item.keywords.length > 0 && item.url != null && item.url != '') {
        } else {
          data_2.push({
            keyword: item.keywords.join(),
            domainName: item.domainName,
            url: 'unknown',
            resaleStatus: item.resale.status,
            resalePrice: item.resale.price,
            favorites: item.favorites,
            wishes: item.wishes,
            nick: '',
            name: '',
            userId: 0,
            price: item.price,
          })
        }
      }
    })
    return [...data_1, ...data_2]
  }, [domainList])

  const userDomainList = useMemo(() => {
    const data = domainList.filter((v: IGetAllDomainListR) => v.userId === userInfo.id)
    return data
  }, [domainList, userInfo.id])

  const isDomain = (val: string) => {
    return val.indexOf('#') === 0 ? true : false
  }

  const isContainSpace = (val: string) => {
    const str = val //.trimLeft()
    return str.includes(' ') ? true : false
  }

  return {
    domainList,
    dataList,
    keywordList,
    userDomainList,
    nickNameList,
    isDomain,
    isContainSpace,
  }
}

export const useGetInstructionList = () => {
  const instructionList = useSelector<AppState, TR_Search['instructionList']>(({ search_reducer }) =>
    search_reducer.get('instructionList')
  )

  const instructions = useMemo(() => {
    if (instructionList && instructionList.length > 0) return [...instructionList]
    else return []
  }, [instructionList])

  return instructions
}
