import { onUpdateUserPrivate } from 'actions/user'
import { ethers } from 'ethers'
import { useCommonCheck } from 'hooks/useCommon'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useGetThemeMode } from 'state/theme/hook'
import { useProfile } from 'views/UserProfile/hooks'
import { useAccount, useProvider } from 'wagmi'
import FormInput from '../../components/FormInput'

import './styles.scss'

const PersonInfo: React.FC<any> = () => {
  const { userInfo } = useProfile()
  const themeMode = useGetThemeMode()
  const dispatch = useDispatch()
  const { address: walletAddress, isConnected } = useAccount()
  const provider = useProvider()
  const { showToastMsg, validateEmail, validateWallet } = useCommonCheck()

  const [emailTxt, setEmailTxt] = useState<string>('')
  const [phoneTxt, setPhoneTxt] = useState<string>('')
  const [walletTxt, setWalletTxt] = useState<string>('')
  const [passwordTxt, setPasswordTxt] = useState<string>('')
  const [rePassTxt, setRePassTxt] = useState<string>('')

  const onChangeText = (flag: string) => (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value
    if (flag === 'email') setEmailTxt(val)
    if (flag === 'phone') setPhoneTxt(val)
    if (flag === 'wallet') setWalletTxt(val)
    if (flag === 'pass') setPasswordTxt(val)
    if (flag === 'repass') setRePassTxt(val)
  }

  const handleDiscard = () => {
    setEmailTxt('')
    setPhoneTxt('')
    setWalletTxt('')
    setPasswordTxt('')
    setRePassTxt('')
  }

  const handleSave = async () => {
    showToastMsg('Invalid confirm password. Invalid confirm password', 'success')

    if (!isConnected || walletAddress == undefined) {
      showToastMsg('Invalid wallet connection', 'error')
      return
    }

    if (emailTxt === '') {
      showToastMsg('Please input email address', 'error')
      return
    }
    if (!validateEmail(emailTxt)) {
      showToastMsg('Invalid email address', 'error')
      return
    }

    if (phoneTxt === '') {
      showToastMsg('Please input phone number', 'error')
      return
    }
    if (phoneTxt.length < 12 || phoneTxt.length > 17) {
      showToastMsg('Invalid phone number', 'error')
      return
    }

    if (walletTxt === '') {
      showToastMsg('Please input wallet address', 'error')
      return
    }
    if (!validateWallet(walletTxt)) {
      showToastMsg('Invalid wallet address', 'error')
      return
    }
    const code = await provider.getCode(walletTxt)
    if (code !== '0x') {
      showToastMsg('Invalid wallet address(contract address)', 'error')
      return
    }

    if (passwordTxt === '') {
      showToastMsg('Please input password', 'error')
      return
    }
    if (passwordTxt.length < 6) {
      showToastMsg('Invalid password', 'error')
      return
    }
    if (passwordTxt !== rePassTxt) {
      showToastMsg('Invalid confirm password', 'error')
      return
    }

    dispatch(
      onUpdateUserPrivate({
        phone: phoneTxt,
        wallet: walletTxt,
        email: emailTxt,
        password: passwordTxt,
      })
    )
  }

  useEffect(() => {
    setEmailTxt(userInfo.email)
    setPhoneTxt(userInfo.phone)
    setWalletTxt(userInfo.wallet)
  }, [userInfo])

  return (
    <div
      className="person_container"
      style={{
        background: themeMode == 'main' ? '#00000090' : '#ffffff90',
        color: themeMode == 'main' ? 'white' : 'black',
      }}
    >
      <div className="info_container">
        <span className="sub_title">EDIT PERSONAL INFO</span>
        <div className="person_input">
          <FormInput
            label=""
            placeholder="Email"
            type="email"
            value={emailTxt}
            onChange={onChangeText('email')}
            className="inputTxt"
            themeMode={themeMode}
          />
          <FormInput
            label=""
            placeholder="Phone/Mobile"
            value={phoneTxt}
            onChange={onChangeText('phone')}
            className="inputTxt"
            themeMode={themeMode}
          />
          <FormInput
            label=""
            placeholder="Wallet"
            value={walletTxt}
            onChange={onChangeText('wallet')}
            className="inputTxt"
            themeMode={themeMode}
          />
        </div>
      </div>
      <div className="pass_container">
        <span className="sub_title">UPDATE PASSWORD</span>
        <div className="person_input">
          <FormInput
            label=""
            placeholder="New Password"
            type="password"
            value={passwordTxt}
            onChange={onChangeText('pass')}
            className="inputTxt"
            themeMode={themeMode}
          />
          <FormInput
            label=""
            placeholder="Confirm New Password"
            type="password"
            value={rePassTxt}
            onChange={onChangeText('repass')}
            className="inputTxt"
            themeMode={themeMode}
          />
        </div>
        <div className="btn_container">
          <button className="btn_discard" onClick={handleDiscard}>
            DISCARD
          </button>
          <button
            className="btn_save"
            onClick={handleSave}
            style={{
              background:
                themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
              color: themeMode == 'main' ? 'white' : 'black',
            }}
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  )
}

export default PersonInfo
