import { Map, Record } from 'immutable'
import { TActionsR } from '../../types'
import { TCartItem } from './types'

export type TR_Cart = {
  cart_listItems: Map<string, TCartItem> | undefined
  isBuyWithBuff: boolean
}
export const InitRCart: TR_Cart = {
  cart_listItems: undefined,
  isBuyWithBuff: false,
}

const State: Record.Factory<TR_Cart> = Record(InitRCart)

export type TActionsR_Cart = TActionsR<TR_Cart>

export const cart_reducer = function (state: Record<TR_Cart> = new State(), action: TActionsR_Cart): Record<TR_Cart> {
  return state.set(action.type, action.payload)
}
