import React from 'react'
import './styles.scss'
import { useSelector } from 'react-redux'
import { AppState } from '../../redusers/config'
import { Init } from '../../redusers/actions'
import { useGetThemeMode } from 'state/theme/hook'

const DomainEditHeader: React.FC = () => {
  const hashtagItem = useSelector<AppState, Init['hashtagItem']>(({ actions }) => actions.get('hashtagItem'))
  const themeMode = useGetThemeMode()

  return (
    <div
      className="domain-edit__header"
      style={{
        background: themeMode == 'main' ? '#000000cc' : 'white',
        color: themeMode == 'main' ? 'white' : 'black',
        borderTopColor: themeMode == 'main' ? '#025498' : 'transparent',
      }}
    >
      <div className="domain-edit__header-tit_second">
        <p>{hashtagItem?.id} Domain Edit</p>
      </div>
    </div>
  )
}

export default DomainEditHeader
