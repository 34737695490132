import React from 'react'
import cn from './cn.module.scss'
import { IAssetsTable } from '../../../types'
import GreenPercent from '../../GreenPercent'
import RedPercent from '../../RedPercent'

interface IPerformanceP {
  asset: IAssetsTable | undefined
  isBest: boolean
  themeMode?: string
}

const PerformanceContent: React.FC<IPerformanceP> = ({ asset, isBest, themeMode }) => {
  return (
    <div className={cn.performanceContent}>
      {asset !== undefined ? (
        <>
          <div className={cn.assetName}>
            <img className={cn.logo} src={asset.logo} alt="" />
            {asset.name}
          </div>
          <div className={cn.title}>
            <span>{isBest ? 'Best' : 'Worst'} Performance</span>
          </div>
          <div className={cn.profitAmount}>{`${asset.isProfit ? '+' : '-'} $${Math.abs(asset.profit).toFixed(6)}`}</div>
          {asset.isProfit ? (
            <GreenPercent percent={asset.profitPercent} />
          ) : (
            <RedPercent percent={asset.profitPercent} />
          )}
        </>
      ) : (
        <div className={cn.title}>
          <span>{isBest ? 'Best' : 'Worst'} Performance</span>
        </div>
      )}
    </div>
  )
}

export default PerformanceContent
