import React, { useCallback, useEffect, useRef, useState } from 'react'
import './styles.scss'
import { FiExternalLink } from 'react-icons/fi'
import { BsSearch } from 'react-icons/bs'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { useGetDomainList, useGetLogList, useGetTransactionList } from 'views/Search/hooks/availableCategoryList'
import { MODAL_TYPES, useGlobalModalContext } from 'components/Modals/GlobalModal'
import { useGetThemeMode } from 'state/theme/hook'
import CryptoIcon from '../../../assets/icons-svg/i_unstoppably.svg'
import EnsIcon from '../../../assets/icons-svg/i_ens.svg'
import FbIconD from '../../../assets/icons-svg/s_facebook_d.svg'
import InIconD from '../../../assets/icons-svg/s_instagram_d.svg'
import TwIconD from '../../../assets/icons-svg/s_twitter_d.svg'
import LnIconD from '../../../assets/icons-svg/s_linkedIn_d.svg'
import FbIconL from '../../../assets/icons-svg/s_facebook_l.svg'
import InIconL from '../../../assets/icons-svg/s_instagram_l.svg'
import TwIconL from '../../../assets/icons-svg/s_twitter_l.svg'
import LnIconL from '../../../assets/icons-svg/s_linkedIn_l.svg'
import FbIconC from '../../../assets/icons-svg/s_facebook_c.svg'
import InIconC from '../../../assets/icons-svg/s_instagram_c.svg'
import TwIconC from '../../../assets/icons-svg/s_twitter_c.svg'
import LnIconC from '../../../assets/icons-svg/s_linkedIn_c.svg'
import ExtraIcon from '../../../assets/icons-svg/extra.svg'

import { IGetAllDomainListR } from 'utils/api'
import {
  API_URL,
  CRYPTO_API_KEY,
  CRYPTO_BASE_API,
  ENS_API_KEY,
  ENS_BASE_API,
  FB_URL,
  IN_URL,
  LN_URL,
  TW_URL,
} from 'constants/urls'
import { showToast } from 'sagas/domain'
import { bookHashtag } from 'actions/hashtag'

const HeaderTopSearch: React.FC<any> = () => {
  const { domainList } = useGetDomainList()
  const { transactionList } = useGetTransactionList()
  const themeMode = useGetThemeMode()
  const dispatch = useDispatch()

  const yellow = 'linear-gradient(#fbb212, #f48f33)'
  const green = 'linear-gradient(#60fb12, #41ae0a)'
  const purple = 'linear-gradient(#9a12fb, #5812fb)'
  const sky = 'linear-gradient(#04b3f8, #0599d3)'

  const [searchTxt, setSearchTxt] = useState<string>('HashtagSpace')
  const [searchDomain, setSearchDomain] = useState<string>('')
  const [searchUrl, setSearchUrl] = useState<string>('')
  const [inputWidth, setInputWidth] = useState<string>('400px')

  const [symbol, setSymbol] = useState<string>('#')
  const [searchBtnTxt, setSearchBtnTxt] = useState<string>('EXPLORE')
  const [searchBtnBg, setSearchBtnBg] = useState<string>(purple)
  const [fbMouseOver, setFbMouseOver] = useState<boolean>(false)
  const [inMouseOver, setInMouseOver] = useState<boolean>(false)
  const [twMouseOver, setTwMouseOver] = useState<boolean>(false)
  const [lnMouseOver, setLnMouseOver] = useState<boolean>(false)
  const [hintDomains, setHintDomains] = useState<IGetAllDomainListR[]>([])
  const [showSocial, setShowSocial] = useState<boolean>(true)
  const [showHint, setShowHint] = useState<boolean>(false)
  inputWidth

  useEffect(() => {}, [])

  const changePrefix = (flag: string) => {
    if (flag === '#') {
      setSymbol('#')
    }
    if (flag === 'crypto') {
      setSymbol('crypto')
      setShowSocial(false)
      setShowHint(false)
    }
    if (flag === 'ens') {
      setSymbol('ens')
      setShowSocial(false)
      setShowHint(false)
    }
  }
  const showHintItems = (itemArr: any) => {
    const matchDomainList = itemArr.filter((v: any) => v.url && v.url !== '').slice(0, 3)
    if (matchDomainList.length > 0) {
      setShowSocial(false)
      setShowHint(true)
      setHintDomains(matchDomainList)
    } else {
      setShowSocial(true)
      setShowHint(false)
    }
  }

  const adjustSearchbox = (obj: any) => {
    if (obj == null) {
      setInputWidth('400px')
      return
    }

    if (obj.length === 0) {
      setInputWidth('400px')
    } else {
      let width
      if (obj.length === 1) {
        const name = obj[0].domainName
        const count = name.length * 3.5
        width = 400 + count
      } else if (obj.length === 2) {
        const name = obj[0].domainName + obj[1].domainName
        const count = name.length * 3.5
        width = 400 + count
      } else if (obj.length === 3) {
        const name = obj[0].domainName + obj[1].domainName + obj[2].domainName
        const count = name.length * 3.5
        width = 400 + count
      } else if (obj.length === 4) {
        const name = obj[0].domainName + obj[1].domainName + obj[2].domainName + obj[3].domainName
        const count = name.length * 3.5
        width = 400 + count
      }
      width = width + 'px'
      setInputWidth(width)
    }
  }

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value
    setSearchTxt(val)
    setShowSocial(true)
    setShowHint(false)
    setSearchDomain('')
    setSearchUrl('')

    changePrefix('#')

    let inputVal = val
    if (inputVal.substring(0, 1) !== '#') inputVal = '#' + inputVal

    inputVal = inputVal.trim()
    if (inputVal.length > 3) {
      if (inputVal.endsWith('.eth') || inputVal.endsWith('.crypto')) {
        setSearchBtnTxt('RESOLVE')
        setSearchBtnBg(sky)
        if (inputVal.endsWith('.eth')) changePrefix('ens')
        if (inputVal.endsWith('.crypto')) changePrefix('crypto')
      } else {
        // Get match domains based on inputValue
        const matchDomains = domainList.filter((v) => {
          const domainName = v.domainName.toLowerCase()
          return domainName.includes(inputVal.toLowerCase()) && domainName !== inputVal.toLowerCase()
        })

        // Change button text based on inputValue
        const fullMatchs = domainList.filter((v) => v.domainName.toLowerCase() === inputVal.toLowerCase())
        if (fullMatchs.length > 0) {
          const tUrl = fullMatchs[0].url
          setSearchBtnTxt('GO')
          setSearchBtnBg(yellow)
          setSearchDomain(fullMatchs[0].domainName)
          setSearchUrl(tUrl)
        } else {
          if (inputVal.includes(' ')) {
            adjustSearchbox(null)
            setSearchBtnTxt('EXPLORE')
            setSearchBtnBg(purple)
          } else {
            setSearchBtnTxt('BUY')
            setSearchBtnBg(green)
          }
        }

        // Show/Hide hint content
        if (matchDomains.length > 0) {
          const matchDomainList = matchDomains.filter((v) => v.url && v.url !== '').slice(0, 3)
          showHintItems(matchDomainList)

          adjustSearchbox(matchDomainList)
        } else {
          if (fullMatchs.length === 0) adjustSearchbox(null)
          setShowSocial(true)
          setShowHint(false)
        }
      }
    } else {
      adjustSearchbox(null)
      setSearchBtnTxt('EXPLORE')
      setSearchBtnBg(purple)
    }
  }
  const onMouseOverSocial = (flag: string) => {
    if (flag === 'fb') setFbMouseOver(true)
    if (flag === 'in') setInMouseOver(true)
    if (flag === 'tw') setTwMouseOver(true)
    if (flag === 'ln') setLnMouseOver(true)
  }
  const onMouseOutSocial = (flag: string) => {
    if (flag === 'fb') setFbMouseOver(false)
    if (flag === 'in') setInMouseOver(false)
    if (flag === 'tw') setTwMouseOver(false)
    if (flag === 'ln') setLnMouseOver(false)
  }
  const handleSocialIcon = (flag: string) => {
    if (searchTxt === '') {
      showToast('Please type #domain in search box', 'info')
      return
    }

    let inputVal = searchTxt
    if (inputVal.substring(0, 1) !== '#') inputVal = '#' + inputVal

    let social_url = ''
    if (flag === 'fb') social_url = FB_URL + 'hashtag/' + inputVal
    if (flag === 'in') social_url = IN_URL + 'explore/tags/' + inputVal
    if (flag === 'tw') social_url = TW_URL + 'search?q=%23' + inputVal + '&src=typed_query&f=top'
    if (flag === 'ln') social_url = LN_URL + 'feed/hashtag/' + inputVal

    window.open(social_url, '_blank')
  }

  const fetchCryptoDomain = async (crypto: string) => {
    let resAddress = ''
    let headers = new Headers()
    headers.append('Authorization', 'Bearer ' + CRYPTO_API_KEY)
    const requestOptions: any = {
      method: 'GET',
      headers: headers,
      redirect: 'follow',
    }
    const response = await fetch(CRYPTO_BASE_API + crypto, requestOptions)
    const jsonResult = await response.json()
    if (jsonResult) {
      const data = jsonResult.meta
      if (data !== null && data !== undefined) {
        resAddress = data.resolver
      }
    }
    return resAddress
  }
  const fetchEnsDomain = async (ens: string) => {
    let resAddress = ''
    let headers = new Headers()
    headers.append('X-API-Key', ENS_API_KEY)
    const requestOptions: any = {
      method: 'GET',
      headers: headers,
      redirect: 'follow',
    }
    const response = await fetch(ENS_BASE_API + ens, requestOptions)
    const jsonResult = await response.json()
    if (jsonResult) {
      const data = jsonResult.entity
      if (data !== null && data !== undefined) {
        resAddress = data.resolvedAddress
      }
    }
    return resAddress
  }
  const addLogData = async (item: string) => {
    let res = false
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const raw = JSON.stringify({
      id: 1,
      method: 'hashtag.addSearchLogFromPlugin',
      jsonrpc: '2.0',
      params: { domain: item },
    })
    const requestOptions: any = {
      method: 'POST',
      headers: headers,
      body: raw,
      redirect: 'follow',
    }
    const response = await fetch(API_URL, requestOptions)
    const jsonResult = await response.json()
    if (jsonResult) res = jsonResult.result
    return res
  }
  const addENSData = async (item: string) => {
    let res = false
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const raw = JSON.stringify({
      id: 1,
      method: 'hashtag.addENSLogFromPlugin',
      jsonrpc: '2.0',
      params: { domain: item },
    })
    const requestOptions: any = {
      method: 'POST',
      headers: headers,
      body: raw,
      redirect: 'follow',
    }
    const response = await fetch(API_URL, requestOptions)
    const jsonResult = await response.json()
    if (jsonResult) res = jsonResult.result
    return res
  }

  const handleSearch = async () => {
    let val = searchTxt
    if (val.substring(0, 1) !== '#') val = '#' + val

    if (searchBtnTxt === 'GO') {
      const urlVal = searchUrl
      const domainVal = searchDomain
      if (urlVal && urlVal !== '') {
        await addLogData(val)
        // AudioItem('tab-launch').play()
        const urlArr = urlVal.split(',')
        window.open(urlArr[0], '_blank')
      } else {
        showToast('No target URL', 'info')
      }
    } else if (searchBtnTxt === 'BUY') {
      dispatch(bookHashtag(val))
    } else if (searchBtnTxt === 'EXPLORE') {
      if (val.includes(' ')) showToast('Not allowed', 'info')
      else showToast('Please input over 3 characters', 'info')
    } else if (searchBtnTxt === 'RESOLVE') {
      let resA
      if (searchTxt.endsWith('.eth')) resA = await fetchEnsDomain(searchTxt)
      else if (searchTxt.endsWith('.crypto')) resA = await fetchCryptoDomain(searchTxt)

      if (resA !== null && resA !== undefined && resA !== '') {
        const etherscan = `https://etherscan.io/address/${resA}`
        await addENSData(searchTxt)
        window.open(etherscan, '_blank')
      }
    }
  }

  const onClickSearch = () => {
    if (searchTxt === 'HashtagSpace') setSearchTxt('')
  }
  const onKeyUpSearch = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return

    let val = searchTxt
    if (val.substring(0, 1) !== '#') val = '#' + val

    if (searchBtnTxt === 'GO') {
      const urlVal = searchUrl
      const domainVal = searchDomain
      if (urlVal && urlVal !== '') {
        await addLogData(val)
        // AudioItem('tab-launch').play()
        const urlArr = urlVal.split(',')
        window.open(urlArr[0], '_blank')
      } else {
        showToast('No target URL', 'info')
      }
    } else if (searchBtnTxt === 'BUY') {
      dispatch(bookHashtag(val))
    } else if (searchBtnTxt === 'EXPLORE') {
      if (val.includes(' ')) showToast('Not allowed', 'info')
      else showToast('Please input over 3 characters', 'info')
    } else if (searchBtnTxt === 'RESOLVE') {
      let resA
      if (searchTxt.endsWith('.eth')) resA = await fetchEnsDomain(searchTxt)
      else if (searchTxt.endsWith('.crypto')) resA = await fetchCryptoDomain(searchTxt)

      if (resA !== null && resA !== undefined && resA !== '') {
        const etherscan = `https://etherscan.io/address/${resA}`
        await addENSData(searchTxt)
        window.open(etherscan, '_blank')
      }
    }
  }

  const handleHintItem = (domain: string, url: string) => {
    let val = domain
    if (val.substring(0, 1) === '#') val = val.substring(1)
    setSearchBtnTxt('GO')
    setSearchBtnBg(yellow)
    setSearchTxt(val)
    setSearchDomain(domain)
    setSearchUrl(url)
  }

  const handleExtrItem = async (domain: string, url: string) => {
    await addLogData(domain)
    // AudioItem('tab-launch').play()
    const urlArr = url.split(',')
    window.open(urlArr[0], '_blank')
  }

  return (
    <div className="searchDiv">
      <span
        className="symbolsharp"
        style={{
          color: themeMode === 'main' ? 'white' : 'black',
        }}
      >
        {symbol === '#' && '#'}
        {symbol === 'crypto' && <img src={CryptoIcon} width="14" alt="" />}
        {symbol === 'ens' && <img src={EnsIcon} width="14" alt="" />}
      </span>

      {showSocial && (
        <div className="socialContainer">
          <img
            src={fbMouseOver ? FbIconC : themeMode === 'main' ? FbIconL : FbIconD}
            width="15"
            className="socialIcon"
            alt=""
            onMouseOver={() => onMouseOverSocial('fb')}
            onMouseOut={() => onMouseOutSocial('fb')}
            onClick={() => handleSocialIcon('fb')}
          />
          <img
            src={inMouseOver ? InIconC : themeMode === 'main' ? InIconL : InIconD}
            width="15"
            className="socialIcon"
            alt=""
            onMouseOver={() => onMouseOverSocial('in')}
            onMouseOut={() => onMouseOutSocial('in')}
            onClick={() => handleSocialIcon('in')}
          />
          <img
            src={twMouseOver ? TwIconC : themeMode === 'main' ? TwIconL : TwIconD}
            width="15"
            className="socialIcon"
            alt=""
            onMouseOver={() => onMouseOverSocial('tw')}
            onMouseOut={() => onMouseOutSocial('tw')}
            onClick={() => handleSocialIcon('tw')}
          />
          <img
            src={lnMouseOver ? LnIconC : themeMode === 'main' ? LnIconL : LnIconD}
            width="15"
            className="socialIcon"
            alt=""
            onMouseOver={() => onMouseOverSocial('ln')}
            onMouseOut={() => onMouseOutSocial('ln')}
            onClick={() => handleSocialIcon('ln')}
          />
        </div>
      )}

      {showHint && (
        <div className="hintItemContent">
          {hintDomains.map((v, inx) => {
            return (
              <div className="hintItem" key={inx}>
                <span onClick={() => handleHintItem(v.domainName, v.url)}>{v.domainName}</span>
                <img src={ExtraIcon} width={15} alt="" onClick={() => handleExtrItem(v.domainName, v.url)} />
              </div>
            )
          })}
        </div>
      )}

      {/* <div className="extrasContainer" id="extrasContainer"></div> */}

      <input
        type="text"
        className="searchInput"
        onChange={onChangeSearch}
        value={searchTxt}
        onKeyUp={onKeyUpSearch}
        onClick={onClickSearch}
        style={{
          background: themeMode == 'main' ? '#222222' : '#f5f5f5',
          color: themeMode === 'main' ? 'white' : 'black',
          width: `${inputWidth}`,
        }}
      />
      <button
        type="button"
        onClick={handleSearch}
        className="searchBtn"
        style={{
          background: searchBtnBg,
          color: themeMode === 'main' ? 'white' : 'black',
        }}
      >
        <div className="explorer">
          <span className="exTxt">{searchBtnTxt}</span>
          <BsSearch size={10} />
        </div>
      </button>
    </div>
  )
}
export default HeaderTopSearch
