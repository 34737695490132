import { Record, Map } from 'immutable'
import { TActionsR } from '../../types'
import { TRInfoByNick } from '../../utils/api'

export type TR_ViewProfile = {
  infoByNick: null | TRInfoByNick
}
export const InitRCart: TR_ViewProfile = {
  infoByNick: null,
}

const State: Record.Factory<TR_ViewProfile> = Record(InitRCart)

export type TActionsR_ViewProfile = TActionsR<TR_ViewProfile>

export const viewProfile_reducer = function (
  state: Record<TR_ViewProfile> = new State(),
  action: TActionsR_ViewProfile
): Record<TR_ViewProfile> {
  return state.set(action.type, action.payload)
}
