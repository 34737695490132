import { Record } from 'immutable'
import { TActionsR } from '../../../types'

export type TRInitMetaTags = {
  metaTags_isLoad: boolean
  metaTags_code: string
}
export const rInitRMenu: TRInitMetaTags = {
  metaTags_isLoad: false,
  metaTags_code: '',
}

const State: Record.Factory<TRInitMetaTags> = Record(rInitRMenu)

export type TActionsMetaTagsR = TActionsR<TRInitMetaTags>

export const metaTags_reducer = function (
  state: Record<TRInitMetaTags> = new State(),
  action: TActionsMetaTagsR
): Record<TRInitMetaTags> {
  return state.set(action.type, action.payload)
}
