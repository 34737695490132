import React from 'react'
import MainContent from '../../components/MainContent'

function NoAccess() {
  return (
    <MainContent>
      <h1>No access</h1>
    </MainContent>
  )
}

export default NoAccess
