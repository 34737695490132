import React, { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { IoIosClose } from 'react-icons/io'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { useGetThemeMode } from 'state/theme/hook'
import { withdrawHashtagReq } from '../../../actions/transaction'
import MHCSymbolImg from '../../../assets/icons/symbol-mhc.svg'
import PayPalSymbolImg from '../../../assets/icons/symbol-paypal.svg'
import { AppState } from '../../../redusers/config'
import { WithdrawReqP } from '../../../utils/api'
import FormInput from '../../FormInput'
import './styles.scss'

const modalRoot: Element = document.getElementById('root-modal') as Element

interface ModalP {
  onClose: (e: React.FormEvent<HTMLElement>) => void
  withdrawHashtagReq: (val: WithdrawReqP) => void
  creditVal: string
  selectOption: WithdrawReqP['type']
  transactionFeePercent: string
  isLoadSendWithdraw: boolean
  userInfo: any
}

const onWithdraw =
  (
    withdrawHashtagReq: ModalP['withdrawHashtagReq'],
    amount: number,
    fee: number,
    type: WithdrawReqP['type'],
    withdrawAddress: string,
    onClose: (e: React.FormEvent<HTMLElement>) => void,
    setDisabled: (disabled: boolean) => void
  ) =>
  (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (amount === null) {
      return
    }

    if (isNaN(amount)) {
      return
    }
    // AudioItem('auto-paypal').play()
    setDisabled(true)
    withdrawHashtagReq({ amount, fee, type, withdrawAddress })

    onClose(e)
  }

const onChange = (setVal: (v: string) => void) => (e: React.FormEvent<HTMLInputElement>) => {
  const val = e.currentTarget.value

  if (val === '') {
    return setVal(val)
  }

  setVal(val)
}

const Modal: React.FC<ModalP> = ({
  withdrawHashtagReq,
  onClose,
  creditVal,
  selectOption,
  transactionFeePercent,
  isLoadSendWithdraw,
  userInfo,
}) => {
  const transactionFeeAmount = (Number(creditVal.slice(1)) * Number(transactionFeePercent)) / 100
  const withdrawAmount = Number(creditVal.slice(1))

  const [val, setVal] = useState<string>(selectOption === 'paypal' ? userInfo.paypal_address : userInfo.mhc_address)
  const [disabled, setDisabled] = useState<boolean>(true)

  const isValidateAddress = useMemo(() => {
    if (isLoadSendWithdraw === true) {
      setDisabled(true)
      return false
    }
    if (val === '') {
      setDisabled(true)
      return false
    }
    const regexAddress =
      selectOption === 'metahash'
        ? /^(0x)?[0-9a-fA-F]{50}$/
        : /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (val.match(regexAddress)) {
      setDisabled(false)
      return true
    } else {
      setDisabled(true)
      return false
    }
  }, [isLoadSendWithdraw, selectOption, val])

  const themeMode = useGetThemeMode()

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-content-header">
          <div className="header-content">
            <p>Withdrawal Confirmation</p>
            <div className="modal-close" onClick={onClose}>
              <IoIosClose size={24} />
            </div>
          </div>
          <div className="header-description">
            <span>You are trying to withdraw the amount of </span>
            <span className="credit-amount">{withdrawAmount.toFixed(2)}</span>
            <span> from your Hashtag Wallet Credit.</span>
          </div>
        </div>
        <div className="transactionView">
          <div className="withdrawCredit">
            <span>
              {selectOption === 'paypal' ? (
                <img src={PayPalSymbolImg} className="paypalSymbolImg" alt="" />
              ) : (
                <div>
                  <img src={MHCSymbolImg} alt="" />
                  <span className="mhcTtext">MHC</span>
                </div>
              )}
            </span>
            <span>${withdrawAmount.toFixed(2)}</span>
          </div>
          <div className="transactionFee">
            <span>Transaction fee</span>
            <span>{`- $${transactionFeeAmount.toFixed(2)}`}</span>
          </div>
        </div>

        <div className="divide-line"></div>

        <div className="total-withdraw-amount">
          <span>Total Withdrawing Amount</span>
          <span className="totalAmount">#{(withdrawAmount + transactionFeeAmount).toFixed(2)}</span>
        </div>

        <div className="paypalAddressInput">
          <FormInput
            label={'Withdraw Address ( Change address to withdraw to a different address )'}
            type={'text'}
            value={val}
            onChange={onChange(setVal)}
            error={!isValidateAddress}
          />
        </div>

        <span className="transaction-description">
          By hitting below button, you accept the conversion & fees of this transaction
        </span>

        <div className="withdrawBtn">
          <button
            className="btn"
            onClick={onWithdraw(
              withdrawHashtagReq,
              withdrawAmount,
              Number(transactionFeeAmount.toFixed(2)),
              selectOption,
              val,
              onClose,
              setDisabled
            )}
            disabled={disabled}
            style={{
              background:
                themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
              color: themeMode == 'main' ? 'white' : 'black',
            }}
          >
            Convert and Withdraw
          </button>
        </div>
      </div>
    </div>
  )
}

const timeout = { enter: 500, exit: 100 }

interface WithdrawCreditModalP {
  showModal: boolean
  creditVal: string
  selectOption: WithdrawReqP['type']
  transactionFeePercent: string
  isLoadSendWithdraw: boolean
  onClose: (e: React.FormEvent<HTMLElement>) => void
  withdrawHashtagReq: (val: WithdrawReqP) => void
  userInfo: any
}
const WithdrawCreditModal: React.FC<WithdrawCreditModalP> = ({
  showModal,
  creditVal,
  selectOption,
  transactionFeePercent,
  isLoadSendWithdraw,
  onClose,
  withdrawHashtagReq,
  userInfo,
}) => {
  return createPortal(
    <CSSTransition classNames="animated" in={showModal} unmountOnExit timeout={timeout}>
      <Modal
        creditVal={creditVal}
        selectOption={selectOption}
        transactionFeePercent={transactionFeePercent}
        isLoadSendWithdraw={isLoadSendWithdraw}
        onClose={onClose}
        withdrawHashtagReq={withdrawHashtagReq}
        userInfo={userInfo}
      />
    </CSSTransition>,
    modalRoot
  )
}

function mapStateToProps({ transaction, user }: AppState) {
  return {
    isLoadSendWithdraw: transaction.get('isLoadSendWithdraw'),
    price: transaction.get('price'),
    userInfo: user.get('userInfo'),
  }
}

export default connect(mapStateToProps, { withdrawHashtagReq })(WithdrawCreditModal)
