import React, { useEffect, useState } from 'react'
import cn from './cn.module.scss'
import { useDispatch } from 'react-redux'
import { useGetTokenMarketChartData } from '../../../hooks'
import { IAssetsTable } from '../../../types'
import { createAssetsChartList } from '../../../actions'
import TokenLineChart from './Chart'
import { useGetThemeMode } from 'state/theme/hook'

interface ITokenLineChartP {
  asset: IAssetsTable
  timeOption: {
    from: number
    to: number
  }
}
const TokenBalance: React.FC<ITokenLineChartP> = ({ asset, timeOption }) => {
  const dispatch = useDispatch()
  const tokenMarketChart = useGetTokenMarketChartData(asset, timeOption.from, timeOption.to)

  useEffect(() => {
    if (tokenMarketChart && tokenMarketChart.tokenBalanceRange.length > 0) {
      const tokenMarketChartTemp = { ...tokenMarketChart }
      dispatch(createAssetsChartList({ ...tokenMarketChartTemp }))
    }
  }, [dispatch, tokenMarketChart])

  return <></>
}

interface ILineP {
  assetsList: IAssetsTable[]
}

const LineChart: React.FC<ILineP> = ({ assetsList }) => {
  const [timeOption, setTimeOption] = useState<number>(1)
  const [timeRange, setTimeRange] = useState<{ from: number; to: number }>({
    from: 0,
    to: 0,
  })

  useEffect(() => {
    const currentDate = new Date().getTime()
    setTimeRange({
      from: currentDate - 24 * timeOption * 60 * 60 * 1000,
      to: currentDate,
    })

    return () => {
      setTimeRange({ from: 0, to: 0 })
    }
  }, [timeOption])

  const themeMode = useGetThemeMode()

  return (
    <>
      {assetsList.map((asset, index) => {
        return <TokenBalance key={index} asset={asset} timeOption={timeRange} />
      })}
      <TokenLineChart />
      <div className={cn.timeOptionTab} style={{ color: themeMode === 'light' ? 'black' : 'white' }}>
        <div className={`${cn.timeOption}  ${timeOption === 1 && cn.active}`} onClick={() => setTimeOption(1)}>
          24h
        </div>
        <div className={`${cn.timeOption}  ${timeOption === 7 && cn.active}`} onClick={() => setTimeOption(7)}>
          7d
        </div>
        <div className={`${cn.timeOption}  ${timeOption === 30 && cn.active}`} onClick={() => setTimeOption(30)}>
          30d
        </div>
        <div className={`${cn.timeOption}  ${timeOption === 90 && cn.active}`} onClick={() => setTimeOption(90)}>
          90d
        </div>
        {/* <div className={`${cn.timeOption}  ${timeOption === 0 && cn.active}`} onClick={() => setTimeOption(0)}>
          All
        </div> */}
      </div>
    </>
  )
}

export default LineChart
