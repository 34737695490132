import React, { FC, useCallback, useEffect, useMemo } from 'react'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redusers/config'
import { InfoItemID, TListInfoItem } from '../../utils/api'
import { onCloseTexTModal, onGetListInfo, onOpenTexTModal } from '../../actions/info'
import UnifiedModal1 from '../Modals/UnifiedModal1'
import ReactMarkdown from 'react-markdown'
import { List } from 'immutable'
import { useGetThemeMode } from 'state/theme/hook'
import { Init } from '../../redusers/user'
import FooterBottom from './FooterBottom'

const Footer: FC = () => {
  const dispatch = useDispatch()
  const isLogIn = useSelector<AppState, Init['isLogin']>(({ user }) => user.get('isLogin'))

  const listInfo = useSelector<AppState, List<TListInfoItem>>(({ info }) => info.get('listInfo'))

  const itemInfoForModal = useSelector<AppState, InfoItemID>(({ info }) => info.get('itemInfoForModal'))
  const isOpenTextModal = useSelector<AppState, boolean>(({ info }) => info.get('isOpenTextModal'))

  useEffect(() => {
    dispatch(onGetListInfo())
  }, [dispatch])

  const memoClick = useCallback(
    (id: number) => () => {
      dispatch(onOpenTexTModal(id))
    },
    [dispatch]
  )
  const memoModalClose = useCallback(() => {
    dispatch(onCloseTexTModal())
  }, [dispatch])

  const themeMode = useGetThemeMode()

  const source = useMemo(() => itemInfoForModal.description.replace(/\n/gi, '\n\n &nbsp;'), [itemInfoForModal])

  return (
    <div className="footer_container">
      <footer className={themeMode == 'main' ? 'footer' : 'footer-d'}>
        {/* // TODO
      style={{ bottom: isLogIn ? '62px' : '0px' }} */}
        <ul>
          {listInfo.map((item) => (
            <li key={item.id}>
              <span onClick={memoClick(item.id)}>{item.title}</span>
            </li>
          ))}
        </ul>
        <UnifiedModal1
          title={itemInfoForModal.title}
          showModal={isOpenTextModal}
          onClose={memoModalClose}
          onSave={memoModalClose}
          btnCloseName={'Close'}
          noOk
        >
          <ReactMarkdown source={source} />
        </UnifiedModal1>
      </footer>

      {/* // TODO later
      {isLogIn && <FooterBottom />} */}
    </div>
  )
}
export default Footer
