import { Record } from 'immutable'
import { TActionsR } from 'types'
import { TGetMarketSpaceList, TR_MarketSpace } from 'views/MarketSpace/types'

export const rInitRMarketSpace: TR_MarketSpace = {
  marketSpaceList: [],
}

const State: Record.Factory<TR_MarketSpace> = Record(rInitRMarketSpace)

export type TActionsR_Search = TActionsR<TR_MarketSpace> | TGetMarketSpaceList

export const marketSpace_reducer = function (
  state: Record<TR_MarketSpace> = new State(),
  action: TActionsR_Search
): Record<TR_MarketSpace> {
  if (action.type === 'MARKETSPACE_LIST') {
    return state.set('marketSpaceList', action.payload)
  }

  return state.set(action.type, action.payload)
}
