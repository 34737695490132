import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchedData, genFetchedData } from '../../utils/api/fetchedData'
import type { TRGetList } from './api'

export interface SendEmail {
  addEmails: FetchedData<boolean>
  emailList: FetchedData<TRGetList>
  removeEmail: FetchedData<boolean>
  sendEmails: FetchedData<boolean>
}

export const initialState: SendEmail = {
  addEmails: genFetchedData<boolean>(null),
  emailList: genFetchedData<TRGetList>(null),
  removeEmail: genFetchedData<boolean>(null),
  sendEmails: genFetchedData<boolean>(null),
}

const sendEmailSlice = createSlice({
  name: 'SendEmail',
  initialState,
  reducers: {
    setAddEmails(state, action: PayloadAction<SendEmail['addEmails']>) {
      state.addEmails = action.payload
    },
    delQuantityReport(state) {
      state.addEmails = initialState.addEmails
    },
    setEmailList(state, action: PayloadAction<SendEmail['emailList']>) {
      state.emailList = action.payload
    },
    delEmailList(state) {
      state.emailList = initialState.emailList
    },
    setRemoveEmail(state, action: PayloadAction<SendEmail['removeEmail']>) {
      state.removeEmail = action.payload
    },
    delRemoveEmail(state) {
      state.removeEmail = initialState.removeEmail
    },
    setSendEmails(state, action: PayloadAction<SendEmail['sendEmails']>) {
      state.sendEmails = action.payload
    },
    delSendEmails(state) {
      state.sendEmails = initialState.sendEmails
    },
  },
})

export const {
  setAddEmails,
  delQuantityReport,
  setEmailList,
  delEmailList,
  setRemoveEmail,
  delRemoveEmail,
  setSendEmails,
  delSendEmails,
} = sendEmailSlice.actions
export const sendEmailReducer = sendEmailSlice.reducer
