import PlanetIcon from 'components/PlanetIcon'
import { useSendingGift } from 'hooks/useModal'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useGlobalModalContext } from '../GlobalModal'

const ModalContainer = styled.div`
  z-index: 99;
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
  box-size: border-box;
`
const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  margin: 24px 0;
  word-break: break-word;
`
interface IImgModal {
  title: string
  onClose: Function
  themeMode?: string
}

const ImgModal: React.FC<IImgModal> = ({ children, title }) => {
  return (
    <ModalContainer>
      <ModalBody>{children}</ModalBody>
    </ModalContainer>
  )
}

const PlanetImgModal: React.FC = () => {
  const { giftId } = useSendingGift()
  const { hideModal } = useGlobalModalContext()
  return (
    <>
      {giftId && (
        <ImgModal title={''} onClose={hideModal}>
          <PlanetIcon seed={giftId} size={250} />
        </ImgModal>
      )}
    </>
  )
}

export default PlanetImgModal
