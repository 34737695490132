import { onActivateUser } from 'actions/user'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import Login from 'views/Login/SignIn'
import MainContent from '../../components/MainContent'

const NotFound = () => {
  const dispatch = useDispatch()
  const isActiveUser = useSelector<AppState, Init['isActiveUser']>(({ user }) => user.get('isActiveUser'))

  useEffect(() => {
    const curUrl = window.location.href
    const item = 'u/?action=confirmation&code='
    if (curUrl.includes(item)) {
      const code = curUrl.split(item)[1]
      dispatch(onActivateUser({ code }))
    }
  }, [dispatch])

  return <MainContent>{isActiveUser ? <Login /> : <h1>Page not found</h1>}</MainContent>
}

export default NotFound
