import {
  TonDashboardGetBridgeEarning,
  TonDashboardGetDomainAnalytics,
  TonDashboardGetMonthlyRevenue,
  TonDashboardGetPurchases,
} from './types'

export function onDashboardGetPurchases(payloads: TonDashboardGetPurchases['payloads']): TonDashboardGetPurchases {
  return {
    type: 'ON_DASHBOARD_GET_PURCHASES',
    payloads,
  }
}
export function onDashboardGetMonthlyRevenue(
  payloads: TonDashboardGetMonthlyRevenue['payloads']
): TonDashboardGetMonthlyRevenue {
  return {
    type: 'ON_DASHBOARD_GET_MONTHLY_REVENUE',
    payloads,
  }
}
export function onDashboardGetDomainAnalytics(
  payloads: TonDashboardGetDomainAnalytics['payloads']
): TonDashboardGetDomainAnalytics {
  return {
    type: 'ON_DASHBOARD_GET_DOMAIN_ANALYTICS',
    payloads,
  }
}
export function onDashboardGetBridgeEarning(
  payloads: TonDashboardGetBridgeEarning['payloads']
): TonDashboardGetBridgeEarning {
  return {
    type: 'ON_DASHBOARD_GET_BRIDGE_EARNING',
    payloads,
  }
}
