import { TChangeReferrerStatus } from '../../../utils/api'
import { TActionsAccounts } from './redusers'

export type TonAccountGetList = {
  type: 'ON_ACCOUNT_GET_LIST'
}

export function onAccountGetList(): TonAccountGetList {
  return {
    type: 'ON_ACCOUNT_GET_LIST',
  }
}

export type TonAccountGetItemDomains = {
  type: 'ON_ACCOUNT_GET_ITEM_DOMAINS'
  payloads: string
}

export function onAccountGetItemDomains(payloads: TonAccountGetItemDomains['payloads']): TonAccountGetItemDomains {
  return {
    type: 'ON_ACCOUNT_GET_ITEM_DOMAINS',
    payloads,
  }
}

export function onAccountClearItemDomains(): TActionsAccounts {
  return {
    type: 'accountItemDomains',
    payload: [],
  }
}

export type TFetchReferralStatus = {
  type: 'accounts/fetchReferralStatus'
  payload: TChangeReferrerStatus
}

export function fetchReferralStatus(payload: TFetchReferralStatus['payload']): TFetchReferralStatus {
  return {
    type: 'accounts/fetchReferralStatus',
    payload,
  }
}
