import React from 'react'
import './styles.scss'

interface IAnimationText {
  text: string
  fontSize?: number
  isDreaming?: boolean
  themeMode?: string
}
const AnimationText: React.FC<IAnimationText> = ({ text, fontSize, isDreaming, themeMode }) => {
  return (
    <div className="sparkAnimationContainer">
      {isDreaming ? (
        <span className={themeMode == 'main' ? 'glow_text_big' : 'glow_text_big_l'}>{text}</span>
      ) : (
        <span className={themeMode == 'main' ? 'glow_text' : 'glow_text_l'}>{text}</span>
      )}
    </div>
  )
}

export default AnimationText
