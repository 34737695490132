import React from 'react'
import { BsArrowRightShort } from 'react-icons/bs'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import { useGetInstructionList } from '../../hooks/availableCategoryList'
import { Instruction } from './data'
import InstructionDetail from './instructionDetail'

const Container = styled.div`
  padding: 0 15px;
`
const InstructionContent = styled.div`
  display: grid;
  flex-wrap: wrap;
  margin-top: 24px;
  grid-gap: 15px;
  grid-template-columns: auto 33% auto;
  margin: 24px 0px;
  @media screen and (max-width: 1024px) and (min-width: 640px) {
    grid-template-columns: auto auto;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: auto;
    margin: 15px 0;
  }
  @media screen and (max-width: 450px) {
    margin: 20px 0;
  }
`
const InstructionDetailContainer = styled.div<{
  width: string
  height: string
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: 0 24px;
  margin-bottom: 12px;
  line-height: 24px;
  font-size: 14px;
  position: relative;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
    min-height: 240px;
    padding: 0 10px;
  }
`
const InstructionPointContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: 12px;
  top: -20px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  color: #000000;
`
const InstructionPTSNumber = styled.span`
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 6px;
`
const InstructionPTSText = styled.span`
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  bottom: -2px;
`
const InstructionMoreInfo = styled.div`
  position: absolute;
  bottom: 4px;
  right: 8px;
  display: flex;
  color: #ff8801;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`

const InstructionBody: React.FC = () => {
  let instructionList = useGetInstructionList()
  instructionList = instructionList.slice(0, 9)
  const themeMode = useGetThemeMode()

  return (
    <Container>
      <InstructionContent>
        {instructionList.map((item, index) => {
          return (
            <InstructionDetailContainer
              key={index}
              width={'100%'}
              height={'180px'}
              style={{
                background: themeMode == 'main' ? '#00000090' : '#ffffff90',
              }}
            >
              <InstructionDetail item={item} type="detail" value={item.detail} themeMode={themeMode} />
              <InstructionPointContainer
                style={{
                  background:
                    themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                  color: themeMode == 'main' ? 'white' : 'black',
                }}
              >
                <InstructionPTSNumber>{item.point}</InstructionPTSNumber>
                <InstructionPTSText>PTS</InstructionPTSText>
              </InstructionPointContainer>
              <InstructionMoreInfo>
                more info <BsArrowRightShort size={24} />
              </InstructionMoreInfo>
            </InstructionDetailContainer>
          )
        })}
      </InstructionContent>
    </Container>
  )
}

export default InstructionBody
