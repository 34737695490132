import { GradientButton } from 'components/Buttons/GradientButton'
import { MODAL_TYPES, useGlobalModalContext } from 'components/Modals/GlobalModal'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { onChangeModalBuyDomen } from '../../actions/hashtag'
import { AppState } from '../../redusers/config'
import { Init as HT_Init } from 'redusers/actions'
import { Init as TR_Modals } from '../../redusers/modals'
import { Init as TR_Transaction } from '../../redusers/transaction'
import { Init as TR_User } from '../../redusers/user'
import { addItemCart, delItemCart } from './actions'
import { TR_Cart } from './reduser'
import { useGetThemeMode } from 'state/theme/hook'

export function useCart() {
  const balance = useSelector<AppState, TR_Transaction['balance']>(({ transaction }) => transaction.get('balance'))
  const isAdmin = useSelector<AppState, TR_User['isAdmin']>(({ user }) => user.get('isAdmin'))
  const showModalBuyDomen = useSelector<AppState, TR_Modals['showModalBuyDomen']>(({ modals }) =>
    modals.get('showModalBuyDomen')
  )
  const history = useHistory()
  const dispatch = useDispatch()
  const themeMode = useGetThemeMode()
  const { showModal } = useGlobalModalContext()
  const { total } = useGetTotalCartAmount()

  const createModal = () => {
    showModal(MODAL_TYPES.CART_MODAL)
  }

  const memoonChangeModalBuyDomen = useCallback(
    (v: boolean) => () => {
      dispatch(onChangeModalBuyDomen(v))
    },
    []
  )

  const memoDelItem = useCallback(
    (id: string) => () => {
      dispatch(delItemCart(id))
    },
    [dispatch]
  )

  const onNavToBridge = () => {
    history.push('/pay/')
  }

  const btn = useMemo(() => {
    type Tkeys = 'none' | 'buy' | 'fund'
    const mapBtn: Map<Tkeys, JSX.Element | null> = new Map([
      ['none', null],
      [
        'buy',
        <div className="myCartBtnContainer" key="buy">
          <GradientButton
            type="button"
            width={'100%'}
            onClick={createModal}
            backgroundColor={
              themeMode === 'main' ? 'linear-gradient(#872f9e, #ac09dd, #872f9e)' : 'linear-gradient(#b7eaf6, #777fc4)'
            }
            borderRadius={'5px'}
            // margin={'10px'}
            color={themeMode === 'main' ? 'white' : 'black'}
            hoverColor={
              themeMode === 'main' ? 'linear-gradient(#662178, #ac09dd, #662178)' : 'linear-gradient(#777fc4, #b7eaf6)'
            }
          >
            <span className="fund-btn">#Buy It</span>
          </GradientButton>
        </div>,
      ],
      [
        'fund',
        <div className="myCartBtnContainer" key="buy">
          <GradientButton
            type="button"
            width={'100%'}
            onClick={onNavToBridge}
            backgroundColor={
              themeMode === 'main' ? 'linear-gradient(#872f9e, #ac09dd, #872f9e)' : 'linear-gradient(#b7eaf6, #777fc4)'
            }
            borderRadius={'5px'}
            color={themeMode === 'main' ? 'white' : 'black'}
            hoverColor={
              themeMode === 'main' ? 'linear-gradient(#662178, #ac09dd, #662178)' : 'linear-gradient(#777fc4, #b7eaf6)'
            }
          >
            <span className="fund-btn">Fund your account</span>
          </GradientButton>
        </div>,
      ],
    ])

    // if (total === 0 && cartList?.filter((item) => item.isDemo)) {
    //   return mapBtn.get('none')
    // }
    if (isAdmin === 'YES') {
      return mapBtn.get('buy')
    }
    if (balance.userPaypal < total) {
      return mapBtn.get('fund')
    }
    return mapBtn.get('buy')
  }, [createModal, themeMode, onNavToBridge, isAdmin, balance, total])

  return {
    memoDelItem,
    btn,
    showModalBuyDomen,
    memoonChangeModalBuyDomen,
  }
}

export const useGetTotalCartAmount = () => {
  const listItems = useSelector<AppState, TR_Cart['cart_listItems']>(({ cart_reducer }) =>
    cart_reducer.get('cart_listItems')
  )

  return useMemo(() => {
    const c = listItems
      ? listItems.reduce<number>((r, v) => {
          let price = v.isDemo ? 0 : v.price
          if (typeof v.discount === 'number') {
            price = price - (price / 100) * v.discount
          }
          return r + price
        }, 0)
      : 0
    return { total: c }
  }, [listItems])
}
