import FormInput from 'components/FormInput'
import { DREAMING_URL } from 'constants/urls'
import { useVideoCallJWT } from 'hooks/useModal'
import React, { useEffect } from 'react'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import TwoBtnModal from '../TwoBtnModal'

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 14px;
    margin-top: 12px;
    color: #caced2;
  }
  input {
    height: 40px;
    box-shadow: none;
    outline: none;
    border: 1px solid #969999;
    padding: 0 20px;
    font-size: 20px;
    border-radius: 5px;
  }
  label {
    font-size: 16px;
    margin-bottom: 10px;
  }
`

const VideoConfirmModal: React.FC = () => {
  const { onCloseVideoConfirm, onVideoCallback, modalProps } = useVideoCallJWT()
  let urlParam = modalProps.id
  if (urlParam.indexOf('#') > -1) {
    urlParam = urlParam.substring(1)
  }
  const videoUrl = DREAMING_URL + urlParam
  const themeMode = useGetThemeMode()
  return (
    <TwoBtnModal title={''} onClose={onCloseVideoConfirm} confirm={onVideoCallback} themeMode={themeMode}>
      <ModalBody>
        <label>Press OK to open your Dreaming URL to invite fellow Dreamers.</label>
        <FormInput type="url" value={videoUrl} themeMode={themeMode} />
      </ModalBody>
    </TwoBtnModal>
  )
}

export default VideoConfirmModal
