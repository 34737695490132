import React, { useCallback, useEffect, useState } from 'react'

export function UseDomainCommand() {
  const generateColor = useCallback((nickname: string) => {
    let result = ''
    const characters = 'abcdef0123456789'
    const charactersLength = characters.length
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength)) // #cluster2th
      // result += characters.charAt(Math.floor(charactersLength))
    }
    return '#' + result
  }, [])

  const generateUserTxt = useCallback((name: string) => {
    let result = name.substring(1, 3)
    result = result.toUpperCase()
    return result
  }, [])

  const splitText = useCallback((str: string, len: number) => {
    let result = str
    if (str.length > len) {
      result = str.substring(0, len) + ' . . .'
    }
    return result
  }, [])

  return {
    generateColor,
    generateUserTxt,
    splitText,
  }
}
