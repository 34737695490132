import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../redusers/config'
import { getNickList, sendCredit, directlyPay } from '../../actions/transaction'
import { TGetNickNameListR, TSendCreditP } from '../../utils/api'
import FormInput from '../FormInput'
import { AutoComplete } from '../Autocomplete'
import { Star } from '../Icons/Star'
import cn from './cn.module.css'
import { useCommonCheck } from 'hooks/useCommon'
import { AudioItem } from 'components/Menu/tooltip'
import { Balance } from 'redusers/transaction'
import { useGetThemeMode } from 'state/theme/hook'

const onCreditChange =
  (setCreditVal: (v: string) => void, balance: number) => (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value
    const prefix = '$'

    if (balance === 0) {
      return null
    }

    if (val === '') {
      return setCreditVal(val)
    }

    const onlyNumberofVal = val.includes('$') ? val.substr(prefix.length) : val
    if (!val.includes('$') && !isNaN(parseInt(val))) {
      return setCreditVal(prefix + val)
    }

    if (val.includes(prefix) && val.substr(prefix.length) === '') {
      return setCreditVal('')
    }

    const reg = /^[$]+?\d+?\.?\d*$/

    if (!reg.test(val)) {
      return null
    }

    const [int, float = ''] = val.substr(prefix.length).split('.')
    if (float.length > 2) {
      return null
    }

    setCreditVal(val)
  }

interface SendCreditFormP {
  balance: Balance
  listNickName: TGetNickNameListR[]
  getNickList: Function
  sendCredit: Function
  userInfo: any
  directlyPay: Function
  isLoadSendPay: boolean
}

const SendCreditForm: React.FC<SendCreditFormP> = ({
  isLoadSendPay,
  balance,
  listNickName,
  getNickList,
  sendCredit,
  userInfo,
  directlyPay,
}) => {
  const { showToastMsg } = useCommonCheck()
  const [nameVal, setNameVal] = useState<string>('')
  const [creditVal, setCreditVal] = useState<string>('')
  const [flag, setFlag] = useState<boolean>(false)
  const [isOpenOption, setIsOpenOption] = useState<boolean>(false)
  const [receiverInfo, setReceiverInfo] = useState<TGetNickNameListR>({
    nick: '',
    paypalAddress: '',
    mhcAddress: '',
    ethAddress: '',
    bnbAddress: '',
  })

  const onCalcMax = () => (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault()

    const maxVal = balance.userPaypal
    setCreditVal('$' + maxVal.toFixed(2))
  }

  const sendingOptionPanel = () => {
    if (Number(creditVal.slice(1)) === 0) {
      showToastMsg('0 amount cannot be transferred', 'error')
      return
    }
    setIsOpenOption(!isOpenOption)
    AudioItem('auto-paypal').play()
  }

  const onSendCredit = () => (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault()
    if (!isNaN(Number(creditVal.slice(1))) && Number(creditVal.slice(1)) > balance.userPaypal) {
      showToastMsg('#Credits more than the balance cannot be transferred.', 'error')
      return
    }

    const sendCreditData: TSendCreditP = {
      receiver: nameVal.slice(1),
      amount: Number(creditVal.slice(1)),
    }

    AudioItem('send-credit').play()
    sendCredit(sendCreditData)
    setNameVal('')
    setIsOpenOption(false)
  }

  const directlyPayment = (type: string, nick: string) => (e: React.FormEvent<HTMLElement>) => {
    directlyPay({
      type: type,
      nick: nick.slice(1),
      amount: Number(creditVal.slice(1)),
      address: type === 'paypal' ? receiverInfo.paypalAddress : receiverInfo.mhcAddress,
    })
    setIsOpenOption(false)
  }

  useEffect(() => {
    setFlag(true)
    getNickList()

    return () => {
      setFlag(false)
    }
  }, [getNickList])

  useEffect(() => {
    setNameVal('')
    setCreditVal('')
  }, [isLoadSendPay])

  useEffect(() => {
    const receiver: TGetNickNameListR = listNickName.filter((item: TGetNickNameListR) => item.nick === nameVal)[0]
    if (receiver === undefined) {
      return
    }

    setReceiverInfo(receiver)
  }, [isLoadSendPay, nameVal, creditVal, listNickName])

  const themeMode = useGetThemeMode()
  return (
    <>
      <div className={cn.credit_payInp}>
        <AutoComplete
          data={
            userInfo.id !== 1042
              ? listNickName.filter((item: TGetNickNameListR) => item.nick.slice(1) !== userInfo.nick)
              : listNickName
          }
          onChange={setNameVal}
          nameValue={nameVal}
          themeMode={themeMode}
        />
        <div className={cn.credit_sendCreditInp}>
          <FormInput
            value={creditVal}
            placeholder={'$ 0.00'}
            isFocusStyle={true}
            onChange={onCreditChange(setCreditVal, balance.userPaypal)}
            themeMode={themeMode}
            border={'none'}
          />
        </div>
      </div>
      <button
        type="button"
        className="btnTag"
        onClick={sendingOptionPanel}
        style={{ background: 'linear-gradient(#4297dd, #03569c)' }}
      >
        <span className="search-btn">
          <span style={{ color: '#fff', marginRight: '10px' }}>SEND</span>
        </span>
      </button>
      {isOpenOption && (
        <>
          <div
            onClick={() => setIsOpenOption(false)}
            style={{
              display: isOpenOption ? 'block' : 'none',
              width: '200vw',
              height: '200vh',
              backgroundColor: 'transparent',
              position: 'fixed',
              zIndex: 0,
              top: 0,
              left: 0,
            }}
          />
          <div
            className={cn.credit_sendingOptionPanel}
            style={{
              background: themeMode === 'main' ? '' : '#fffffff5',
              color: themeMode === 'main' ? 'white' : 'black',
            }}
          >
            <div className={cn.credit_senCreditContainer}>
              <span className={cn.credit_sendCreditText}>Send Credits to</span>
              <span className={cn.credit_nickNameText}>{receiverInfo?.nick}</span>
            </div>

            <div className={cn.credit_divideLine}></div>

            <div className={cn.credit_btnContainer}>
              <span className={cn.credit_zeroPercentText}>
                Send for <strong className={cn.credit_zeroText}>0</strong>% fees.
              </span>
              <button
                type="button"
                className={`${cn.credit_sendConfirmBtn} ${cn.credit_payBtn}`}
                disabled={Number(creditVal.slice(1)) > balance.userPaypal}
                style={{
                  background:
                    themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                  color: themeMode == 'main' ? 'white' : 'black',
                }}
                onClick={onSendCredit()}
              >
                Confirm and Send
              </button>
            </div>

            <div className={cn.credit_divideLine}></div>

            <div className={cn.credit_directPaymentContainer}>
              <span className={cn.credit_directPaymentTitle}>Account native platform fees will apply.</span>
              <div className={cn.credit_directPaymentOption}>
                <button
                  type="button"
                  className={`${cn.credit_paymentBtn} ${cn.credit_payBtn}`}
                  disabled={receiverInfo?.paypalAddress === ''}
                  style={{
                    background:
                      themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                    color: themeMode == 'main' ? 'white' : 'black',
                  }}
                  onClick={directlyPayment('paypal', receiverInfo.nick)}
                >
                  <Star size={'15px'} isFilled={receiverInfo?.paypalAddress !== ''} />
                  <div className={cn.credit_optionText}>PayPal</div>
                </button>

                <button
                  type="button"
                  className={`${cn.credit_paymentBtn} ${cn.credit_payBtn}`}
                  // disabled={receiverInfo?.mhcAddress === ''}
                  disabled={true}
                  style={{
                    background: true //receiverInfo?.mhcAddress === ''
                      ? '#606061'
                      : themeMode === 'main'
                      ? 'linear-gradient(#873f99, #311438)'
                      : 'linear-gradient(#b7eaf6, #777fc4)',
                    color: true ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
                  }}
                  // onClick={directlyPayment('metahash', receiverInfo.nick)}
                >
                  <Star size={'15px'} isFilled={receiverInfo?.mhcAddress !== ''} />
                  <div className={cn.credit_optionText}>MHC</div>
                </button>

                <button
                  type="button"
                  className={`${cn.credit_paymentBtn} ${cn.credit_payBtn}`}
                  // disabled={receiverInfo?.ethAddress === ''}
                  disabled={true}
                  style={{
                    background: true //receiverInfo?.ethAddress === ''
                      ? '#606061'
                      : themeMode === 'main'
                      ? 'linear-gradient(#873f99, #311438)'
                      : 'linear-gradient(#b7eaf6, #777fc4)',
                    color: true ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
                  }}
                  // onClick={directlyPayment('metahash', receiverInfo.nick)}
                >
                  <Star size={'15px'} isFilled={receiverInfo?.ethAddress !== ''} />
                  <div className={cn.credit_optionText}>ETH</div>
                </button>

                <button
                  type="button"
                  className={`${cn.credit_paymentBtn} ${cn.credit_payBtn}`}
                  // disabled={receiverInfo?.bnbAddress === ''}
                  disabled={true}
                  style={{
                    background: true //receiverInfo?.bnbAddress === ''
                      ? '#606061'
                      : themeMode === 'main'
                      ? 'linear-gradient(#873f99, #311438)'
                      : 'linear-gradient(#b7eaf6, #777fc4)',
                    color: true ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
                  }}
                  // onClick={directlyPayment('metahash', receiverInfo.nick)}
                >
                  <Star size={'15px'} isFilled={receiverInfo?.bnbAddress !== ''} />
                  <div className={cn.credit_optionText}>BNB</div>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

function mapStateToProps({ transaction, user }: AppState) {
  return {
    isLoadSendPay: transaction.get('isLoadSendPay'),
    balance: transaction.get('balance'),
    listNickName: transaction.get('listNickName'),
    userInfo: user.get('userInfo'),
  }
}

export default connect(mapStateToProps, {
  getNickList,
  sendCredit,
  directlyPay,
})(SendCreditForm)
