import React from 'react'
import { FlexRow, FlexRowContent, HorizontalDivider, SubContainer, SubContainerBody, SubTitleText } from '../../styled'
import { FaRegStopCircle, FaRegCheckCircle, FaExclamationCircle } from 'react-icons/fa'
import { IBridgeStatusProps } from '../../types'
import { useGetThemeMode } from 'state/theme/hook'

const StatusIcon = {
  Pending: <FaRegStopCircle color={'#ee092f'} />,
  Process: <FaExclamationCircle color={'#0093fc'} />,
  Success: <FaRegCheckCircle color={'#2ec814'} />,
}

export const MHCLoans: React.FC = () => {
  const recurringCredit: IBridgeStatusProps[] = [
    { id: 1, amount: 1502.2626, status: 'Process' },
    { id: 2, amount: 1502.2626, status: 'Success' },
    { id: 3, amount: 1502.2626, status: 'Success' },
  ]
  const themeMode = useGetThemeMode()

  return (
    <SubContainer width={'20%'} height={'200px'} themeMode={themeMode}>
      <FlexRowContent>
        <SubTitleText fontSize={'12px'}>MHC LOANS</SubTitleText>
      </FlexRowContent>
      <HorizontalDivider />
      <SubContainerBody>
        {recurringCredit.map((item) => {
          return (
            <FlexRowContent key={item.id}>
              <SubTitleText>{`${item.amount} MHC`}</SubTitleText>
              {StatusIcon[item.status]}
            </FlexRowContent>
          )
        })}
      </SubContainerBody>
    </SubContainer>
  )
}
