import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FaCheck, FaEthereum, FaTimes } from 'react-icons/fa'
import { GoGift } from 'react-icons/go'
import { Init } from '../../redusers/user'
import { AppState } from '../../redusers/config'
import { confirmWithdrawReq, getListTransactionForAdm, rejectWithdrawReq } from '../../actions/transaction'
import cn from './cn.module.css'
import './style.css'
import SuccessIcon from '../Icons/SuccessCheckIcon'
import FailExclamationIcon from '../Icons/FailCheckIcon'
import FailureIcon from '../Icons/FailureIcon'
import { Star } from '../Icons/Star'
import { CSSTransition } from 'react-transition-group'
import { useGetThemeMode } from 'state/theme/hook'
import { SiBinance } from 'react-icons/si'
import { MdOutlineCallMade, MdOutlineCallReceived } from 'react-icons/md'
import { BNB_EXPLORER_TEST_URL, ETH_EXPLORER_TEST_URL, MHC_EXPLORER_URL } from 'constants/urls'

export interface ColumnDefsI {
  headerName: string
  field: string
  isKey?: boolean
  hide?: boolean
  display?: (row: any) => string
  styleClass?: string
}
export type ColumnDefs = Array<ColumnDefsI>

interface PayTableP<R> {
  columnRow: Array<R>
  columnDefs: ColumnDefs
  loadf?: () => any
  dataLength?: number
  hasMore?: boolean
  classname?: string
}

function getKey(row: any, columnDefs: ColumnDefs) {
  const item = columnDefs.find((i) => i.isKey)
  if (item === undefined) {
    return null
  }
  return row[item.field]
}

interface RowP {
  row: any
  columnDefs: ColumnDefs
  isWithdraw: boolean
  isAdmin: string
}

const Row: React.FC<RowP> = React.memo(({ row, columnDefs, isWithdraw, isAdmin }) => {
  const dispatch = useDispatch()
  const [disable, setDisable] = useState<boolean>(false)
  const [disableRejectBtn, setDisableRejectBtn] = useState<boolean>(false)

  const url = new URL(window.location.href)
  const isTransaction = url.pathname.includes('listTransaction')

  const confirmWithdraw = (disable: boolean) => {
    if (disable) return

    dispatch(
      confirmWithdrawReq({
        id: row.id,
        amount: row.amount,
        type: row.paySystem,
        withdrawAddress: row.withdrawAddress,
      })
    )

    setDisable(true)
  }

  const rejectWithdraw = (disable: boolean) => {
    if (disable) return
    dispatch(rejectWithdrawReq({ id: row.id }))

    setDisableRejectBtn(true)
  }

  const showTransactionDetail = () => {
    if (row.paySystem == 'metahash' || row.paySystem == 'ETH' || row.paySystem == 'BNB') {
      if (row.paySystemHash === '' || row.paySystemHash === null) return

      let detailLink = ''
      if (row.paySystem == 'metahash') {
        detailLink = MHC_EXPLORER_URL + row.paySystemHash
      } else if (row.paySystem == 'ETH') {
        const hash = row.paySystemHash.substring(4)
        detailLink = ETH_EXPLORER_TEST_URL + hash
      } else if (row.paySystem == 'BNB') {
        const hash = row.paySystemHash.substring(4)
        detailLink = BNB_EXPLORER_TEST_URL + hash
      }

      if (detailLink !== '') window.open(detailLink, '_blank')
    }
  }

  const themeMode = useGetThemeMode()

  return (
    <div
      className={themeMode == 'main' && isWithdraw ? cn.itemWithdraw : themeMode == 'light' ? cn.item_l : cn.item}
      key={getKey(row, columnDefs)}
      onClick={() => showTransactionDetail()}
    >
      {columnDefs.map((item, index) => {
        if (item.hide) return null

        return (
          <div key={item.field} className={`${cn.cell} ${item.styleClass ? item.styleClass : ''}`}>
            {typeof item.display === 'function' ? (
              item.field === 'status' ? (
                row[item.field] === 'SUCCESS' ? (
                  <span className={cn.statusCell}>
                    {'Success'}
                    <SuccessIcon />
                  </span>
                ) : row[item.field] === 'ERROR' ? (
                  <span className={cn.statusCell}>
                    {'Failure'}
                    <FailureIcon />
                  </span>
                ) : isWithdraw ? (
                  isAdmin === 'YES' && isTransaction && row.reason === 0 && !disable && !disableRejectBtn ? (
                    <div className={cn.withdrawConfirm}>
                      <div
                        className={`${disable ? cn.withdrawConfirmDisableBtn : cn.withdrawConfirmBtn}`}
                        onClick={() => {
                          confirmWithdraw(disable)
                        }}
                      >
                        <FaCheck />
                      </div>
                      <div
                        className={`${disableRejectBtn ? cn.withdrawRejectDisableBtn : cn.withdrawRejectBtn}`}
                        onClick={() => {
                          rejectWithdraw(disableRejectBtn)
                        }}
                      >
                        <FaTimes />
                      </div>
                    </div>
                  ) : (
                    <div className={cn.withdrawConfirm}>
                      <span className={cn.statusCell}>
                        {'Processing'}
                        <FailExclamationIcon />
                      </span>
                    </div>
                  )
                ) : (
                  <span className={cn.statusCell}>
                    {'Processing'}
                    <FailExclamationIcon />
                  </span>
                )
              ) : item.field === 'type' ? (
                item.display(row) === 'D-Received' ? (
                  row['paySystem'] === 'paypal' ? (
                    <div className={cn.directPaymentType}>
                      <Star size={'14px'} isFilled={true} />
                      <div className={cn.optionText}>PayPal</div>
                      <MdOutlineCallReceived className={cn.directPaymentDirection} />
                    </div>
                  ) : (
                    <div className={cn.directPaymentType}>
                      <Star size={'14px'} isFilled={true} />
                      <div className={cn.optionText}>MHC</div>
                      <MdOutlineCallReceived className={cn.directPaymentDirection} />
                    </div>
                  )
                ) : item.display(row) === 'D-Sent' ? (
                  row['paySystem'] === 'paypal' ? (
                    <div className={cn.directPaymentType}>
                      <Star size={'14px'} isFilled={true} />
                      <div className={cn.optionText}>PayPal</div>
                      <MdOutlineCallMade className={cn.directPaymentDirection} />
                    </div>
                  ) : (
                    <div className={cn.directPaymentType}>
                      <Star size={'14px'} isFilled={true} />
                      <div className={cn.optionText}>MHC</div>
                      <MdOutlineCallMade className={cn.directPaymentDirection} />
                    </div>
                  )
                ) : item.display(row) === 'E-Received' ? (
                  <div className={cn.ethType}>
                    <FaEthereum size={14} color={'#353738'} />
                    <div className={cn.optionText}>ETH</div>
                    <MdOutlineCallReceived className={cn.directPaymentDirection} />
                  </div>
                ) : item.display(row) === 'E-Sent' ? (
                  <div className={cn.ethType}>
                    <FaEthereum size={14} color={'#353738'} />
                    <div className={cn.optionText}>ETH</div>
                    <MdOutlineCallMade className={cn.directPaymentDirection} />
                  </div>
                ) : item.display(row) === 'B-Received' ? (
                  <div className={cn.bnbType}>
                    <SiBinance size={12} color={'#ffd900'} />
                    <div className={cn.optionText}>BNB</div>
                    <MdOutlineCallReceived className={cn.directPaymentDirection} />
                  </div>
                ) : item.display(row) === 'B-Sent' ? (
                  <div className={cn.bnbType}>
                    <SiBinance size={12} color={'#ffd900'} />
                    <div className={cn.optionText}>BNB</div>
                    <MdOutlineCallMade className={cn.directPaymentDirection} />
                  </div>
                ) : item.display(row) === 'G-Received' ? (
                  <div className={cn.giftType}>
                    <GoGift size={'14px'} color="yellow" style={{ marginRight: '10px' }} />
                    <div className={cn.optionText}> GIFT</div>
                    <MdOutlineCallReceived className={cn.directPaymentDirection} />
                  </div>
                ) : item.display(row) === 'G-Sent' ? (
                  <div className={cn.giftType}>
                    <GoGift size={'14px'} color="yellow" style={{ marginRight: '10px' }} />
                    <div className={cn.optionText}> GIFT</div>
                    <MdOutlineCallMade className={cn.directPaymentDirection} />
                  </div>
                ) : item.display(row) === 'Received' ? (
                  <div className={cn.commonType}>
                    <div className={cn.optionText}>ok-{item.display(row)}</div>
                  </div>
                ) : (
                  // 'Sent'
                  <div className={cn.commonType}>
                    <div className={cn.optionText}>{item.display(row)}</div>
                  </div>
                )
              ) : (
                item.display(row)
              )
            ) : (
              row[item.field]
            )}
          </div>
        )
      })}
    </div>
  )
})

Row.displayName = 'Row'

function PayTable<R>({
  columnRow,
  columnDefs,
  loadf = () => {},
  dataLength = 0,
  hasMore = false,
  classname,
}: PayTableP<R>) {
  const [flag, setFlag] = useState<boolean>(false)
  const isAdmin = useSelector<AppState, Init['isAdmin']>(({ user }) => user.get('isAdmin'), shallowEqual)

  columnRow.map((item: any) => {
    if (item.withdrawAddress?.length > 0) {
      item.type = 'withdraw'
    }
    return item
  })

  const withdrawRequestsList = columnRow.filter(
    (item: any) => item.status === 'PENDING' && item.type === 'withdraw' && item.reason === 0
  )
  const otherTransactionsList = columnRow.filter((item: any) => !withdrawRequestsList.includes(item))

  useEffect(() => {
    setFlag(true)

    return () => {
      setFlag(false)
    }
  }, [])

  const themeMode = useGetThemeMode()

  return (
    <CSSTransition in={flag} timeout={4500} classNames="domainDashboardFadeTransition" unmountOnExit>
      <div className={themeMode == 'main' ? cn.table : cn.table_l}>
        <div className={cn.header}>
          {columnDefs.map((item) => {
            if (item.hide) {
              return null
            }
            return (
              <div key={item.field} className={`${cn.cell} ${item.styleClass ? item.styleClass : ''}`}>
                {item.headerName}
              </div>
            )
          })}
        </div>
        <InfiniteScroll
          dataLength={dataLength}
          next={loadf}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          className={cn.infiniteScroll}
          height={'calc(100% - 25px)'}
          scrollableTarget={'table_body'}
        >
          <div className={cn.body} id={'table_body'}>
            {withdrawRequestsList.map((item) => {
              return (
                <Row
                  key={getKey(item, columnDefs)}
                  row={item}
                  columnDefs={columnDefs}
                  isWithdraw={true}
                  isAdmin={isAdmin}
                />
              )
            })}
            {otherTransactionsList.map((item) => {
              return (
                <Row
                  key={getKey(item, columnDefs)}
                  row={item}
                  columnDefs={columnDefs}
                  isWithdraw={false}
                  isAdmin={isAdmin}
                />
              )
            })}
          </div>
        </InfiniteScroll>
      </div>
    </CSSTransition>
  )
}

export default PayTable
