import React, { useEffect, useState } from 'react'
import cn from './cn.module.css'
import Title from '../FormTitle'
import FormInput from '../FormInput'
import CustomSelectBox from '../CustomSelectBox'
import WithdrawCreditModal from '../Modals/WithdrawCreditModal'
import NotificationModal from '../Modals/WithdrawCreditModal/NotificationModal'
import { Record } from 'immutable'
import { Balance, Init as Transaction } from '../../redusers/transaction'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { useGetThemeMode } from 'state/theme/hook'
import { AudioItem } from 'components/Menu/tooltip'

type tags = 'paypal' | 'metahash' | 'userETH' | 'userBNB'

const onCreditChange =
  (setVal: (v: string) => void, balance: Balance, selectOption: tags, setDisabled: (d: boolean) => void) =>
  (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value
    const prefix = '$'
    if (balance.userPaypal === 0) {
      return null
    }
    if (val === '') {
      return setVal(val)
    }

    const onlyNumberofVal = val.includes('$') ? val.substr(prefix.length) : val
    const numAmount = Number(onlyNumberofVal)
    const transactionFeePercent = 5.99

    if (!isNaN(numAmount) && numAmount + (numAmount * transactionFeePercent) / 100 > balance.userPaypal) {
      setDisabled(true)
      return null
    }

    if (numAmount > balance[selectOption]) {
      setDisabled(true)
      return null
    }

    if (!val.includes('$') && !isNaN(parseInt(val))) {
      return setVal(prefix + val)
    }

    if (val.includes(prefix) && val.substr(prefix.length) === '') {
      return setVal('')
    }
    const reg = /^[$]+?\d+?\.?\d*$/

    if (!reg.test(val)) {
      return null
    }

    const [int, float = ''] = val.substr(prefix.length).split('.')
    if (float.length > 2) {
      return null
    }

    if (Number(val.substr(prefix.length)) > balance[selectOption]) {
      return null
    }

    setVal(val)
  }

const onWithdrawCredit = (setShowWithdrawModal: Function) => (e: React.FormEvent<HTMLElement>) => {
  setShowWithdrawModal(true)
  e.preventDefault()
}

const closeModal = (setShowWithdrawModal: Function) => (e: React.FormEvent<HTMLElement>) => {
  setShowWithdrawModal(false)
}

const closeResModal = (setShowResponseModal: Function) => (e: React.FormEvent<HTMLElement>) => {
  AudioItem('paypal-withdraw').play()
  setShowResponseModal(false)
}

interface IWithdrawForm {
  isLoadSendWithdraw: boolean
  // balance: number
  balance: Balance
}

const WithdrawForm: React.FC<IWithdrawForm> = ({ isLoadSendWithdraw, balance }) => {
  const [creditVal, setCreditVal] = useState<string>('')
  const [selectOption, setSelectOption] = useState<tags>('paypal')
  const [disabled, setDisabled] = useState<boolean>(true)
  const [showWithdrawModal, setShowWithdrawModal] = useState<boolean>(false)
  const [showResponseModal, setShowResponseModal] = useState<boolean>(false)
  const [flag, setFlag] = useState<boolean>(false)

  const transactionFeePercent = '5.99'

  const onSelectOptionChange = (value: tags) => {
    setSelectOption(value)
  }

  const onCalcMax = () => (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault()

    const maxVal = Math.min(
      balance.userPaypal - (balance.userPaypal * Number(transactionFeePercent)) / 100,
      balance[selectOption]
    )
    setCreditVal('$' + maxVal.toFixed(2))
  }

  useEffect(() => {
    setFlag(true)

    return () => {
      setFlag(false)
    }
  }, [])

  useEffect(() => {
    setCreditVal('')
  }, [isLoadSendWithdraw])

  useEffect(() => {
    if (isLoadSendWithdraw === true) {
      setDisabled(true)
      setShowResponseModal(true)
      return
    }
    if (creditVal === '') {
      setDisabled(true)
      return
    }
    setDisabled(false)
  }, [isLoadSendWithdraw, creditVal])

  useEffect(() => {
    if (creditVal === '') {
      setDisabled(true)
      return
    }

    const onlyNumberofVal = creditVal.includes('$') ? creditVal.substr(1) : creditVal
    const numAmount = Number(onlyNumberofVal)

    if (!isNaN(numAmount) && numAmount > balance[selectOption]) {
      setDisabled(true)
      return
    }

    setDisabled(false)
  }, [selectOption])

  const themeMode = useGetThemeMode()

  return (
    <CSSTransition in={flag} timeout={5500} classNames="bridgeFadeTransition" unmountOnExit>
      <div className={themeMode == 'main' ? cn.withdrawForm : cn.withdrawForm_l}>
        <Title title="Withdraw #Credit" feePercent="5.99%" themeMode={themeMode}></Title>
        <label className={cn.formLabel}>
          <span
            style={{
              color: themeMode == 'main' ? '#fff' : themeMode == 'light' ? 'black' : '#fff',
            }}
          >
            #Hashtag Credit Amount:&nbsp;&nbsp;
          </span>
          {balance.userPaypal.toFixed(2)}
        </label>
        <form>
          <div className={cn.payInp}>
            <div className={cn.withdrawInp}>
              <FormInput
                value={creditVal}
                placeholder={'$ 0.00'}
                onChange={onCreditChange(setCreditVal, balance, selectOption, setDisabled)}
                isFocusStyle={true}
                themeMode={themeMode}
              />
              <button
                className={cn.maxBtn}
                onClick={onCalcMax()}
                style={{
                  color: themeMode == 'main' ? '#fff' : themeMode == 'light' ? 'black' : '#fff',
                }}
              >
                Max
              </button>
            </div>
            <CustomSelectBox onOptionChange={onSelectOptionChange} value={selectOption} />
          </div>
          <div className={cn.btnCont}>
            <button
              className={cn.payGoBtn}
              disabled={disabled}
              onClick={onWithdrawCredit(setShowWithdrawModal)}
              style={{
                background: disabled
                  ? '#606061'
                  : themeMode === 'main'
                  ? 'linear-gradient(#873f99, #311438)'
                  : 'linear-gradient(#b7eaf6, #777fc4)',
                color: disabled ? '#c5c4c4' : themeMode === 'main' ? 'white' : 'black',
              }}
            >
              GO
            </button>
          </div>
        </form>
        <WithdrawCreditModal
          showModal={showWithdrawModal}
          onClose={closeModal(setShowWithdrawModal)}
          creditVal={creditVal}
          selectOption={selectOption}
          transactionFeePercent={transactionFeePercent}
        />
        <NotificationModal
          showModal={showResponseModal}
          onClose={closeResModal(setShowResponseModal)}
          title="Withdraw Request Confirmation"
          messageText="Success your withdraw is in the que"
          description="Normally within an hour but could take up to 24 hrs."
        />
      </div>
    </CSSTransition>
  )
}

function mapStateToProps({ transaction }: { transaction: Record<Transaction> }) {
  return {
    isLoadSendWithdraw: transaction.get('isLoadSendWithdraw'),
    price: transaction.get('price'),
    balance: transaction.get('balance'),
  }
}

export default connect(mapStateToProps)(WithdrawForm)
