import request from '../../../utils/api/request'

export type TMailTmpl = { text: string }

export const setTmpl = request<boolean, TMailTmpl>({
  method: 'mailing.setTmpl',
})

export const getTmpl = request<string>({
  method: 'mailing.getTmpl',
})

export type TRGetTemplateList = {
  code: string
  name: string
  status: boolean
}

export const getTemplateList = request<TRGetTemplateList[]>({
  method: 'notification.getTemplateList',
})

export type TRGetTemplate = {
  template: {
    code: string
    name: string
    title: string
    body: string
    status: boolean
  }
  macro: { name: string; description: string }[]
}

export type TPGetTemplate = {
  code: string
}

export const getTemplate = request<TRGetTemplate, TPGetTemplate>({
  method: 'notification.getTemplate',
})

export type TPSetTemplate = {
  code: string
  fields: {
    body?: string
    title?: string
    status?: boolean
  }
}
export const setTemplateN = request<boolean, TPSetTemplate>({
  method: 'notification.setTemplate',
})

export type TPPreviewTemplate = {
  code: string
  fields?: {
    body?: string
    title?: string
  }
}
export type TRPreviewTemplate = {
  body: string
  title: string
}

export const previewTemplate = request<TRPreviewTemplate, TPPreviewTemplate>({
  method: 'notification.previewTemplate',
})
