import { MODAL_TYPES, useGlobalModalContext } from 'components/Modals/GlobalModal'
import moment from 'moment'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { AiOutlineCloseCircle, AiOutlinePlusSquare } from 'react-icons/ai'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import 'react-tippy/dist/tippy.css'
import { CSSTransition } from 'react-transition-group'
import { onSetDefaultDomain, onUpdateHashtag, TonShowModalManage } from '../../actions/hashtag'
import { HashtagItem } from '../../utils/api'
import DollarGreenIcon from '../Icons/DollarGreenIcon'
import TargetUrlIcon from '../Icons/TargetUrlIcon'
import './styles.scss'
import { UseDomainsDashboard } from './hooks'
import { GoGift } from 'react-icons/go'
import { useGetThemeMode } from 'state/theme/hook'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import PlanetIcon from 'components/PlanetIcon'
import { BsCameraVideoFill, BsPencil } from 'react-icons/bs'
import FormInput from 'components/FormInput'
import { FaRegCopy } from 'react-icons/fa'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import { Init as HashtagInit } from 'redusers/actions'

import ExtraInL1 from '../../assets/extra-svg/extra_in_l1.svg'
import ExtraInD1 from '../../assets/extra-svg/extra_in_d1.svg'
import ExtraInB1 from '../../assets/extra-svg/extra_in_b1.svg'

import ExtraOutL2 from '../../assets/extra-svg/extra_out_l2.svg'
import ExtraOutL3 from '../../assets/extra-svg/extra_out_l3.svg'
import ExtraOutL4 from '../../assets/extra-svg/extra_out_l4.svg'
import ExtraOutL5 from '../../assets/extra-svg/extra_out_l5.svg'
import ExtraOutL6 from '../../assets/extra-svg/extra_out_l6.svg'
import ExtraOutL7 from '../../assets/extra-svg/extra_out_l7.svg'
import ExtraOutL8 from '../../assets/extra-svg/extra_out_l8.svg'
import ExtraOutL9 from '../../assets/extra-svg/extra_out_l9.svg'

import ExtraOutD2 from '../../assets/extra-svg/extra_out_d2.svg'
import ExtraOutD3 from '../../assets/extra-svg/extra_out_d3.svg'
import ExtraOutD4 from '../../assets/extra-svg/extra_out_d4.svg'
import ExtraOutD5 from '../../assets/extra-svg/extra_out_d5.svg'
import ExtraOutD6 from '../../assets/extra-svg/extra_out_d6.svg'
import ExtraOutD7 from '../../assets/extra-svg/extra_out_d7.svg'
import ExtraOutD8 from '../../assets/extra-svg/extra_out_d8.svg'
import ExtraOutD9 from '../../assets/extra-svg/extra_out_d9.svg'

import ExtraOutR2 from '../../assets/extra-svg/extra_out_r2.svg'
import ExtraOutR3 from '../../assets/extra-svg/extra_out_r3.svg'
import ExtraOutR4 from '../../assets/extra-svg/extra_out_r4.svg'
import ExtraOutR5 from '../../assets/extra-svg/extra_out_r5.svg'
import ExtraOutR6 from '../../assets/extra-svg/extra_out_r6.svg'
import ExtraOutR7 from '../../assets/extra-svg/extra_out_r7.svg'
import ExtraOutR8 from '../../assets/extra-svg/extra_out_r8.svg'
import ExtraOutR9 from '../../assets/extra-svg/extra_out_r9.svg'
import { onUpdateUserOther } from 'actions/user'
import { useGetDomainList } from 'views/Search/hooks/availableCategoryList'
import styled from 'styled-components'
import { useIsCollapseMenu } from 'state/collapseMenu/hook'

interface RowP {
  id: string
  onShowModalManage: (payload: TonShowModalManage['payload']) => void
  searchVal: string
  onStopResale: () => void
  setShowId: Function
  setIsDefault: Function
  item: any
  setCurItem: Function
  onClear: () => void
  themeMode: string
}

const showTargetURLModal = (onShowModalManage: RowP['onShowModalManage'], id: RowP['id'], searchVal: string) => () => {
  onShowModalManage({ id, searchVal })
}

let resaleTimer: any = undefined
const onClickResale = (setIsShowTR: Function) => () => {
  if (resaleTimer !== undefined) {
    setIsShowTR(false)
    clearTimeout(resaleTimer)
  }

  setIsShowTR(true)
  resaleTimer = setTimeout(function () {
    setIsShowTR(false)
  }, 2000)
}

function Row({
  id,
  onShowModalManage,
  searchVal,
  onStopResale,
  setShowId,
  setIsDefault,
  item,
  setCurItem,
  onClear,
  themeMode,
}: RowP) {
  const { showModal } = useGlobalModalContext()

  const onResale = (id: string) => {
    showModal(MODAL_TYPES.DOMAIN_RESALE_MODAL, id)
  }

  const onSendingGift = (id: string) => {
    showModal(MODAL_TYPES.GIFT_SENDING_MODAL, id)
  }

  const onVideoConfirm = (id: string, url: string) => {
    showModal(MODAL_TYPES.VIDEO_CONFIRM_MODAL, { id, url })
  }

  const onShowDetail = () => {
    onClear()
    setShowId(id)
    setCurItem(item)
    if (item.is_default_domain === 1) setIsDefault(true)
    else setIsDefault(false)
  }

  const resale = item.resale
  const keywordArr = item.keywords
  const url = item.url
  const defaultVal = item.is_default_domain
  let originTargetUrl = url ? url : ''

  let key = 'No Keywords'
  if (keywordArr !== null && keywordArr !== undefined && keywordArr.length > 0) {
    const keystr = keywordArr.join()
    if (keystr !== '') key = keystr
  }

  return (
    <tr>
      <td className="smThree">
        <div className="all">
          <div className="suball">
            <span className="domain_icon">
              <PlanetIcon seed={id} size={50} themeMode={themeMode} />
              {/* {`/domain_edit/${encodeURIComponent(id)}`} */}
              <div className="edittxt_container" onClick={onShowDetail}>
                <span className="edittxt">Edit</span>
                <BsPencil size={12} color={themeMode === 'light' ? 'gray' : '#ffffff'} />
              </div>
            </span>
            <div className="domainNameItem">
              <div className="domain_name" style={{ color: defaultVal === 1 ? '#cf8909' : '' }}>
                {id}
                {defaultVal === 1 && <MdRadioButtonChecked style={{ marginLeft: '30px' }} />}
              </div>
              <div className="domainUrls">{url ? url : 'No target url'}</div>
              <div className="domainUrls">{key}</div>
            </div>
          </div>
        </div>
      </td>
      <td className="smThree">
        <div className="date">{moment(item.expire_timestamp * 1000).format('MMM DD, YYYY')}</div>
        <div className="smfont">
          <span>{moment(item.expire_timestamp * 1000).format('MMM DD, YYYY')}</span>
        </div>
        <div className="smfont">{resale.status && <div>Resale price: ${resale.price.toFixed(2)} </div>}</div>
      </td>
      <td className="smFour">
        <div className="row">
          <div className="subrow">
            <div className="videoBtn" onClick={() => onVideoConfirm(id, originTargetUrl)}>
              <BsCameraVideoFill size={18} />
              <span style={{ marginLeft: '5px' }}>GO LIVE</span>
            </div>
            <div className="targetBtn" onClick={showTargetURLModal(onShowModalManage, id, searchVal)}>
              <TargetUrlIcon />
              Target URL
            </div>
          </div>
          <div className="subrow">
            {resale.status === false && (
              <span className="resellBtn" onClick={() => onResale(id)}>
                <DollarGreenIcon />
                Resell
              </span>
            )}
            {resale.status === true && (
              <span className="stopResaleBtn" onClick={onStopResale}>
                Stop resale
              </span>
            )}
            <div className="giftBtn" onClick={() => onSendingGift(id)}>
              <GoGift size={18} />
              &nbsp;Gift
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
}

const DashboardContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `calc(100% - ${width})`};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const DomainsDashboardTable: React.FC = () => {
  const dispatch = useDispatch()
  const isCollapseMenu = useIsCollapseMenu()
  const userInfo = useSelector<AppState, Init['userInfo']>(({ user }) => user.get('userInfo'))
  const hashtagList = useSelector<AppState, HashtagInit['hashtagList']>(({ actions }) => actions.get('hashtagList'))

  const [flagCSS, setFlagCSS] = useState<boolean>(true)
  const [noExistStr, setNoExistStr] = useState<string>('')
  const [showId, setShowId] = useState<string>('')
  const [curItem, setCurItem] = useState<any>()
  const [targetUrlArr, setTargetUrlArr] = useState<Array<string>>([''])
  const [descTxt, setDescTxt] = useState<string>('')
  const [keywordTxt, setKeywordTxt] = useState<string>('')
  const [keywordVal, setKeywordVal] = useState<string>('')
  const [ethTxt, setEthTxt] = useState<string>('')
  const [bnbTxt, setBnbTxt] = useState<string>('')
  const [btcTxt, setBtcTxt] = useState<string>('')
  const [gpsTxt1, setGpsTxt1] = useState<string>('')
  const [gpsTxt2, setGpsTxt2] = useState<string>('')
  const [isCopy, setIsCopy] = useState<string>('')
  const [isDefault, setIsDefault] = useState<boolean>(false)

  const [newList, setNewList] = useState<HashtagItem[]>([])
  const [searchVal, setSearchVal] = useState<string>('')

  const [overExtra1, setOverExtra1] = useState<boolean>(false)
  const [overExtra2, setOverExtra2] = useState<boolean>(false)
  const [overExtra3, setOverExtra3] = useState<boolean>(false)
  const [overExtra4, setOverExtra4] = useState<boolean>(false)
  const [overExtra5, setOverExtra5] = useState<boolean>(false)
  const [overExtra6, setOverExtra6] = useState<boolean>(false)
  const [overExtra7, setOverExtra7] = useState<boolean>(false)
  const [overExtra8, setOverExtra8] = useState<boolean>(false)
  const [overExtra9, setOverExtra9] = useState<boolean>(false)
  const [overExtra10, setOverExtra10] = useState<boolean>(false)

  const { onShowModalURL, onStopResale } = UseDomainsDashboard(0)
  // const getExpiredItem = (item: string) => {
  //   onGetExpiredItem(item)
  // }

  // const IsOpenExpireList = () => {
  //   if (expiredList.length < 1) return
  //   setOpenExpiredList(true)
  // }

  const { showModal } = useGlobalModalContext()

  const onResale = (id: string) => {
    showModal(MODAL_TYPES.DOMAIN_RESALE_MODAL, id)
  }

  const onSendingGift = (id: string) => {
    showModal(MODAL_TYPES.GIFT_SENDING_MODAL, id)
  }

  const onVideoConfirm = (id: string, url: string) => {
    showModal(MODAL_TYPES.VIDEO_CONFIRM_MODAL, { id, url })
  }

  const onCloseCurItem = () => {
    setShowId('')
    setCurItem({})
    setDescTxt('')
    setKeywordTxt('')
    setEthTxt('')
    setBnbTxt('')
    setBtcTxt('')
    setGpsTxt1('')
    setGpsTxt2('')
    setTargetUrlArr([''])
  }
  const onSaveCurItem = () => {
    const domain = curItem.id
    const gpsVal = gpsTxt1 + ',' + gpsTxt2
    const urlArr = targetUrlArr.filter((v) => v !== '' && (v.includes('http://') || v.includes('https://')))
    const targetUrlVal = urlArr.join()
    const param1 = { id: domain, desc: descTxt, keyword: keywordTxt, url: targetUrlVal }
    dispatch(onUpdateHashtag(param1))

    const param2 = { eth: ethTxt, bnb: bnbTxt, btc: btcTxt, gps: gpsVal }
    dispatch(onUpdateUserOther(param2))

    onCloseCurItem()
  }

  const onChangeText = useCallback(
    (flag: string) => (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const val = e.currentTarget.value
      if (flag === 'desc') setDescTxt(val)
      if (flag === 'keyword') setKeywordTxt(val)
      if (flag === 'eth') setEthTxt(val)
      if (flag === 'bnb') setBnbTxt(val)
      if (flag === 'btc') setBtcTxt(val)
      if (flag === 'gps1') setGpsTxt1(val)
      if (flag === 'gps2') setGpsTxt2(val)
      if (flag.includes('turl_')) {
        const num = Number(flag.substring(5))
        const arr = targetUrlArr.map((v, inx) => (inx === num ? val : v))
        setTargetUrlArr(arr)
      }
    },
    [targetUrlArr]
  )

  const handleAddTargetUrl = () => {
    if (targetUrlArr.length > 8) return
    const arr = [...targetUrlArr, '']
    setTargetUrlArr(arr)
  }

  const removeTargetUrl = (num: number) => {
    const arr = targetUrlArr.filter((v: string, inx: number) => inx !== num)
    setTargetUrlArr(arr)
  }
  const handleInputKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    if (val.includes(',')) return
    setKeywordVal(val)
  }

  const handleAddKeyword = () => {
    const val = keywordTxt === '' ? keywordVal : keywordTxt + ', ' + keywordVal
    setKeywordTxt(val)
    setKeywordVal('')
  }
  const handleAddKeywordByEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleAddKeyword()
  }
  const openTargetUrl = (flag: number) => {
    if (targetUrlArr.length === 0) return
    if (targetUrlArr[flag] === '') return
    if (!targetUrlArr[flag].includes('http://') && !targetUrlArr[flag].includes('https://')) return

    window.open(targetUrlArr[flag], '_blank')
  }
  const onMouseOverExtra = (flag: number) => {
    if (flag === 0) setOverExtra1(true)
    if (flag === 1) setOverExtra2(true)
    if (flag === 2) setOverExtra3(true)
    if (flag === 3) setOverExtra4(true)
    if (flag === 4) setOverExtra5(true)
    if (flag === 5) setOverExtra6(true)
    if (flag === 6) setOverExtra7(true)
    if (flag === 7) setOverExtra8(true)
    if (flag === 8) setOverExtra9(true)
    if (flag === 9) setOverExtra10(true)
  }
  const onMouseOutExtra = (flag: number) => {
    if (flag === 0) setOverExtra1(false)
    if (flag === 1) setOverExtra2(false)
    if (flag === 2) setOverExtra3(false)
    if (flag === 3) setOverExtra4(false)
    if (flag === 4) setOverExtra5(false)
    if (flag === 5) setOverExtra6(false)
    if (flag === 6) setOverExtra7(false)
    if (flag === 7) setOverExtra8(false)
    if (flag === 8) setOverExtra9(false)
    if (flag === 9) setOverExtra10(false)
  }

  const handleCopy = (flag: string) => {
    let txt = ''
    if (flag === 'eth') txt = ethTxt
    if (flag === 'bnb') txt = bnbTxt
    if (flag === 'btc') txt = btcTxt

    setIsCopy(flag)
    navigator.clipboard.writeText(txt)
    setTimeout(function () {
      setIsCopy('')
    }, 1000)
  }
  const handleSetDefault = () => {
    const defaultVal = !isDefault
    setIsDefault(defaultVal)

    const newId = curItem.id
    dispatch(onSetDefaultDomain({ newId, isDefault: defaultVal }))
  }

  const onClearItem = () => {
    setDescTxt('')
    setKeywordTxt('')
    setTargetUrlArr([''])
  }

  const onChangeSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value
    setSearchVal(e.currentTarget.value)

    if (search === '') setNewList(hashtagList)
    else if (search.length > 0) {
      const searchStr = '#' + search.toLowerCase()
      const list = hashtagList.filter((v) => v.id.toLowerCase().includes(searchStr))
      setNewList(list)

      if (list.length === 0) setNoExistStr('DOES NOT EXIST.')
      else setNoExistStr('')
    }
  }

  useEffect(() => {
    if (curItem !== null && curItem !== undefined) {
      if (curItem.description !== null) setDescTxt(curItem.description)
      if (curItem.url !== null && curItem.url !== undefined) {
        const targetUrls = curItem.url.split(',')
        const urls = targetUrls.filter((v: string, inx: number) => inx <= 8)
        setTargetUrlArr(urls)
      }
      if (curItem.keywords !== null && curItem.keywords !== undefined) {
        const keywordStr = curItem.keywords.join()
        setKeywordTxt(keywordStr)
      }
    }
    if (userInfo.eth_address) setEthTxt(userInfo.eth_address)
    if (userInfo.bnb_address) setBnbTxt(userInfo.bnb_address)
    if (userInfo.btc_address) setBtcTxt(userInfo.btc_address)
    if (userInfo.gps_info) {
      const gps = userInfo.gps_info
      const gpsArr = gps.split(',')
      setGpsTxt1(gpsArr[0])
      setGpsTxt2(gpsArr[1])
    }
  }, [curItem, userInfo])

  useEffect(() => {
    if (hashtagList.length > 0) setNewList(hashtagList)
  }, [hashtagList])

  const themeMode = useGetThemeMode()

  return (
    <DashboardContainer width={isCollapseMenu ? '65px' : '235px'}>
      <CSSTransition in={flagCSS} timeout={3000} classNames="domainDashboardFadeTransition" unmountOnExit>
        <input
          className="domaindashboardsearchinp"
          type="text"
          placeholder={'Search'}
          value={searchVal}
          onChange={onChangeSearch}
          style={{
            background: themeMode === 'main' ? '#00000090' : '#ffffff90',
            color: themeMode === 'main' ? 'white' : 'black',
            borderColor: themeMode === 'main' ? '#ffffff90' : '#26a5e0ab',
          }}
        />
      </CSSTransition>

      {showId !== '' && (
        <div className={themeMode == 'main' ? 'form' : 'form_l'}>
          {/* <div className="close} onClick={onCloseCurItem}>
            <AiOutlineCloseCircle size={20} />
          </div> */}
          <div className="formRowBetween_top">
            <div className="formRowStart" style={{ padding: '15px 20px' }}>
              <span>
                <PlanetIcon seed={showId} size={50} themeMode={themeMode} />
              </span>
              <div className="domainNameItem">
                {showId}
                <div className="keywordSpan">
                  <span
                    className="keyWords"
                    style={{
                      color: themeMode === 'main' ? 'white' : 'black',
                    }}
                  >
                    Edit
                  </span>
                  <BsPencil color={themeMode === 'light' ? 'gray' : '#ffffff'} />
                </div>
              </div>
              <div className="default" onClick={handleSetDefault}>
                <span className="defaulttxt">Set as Default</span>
                {isDefault ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
              </div>
            </div>

            <div className="formRowEnd">
              <div className="videoBtn" onClick={() => onVideoConfirm(showId, curItem.url)}>
                <BsCameraVideoFill size={18} />
                <span style={{ marginLeft: '5px' }}>GO LIVE</span>
              </div>
              <div className="targetBtn" onClick={showTargetURLModal(onShowModalURL, showId, searchVal)}>
                <TargetUrlIcon />
                Target URL
              </div>
              {curItem.resale.status === false && (
                <span className="resellBtn" onClick={() => onResale(showId)}>
                  <DollarGreenIcon />
                  Resell
                </span>
              )}
              {curItem.resale.status === true && (
                <span className="stopResaleBtn" onClick={onStopResale(showId)}>
                  Stop resale
                </span>
              )}
              <div className="giftBtn" onClick={() => onSendingGift(showId)}>
                <GoGift size={18} />
                &nbsp;Gift
              </div>
            </div>
          </div>
          <div>
            <div className="formRowBetween">
              <div className="twocol">
                <label>Description</label>
                <textarea
                  rows={4}
                  className="txtarea"
                  placeholder={'someone stole my dog those fucks!!'}
                  value={descTxt}
                  onChange={onChangeText('desc')}
                  style={{
                    background: themeMode === 'main' ? '#00000090' : '#ffffff90',
                    color: themeMode === 'main' ? 'white' : 'black',
                  }}
                />
              </div>
              <div className="twocol">
                <div className="topkeyword">
                  <label>Keyword</label>
                  <span className="keywordSpan">
                    <input
                      type="text"
                      className="keyword_input"
                      placeholder="Type your keyword here"
                      value={keywordVal}
                      onChange={handleInputKeyword}
                      onKeyPress={handleAddKeywordByEnter}
                      style={{
                        color: themeMode === 'main' ? 'white' : 'black',
                      }}
                    />
                    <AiOutlinePlusSquare
                      size={16}
                      color={themeMode === 'light' ? 'gray' : '#ffffff'}
                      onClick={handleAddKeyword}
                    />
                  </span>
                </div>
                <textarea
                  rows={4}
                  className="txtarea"
                  placeholder={'SEO Backlinks, Backlinks'}
                  value={keywordTxt}
                  onChange={onChangeText('keyword')}
                  style={{
                    background: themeMode === 'main' ? '#00000090' : '#ffffff90',
                    color: themeMode === 'main' ? 'white' : 'black',
                  }}
                />
              </div>
            </div>

            <div className="formRowBetween_crypto">
              <div className="threecol">
                <FormInput label="ETH" value={ethTxt} onChange={onChangeText('eth')} themeMode={themeMode} />
                <div className="copyicon" onClick={() => handleCopy('eth')}>
                  <FaRegCopy className={isCopy === 'eth' ? 'circleborder' : ''} />
                </div>
              </div>
              <div className="threecol">
                <FormInput label="BNB" value={bnbTxt} onChange={onChangeText('bnb')} themeMode={themeMode} />
                <div className="copyicon" onClick={() => handleCopy('bnb')}>
                  <FaRegCopy className={isCopy === 'bnb' ? 'circleborder' : ''} />
                </div>
              </div>
              <div className="threecol">
                <FormInput label="BTC" value={btcTxt} onChange={onChangeText('btc')} themeMode={themeMode} />
                <div className="copyicon" onClick={() => handleCopy('btc')}>
                  <FaRegCopy className={isCopy === 'btc' ? 'circleborder' : ''} />
                </div>
              </div>
            </div>

            <div className="formRowBetween_gps">
              <div className="fourcol">
                <div>
                  <label>GPS Coordination</label>
                  <div className="card" style={{ background: themeMode === 'main' ? '#00000090' : '#ffffff90' }}>
                    <div className="gps_item">
                      <label>Latitude : </label>
                      <input
                        type="number"
                        className="gps_input"
                        placeholder="26.025648"
                        value={gpsTxt1}
                        onChange={onChangeText('gps1')}
                        style={{
                          color: themeMode === 'main' ? 'white' : 'black',
                        }}
                      />
                    </div>
                    <div className="gps_item">
                      <label>Longtitude : </label>
                      <input
                        type="number"
                        className="gps_input"
                        placeholder="-123.458562"
                        value={gpsTxt2}
                        onChange={onChangeText('gps2')}
                        style={{
                          color: themeMode === 'main' ? 'white' : 'black',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="allUrl">
                <div className="rowUrl">
                  {targetUrlArr.map((v: string, inx: number) => {
                    if (inx > 2) return // 0, 1, 2
                    return (
                      <div key={inx} className="targeturl">
                        {inx === 0 && (
                          <img
                            src={overExtra1 ? ExtraInB1 : themeMode === 'main' ? ExtraInL1 : ExtraInD1}
                            className="extraicon"
                            onClick={() => openTargetUrl(inx)}
                            onMouseOver={() => onMouseOverExtra(inx)}
                            onMouseOut={() => onMouseOutExtra(inx)}
                          />
                        )}
                        {inx === 1 && (
                          <img
                            src={overExtra2 ? ExtraOutR2 : themeMode === 'main' ? ExtraOutL2 : ExtraOutD2}
                            className="extraicon"
                            onClick={() => openTargetUrl(inx)}
                            onMouseOver={() => onMouseOverExtra(inx)}
                            onMouseOut={() => onMouseOutExtra(inx)}
                          />
                        )}
                        {inx === 2 && (
                          <img
                            src={overExtra3 ? ExtraOutR3 : themeMode === 'main' ? ExtraOutL3 : ExtraOutD3}
                            className="extraicon"
                            onClick={() => openTargetUrl(inx)}
                            onMouseOver={() => onMouseOverExtra(inx)}
                            onMouseOut={() => onMouseOutExtra(inx)}
                          />
                        )}
                        <FormInput
                          label={inx === 0 ? 'Target URL' : ''}
                          value={targetUrlArr[inx]}
                          onChange={onChangeText('turl_' + inx)}
                          paddingRight="25px"
                          paddingLeft="25px"
                          labelHeight="15px"
                          themeMode={themeMode}
                        />
                        <AiOutlineCloseCircle className="closeicon" onClick={() => removeTargetUrl(inx)} />
                      </div>
                    )
                  })}
                </div>
                <div className="rowUrl1">
                  {targetUrlArr.map((v: string, inx: number) => {
                    if (inx <= 2 || inx > 5) return // 3, 4, 5
                    return (
                      <div key={inx} className="targeturl">
                        {inx === 3 && (
                          <img
                            src={overExtra4 ? ExtraOutR4 : themeMode === 'main' ? ExtraOutL4 : ExtraOutD4}
                            className="extraicon"
                            onClick={() => openTargetUrl(inx)}
                            onMouseOver={() => onMouseOverExtra(inx)}
                            onMouseOut={() => onMouseOutExtra(inx)}
                          />
                        )}
                        {inx === 4 && (
                          <img
                            src={overExtra5 ? ExtraOutR5 : themeMode === 'main' ? ExtraOutL5 : ExtraOutD5}
                            className="extraicon"
                            onClick={() => openTargetUrl(inx)}
                            onMouseOver={() => onMouseOverExtra(inx)}
                            onMouseOut={() => onMouseOutExtra(inx)}
                          />
                        )}
                        {inx === 5 && (
                          <img
                            src={overExtra6 ? ExtraOutR6 : themeMode === 'main' ? ExtraOutL6 : ExtraOutD6}
                            className="extraicon"
                            onClick={() => openTargetUrl(inx)}
                            onMouseOver={() => onMouseOverExtra(inx)}
                            onMouseOut={() => onMouseOutExtra(inx)}
                          />
                        )}
                        <FormInput
                          label={inx === 0 ? 'Target URL' : ''}
                          value={targetUrlArr[inx]}
                          onChange={onChangeText('turl_' + inx)}
                          paddingRight="25px"
                          paddingLeft="25px"
                          labelHeight="15px"
                          themeMode={themeMode}
                        />
                        <AiOutlineCloseCircle className="closeicon" onClick={() => removeTargetUrl(inx)} />
                      </div>
                    )
                  })}
                </div>
                <div className="rowUrl1">
                  {targetUrlArr.map((v: string, inx: number) => {
                    if (inx <= 5) return
                    return (
                      <div key={inx} className="targeturl">
                        {inx === 6 && (
                          <img
                            src={overExtra7 ? ExtraOutR7 : themeMode === 'main' ? ExtraOutL7 : ExtraOutD7}
                            className="extraicon"
                            onClick={() => openTargetUrl(inx)}
                            onMouseOver={() => onMouseOverExtra(inx)}
                            onMouseOut={() => onMouseOutExtra(inx)}
                          />
                        )}
                        {inx === 7 && (
                          <img
                            src={overExtra8 ? ExtraOutR8 : themeMode === 'main' ? ExtraOutL8 : ExtraOutD8}
                            className="extraicon"
                            onClick={() => openTargetUrl(inx)}
                            onMouseOver={() => onMouseOverExtra(inx)}
                            onMouseOut={() => onMouseOutExtra(inx)}
                          />
                        )}
                        {inx === 8 && (
                          <img
                            src={overExtra9 ? ExtraOutR9 : themeMode === 'main' ? ExtraOutL9 : ExtraOutD9}
                            className="extraicon"
                            onClick={() => openTargetUrl(inx)}
                            onMouseOver={() => onMouseOverExtra(inx)}
                            onMouseOut={() => onMouseOutExtra(inx)}
                          />
                        )}
                        <FormInput
                          label={inx === 0 ? 'Target URL' : ''}
                          value={targetUrlArr[inx]}
                          onChange={onChangeText('turl_' + inx)}
                          paddingRight="25px"
                          paddingLeft="25px"
                          labelHeight="15px"
                          themeMode={themeMode}
                        />
                        <AiOutlineCloseCircle className="closeicon" onClick={() => removeTargetUrl(inx)} />
                      </div>
                    )
                  })}
                </div>
                <div className="formRowStart">
                  <div className="addurlSpan" onClick={handleAddTargetUrl}>
                    <span
                      className="keyWords"
                      style={{
                        color: themeMode === 'main' ? 'white' : 'black',
                      }}
                    >
                      Add URL
                    </span>
                    <AiOutlinePlusSquare size={16} color={themeMode === 'light' ? 'gray' : '#ffffff'} />
                  </div>
                </div>
              </div>
            </div>

            <div className="formRowEnd_btn">
              <button className="discardBtn" onClick={onCloseCurItem}>
                DISCARD
              </button>
              <button
                className="saveBtn"
                onClick={onSaveCurItem}
                style={{
                  background:
                    themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                  color: themeMode == 'main' ? 'white' : 'black',
                }}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      )}
      <CSSTransition in={flagCSS} timeout={3000} classNames="domainDashboardFadeTransition11" unmountOnExit>
        <div
          className="wrapper"
          id={'scroll_table'}
          style={{
            background: themeMode == 'main' ? '#00000090' : '#ffffff90',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th className="smThree">All</th>
                <th className="smThree">Deadman&apos;s Switch</th>
                <th className="smFour">Actions</th>
              </tr>
            </thead>
            <tbody>
              {newList.length === 0 && (
                <tr>
                  <td>{noExistStr}</td>
                </tr>
              )}
              {newList.map((item, index) => {
                let keyVal = item.id + '-' + index
                if (showId === item.id) return

                return (
                  <Row
                    key={keyVal}
                    id={item.id}
                    setShowId={setShowId}
                    setIsDefault={setIsDefault}
                    onShowModalManage={onShowModalURL}
                    searchVal={searchVal}
                    onStopResale={onStopResale(item.id)}
                    item={item}
                    setCurItem={setCurItem}
                    onClear={onClearItem}
                    themeMode={themeMode}
                  />
                )
              })}
            </tbody>
          </table>
        </div>
      </CSSTransition>
    </DashboardContainer>
  )
}

export default DomainsDashboardTable
