import React, { useRef } from 'react'
import styled from 'styled-components'
import ContentEditable from 'react-contenteditable'
import { TMarketSpaceItem } from './types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { AppState } from 'redusers/config'
import { Init } from 'redusers/user'
import { updateMarketSpaceDetail } from 'actions/marketspace'

const MarketSpaceDetailBody = styled(ContentEditable)`
  padding: 0px 4px;
  line-height: 1.8;
`
interface IMarketSpaceDetail {
  item: TMarketSpaceItem
  type: 'title' | 'detail' | 'buttonText' | 'url'
  value: string
  themeMode?: string
}
const MarketSpaceDetail: React.FC<IMarketSpaceDetail> = ({ item, type, value, themeMode }) => {
  const dispatch = useDispatch()
  const detailRef = useRef(value)
  const isAdmin = useSelector<AppState, Init['isAdmin']>(({ user }) => user.get('isAdmin'), shallowEqual)

  const handleChange = (e: any) => {
    detailRef.current = e.target.value
  }
  const handleSave = () => {
    dispatch(updateMarketSpaceDetail([{ ...item, [type]: detailRef.current }]))
  }

  return (
    <MarketSpaceDetailBody
      html={detailRef.current}
      disabled={isAdmin !== 'YES'} // use true to disable edition
      onChange={(e) => handleChange(e)} // handle innerHTML change
      onBlur={handleSave}
      style={{
        color: themeMode == 'main' ? (type == 'title' ? '#ffffff' : '#ffffffd5') : 'black',
      }}
    />
  )
}

export default MarketSpaceDetail
