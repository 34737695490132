import React from 'react'
import classnames from 'classnames'
import { usePagination, DOTS } from './usePagination'
import styled from 'styled-components'

const PagenationContainer = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  list-style-type: none;
`
const PaginationItem = styled.li<{
  isDots?: boolean
  isActive?: boolean
  isDisabled?: boolean
}>`
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: white;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
  background-color: ${({ isActive }) => (isActive ? 'rgba(255, 255, 255, 0.08)' : 'transparent')};
  &.dots:hover {
    background-color: transparent;
    cursor: default;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.04);
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;

    .arrow::before {
      border-right: 0.12em solid rgba(255, 255, 255, 0.43);
      border-top: 0.12em solid rgba(255, 255, 255, 0.43);
    }

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`
const Arrow = styled.div`
  &::before {
    position: relative;
    /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(255, 255, 255, 0.87);
    border-top: 0.12em solid rgba(255, 255, 255, 0.87);
  }
`
const LeftArrow = styled(Arrow)`
  transform: rotate(-135deg) translate(-50%);
`
const RightArrow = styled(Arrow)`
  transform: rotate(45deg);
`

export interface IPagination {
  onPageChange: Function
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
  className?: string
}
const Pagination: React.FC<IPagination> = (props) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (currentPage === 0 || !!!paginationRange || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  const lastPage = paginationRange[paginationRange.length - 1]
  return (
    <PagenationContainer>
      {currentPage > 1 && (
        <PaginationItem onClick={onPrevious}>
          <LeftArrow />
        </PaginationItem>
      )}
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <PaginationItem key={pageNumber} isDots={true}>
              &#8230;
            </PaginationItem>
          )
        }

        return (
          <PaginationItem
            key={pageNumber}
            isActive={pageNumber === currentPage}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </PaginationItem>
        )
      })}
      {currentPage < lastPage && (
        <PaginationItem onClick={onNext}>
          <RightArrow />
        </PaginationItem>
      )}
    </PagenationContainer>
  )
}

export default Pagination
