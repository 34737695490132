import React, { FC } from 'react'
import Article from '../Article'
import useChangeInfo from './useChangeInfo'

const ChanfeInfo: FC = () => {
  const { title, description, error, memoizedSetTitle, memoizedDescription, memoizedSubmit } = useChangeInfo()

  return (
    <Article
      title={title}
      description={description}
      error={error}
      memoizedSetTitle={memoizedSetTitle}
      memoizedDescription={memoizedDescription}
      memoizedSubmit={memoizedSubmit}
    />
  )
}

export default ChanfeInfo
