import { SagaIterator } from '@redux-saga/core'
import { takeEvery } from 'redux-saga/effects'
import { TAddItem, TDelItem, TInitCart, TPayForCart } from '../types'
import { worcerInitCart, worcerPayForCart, workerAddToCart, workerDelItemCart } from './workers'

export default function* watchCart(): SagaIterator {
  yield takeEvery<TAddItem>('CART_ADD_ITEM', workerAddToCart)
  yield takeEvery<TDelItem>('CART_DEL_ITEM', workerDelItemCart)
  yield takeEvery<TInitCart>('CART_INIT', worcerInitCart)
  yield takeEvery<TPayForCart>('PAY_FOR_CART', worcerPayForCart)
}
