import { push } from 'connected-react-router'
import { List } from 'immutable'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onGetListInfo, onRemoveIteminfo, onSortInfoList } from '../../../actions/info'
import { AppState } from '../../../redusers/config'
import { TListInfoItem } from '../../../utils/api'

export function useInfoList() {
  const dispatch = useDispatch()
  const listInfo = useSelector<AppState, List<TListInfoItem>>(({ info }) => info.get('listInfo'))

  useEffect(() => {
    dispatch(onGetListInfo())
  }, [dispatch])

  const [listData, setListData] = useState(() => listInfo)

  useEffect(() => {
    setListData(listInfo)
  }, [listInfo])

  const memoizedDelete = useCallback(
    (id: number) => () => {
      dispatch(onRemoveIteminfo(id))
    },
    [dispatch]
  )
  const memoizedEdit = useCallback(
    (id: number) => () => {
      dispatch(push(`/admin/changeInfo/changeArticle/${id}`))
    },
    []
  )

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragData = listData.get(dragIndex)
      if (dragData === undefined) return
      const hoverData = listData.get(hoverIndex)
      if (hoverData === undefined) return

      const newList = listData.set(dragIndex, hoverData).set(hoverIndex, dragData)
      setListData(newList)
    },
    [listData]
  )
  const onDrop = useCallback(() => {
    dispatch(onSortInfoList(listData))
  }, [dispatch, listData])

  return { listData, memoizedDelete, memoizedEdit, moveCard, onDrop }
}
