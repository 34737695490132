import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import DomainsDashboardTable from '../../components/DomainsDashboardTable'
import DomainsSearch from '../../components/DomainsSearch'
import MainContent from '../../components/MainContent'
import Menu from '../../components/Menu'
import { AppState } from '../../redusers/config'
import { getLogin } from '../../utils/utils'
import ComingSoon from '../../views/ComingSoon'
import { TR_Search } from '../../views/Search/types'
import Affiliates from '../Affiliates'
import PayPage from '../PayPage'
import Payment from '../PayPage/Payment'
import Search from '../Search'
import SendEmail from '../SendEmail'
import UserProfile from '../UserProfile'
import { useAvailableCategoryList } from 'views/Search/hooks/availableCategoryList'
import DomainEdit from 'views/DomainEdit'
import Dreaming from 'views/Dreaming'
import MarketSpace from 'views/MarketSpace'
import DomainsCommand from 'components/DomainsCommand'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import HeaderButtons from 'components/Header/HeaderButtons'
import OptionSearchForm from 'views/Search/components/OptionSearchForm'
import CategoryMainContainer from 'views/Search/components/Category/CategoryMain'
import SearchEngineResponse from 'views/Search/components/searchEngineResponse'
import { useIsClickAvatar } from 'state/avatar/hook'
import { setIsClickAvatar } from 'state/avatar/reducer'
import PersonInfo from 'views/PersonInfo'
import EnterWeb from 'views/EnterWeb'
import { UserInfo } from 'utils/api'
import DeleteItem from 'views/DeleteItem'
import Dashboard from 'views/Dashboard'

const DomainsResult = () => {
  const isClickAvatar = useIsClickAvatar()
  const dispatch = useDispatch()

  const [flag, setFlag] = useState<boolean>(false)
  const [loadUser, setLoadUser] = useState<boolean>(false)
  const [closeSearch, setCloseSearch] = useState<boolean>(true)

  const handleCloseSearch = useCallback(() => {
    setCloseSearch(!closeSearch)
  }, [closeSearch])

  useEffect(() => {
    return () => {
      dispatch(setIsClickAvatar(false))
    }
  }, [dispatch])

  return (
    <DomainsSearch>
      {isClickAvatar ? (
        <PersonInfo />
      ) : (
        <OptionSearchForm closeSearch={closeSearch} handleCloseSearch={handleCloseSearch} />
      )}
      <CSSTransition in={flag} timeout={3000} classNames="domainDashboardFadeTransition" unmountOnExit>
        <>
          <CategoryMainContainer closeSearch={closeSearch} handleCloseSearch={handleCloseSearch} />
          <SearchEngineResponse closeSearch={closeSearch} handleCloseSearch={handleCloseSearch} />
        </>
      </CSSTransition>
      {/* <SearchResult /> */}
      <DomainsCommand />
    </DomainsSearch>
  )
}

const UserRouting = () => {
  const isSearch = useSelector<AppState, TR_Search['search_isSearch']>(({ search_reducer }) =>
    search_reducer.get('search_isSearch')
  )

  const allCategoryList = useAvailableCategoryList().filter((item) => item.availability !== 'sold')
  const categoryList = useMemo(() => {
    return allCategoryList.length > 3 ? [...allCategoryList.slice(3)] : [...allCategoryList]
  }, [allCategoryList])

  return (
    <MainContent>
      <Menu />
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/domainsresult" component={DomainsResult} />
        <Route path="/pay/payment" component={Payment} />
        <Route path="/pay" component={PayPage} />
        <Route path="/search" component={Search} />
        <Route path="/enter" component={EnterWeb} />
        <Route path="/domainsdashboard" component={DomainsDashboardTable} />
        <Route path="/domain_edit" component={DomainEdit} />
        <Route path="/Affiliates" exact component={Affiliates} />
        <Route path="/dreaming" component={Dreaming} />
        <Route path="/coming_soon/:pageID" component={ComingSoon} />
        <Route path="/marketspace" component={MarketSpace} />
        <Route path="/delete" component={DeleteItem} />
        {getLogin() && <Route path="/profile" component={UserProfile} />}
      </Switch>{' '}
    </MainContent>
  )
}

export default UserRouting
