import React from 'react'
import DomainEditForm from '../../components/DomainEditForm'
import DomainEditHeader from '../../components/DomainEditHeader'
import styled from 'styled-components'

const DomainEditContainer = styled.div`
  width: 100%;
  margin-left: 15px;
  box-sizing: border-box;
  position: relative;
`
const DomainEdit: React.FC = () => {
  return (
    <DomainEditContainer>
      <DomainEditHeader />
      <DomainEditForm />
    </DomainEditContainer>
  )
}

export default DomainEdit
