export const tooltipMenu = {
  command:
    'Command and conquer Hashtag space by finding and purchasing popular hashtag domains.  Hashtag domains can be pointed to any where on the web, dark web, or IPFS web 3.0 location you would like or can be held and resold as our platform gains in popularity.',
  control:
    'Take control of your decentralized #domains.  Here you can set your target URL, manage your auto-renewal, gift domains, and even list your domain for resell.  You can also purchase keywords for each domain to get your domain listed when users search our “Option” decentralized search engine.',
  spacebar:
    'Hang out with friends, surf social media, and even list products for sale, in #Space’s very own Social Media platform.  All the features you know and love without “Big Tech” overreaching or shutting you down.  If you hold MHC we hold your content for life!   If you drop MHC we drop your content.',
  function:
    'Learn How-to use various space.hashtag.org and MHC tools and features.  From purchasing MCH, to staking, and even learning about companies and products that support and feature MHC.',
  capslock:
    'Our premier decentralized, block chain based, email system allows you to securely send emails from one hashtag to another.  Every user gets a free email account for their space.hashtag.org profile name that sends and receives emails on the block chain.   Additionally every hashtag domain you purchase also gets an email account.',
  backslash:
    'An internal listing of our products and services for sale in our platform.  Expansion will include services and products across web 2.0, web 3.0, and the dark web available for purchase in our decentralized platform.  You can submit your own product or service for consideration.',
  option:
    'Our search is unique in that it’s decentralized and controlled by user input.  Each Hashtag Domain will choose and upgrade keywords.  Various tasks can be complete to earn points to help rank higher based on relevancy and participation.',
  return:
    'Put the power of #’s to work for you.  We offer 25% commissions for life on all referrals you bring to the platform.  Anything they buy, you earn - Instantly! We are also developing the words first commission based crypto currencies that can be used for services and abilities in our platform.',
  shift:
    'Upload money to purchase hashtag credits.  Exchange these credits for products and services in our platform, including buying MHC.  Other cryptocurrencies will be available for purchase with hashtag credits in the future.  You can also cross the bridge and exchange your MHC for credits, and cash those credits out to your PayPal account.',
  tab: 'Connect your various crypto wallets for an easy to monitor interface of your portfolio value. Use Hashtag space as your hang out and watch your crypto fortune grow as well continue to grow for the future.',
  dream:
    'The dreaming platform puts the power of live video at your finger tips.  Organize meetings, stream gaming, or even go live directly from your #domain to engage your audience.  New monetization features will be added in the future.  Initial launch will focus on abilities over magnetization.  Our Gamebling coin will work hand in hand to unlock additional features in the future.',
  enter:
    'An Esports platform that puts your skill to the test.  Join our platform and earn real money per kill.  Players across the globe compete in various game play modes and win/lose money per death/kill.  Use hashtag credits to play for real cash in various games with multiple game modes.  Currently in Beta.',
  delete: 'here tooltip for delete item.',
  esc: 'here tooltip for enter item.',
}

export const tooltipSearch = {
  symbol_1: 'Search #domain/keyword',
  symbol_2: 'Send credit',
  symbol_3: 'Search profile',
  symbol_4: 'Send email',
  symbol_5: 'Show Top earners',
  symbol_6: 'Show stream',
  symbol_7: 'Confirm bookmark',
  symbol_8: 'Send message',
  symbol_9: 'Domains recent claimed',
  symbol_10: 'Domains recent dropped',
  symbol_11: 'Open random target URLs',
  symbol_12: 'Exchange crypto',
  symbol_13: 'Pay',
  symbol_14: 'GameBling',
  symbol_15: 'Results',

  dotIcon: 'Mute/Unmute sound',
  menuEyeIcon: 'Show/Hide tooltip',
  menuTotalIcon: 'Mute/Unmute total sound',
  cartIcon: 'Shopping cart',
  starIcon: 'Star icon',
  bookmarkIcon: 'Bookmark',
  darkIcon: 'Dark',
  lightIcon: 'Light',
  androidIcon: 'RoadMap',
  adminDashBoard: 'Open Dashboard',
  adminTransaction: 'Open Transaction list',
  vpnIcon: 'Show/Hide VPN status',
}

const randomInteger = (max: number) => {
  return Math.floor(Math.random() * max) + 1
}
export const AudioItem = (symbol: string) => {
  const isPlay_search = localStorage.getItem('soundKiller_search')
  const isPlay_total = localStorage.getItem('soundKiller_total')
  if (isPlay_search == 'false' || isPlay_total == 'false') return new Audio()

  if (symbol == '#') return new Audio('/audio/symbols/Voice Clip Male 219.mp3')
  else if (symbol == '$') return new Audio('/audio/symbols/Voice Clip Male 120.mp3')
  else if (symbol == '/') return new Audio('/audio/symbols/Voice Clip Male 226.mp3')
  else if (symbol == '@') return new Audio('/audio/symbols/Horn Bike Clown 1.mp3')
  else if (symbol == '%') return new Audio('/audio/symbols/Kids Cheer Light.mp3')
  else if (symbol == '~') return new Audio('/audio/symbols/Cartoon Voice 34.mp3')
  else if (symbol == '()') return new Audio('/audio/symbols/PREL Glitch 5.mp3')
  else if (symbol == '!') return new Audio('/audio/symbols/PREL High Frequency 1.mp3')
  else if (symbol == '+') return new Audio('/audio/symbols/Magic Wind Chimes Harp.mp3')
  else if (symbol == '-') return new Audio('/audio/symbols/Magical Twinkle 1.mp3')
  else if (symbol == '&') return new Audio('/audio/symbols/Violin Tension.mp3')
  else if (symbol == '^') return new Audio('/audio/symbols/Sci Fi Alien 5.mp3')
  else if (symbol == 'btn-bookmark') return new Audio('/audio/symbols/Graphics Beeps 17.mp3')
  else if (symbol == 'receive-domain') return new Audio('/audio/symbols/Multimedia Notify 50.mp3')
  else if (symbol == 'send-msg') return new Audio('/audio/symbols/Evil Laugh 3.mp3')
  else if (symbol == 'error') return new Audio('/audio/symbols/MM Error 23.mp3')
  else if (symbol == 'btn-go' || symbol == 'btn-visit' || symbol == 'tab-launch') {
    if (randomInteger(7) == 1) {
      return new Audio('/audio/like/Missile Launch 2.mp3')
    } else if (randomInteger(7) == 2) {
      return new Audio('/audio/like/Rocket Launch Away 1.mp3')
    } else if (randomInteger(7) == 3) {
      return new Audio('/audio/like/Rocket Launcher 2.mp3')
    } else if (randomInteger(7) == 4) {
      return new Audio('/audio/like/Rocket Launcher 3.mp3')
    } else if (randomInteger(7) == 5) {
      return new Audio('/audio/like/Wrist Rocket Launch 2.mp3')
    } else if (randomInteger(7) == 6) {
      return new Audio('/audio/like/Wrist Rocket Launch 3.mp3')
    } else {
      return new Audio('Warp Speed.mp3')
    }
  }
  // else if (symbol == 'btn-visit') return new Audio('/audio/like/Rocket Launcher 2.mp3')
  // else if (symbol == 'tab-launch') return new Audio('/audio/like/Missile Launch 2.mp3')
  //
  else if (symbol == 'auto-paypal') return new Audio('/audio/ok_popup.mp3')
  else if (symbol == 'send-credit') return new Audio('/audio/send-credit-effect.mp3')
  else if (symbol == 'paypal-withdraw') return new Audio('/audio/paypal_withdraw.mp3')
  else if (symbol == 'wait_confirm') return new Audio('/audio/awaiting_confirm.mp3')
  else if (symbol == 'target-pop') return new Audio('/audio/menu_left/15.mp3')
  else {
    return new Audio('/audio/symbols/.mp3')
  }
}

export const AudioMenu = (flag: string) => {
  const isPlay_total = localStorage.getItem('soundKiller_total')
  if (isPlay_total == 'false') return new Audio()

  if (flag == 'logout') {
    if (randomInteger(5) == 1) return new Audio('/audio/menu_left/52.mp3')
    else if (randomInteger(5) == 2) return new Audio('/audio/menu_left/147.mp3')
    else if (randomInteger(5) == 3) return new Audio('/audio/menu_left/Robot Voice No.mp3')
    else if (randomInteger(5) == 4) return new Audio('/audio/menu_left/40.mp3')
    else return new Audio('/audio/menu_left/67.mp3')
  } else if (flag == 'login') {
    return new Audio('/audio/menu_left/101.mp3')
  } else if (flag == 'show_login') {
    return new Audio('/audio/menu_left/118.mp3')
  } else if (flag == 'failed_login') {
    return new Audio('/audio/menu_left/100.mp3')
  } else if (flag == 'require_login') {
    return new Audio('/audio/menu_left/30.mp3')
  } else if (flag == 'dream') {
    if (randomInteger(2) == 1) return new Audio('/audio/menu_left/72.mp3')
    else return new Audio('/audio/menu_left/Cosmic Static.mp3')
  } else if (flag == 'capslock') {
    if (randomInteger(4) == 1) return new Audio('/audio/menu_left/88.mp3')
    else if (randomInteger(4) == 2) return new Audio('/audio/menu_left/123.mp3')
    else if (randomInteger(4) == 3) return new Audio('/audio/menu_left/Teletype Readout Beeps 4.mp3')
    else return new Audio('/audio/menu_left/Teletype Beeps 2.mp3')
  } else if (flag == 'command') {
    if (randomInteger(4) == 1) return new Audio('/audio/menu_left/20.mp3')
    else if (randomInteger(4) == 2) return new Audio('/audio/menu_left/31.mp3')
    else if (randomInteger(4) == 3) return new Audio('/audio/menu_left/49.mp3')
    else return new Audio('/audio/menu_left/Panel Open.mp3')
  } else if (flag == 'option') {
    if (randomInteger(2) == 1) return new Audio('/audio/menu_left/143.mp3')
    else return new Audio('/audio/menu_left/Human Voice Clip 107.mp3')
  } else if (flag == 'control') {
    if (randomInteger(3) == 1) return new Audio('/audio/menu_left/18.mp3')
    else if (randomInteger(3) == 2) return new Audio('/audio/menu_left/16.mp3')
    else return new Audio('/audio/menu_left/PREL Glitch 10.mp3')
  } else if (flag == 'shift') {
    if (randomInteger(2) == 1) return new Audio('/audio/menu_left/122.mp3')
    else return new Audio('/audio/menu_left/Spaceship Pass By 46.mp3')
  } else if (flag == 'backslash') {
    if (randomInteger(2) == 1) return new Audio('/audio/menu_left/177.mp3')
    else return new Audio('/audio/menu_left/Robot Voice 8.mp3')
  } else if (flag == 'mute/unmute') {
    if (randomInteger(2) == 1) return new Audio('/audio/menu_left/Computer Hard Drive 3.mp3')
    else return new Audio('/audio/menu_left/Keypad Door Lock 1.mp3')
  } else if (flag == 'tab') {
    if (randomInteger(2) == 1) return new Audio('/audio/menu_left/24.mp3')
    else return new Audio('/audio/menu_left/Human Voice Clip 186.mp3')
  } else if (flag == 'function') {
    return new Audio('/audio/menu_left/Computer Access Data 5.mp3')
  } else if (flag == 'spacebar') {
    if (randomInteger(2) == 1) return new Audio('/audio/menu_left/Prison Crowd Jeer.mp3')
    else return new Audio('/audio/menu_left/Crowd Laughing 2.mp3')
  } else if (flag == 'profile') {
    return new Audio('/audio/menu_left/Robot Voice No.mp3')
  } else if (flag == 'return') {
    return new Audio('/audio/menu_left/Defibrillator 3.mp3')
  } else if (flag == 'error') {
    return new Audio('/audio/menu_left/144.mp3')
  } else {
    return new Audio('/audio/symbols/.mp3')
  }
}

export const placeTxt = {
  keyword: 'Type in your #destination',
  dream: 'Decentralized Streaming(DREAMING)',
  claim: '#Domains recently claimed',
  drop: '#Domains recently dropped',
  url: '(Target URL)',
  earn: 'TOP earners on #Space',
  profile: '#Profile',
  nick: '#NickName',
  dollar: '$ 0.00',
}
