import { getMarketSpaceList } from 'actions/marketspace'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redusers/config'
import { TR_MarketSpace } from './types'

export const useGetMarketSpaceList = () => {
  const dispatch = useDispatch()

  const marketSpaceList = useSelector<AppState, TR_MarketSpace['marketSpaceList']>(({ marketSpace_reducer }) =>
    marketSpace_reducer.get('marketSpaceList')
  )

  const lists = useMemo(() => {
    if (marketSpaceList && marketSpaceList.length > 0) return [...marketSpaceList]
    else {
      dispatch(getMarketSpaceList())
      return []
    }
  }, [dispatch, marketSpaceList])

  return lists
}
