import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/theme/material.css'
import React, { FC } from 'react'
import { Controlled as CodeMirror } from 'react-codemirror2'
import { useGetThemeMode } from 'state/theme/hook'
import cn from './cn.module.css'
import { useMetaTags } from './Hooks'

const codeOptions = {
  mode: 'htmlmixed',
  theme: 'material',
  lineNumbers: true,
  showCursorWhenSelecting: true,
}
export const MetaTags: FC = () => {
  const { code, memoizedDescription, memoizedSubmit, isLoad } = useMetaTags()
  const themeMode = useGetThemeMode()

  return (
    <div className={cn.wraper}>
      <form className={cn.form} onSubmit={memoizedSubmit} style={{ background: themeMode === 'light' ? 'white' : '' }}>
        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            {/*<label className={cn.label}>Meta tags</label>*/}
            {/*<textarea*/}
            {/*  className={cn.textarea}*/}
            {/*  value={code}*/}
            {/*  onChange={memoizedDescription}*/}
            {/*/>*/}

            <CodeMirror
              value={code}
              options={codeOptions}
              onBeforeChange={memoizedDescription}
              //onChange={memoizedDescription}
            />
          </div>
        </div>
        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <p className={cn.formTit}></p>
            <button
              disabled={isLoad}
              className={cn.formBtn}
              style={{
                background:
                  themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                color: themeMode == 'main' ? 'white' : 'black',
              }}
            >
              SAVE
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
