import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onGetResetCode, onResetPassword } from '../../../actions/user'
import LoginFormInp from '../LoginInp'
import MainContent from '../../../components/MainContent'
import { AppState } from '../../../redusers/config'
import { Init } from '../../../redusers/user'
import { FormButton, FormContainer, FormTitle } from '../styles'
import { useGetThemeMode } from 'state/theme/hook'

const ResetPassword: React.FC<any> = () => {
  const [password, setPassword] = useState<string>('')
  const [cPassword, setCPassword] = useState<string>('')
  const [errors, setErrors] = useState<Array<string>>([])
  const dispatch = useDispatch()
  dispatch(onGetResetCode())

  const code = useSelector<AppState, Init['resetPwdCode']>(({ user }) => user.get('resetPwdCode'))

  const resetPassword = () => {
    const errors = []

    if (!password) {
      errors.push('password')
    }
    if (password.length < 6) {
      errors.push('password')
    }
    if (password !== cPassword) {
      errors.push('cPassword')
    }

    setErrors(errors)
    if (!errors.length) {
      const val = { code, password }
      dispatch(onResetPassword(val))
    }
  }

  const themeMode = useGetThemeMode()

  return (
    <MainContent cn__maincontent_startpage="cn__maincontent_startpage">
      <FormContainer>
        <FormTitle>
          <span
            style={{
              color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
            }}
          >
            Reset Password
          </span>
        </FormTitle>
        <LoginFormInp
          label={'Password'}
          type={'password'}
          value={password}
          themeMode={themeMode}
          onChange={setPassword}
          isError={errors.includes('password')}
        />
        <LoginFormInp
          label={'Confirm password'}
          type={'password'}
          value={cPassword}
          themeMode={themeMode}
          onChange={setCPassword}
          isError={errors.includes('cPassword')}
        />
        <FormButton
          type="button"
          onClick={() => resetPassword()}
          style={{
            background: themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
            color: themeMode == 'main' ? 'white' : 'black',
          }}
        >
          Reset Password
        </FormButton>
      </FormContainer>
    </MainContent>
  )
}

export default ResetPassword
