import { useMenuAudio } from 'components/DomainsDashboardTable/hooks'
import MenuIcons from 'components/Icons/MenuIcons'
import { SOCIAL_URL } from 'constants/urls'
import React, { useCallback, useState } from 'react'
import { BsChat, BsHandThumbsUp, BsHeart } from 'react-icons/bs'
import { FiExternalLink } from 'react-icons/fi'
import { RiDoubleQuotesL } from 'react-icons/ri'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import './styles.scss'

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: 280px;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid;
  font-size: 14px;
  @media screen and (max-width: 1660px) {
    font-size: 12px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }
`
const IconWrapper = styled.div<{
  width: string
  height: string
  bgColor: string
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface RowP {
  time: string
  follower: string
  msg_type: string
  themeMode: string
}
function Row({ time, follower, msg_type, themeMode }: RowP) {
  let msg = 'New Message'
  let from = 'from'
  let bgColor = '#01b1f3'
  let iconItem = <BsChat color="#ffffff" size={20} />
  if (msg_type == 'comment') {
    msg = 'New Comment'
    bgColor = '#14c783'
    iconItem = <RiDoubleQuotesL color="#ffffff" size={20} />
  } else if (msg_type == 'like') {
    msg = 'Liked your post on..'
    from = 'by'
    bgColor = '#01d5f4'
    iconItem = <BsHandThumbsUp color="#ffffff" size={20} />
  } else if (msg_type == 'follow') {
    msg = 'Just followed you'
    from = 'by'
    bgColor = '#f40157'
    iconItem = <BsHeart color="#ffffff" size={20} />
  }

  return (
    <tr>
      <td className="msg-time">{time} ago</td>
      <td className="msg-type">
        <IconWrapper width={'40px'} height={'40px'} bgColor={bgColor}>
          {iconItem}
        </IconWrapper>
      </td>
      <td className="msg-content">
        {msg} <br />
        <span className="msg-time">{from}</span> {follower}
      </td>
    </tr>
  )
}

const CommandSpacebar = () => {
  const themeMode = useGetThemeMode()
  const { playAudio } = useMenuAudio()
  const MsgList = [
    { id: 0, time: '4 min', msg_type: 'message', user: '#cluster2th' },
    { id: 1, time: '26 min', msg_type: 'comment', user: '#cluster2th' },
    { id: 2, time: '7 hr', msg_type: 'like', user: '#cluster2th' },
    { id: 3, time: '1 day', msg_type: 'follow', user: '#cluster2th' },
  ]

  const msgCount = 5

  const nextLoad = useCallback(() => {
    setTimeout(() => {}, 500)
  }, [])

  return (
    <Container
      style={{
        background: themeMode == 'main' ? '#00000090' : '#ffffff90',
        borderColor: themeMode == 'main' ? '#02569d' : 'gray',
      }}
    >
      <div className="top-item">
        <div className="top-left">
          <MenuIcons url="social-white" themeMode={themeMode} size={'18px'} />
          <h3 style={{ marginLeft: '-20px' }}>
            <a
              href={SOCIAL_URL}
              onClick={() => playAudio('spacebar')}
              target="_self"
              style={{ color: themeMode === 'main' ? 'white' : 'black' }}
            >
              SPACEBAR
            </a>
          </h3>
        </div>
        <a href={SOCIAL_URL} onClick={() => playAudio('spacebar')} target="_blank" rel="noreferrer">
          <FiExternalLink size={20} color={themeMode === 'light' ? '#000' : '#fff'} />
        </a>
      </div>

      <div className="bottom-space-item">
        <InfiniteScroll
          dataLength={4} //This is important field to render the next data
          next={nextLoad}
          hasMore={msgCount > MsgList.length}
          loader={''}
          scrollableTarget={'scroll_table'}
        >
          <table
            className="spacebar-table"
            cellSpacing="0"
            cellPadding="10"
            width="100%"
            style={{
              background:
                themeMode == 'main' ? 'rgba(0, 0, 0, 0.5)' : themeMode == 'light' ? 'white' : 'rgba(0, 0, 0, 0.5)',
              color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
            }}
          >
            <tbody>
              {MsgList.map((item, index) => {
                let keyVal = item.id + '-' + index
                return (
                  <Row
                    key={keyVal}
                    time={item.time}
                    msg_type={item.msg_type}
                    follower={item.user}
                    themeMode={themeMode}
                  />
                )
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </Container>
  )
}
export default CommandSpacebar
