import { AutoComplete } from 'components/Autocomplete'
import React from 'react'
import TwoBtnModal from '../TwoBtnModal'
import { TGetNickNameListR } from 'utils/api'
import styled from 'styled-components'
import { useGetThemeMode } from 'state/theme/hook'
import { useSendingGift } from 'hooks/useModal'

const GiftModalBody = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 14px;
    margin-top: 12px;
    color: #caced2;
  }
`

const GiftSendingModal: React.FC = () => {
  const { onCloseSendingGift, onSendingGifts, setReceiverNickName, giftId, receiverNickName, userInfo, listNickName } =
    useSendingGift()

  const themeMode = useGetThemeMode()

  return (
    <TwoBtnModal
      title={`Sending ${giftId} as a Gift to a friend`}
      onClose={onCloseSendingGift}
      confirm={onSendingGifts}
      themeMode={themeMode}
    >
      <GiftModalBody>
        <AutoComplete
          optionsStyle={{ width: '100%', position: 'relative' }}
          data={listNickName.filter((item: TGetNickNameListR) => item.nick.slice(1) !== userInfo.nick)}
          onChange={setReceiverNickName}
          nameValue={receiverNickName}
          themeMode={themeMode}
        />
        <span
          style={{
            color: themeMode == 'main' ? 'white' : themeMode == 'light' ? 'black' : 'white',
          }}
        >{`You are sending your domain and will no longer have access to this ${giftId}. Do you want to continue?`}</span>
      </GiftModalBody>
    </TwoBtnModal>
  )
}

export default GiftSendingModal
