import React, { FC } from 'react'
import { useGetThemeMode } from 'state/theme/hook'
import FormInput from '../../../../components/FormInput'
import cn from './cn.module.css'

type PArticle = {
  title: string
  description: string
  error: '' | 'description' | 'title'
  memoizedSetTitle: (e: React.FormEvent<HTMLInputElement>) => void
  memoizedDescription: (e: React.FormEvent<HTMLTextAreaElement>) => void
  memoizedSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const Article: FC<PArticle> = ({
  title,
  description,
  error,
  memoizedSetTitle,
  memoizedDescription,
  memoizedSubmit,
}) => {
  const themeMode = useGetThemeMode()

  return (
    <div className={cn.wraper}>
      <form className={cn.form} onSubmit={memoizedSubmit} style={{ background: themeMode === 'light' ? 'white' : '' }}>
        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <FormInput
              value={title}
              onChange={memoizedSetTitle}
              label="Title"
              error={error === 'title' ? true : false}
              themeMode={themeMode}
            />
          </div>
        </div>
        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <label className={`${cn.label} ${error === 'description' ? cn.error : null}`}>Description</label>
            <textarea
              className={cn.textarea}
              value={description}
              onChange={memoizedDescription}
              style={{
                borderColor: themeMode === 'light' ? 'black' : 'white',
                color: themeMode === 'light' ? 'black' : 'white',
              }}
            />
          </div>
        </div>
        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <p className={cn.formTit}></p>
            <button
              className={cn.formBtn}
              style={{
                background:
                  themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                color: themeMode == 'main' ? 'white' : 'black',
              }}
            >
              SAVE
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
export default Article
