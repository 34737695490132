import React from 'react'
import './styles.scss'
import { NavLink } from 'react-router-dom'
import { useGetThemeMode } from 'state/theme/hook'

const HeaderLoginButtons: React.FC<any> = () => {
  const themeMode = useGetThemeMode()

  return (
    <div className="header-btns">
      <NavLink
        to="/login/"
        style={{
          background: 'transparent',
          color: themeMode == 'main' ? 'white' : 'black',
          border: themeMode == 'main' ? '1px solid goldenrod' : '1px solid white',
        }}
      >
        Login
      </NavLink>
      {/* <NavLink
        to="/register/"
        style={{
          background: themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
          color: themeMode == 'main' ? 'white' : 'black',
        }}
      >
        Sign up
      </NavLink> */}
    </div>
  )
}
export default HeaderLoginButtons
