import { useMenuAudio } from 'components/DomainsDashboardTable/hooks'
import MenuIcons from 'components/Icons/MenuIcons'
import React, { useState } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { useGetThemeMode } from 'state/theme/hook'
import styled from 'styled-components'
import BackslashImg from '../../assets/command/Backslash.png'
import BackslashLImg from '../../assets/command/Backslash-l.png'
import './styles.scss'

const Container = styled.div<{}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 200px;
  font-size: 14px;
  position: relative;
  @media only screen and (max-width: 1660px) {
    font-size: 12px;
    width: 50%;
  }
  @media only screen and (max-width: 1024px) {
    width: 103%;
  }
  // @media only screen and (max-width: 768px) {
  //   width: 103%;
  // }
`
const CommandBackslash = () => {
  const themeMode = useGetThemeMode()
  const { playAudio } = useMenuAudio()

  return (
    <Container style={{ color: themeMode === 'main' ? 'white' : 'black' }}>
      <img src={themeMode === 'main' ? BackslashImg : BackslashLImg} className="background-img" alt="" />
      <div className="top-item-backslash">
        <div className="top-header">
          <MenuIcons url="marketspace" size={'10px'} themeMode={themeMode} />
          <h3 style={{ marginLeft: '-20px' }}>
            <NavLink
              to="/marketspace/"
              onClick={() => playAudio('backslash')}
              style={{ color: themeMode === 'main' ? 'white' : 'black' }}
            >
              BACKSLASH
            </NavLink>
          </h3>
        </div>
        <NavLink
          to="/marketspace/"
          onClick={() => playAudio('backslash')}
          style={{ color: themeMode === 'main' ? 'white' : 'black' }}
        >
          <FiExternalLink size={20} />
        </NavLink>
      </div>
      <div className="bottom-item-backslash">
        <div className="bottom-left">
          <div className="backslash-txt">A DECENTRALIZED</div>
          <h1 style={{ marginTop: '5px' }}>MARKETPLACE</h1>
          <div style={{ fontSize: '12px', marginTop: '5px' }}>full of wonders!</div>
        </div>
        <div className="bottom-right">
          <button
            className="button-lay"
            style={{
              background:
                themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#fbb212, #f48f33)',
              color: themeMode == 'main' ? 'white' : 'black',
            }}
          >
            Explore
          </button>
        </div>
      </div>
    </Container>
  )
}
export default CommandBackslash
