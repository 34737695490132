import React, { ReactNode } from 'react'
import { ICurrentDate, ICurrentDatePicker } from '../types'

const InitialState: ICurrentDatePicker = {
  currentDate: {
    year: new Date().getUTCFullYear(),
    month: new Date().getUTCMonth(),
  },
  setCurrentDate: () => {},
}

const DatePickerContext = React.createContext<ICurrentDatePicker>(InitialState)
const useDatePickerContext = () => React.useContext(DatePickerContext)

const DatePickerContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentDate, setCurrentDate] = React.useState<ICurrentDate>(InitialState['currentDate'])

  return <DatePickerContext.Provider value={{ currentDate, setCurrentDate }}>{children}</DatePickerContext.Provider>
}

export { DatePickerContext, useDatePickerContext, DatePickerContextProvider }
