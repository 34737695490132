import { fork, put, takeLatest, delay, call, all } from '@redux-saga/core/effects'
import { checkErorr, getToken } from './helper'
import { push } from 'connected-react-router'
import { TMarketSpaceItem, TUpdateMarketSpaceList } from 'views/MarketSpace/types'
import { getMarketSpaceList, updateMarketSpaceList } from 'utils/api'
import { showToast } from './domain'

export function* watcherMarketSpace() {
  yield takeLatest('MARKETSPACE_GET_LIST', workerGetMarketSpaceList)
  yield takeLatest('MARKETSPACE_UPDATE_DETAIL', workerUpdateMarketSpaceDetail)
}

function* workerGetMarketSpaceList() {
  const token: string = yield call(getToken)
  if (!token) {
    yield put(push(`/login/`))
    return
  }

  try {
    const res: TMarketSpaceItem[] = yield call(getMarketSpaceList, token)
    if (res.length > 0) {
      yield put({
        type: 'MARKETSPACE_LIST',
        payload: res,
      })
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  } finally {
  }
}

function* workerUpdateMarketSpaceDetail({ payload }: TUpdateMarketSpaceList) {
  const token: string = yield call(getToken)
  if (!token) {
    yield put(push(`/login/`))
    return
  }

  try {
    const isUpdated: boolean = yield call(updateMarketSpaceList, token, { list: payload })
    if (isUpdated) {
      const marketList: TMarketSpaceItem[] = yield call(getMarketSpaceList, token)
      if (marketList.length > 0) {
        yield put({
          type: 'MARKETSPACE_LIST',
          payload: marketList,
        })
      }
      showToast('Updated successfully', 'success')
    }
  } catch (e: any) {
    yield fork(checkErorr, e)
  }
}

export default watcherMarketSpace
