import React from 'react'
import cn from './cn.module.scss'
import { ITokenP } from '../../../types'

type ITokenWrapper = {
  token: ITokenP
  selectToken: (token: ITokenP) => void
}

const TokenWrapper: React.FC<ITokenWrapper> = ({ token, selectToken }) => {
  const onSelectToken = (token: ITokenP) => () => {
    selectToken(token)
  }

  return (
    <div className={cn.tokenWrapper} onClick={onSelectToken(token)}>
      <div className={cn.tokenName}>
        <img className={cn.tokenIcon} src={token.logoURI} alt={token.name} />
        {token.symbol}
      </div>
      <div className={cn.tokenBalance}>{!!token.balance ? token.balance?.toFixed(6) : 0}</div>
    </div>
  )
}

export default TokenWrapper
