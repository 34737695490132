import React from 'react'
import styled from 'styled-components'

export const AdminTransactionListContainer = styled.div`
  width: 100%;
  max-height: 100vh;
`
export const TransactionSearchContainer = styled.div`
  display: flex;
`
export const TransactionSearchInput = styled.input`
  height: 42px;
  font-size: 14px;
  padding: 0 10px;
  border: none;
  display: flex;
  width: 100%;
  outline: #ffdead;
`
export const RecheckBtn = styled.button`
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  // color: #000;
  font-size: 16px !important;
  font-weight: 700 !important;
  cursor: pointer;
  &:disabled {
    background: #a2a2a2;
    cursor: default;
  }
`
