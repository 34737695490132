import classes from 'classnames'
import React, { FC } from 'react'
import Avatar from 'react-avatar'
import { FaFacebookF, FaInstagram, FaSkype, FaTelegramPlane } from 'react-icons/fa'
import { useGetThemeMode } from 'state/theme/hook'
import FormInput from '../../components/FormInput'
import UnifiedModal1 from '../../components/Modals/UnifiedModal1'
import ModalGA from '../ModalGA'
import modal from '../ModalGA/modal.module.css'
import cn from './cn.module.css'
import { useProfile } from './hooks'
import { useModal } from './modalsHooks'

const UserProfile: FC = () => {
  const { state, onChangeFile, onChangeText, onSubmit, userInfo } = useProfile()

  const {
    isOpenModal,
    setisOpenModal,
    memoModalOpen,
    memoModalCodeClose,
    memoModalonSend,
    unBindGA,
    isOpenModalUnGA,
    memoModalOpenUnGA,
    memoModalCloseUnGA,
    memoOnChangeCodeUnGA,
    codeUnGA,
    memoModalonSendUnGA,
    codeUnGAEr,
  } = useModal()

  const themeMode = useGetThemeMode()

  return (
    <div className={cn.wraper}>
      <form className={themeMode == 'light' ? cn.form_l : cn.form} onSubmit={onSubmit}>
        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <div className={cn.input + ' ' + classes(false ? cn.error : '')}>
                <label>Upload avatar (Max file size 1MB)</label>
                <input accept=".jpeg, .jpg, .png, .svg" type="file" onChange={onChangeFile} />
              </div>
            </div>
            <div className={cn.threecol}>
              <div className={cn.input}>
                <Avatar
                  src={state.src ? state.src : userInfo.img ? userInfo.img : undefined}
                  name={`${state.first_name} ${state.last_name}`}
                  size="50"
                  round="50px"
                  color={
                    themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)'
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <FormInput
                label="#HashtagName"
                value={state.nick}
                onChange={onChangeText('SET_NICK')}
                error={state.isNickE}
                maxLength={36}
                readOnly={userInfo.nick !== null && userInfo.nick !== ''}
                themeMode={themeMode}
              />
            </div>
            <div className={cn.threecol}>
              <FormInput
                label="PayPal Address"
                value={state.paypal_address}
                onChange={onChangeText('SET_PAYPAL_ADDRESS')}
                themeMode={themeMode}
              />
            </div>
          </div>
        </div>

        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <div className={`${cn.threecol} ${cn.long}`}>
              <FormInput
                label="MHC Wallet Address"
                value={state.mhc_address}
                onChange={onChangeText('SET_MHC_ADDRESS')}
                themeMode={themeMode}
              />
            </div>
          </div>
        </div>

        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <div className={`${cn.threecol} ${cn.long}`}>
              <FormInput
                label="ETH Wallet Address"
                value={state.eth_address}
                onChange={onChangeText('SET_ETH_ADDRESS')}
                themeMode={themeMode}
              />
            </div>
          </div>
        </div>

        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <div className={`${cn.threecol} ${cn.long}`}>
              <FormInput
                label="BNB Wallet Address"
                value={state.bnb_address}
                onChange={onChangeText('SET_BNB_ADDRESS')}
                themeMode={themeMode}
              />
            </div>
          </div>
        </div>

        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <FormInput
                label="First name"
                value={state.first_name}
                onChange={onChangeText('SET_FIRST_NAME')}
                maxLength={36}
                themeMode={themeMode}
              />
            </div>
            <div className={cn.threecol}>
              <FormInput
                label="Last Name"
                value={state.last_name}
                onChange={onChangeText('SET_LAST_NAME')}
                maxLength={36}
                themeMode={themeMode}
              />
            </div>
          </div>
        </div>

        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <FormInput
                label="New password"
                onChange={onChangeText('SET_PASSWORD')}
                value={state.password}
                error={state.isPasswordErr}
                type={'password'}
                maxLength={36}
                themeMode={themeMode}
              />
            </div>
            <div className={cn.threecol}>
              <FormInput
                label="Confirm new password"
                onChange={onChangeText('SET_CONFIRMPASSWORD')}
                value={state.confirmPassword}
                error={state.isConfirmPassErr}
                type={'password'}
                maxLength={36}
                themeMode={themeMode}
              />
            </div>
          </div>
        </div>

        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <div className={cn.bio_col}>
              <label>Info</label>
              <textarea
                value={state.info}
                onChange={onChangeText('SET_INFO')}
                style={{
                  background: themeMode === 'main' ? '#00000090' : '#ffffff90',
                  color: themeMode === 'main' ? 'white' : 'black',
                  padding: '5px',
                }}
              />
            </div>
          </div>
        </div>

        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <div
                className={cn.soc}
                style={{
                  background:
                    themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                  color: themeMode == 'main' ? 'white' : 'black',
                }}
              >
                <FaFacebookF />
              </div>
              <FormInput
                error={state.isFacebookE}
                className={cn.soc_input}
                onChange={onChangeText('SET_facebook')}
                value={state.facebook}
                type={'text'}
                placeholder={'userID'}
                maxLength={100}
                themeMode={themeMode}
              />
            </div>

            <div className={cn.threecol}>
              <div
                className={cn.soc}
                style={{
                  background:
                    themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                  color: themeMode == 'main' ? 'white' : 'black',
                }}
              >
                <FaTelegramPlane />
              </div>
              <FormInput
                error={state.isTelegramE}
                className={cn.soc_input}
                type={'text'}
                onChange={onChangeText('SET_telegram')}
                value={state.telegram}
                placeholder={'username'}
                maxLength={100}
                themeMode={themeMode}
              />
            </div>
          </div>
        </div>
        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <div className={cn.threecol}>
              <div
                className={cn.soc}
                style={{
                  background:
                    themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                  color: themeMode == 'main' ? 'white' : 'black',
                }}
              >
                <FaInstagram />
              </div>
              <FormInput
                error={state.isInstagramE}
                className={cn.soc_input}
                onChange={onChangeText('SET_instagram')}
                value={state.instagram}
                type={'text'}
                placeholder={'username'}
                maxLength={100}
                themeMode={themeMode}
              />
            </div>

            <div className={cn.threecol}>
              <div
                className={cn.soc}
                style={{
                  background:
                    themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                  color: themeMode == 'main' ? 'white' : 'black',
                }}
              >
                <FaSkype />
              </div>
              <FormInput
                className={cn.soc_input}
                type={'text'}
                placeholder={'username'}
                onChange={onChangeText('SET_skype')}
                value={state.skype}
                error={state.isSkypeE}
                maxLength={100}
                themeMode={themeMode}
              />
            </div>
          </div>
        </div>

        {userInfo.enabled_ga === false ? (
          <div className={cn.formCol2}>
            <div className={cn.formRow}>
              <p className={cn.formTit}></p>
              <div
                className={cn.formBtn}
                onClick={memoModalOpen}
                style={{
                  background:
                    themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                  color: themeMode == 'main' ? 'white' : 'black',
                }}
              >
                Bind Google Authenticator
              </div>
            </div>
          </div>
        ) : null}

        {userInfo.enabled_ga === true ? (
          <div className={cn.formCol2}>
            <div className={cn.formRow}>
              <p className={cn.formTit}></p>
              <div
                className={cn.formBtn}
                onClick={memoModalOpenUnGA}
                style={{
                  background:
                    themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                  color: themeMode == 'main' ? 'white' : 'black',
                }}
              >
                Unbind Google Authenticator
              </div>
            </div>
          </div>
        ) : null}

        <div className={cn.formCol2}>
          <div className={cn.formRow}>
            <p className={cn.formTit}></p>
            <button
              className={cn.formBtn}
              disabled={state.isButtonDisabled}
              style={{
                background:
                  themeMode == 'main' ? 'linear-gradient(#873f99, #311438)' : 'linear-gradient(#b7eaf6, #777fc4)',
                color: themeMode == 'main' ? 'white' : 'black',
              }}
            >
              SAVE
            </button>
          </div>
        </div>
      </form>

      <ModalGA
        isOpen={isOpenModal}
        setisOpenModal={setisOpenModal}
        onSend={memoModalonSend}
        title={'Bind Google Authenticator'}
      />

      <UnifiedModal1
        title={'Please save this code it is needed to disable GA'}
        showModal={unBindGA === '' ? false : true}
        noOk={true}
        btnCloseName={'Ok'}
        onClose={memoModalCodeClose}
      >
        <p className={cn.code}>{unBindGA}</p>
      </UnifiedModal1>

      <UnifiedModal1
        title={'Unbind Google Authenticator'}
        showModal={isOpenModalUnGA}
        btnSaveName={'Send'}
        onClose={memoModalCloseUnGA}
        onSave={memoModalonSendUnGA}
      >
        <div className={modal.container}>
          <label className={codeUnGAEr ? modal.error : ''}>Please enter the code received earlier to disable GA</label>
          <input className={modal.s} type="text" value={codeUnGA} onChange={memoOnChangeCodeUnGA} />
        </div>
      </UnifiedModal1>
    </div>
  )
}

export default UserProfile
