import React from 'react'
import cn from './cn.module.scss'
import { Config } from '../config'

type IWalletWrapper = {
  wallet: Config
  selectWallet: (wallet: Config) => void
}

const WalletWrapper: React.FC<IWalletWrapper> = ({ wallet, selectWallet }) => {
  const Icon = wallet.icon
  const onSelectWallet = (wallet: Config) => () => {
    selectWallet(wallet)
  }

  return (
    <div className={cn.walletWrapper} onClick={onSelectWallet(wallet)}>
      <div className={cn.walletName}>{wallet.title}</div>
      <div className={cn.walletIcon}>
        <Icon />
      </div>
    </div>
  )
}

export default WalletWrapper
