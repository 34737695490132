import React from 'react'
import { DomainAnalyticsItems } from './DomainAnalyticsItems'
import { useGetDomainAnalytics } from '../../hooks/useDomainAnalytics'
import { useGetThemeMode } from 'state/theme/hook'

export const DomainAnalytics: React.FC = () => {
  const domainAnalytics = useGetDomainAnalytics()
  const themeMode = useGetThemeMode()

  return (
    <>
      <DomainAnalyticsItems
        title={'Dropped Billing'}
        value={domainAnalytics.droppedBilling ? domainAnalytics.droppedBilling.length : 0}
        color={'#ee092f'}
        list={domainAnalytics.droppedBilling}
        themeMode={themeMode}
      ></DomainAnalyticsItems>
      <DomainAnalyticsItems
        title={'Domain Sold'}
        value={domainAnalytics.soldDomains ? domainAnalytics.soldDomains.length : 0}
        color={'#2ec814'}
        list={domainAnalytics.soldDomains}
        themeMode={themeMode}
      ></DomainAnalyticsItems>
      <DomainAnalyticsItems
        title={'Domain Resold'}
        value={domainAnalytics.resoldDomains ? domainAnalytics.resoldDomains.length : 0}
        color={'#fffc00'}
        list={domainAnalytics.resoldDomains?.map((item) => {
          return { id: item.id, name: item.name.replace(' resale', '') }
        })}
        themeMode={themeMode}
      ></DomainAnalyticsItems>
      <DomainAnalyticsItems
        title={'Keywords'}
        value={domainAnalytics.keywords ? domainAnalytics.keywords.length : 0}
        color={'#96ff00'}
        list={domainAnalytics.keywords}
        themeMode={themeMode}
      ></DomainAnalyticsItems>
      <DomainAnalyticsItems
        title={'Parked Domains'}
        value={domainAnalytics.parkedDomains ? domainAnalytics.parkedDomains.length : 0}
        color={'#0dffff'}
        list={domainAnalytics.parkedDomains}
        themeMode={themeMode}
      ></DomainAnalyticsItems>
    </>
  )
}
